/**
 * Created by fliak on 23.2.17.
 */

import strtr from "./../helper/strtr";

class ViolationBuilder {

    build(messageKey, params, property, root = null, errorCode = null, invalidValue = null) {
        let originMessageKey = messageKey;
        //messageKey = this.translate(messageKey);

        let message = strtr(messageKey, params);

        return {
            message,
            messageKey,
            originMessageKey,
            params,
            property,
            root,
            errorCode,
            invalidValue
        };
    }
}

export default new ViolationBuilder();
