/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 25.2.17
 * Time: 7.26
 */

import React from 'react';
import PropTypes from 'prop-types';

export default function Violation(props) {

    let visible = props.visible;
    let violationSet = props.violationSet || [];


    if (!Array.isArray(violationSet)) {
        violationSet = [violationSet];
    }

    visible = violationSet.length === 0 ? false : visible;

    let display = visible ? '' : 'none';

    let classes = ['fr-violation'];

    if (props.className !== undefined)  {
        classes.push(props.className);
    }

    return (<ul style={{display}} data-type="violation" data-visible={visible} className={classes.join(' ')}>
        {violationSet.map((violation, i) => {

            let message = typeof violation === 'object' ? violation.message : violation;

            return <li key={i} className="fr-error fr-alert-msg">
                {props.children} {message}
            </li>
        })}
    </ul>);

}

Violation.propTypes = {
    visible: PropTypes.bool,
    violationSet: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};


Violation.defaultProps = {
    visible: true,
    violationSet: []
};

Violation.getFirstOnPage = () => {
    return document.querySelector('[data-type=violation][data-visible=true]')
};

Violation.scrollToFirstOnPage = () => {
    let firstViolation = Violation.getFirstOnPage();
    if (firstViolation) {
        firstViolation.scrollIntoView({behavior: "smooth"})
    }
};