/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.5.17
 * Time: 9.02
 */

import React from "react";
import { Modal } from "react-bootstrap";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";

export function awareModal(ComponentClass) {

    return createReactClass({
        displayName: "ModalAwareWrapper",

        contextTypes: {
            router: PropTypes.object.isRequired
        },

        getInitialState() {
            return { showModal: Boolean(this.props.children) };
        },


        componentWillReceiveProps: function(nextProps) {
            this.setState({
                showModal: Boolean(nextProps.children)
            });
        },

        close() {

            let router = this.context.router;

            router.routes.reverse().forEach(route => {

                if (route.modalRelative) {
                    router.push(route.path);

                    return false;
                }
            });
        },

        render: function render() {

            return <div>

                <Modal
                    show={this.state.showModal}
                    onHide={this.close}
                    backdrop="static" animation={true}
                >
                    {this.props.children}
                </Modal>

                <ComponentClass {...this.props} />
            </div>;
        }
    });
}
