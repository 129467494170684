/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.6.4
 * Time: 15:26
 */

import { xhr } from "../service/api-client-2";
import { Request } from "../service/api-client-2/request";

export function xhrAddSalonService(serviceId, price, duration, processing) {

    return xhr()
    .suppressNotifications()
    .post("@Q2-22-add-salon-service")
    .data({ serviceId, price, duration, processing })
    .end()
    .then((request: Request) => {
        let { response } = request;

        return Promise.resolve(response);
    }, (request: Request) => {
        let { response } = request;

        return Promise.reject(response);
    });
}

export function xhrModifySalonService(id, price, duration, processing) {

    return xhr()
    .suppressNotifications()
    .put("@Q2-23-edit-salon-service", { salonServiceId: id })
    .data({ price, duration, processing })
    .end()
    .then((request: Request) => {
        let { response } = request;

        return Promise.resolve(response);
    });
}

export function xhrRemoveSalonService(id) {

    return xhr()
    .suppressNotifications()
    .delete("@Q2-24-delete-salon-service", { salonServiceId: id })
    .end()
    .then((request: Request) => {
        let { response } = request;

        return Promise.resolve(response);
    }, (request: Request) => {
        let { response } = request;

        return Promise.reject(response);
    });
}
