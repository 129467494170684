/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 17.12.2018
 * Time: 13:51
 */
import React from "react";

export default function LoaderBar(props) {

    let defaultClasses = ["loader-msg-wrapper"];
    if (props.className) {
        defaultClasses.push(props.className);
    }

    return (
        <div className={defaultClasses.join(" ")}>
            <div className={"loader-msg-wrapper--item"}>
                <div className={"loader-msg-block"}>
                    <div className="loader">
                        <span className="loader-span"></span>
                    </div>
                </div>
            </div>
        </div>
    );
}
