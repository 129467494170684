/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 09.11.2018
 * Time: 12:10
 */

import AbstractGalleryStore from "./gallery2/abstract-gallery-store";
import { GALLERY2_ARTIST_PROMO_GALLERY_ID } from "./../frizo-shared-constants";
import { GALLERY_LOAD } from "./../constants";

export default class ArtistPromoGalleryStore extends AbstractGalleryStore {
    constructor(...args) {
        super(GALLERY2_ARTIST_PROMO_GALLERY_ID, ...args);

        this.setGenericFilterAware(true);


        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case GALLERY_LOAD:
                    if (!payload || payload.galleryId !== this.galleryId) return false;
                    this.store.gallery.params.salonId = payload.salonId;
                    this.store.gallery.params.artistId = payload.artistId;

                    //fall to super
                    return true;

                default:
                    return true;
            }


            this.emitChange(); // eslint-disable-line no-unreachable
        });
    }

    getInitialGallery() {
        return Object.assign(super.getInitialGallery(), {
            totalIsKnown: false,
            params: {
                offset: 0,
                count: 12,
                salonId: null,
                artistId: 0
            }
        });
    }

    nextPage() {
        this.store.gallery.params.offset++;
        this.store.gallery.loading = true;
        this.store.gallery.scrollMode = true;

        return this.loadGallery();
    }
}
