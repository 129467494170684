/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 28.05.2017
 * Time: 11:18
 */

import React, {Component} from 'react';
import {Dropdown, MenuItem, Button} from 'react-bootstrap';

export default class DashboardWhat extends Component {
    constructor(props) {
        super();

        this.state = {
            isOpen: false
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.onReset = this.onReset.bind(this);
    }

    onReset() {
        this.handleSelect('0');
    }

    createOptions() {
        if (!this.props.data) return null;

        let selectedId = this.props.selected;
        return this.props.data.map((item, i) => {
            return (
                <MenuItem
                    className="dashboard-what__item"
                    key={item.categoryID}
                    active={item.categoryID === selectedId}
                    eventKey={item.categoryID}
                    // onSelect={this.handleSelect} /* Removed due to duplication, see onSelect={this.handleSelect} in render() */
                >
                    {item.categoryName}
                </MenuItem>
            )
        });
    }


    handleSelect(e) {
        this.props.onChange(e);
    }

    renderSelected() {
        if (this.props.selected < 1) {
            return (
                <Dropdown.Toggle style={{'width': '100%', 'textAlign': 'left'}}
                                 className="dashboard-what__filter-dropdown-toggle">
                    Select category
                </Dropdown.Toggle>
            )
        }
        let selectedId = this.props.selected,
            selectedData = this.props.data.filter((item, i) => item.categoryID === selectedId)[0];
        return (
            <Dropdown.Toggle style={{'width': '100%', 'textAlign': 'left'}}
                             className="dashboard-what__filter-dropdown-toggle-selected">
                {selectedData.categoryName}
            </Dropdown.Toggle>
        )
    }

    handleFocus() {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {

        return (
            <div className="dashboard-what__filter-wrap">
                <Dropdown id="dashboard-what__filter"
                          bsStyle={{'width': '100%', 'textAlign': 'left'}}
                          className={this.props.className + ' dashboard-what__filter'}
                          onSelect={this.handleSelect}
                          onToggle={this.handleFocus}
                          open={this.state.isOpen}>
                    {this.renderSelected()}
                    <Dropdown.Menu className="dashboard-what__menu">
                        <MenuItem className="dashboard-what__item" disabled value="0">Select category</MenuItem>
                        {this.createOptions()}
                    </Dropdown.Menu>
                </Dropdown>
                {this.props.selected > 0 && <Button onClick={this.onReset}
                                                    className={this.props.className + ' dashboard-btn__reset fr-btn pull-right'}><span
                    className="glyphicon glyphicon-remove"></span></Button>}
            </div>

        )
    }
}
