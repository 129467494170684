/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.18.8
 * Time: 10:26
 */

import React, { Component } from "react";
import { Button, Dropdown, MenuItem } from "react-bootstrap";
import idGenerator from "./../helper/id-generator-component";

import { DROPDOWN_PLACEHOLDER_VALUE } from "./../frizo-shared-constants";

export default class DropdownWrapper extends Component {

    constructor(props) {
        super(props);

        this.onSelectHandler = this.onSelectHandler.bind(this);
    }

    createSelected() {
        let toggled = this.props.placeholder;
        if (typeof this.props.selected !== "undefined" && this.props.selected !== null) {
            let selectedItem = this.props.list.find(item => item.id === this.props.selected);
            if (selectedItem && selectedItem[this.props.optionTextPropName]) {
                toggled = selectedItem[this.props.optionTextPropName];
            }
        }

        return toggled;
    }

    createMenuItems() {
        let menuItems = [];
        if (this.props.showPlaceholder) {
            menuItems.push(<MenuItem
                key={-1}
                disabled
                value={DROPDOWN_PLACEHOLDER_VALUE}>
                {this.props.placeholder}
            </MenuItem>);
        }
        this.props.list.forEach((item) => {
            if (item.id !== this.props.selected) {
                menuItems.push(<MenuItem
                    bsClass={this.props.itemClass}
                    key={item.id}
                    eventKey={item.id}
                    onSelect={this.onSelectHandler}>
                    {item[this.props.optionTextPropName]}
                </MenuItem>);
            }
        });

        return menuItems;
    }

    onSelectHandler(key) {
        this.props.onChange(key);
    }

    render() {
        let menuItems = this.createMenuItems();
        if (menuItems.length === 0) {
            return (
                <div className={this.props.className + " dropdown btn-group"}>
                    <Button
                        className={this.props.className + "__disabled btn btn-default"}> {this.createSelected()}</Button>
                </div>
            );
        }
        return (
            <Dropdown
                className={this.props.className}
                id={this.props.dropdownId || idGenerator()}
                defaultOpen={this.props.defaultOpen}
            >

                <Dropdown.Toggle className={this.props.className + "__selected"}>
                    {this.createSelected()}
                </Dropdown.Toggle>

                <Dropdown.Menu className={this.props.className + "--menu"}>
                    {menuItems}
                </Dropdown.Menu>
            </Dropdown>);
    }

}

DropdownWrapper.defaultProps = {
    selected: 0,
    list: [],
    className: "dropdown-default",
    optionTextPropName: "name",
    dropdownId: "",
    placeholder: "Select",
    defaultOpen: false,
    open: false,
    showPlaceholder: true,
    onChange: () => false
};
