/**
 * Created by fliak on 23.2.17.
 */

import { getConstraint, getValidatorFor } from "./constraints/index";
import sprintf from "./../helper/sprintf";
import { autoHash } from "./../helper/auto-hash";

export default class Validator {
    constructor() {
        this.chain = autoHash();
    }

    static validateAtomic(constraint, value, options) {

        let constraintObject = Validator.createConstraint(constraint, options);


        let validator = getValidatorFor(constraintObject);

        return validator.validate(constraintObject, "temp1", {
            "temp1": value
        });

    }

    /**
     * @protected
     */
    static createConstraint(constraint, options) {
        let constructor = getConstraint(constraint);

        if (!constructor) throw new Error(sprintf("Constraint '%s' is missing", constraint));

        return new constructor(options);
    }

    addConstraint(field, constraint, options) {

        let constraintObject = Validator.createConstraint(constraint, options);

        this.chain.ensureArray(field).push(constraintObject);

        return this;
    }

    getChain() {
        return this.chain;
    }

    validate(object) {
        let violationList = autoHash();
        let isValid = true;

        console.log("this.chain", this.chain);
        console.log("object to validate", object);

        for (let field in this.chain) {
            let fieldConstraints = this.chain[field];
            if (!fieldConstraints) continue;

            for (let constraint of fieldConstraints) {
                let validator = getValidatorFor(constraint);
                let result = validator.validate(constraint, field, object);

                if (result !== true) {
                    violationList.ensureArray(field).push(result);
                    isValid = false;


                    if (constraint.breakOnError2) {
                        return violationList;
                    }

                    if (constraint.breakOnError1) {
                        break;
                    }
                }
            }
        }
        console.log("isValid", isValid);

        if (isValid) {
            return true;
        }

        console.warn("violationList", violationList);

        return violationList;
    }
}
