/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 14.11.2017
 * Time: 11:15
 */

import { sessionNormalize } from "../normal-form/user-derived-forms/session";
import { CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";
import { xhr } from "../service/api-client-2";
import { alterAuthRequest } from "../auth";
import { Request } from "../service/api-client-2/request";

export const xhrGetQuickLogin = function(token) {

    return xhr()
    .skipAuth()
    .post("@Q127_quick_sign_in")
    .data({ token })
    .alterWith(alterAuthRequest, "userSalon")
    .end()
    .then((request: Request) => {

        let response = request.response.response;
        let groupsString = request.getRequestHeader(CUSTOM_HEADER_U6_GROUPS);
        let groups = groupsString.split(",");

        let payload = {
            session: sessionNormalize(response),
            response,
            authorized: true,
            request: request,
            options: { groups }
        };

        return Promise.resolve(payload);
    }, (request: Request) => {

        let { response, xhr } = request;

        let groupsString = request.getRequestHeader(CUSTOM_HEADER_U6_GROUPS);
        let groups = groupsString.split(",");

        return Promise.reject({
            session: null,
            response: response.response,
            authorized: false,
            problemKey: response.errorKey,
            problem: response.message,
            request: xhr,
            options: { groups }
        });
    });
};
