/**
 * User: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 26.3.17
 * Time: 13.13
 */

import React from 'react';
import {Row, Col} from 'react-bootstrap';
import gradientFill from '../../../helper/gradient-fill';

export default function SalonName(props) {

    return (
        <div>
            <div className="fr-banner-top fr-full-width" style={{'background': gradientFill()}}>
                <Row className="fr-container-width">
                    <Col xs={12} className="fr-banner-top__text--salonName">
                <h1>{props.name}</h1>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
