/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 18.8.17
 */

import BaseStore from "./base-store";
import { FEATURE_FLAG_LOADED, FEATURE_FLAG_PARAMS_ENTERED, ROUTE_MATCHED } from "./../constants";

export default class FeatureFlagStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case ROUTE_MATCHED:
                    if (!this.store.waitingForFF) {
                        this.setReady(true);
                    }

                    break;

                case FEATURE_FLAG_PARAMS_ENTERED:
                    this.resetStore();
                    this.store.waitingForFF = true;
                    this.store.ffParams = payload.ffParams;
                    this.setReady(false);

                    break;

                case FEATURE_FLAG_LOADED:
                    Object.assign(this.store, payload);
                    this.setReady(true);

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialStore() {
        return {
            waitingForFF: false,
            ffParams: {},
            ff: {}
        };
    }
}
