/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 22.09.2017
 * Time: 17:24
 */

import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {modalHide} from '../../ac/modals';
import {browserHistory} from 'react-router';
import PropTypes from 'prop-types';
import {subscriptionsStore} from './../../stores/index';
import gradientFill from './../../helper/gradient-fill';

export default class SubscriptionFinishModal extends Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.confirm = this.confirm.bind(this);


    }


    confirm() {
        if (this.props.serverErrors.length === 0) {
            browserHistory.push(`/book`);
        }
        modalHide();
    }


    close() {
        modalHide();
    }


    render() {
        let {planName, serverErrors} = this.props;
        let title = serverErrors === '' ? 'Success' : 'Fail';

        let externalUrl = "fz-subscriptions://?subscribed=" + (serverErrors === '' ? "true" : "false");

        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>{title}</h2>
                </Modal.Header>
                <Modal.Body>
                    {serverErrors === '' &&
                    <div className="fr-text-center">
                        <div className="approval-title">
                            You have successfully subscribed to
                        </div>
                        <div className="approval-title  approval-title__success">{planName}</div>
                    </div>}

                    {serverErrors !== '' &&
                    <div className="fr-text-center">
                        <div className="approval-title">
                            Unfortunately, subscription process was interrupted with following message:
                        </div>
                        <div className="approval-title  approval-title__error">{serverErrors}</div>
                    </div>}
                </Modal.Body>

                <Modal.Footer className="card-add-footer">

                    <div className="pull-right">
                        {subscriptionsStore.store.userPlatform === null &&
                        <Button onClick={this.confirm} bsStyle="success" className="">OK</Button>}
                        {subscriptionsStore.store.userPlatform !== null &&
                        <a href={externalUrl} className="btn btn-success">OK</a>}
                    </div>

                </Modal.Footer>
            </div>)
    }
}
SubscriptionFinishModal.propTypes = {
    planName: PropTypes.string,
    serverErrors: PropTypes.string
};

SubscriptionFinishModal.defaultProps = {
    planName: '',
    serverErrors: '',
    userPlatform: null
};
