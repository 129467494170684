/**
 * Created by fliak on 22.2.17.
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_REQUIRED = "VALUE_IS_REQUIRED";

export default class IsRequired extends BaseConstraint {

    constructor(options = null) {
        super();

        this.breakOnError1 = true;

        this.message = "Required field";

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        return "is-required";
    }
}
