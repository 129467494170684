/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 18.8.17
 */

import { FEATURE_FLAG_LOADED, FEATURE_FLAG_PARAMS_ENTERED, ROUTE_MATCHED } from "./../constants";

import AppDispatcher from "./../dispatcher";

import { getFeatureFlags } from "./../xhr/feature-flag-xhr";

export function routeMatched() {
    setTimeout(() => {
        AppDispatcher.dispatch({
            type: ROUTE_MATCHED
        });
    });
}

export function setFFParams(ffParams) {

    let parsed = {};
    if (ffParams.p) {
        parsed.platform = ffParams.p;
    }

    if (ffParams.v) {
        parsed.version = ffParams.v;
    }

    AppDispatcher.dispatch({
        type: FEATURE_FLAG_PARAMS_ENTERED,
        payload: {
            ffParams: parsed
        }
    });


    getFeatureFlags(parsed).then(response => {
        AppDispatcher.dispatch({
            type: FEATURE_FLAG_LOADED,
            payload: {
                ffParams: parsed,
                ff: response
            }
        });
    });

}
