/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 11.05.2017
 * Time: 10:06
 */
import ApiClient from "../service/api-client";

export function xhrLoadLegalDoc(docType) {
    let url = ApiClient.getRouting().generate(("Q17_sign_up_documents"), {
        type: docType
    });

    return ApiClient.get((url), {}, true).then(answer => {
        return answer[0].response;
    });
}

export function xhrLoadUnsignedDoc() {
    let newApiClientURL = ApiClient.getRouting().generate("Q92_get_unsigned_legal", {}, true);

    return ApiClient.get(newApiClientURL).then(answer => {
        let response = answer[0];
        return Promise.resolve(response.response);
    });
}

export function xhrSignDocument(docId) {
    let newApiClientURL = ApiClient.getRouting().generate("Q91_legal_sign", {
        "id": docId
    }, true);

    return ApiClient.post(newApiClientURL, {
        "id": docId
    }, { run_directly: true }, true).then(answer => {
        return answer;
    });
}
