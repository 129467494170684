/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 05.04.2017
 * Time: 09:23
 */

import React, {Component} from 'react';
import {Col, Row, Badge} from 'react-bootstrap';
import printDate from '../../../../../helper/print-date';
import PaymentOrderAction from './payment-order-action';
import moment from 'moment';

import {
    PAYMENT_STATUS_NO_STATUS,
    PAYMENT_STATUS_PENDING,
    PAYMENT_STATUS_PAID,
    PAYMENT_STATUS_DECLINED
} from '../../../../../frizo-shared-constants';

import PropTypes from 'prop-types';

import config from './../../../../../config';

export default class PaymentOrderRow extends Component {

    getStatusClass(status) {
        switch (status) {
            default:
                console.warn("Wrong status " + status);
                //fallthrough
            case PAYMENT_STATUS_NO_STATUS:
                return 'no-status';

            case PAYMENT_STATUS_PENDING:
                return 'pending';

            case PAYMENT_STATUS_PAID:
                return 'paid';

            case PAYMENT_STATUS_DECLINED:
                return 'declined';
        }
    }

    getStatusTitle(status) {
        switch (status) {
            default:
                console.warn("Wrong status " + status);
                //fallthrough
            case PAYMENT_STATUS_NO_STATUS:
                return 'no status';

            case PAYMENT_STATUS_PENDING:
                return 'pending';

            case PAYMENT_STATUS_PAID:
                return 'paid';

            case PAYMENT_STATUS_DECLINED:
                return 'declined';
        }
    }

    getActions() {
        let expirationTimestamp = moment(this.props.create)
            .add(config.paymentOrderExpireDays, 'days')
            .unix();

        let nowTimestamp = moment().unix();

        if (nowTimestamp > expirationTimestamp) {
            return [];
        }

        let daysLeft = Math.round((expirationTimestamp - nowTimestamp) / 24 / 60 / 60);

        return [{
            title: `${daysLeft} days left`,
            onClick: () => {
                this.props.onPay(this.props.orderId)
            }
        }]

    }

    render() {

        return (
            <div>
                <Row className="fr-table-content fr-table-centred__bordered">
                    <Col xsHidden={true} smHidden={true} md={1}>
                        <Badge
                            className={"fr-apt-" + this.getStatusClass(this.props.status)}>{this.getStatusTitle(this.props.status)}</Badge>
                    </Col>
                    <Col xs={1} mdHidden={true} lgHidden={true}>
                        <span className={"fr-apt-span__" + this.getStatusClass(this.props.status)}>&bull;</span>
                    </Col>

                    <Col xs={4} md={2} className="fr-apt-cell">
                        <span>
                            {printDate(this.props.date, 'MMM DD, YYYY')}
                        </span>
                    </Col>

                    <Col xsHidden={true} smHidden={true} md={3} className="fr-apt-cell">
                        <span>{this.props.services.join(', ')}</span>
                    </Col>

                    <Col xs={4} md={3} className="fr-apt-cell">
                        <span>{this.props.salonName}</span>
                    </Col>
                    <Col md={3} xs={3}>
                        <ul className="fr-pl0">
                            {this.getActions().map((action, i) => {

                                return <li key={i} className="action" style={{listStyle: 'none'}}>
                                    <PaymentOrderAction {...action} />
                                </li>

                            })}
                        </ul>
                    </Col>
                </Row>
            </div>
        )
    }
}

PaymentOrderRow.propTypes = {
    orderId: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    create: PropTypes.string.isRequired,
    services: PropTypes.array.isRequired,
    salonName: PropTypes.string.isRequired,
    onPay: PropTypes.func
};
