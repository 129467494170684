/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 12.4.17
 * Time: 6.53
 */

export default function drawTime(seconds) {
    if (seconds < 3600) {
        let minutes = Math.floor(seconds / 60);

        return minutes + "m";
    }
    else {
        let hours = seconds / 3600;
        let hoursInt = Math.floor(hours);
        let minutesInt = Math.floor((seconds - hoursInt * 3600) / 60);

        return hoursInt + "h" + (minutesInt ? " " + minutesInt + "m" : "");
    }
}
