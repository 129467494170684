/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 27.10.17
 */

import React from 'react';
import PropTypes from 'prop-types';

export class DurationShadowElement extends React.Component {


    render() {

        let style = {
            top: 0,
            left: 0,
            zIndex: 1,
            height: this.props.height
        };


        let classList = ['duration-shadow-element'];
        if (this.props.config.classes && Array.isArray(this.props.config.classes)) {
            classList = classList.concat(this.props.config.classes);
        }

        classList = classList.concat(this.props.extraClasses);

        return (
            <div
                draggable={false}
                data-id={this.props.uniqueId}
                style={style}
                className={classList.join(' ')}
            />
        )
    }
}

DurationShadowElement.propTypes = {
    config: PropTypes.object,
    uniqueId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    extraClasses: PropTypes.array
};

DurationShadowElement.defaultProps = {
    config: {
        classes: [],
        style: {
            backgroundColor: 'green',
        }
    },
    extraClasses: []
};
