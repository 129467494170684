/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 27.03.2017
 * Time: 15:45
 */
import React, { Component } from "react";
import { Form, FormControl, Button, Glyphicon } from "react-bootstrap";

export default class ClientsFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: ""
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onReset = this.onReset.bind(this);
    }

    handleFilterChange(e) {
        this.setState({
            searchString: e.target.value
        });

    }

    searchClient(ev) {
        ev.preventDefault();
        this.props.clientsFilter(this.state.searchString);
    }

    onReset(e) {
        e.preventDefault();
        this.setState({
            searchString: ""
        });
        this.props.clientsFilter("");
    }

    render() {

        return (
            <div>
                <Form inline className="fr-clients__filter" onSubmit={this.searchClient.bind(this)}>
                    <div className="input-group">
                        <FormControl className="fr-rounded" componentClass="input" placeholder="Name or phone"
                                     value={this.state.searchString} onChange={this.handleFilterChange}
                        />
                        <span className="input-group-btn">
                            {this.state.searchString !== "" &&
                            <Button onClick={this.onReset} type="button"
                                    className="fr-clients__reset fr-btn pull-right">
                                <span className="glyphicon glyphicon-remove"></span></Button>}
                             <Button type="submit" className="fr-pr0 btn-link">
                                <span className="fr-glyphicon" type="submit"><Glyphicon
                                    glyph="search"/></span>
                            </Button>
                          </span>
                    </div>
                </Form>
            </div>
        );
    }
}
