/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.11.17
 * Time: 14.50
 */

import type { TGalleryImage } from "../../../types";
import { GALLERY_ITEM_TYPE_AVATAR } from "../../../frizo-shared-constants";
import type { TRawUser } from "../../raw-types";
import { artistNormalizer1 } from "../../user-derived-forms/artist";

export function singleAvatarNormalizer(rawArtist: TRawUser): TGalleryImage {

    let artist = artistNormalizer1(rawArtist);

    return {
        id: artist.id,
        image: artist.image,
        salon: artist.salon,
        artist: artist,
        galleryItemType: GALLERY_ITEM_TYPE_AVATAR
    };
}
