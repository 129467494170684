/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.12.4
 * Time: 10:57
 */

import AppDispatcher from "../../dispatcher";
import {
    xhrArtistAddEdit,
    xhrLoadSalonArtists,
    xhrRemoveArtist,
    xhrSendArtistInvitation
} from "../../xhr/profile-artists-xhr";
import {
    ARTIST_ADDED,
    ARTIST_INVITATION_SENT,
    ARTIST_LIST_LOADED,
    ARTIST_REMOVED,
    ARTIST_SAVE_ERROR,
    ARTIST_SERVICES_LOADED,
    PROFILE_ARTIST_SALON_INFO_LOADED,
    PROFILE_ARTIST_SERVICE_ADDED,
    PROFILE_ARTIST_SERVICE_MODIFIED,
    PROFILE_ARTIST_SERVICE_REMOVED,
    RAW_SERVICES_LOADED,
    UPDATE_ARTIST_FORM
} from "../../constants";
import { xhrLoadNameAndLocation } from "../../xhr/my-salon-xhr";
import { xhrLoadArtistInfo, xhrLoadServices } from "../../xhr/services-xhr";
import xhrModifyArtistService from "../../xhr/profile-artist-xhr";

import { notifyFail, notifySuccess } from "./../notify-actions";

import { profileArtistServicesStore } from "../../stores";

import { artistNormalizerLegacy } from "../../normal-form/user-derived-forms/artist";

import notificationSystem from "./../../service/notification-system";

export const loadArtistsSalonInfo = () => {
    xhrLoadNameAndLocation().then(data => {
        AppDispatcher.dispatch({
            type: PROFILE_ARTIST_SALON_INFO_LOADED,
            payload: {
                salonInfo: data
            }
        });
    });
};

export const loadArtistServicesAction = (artistId = 0) => {
    xhrLoadNameAndLocation().then(data => {
        let salonId = data.id;
        let artists = [];
        if (artistId) {
            artists.push(artistId);
        }

        xhrLoadServices(salonId, artists).then(answer => {

            AppDispatcher.dispatch({
                type: ARTIST_SERVICES_LOADED,
                payload: answer
            });

            return Promise.resolve(answer);

        }, answer => {
            notifyFail("Unable to load services");

            return Promise.resolve(answer);

        });
    });
};

export const loadArtistInfoAction = (artistId) => {

    xhrLoadArtistInfo(artistId).then(answer => {
        let { firstName, lastName, username: email, phone, id, activated, invitationStatus, instagramUsername } = answer;
        let imageId = "";
        let imageURL = "";
        instagramUsername = instagramUsername ? instagramUsername : "";

        if (answer.image) {
            imageId = answer.image.id;
            imageURL = answer.image.url + "-medium.jpg";
        }


        const payload = {
            firstName,
            lastName,
            email,
            phone,
            instagramUsername: instagramUsername,
            image: { id: imageId, url: imageURL },
            id,
            activated,
            invitationStatus
        };
        AppDispatcher.dispatch({
            type: ARTIST_ADDED,
            payload: payload
        });
    });
};

export const getSalonArtistAction = (salonId, limit, offset, action) => {

    xhrLoadSalonArtists(salonId, limit, offset).then(answer => {
            AppDispatcher.dispatch({
                type: ARTIST_LIST_LOADED,
                payload: {
                    galleryData: answer,
                    action
                }
            });
        }
    );

};

export function updateArtistFormAction(patch) {

    AppDispatcher.dispatch({
        type: UPDATE_ARTIST_FORM,
        payload: patch
    });
}

export const addArtistAction = () => {

    let validationResult = profileArtistServicesStore.fluxValidate();
    if (validationResult !== true) {
        return new Promise((_, fail) => {
            fail(validationResult);
        });
    }

    let payload = Object.assign({}, profileArtistServicesStore.getStore().artistInfo);

    if (payload.image && payload.image.id && payload.image.url) {
        payload.imageId = payload.image.id;
        payload.imageURL = payload.image.url;
        delete payload.image;
    }

    if (payload.id) {
        payload.userID = payload.id;
        delete payload.id;
    }

    payload.username = payload.email;
    delete payload.email;
    return xhrArtistAddEdit(payload).then(response => {

        let artist = artistNormalizerLegacy(response);

        if (artist.imageURL) {
            artist.imageURL += "-large.jpg";
        }

        AppDispatcher.dispatch({
            type: ARTIST_ADDED,
            payload: artist
        });


        notifySuccess();

    }, (request: Response) => {

        let { response } = request;

        AppDispatcher.dispatch({
            type: ARTIST_SAVE_ERROR,
            payload: response
        });

        return Promise.reject(response);
    });
};

export const removeArtistAction = (artistId) => {

    return xhrRemoveArtist(artistId).then(answer => {
        AppDispatcher.dispatch({
            type: ARTIST_REMOVED,
            payload: artistId
        });
    }, answer => {
        if (answer.errorKey === "profile.artists.cannot_remove_artist_upcoming_apts") {
            notificationSystem.alert(answer.message);
        }
        else {
            notifyFail(answer.message || "Cannot remove artist");
        }
    });
};

export const removeArtistServiceAction = (artistId, serviceId) => {

    const payload = JSON.stringify({ "remove": [{ "serviceID": serviceId }] });

    xhrModifyArtistService(artistId, payload).then(answer => {
        AppDispatcher.dispatch({
            type: PROFILE_ARTIST_SERVICE_REMOVED,
            payload: serviceId
        });

        notifySuccess();
    }, answer => {
        let [response] = answer;
        notifyFail(response.message);
    });
};

export const addArtistServiceAction = (artistId, service) => {

    const payload = JSON.stringify({ "new": [{ "serviceID": service.id, "individualPrice": service.price }] });

    xhrModifyArtistService(artistId, payload).then(answer => {
        AppDispatcher.dispatch({
            type: PROFILE_ARTIST_SERVICE_ADDED,
            payload: service
        });

        notifySuccess();
    }, answer => {
        let [response] = answer;
        notifyFail(response.message);
    });
};

export const modifyArtistServiceAction = (artistId, service) => {

    const payload = JSON.stringify({ "edit": [{ "serviceID": service.id, "individualPrice": service.price }] });

    xhrModifyArtistService(artistId, payload).then(answer => {
        AppDispatcher.dispatch({
            type: PROFILE_ARTIST_SERVICE_MODIFIED,
            payload: service
        });

        notifySuccess();
    }, answer => {
        let [response] = answer;
        notifyFail(response.message);
    });
};

export const loadRawServices = (salonId) => {
    xhrLoadServices(salonId).then(answer => {

        AppDispatcher.dispatch({
            type: RAW_SERVICES_LOADED,
            payload: answer
        });
    });
};

export const sendInvitation = (artistId) => {
    xhrSendArtistInvitation(artistId).then(answer => {
        AppDispatcher.dispatch({
            type: ARTIST_INVITATION_SENT,
            payload: answer
        });
    });
};