/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.20.4
 * Time: 15:46
 */
import React, {Component} from 'react';
import TableRowArtistEdit from './table-row-artist-edit';
import TableRowArtistStatic from './table-row-artist-static';

export default class TableRowArtist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            staticView: this.props.staticView,
            assigned: this.props.assigned
        };

        this.toggleView = this.toggleView.bind(this);
    }

    toggleView() {
        this.setState({staticView: !this.state.staticView});
    }

    componentWillReceiveProps(nextProps) {
        this.setState({staticView: nextProps.staticView, assigned: nextProps.assigned})
    }

    render() {
        if (this.state.staticView) {

            return <TableRowArtistStatic actions={this.props.actions} toggleView={this.toggleView} {...this.state} {...this.props} />
        }

        return <TableRowArtistEdit actions={this.props.actions} toggleView={this.toggleView} {...this.state} {...this.props} />

    }

}

TableRowArtist.defaultProps = {
    staticView: false,
    assigned: false,
    actions: true
};