/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 5.12.17
 * Time: 8.13
 */

import BaseStore from "./base-store";
import {
    GALLERY_FAVORITE_ADDED,
    GALLERY_FAVORITE_REMOVED,
    LOAD_SESSION_SUCCESS,
    PROFILE_FAVORITES_LOADED,
    USER_AUTH,
    USER_FAVORITE_ADDED,
    USER_FAVORITE_REMOVED,
    USER_LOGOUT
} from "../constants";
import arrayToHash from "../helper/array-to-hash";


export default class FavoritesStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.setMaxListeners(999);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case LOAD_SESSION_SUCCESS:
                case USER_AUTH:
                case USER_LOGOUT:
                    this.waitFor(this.stores.session.getDispatchToken());
                    let enabled = this.stores.session.getStore().session.isLogged;
                    if (enabled === this.store.enabled) {
                        return true;
                    }

                    this.store.enabled = enabled;
                    this.store.ready = false;

                    break;

                case PROFILE_FAVORITES_LOADED:

                    if (payload.galleryItemFavorite) {
                        this.store.galleryFavorites = arrayToHash(payload.galleryItemFavorite,
                            element => [element.id, true]);
                    }

                    if (payload.userFavorite) {
                        this.store.artistsFavorites = arrayToHash(payload.userFavorite, element => [element.id, true]);
                    }
                    this.store.ready = true;
                    break;


                case GALLERY_FAVORITE_ADDED:
                    this.store.galleryFavorites[payload] = true;
                    this.store.linkDisabled = false;
                    break;

                case GALLERY_FAVORITE_REMOVED:
                    this.store.galleryFavorites[payload] = false;
                    this.store.linkDisabled = false;

                    break;

                case USER_FAVORITE_ADDED:
                    this.store.artistsFavorites[payload] = true;
                    this.store.linkDisabled = false;
                    break;

                case USER_FAVORITE_REMOVED:
                    this.store.artistsFavorites[payload] = false;
                    this.store.linkDisabled = false;
                    break;


                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialStore() {
        return {
            enabled: this.stores.session.getStore().session.isLogged,
            galleryFavorites: {},
            artistsFavorites: {},
            linkDisabled: false,
            ready: false
        };
    }
}
