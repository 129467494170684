/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 26.02.2018
 * Time: 17:44
 */

import React, { Component } from "react";
import { Button, Col } from "react-bootstrap";
import config from "../config";

export default class SmartBanner extends Component {

    constructor(props) {
        super(props);


        this.state = {
            visible: false,
            closed: false
        };

        this.onScrollResizeHandler = this.onScrollResizeHandler.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onScrollResizeHandler);
        window.addEventListener("resize", this.onScrollResizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScrollResizeHandler);
        window.removeEventListener("resize", this.onScrollResizeHandler);
    }


    onScrollResizeHandler() {
        let footerTop = document.querySelector("#footer").getBoundingClientRect().top;
        this.setState({ visible: footerTop > window.innerHeight });
    }

    onClose() {
        this.setState({ closed: true });
    }

    render() {
        let params = config.defaultFooter;

        if (!this.state.closed) {
            return (<div className="fr-banner-bottom" style={{ "display": this.state.visible ? "" : "none" }}>
                <Col xs={12} md={6}>
                    <div className="fr-banner-bottom__text">Get app for best experience</div>
                </Col>
                <Col xs={12} md={6}>
                    <a className="fr-iosicon " href={params.appstore.url}
                       target="_blank" rel="noopener noreferrer"
                       title={params.appstore.title}
                       alt={params.appstore.title}>
                    </a>
                    <a className=" fr-google-icon" href={params.googlePlay.url}
                       target="_blank" rel="noopener noreferrer"
                       title={params.googlePlay.title}
                       alt={params.googlePlay.title}>
                    </a>
                </Col>
                <div className="fr-banner-bottom__close">
                    <Button className="close" onClick={this.onClose}>
                        <span>×</span>
                    </Button>
                </div>
            </div>);
        }
        else {
            return false;
        }
    }
}
