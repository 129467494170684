/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.6.10
 * Time: 11:00
 */

import {Component} from 'react';
import {updateRegistrationData, validationFail} from '../../ac/sign-up';

export default class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.resetRecaptcha = this.resetRecaptcha.bind(this);
        this.verifyReCaptureCallback = this.verifyReCaptureCallback.bind(this);
        this.process = this.process.bind(this);
        this.filter = this.filter.bind(this);
        this.validate = this.validate.bind(this);
        this.save = this.save.bind(this);
        this.toggleFormDisable = this.toggleFormDisable.bind(this);
    }


    /**
     * Method to process form
     * use as Save button click event handler
     */
    process(e) {
        e.preventDefault();
        this.toggleFormDisable(true);
        this.filter().then(filtred => {
            this.setState(filtred, () => {
                    this.validate().then(this.save, (data) => {
                        validationFail(data);
                        this.resetRecaptcha();
                    })
                }
            )
        })
    }

    /**
     * Method to validate data
     * before sending
     */
    validate() {
        let validRecaptcha = this.state.recaptchaResponse.length > 0;

        let validator = this.buildValidation();
        // let obj = validation(validator, this.state);
        let violations = validator.validate(this.state);

        return new Promise((resolve, reject) => {
            if ((violations === true) && validRecaptcha) {
                resolve();
            }
            else {
                reject({
                    formIsValid: false,
                    violations: violations,
                    showRecaptchaError: !validRecaptcha,
                    formDisabled: false
                });
            }
        });

    }

    /**
     * Method to apply any filter function before
     * validation
     */
    filter() {
        return Promise.resolve();
    }


    /**
     * Method to save form data on server
     */
    save() {

    }

    /**
     * Method call when initialize/mount
     * registration component
     */
    init() {

    }

    /**
     *
     * @param response
     */
    verifyReCaptureCallback(response) {
        updateRegistrationData({
            recaptchaResponse: response,
            showRecaptchaError: false
        });
    }

    onLoadReCaptchaCallback() {   //required!
        // console.log('Done !!');
    }

    resetRecaptcha() {
        this.refs.recaptchaInstance.reset();
    };

    render() {
        return null;
    }

    toggleFormDisable(disabled) {
        this.setState({formDisabled: disabled});
    }

}