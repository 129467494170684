import {
    DASHBOARD_FILTER_SERVICE_TREE,
    DASHBOARD_WHEN_DATE_CHANGE,
    DASHBOARD_WHEN_DATE_RESET,
    DASHBOARD_WHEN_TIME_INTERVAL_CHANGE,
    DASHBOARD_WHERE_HINT_LIST_LOADED,
    DASHBOARD_WHERE_HINT_SELECT,
    DASHBOARD_WHERE_INPUT_CHANGE,
    DASHBOARD_WHERE_IS_NEAREST_CHANGE,
    DASHBOARD_WHO_HINT_CHANGE,
    DASHBOARD_WHO_HINT_LIST_LOADED,
    DASHBOARD_WHO_INPUT_CHANGE
} from "./../constants";
import AppDispatcher from "./../dispatcher";
import ApiClient from "./../service/api-client";
import { xhrWhoHintInfo } from "../xhr/dashboard-who-xhr";
import { xhrGetWhereHintList } from "../xhr/dashboard-where-xhr";
// TODO: check if we need this constants
// import {
//     GALLERY_LAYOUT_GROUPED,
//     GALLERY_LAYOUT_SIMPLE,
//
//     ARTISTS_BY_SALON,
//     IMAGES_BY_ARTIST_BY_SALON
// } from './../frizo-shared-constants';

export function getServiceTree() {
    ApiClient.get(["Q3_service_tree_v1"]).then((data) => {
        AppDispatcher.dispatch({
            type: DASHBOARD_FILTER_SERVICE_TREE,
            payload: data[0].response
        });
    });
}

export function getWhoHintList() {
    xhrWhoHintInfo().then(answer => {
        AppDispatcher.dispatch({
            type: DASHBOARD_WHO_HINT_LIST_LOADED,
            payload: answer
        });
    });
}

export function whoInputChange(val) {
    AppDispatcher.dispatch({
        type: DASHBOARD_WHO_INPUT_CHANGE,
        payload: val
    });
}

export function whoHintChange(val) {
    AppDispatcher.dispatch({
        type: DASHBOARD_WHO_HINT_CHANGE,
        payload: val
    });
}

export function whereInputChange(val) {
    AppDispatcher.dispatch({
        type: DASHBOARD_WHERE_INPUT_CHANGE,
        payload: val
    });
}

export function isNearestCheckedChange(val) {
    AppDispatcher.dispatch({
        type: DASHBOARD_WHERE_IS_NEAREST_CHANGE,
        payload: val
    });
}

export function getWhereHintList(val) {
    xhrGetWhereHintList(val).then(answer => {
        AppDispatcher.dispatch({
            type: DASHBOARD_WHERE_HINT_LIST_LOADED,
            payload: answer
        });
    });
}

export function whereHintSelect(val) {
    AppDispatcher.dispatch({
        type: DASHBOARD_WHERE_HINT_SELECT,
        payload: val
    });
}


export function whenTimeIntervalChange(val) {
    AppDispatcher.dispatch({
        type: DASHBOARD_WHEN_TIME_INTERVAL_CHANGE,
        payload: val
    });
}

export function whenDateChange(val) {
    AppDispatcher.dispatch({
        type: DASHBOARD_WHEN_DATE_CHANGE,
        payload: val
    });
}

export function whenDateReset(val) {
    AppDispatcher.dispatch({
        type: DASHBOARD_WHEN_DATE_RESET,
        payload: val
    });
}

