/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.3.11
 * Time: 15:05
 */

import React, {Component} from 'react';
import PhoneInput from '../../elements/phone-input';
import  WithHintDecorator from '../../decorators/with-hint-decorator';
import Violation from '../helper-components/violation';


class ClientPhoneInput extends Component {

    constructor(props) {
        super(props);
        this.onPhoneChange = this.onPhoneChange.bind(this);
    }

    onPhoneChange(obj) {
        this.props.change({field: 'phone', value: obj.target.rawValue});
        this.props.onValueChange(obj.target.rawValue);
    }

    render() {
        return (<div>
            <PhoneInput
                ref={"masker"}
                className={"form-control"}
                type={"text"}
                name={"phone"}
                value={this.props.phone}
                mask={"(111) 111-1111"}
                onChange={this.onPhoneChange}
                readOnly={this.props.readOnly}
                placeholder={"Enter сlient’s phone"}
                autoComplete={"off"}
            />
            <Violation violationSet={this.props.violations.phone} visible={this.props.showViolations}/>
        </div>);
    }
}

export default WithHintDecorator(ClientPhoneInput);