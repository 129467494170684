/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 25.7.17
 */

import { FILTER_PERSONAL_PAYMENT_ORDERS, LOAD_PERSONAL_PAYMENT_ORDERS } from "../constants";

import BaseStore from "./base-store";

export default class PersonalPaymentOrdersStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;
            switch (type) {


                case LOAD_PERSONAL_PAYMENT_ORDERS:
                    this.store.orders = payload.paymentOrders;
                    this.filterOrders();

                    break;

                case FILTER_PERSONAL_PAYMENT_ORDERS:
                    this.store.searchString = payload.searchString;
                    this.filterOrders();

                    break;


                default:
                    return true;
            }

            this.emitChange();
        });

    }

    getInitialStore() {
        return {
            orders: [],
            searchString: "",
            filteredOrders: []
        };
    }

    filterOrders() {

        let searchString = this.store.searchString.toLowerCase();

        this.store.filteredOrders = this.store.orders.filter(order => {

            let salonName = order.salonName.toLowerCase();

            return salonName.indexOf(searchString) !== -1;
        });
    }
}
