/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 13.29
 */


import React, {Component} from 'react';
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type {TGalleryImage} from "../../../types";

type Props = TGalleryImage & {};

export default class SalonPromoBrandItem extends Component<Props, any> {

    render() {

        return <GalleryItemContainer>
            <GalleryItemImage {...this.props} blockInflection={true}/>
            <GalleryItemCardInfo>

            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}
