/**
 * Created by fliak on 23.2.17.
 */

export default function strtr(str, replacePairs) {

    for (let key in replacePairs) {
        if (replacePairs.hasOwnProperty(key)) {
            let re = new RegExp(key, "g");
            str = str.replace(re, replacePairs[key]);
        }
    }

    return str;
}
