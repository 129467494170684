/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.7.17
 * Time: 18.11
 */

import ApiClient from "./../../../service/api-client";
import { renewToken } from "../../../auth";

import { CUSTOM_HEADER_U6_PAGINATION } from "../../../frizo-shared-constants";

export function xhrCreatePaymentProfile(data) {
    let url = ApiClient.getRouting().generate("Q107_create_stripe_managed_account");

    return ApiClient.post(url, data).then(answer => {
        let [response] = answer;

        return Promise.resolve(response);
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}

export function xhrUpdatePaymentProfile(data) {
    let url = ApiClient.getRouting().generate("Q108_modify_stripe_managed_account");

    return ApiClient.put(url, data).then(answer => {
        let [response] = answer;

        return Promise.resolve(response);
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}

export function xhrGetCustomerCharges(params) {
    let url = ApiClient.getRouting().generate("Q156_get_customer_charges", params);


    return ApiClient.get(url, params).then(answer => {
        let [response] = answer;

        return Promise.resolve(response);
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}

export function xhrCancelSubscription() {
    let url = ApiClient.getRouting().generate("Q151_cancel_subscription");

    return ApiClient.del(url).then(answer => {
        let [response] = answer;

        return Promise.resolve(response);
    });
}

export function xhrGetPaidTransactions(params) {
    let url = ApiClient.getRouting().generate("Q159_get_payment_transfers");

    return ApiClient.get(url, params, {
        headers: {
            [CUSTOM_HEADER_U6_PAGINATION]: 1
        }
    }).then(answer => {
        let [response] = answer;

        return Promise.resolve(response);
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}

export function xhrUploadLegalDocument(image) {
    let formData = new FormData();
    formData.append(
        "legalDoc",
        image,
        image.name
    );
    let xhr = new XMLHttpRequest();

    let url = ApiClient.getRouting().generate("Q122_upload_legal_document", {});
    xhr.open("POST", url, true);

    return renewToken().then(({ success }) => {
        if (success) {
            xhr.send(formData);
        }
    });
}

export function xhrGetCustomAccountPersons(data) {
    let url = ApiClient.getRouting().generate("Q107_create_stripe_managed_account");

    return ApiClient.post(url, data).then(answer => {
        let [response] = answer;

        return Promise.resolve(response);
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}