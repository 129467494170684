/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.3.4
 * Time: 11:29
 */

import React, {Component} from 'react';

const toggleListDecorator = (ParentComponent) => {

    return class ToggleList extends Component {
        constructor(props) {
            super(props);

            this.state = {
                open: false
            };

            this.toggleOpen = this.toggleOpen.bind(this);
        }

        componentDidMount(){
            if (this.props.first){
                this.setState({open:true});
            }
        }

        toggleOpen() {
            this.setState({
                    open: !this.state.open
                }
            );
        }

        render(){
            return <ParentComponent {...this.state} {...this.props} toggleOpen = {this.toggleOpen}/>
        }
    }
};

export default toggleListDecorator;