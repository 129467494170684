/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 16.33
 */

import { IMAGE_SIZE_MEDIUM } from "../frizo-shared-constants";

export function imageUrl(baseImageUrl, size = IMAGE_SIZE_MEDIUM) {
    if (baseImageUrl.substr(-4) !== ".jpg") {
        return baseImageUrl + "-" + size + ".jpg";
    }

    return baseImageUrl;
}
