/**
 * User: Zakhar Shushkevich
 * Company: U6 SIA
 * Date: 02.01.2017
 * Time: 14:48
 */

import { LOAD_FILTERED_GALLERY_ITEMS, LOAD_IMAGES_START, LOAD_IMAGES_SUCCESS } from "../constants";
import BaseStore from "./base-store";

class ImageStore extends BaseStore {

    constructor(...args) {
        super(...args);
        this.items = [];
        this.currentItem = {};
        this.type = 1;

        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case LOAD_IMAGES_START:
                    this.loading = true;

                    break;

                case LOAD_IMAGES_SUCCESS:
                    this.loading = false;

                    break;

                case LOAD_FILTERED_GALLERY_ITEMS:
                    this.loading = false;
                    this.items = payload.items;
                    this.type = payload.type;

                    return true;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    setCurrentImage(item) {
        this.currentItem = this.items.find(x => x.galleryItemID === item.galleryItemID);
        this.emitChange();

    }
}

export default ImageStore;
