/**
 * Created by x_a_x_a on 12.01.2017
 * User: Eleonora Kolodinskaya
 * Company: U6 SIA
 */

import { USER_AUTH } from "../constants";
import AppDispatcher from "../dispatcher";
import { CUSTOM_HEADER_U6_GROUPS } from "./../frizo-shared-constants";
import { sessionNormalize } from "../normal-form/user-derived-forms/session";
import { xhr } from "../service/api-client-2";
import { alterAuthRequest } from "../auth";
import { Request } from "../service/api-client-2/request";
import { events } from "../auth/events";

export function sendLoginData(data) {

    return xhr()
    .skipAuth()
    .suppressNotifications()
    .post("@QN1_auth_login")
    .data(data)
    .alterWith(alterAuthRequest)
    .header(CUSTOM_HEADER_U6_GROUPS, "userSalon")
    .end()
    .then((request: Request) => {
        let { response, xhr } = request;

        let groups = request.getRequestHeader(CUSTOM_HEADER_U6_GROUPS).split(",");

        let session = sessionNormalize(response.response);

        let payload = {
            session: session,
            response: response.response,
            authorized: true,
            request: xhr,
            options: { groups }
        };


        events.renewingFinished.dispatch(true, session, {
            groups,
            request
        });

        events.login.dispatch(true, session, {
            groups,
            request
        });


        return Promise.resolve(payload);

    }, (request: Request) => {
        //console.log('request2', request);
        let { response, xhr } = request;

        let groups = request.getRequestHeader(CUSTOM_HEADER_U6_GROUPS);

        return Promise.resolve({
            session: null,
            response: response.response,
            authorized: false,
            problemKey: response.errorKey,
            problem: response.message,
            request: xhr,
            options: { groups: groups.split(",") }
        });

    }).then(payload => {

        AppDispatcher.dispatch({
            type: USER_AUTH,
            payload: payload
        });

        return Promise.resolve(payload);

    });

}