/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.11.17
 * Time: 15.30
 */

import {
    xhrGetCurrentUserFavouriteArtists,
    xhrGetCurrentUserFavouriteGalleryItems,
    xhrGetCurrentUserFavourites
} from "../../xhr/get-current-user-xhr";
import AppDispatcher from "../../dispatcher/index";
import {
    GALLERY_FAVORITE_ADDED,
    GALLERY_FAVORITE_REMOVED,
    PROFILE_FAVORITES_LOADED,
    USER_FAVORITE_ADDED,
    USER_FAVORITE_REMOVED
} from "../../constants";
import {
    xhrAddGalleryFavorite,
    xhrAddUserFavorite,
    xhrRemoveGalleryFavorite,
    xhrRemoveUserFavorite
} from "../../xhr/favorites-xhr";
import { GALLERY_ITEM_TYPE_AVATAR, GALLERY_ITEM_TYPE_LOOK } from "../../frizo-shared-constants";

export function loadAllFavorites() {
    xhrGetCurrentUserFavourites().then(response => {
        AppDispatcher.dispatch({
            type: PROFILE_FAVORITES_LOADED,
            payload: response
        });
    });
}

export function loadArtistFavorites() {
    xhrGetCurrentUserFavouriteArtists().then(response => {
        AppDispatcher.dispatch({
            type: PROFILE_FAVORITES_LOADED,
            payload: response
        });
    });
}

export function loadGalleryFavorites() {
    xhrGetCurrentUserFavouriteGalleryItems().then(response => {
        AppDispatcher.dispatch({
            type: PROFILE_FAVORITES_LOADED,
            payload: response
        });
    });
}

export function favoriteMarkSet(entityId, type: FAVORITE_TYPES) {
    switch (type) {
        case GALLERY_ITEM_TYPE_LOOK:
            xhrAddGalleryFavorite(entityId).then(answer => {
                AppDispatcher.dispatch({
                    type: GALLERY_FAVORITE_ADDED,
                    payload: entityId
                });
            });

            break;

        case GALLERY_ITEM_TYPE_AVATAR:
            xhrAddUserFavorite(entityId).then(answer => {
                AppDispatcher.dispatch({
                    type: USER_FAVORITE_ADDED,
                    payload: entityId
                });
            });

            break;

        default:
            throw new Error("Unsupported entity type");

    }

}

export function favoriteMarkUnSet(entityId, type: FAVORITE_TYPES) {
    switch (type) {
        case GALLERY_ITEM_TYPE_LOOK:
            xhrRemoveGalleryFavorite(entityId).then(answer => {
                AppDispatcher.dispatch({
                    type: GALLERY_FAVORITE_REMOVED,
                    payload: entityId
                });
            });

            break;

        case GALLERY_ITEM_TYPE_AVATAR:
            xhrRemoveUserFavorite(entityId).then(answer => {
                AppDispatcher.dispatch({
                    type: USER_FAVORITE_REMOVED,
                    payload: entityId
                });
            });

            break;

        default:
            throw new Error("Unsupported entity type");

    }

}