/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.1.18
 *
 * @flow
 */
import { xhr } from "../service/api-client-2";
import { CUSTOM_HEADER_U6_AUTH, CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";
import { RequestBuilder } from "../service/api-client-2/request-builder";
import { sessionNormalize } from "../normal-form/user-derived-forms/session";
import { Request } from "../service/api-client-2/request";

import config from "./../config";
import { notifyFail } from "../ac/notify-actions";
import { browserHistory } from "react-router";
import { events } from "./events";

let baseDataGroups = ["auth", "basic", "discount"];
let dataGroups = [];
let renewInProgressPromise = null;

let warmed = false;


export function assertSession() {
    if (!warmed) renewToken();
}

export function updateSessionData() {
    return renewToken();
}

export function renewToken() {

    if (!renewInProgressPromise) {

        events.renewingStarted.dispatch();

        renewInProgressPromise = xhr()
        .skipAuth()
        .post("@QN2_auth_renew", { "expose": "expose" })
        .alterWith(alterAuthRequest)
        .end()
        .then(request => {
            renewInProgressPromise = null;

            let groups = request.getDataGroups();

            if (request.xhr.getResponseHeader(CUSTOM_HEADER_U6_AUTH) === config.auth_problems.token_missing) {

                //fail to renew token

                events.renewingFinished.dispatch(false, request.response.response, {
                    groups,
                    request
                });

                return Promise.resolve({
                    success: false,
                    groups,
                    request
                });
            }


            let session = sessionNormalize(request.response.response);

            events.renewingFinished.dispatch(true, session, {
                groups,
                request
            });

            return Promise.resolve({
                success: true,
                session, groups,
                request
            });


        }, request => {
            renewInProgressPromise = null;

            return Promise.reject(request);

        }).catch(requestOrError => {
            console.warn("Renew exception", requestOrError);

            notifyFail("Problem with connection");

            return Promise.resolve({
                success: false
            });


        }).then(data => {
            warmed = true;

            return Promise.resolve(data);
        });
    }

    return renewInProgressPromise;
}

export function alterAuthRequest(requestBuilder: RequestBuilder, groups: Array<string> = []) {

    let unitedGroups = baseDataGroups.concat(dataGroups).concat(groups);
    //console.log('unitedGroups', unitedGroups);

    requestBuilder
    .header(CUSTOM_HEADER_U6_GROUPS, unitedGroups.join(","))
    .header(config.api_mode_header, "auth-2");
}

export function setDataGroups(groups: Array<string>) {
    dataGroups = groups;
}

export function getDataGroups() {
    return dataGroups;
}

export function isRenewing() {
    return !!renewInProgressPromise;
}

export function logout() {

    return xhr()
    .suppressNotifications()
    .get("@Q54_sign_out").end()
    .then((request: Request) => {
        if (!request.response.success) {
            return Promise.reject(request);
        }

        events.logout.dispatch();
        browserHistory.push("/");

    }, (request: Request) => Promise.reject(request))
    .catch((request: Request) => {
        notifyFail("Unable to log out");

        return Promise.reject(request);
    });
}
