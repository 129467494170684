/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.1.18
 */

import AbstractGalleryStore from "../../gallery2/abstract-gallery-store";
import { ARTIST_REMOVED, GALLERY_RESET, LOAD_SESSION_SUCCESS } from "../../../constants";
import { GALLERY2_PROFESSIONAL_PROFILE_ARTIST_ID } from "../../../frizo-shared-constants";
import { loadGallery } from "../../../ac/gallery/generic-gallery-actions";

export default class ProfessionalProfileArtistStore extends AbstractGalleryStore {

    constructor(...args) {
        super(GALLERY2_PROFESSIONAL_PROFILE_ARTIST_ID, ...args);

        this._registerActionSubscription((action) => {
            let { type } = action;

            switch (type) {
                case GALLERY_RESET:
                    this.resetGallery({
                        offset: 0,
                        count: 12,
                        salonId: this.store.gallery.params.salonId
                    });
                    break;

                case LOAD_SESSION_SUCCESS:
                    this.waitFor(this.stores.session.getDispatchToken());
                    let session = this.stores.session.getStore().session;

                    if (session.isLogged && session.salon) {
                        this.store.gallery.params.salonId = session.salon.id;
                    }

                    break;

                case ARTIST_REMOVED:
                    this.reloadGallery();

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialGallery() {
        return Object.assign(super.getInitialGallery(), {
            totalIsKnown: false,
            params: {
                offset: 0,
                count: 12,
                salonId: null
            }
        });
    }

    nextPage() {
        this.store.gallery.params.offset++;
        this.store.gallery.loading = true;
        this.store.gallery.scrollMode = true;

        return loadGallery(this.galleryId, this.store.gallery.params);
    }
}
