/**
 * User: Zakhar Shushkevich
 * Company: U6 SIA
 * Date: 02.01.2017
 * Time: 14:48
 */

import BookAptSalonStore from "./book-apt/salon-store";
import BookAptClientStore from "./book-apt/client-store";
import BookAptStore from "./book-apt/apt-store";
import BookAptConfStore from "./book-apt/conf-store";
import BookAptArtistStore from "./book-apt/artist-store";
import BookAptClientListStore from "./book-apt/client-list-store";

import BookTimelineStore from "./book-apt/book-timeline-store";
import ServicesStore from "./services-store";

import DocsStore from "./legal-store";
import ModalsStore from "./modals-store";
import SessionStore from "./session-store";
import ImageStore from "./images-store";
import ClientsStore from "./clients-store";
import ClientCommentStore from "./client-comment-store";

import SalonPromoStore from "./salon-promo/salon-promo-store";
import PromoGalleryStore from "./promo/promo-gallery-store";
import PromoArtistStore from "./promo/promo-artist-store";
import PromoBrandStore from "./promo/promo-brand-store";

import ProfileProfessionalStore from "./profile-professional-store";
import GallerySelectStore from "./gallery-select-store";

import ProfileServiceStore from "./profile/professional/profile-services-store";
import ProfileArtistServicesStore from "./profile/professional/profile-artist-services-store";
import ProfileArtistStore from "./profile/professional/profile-artist-store";
import ProfileGalleryStore from "./profile/professional/profile-gallery-store";
import ProfessionalProfileBrandStore from "./profile/professional/professional-profile-brand-store";

import PaymentProfileFormStore from "./profile/professional/payment-profile-form-store";
import PaymentProfileStore from "./profile/professional/payment-profile-store";
import PaymentSourceFormStore from "./payment/payment-source-form-store";

import DashboardFilterStore from "./dashboard-filter-store";
import DashboardWhoHintStore from "./dashboard-who-hint-store";
import DashboardWhereStore from "./dashboard-where-store";
import DashboardWhenStore from "./dashboard-when-store";

import PersonalProfileFavoritesItemsStore from "./profile/personal/personal-profile-favorites-items-store";
import PersonalProfileFavoritesArtistsStore from "./profile/personal/personal-profile-favorites-artists-store";
import MyCardsStore from "./profile/personal/my-cards-store";

import SalonAptStore from "./salon-apt-store";
import SalonAptFilterStore from "./salon-apt-filter-store";
import PersonalAptStore from "./personal-apt-store";
import PersonalPaymentOrdersStore from "./personal-payment-orders-store";

import ProfilePersonalEssentialsStore from "./profile/personal/profile-personal-essentials-store";

import GetPaidPopupStore from "./payment-order/get-paid-popup-store";
import PaidApprovalPopupStore from "./payment-approval-popup-store";

import FaqStore from "./faq-store";

import PricingStore from "./pricing-store";

import UnseenAppointmentsStore from "./unseen-appointments-store";

import NotificationsStore from "./notifications-store";

import FeatureFlagStore from "./feature-flag-store";

import ProfileCompletenessStore from "./profile-completeness-store";

import SubscriptionsStore from "./subscriptions-store";


import CalendarMarkersStore from "./calendar-markers-store";

import ClientStreamStore from "./client-stream-store";


import ClientCardsStore from "./client-cards-store";

import ProfileWHStore from "./profile/professional/profile-wh-store";

import ClientSignUpStore from "./sign-up/client-sign-up-store";
import ListYourBusinessStore from "./sign-up/list-your-business-store";

import DashboardGalleryStore from "./gallery2/dashboard-gallery-store";
import GenericGalleryStore from "./gallery2/generic-gallery-store";

import ChangeEmailStore from "./profile/personal/change-email-store";
import ChangePasswordStore from "./profile/personal/change-password-store";
import ProfessionalProfileGalleryStore from "./profile/professional/professional-profile-gallery-store";
import ProfessionalProfileArtistStore from "./profile/professional/professional-profile-artist-store";

import SelectedServiceStore from "./profile/professional/selected-service-store";
import ArtistServiceStore from "./profile/professional/artist-service-store";

import FavoritesStore from "./favorites-store";
import ContactUsStore from "./contact-us-store";

import SalonAddressesStore from "./salon-addresses-store";
import BrandSelectStore from "./profile/professional/brand-select-store";
import ArtistPromoStore from "./artist-promo-store";
import ArtistPromoGalleryStore from "./artist-promo-gallery-store";
import ClientRecommendationStore from "./recommendation/client-recommendation-store";
import ProRecommendationStore from "./recommendation/pro-recommendation-store";


const stores = {};

export const sessionStore = new SessionStore(stores);
export const modalsStore = new ModalsStore(stores);
export const docsStore = new DocsStore(stores);
export const imageStore = new ImageStore(stores);
export const clientsStore = new ClientsStore(stores);
export const clientCommentStore = new ClientCommentStore(stores);

Object.assign(stores, {
    modal: modalsStore,
    session: sessionStore,
    docs: docsStore,
    images: imageStore,
    clients: clientsStore
});

let bookAptStores = {};

bookAptStores.session = sessionStore;

export const bookAptSalonStore = new BookAptSalonStore();
bookAptStores.salon = bookAptSalonStore;

export const bookAptClientStore = new BookAptClientStore();
bookAptStores.client = bookAptClientStore;

export const servicesStore = new ServicesStore(bookAptStores);
bookAptStores.services = servicesStore;

export const bookAptConfStore = new BookAptConfStore(bookAptStores);
bookAptStores.conf = bookAptConfStore;

export const bookAptStore = new BookAptStore(bookAptStores);
bookAptStores.apt = bookAptStore;

export const bookTimelineStore = new BookTimelineStore(bookAptStores);
bookAptStores.timeline = bookTimelineStore;

export const artistStore = new BookAptArtistStore(bookAptStores);
bookAptStores.artist = artistStore;

export const bookAptClientListStore = new BookAptClientListStore(bookAptStores);
bookAptStores.clientList = bookAptClientListStore;

export {
    bookAptStores
};

export const profileServiceStore = new ProfileServiceStore();

export const profileArtistStore = new ProfileArtistStore();
export const profileArtistServicesStore = new ProfileArtistServicesStore();

export const profileGalleryStore = new ProfileGalleryStore();

export const salonPromoStore = new SalonPromoStore();

export const dashboardFilterStore = new DashboardFilterStore();
export const dashboardWhoHintStore = new DashboardWhoHintStore();
export const dashboardWhereStore = new DashboardWhereStore();
export const dashboardWhenStore = new DashboardWhenStore();

export const personalProfileFavoritesItemsStore = new PersonalProfileFavoritesItemsStore();
export const personalProfileFavoritesArtistsStore = new PersonalProfileFavoritesArtistsStore();

export const salonAptStore = new SalonAptStore();
export const salonAptFilterStore = new SalonAptFilterStore();

export default stores;

export const profileProfessionalStore = new ProfileProfessionalStore();
export const paymentProfileFormStore = new PaymentProfileFormStore(stores);
export const paymentProfileStore = new PaymentProfileStore();
export const paymentSourceFormStore = new PaymentSourceFormStore(stores);

export const gallerySelectStore = new GallerySelectStore();

export const personalAptStore = new PersonalAptStore();
export const personalPaymentOrderStore = new PersonalPaymentOrdersStore();

export const profilePersonalEssentialsStore = new ProfilePersonalEssentialsStore(stores);

export const faqStore = new FaqStore();

export const pricingStore = new PricingStore();

export const personalProfileMyCardsStore = new MyCardsStore();
export const unseenAppointmentsStore = new UnseenAppointmentsStore(stores);

export const getPaidPopupStore = new GetPaidPopupStore();
export const paidApprovalPopupStore = new PaidApprovalPopupStore();

export const notificationsStore = new NotificationsStore();

export const featureFlagStore = new FeatureFlagStore();

export const profileCompletenessStore = new ProfileCompletenessStore(stores);
stores["profileCompletenessStore"] = profileCompletenessStore;

export const calendarMarkersStore = new CalendarMarkersStore();

export const clientStreamStore = new ClientStreamStore();

export const subscriptionsStore = new SubscriptionsStore(stores);
export const clientCardsStore = new ClientCardsStore(stores);

export const profileWHStore = new ProfileWHStore({
    artist: artistStore
});

export const clientSignUpStore = new ClientSignUpStore();
export const listYourBusinessStore = new ListYourBusinessStore();


export const dashboardGalleryStore = new DashboardGalleryStore();
export const genericGalleryStore = new GenericGalleryStore("an-id");

export const professionalProfileGalleryStore = new ProfessionalProfileGalleryStore(stores);
export const professionalProfileArtistStore = new ProfessionalProfileArtistStore(stores);
export const professionalProfileBrandStore = new ProfessionalProfileBrandStore(stores);

export const changeEmailStore = new ChangeEmailStore();
export const changePasswordStore = new ChangePasswordStore();

export const favoritesStore = new FavoritesStore({
    session: sessionStore
});

export const selectedServiceStore = new SelectedServiceStore();
export const artistServiceStore = new ArtistServiceStore();

export const promoGalleryStore = new PromoGalleryStore();
export const promoArtistStore = new PromoArtistStore();
export const promoBrandStore = new PromoBrandStore();
export const contactUsStore = new ContactUsStore();
export const salonAddressesStore = new SalonAddressesStore();

export const brandSelectStore = new BrandSelectStore();

export const artistPromoStore = new ArtistPromoStore();
export const artistPromoGalleryStore = new ArtistPromoGalleryStore();

export const clientRecommendationStore = new ClientRecommendationStore();
export const proRecommendationStore = new ProRecommendationStore();