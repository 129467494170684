/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.9.17
 */

import BaseStore from "./base-store";
import { BOOK_PERSIST_SUCCESS, CALENDAR_BOUND_CHANGES, CALENDAR_MARKERS_LOADED } from "./../constants";
import { reloadMarkers } from "./../ac/calendar-markers-actions";

export default class CalendarMarkersStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case CALENDAR_MARKERS_LOADED:
                    this.store.markers = payload;

                    break;

                case CALENDAR_BOUND_CHANGES:

                    let markersReload = payload.forceMarkersReload;

                    if (
                        payload.fromDate !== this.store.bounds.fromDate ||
                        payload.toDate !== this.store.bounds.toDate
                    ) {
                        Object.assign(this.store.bounds, payload);

                        markersReload = true;
                    }

                    if (markersReload) {
                        reloadMarkers(this.store.bounds.fromDate, this.store.bounds.toDate);
                    }

                    break;

                case BOOK_PERSIST_SUCCESS:
                    if (this.store.bounds.fromDate && this.store.bounds.toDate) {
                        reloadMarkers(this.store.bounds.fromDate, this.store.bounds.toDate);
                    }

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialStore() {
        return {
            bounds: {
                fromDate: null,
                toDate: null
            },
            markers: []
        };
    }
}
