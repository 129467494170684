/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.12.17
 * Time: 15.44
 *
 * @flow
 */
import type { THeader, THttpMethod } from "../../types";
import {
    CUSTOM_HEADER_U6_GROUPS,
    HTTP_DELETE,
    HTTP_GET,
    HTTP_POST,
    HTTP_PUT,
    REQUEST_STATE_DONE,
    REQUEST_STATE_NOT_READY,
    REQUEST_STATE_READY,
    REQUEST_STATE_RUNNING,
    REQUEST_STATE_SCHEDULED
} from "../../frizo-shared-constants";

import Signal from "signals";
import { RequestWrapper } from "./request-wrapper";

type TState = REQUEST_STATE_NOT_READY | REQUEST_STATE_READY
    | REQUEST_STATE_SCHEDULED | REQUEST_STATE_RUNNING | REQUEST_STATE_DONE;


export class Request extends RequestWrapper {
    url: string;
    method: THttpMethod;
    requestBody: string | FormData;
    requestId: string | null;
    requestHeaders: Array<THeader>;

    response: any;
    responseHeaders: Array<THeader>;
    originResponseBody: string;

    statusCode: number;
    state: TState;
    xhr: XMLHttpRequest;
    success: null | boolean;

    successCallback: Function;
    failCallback: Function;

    _suppressNotifications: boolean;
    failNotificationMessage: string;

    hops: number;

    events: { [eventName: string]: Signal };
    header = (name: string, value: string): Request => {
        this.requestHeaders.push({
            name: name.toLowerCase(), value
        });

        return this;
    };
    setRequestBody = (body: string | FormData): Request => {
        this.requestBody = body;

        return this;
    };
    getRequestBody = (): string | FormData => {
        return this.requestBody;
    };
    setResponseBody = (body: any): Request => {
        this.response = body;

        return this;
    };
    setOriginResponseBody = (body: string): Request => {
        this.originResponseBody = body;

        return this;
    };
    getOriginResponseBody = (): string => {
        return this.originResponseBody;
    };
    getResponseBody = (): any => {
        return this.response;
    };
    setStatusCode = (code: number): Request => {
        this.statusCode = code;
        this.decideSuccessOrNot();

        return this;
    };
    getStatusCode = (): number => {
        return this.statusCode;
    };
    setResponseHeader = (name: string, value: string) => {
        this.responseHeaders.push({
            name: name.toLowerCase(),
            value
        });
    };
    setResponseHeaders = (headers: Array<THeader>): Request => {
        this.responseHeaders = headers.map(({ name, value }) => {
            return {
                name: name.toLowerCase(),
                value
            };
        });

        return this;
    };
    getResponseHeaders = (): Array<THeader> => {
        return this.responseHeaders;
    };
    getResponseHeadersHash = (): { [string]: string } => {
        let hash = {};
        this.responseHeaders.forEach((pair: THeader) => {

            let name = pair.name.toLowerCase();

            if (hash[name] === undefined) {
                hash[name] = pair.value;
            }
            else {
                hash[name] += "," + pair.value;
            }

        });

        return hash;
    };
    getResponseHeader = (name: string) => {
        return this.responseHeaders
        .filter((header: THeader) => header.name.toLowerCase() === name.toLowerCase())
        .map((header: THeader) => header.value).join(",");
    };
    setXhr = (xhr: XMLHttpRequest): Request => {
        this.xhr = xhr;

        return this;
    };
    getXhr = (): XMLHttpRequest => {
        return this.xhr;
    };
    hop = (): void => {
        this.hops++;
    };
    getHopsCount = (): number => {
        return this.hops;
    };
    getRequestId = (): string | null => {
        return this.requestId;
    };
    getMethod = (): string => {
        return this.method;
    };
    isGet = (): boolean => {
        return this.method === HTTP_GET;
    };
    isDelete = (): boolean => {
        return this.method === HTTP_DELETE;
    };
    isPost = (): boolean => {
        return this.method === HTTP_POST;
    };
    isPut = (): boolean => {
        return this.method === HTTP_PUT;
    };
    getUrl = (): string => {
        return this.url;
    };
    setUrl = (url: string): Request => {
        this.url = url;

        return this;
    };
    getRequestHeaders = (): Array<{ name: string, value: string }> => {
        return this.requestHeaders;
    };
    getRequestHeadersHash = (): { [string]: string } => {
        let hash = {};
        this.requestHeaders.forEach((pair: THeader) => {
            if (hash[pair.name] === undefined) {
                hash[pair.name] = pair.value;
            }
            else {
                hash[pair.name] += "," + pair.value;
            }

        });

        return hash;
    };
    //FIXME: Write test
    getRequestHeader = (name: string) => {
        return this.requestHeaders
        .filter((header: THeader) => header.name.toLowerCase() === name.toLowerCase())
        .map((header: THeader) => header.value).join(",");
    };
    getDataGroups = (): Array<string> => {
        let groups = this.getRequestHeader(CUSTOM_HEADER_U6_GROUPS).split(",");

        return groups.map(group => group.trim());
    };
    /**
     * FIXME: Use more strongly or more formal check
     * @returns {boolean}
     */
    isSecure = (): boolean => {
        return /\/secure\//.test(this.getUrl());
    };
    setState = (state: TState) => {
        this.state = state;
    };
    getState = () => {
        return this.state;
    };
    isDone = () => {
        return this.state === REQUEST_STATE_DONE;
    };
    isSuccess = () => {
        return this.success;
    };
    /**
     * @protected
     */
    decideSuccessOrNot = () => {
        if (this.statusCode >= 200 && this.statusCode < 400) {
            this.success = true;
        }
        else {
            this.success = false;
        }
    };
    suppressNotifications = (suppress: boolean = true): Request => {
        this._suppressNotifications = suppress;

        return this;
    };
    isNotificationsSuppressed = (): boolean => {
        return this._suppressNotifications;
    };
    setFailNotificationMessage = (message: string): Request => {
        this.failNotificationMessage = message;

        return this;
    };
    getFailNotificationMessage = (): string => {
        return this.failNotificationMessage;
    };

    constructor(url: string, method: string, requestId: string = "none") {
        super();

        this.url = url;
        this.method = method;
        this.requestHeaders = [];
        this.requestBody = "";

        this.response = null;
        this.responseHeaders = [];
        this.originResponseBody = "";

        this.state = REQUEST_STATE_NOT_READY;
        this.statusCode = 0;
        this.success = null;

        this._suppressNotifications = false;

        switch (method) {
            case HTTP_PUT:
            case HTTP_POST:
                this.failNotificationMessage = "Save fail";
                break;

            case HTTP_DELETE:
                this.failNotificationMessage = "Fail to remove";
                break;

            default:
            case HTTP_GET:
                this.failNotificationMessage = "Request fail";
                break;
        }

        this.hops = 0;

        this.requestId = requestId === "none" ? null : requestId;

        this.successCallback = () => {
        };
        this.failCallback = () => {
        };

        this.events = {
            succeeded: new Signal(),
            failed: new Signal()
        };

    }
}
