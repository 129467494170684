/**
 * Created by sn on 19.09.2016.
 * User: Zakhar Shushkevich
 * Company: U6 SIA
 */

import React, { Component } from 'react';
import Header from './header-component';
import ModalComponent from './modal/modal-component';
import Footer from './footer-component';
import ClientStreamWidget from './client-stream/widget';

export default class App extends Component {


    render() {

        return (
            <div className="frizo-app">
                <ClientStreamWidget />
                <Header />
                <div className='container main-content' id="main">
                    {this.props.children}
                </div>
                <ModalComponent />
                <Footer />
            </div>
        )
    }
}

