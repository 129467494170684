/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.9.5
 * Time: 10:41
 *
 * @flow
 */
import React from 'react';

type TProps = {
    artistName?: string,
    firstName?: string,
    lastName?: string,
    categories?: string
}

export default function ArtistImageTitle(props: TProps) {
    let artistName = '';
    if (props.artistName)   {
        artistName = props.artistName;
    }
    else if (props.firstName && props.lastName)   {
        artistName = props.firstName + ' ' + props.lastName;
    }

    return (<div>
        <div className={"fr-card-artist-title"}>{artistName}</div>
        <div className={"fr-card-artist-subtitle"}>{props.categories}</div>
    </div>);
}

ArtistImageTitle.defaultProps = {
    categories: ''
};
