/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 4/25/17
 * Time: 14:21
 *
 * @flow
 *
 */

import React, {Component} from 'react';
import {Modal, FormGroup} from 'react-bootstrap';
import Violation from '../helper-components/violation';
import {modalHide} from '../../ac/modals';

import {
    profileEmailFormChange,
    processChangeEmailForm,
    clearEmailChangeForm
} from '../../ac/profile/personal/email-change-form-actions';

import {changeEmailStore} from '../../stores';
import gradientFill from './../../helper/gradient-fill';

type TState = {
    newEmail: string,
    password: string,
    formIsValid: boolean,
    violations: {
        newEmail: string,
        password: string
    },
    showMessage: boolean,
    showError: boolean,
    modal: {
        show: boolean,
        data: string
    }
}

export default  class ChangeEmailModal extends Component<null, TState> {


    constructor() {

        super();
        this.state = {
            newEmail: '',
            password: '',
            formIsValid: true,
            violations: {
                newEmail: '',
                password:''
            },
            showMessage: false,
            showError: false,
            modal: {
                show: false,
                data: ''
            }
        };

        (this: any).clear = this.clear.bind(this);
        (this: any).handleChange = this.handleChange.bind(this);
        (this: any).handleStoreChange = this.handleStoreChange.bind(this);
        (this: any).changeEmail = this.changeEmail.bind(this);
        (this: any).close = this.close.bind(this);
    }


    close() {
        modalHide();
    }


    handleChange (event: SyntheticEvent<HTMLButtonElement>) {

        let name =  event.currentTarget.name;
        let value =  event.currentTarget.value;

        profileEmailFormChange({name, value})
    }

    handleStoreChange () {
        this.setState(changeEmailStore.getStore());
    }


    clear(e: Event) {
        e.preventDefault();
        clearEmailChangeForm ();
    }

    changeEmail(e: Event) {
        e.preventDefault();
        processChangeEmailForm();
    }

    componentDidMount() {
      changeEmailStore.addChangeListener(this.handleStoreChange);
    }

    componentWillUnmount() {
        changeEmailStore.removeChangeListener(this.handleStoreChange);
    }

    render() {
        let content;
        if(this.state.showMessage) {
            content = (
                <div>
                    <div className="info-message">Check entered new e-mail to continue.</div>
                    <button name="btn fr-btn " className="btn fr-btn pull-right ok-button" onClick={this.close}>Ok</button>
                </div>
            );
        } else {
            content = (
                <div>
                    <form name="changeEmail" method="post" noValidate="novalidate" className="form-horizontal change-email">

                        <FormGroup>
                            <div className="col-sm-12">
                                <input type="text" name="newEmail" required="required" autoComplete="off" value={this.state.newEmail}
                                       className="form-control" placeholder="New e-mail" onChange={this.handleChange}/>
                                <Violation violationSet={this.state.violations.newEmail} visible={!this.state.formIsValid} />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="col-sm-12">
                                <input type="password" name="password" required="required" autoComplete="off" value={this.state.password}
                                       className="form-control" placeholder="Current Frizo password" onChange={this.handleChange}/>
                                <Violation violationSet={this.state.violations.password} visible={!this.state.formIsValid} />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="col-sm-12">
                                <button name="clear_button" className="btn fr-btn--clear pull-left clear-button" onClick={this.clear}>Clear</button>
                                <button name="email_change" className="btn fr-btn pull-right" onClick={this.changeEmail}>Change</button>
                            </div>

                        </FormGroup>
                        { this.state.showError && <div className="error-message">Something went wrong, please try again.</div> }
                    </form>
                </div>
            );
        }

        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>Change e-mail</h2>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
            </div>
        );
    }
}

