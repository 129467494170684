/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 4.10.17
 */

import { ADD_NEW_CARD_MODAL } from "./../../constants";
import { modalChange } from "./../modals";
import { addNewPaymentSource } from "./../payments/payments-personal";

export function openAddPersonalCardModal(noCard = false) {

    return new Promise((success, fail) => {
        modalChange(ADD_NEW_CARD_MODAL, {
            noCard: noCard,
            professional: false,
            action: token => {
                return addNewPaymentSource(token.id).then(response => {

                    success({
                        token: token,
                        paymentProfile: response
                    });

                    return response;

                }, response => {

                    fail(response);

                    return Promise.reject(response);

                });
            }
        });
    });

}