/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.3.17
 * Time: 16.29
 */

import React, {Component} from 'react';
import {NavItem} from 'react-bootstrap';
import ProfileBanner from '../../elements/profile-banner';
import RoutedTab from './../tabs/routed-tab';
import {profileCompletenessStore, personalProfileMyCardsStore} from './../../stores';
import PropTypes from 'prop-types';
import {loadMyCards} from './../../ac/payments/payments-personal';
import config from '../../config';

export default class PersonalTab extends Component {

    constructor() {
        super();

        this.state = {
            completeness: this.getCompletenessStoreData(),
            cardsCount: this.getMyCardsStoreData(),
            billingTabHide: config.client_billing_tab_hide || false
        };
        this.handleCompletenessChange = this.handleCompletenessChange.bind(this);
        this.handleMyCardsChange = this.handleMyCardsChange.bind(this);

    }

    getChildContext() {
        return {
            completeness: this.getCompletenessStoreData()
        };
    }


    componentDidMount() {

        loadMyCards();
        profileCompletenessStore.addChangeListener(this.handleCompletenessChange);
        personalProfileMyCardsStore.addChangeListener(this.handleMyCardsChange);

    }

    componentWillUnmount() {

        profileCompletenessStore.removeChangeListener(this.handleCompletenessChange);
        personalProfileMyCardsStore.removeChangeListener(this.handleMyCardsChange);
    }


    handleCompletenessChange() {
        this.setState({
            completeness: this.getCompletenessStoreData()
        });
    }

    handleMyCardsChange() {
        this.setState({
            cardsCount: this.getMyCardsStoreData()
        });
    }

    getCompletenessStoreData() {
        return profileCompletenessStore.getStore().status;
    }

    getMyCardsStoreData() {

        return personalProfileMyCardsStore.getStore().cardsCount;
    }

    render() {
        return <div ref={this.containerRef}>
            <ProfileBanner
                mode="personal-profile"
                incomplete={false}
                completeness={this.state.completeness}
            />
            <RoutedTab route="/me/:tab" activeKey="1">
                <NavItem key={1} eventKey="essentials">Essentials</NavItem>
                <NavItem key={2} eventKey="my-appointments" data-key="myAppointments">Appointments</NavItem>
                <NavItem key={3} eventKey="favorites">Favorites</NavItem>
                {(this.state.cardsCount > 0 || !this.state.billingTabHide) &&
                <NavItem key={4} eventKey="billing">Billing</NavItem>}
            </RoutedTab>
            <div className='container fr-tab-content'>
                {this.props.children}
            </div>
        </div>
    }
}

PersonalTab.childContextTypes = {
    completeness: PropTypes.object
};