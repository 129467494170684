/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 27.11.2018
 * Time: 14:25
 */

import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment";

export default class ConfirmationBadge extends Component {
    constructor(props) {
        super(props);
        this.confirmation = this.props.confirmation;
        this.getPopover = this.getPopover.bind(this);
    }

    getPopover() {
        let confirmationDate = this.confirmation.confirmationTimestamp ? moment.tz(this.confirmation.confirmationTimestamp * 1000,
            this.confirmation.timezone).format("LL hh:mm A") : "";
        let issuedDate = this.confirmation.issuedTimestamp ? moment.tz(this.confirmation.issuedTimestamp * 1000, this.confirmation.timezone)
        .format("LL hh:mm A") : "";
        let text = this.confirmation.confirmed ? <p>Confirmed by client
            <br/> on {confirmationDate}</p> : <p>Client confirmation requested
            <br/> on {issuedDate}</p>;


        return (<Popover id="popover-confirmation-top">
            {text}
        </Popover>);
    };


    render() {
        let confirmationStyle = !this.confirmation.confirmed && this.confirmation.issued ? "sent" : this.confirmation.confirmed ? "confirmed" : "";
        if (this.props.status === "past") {
            confirmationStyle = "history";
        }
        let confirmationPopover = this.getPopover();

        if (this.confirmation.issued) {
            return (
                <OverlayTrigger trigger={["hover", "focus", "click"]} placement="top" overlay={confirmationPopover}>
                    <span className={"notification-badge " + confirmationStyle}></span>
                </OverlayTrigger>
            );
        }
        else {
            return null;
        }
    }
}
