/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 12.11.2018
 * Time: 10:14
 */


import React, {
    Component
} from 'react';
import {artistPromoGalleryStore} from "../../stores";
import {
    GALLERY2_ARTIST_PROMO_GALLERY_ID,
} from "../../frizo-shared-constants";
import {createGallery} from "../gallery2/gallery-factory";
import {loadArtistPromoGallery} from "../../ac/artist-promo-actions";
import ArtistPromoGalleryItemLook from "../gallery2/items/artist-promo-gallery-item-look";
import SalonPromoFullscreenLook from "../gallery2/fullscreen-items/salon-promo-fullscreen-look";
import GoUpButton from "../../elements/go-up-button";
import LoaderBar from "../../elements/loader-bar";


export default class ArtistPromoGallery extends Component {
    constructor(props) {
        super(props);

        this.state = Object.assign({
            ready: false,
            isEmptyGallery: false,
            noActions: true,
            params: {
                offset: 0,
                count: 12,
                salonId: null,
                artistId: 0,

            },
        }, this.getStoreData());


        this.onStoreChange = this.onStoreChange.bind(this);

        this.gallery = this.createGallery();
        setTimeout(() => {
            this.gallery.reset();

            this.gallery.init({
                artistId: this.props.artistId,
                salonId: this.props.salonId
            });

        });

    }

    getStoreData() {
        let storeData = artistPromoGalleryStore.getStore();

        return {
            params: storeData.gallery.params,
            artistId: storeData.artistId,
            salonId: storeData.salonId
        }
    }

    componentDidMount() {
        artistPromoGalleryStore.addChangeListener(this.onStoreChange);
    }

    componentWillUnmount() {
        artistPromoGalleryStore.removeChangeListener(this.onStoreChange);
    }

    createGallery() {

        return createGallery(GALLERY2_ARTIST_PROMO_GALLERY_ID)
            .setGalleryStore(artistPromoGalleryStore)
            .setFavoritesEnabled(false)
            .setNoItemsText('Work imagery coming soon...')
            .setItemClass(ArtistPromoGalleryItemLook)
            .setFullscreenClass(SalonPromoFullscreenLook)
            .setGalleryLoader(loadArtistPromoGallery);
    }


    onStoreChange() {
        let newState = Object.assign({}, this.getStoreData());

        if (newState.params.artistId) {
            if (!this.state.ready) {

                newState.ready = true;

            }
        }

        this.setState(newState);
    }


    render() {
        if (!this.state.ready) {
            return <LoaderBar className={"loader-msg-wrapper--full-height"}/>
        }

        return (
            <div>
                {this.gallery.getGallery()}
                <GoUpButton/>
            </div>
        )

    }
}
