/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 3/31/17
 * Time: 16:17
 */

import BaseConstraint from "./base-constraint";

export default class IsPassword extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Symbols are not permitted";
        this.regex = /^[a-zA-Z0-9!?@#$%&*\-+,.\s]*$/;
        this.errorCode = "VALUE_IS_NOT_PASSWORD";
    }


    static getName() {
        return "is-password";
    }
}
