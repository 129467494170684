/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.10.17
 */

import BaseStore from "../../base-store";
import {
    PROFESSIONAL_PROFILE_LOAD_SALON_INFO_SUCCESS,
    PROFESSIONAL_PROFILE_WH_DISCARD,
    PROFESSIONAL_PROFILE_WH_PERSIST,
    PROFESSIONAL_PROFILE_WH_SELECT_ARTIST,
    PROFESSIONAL_PROFILE_WH_UPDATE,
    PROFESSIONAL_PROFILE_WH_OPEN
} from "../../../constants";
import { createArtistIndexer } from "./../../../service/artist-indexer";
import { WH_END_TIME, WH_START_TIME } from "./../../../frizo-shared-constants";
import { sessionStore } from "../../index";
import { USER_TYPE_MASTER } from "../../../frizo-shared-constants";

export default class ProfileWHStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case PROFESSIONAL_PROFILE_WH_SELECT_ARTIST:

                    this.selectArtist(payload.artistId);

                    break;

                case PROFESSIONAL_PROFILE_WH_OPEN:
                    if (sessionStore.getStore().session.rawUserType === USER_TYPE_MASTER) {
                        this.store.selectedArtistId = payload.artistId;
                    }

                    break;
                case PROFESSIONAL_PROFILE_LOAD_SALON_INFO_SUCCESS:

                    this.store.artists = payload.user.filter(user => user.master);
                    this.store.addresses = payload.address;

                    let indexer = createArtistIndexer()
                    .putAddressSet(this.store.addresses)
                    .putArtistSet(this.store.artists)
                    .index();

                    this.store.artistsIndex = indexer.getArtistIndex();
                    this.store.addressIndex = indexer.getAddressIndex();

                    let selected = this.store.selectedArtistId;
                    if (!selected || !this.store.artistsIndex[selected]) {
                        if (this.store.artists.length > 0) {
                            selected = this.store.artists[0].id;
                        }
                        else {
                            selected = null;
                        }
                    }

                    this.store.addressesPrinted = {};
                    payload.address.forEach(address => {
                        this.store.addressIndex[address.id] = address;
                        this.store.addressesPrinted[address.id] = this.printAddress(address);
                    });

                    this.selectArtist(selected);

                    this.setReady(true);

                    break;

                case PROFESSIONAL_PROFILE_WH_UPDATE:

                    let artist = this.getArtistById(payload.artistId);

                    //take care about expanded address in artist object
                    if (payload.patch["addressId"] !== undefined) {
                        let addressId = Number(payload.patch["addressId"]);

                        payload.patch["address"] = this.getAddressById(addressId);
                        payload.patch["addressId"] = addressId;

                    }

                    if (artist.scheduleIndex[payload.day] === undefined) {
                        artist.scheduleIndex[payload.day] = {};
                    }

                    Object.assign(artist.scheduleIndex[payload.day], payload.patch);


                    break;

                case PROFESSIONAL_PROFILE_WH_PERSIST:
                    let artistSet = [this.getArtistById(payload.artistId)];

                    payload.syncer.putArtistSet(artistSet);
                    payload.syncer.persist();


                    break;

                case PROFESSIONAL_PROFILE_WH_DISCARD:

                    break;


                default:
                    return true;
            }

            this.emitChange();
        });

    }

    getInitialStore() {
        return {
            artists: [],
            artistsIndex: {},
            selectedArtistId: null,
            selectedArtist: {},

            addresses: [],
            addressesPrinted: {},
            addressIndex: {}
        };
    }

    /**
     * @protected
     * @param id
     * @returns {*}
     */
    getArtistById(id) {
        return this.store.artistsIndex[id];
    }


    /**
     * @protected
     * @param addressId
     * @returns {*}
     */
    getAddressById(addressId) {
        let address = this.store.addressIndex[addressId];
        if (!address) throw new Error("Provided address `" + addressId + "` is missing");

        return address;
    }


    /**
     * @protected
     * @returns {*}
     */
    getFirstAddress() {
        if (!this.store.addresses || this.store.addresses.length === 0) {
            throw new Error("Salon didn't have addresses");
        }

        return this.store.addresses[0];
    }

    /**
     * @protected
     * @param address
     * @returns {string}
     */
    printAddress(address) {
        return [
            address.address,
            address.city,
            address.state,
            address.zip
        ].filter(value => value && value.length > 0).join(", ");
    }


    /**
     * @protected
     * @param artistId
     */
    selectArtist(artistId) {

        let artist = this.store.artistsIndex[artistId];

        if (!this.store.artists.length) {
            this.store.selectedArtist = {};

            return true;
        }

        if (!artist) throw new Error("Artist " + artistId + " is missing");

        this.store.selectedArtistId = artistId;

        let firstAddress = this.getFirstAddress();

        if (!artist.schedule || artist.schedule.length === 0) {
            artist.schedule = [];
            for (let day = 0; day < 7; day++) {
                let schedule = {
                    day: day,
                    dayOff: true,
                    fromTime: WH_START_TIME / 60,
                    toTime: WH_END_TIME / 60,
                    address: firstAddress,
                    from: WH_START_TIME,
                    to: WH_END_TIME,
                    addressId: firstAddress.id
                };

                artist.schedule.push(schedule);
                artist.scheduleIndex[day] = schedule;
            }
        }

        this.store.selectedArtist = artist;
    }
}
