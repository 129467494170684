/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 6/20/17
 * Time: 19:17
 */

import React from 'react';
import {NavItem} from 'react-bootstrap';
import routedTabCreator from './../tabs/routed-tab-creator';
import Banner from './../../elements/banner';

let titleText=<div className="fr-banner-top__text--legal">FAQ</div>;

export default routedTabCreator("/faq/:tab", "faq-component",
    <Banner left={titleText} leftLength={3} key={3} />,
    <NavItem key={1} eventKey="for-clients">For Clients</NavItem>,
    <NavItem key={2} eventKey="for-professionals">For Professionals</NavItem>
);

