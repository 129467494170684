/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 3/31/17
 * Time: 18:24
 */

import BaseConstraint from "./base-constraint";

export default class IsName extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Symbols are not permitted";
        this.regex = /^[a-zA-Z][\x20a-zA-Z0-9'.-]*$/i;
        this.errorCode = "VALUE_IS_NOT_NAME";
    }

    static getName() {
        return "is-name";
    }
}
