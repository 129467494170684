/**
 * Created by fliak on 24.10.16.
 */



import React, { Component } from 'react'
import idGenerator from '../../helper/id-generator-component.js';
import {Checkbox} from 'react-bootstrap';

export default class MembersFilter extends Component {
    constructor(props)   {
        super();


        this.state = {
        };

        this.onChange = this.onChange.bind(this);

    }

    onChange(e) {
        console.log('change', e.target.value, e.target.checked);

        if (typeof this.props.onCheck === 'function')   {

            this.props.onCheck(Number(e.target.value), e.target.checked, e)
        }

    }

    render() {

        if (this.props.members.length > 1) {

            let checkedHash = {};
            for (let i = 0; i < this.props.checked.length; i++)  {
                checkedHash[this.props.checked[i]] = true;
            }


            let options = [];
            for (let i = 0; i < this.props.members.length; i++) {
                let member = this.props.members[i];

                options.push(
                    <Checkbox checked={checkedHash[member.id]} key={idGenerator('member', 'option')}
                              value={member.id} onChange={this.onChange}>
                        {member.firstName} {member.lastName}
                    </Checkbox>
                );
            }

            return (
                <div className="fr-artist-checked-block">
                    <div className="ui-widget-header">
                        <div className="fr-artist-header">
                            Artists
                        </div>
                    </div>
                    <div className="fr-artist-body">
                        {options}
                    </div>
                </div>
            )
        }
        else {
            return null;
        }
    }
}