/**
 * User: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 22.3.17
 * Time: 17.13
 */

import React, {
    Component
} from 'react';
import {promoBrandStore} from "../../stores";
import {
    GALLERY2_SALON_PROMO_BRANDS_ID
} from "../../frizo-shared-constants";
import {createGallery} from "../gallery2/gallery-factory";
import {loadSalonPromoBrands} from "../../ac/salon-promo-action";
import SalonPromoBrandItem from "../gallery2/items/salon-promo-brand-item";
import LoaderBar from "../../elements/loader-bar";


export default class SalonPromoBrands extends Component {
    constructor(props) {
        super(props);


        this.state = Object.assign({
            ready: false,
            isEmptyGallery: false,
            params: {},
        }, this.getStoreData());


        this.onGalleryStoreChange = this.onGalleryStoreChange.bind(this);

        this.gallery = this.createGallery();

        setTimeout(() => {
            this.gallery.reset();
            this.gallery.init({
                salonUrl: props.params.url,
                tab: this.props.route.tab
            });
        });

        //console.log('props', props, props.params.url, this.props.route.tab)

    }

    getStoreData()  {
        let storeData = promoBrandStore.getStore().gallery;

        return {
            params: storeData.params,
        }
    }

    componentDidMount() {
        promoBrandStore.addChangeListener(this.onGalleryStoreChange);
    }

    componentWillUnmount() {
        promoBrandStore.removeChangeListener(this.onGalleryStoreChange);

    }

    createGallery() {

        return createGallery(GALLERY2_SALON_PROMO_BRANDS_ID)
            .setGalleryStore(promoBrandStore)
            .setFavoritesEnabled(false)
            .setNoItemsText('Yet to post images')
            .setItemClass(SalonPromoBrandItem)
            .setItemClickHandler(() => {}) //block fullscreen
            .setGalleryLoader(loadSalonPromoBrands);
    }



    onGalleryStoreChange() {
        let newState = Object.assign({}, this.getStoreData());

        this.setState(newState);
    }

    render() {
        if (!this.state.params.salonId) {
            return <LoaderBar className={"loader-msg-wrapper--full-height"}/>
        }

        return (
            <div>
                {this.gallery.getGallery()}
            </div>
        )

    }
}
