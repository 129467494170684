/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 18.9.17
 */

import ApiClient from "../service/api-client";

export function xhrGetClientStream() {

    return ApiClient.get(["Q149_get_client_stream"]).then((answer) => {
        let [response] = answer;

        return Promise.resolve(response);
    });
}

export function xhrMarkAsRead(messageId) {

    return ApiClient.put(["Q153_client_stream_mark_as_read", { messageId: messageId }]).then((answer) => {
        let [response] = answer;

        return Promise.resolve(response);
    });
}
