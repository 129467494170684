/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 09.05.2017
 * Time: 11:26
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { sessionStore } from "./../stores/index";
import { USER_TYPE_FREELANCER } from "../frizo-shared-constants";
import ProfileCompletenessBadge from "./../components/badge/profile-completeness-badge";
import Banner from "./../elements/banner";

export default class ProfileBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProfessional: sessionStore.isProfessional()
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        sessionStore.addChangeListener(this.handleChange);
    }

    componentWillUnmount() {
        sessionStore.removeChangeListener(this.handleChange);
    }

    handleChange() {
        this.setState({
            isProfessional: sessionStore.isProfessional()
        });
    }

    render() {

        let leftText, rightText, rightUrl, middle, leftLength, rightBadge;

        let mySalonText = sessionStore.getUserType() === USER_TYPE_FREELANCER ? "My business" : "My Salon";
        let mySalonLength = mySalonText.length;

        mySalonText = <span>
            {mySalonText}&nbsp;
            {this.props.mode !== "professional-profile" &&
            <ProfileCompletenessBadge strength={this.props.completeness.profileProfessional.status}/>}
        </span>;

        let meText = <span>
            Me&nbsp;
            {this.props.mode !== "personal-profile" &&
            <ProfileCompletenessBadge strength={this.props.completeness.profilePersonal.status}/>}
        </span>;
        let meTextLength = 2;


        switch (this.props.mode) {
            case "personal-profile":

                leftText = meText;
                leftLength = meTextLength;

                rightText = mySalonText;
                rightUrl = "/my-salon";
                rightBadge = this.props.completeness.profileProfessional.status !== 2;

                break;

            case "professional-profile":

                leftText = mySalonText;
                leftLength = mySalonLength;

                rightText = meText;
                rightUrl = "/me";
                rightBadge = this.props.completeness.profilePersonal.status !== 2;

                break;

            default:
                throw new Error("Unknown mode " + this.props.mode);
        }

        if (!this.state.isProfessional) {
            rightText = null;
        }


        if (this.props.completeness.profileProfessional.status === 0) {
            middle = <div className="fr-profile fr-profile__fully-incomplete">
                <div className="fr-profile__text">
                    <span className="rwd-line">Some steps are incomplete. </span>
                    <span className="rwd-line">Profile hidden from public</span>
                </div>
            </div>;
        }
        if (this.props.completeness.profileProfessional.status === 1) {
            middle = <div className="fr-profile fr-profile__incomplete">
                <div className="fr-profile__text">
                    <span className="rwd-line">Parts of your profile are not public,</span>
                    <span className="rwd-line"> follow orange badge to locate them</span>
                </div>
            </div>;
        }
        if (!this.state.isProfessional) {
            middle = null;
        }

        return <Banner
            left={leftText}
            leftLength={leftLength}
            right={rightText}
            rightUrl={rightUrl}
            rightBadge={rightBadge}
            middle={middle}
            gradientStyle="right"
        />;
    }
}

ProfileBanner.defaultProps = {
    leftUrl: "",
    rightUrl: "",
    rightText: "",
    leftText: ""
};
ProfileBanner.propTypes = {
    leftUrl: PropTypes.string,
    rightUrl: PropTypes.string,
    rightText: PropTypes.string,
    leftText: PropTypes.string
};
