/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 14.6.17
 * Time: 13.33
 */

import ApiClient from "../service/api-client";

export function xhrGetCalendarMarkers(fromDate, toDate, userList, addressList) {

    let params = {
        fromDate, toDate
    };

    if (userList) params.userList = userList;
    if (addressList) params.addressList = addressList;


    return ApiClient.get(["Q76_get_calendar_markings", params], null, {
        enableDefaultNotifications: false
    }).then(answer => {
        let [response] = answer;

        return response.response;
    });
}
