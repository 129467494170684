/**
 * Created by x_a_x_a on 10.01.2017
 * User: Eleonora Kolodinskaya
 * Company: U6 SIA
 */
import React, { Component } from "react";
import Login from "./login-component";
import ForgotModal from "./forgot-password-modal";
import BookModal from "../book-apt/book-modal";
import LegalModal from "./legal-modal";
import ContactUsModal from "./contact-us-modal";
import ClientModal from "./client-modal";
import ClientCommentModal from "./client-comment-modal";
import ChangePasswordModal from "./change-password-modal";
import ChangeEmailModal from "./change-email-modal";
import AlertSubscriptionModal from "./alert-subscription-modal";
import SubscriptionApprovalModal from "./subscription-approval-modal";
import SubscriptionFinishModal from "./subscription-finish-modal";
import ServiceEditModal from "./service-edit-modal";
import AppointmentDetailModal from "./appointment-detail-modal";
import PersonalAppointmentModal from "./personal-appointment-modal";
import ArtistServiceModal from "./artist-service-modal";
import { modalHide } from "../../ac/modals";
import { Modal } from "react-bootstrap";
import stores from "../../stores";
import {
    LOGIN_MODAL,
    FORGOT_MODAL,
    BOOK_MODAL,
    LEGAL_MODAL,
    CONTACT_MODAL,
    CLIENT_MODAL,
    CLIENT_COMMENT_MODAL,
    CHANGE_PASSWORD_MODAL,
    CHANGE_EMAIL_MODAL,
    ADD_NEW_CARD_MODAL,
    CREATE_PAYMENT_PROFILE_MODAL,
    ADD_NEW_BANK_ACCOUNT_MODAL,
    CREATE_PAYMENT_ORDER_MODAL,
    PAYMENT_APPROVAL_MODAL,
    ALERT_SUBSCRIPTION_MODAL,
    SUBSCRIPTION_APPROVAL_MODAL, SUBSCRIPTION_FINISH_MODAL, GALLERY_FULLSCREEN_MODAL,
    SERVICE_EDIT_MODAL,
    SALON_APPOINTMENT_DETAIL_MODAL, PERSONAL_APPOINTMENT_DETAIL_MODAL,
    ARTIST_SERVICE_MODAL, AFFILIATE_FORM_MODAL, PRO_PRODUCTS_VIDEO_MODAL
} from "../../constants";

import AddNewCardForm from "./../payments/add-new-card-form";
import AddNewBankAccountForm from "./../payments/add-new-bank-account-form";
import CreatePaymentProfileForm from "../profile/professional/cards-and-bank/payment-profile-form";

import GetPaidPopup from "./../payment-order/get-paid-popup";
import PaymentApprovalPopup from "./../payment-approval/payment-approval-popup";
import GalleryFullscreenWidget from "../gallery2/gallery-fullscreen-widget";
import AffiliateFormModal from "./affiliate-form-modal";
import ProProductsVideoModal from "./pro-products-video-modal";


export default class ModalComponent extends Component {
    constructor(props) {
        super(props);
        this.stores = stores;
        this.modalStore = stores.modal;

        this.state = {
            modalType: this.modalStore.getModalType(),
            modalShow: this.modalStore.getModalToggle()
        };

        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        this.modalStore.addChangeListener(this.handleChange);
    }

    componentWillUnmount() {
        this.modalStore.removeChangeListener(this.handleChange);
    }

    handleChange() {
        this.setState({
            modalType: this.modalStore.getModalType(),
            modalShow: this.modalStore.getModalToggle(),
            modalOptions: this.modalStore.getModalOptions()
        });
    }

    close() {
        modalHide();
    }

    render() {

        let body, modalSize, dialogClassName;
        switch (this.state.modalType) {
            case LOGIN_MODAL:
                body = <Login {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "login-modal";
                break;

            case FORGOT_MODAL:
                body = <ForgotModal/>;
                modalSize = "small";
                dialogClassName = "forgot-modal";
                break;

            case BOOK_MODAL:
                body = <BookModal/>;
                modalSize = "large";
                dialogClassName = "book-modal";
                break;

            case LEGAL_MODAL:
                body = <LegalModal/>;
                modalSize = "large";
                dialogClassName = "legal-modal";
                break;

            case CONTACT_MODAL:
                body = <ContactUsModal/>;
                modalSize = "small";
                dialogClassName = "contact-us-modal";
                break;


            case GALLERY_FULLSCREEN_MODAL:
                console.log("this.state.modalOptions", this.state.modalOptions);

                body = <GalleryFullscreenWidget {...this.state.modalOptions} />;
                modalSize = "large";
                dialogClassName = "image-modal";

                break;

            case CLIENT_MODAL:
                body = <ClientModal {...this.state.modalOptions}/>;
                modalSize = "small";
                dialogClassName = "client-modal";

                break;

            case CLIENT_COMMENT_MODAL:
                body = <ClientCommentModal {...this.state.modalOptions}/>;
                modalSize = "small";
                dialogClassName = "client-modal";

                break;

            case CHANGE_EMAIL_MODAL:
                body = <ChangeEmailModal/>;
                modalSize = "small";
                dialogClassName = "change-email-modal";
                break;

            case CHANGE_PASSWORD_MODAL:
                body = <ChangePasswordModal/>;
                modalSize = "small";
                dialogClassName = "change-password-modal";

                break;

            case ADD_NEW_CARD_MODAL:
                body = <AddNewCardForm {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "";

                break;

            case ADD_NEW_BANK_ACCOUNT_MODAL:
                body = <AddNewBankAccountForm {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "";

                break;

            case CREATE_PAYMENT_PROFILE_MODAL:
                body = <CreatePaymentProfileForm {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "";

                break;

            case CREATE_PAYMENT_ORDER_MODAL:
                body = <GetPaidPopup {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "get-paid";

                break;

            case PAYMENT_APPROVAL_MODAL:
                body = <PaymentApprovalPopup {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "payment-approval";

                break;

            case ALERT_SUBSCRIPTION_MODAL:
                body = <AlertSubscriptionModal {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "alert-modal";

                break;

            case SUBSCRIPTION_APPROVAL_MODAL:
                body = <SubscriptionApprovalModal {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "subscription-approval-modal";

                break;
            case SUBSCRIPTION_FINISH_MODAL:
                body = <SubscriptionFinishModal {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "subscription-finish-modal";

                break;
            case SERVICE_EDIT_MODAL:
                body = <ServiceEditModal {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "service-edit-modal";

                break;
            case SALON_APPOINTMENT_DETAIL_MODAL:
                body = <AppointmentDetailModal {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "app-detail-modal";

                break;
            case PERSONAL_APPOINTMENT_DETAIL_MODAL:
                body = <PersonalAppointmentModal {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "app-detail-modal";

                break;

            case ARTIST_SERVICE_MODAL:
                body = <ArtistServiceModal {...this.state.modalOptions} />;
                modalSize = "small";
                dialogClassName = "app-detail-modal";

                break;

            case AFFILIATE_FORM_MODAL:
                body = <AffiliateFormModal {...this.state.modalOptions}/>;
                modalSize = "small";
                dialogClassName = "affiliate-modal";
                break;

            case PRO_PRODUCTS_VIDEO_MODAL:
                body = <ProProductsVideoModal {...this.state.modalOptions}/>;
                modalSize = "large";
                dialogClassName = "video-modal";
                break;

            default:
                body = "";
                modalSize = "large";
                dialogClassName = "";

                break;
        }

        return (
            <Modal show={this.state.modalShow} onHide={this.close} bsSize={modalSize} backdrop="static"
                   dialogClassName={dialogClassName}
                   animation={true}>
                {body}
            </Modal>
        );
    }
}
