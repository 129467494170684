/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.09.2018
 * Time: 12:02
 */

import ApiClient from "./../service/api-client";

export const xhrGetAllSalonAddresses = function() {

    let url = ApiClient.getRouting().generate(("Q95_get_all_salon_addresses"));

    return ApiClient.get(url)
    .then((answer) => {
        let [response] = answer;

        return Promise.resolve({
            data: response.response
        });
    });
};
