/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 11.10.2017
 * Time: 16:24
 */
import React from 'react';
import {Col, Row, Badge} from 'react-bootstrap';
import printedSum from '../../../../helper/print-sum';
import {LocalTime} from '../../../helper-components/time-component';
import idGenerator from "../../../../helper/id-generator-component";
import PropTypes from 'prop-types';

export default function TransactionWidget(props) {
    let title = props.mode === "salon" ? "Subscription fees" : "Transactions";
    let emptyTable = props.mode === "salon" ? "No history yet" : "No transactions yet";

    return <div>
        <div className="subscription-profile__plan-title">
            {title}
        </div>
        <Row className="fr-list-header bordered">
            <Col xs={4} md={4}>Date</Col>
            <Col xs={3} md={3}>Amount</Col>
            <Col xs={5} md={5}>Status</Col>
        </Row>
        {props.sources !== undefined &&
        <div className="bank-card-profile__payment-sources">
            {props.sources.map(transaction => <Row key={idGenerator('transaction')}
                                                   className="bank-card-profile__payment-sources-table fr-table__border-bottom">

                <Col md={4} xs={4} className="info-column">
                      <span>
                            <LocalTime
                                timestamp={transaction.created}
                                format="ll"
                            />
                          <br/>
                          <LocalTime timestamp={transaction.created} format={"hh:mm A"}/>
                        </span>
                </Col>

                <Col md={3} xs={3} className="info-column fr-text-overflow">
                    $ {printedSum(transaction.amount / 100)} <br/>
                    {props.mode === "client" && transaction.salonName}
                </Col>
                <Col md={5} xs={5}>
                    {transaction.cardBrand}
                    &nbsp;&bull;&nbsp;
                    {transaction.cardLast4}
                    <br/>
                    <Badge
                        className={transaction.status === 'succeeded' ? 'transaction-status__success' : 'transaction-status__other'}>{transaction.status}</Badge>
                </Col>

            </Row>)}
        </div>}
        {props.sources.length === 0 &&
        <div className="fr-mt fr-text-center">{emptyTable}</div>
        }
    </div>;
}
TransactionWidget.propTypes = {
    sources: PropTypes.array,
    mode: PropTypes.string
};


TransactionWidget.defaultProps = {
    sources: [],
    mode: 'client'
};