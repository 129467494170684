/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 12.09.2017
 * Time: 17:30
 */

import {
    GET_COUPON,
    LOAD_SUBSCRIPTION_PRODUCTS,
    PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ERROR,
    PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR,
    PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
    SALON_SUBSCRIPTION_ADDED,
    SALON_SUBSCRIPTION_CARD_ADDED,
    SALON_SUBSCRIPTION_CHANGED,
    SALON_SUBSCRIPTION_CREATE_ERROR,
    SELECT_PLAN,
    SUBSCRIPTION_APPROVAL_MODAL,
    SUBSCRIPTION_FINISH_MODAL
} from "../constants";
import BaseStore from "./base-store";
import { modalChange } from "../ac/modals";

export default class SubscriptionsStore extends BaseStore {

    constructor(...args) {
        super(...args);
        this.store = {
            products: [],
            showAlert: false,
            selectedPlan: [],
            subscription: [],
            defaultPaymentCard: [],
            coupon: [],
            violations: [],
            couponError: false,
            currentPlan: [],
            userPlatform: null
        };

        this._registerActionSubscription((action) => {
                const { type, payload } = action;

                switch (type) {
                    case PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR:
                    case PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ERROR:
                        this.store.violations = payload;

                        break;
                    case LOAD_SUBSCRIPTION_PRODUCTS:
                        this.store.products = payload.data;
                        this.store.userPlatform = payload.userPlatform;
                        break;

                    case SELECT_PLAN:
                        this.store.selectedPlan = payload;
                        break;

                    case PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED:

                        this.store.defaultPaymentCard = this.getDefaultCard(payload);
                        this.store.currentPlan = this.getCurrentPlan(payload);

                        break;

                    case GET_COUPON:

                        this.store.coupon = payload;
                        this.store.couponError = payload.length === 0 || !this.store.coupon.valid;

                        break;

                    case SALON_SUBSCRIPTION_CARD_ADDED:
                        this.store.defaultPaymentCard = this.getDefaultCard(payload);
                        if (this.store.defaultPaymentCard.length > 0) {
                            setTimeout(() => modalChange(SUBSCRIPTION_APPROVAL_MODAL, {
                                selectedPlan: this.store.selectedPlan,
                                paymentCard: this.store.defaultPaymentCard[0],
                                violations: this.store.violations,
                                currentPlan: this.store.currentPlan
                            }), 0);
                        }

                        break;
                    case SALON_SUBSCRIPTION_ADDED:
                    case SALON_SUBSCRIPTION_CHANGED:
                        this.store.subscription = payload;

                        setTimeout(() => modalChange(SUBSCRIPTION_FINISH_MODAL, {
                            planName: this.store.selectedPlan.nickName,
                            serverErrors: "",
                            userPlatform: this.store.userPlatform
                        }), 0);

                        break;

                    case SALON_SUBSCRIPTION_CREATE_ERROR:
                        this.store.serverErrors = payload.message;
                        setTimeout(() => modalChange(SUBSCRIPTION_FINISH_MODAL, {
                            planName: this.store.selectedPlan.nickName,
                            serverErrors: this.store.serverErrors
                        }), 0);
                        break;


                    default:
                        return true;
                }

                this.emitChange();
            }
        );
    }

    getFilteredPlans(interval) {
        let products = this.store.products || [];
        let plans = [];
        products.map((product) => {
                return product.plans.map(plan => {
                        if (plan.billingInterval === interval) {
                            plan.metadata = product.metadata;
                            plans.push(plan);
                        }
                        return plans;
                    }
                );
            }
        );
        return plans;
    }

    getDefaultCard(sources) {
        let card = [];
        let cardId = sources.default_source;
        if (cardId !== null) {
            card = sources.sources.data.filter(function(source) {
                return source.id === cardId;
            });
        }
        return card;
    }

    getCurrentPlan(data) {
        let plan = [];
        let subscriptionsData = data.subscriptions ? data.subscriptions.data : [];
        if (subscriptionsData.length) {
            plan = subscriptionsData[0].plan;
        }
        return plan;
    }
}
