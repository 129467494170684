/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.2.10
 * Time: 10:20
 */

export default class Filtration {

    constructor() {
        this.filters = {};
    }

    /**
     * @public
     * @param field
     * @param rest
     */
    registerFilter(field: string, ...rest) {

        if (this.filters[field]) {

            throw  new TypeError("Filter already registered");
        }

        this.filters[field] = rest;
        return this;
    }

    /**
     * @public
     * @param obj
     */
    filtrate(obj) {
        for (let prop in obj) {
            if (this.filters.hasOwnProperty(prop)) {
                obj[prop] = this.applyFilters(obj[prop], this.filters[prop]);
            }
        }

        return obj;
    }

    /**
     * @protected
     * @param value
     * @param filters
     * @returns {*}
     */
    applyFilters(value, filters) {

        let result = value;
        filters.forEach((filter) => {
            result = filter.filter(result);
        });

        return result;
    }
}
