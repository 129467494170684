/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 31.07.17
 * Time: 10.24
 */

/**
 * Analogue Array.prototype.filter for hashes
 * @param hash
 * @param fn
 * @returns {Object}
 */
export function hashFilter(hash, fn) {

    let filtered = Object.create(null);

    Object.keys(hash).forEach(key => {
        let ret = fn(hash[key], key);

        if (ret === true) {
            filtered[key] = hash[key];
        }
    });

    return filtered;
}
