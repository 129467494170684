import {
    LOAD_SALON_LOCATION,
    LOAD_SALON_SERVICES,
    PROMO_INITIALS_LOADED,
    SALON_PROMO_INITIALS_SET,
    SALON_PROMO_RESET_GALLERY
} from "./../constants";
import AppDispatcher from "./../dispatcher";
import ApiClient from "./../service/api-client";
import {
    getPublicSalonData,
    getPublicSalonDataByUrl,
    xhrLoadSalonPromoLocationData,
    xhrLoadSalonPromoServicesData
} from "../xhr/salon-promo-data-xhr";
import { singleLookNormalizer } from "../normal-form/gallery/professional-profile/single-look-normalizer";
import { singleAvatarNormalizer } from "../normal-form/gallery/professional-profile/single-avatar-normalizer";
import { brandNormalize2 } from "../normal-form/brand";
import { SALON_PUBLIC_DATA_LOADED } from "../constants";

export function loadSalonLocation(userUrl: string) {
    xhrLoadSalonPromoLocationData(userUrl).then(response => {
        AppDispatcher.dispatch({
            type: LOAD_SALON_LOCATION,
            payload: response
        });
    });
}

export function loadSalonServices(userUrl: string) {
    xhrLoadSalonPromoServicesData(userUrl).then(response => {
        AppDispatcher.dispatch({
            type: LOAD_SALON_SERVICES,
            payload: response
        });
    });
}

export function loadSalonPromoGallery({ offset, count, salonUrl, artistId, categoryId, serviceId }) {

    let params = {
        tab: "gallery",
        url: salonUrl,
        offset: offset,
        limit: count,
        artistId,
        serviceId: serviceId || categoryId
    };

    return ApiClient.get(["Q104_promo_tabs_by_url", params]).then(answer => {
        let [response] = answer;

        return {
            items: response.data ? response.data.map(singleLookNormalizer) : [],
            salonId: response.salonId,
            salonName: response.salonName
        };
    });
}

export function loadSalonPromoArtists({ offset, count, salonUrl }) {

    let params = {
        tab: "artists",
        url: salonUrl,
        offset: offset,
        limit: count
    };

    return ApiClient.get(["Q104_promo_tabs_by_url", params]).then(answer => {
        let [response] = answer;

        return {
            items: response.data ? response.data.map(singleAvatarNormalizer) : [],
            salonId: response.salonId,
            salonName: response.salonName
        };
    });
}

export function loadSalonPromoBrands({ offset, count, salonUrl }) {

    let params = {
        tab: "brands",
        url: salonUrl,
        offset: offset,
        limit: count
    };

    return ApiClient.get(["Q104_promo_tabs_by_url", params]).then(answer => {
        let [response] = answer;

        return {
            items: response.data ? response.data.map(brandNormalize2) : [],
            salonId: response.salonId,
            salonName: response.salonName
        };
    });
}

export function loadPromoInitials(userUrl: string) {
    let url = ApiClient.getRouting().generate("Q104_promo_tabs_by_url", {
        url: userUrl,
        tab: "location"
    });

    ApiClient.get(url).then((res) => {
        AppDispatcher.dispatch({ type: PROMO_INITIALS_LOADED, payload: res[0] });
    });
}

export function resetGalleryStore() {
    AppDispatcher.dispatch({ type: SALON_PROMO_RESET_GALLERY });
}

export function setPromoInitials(data) {
    AppDispatcher.dispatch({ type: SALON_PROMO_INITIALS_SET, payload: data });
}

export function loadPublicSalonDataById(id) {
    getPublicSalonData(id).then(data => {
        AppDispatcher.dispatch({
            type: SALON_PUBLIC_DATA_LOADED,
            payload: data
        });
    });
}

export function loadPublicSalonDataByUrl(id) {
    getPublicSalonDataByUrl(id).then(data => {
        AppDispatcher.dispatch({
            type: SALON_PUBLIC_DATA_LOADED,
            payload: data
        });
    });
}
