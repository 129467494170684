/**
 * Created by fliak on 22.2.17.
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_NOT_A_DATE = "VALUE_IS_NOT_A_DATE";
export const VALUE_IS_NOT_IN_FUTURE = "VALUE_IS_NOT_IN_FUTURE";
export const VALUE_IS_NOT_IN_PAST = "VALUE_IS_NOT_IN_PAST";

export default class IsDate extends BaseConstraint {

    constructor(options = null) {
        super();

        this.breakOnError1 = true;

        this.message = "Invalid date";
        this.messageIsNotInFuture = "Future date required";
        this.messageIsNotInPast = "Past date required";

        this.inFutureOnly = false;
        this.inPastOnly = false;

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        return "is-date";
    }
}
