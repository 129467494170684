/**
 * User: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 22.3.17
 * Time: 17.13
 */

import React, {Component} from 'react';

import {loadSalonServices} from './../../ac/salon-promo-action';
import {updateIndex} from '../../components/profile/professional/services/helpers';
import {salonPromoStore} from './../../stores/index';
import SalonServicesList from '../../components/profile/professional/services/salon-services-list';
import LoaderBar from "../../elements/loader-bar";


export default class SalonPromoServices extends Component {

    constructor(props) {
        super(props);

        loadSalonServices(props.params.url);

        this.state = {
            salonServiceData: [
                [],
                [],
                []
            ]
        };

        this.onLoad = this.onLoad.bind(this);
    }
    onLoad() {
        let store = salonPromoStore.getStore();
        this.setState({
            salonServiceData: store.services.data
        });
    }

    componentDidMount() {
        salonPromoStore.addChangeListener(this.onLoad);
    }
    componentWillUnmount() {
        salonPromoStore.removeChangeListener(this.onLoad);
    }

    prepareServices(){

        let services = this.state.salonServiceData[0];
        let servicesIndex = updateIndex(services);
        let selectedServices = [];

        this.state.salonServiceData[1].forEach( item => {
            if (servicesIndex[item.serviceId]){
                selectedServices.push(Object.assign(servicesIndex[item.serviceId], item, {leaf: true}));
            }
        });

        return ({services, selectedServices, servicesIndex})

    }

    render() {

       if (this.state.salonServiceData[0].length) {
           let preparedServices = this.prepareServices();

           return <div> <SalonServicesList promo={true} {...preparedServices} onServiceModify={()=> false}
                                      onServiceRemove={()=>false}/>
           </div>

       }

       return <LoaderBar className={"loader-msg-wrapper--full-height"}/>
    }
}
