/**
 * Created by Alexey Ilasov on 15.02.2017.
 */

import {
    JOURNAL_ELEMENT_APPOINTMENT,
    MODE_CLIENT,
    MODE_PROFESSIONAL,
    USER_TYPE_FREELANCER,
    USER_TYPE_MASTER,
    USER_TYPE_SALON_MASTER
} from "../frizo-shared-constants";

function setAptConfig(session, artistsIndex, artists, config, schedule) {

    let clientId = config.clientId;
    let addressId = config.addressId;
    let mode = config.mode;
    let salonId = config.salonId;
    let artistId = config.artistId;
    let type = JOURNAL_ELEMENT_APPOINTMENT;
    let artist = artistId ? artistsIndex[artistId] : artists[0];
    let result = { type, salonId };

    switch (artist.rawUserType) {
        case USER_TYPE_FREELANCER: {
            artistId = artist.id;
            if (schedule) {
                Object.assign(result, {
                    artistId,
                    addressId: schedule.addressId
                });
            }
            break;
        }

        case USER_TYPE_SALON_MASTER: {
            artistId = artist.id;
            addressId = artist.schedule[0].address.id;
            Object.assign(result, { artistId, addressId });
            break;
        }

        case USER_TYPE_MASTER: {
            addressId = artist.schedule[0].address.id;
            Object.assign(result, { addressId });
            break;
        }

        default:

            return true;
    }

    switch (mode) {
        case MODE_CLIENT: {
            clientId = session.id;
            Object.assign(result, { clientId });
            break;
        }

        case MODE_PROFESSIONAL:
            break;

        default:

            return true;
    }

    return result;
}

export default setAptConfig;
