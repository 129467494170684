/**
 * Created by fliak on 10.1.17.
 */

import {
    BOOK_PERSIST,
    BOOK_PERSIST_FAIL,
    BOOK_PERSIST_STOP,
    BOOK_PERSIST_SUCCESS,
    BOOK_START_BOOKING
} from "../../constants";

import BaseStore from "./../base-store";

class BookAptConfStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            violations: {},
            showViolations: false,
            bookDisabled: false,
            config: {
                mode: null, //MODE_CLIENT, MODE_PROFESSIONAL
                artistId: null,
                salonId: null,
                clientId: null,
                date: null,
                startTime: null,
                serviceId: null,
                imageId: null,
                imageURL: null,
                action: null
            }
        };


        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case BOOK_START_BOOKING:
                    this.configure(payload);
                    this.store.showViolations = false;
                    this.store.violations = {};
                    this.store.bookDisabled = false;
                    this.setReady(true);

                    break;

                case BOOK_PERSIST_STOP:
                    this.store.bookDisabled = false;
                    break;

                case BOOK_PERSIST:
                    this.store.showViolations = false;
                    this.store.violations = {};
                    payload.syncer.setMode(this.getMode());
                    this.store.bookDisabled = true;

                    break;

                case BOOK_PERSIST_FAIL:
                    if (payload.fatal) {
                        Object.assign(this.store.violations, {
                            general: ["SHiFT happened while saving the appointment"]
                        });
                        this.store.showViolations = true;
                    }
                    this.store.bookDisabled = false;

                    break;

                case BOOK_PERSIST_SUCCESS:
                    this.store.bookDisabled = false;

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    configure(config) {
        Object.assign(this.store.config, config);
    }

    getMode() {
        return this.store.config.mode;
    }

    getConfig() {
        return this.store.config;
    }
}

export default BookAptConfStore;
