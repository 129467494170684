/**
 * Created by x_a_x_a on 05.01.2017
 * User: Eleonora Kolodinskaya
 * Company: U6 SIA
 */

import React, {Component} from 'react';
import {Modal, Button, Form, FormGroup} from 'react-bootstrap';
import {LOGIN_MODAL} from '../../constants';
import {modalChange} from '../../ac/modals';
import ApiClient from '../../service/api-client';
import {
    LINK_SENT,
    SERVER_ERROR
} from './../../frizo-shared-constants';
import gradientFill from './../../helper/gradient-fill';

export default class ForgotModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            showMessage: false,
            showError: false,
            showServerError: false,
            modal: {
                show: false,
                data: ''
            }
        };
    }

    close() {
        this.props.close();
    }

    openModal() {
        modalChange(LOGIN_MODAL);
    }

    handleEmailChange() {
        return function (e) {
            this.setState({email: e.target.value});
        }.bind(this);
    }

    resetPassword() {
        this.setState({showMessage: false});
        ApiClient.post(['Q14_forgot_password'], {username: this.state.email}, {run_directly: true}).then(answer => {
            let [response, xhr] = answer;

            let status = xhr.status === 200 ? LINK_SENT : SERVER_ERROR;

            switch (status) {
                case LINK_SENT:
                    this.setState({showMessage: true, showError: false, showServerError: false});
                    break;

                default:
                case SERVER_ERROR:
                    this.setState({showMessage: false, showServerError: true});
                    break;

            }

            return Promise.resolve({
                response: response.response
            });

        });


    }

    validateEmail() {
        /* eslint no-useless-escape: "off" */
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(this.state.email)) {
            this.setState({showError: false, showMessage: false, showServerError: false});
            this.resetPassword();
        } else {
            this.setState({showError: true, showMessage: false, showServerError: false});
        }
    }

    render() {
        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>Password reset</h2>
                </Modal.Header>
                <Modal.Body className="password-reset-modal__body">
                    {this.state.showServerError && <div className="error-message">Server error. Please try again</div>}
                    <p>Please enter your e-mail.<br/>We will send you a new password right away.</p>
                    <Form>
                        <FormGroup>
                            <input className="form-control" type="email" required placeholder="E-mail" name="email"
                                   onChange={this.handleEmailChange()}/>
                            {this.state.showError &&
                            <div className="error-message">The entered e-mail address is incorrect</div>}
                        </FormGroup>

                    </Form>
                    {this.state.showMessage &&
                    <div className="info-message">The password reset link will be send to the entered e-mail
                        address</div>}
                </Modal.Body>
                <Modal.Footer className="password-reset-modal__footer">
                    <div className="pull-left">
                        <Button className="btn" onClick={this.openModal.bind(this)}>Cancel</Button>
                    </div>
                    <div className="pull-right">
                        <Button className="fr-btn-reset btn" onClick={this.validateEmail.bind(this)}>Reset</Button>
                    </div>
                </Modal.Footer>
            </div>
        );
    }
}
