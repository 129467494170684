/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 11.10.2017
 * Time: 10:24
 */

import React, {Component} from 'react';
import {sessionStore, paymentProfileStore} from '../../../stores';
import {Row, Col, Grid, Badge, Pagination} from 'react-bootstrap';
import {Link} from 'react-router';
import SpinnerGif from '../../../elements/spinner-gif';
import LoaderBar from '../../../elements/loader-bar';


import {
    loadStripeAccount,
    loadSalonSubscriptionCharges,
    cancelSalonSubscription,
    getSalonSubscriptionCharges
} from '../../../ac/payments/payments-professional';

import PayWidget from './cards-and-bank/pay-widget';
import TransactionWidget from './cards-and-bank/transaction-widget';

import Violation from '../../helper-components/violation';

import {PAGINATION_ITEMS_COUNT} from "../../../frizo-shared-constants";

export default class ProfessionalProfileSubscription extends Component {
    constructor(...args) {
        super(...args);

        this.state = Object.assign({
            ready: false,
            artistId: null,

            verified: false,
            profileExist: false,

            subscriptionCustomer: {
                sources: [],
            },
            subscriptionPlan: [],
            subscriptionCharges: {
                sources: []
            },
            btnDisabled: false,
        }, this.getDataFromStore());
        this.state.currentPageNumber = 1;

        this.changePaymentProfile = this.changePaymentProfile.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        sessionStore.getReadyPromise().then(store => {
            this.setState({
                artistId: sessionStore.getUserId()
            });
        });

        let params = {
            mode: 'salon',
            limit: PAGINATION_ITEMS_COUNT,
            offset: 0,
        };
        loadStripeAccount();
        getSalonSubscriptionCharges(params);
        paymentProfileStore.addChangeListener(this.changePaymentProfile);

    }

    componentWillUnmount() {
        paymentProfileStore.removeChangeListener(this.changePaymentProfile);
    }

    changePaymentProfile() {
        this.setState(Object.assign({}, this.getDataFromStore(), {
            ready: true
        }));
    }

    getDataFromStore() {
        return paymentProfileStore.getStore();
    }

    cancelSubscription() {
        this.setState({btnDisabled: true});
        cancelSalonSubscription();
    }

    handleSelect(eventKey) {

        let params = {
            mode: 'salon',
            limit: PAGINATION_ITEMS_COUNT,
            offset: (eventKey - 1) * PAGINATION_ITEMS_COUNT,
        };

        this.setState({
            currentPageNumber: eventKey
        });

        loadSalonSubscriptionCharges(params);
    }


    render() {

        if (!this.state.ready) return <LoaderBar className="loader-msg-wrapper--full-height"/>;

        let totalPages = Math.ceil(this.state.subscriptionCharges.count / PAGINATION_ITEMS_COUNT);
        let maxVisibleButtons = (window.innerWidth < 769 ? 3 : 7);

        let subscriptionPlan = this.state.subscriptionPlan;
        let planTitle = 'Not subscribed ';
        let planStatus = '';
        if ((subscriptionPlan !== undefined) && (subscriptionPlan.plan !== undefined)) {
            planTitle = 'Current plan: ' + subscriptionPlan.plan.metadata.title;
            planStatus = subscriptionPlan.status;
        }

        return <div className="subscription-profile">

            <Row className="subscription-profile__section-separator">
                <Col md={6} xs={12}>
                    <div className="subscription-profile__plan-title">
                        <div className="fr-inline" dangerouslySetInnerHTML={{__html: planTitle}}></div>
                        {planStatus !== '' && <Badge className="subscription-status__success ">{planStatus}</Badge>}
                    </div>
                </Col>
                <Col md={6} xs={12} className="">

                    {subscriptionPlan === undefined &&
                    <Link to='/subscription'
                          className="btn fr-btn-add subscription-profile__btn-subscribe">
                        SUBSCRIBE</Link>}
                    <div className={"subscription-profile__btn-subscribe"}>
                        {this.state.btnDisabled === true &&
                        <div className={"subscription-profile__spinner"}><SpinnerGif/></div>}
                        {subscriptionPlan !== undefined &&
                        <button className="btn fr-btn-remove "
                                onClick={this.cancelSubscription}
                                disabled={this.state.btnDisabled}>
                            UNSUBSCRIBE</button>}
                    </div>
                </Col>
            </Row>


            {this.state.subscriptionCustomer.defaultSourceId !== null && <Grid className="bank-card-profile__container">
                <Row>

                    <Col md={6} className="fr-mt fr-mb-xs">
                        <PayWidget
                            title={'Cards'}
                            sources={this.state.subscriptionCustomer.sources}
                            defaultSourceId={this.state.subscriptionCustomer.defaultSourceId}
                        />

                    </Col>

                    <Col md={6} className="fr-mt fr-mb-xs">
                        <TransactionWidget
                            sources={this.state.subscriptionCharges.sources} mode="salon"
                        />
                        {totalPages > 1 && <Pagination
                            className="fr-pagination-standard fr-ml"
                            bsSize="medium"
                            items={totalPages}
                            activePage={this.state.currentPageNumber}
                            onSelect={this.handleSelect}
                            maxButtons={maxVisibleButtons} first={totalPages > 1 ? "<<" : null}
                            last={totalPages > 1 ? ">>" : null} next={totalPages > 1 ? ">" : null}
                            prev={totalPages > 1 ? "<" : null}
                            ellipsis={totalPages > 1 ? "..." : null}
                        />}
                    </Col>

                </Row>
                <Row>
                    <Col md={2} mdOffset={5}>
                        <Violation violationSet={this.state.violations.general} visible={true}/>
                    </Col>
                </Row>
            </Grid>}
        </div>;
    }
}

ProfessionalProfileSubscription.requireData = ['userSalon', 'salonInfo'];