/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.27.6
 * Time: 15:12
 */

import React, {Component} from 'react';
import {MenuItem} from 'react-bootstrap';
import ClientPhoneItem from './client-phone-item';

export default class FindListList extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);

    }

    onClick(phone) {

        return () => {
            this.props.onClick(phone);
        };
    }

    makeMenuItems() {

        return this.props.filtered.map(item => {
            let listItem = React.createElement(this.props.listItem, item, null);
            let className = this.props.className;
            if (this.props.selected && (this.props.selected.id === item.id)) {
                className = className + ' active';
            }

            return (<MenuItem
                key={item.id}
                onKeyPress={this.props.onKeyPress}
                onKeyDown={this.props.onKeyDown}
                onClick={this.onClick(item.phone)}
                className={className}
            >
                {listItem}
            </MenuItem>);
        });
    }

    render() {

        if (this.props.show === false || this.props.force === true) return null;

        if (this.props.filtered.length) {
            return (<ul className="dropdown-menu open find-client-list">
                {this.makeMenuItems()}
            </ul>);
        }

        return null;
    }

}

FindListList.defaultProps = {
    list: [],
    show: false,
    force: false,
    onChange: () => {
        console.log('Change Event')
    },
    onClick: () => {
        console.log('Click Event')
    },
    listItem: ClientPhoneItem
};