/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.29.9
 * Time: 17:58
 */

import AbstractFilter from "./abstract-filter";

export default class TrimFilter extends AbstractFilter {

    /**
     * @public
     * @returns {string}
     */
    getName() {

        return "trim_filter";
    }

    /**
     * @public
     * @param value
     * @returns {string}
     */

    filter(value: string) {

        return value.trim();
    }
}
