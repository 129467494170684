/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 25.7.17
 */

import { paymentOrderServiceNormalize } from "./payment-order-service";
import { addressNormalize1 } from "./address";

export function paymentOrderNormalize(rawElement) {

    let normalForm = {
        id: rawElement.id,
        address: rawElement.address,
        date: rawElement.date,
        paymentDetail: rawElement.paymentDetail,
        paymentType: rawElement.paymentType, //PAYMENT_TYPE_CASH or PAYMENT_TYPE_STRIPE
        serviceAndTax: rawElement.serviceAndTaxCent,
        serviceTotalDefault: rawElement.serviceTotalDefaultCent,
        serviceTotalEntered: rawElement.serviceTotalEnteredCent,
        status: rawElement.status,
        stripePaymentId: rawElement.stripePaymentId,
        taxPercent: rawElement.taxPercent,
        taxPercentDefault: rawElement.taxPercentDefault,
        taxValue: rawElement.taxValueCent,
        tip: rawElement.tipCent,
        total: rawElement.totalCent,
        create: rawElement.create
    };

    if (rawElement.address) {
        normalForm.address = addressNormalize1(rawElement.address);
    }

    if (rawElement.salon) {
        normalForm.salonId = rawElement.salon.id;
        normalForm.salonName = rawElement.salon.name;
    }

    if (rawElement.salonClient) {
        normalForm.salonClient = {
            id: rawElement.salonClient.id,
            firstName: rawElement.salonClient.firstName,
            lastName: rawElement.salonClient.lastName,
            phone: rawElement.salonClient.phone
        };
    }

    if (rawElement.services) {
        normalForm.services = rawElement.services.map(paymentOrderServiceNormalize);
    }

    return normalForm;
}
