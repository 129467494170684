/**
 * Created by fliak on 22.2.17.
 */

import sprintf from "./../../helper/sprintf";

import ServiceIsLeafValidator from "./service-is-leaf-validator";

import IsRequiredValidator from "./is-required-validator";
import IsNumberValidator from "./is-number-validator";
import LengthValidator from "./length-validator";
import IsAlphaValidator from "./is-alpha-validator";
import IsPasswordMatchValidator from "./is-password-match-validator";
import RegexValidator from "./regex-validator";
import ImageValidator from "./image-validator";
import GalleryFormValidator from "./gallery-form-validator";
import IsDateValidator from "./is-date-validator";
import NumberComparisonValidator from "./number-comparison-validator";
import IsEqualValidator from "./is-equal-validator";
import IsNotEqualValidator from "./is-not-equal-validator";
import DateComparisonValidator from "./date-comparison-validator";

let validatorRegistry = {};
let constraintRegistry = {};

export function registerValidator(validatorClass) {

    let constraints = validatorClass.getValidConstraints();
    if (!Array.isArray(constraints)) throw new Error(
        "Wrong validator '%s'. Method getValidConstraints should return as least one constraint");

    constraints.forEach(costraintClass => {
        if (typeof costraintClass.getName !== "function") {
            throw new Error("getName() static method should be implemented");
        }

        let name = costraintClass.getName();

        constraintRegistry[name] = costraintClass;
        validatorRegistry[name] = validatorClass;
    });

}

export function getConstraint(name) {
    return constraintRegistry[name];
}


let validatorInstanceRegistry = new Map();

export function getValidatorFor(constraintInstance) {
    let constraintName = constraintInstance.constructor.getName();
    let validatorClass = validatorRegistry[constraintName];

    if (validatorInstanceRegistry.has(validatorClass)) {
        return validatorInstanceRegistry.get(validatorClass);
    }

    //validator class should be imported
    try {
        validatorInstanceRegistry.set(validatorClass, new validatorClass());
    }
    catch (e) {
        throw new Error(sprintf("Validator class '%s' should be imported before use. (%s)", validatorClass.name, e));
    }

    return validatorInstanceRegistry.get(validatorClass);
}

registerValidator(ServiceIsLeafValidator);
registerValidator(IsRequiredValidator);
registerValidator(IsNumberValidator);
registerValidator(LengthValidator);
registerValidator(IsAlphaValidator);
registerValidator(IsAlphaValidator);
registerValidator(IsPasswordMatchValidator);
registerValidator(RegexValidator);
registerValidator(ImageValidator);
registerValidator(GalleryFormValidator);
registerValidator(IsDateValidator);
registerValidator(NumberComparisonValidator);
registerValidator(IsEqualValidator);
registerValidator(IsNotEqualValidator);
registerValidator(DateComparisonValidator);
