/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 25.01.2018
 * Time: 10.35
 */


import React, {Component} from 'react';
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type {TGalleryImage} from "../../../types";

import {
    BOOK_APPOINTMENT_NEW,
    BUTTON_DEFAULT, DROPDOWN_PLACEHOLDER_VALUE,
    MODE_CLIENT, BUTTON_DANGER_TRASH, IMAGE_SIZE_MEDIUM
} from "../../../frizo-shared-constants";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import GalleryItemButton from "../gallery-parts/gallery-item-button";
import ArtistImageTitle from "../../gallery/elements/artist-image-title";
import moment from "moment/moment";
import {startBooking} from "../../../ac/book-apt/common-actions";
import {removeArtistAction} from "../../../ac/profile/personal/personal-favorites-actions";

type Props = TGalleryImage & {};

export default class PersonalProfileFavoritesAvatar extends Component<Props, any> {

    bookAction(favorite) {

        if (!favorite.artist || !favorite.salon) {
            throw new Error("No artist or salon specified. Possible broken favorites entry.");
        }

        return startBooking(MODE_CLIENT,
            favorite.artist.id,
            favorite.salon.id,
            moment(new Date()).format('YYYY-MM-DD'),
            DROPDOWN_PLACEHOLDER_VALUE,
            0,
            0,
            null,
            0,
            null,
            BOOK_APPOINTMENT_NEW);
    }

    render() {

        return <GalleryItemContainer>
            <GalleryItemImage {...this.props} size={IMAGE_SIZE_MEDIUM} />
            <GalleryItemCardInfo>
                <ArtistImageTitle
                    firstName = {this.props.artist.firstName}
                    lastName = {this.props.artist.lastName}
                />

                <GalleryItemButtonsContainer>
                    <GalleryItemButton title="Book" type={BUTTON_DEFAULT} onClick={this.bookAction.bind(this, this.props)} />
                    <GalleryItemButton type={BUTTON_DANGER_TRASH} onClick={removeArtistAction.bind(this, this.props.id)} />
                </GalleryItemButtonsContainer>

            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}
