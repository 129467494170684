/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.5.10
 * Time: 15:19
 */

import RegistrationStore from "./registration-store";

export default class ListYourBusinessStore extends RegistrationStore {

    /**
     * @protected
     */
    getInitialStore() {
        this.store = {
            userName: "",
            firstName: "",
            lastName: "",
            salonName: "",
            userTypeCode: "",
            zip: "",
            address: "",
            streetAddress: "",
            phone: "",
            password: "",
            confirmPassword: "",
            facebookID: "",
            referralCode: "",
            showRecaptchaError: false,
            formIsValid: true,
            violations: {}
        };
    }
}
