/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.09.2017
 * Time: 10:27
 */

import React, {Component} from 'react'
import {Button} from 'react-bootstrap';
import {pricingStore} from "../../../stores/index";
import {MODE_PRICING} from "../../../frizo-shared-constants";

export default class SubscriptionPlan extends Component {

    toggleActive() {
        this.props.toggleActive(this.props.product.cardId)
    }

    startSubscription() {
        this.props.onStartSubscription(this.props.product);
    }


    render() {
        let product = this.props.product;
        let {selectedPlan, itemIndex} = this.props;
        let selectedPlanClass = selectedPlan === itemIndex ? 'fr-pricing-flex__item--selected' : '';
        let modePlanClass = this.props.mode === MODE_PRICING ? 'bordered' : '';
        let annualAmount, monthToMonthAmount, monthlyPrice;
        if (this.props.mode === MODE_PRICING) {
            annualAmount = product.plans.find(item => item.billingInterval === 'year') || {};
            monthToMonthAmount = product.plans.find(item => item.billingInterval === 'month') || {};
            monthlyPrice = annualAmount.amount / 12 / 100;
        }
        else {
            monthlyPrice = this.props.interval === 'year' ? product.amount / 12 / 100 : product.amount / 100;
        }


        let btnCaption = this.props.mode === MODE_PRICING ? 'Subscribe' : 'Start now';

        return (
            <div className={`fr-pricing-flex__item ${selectedPlanClass} ${modePlanClass}`}>
                <div key={product.cardId}
                     className={`pricing-plan-item--top`}>
                    <h3 className={`pricing-plan-item__name `}
                    >${monthlyPrice} per month</h3>
                    <p className="pricing-plan-item__quality pricing-plan-item__quality--padding-top"
                       dangerouslySetInnerHTML={{__html: product.metadata.features}}></p>
                    <div className={`pricing-plan-item--bottom `}>
                        {pricingStore.store.userAppType === null &&
                        <Button className="pricing-plan-item__start"
                                onClick={this.startSubscription.bind(this)}>{btnCaption}</Button>}
                        {pricingStore.store.userAppType !== null &&
                        <a href="fz-register://" className="button btn-link pricing-plan-item__start">Start now</a>}
                    </div>
                </div>
                {this.props.mode === MODE_PRICING && <div className="pricing-plan-item__quality--note">
                    <p>Price shown is for annual<br/>
                        payment.<br/>
                        When billed month-to-month,<br/>
                        price is ${monthToMonthAmount.amount / 100} per month.</p>
                </div>}
            </div>
        )
    }
}
