/**
 * Created by fliak
 * Name: George Novik
 * Company: U6 SIA
 * Date: 05.12.2017
 * Time: 14:40
 */

import React, { Component } from 'react';
import { Img } from "../../../elements/img";
import {
    BOOK_APPOINTMENT_NEW, BUTTON_DEFAULT, DROPDOWN_PLACEHOLDER_VALUE,
    IMAGE_SIZE_LARGE, MODE_CLIENT
} from "../../../frizo-shared-constants";
import { FavoriteMark } from "../../favorites/favorite-mark";
import GalleryFullscreenItemContainer from "../gallery-parts/gallery-fullscreen-item-container";
import noPhoto from './../../../../../img/frizo-no-photo-medium.png';
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import ArtistImageTitle from "../../gallery/elements/artist-image-title";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import GalleryItemButton from "../gallery-parts/gallery-item-button";
import moment from "moment/moment";
import {startBooking} from "../../../ac/book-apt/common-actions";
import GalleryShareButton from '../gallery-parts/gallery-share-button';
import ApiClient from "../../../service/api-client";
import getImageTitle from '../../../helper/get-image-title';

export default class PersonalProfileFavoritesFullscreenAvatar extends Component {

    bookAction(favorite) {
        //console.log('favorite', favorite);

        if (!favorite.artist || !favorite.salon) {
            throw new Error("No artist or salon specified. Possible broken favorites entry.");
        }

        return startBooking(MODE_CLIENT,
            favorite.artist.id,
            favorite.salon.id,
            moment(new Date()).format('YYYY-MM-DD'),
            DROPDOWN_PLACEHOLDER_VALUE,
            0,
            0,
            null,
            0,
            null,
            BOOK_APPOINTMENT_NEW);
    }

    render() {
        let imageUrl = this.props.currentItem.image ? this.props.currentItem.image.url : noPhoto;

        let shareUrl = ApiClient.getRouting().generate("frizo_salons_artist_image_item", {userId: this.props.currentItem.id}, true);
        let imgDescription = getImageTitle(this.props.currentItem);

        return <GalleryFullscreenItemContainer>

            {this.props.favoritesEnabled && <FavoriteMark id={this.props.currentItem.id} type={this.props.currentItem.galleryItemType} inModal={true} /> }

            <Img size={IMAGE_SIZE_LARGE} className="img-responsive" src={imageUrl} description={imgDescription} />

            {this.props.showPrev && this.props.prevArrow}
            {this.props.showNext && this.props.nextArrow}

            <GalleryItemCardInfo>
                <ArtistImageTitle
                    firstName = {this.props.currentItem.artist.firstName}
                    lastName = {this.props.currentItem.artist.lastName}
                />

                <GalleryItemButtonsContainer>
                    <GalleryItemButton title="Book" type={BUTTON_DEFAULT} onClick={this.bookAction.bind(this, this.props.currentItem)} />
                    <GalleryShareButton title="Share" className="fr-btn fr-card-btn__item " shareUrl={shareUrl} imageUrl={imageUrl}/>
                </GalleryItemButtonsContainer>
            </GalleryItemCardInfo>

        </GalleryFullscreenItemContainer>
    }

}
