import React, {Component} from 'react'
import stores from '../stores'
import {Row, Col} from 'react-bootstrap';
import {modalChange} from '../ac/modals'
import {LOGIN_MODAL} from '../constants'
import {Link, browserHistory} from 'react-router'
import AuthLink from './../require-auth/auth-link';
import ProfileCompletenessBadge from './badge/profile-completeness-badge';
import {profileCompletenessStore, sessionStore} from './../stores';

import analytics from "../service/analytics";
import {logout} from "../auth";
import gradientFill from '../helper/gradient-fill';
import {centToUsd} from "../helper/money-converter";
import printSum from '../helper/print-sum';

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.sessionStore = stores.session;

        this.state = {
            session: stores.session.getSession(),
            completeness: this.getCompletenessStoreData()
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCompletenessChange = this.handleCompletenessChange.bind(this);

        this.navbarLeft = this.navbarRight = '';
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.handleCollapseClick = this.handleCollapseClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.sessionStore.addChangeListener(this.handleChange)
        window.addEventListener('click', this.handleClickOutside);

        profileCompletenessStore.addChangeListener(this.handleCompletenessChange)
    }

    componentWillUnmount() {
        this.sessionStore.removeChangeListener(this.handleChange)
        window.removeEventListener('click', this.handleClickOutside);

        profileCompletenessStore.removeChangeListener(this.handleCompletenessChange)
    }

    handleCompletenessChange() {
        this.setState({
            completeness: this.getCompletenessStoreData()
        });
    }

    getCompletenessStoreData() {
        return profileCompletenessStore.getStore().status;
    }

    handleClickOutside(e) {
        let dropdownMenu = this.refs.dropdownMenu;
        let menuBeautyPro = this.refs.menuBeautyPro;

        if (dropdownMenu !== undefined) {
            if (dropdownMenu.classList.contains('open') && !dropdownMenu.contains(e.target)) {
                dropdownMenu.classList.remove('open');
            }
        }
        if (menuBeautyPro !== undefined) {
            if (menuBeautyPro.classList.contains('open') && !menuBeautyPro.contains(e.target)) {
                menuBeautyPro.classList.remove('open');
            }
        }

        let navbarCollapse = this.refs.navbarCollapse;
        let navbar = this.refs.navbar;
        if ((navbarCollapse !== undefined) && (navbar !== undefined)) {
            if (navbarCollapse.classList.contains('collapse') && navbarCollapse.classList.contains('in') && !navbar.contains(e.target)) {
                navbarCollapse.classList.remove('in');
            }
        }

    }

    openModal() {
        modalChange(LOGIN_MODAL);
    }

    handleChange() {
        this.setState({
            session: this.sessionStore.getSession()
        })
    }

    handleMenuClick(ref) {
        let dropdownMenu = this.refs[ref];
        if (dropdownMenu !== undefined) {
            if (dropdownMenu.classList.contains('open')) {
                dropdownMenu.classList.remove('open');
            }
            else {
                dropdownMenu.classList.add('open');
            }
        }
    }

    handleLogout(e) {
        e.preventDefault();

        logout();

        this.handleLinkClick();

        return false;
    }

    handleLinkClick() {
        let dropdownMenu = this.refs.dropdownMenu;
        let menuBeautyPro = this.refs.menuBeautyPro;
        let navbarCollapse = this.refs.navbarCollapse;
        if (dropdownMenu !== undefined) {
            if (dropdownMenu.classList.contains('open')) {
                dropdownMenu.classList.remove('open');
            }
        }
        if (menuBeautyPro !== undefined) {
            if (menuBeautyPro.classList.contains('open')) {
                menuBeautyPro.classList.remove('open');
            }
        }
        if (navbarCollapse !== undefined) {
            if (navbarCollapse.classList.contains('collapse') && navbarCollapse.classList.contains('in')) {
                navbarCollapse.classList.remove('in')
            }
        }
    }

    handleCollapseClick() {
        let navbarCollapse = this.refs.navbarCollapse;
        if (navbarCollapse !== undefined) {
            if (navbarCollapse.classList.contains('collapse') && navbarCollapse.classList.contains('in')) {
                navbarCollapse.classList.remove('in');
            }
            else {
                navbarCollapse.classList.add('in');
            }
        }
    }

    render() {

        let navbarLeft = [];
        let navbarRight = [];
        let currentPath = browserHistory.getCurrentLocation().pathname.split("/", 2);
        let badgeVisibility = this.sessionStore.isProfessional() ? (currentPath.indexOf("me") === -1 ) && (currentPath.indexOf("my-salon") === -1) : false;
        let bannerVisibility = (browserHistory.getCurrentLocation().pathname === '/') || (browserHistory.getCurrentLocation().pathname === '/log-in') || (browserHistory.getCurrentLocation().pathname === '/log-out');
        let discount = centToUsd(sessionStore.getDiscountTotal());
        let shortName = this.sessionStore.isLogged() ? this.state.session.firstName + String.fromCharCode(160) + this.state.session.lastName.charAt(0) + '.' : '';

        if (!this.sessionStore.isLogged()) {
            navbarRight.push(
                <div key={1} className={"clients-menu"}>
                    <li>
                        <Link to="/client-sign-up" onClick={this.handleLinkClick}>Client Sign Up</Link>
                    </li>
                    <li className="dropdown dropdown-beauty" ref="menuBeautyPro">
                        <button className="btn fr-btn--inverse dropdown-toggle hidden-xs hidden-sm"
                                data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"
                                onClick={() => this.handleMenuClick("menuBeautyPro")}>
                            Beauty Pro?
                        </button>
                        <ul className="dropdown-menu header-menu__dropdown">
                            <li><Link to="/why-frizo" onClick={this.handleLinkClick}>Why Frizo</Link></li>
                            <li><Link to="/pricing" onClick={this.handleLinkClick}>Pricing</Link></li>
                            <li><Link to="/pro-sign-up" onClick={this.handleLinkClick}>Pro Sign Up</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="fr-mb__login">
                        <Link to="/log-in" onClick={this.handleLinkClick}>Log In</Link>
                    </li>
                </div>
            );
        } else {
            if (this.sessionStore.isProfessional()) {
                navbarLeft.push(
                    <div key={1}>
                        <li>
                            <AuthLink to="/book" onClick={this.handleLinkClick}>Book</AuthLink>
                        </li>
                        <li>
                            <AuthLink to="/clients" onClick={this.handleLinkClick}>Clients</AuthLink>
                        </li>
                        <li>
                            <AuthLink to="/appointments/pending" onClick={this.handleLinkClick}>
                                Appointments
                            </AuthLink>
                        </li>
                    </div>
                );
                navbarRight.push(
                    <div key={2} className={""}>
                        <li className={"hidden-xs hidden-sm"}>
                            <AuthLink to={'/my-salon'}
                                      className="dropdown-toggle  fr-capitalize hidden-xs hidden-sm fr-navbar-dropdown-toggle"
                                      data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                <span
                                    className="glyphicon glyphicon-user"></span>{this.state.session.firstName}{String.fromCharCode(160)}{this.state.session.lastName}
                                {badgeVisibility &&
                                <ProfileCompletenessBadge strength={this.state.completeness.profileGeneric.status}
                                                          className="small-badge"/>}
                            </AuthLink>
                            {discount > 0 &&
                            <span className={"hidden-xs hidden-sm fr-discount"}
                                  title={"Total discount"}>{'$ ' + printSum(discount)}</span>}
                        </li>
                        <li className={"hidden-xs hidden-sm"}><a href='/log-out' onClick={this.handleLogout.bind(this)}><span
                            className="glyphicon  glyphicon-log-out fr-ml hidden-xs hidden-sm"></span></a>
                        </li>

                        <li className="dropdown hidden-lg" ref="dropdownMenu">
                            <button
                                className="btn-link dropdown-toggle fr-capitalize hidden-xs hidden-sm fr-navbar-dropdown-toggle"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                onClick={() => this.handleMenuClick("dropdownMenu")}>
                            <span
                                className="glyphicon glyphicon-user"></span>{shortName}
                                {badgeVisibility &&
                                <ProfileCompletenessBadge strength={this.state.completeness.profileGeneric.status}
                                                          className="small-badge"/>}
                                <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link to='/my-salon' onClick={this.handleLinkClick} className={"no-decoration"}>
                                          <span
                                              className="glyphicon glyphicon-user"></span>{shortName}&nbsp;
                                        {badgeVisibility &&
                                        <ProfileCompletenessBadge className="fr-mb-min small-badge"
                                                                  strength={this.state.completeness.profileGeneric.status}/>}
                                        {discount > 0 && <span className={"fr-discount"}
                                                               title={"Total discount"}>{'$ ' + printSum(discount)}</span>}
                                    </Link>

                                </li>
                                <li><a href='/log-out' onClick={this.handleLogout.bind(this)}>Log out</a></li>
                            </ul>
                        </li>
                    </div>
                );
            }
            else {
                navbarRight.push(<div key={2}>
                        <li className="dropdown dropdown-beauty " ref="menuBeautyPro">
                            <button className=" btn fr-btn--inverse dropdown-toggle hidden-xs hidden-sm"
                                    data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"
                                    onClick={() => this.handleMenuClick("menuBeautyPro")}>
                                Beauty Pro?
                            </button>
                            <ul className="dropdown-menu header-menu__dropdown">
                                <li><Link to="/why-frizo" onClick={this.handleLinkClick}>Why Frizo</Link></li>
                                <li><Link to="/pricing" onClick={this.handleLinkClick}>Pricing</Link></li>
                                <li><Link to="/pro-sign-up" onClick={this.handleLinkClick}>List your business</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <AuthLink to={'/me'}
                                      className="dropdown-toggle  fr-capitalize hidden-xs hidden-sm fr-navbar-dropdown-toggle"
                                      data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
                            >
                        <span
                            className="glyphicon glyphicon-user"></span>{this.state.session.firstName}{String.fromCharCode(160)}{this.state.session.lastName}
                                {badgeVisibility &&
                                <ProfileCompletenessBadge strength={this.state.completeness.profileGeneric.status}
                                                          className="small-badge"/>}
                            </AuthLink>
                            {discount > 0 && <span className={"hidden-xs hidden-sm fr-discount"}
                                                   title={"Total discount"}>{'$ ' + printSum(discount)}</span>}
                        </li>
                        <li>
                            <a className={"hidden-xs hidden-sm"} href='/log-out'
                               onClick={this.handleLogout.bind(this)}><span
                                className="glyphicon  glyphicon-log-out fr-ml"></span></a>
                            <ul className="dropdown-menu hidden-lg">
                                <li><Link to='/me' onClick={this.handleLinkClick} className={"no-decoration"}>  <span
                                    className="glyphicon glyphicon-user"></span>{shortName}
                                    {discount > 0 &&
                                    <span className={"fr-discount"}
                                          title={"Total discount"}>{'$ ' + printSum(discount)}</span>}
                                </Link>
                                </li>
                                <li><a href='/log-out' onClick={this.handleLogout.bind(this)}>Log out</a></li>
                            </ul>
                        </li>
                    </div>
                );
            }
        }

        return (
            <div className="fr-header-wrap" id="header-wrap">
                <div className="container fr-header">
                    <div role="navigation" className="navbar navbar-inverse fr-navbar" ref="navbar">
                        <div className="navbar-header">
                            <button type="button" data-target="#navbarCollapse" data-toggle="collapse"
                                    className="navbar-toggle" onClick={this.handleCollapseClick}>
                                {badgeVisibility &&
                                <ProfileCompletenessBadge className="fr-badge__header small-badge"
                                                          strength={this.state.completeness.profileGeneric.status}/>}
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <h1 className="m0">
                                <Link className="fr-header-logo" to="/"></Link>
                            </h1>
                        </div>
                        <div id="navbarCollapse" className="collapse navbar-collapse" ref="navbarCollapse">
                            <ul className="nav navbar-nav">
                                {navbarLeft}
                            </ul>
                            <ul className="nav navbar-nav navbar-right">
                                {navbarRight}
                            </ul>
                        </div>
                    </div>
                </div>
                    {bannerVisibility && <Row className="fr-banner-main--header" style={{'background': gradientFill()}}>
                    <Col xs={12}>
                        <h1 onClick={analytics.getTrackCallback('fliak_custom_event')}>Browse. Book. Hello&nbsp;Beautiful!</h1>
                    </Col>
                </Row>}
            </div>
        )
    }
}
