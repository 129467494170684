/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 13.29
 */

import React, {Component} from 'react';
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type { TGalleryImage } from "../../../types";
import ArtistImageTitle from "../../gallery/elements/artist-image-title";
import {IMAGE_SIZE_MEDIUM} from "../../../frizo-shared-constants";
import AirtouchCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";

type Props = TGalleryImage & {};

export default class DashboardGalleryItemAvatar extends Component<Props, any> {

    render() {

        let categories = this.props.artistServices.map(service => service.parentTitle);

        //make it unique
        categories = [...new Set(categories)];

        return <GalleryItemContainer>
            <GalleryItemImage {...this.props} size={IMAGE_SIZE_MEDIUM} />
            {this.props.artist.airtouchCertified && <AirtouchCertificateBadge {...this.props.artist}/>}
            <GalleryItemCardInfo>
                <ArtistImageTitle
                    firstName = {this.props.artist.firstName}
                    lastName = {this.props.artist.lastName}
                    categories={categories.join(', ')}
                />
            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}

