/**
 * Created by x_a_x_a on 11.01.2017
 * User: Eleonora Kolodinskaya
 * Company: U6 SIA
 */

import AppDispatcher from "../dispatcher";
import { GALLERY_FULLSCREEN_MODAL_LEGACY, MODAL_CHANGE, MODAL_HIDE } from "../constants";

export function modalChange(type, options) {
    AppDispatcher.dispatch({
        type: MODAL_CHANGE,
        payload: {
            type: type,
            options: options
        }
    });
}

export function showImageDetail(component, items, index) {

    let options = { component, items, index };

    AppDispatcher.dispatch({
        type: MODAL_CHANGE,
        payload: {
            type: GALLERY_FULLSCREEN_MODAL_LEGACY,
            options: options
        }
    });
}

export function modalHide(safe = false) {
    if (safe && AppDispatcher.isDispatching()) {
        return false;
    }

    AppDispatcher.dispatch({
        type: MODAL_HIDE
    });
}
