/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.7.17
 */

import React, {Component} from 'react';
import {FormControl, Button, Col, Row, Modal, FormGroup} from 'react-bootstrap';
import ServiceRow from './elements/service-row';
import {getPaidPopupStore} from './../../stores';
import printDate from '../../helper/print-date';
import PhoneStaticMask from './../../elements/phone-static-mask';
import {
    updateTax,
    updateServicePrice,
    createPaymentOrder,
    closePaymentOrderWithCash
} from './../../ac/payment-order-ac';
import {
    PAYMENT_TYPE_CASH,
    PAYMENT_TYPE_STRIPE,
    FORM_MODE_CREATE,
    FORM_MODE_UPDATE
} from './../../frizo-shared-constants';
import Violation from '../helper-components/violation';
import notificationSystem from './../../service/notification-system';
import {loadStripeAccount} from "../../ac/payments/payments-professional";

import {browserHistory} from 'react-router';
import {modalHide} from "../../ac/modals";
import gradientFill from './../../helper/gradient-fill';
import {printCentToUsd} from "../../helper/money-converter";

export default class GetPaidPopup extends Component {


    constructor(props) {
        super(props);

        this.priceChangeHandlers = {};

        this.state = Object.assign({
            paymentOrderId: null,
            apt: null,
            salonClient: null,
            taxValue: 0,
            taxPercent: 0,
            serviceAndTax: 0,
            serviceTotalEntered: 0,
            date: null,
            services: {},

            mode: FORM_MODE_CREATE,

            violations: {},
            ready: false,
            btnDisabled: false,

            paymentProfileExists: null,
            externalAccountExists: null

        }, this.getStoreData());

        this.storeUpdate = this.storeUpdate.bind(this);
        this.getPriceChangeHandler = this.getPriceChangeHandler.bind(this);
        this.onCash = this.onCash.bind(this);
        this.onCredit = this.onCredit.bind(this);
        this.acceptCash = this.acceptCash.bind(this);
        this.createFooter = this.createFooter.bind(this);

        this.zeroText = "Total price must be greater than zero";

    }

    componentDidMount() {
        getPaidPopupStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        getPaidPopupStore.removeChangeListener(this.storeUpdate);
    }

    getStoreData() {

        return getPaidPopupStore.getStore();
    }

    storeUpdate() {
        let object = Object.assign({
            ready: true
        }, this.getStoreData());

        this.setState(object);
    }

    onTaxChange(e) {
        let tax = e.target.value.replace(/[^0-9/.]+/g, '');
        if (parseInt(tax, 10) > 99 || tax.slice(tax.indexOf(".")).length > 5 || isNaN(tax)) {
            return false
        }
        updateTax(tax);
    }

    onCash() {
        if (Number(this.state.serviceTotalEntered) === 0) {
            notificationSystem.alert(this.zeroText);
            return false;
        }
        let message = '<p class="title">Payment confirmation</p>' +
            'You are about to accept cash payment </br>for the total of <strong>' +
            `$${printCentToUsd(this.state.serviceAndTax)}</strong>. </br>This can't be undone.</br><strong>Proceed?</strong>`;

        /*global confirm */
        /*eslint no-restricted-globals: 0 */

        notificationSystem.confirm(message).then(result => {
            if (result.buttonClicked !== 'ok') {
                return false;
            }
            this.setState({btnDisabled: true});
            createPaymentOrder(this.getStoreData(), PAYMENT_TYPE_CASH)
        });
    }

    onCredit() {
        if (Number(this.state.serviceTotalEntered) === 0) {
            notificationSystem.alert(this.zeroText);
            return false;
        }

        this.setState({btnDisabled: true});

        loadStripeAccount().then(response => {

            if (this.state.paymentProfileExists && this.state.externalAccountExists) {
                let message = '<p class="title">Request for payment</p>' +
                    'Please ask your Client to approve payment via Frizo app</br>for amount of <strong>' +
                    `$${printCentToUsd(this.state.serviceAndTax)}</strong>.`;

                notificationSystem.confirm(message).then(result => {
                    if (result.buttonClicked !== 'ok') {
                        return false;
                    }
                    createPaymentOrder(this.getStoreData(), PAYMENT_TYPE_STRIPE);
                });
            }
            else {
                let message = '<p class="title">No external account</p>' +
                    'Add debit card</br>or bank account to get paid';

                let okBtn = 'Add';

                if (!this.state.paymentProfileExists) {
                    message = '<p class="title">No Payment profile</p>' +
                        'Create Payment profile';

                    okBtn = 'Create';
                }

                notificationSystem.confirm(message, okBtn).then(result => {
                    if (result.buttonClicked !== 'ok') {
                        return false;
                    }

                    modalHide();
                    browserHistory.replace('/my-salon/payments');
                });
            }
        });


    }

    acceptCash() {
        this.setState({btnDisabled: true});
        closePaymentOrderWithCash(this.state.paymentOrderId);
    }

    handlePriceChange(aptId, e) {
        let price = e.target.value.replace(/\D+/, '');

        if (price.indexOf('.') > -1 || price.length > 4) {
            return false;
        }

        updateServicePrice(aptId, price);
    }

    getPriceChangeHandler(aptId) {
        if (!this.priceChangeHandlers[aptId]) {
            this.priceChangeHandlers[aptId] = this.handlePriceChange.bind(this, aptId);
        }

        return this.priceChangeHandlers[aptId];
    }

    createFooter() {
        if (this.state.btnDisabled) {
            return <div>
                <div className="pull-center">
                    <Button disabled={this.state.btnDisabled}
                            className="btn fr-btn btn-accept">Processing</Button>
                </div>
            </div>
        }
        else {
            if (this.state.mode === FORM_MODE_CREATE) {
                return <div>
                    <div className="pull-left">
                        <Button onClick={this.onCash} className="btn fr-btn">CASH</Button>
                    </div>
                    <div className="pull-right">
                        <Button onClick={this.onCredit} className="btn fr-btn-add">CARD</Button>
                    </div>
                </div>
            }
            if (this.state.mode === FORM_MODE_UPDATE) {
                return <div>
                    <div className="pull-center">
                        <Button onClick={this.acceptCash} className="btn fr-btn btn-accept">ACCEPT CASH</Button>
                    </div>
                </div>
            }
        }
        return null;
    }

    render() {
        if (!this.state.ready) return <div className={"loading-message"}>Loading...</div>;
        let formDisabled = this.state.mode !== FORM_MODE_CREATE;

        return <div>
            <Modal.Header closeButton style={{'background': gradientFill()}}>
                <h2>Get paid</h2>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <FormGroup>
                        <Col md={2} xs={2}>
                            Client
                        </Col>
                        <Col md={10} xs={10}>
                            <div className="fr-text-overflow">
                                <div className={"paid-label--title"}> {this.state.salonClient.firstName}&nbsp;
                                    {this.state.salonClient.lastName}
                                </div>
                                <PhoneStaticMask phone={this.state.salonClient.phone}/>
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} xs={2}>
                            Date
                        </Col>
                        <Col md={10} xs={10}>
                            {printDate(this.state.date, "ll")}
                        </Col>
                    </FormGroup>
                </Row>
                <div className="paid-services">
                    <Row className="services-title">Services</Row>

                    {this.state.services.map((service, key) => {

                        return <ServiceRow key={key}
                                           {...service}
                                           onChange={this.getPriceChangeHandler(service.id)}
                                           disabled={formDisabled}
                        />;
                    })}

                </div>

                <div className="service-total">
                    <FormGroup>
                        <Row>
                            <Col md={7} xs={7}>Service total</Col>
                            <Col md={5} xs={5}>$ {printCentToUsd(this.state.serviceTotalEntered)}</Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col md={3} xs={3}>
                                Tax @
                            </Col>
                            <Col md={4} xs={4} className="fr-pr0">
                                <FormControl type="text" className="tax-input" placeholder="0"
                                             value={this.state.taxPercent}
                                             onChange={this.onTaxChange}
                                             disabled={formDisabled}
                                />
                                <label className="control-label paid-label">%</label>
                            </Col>
                            <Col md={5} xs={5}>$ {printCentToUsd(this.state.taxValue)}</Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col md={7} xs={7}>
                                Service & tax
                            </Col>
                            <Col md={5} xs={5}>
                                $ {printCentToUsd(this.state.serviceAndTax)}
                            </Col>
                        </Row>
                    </FormGroup>

                    <div className="tip-message">
                        Client may add tip while confirming the payment
                    </div>

                </div>
                <div>
                    <Violation violationSet={this.state.violations.general} visible={true}/>
                </div>
            </Modal.Body>


            <Modal.Footer className="card-add-footer">
                {this.createFooter()}
            </Modal.Footer>
        </div>;
    }
}
