/**
 * User: Alexey Ilasov
 * Company: U6 SIA
 * Date: 18.01.2017.
 */

import React from 'react'
import BookForm from './book-form'
import Calendar from './../calendar-component'
import { FormGroup, Col } from 'react-bootstrap';
import DropCrop from '../../elements/drop-crop';
import bookAptFormActions from '../../ac/book-apt/form-actions';
import {
    xhrGetCalendarAvailability,
    CALENDAR_MARKERS_MODE_CLIENT
} from './../../xhr/calendar-availability-xhr';
import moment from 'moment';
import Violation from './../helper-components/violation';

export default class BookFormClient extends BookForm {
    constructor(props) {
        super(props);
        this.state = {
            availableTimes: [],
            availableProcessingIntervals: [],
        };
    }


    onImageChange(patch){
        bookAptFormActions.aptChange(this.props.aptId, patch);
    }

    updateMarkers() {
        let date = this.props.date;

        if (this.displayMonth !== undefined && this.displayYear !== undefined) {
            date = Date.UTC(this.displayYear, this.displayMonth);
        }

        let start = moment.utc(date).startOf('month').unix();
        let end   = moment.utc(date).endOf('month').unix();

        let serviceId = this.props.serviceId ? this.props.serviceId : null;

        if (serviceId) {
            xhrGetCalendarAvailability(
                this.props.salonId,
                start,
                end,
                this.props.addressId,
                this.props.artistId,
                serviceId,
                null,
                CALENDAR_MARKERS_MODE_CLIENT
            ).then(response => {
                this.setState({
                    markers: response
                });
            }, response => {

                this.setState({
                    markers: []
                });
            })
        }
    }


    render() {

        let service = 'Service ' + this.props.serviceNum;
        let previewId = 'chosen-style' + this.props.serviceNum;

        let artistsSelect = this.createArtistSelect();
        let servicesSelects = this.createServiceSelects();
        let timeSelect = this.createTimeSelect();
        let addressInfo = this.createAddress();

        return  <div>
            <div className="clearfix">
                <div className="fr-book__title">
                    {service}
                    {this.createRemoveButton()}
                </div>
            </div>
            <FormGroup>
                <Col md={4}>
                    {addressInfo}
                    {artistsSelect}
                    {servicesSelects}

                </Col>
                <Col md={4}>

                    <Calendar
                        key={this.props.num}
                        static
                        change= {this.onCalendarChange.bind(this)}
                        changeMonth={this.changeMonth}
                        date={this.props.date}
                        markers={this.state.markers}
                        timezone={this.props.timezone}
                        forbidPast={true}

                    />
                    {timeSelect}
                    <Violation violationSet={this.props.aptViolations.startTime} visible={this.props.showViolations} />
                </Col>
                <div className="top-align col-md-4">
                    <div style={{'marginBottom': this.props.imageId ? '11px' : '14px'}} className="fr-book__style">Chosen style</div>
                        <DropCrop
                            imgType={'appointment'}
                            style={{width: '100%', height: '360px','marginBottom': this.props.imageId ? '0' : '15px'}}
                            previewId={previewId}
                            imageURL={this.props.imageURL}
                            imageId={this.props.imageId}
                            onImageChange={this.onImageChange.bind(this)}/>

                </div>
            </FormGroup>
            <div>
                <Violation violationSet={this.props.aptViolations.general} visible={this.props.showViolations}/>
            </div>
        </div>

    }

}
