/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.09.2018
 * Time: 12:36
 */
import AppDispatcher from "../dispatcher";
import { LOAD_ALL_SALON_ADDRESSES } from "../constants";
import { xhrGetAllSalonAddresses } from "../xhr/get-salons-addresess-xhr";

export function getAllSalonAddresses() {
    xhrGetAllSalonAddresses().then(data => {
        AppDispatcher.dispatch({
            type: LOAD_ALL_SALON_ADDRESSES,
            payload: data
        });
    });
}
