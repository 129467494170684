/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.21.4
 * Time: 12:35
 */

import ApiClient from "../service/api-client";

export default function xhrModifyArtistService(artistId, data) {

    return ApiClient.post(["Q42_set_artist_services"], { userID: artistId, data: data }, {
        enableDefaultNotifications: false
    }).then(answer => {
        return answer;
    });
}
