/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 28.04.2017
 * Time: 17:47
 */
import ApiClient from "../service/api-client";
import { CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";

import { appointmentNormalize } from "./../normal-form/appointment";

export const xhrLoadSalonAppointments = function(params) {
    let newApiClientURL = ApiClient.getRouting().generate("Q103_pro_appointment_tabs", params);
    return ApiClient.get(newApiClientURL, params, {
        headers: [{
            name: CUSTOM_HEADER_U6_GROUPS,
            value: ["basic", "apt-confirm"]
        }]
    })
    .then((answer) => {
        let [response] = answer;
        return Promise.resolve({
            requestData: response.data.map(appointmentNormalize),
            requestTotalCount: response.count,
            requestParams: params
        });
    });
};
