/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 24.7.17
 */

import React from 'react';
import {FormControl} from 'react-bootstrap';
import PaymentSource from './payment-source';
import PropTypes from 'prop-types';

export default function CardSelector(props) {

    let changeHandler = (e) => {
        props.onChange(e.target.value);
    };

    return <FormControl componentClass="select" onChange={changeHandler} value={props.value} className={"fr-card-select"}>
        {/*Make Add New clickable*/}
        <option value={props.addNewOptionValue}>Add new</option>
        { props.cards.map(paymentSource => <PaymentSource key={paymentSource.id} {...paymentSource} />) }
    </FormControl>;
}

CardSelector.propTypes = {
    onChange: PropTypes.func,
    cards: PropTypes.array,
    value: PropTypes.string,
    addNewOptionValue: PropTypes.string
};

CardSelector.defaultProps = {
    addNewOptionValue: 'add-new'
};