/**
 * Created by x_a_x_a on 11.01.2017
 * User: Eleonora Kolodinskaya
 * Company: U6 SIA
 */

import { MODAL_CHANGE, MODAL_HIDE, MODAL_SEQUENCE } from "../constants";
import BaseStore from "./base-store";

class ModalsStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {};
        this.modalType = {};
        this.modalOptions = {};
        this.toggle = false;

        this.onHideListeners = [];

        this._registerActionSubscription((callback) => {
            const { type, payload } = callback;

            switch (type) {

                case MODAL_CHANGE:
                    this.toggle = true;
                    this.modalType = payload.type;
                    this.modalOptions = payload.options;
                    this.store.atSequence = false;

                    break;

                case MODAL_HIDE:
                    this.onHideListeners.forEach(callback => {
                        callback(this.modalType);
                    });

                    if (this.store.atSequence) {

                        let sequenceElement = this.sequenceNext();
                        if (sequenceElement) {
                            this.modalType = sequenceElement.modalType;
                            this.modalOptions = sequenceElement.options;
                        }
                        else {
                            this.toggle = false;
                            this.modalType = [];
                            this.store.atSequence = false;
                        }

                    }
                    else {
                        this.toggle = false;
                        this.modalType = [];
                    }

                    break;

                case MODAL_SEQUENCE:
                    this.store.sequence = payload.sequence;
                    this.store.currentInSequenceIndex = 0;
                    this.store.atSequence = true;

                    let sequenceElement = this.store.sequence[this.store.currentInSequenceIndex];
                    this.toggle = true;
                    this.modalType = sequenceElement.modalType;
                    this.modalOptions = sequenceElement.options;

                    break;

                default:

                    return true;
            }

            this.emitChange();
        });
    }

    sequenceNext() {
        this.store.currentInSequenceIndex++;

        let sequenceElement = this.store.sequence[this.store.currentInSequenceIndex];

        if (sequenceElement === undefined) {
            return false;
        }

        return sequenceElement;
    }

    setModalToggle(toggle) {
        this.toggle = false;
        this.emitChange();
    }

    getModalToggle() {
        return this.toggle;
    }

    getModalOptions() {
        return this.modalOptions;
    }

    getModalType() {
        return this.modalType;
    }

    setModalType(type) {
        this.toggle = true;
        this.modalType = type;
        this.emitChange();
    }

    getModalSessionStore() {
        return this.store;
    }

    /**
     * Not a flux way
     * used for non-fluxed parts of system
     *
     * @param listener
     */
    onHide(listener) {
        this.onHideListeners.push(listener);
    }
}

export default ModalsStore;
