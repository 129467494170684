/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 26.09.2020
 * Time: 09:42
 */

import React, { Component } from "react";
import { Link } from "react-router";
import { Row, Col } from "react-bootstrap";
import { checkClientRecommendationLink } from "../../ac/recommendation-actions";
import { clientRecommendationStore } from "../../stores/index";
import config from "./../../config";
import LoaderBar from "../../elements/loader-bar";


export default class ClientRecommendation extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({
            ready: false
        }, this.getStoreData());
        this.hash = this.props.params.hash || null;
        this.storeChange = this.storeChange.bind(this);
    }


    componentDidMount() {
        if (this.hash) {
            checkClientRecommendationLink(this.hash);
        }
        clientRecommendationStore.addChangeListener(this.storeChange);
    }

    componentWillUnmount() {
        clientRecommendationStore.removeChangeListener(this.storeChange);
    }

    getStoreData() {
        let storeData = clientRecommendationStore.getStore();

        return {
            linkValid: storeData.linkValid,
            artistName: storeData.artistName,
            salonName: storeData.salonName,
            linkExist: storeData.linkExist
        };
    }

    storeChange() {
        this.setState(Object.assign({}, this.getStoreData(), {
            ready: true
        }));
    }

    render() {
        let params = config.defaultFooter;
        if (!this.state.ready) {
            return <LoaderBar className={"loader-msg-wrapper--full-height"}/>;
        }

        return (
            <div className="fr-banner-top__margin--mobile">
                <Row className="fr-banner-main--landing">
                    <Col xs={12} md={8} mdOffset={2}>
                        {!this.state.linkExist &&
                        <div className={"recommendation"}>
                            <h1 className={"recommendation__title"}>Recommendation link does not exist.

                            </h1>
                            <div className={"recommendation__msg"}>
                                [We’ll take you to <Link className="recommendation__link" to={"/"}>home</Link> homepage]
                            </div>
                        </div>}
                        {this.state.linkExist && !this.state.linkValid &&
                        <div className={"recommendation"}>
                            <h1 className={"recommendation__title"}>This recommendation is&nbsp;no&nbsp;longer valid.
                                Ask your<br/>stylist
                                to&nbsp;send a&nbsp;new one.</h1>

                            <div className={"recommendation__msg"}>By&nbsp;the way, you will need our razor sharp app
                                to&nbsp;view<br/>
                                your personal recommendations.<br/>
                                So&nbsp;why wait? Get Frizo app now!
                            </div>
                        </div>}

                        {this.state.linkExist && this.state.linkValid &&
                        <div className={"recommendation"}>
                            <h1 className={"recommendation__title"}>Recommendation by&nbsp;
                                <strong>{this.state.artistName}</strong>{this.state.salonName ?
                                    <span> from <strong>{this.state.salonName}</strong></span> : ""}
                            </h1>
                            <div className={"recommendation__msg"}>Tap link in&nbsp;the text message on&nbsp;your<br/>mobile
                                phone
                            </div>
                            <div className={"recommendation__msg"}>It&nbsp;will lead you to&nbsp;your personal
                                product<br/>
                                recommendation on&nbsp;Frizo app
                            </div>
                        </div>}
                    </Col>
                </Row>

                {this.state.linkExist && <div className="fr-title-centred">
                    <div className={"fr-mt"}>
                        <a className="fr-iosicon large" href={params.appstore.url}
                           target="_blank" rel="noopener noreferrer"
                           title={params.appstore.title}
                           alt={params.appstore.title}>
                        </a>
                        <a className="fr-google-icon large" href={params.googlePlay.url}
                           target="_blank" rel="noopener noreferrer"
                           title={params.googlePlay.title}
                           alt={params.googlePlay.title}>
                        </a>
                    </div>
                </div>}
            </div>
        );
    }
}
