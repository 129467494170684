/**
 * Created by Ihnat, Kryshchyk
 * Company: U6 SIA
 * Date: 27.06.2017
 * Time: 10:28
 */
import React, {Component} from 'react';
import {Button, Popover, OverlayTrigger} from 'react-bootstrap';
import {FacebookButton, PinterestButton} from "react-social";
import config from '../../../config';
import {imageUrl} from "../../../helper/image-url";
import {IMAGE_SIZE_LARGE} from "../../../frizo-shared-constants";


export default class GalleryShareButton extends Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.renderPopover = this.renderPopover.bind(this);
        this.handleNextPref = this.handleNextPref.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleNextPref)
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleNextPref)
    }

    handleNextPref(e) {
        let img = document.querySelector('.img-responsive');
        if (e.code === 'ArrowRight' || e.code === 'ArrowLeft') {
            img && img.click();
        }
    }

    renderPopover() {

        let appId = config.facebook_app_id;
        let imgSrc = imageUrl(this.props.imageUrl, IMAGE_SIZE_LARGE);

        return (

            <Popover
                id="popover-share"
                className="popover-share">
                <div className="popover-share__block">
                    <FacebookButton
                        url={this.props.shareUrl}
                        appId={appId}
                        className="popover-share__btn">
                        <div className="popover-share__facebook"></div>
                    </FacebookButton>
                </div>
                <div className="popover-share__block">
                    <PinterestButton
                        className="popover-share__btn"
                        url={this.props.shareUrl}
                        media={imgSrc}>
                        <div className="popover-share__pinterest"></div>
                    </PinterestButton>
                </div>
            </Popover>
        )
    }

    render() {
        return (
            <OverlayTrigger rootClose trigger="click" placement="top" overlay={this.renderPopover()}>
                <Button className={this.props.className}>Share</Button>
            </OverlayTrigger>
        )
    }
}
