/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.3.17
 * Time: 6.30
 */

import ApiClient from "../service/api-client";
import { addressNormalizerLegacy } from "../normal-form/address";
import { CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";

export const xhrLoadNameAndLocation = function(withGalleryInfo = false) {
    let u6groups = {};
    if (withGalleryInfo) {
        u6groups[CUSTOM_HEADER_U6_GROUPS] = "gallery-info";
    }

    return ApiClient.get(["Q62_professional_profile"], null, {
        headers: u6groups
    }).then((answer) => {
        let [response] = answer;

        let data = response.response;

        let result = {
            id: data.salonID,
            publicAvailable: data.publicAvailable,
            name: data.profName,
            phone: data.profPhone,
            addresses: data.profAddress.map(addressNormalizerLegacy),
            isServicePaused: data.servicePaused

        };

        if (withGalleryInfo) {
            result["galleryItemsCount"] = data.galleryItemsCount;
        }

        return Promise.resolve(result);
    });
};

export const xhrGetGalleryItem = function(galleryItemId) {

    return ApiClient.get(["Q15_gallery_items"], { galleryItemID: galleryItemId }).then((answer) => {
        // return ApiClient.get(['Q15_gallery_items']).then((answer) => {
        let [response] = answer;
        // let data = response.response;

        return response.response[0];
        // return data.filter((item) => item.galleryItemID === parseInt(galleryItemId))[0];
    });
};

export const xhrUpdateSalonBaseInfo = function(name, phone, instagramUsername) {
    return ApiClient.post(["Q64_update_professional_profile"], {
        salonName: name,
        phone: phone,
        instagramUsername: instagramUsername

    }).then(answer => {
        let [response] = answer;

        return response;
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
};

export const xhrRemoveAddress = function(id) {

    return ApiClient.post(["Q67_edit_prof_adress"], {
        addressData: JSON.stringify({
            remove: id
        })
    }, {
        enableDefaultNotifications: false
    }).then(answer => {
        let [response] = answer;

        return response.response.map(addressNormalizerLegacy);
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
};

export const xhrAddAddress = function(addressData) {

    return ApiClient.post(["Q67_edit_prof_adress"], {
        addressData: JSON.stringify({
            new: [{
                onLocation: addressData.onLocation,
                streetAddress: addressData.address,
                zip: addressData.zip,
                suite: addressData.suite
            }]
        })
    }).then(answer => {
        let [response] = answer;

        return response.response.map(addressNormalizerLegacy);
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
};

export const xhrUpdateAddress = function(addressData) {

    return ApiClient.post(["Q67_edit_prof_adress"], {
        addressData: JSON.stringify({
            edit: [{
                addressID: addressData.id,
                onLocation: addressData.onLocation,
                streetAddress: addressData.address,
                suite: addressData.suite,
                zip: addressData.zip
            }]
        })
    }).then(answer => {
        let [response] = answer;

        return response.response.map(addressNormalizerLegacy);
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
};

export const xhrZipResolve = function(zip) {
    return ApiClient.get(["Q63_zipcode_data"], { zip }, { enableDefaultNotifications: false }).then(answer => {
        let [response] = answer;

        return response.response;
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
};
