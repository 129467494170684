/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 04.04.2017
 * Time: 14:27
 */
import React, { Component } from "react";
import { Button, Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import RoutedGroup from "../../components/forms/routed-group";
import gradientFill from "../../helper/gradient-fill";
import withNonProDisclaimer from "../hoc/non-pro-disclaimer-hoc";
import { LIMIT_APT_LIST } from "../../frizo-shared-constants";
import AppointmentsFilter from "./appointments-filter";
import { changeAptFilter } from "../../ac/salon-apt";

class SalonAppointments extends Component {
    constructor(props) {
        super(props);
        this.handleSearchStringUpdate = this.handleSearchStringUpdate.bind(this);
    }

    handleSearchStringUpdate(filter) {
        changeAptFilter(filter);
    }

    render() {
        return (
            <div>
                <div className="fr-banner-top fr-full-width" style={{ "background": gradientFill() }}>
                    <Row className="fr-container-width">
                        <Col xs={12} md={6} sm={6} className="fr-banner-top__text--apt">
                            <h1>Appointments</h1>
                        </Col>
                        <Col xs={12} md={6} sm={6} className="fr-banner-top__text--right">
                            <AppointmentsFilter searchString={this.handleSearchStringUpdate}
                                                placeholder="Client, phone, artist"/>
                        </Col>
                    </Row>
                </div>
                <div className="fr-apt-left-container fr-mb-xs">
                    <RoutedGroup className="fr-btn-routed">
                        <Button value="/appointments/pending">Pending</Button>
                        <Button value="/appointments/upcoming">Upcoming</Button>
                        <Button value="/appointments/history">History</Button>
                    </RoutedGroup>
                </div>
                {this.props.children}
            </div>
        );
    }
}

let WrappedComponent = withNonProDisclaimer(SalonAppointments, {
    nonPro: true,
    limits: [LIMIT_APT_LIST]
});

WrappedComponent.requireData = ["subscription-tier-2"];

export default WrappedComponent;