/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.31.3
 * Time: 17:49
 */

import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import drawTime from './../../../../../helper/draw-time';

export default class TableRowStatic extends Component {
    constructor(props) {
        super(props);
        this.renderPrice = this.renderPrice.bind(this);
    }

    renderPrice() {
        let price = this.props.service.servicePriceFrom;
        if (this.props.service.servicePriceFrom !== this.props.service.servicePriceTo) {
            return this.props.service.servicePriceFrom + String.fromCharCode(8211) + this.props.service.servicePriceTo;
        }

        return price
    }

    render() {
        let price = this.renderPrice()

        if (this.props.promo) {
            return <Row className="fr-serv-row fr-table-centred">
                <Col xs={6} md={6} className="fr-serv-name">{this.props.service.i18nKey}</Col>
                <Col xs={3} md={2} className="fr-serv-duration">{drawTime(this.props.service.durationValue)}</Col>
                <Col xs={3} md={3} className={price || this.props.service.allowZeroPrice ? "fr-serv-price" : ""}>{price}</Col>
            </Row>
        }
        return <Row className="fr-table-centred__bordered services">
            <Col xs={8} md={4} className="fr-serv-name fr-text-overflow">{this.props.service.i18nKey}</Col>
            <Col xs={2} md={2} className="fr-serv-price">{this.props.service.price}</Col>
            <Col xsHidden smHidden md={2}
                 className="fr-serv-duration">{drawTime(this.props.service.durationValue)}</Col>
            <Col xsHidden smHidden md={2}
                 className="fr-serv-duration">{drawTime(this.props.service.processingValue)}</Col>
            <Col xs={2} md={2} className="fr-serv-action">
                <Button bsSize="small" className="fr-btn fr-serv-btn__edit hidden-sm hidden-xs"
                        onClick={this.props.onServiceEdit(this.props.service)}
                        disabled={this.props.btnDisabled}>Edit</Button>
                <Button bsStyle="link" onClick={this.props.onServiceRemove(this.props.service)}
                        className="fr-btn-serv-remove hidden-sm hidden-xs"
                        disabled={this.props.btnDisabled}>Remove</Button>
                <Button className="fr-serv-action-btn hidden-lg hidden-md " disabled={this.props.btnDisabled}
                        onClick={this.props.onServiceEdit(this.props.service)}><span
                    className="glyphicon glyphicon-option-horizontal hidden-lg"></span></Button>
            </Col>
        </Row>
    }
}

TableRowStatic.defaultProps = {
    promo: false
};
