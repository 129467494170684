/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 22.03.2017
 * Time: 14:20
 */

import React, {Component} from 'react'
import {Col, Row, Button} from 'react-bootstrap';
import PhoneStaticMask from '../../elements/phone-static-mask';


export default class ClientStatic extends Component {

    toggleActive() {
        this.props.toggleActive(this.props.clientID)
    }

    onDeleteHandler() {
        this.props.onDelete(this.props.clientID);
    }

    onEditHandler() {
        this.props.onEdit(this.props.clientID);
    }

    render() {
        let {clientFirstName, clientLastName, clientPhone, clientComment} = this.props;
        let fullName = clientFirstName + '\t' + clientLastName;

        return (
            <div>
                <Row className="fr-table-content  fr-table-centred__bordered ">
                    <Col xs={5} md={3}>
                        <span>{fullName}</span>
                    </Col>
                    <Col xs={5} md={2}>
                        <span><PhoneStaticMask phone={clientPhone}/></span>
                    </Col>
                    <Col xsHidden={true} smHidden={true} md={6}>
                        <span>{clientComment}</span>
                    </Col>
                    <Col xs={2} md={1}>

                        <Button  className="fr-btn fr-table-clients__btn visible-lg visible-md"
                                onClick={this.onEditHandler.bind(this)}>Edit</Button>
                        <Button className="fr-btn fr-btn-small fr-table-clients__btn visible-xs visible-sm"
                                onClick={this.onEditHandler.bind(this)}><span className="glyphicon glyphicon-option-horizontal"></span></Button>

                    </Col>
                </Row>
            </div>
        )
    }
}

ClientStatic
    .defaultProps = {
    clientID: '',
    clientFirstName: '',
    clientLastName: '',
    clientPhone: '',
    clientEmail: '',
    clientComment: ''
};
