/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 5/15/17
 * Time: 11:47
 */

import AppDispatcher from "../../../dispatcher";
import ApiClient from "../../../service/api-client";
import { FAVORITES_ARTIST_REMOVED, FAVORITES_ITEM_REMOVED } from "../../../constants";
import { xhrRemoveGalleryFavorite, xhrRemoveUserFavorite } from "../../../xhr/favorites-xhr";
import { notifySuccess } from "./../../notify-actions";
import { singleLookNormalizer } from "../../../normal-form/gallery/professional-profile/single-look-normalizer";
import { singleAvatarNormalizer } from "../../../normal-form/gallery/professional-profile/single-avatar-normalizer";

export function loadFavoriteItems({ offset, count }) {

    return ApiClient.get(["Q20_2_get_gallery_item_favorites"], {
        offset, limit: count
    }, {
        headers: [{
            name: "U6-groups",
            value: "basic,favorites,gallery-basic"
        }]
    }).then(answer => {

        let [response] = answer;

        return {
            items: response.map(singleLookNormalizer)
        };

    });
}

export function loadFavoriteArtists({ offset, count }) {

    return ApiClient.get(["Q27_2_get_user_favorite"], {
        offset, limit: count
    }, {
        headers: [{
            name: "U6-groups",
            value: "basic,favorites"
        }]
    }).then(answer => {

        let [response] = answer;

        return {
            items: response.map(singleAvatarNormalizer)
        };

    });
};

export const removeArtistAction = (artistId) => {

    return xhrRemoveUserFavorite(artistId).then(answer => {
        AppDispatcher.dispatch({
            type: FAVORITES_ARTIST_REMOVED,
            payload: artistId
        });
    });
};

export const removeItemAction = (itemId) => {

    return xhrRemoveGalleryFavorite(itemId).then(answer => {
        AppDispatcher.dispatch({
            type: FAVORITES_ITEM_REMOVED,
            payload: itemId
        });

        notifySuccess("Removed");

    });
};
