/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.6.17
 * Time: 8.37
 */

import React, {Component} from 'react';
import {personalProfileMyCardsStore} from './../../../stores';
import PaymentSource from '../../payments/elements/payment-source';
import {loadBilling, setDefaultCard, removeCard, loadPersonalPayments} from '../../../ac/payments/payments-personal';
import {Row, Col, Button, Pagination} from 'react-bootstrap';
import {modalChange} from './../../../ac/modals';
import {ADD_NEW_CARD_MODAL} from './../../../constants';
import Violation from '../../helper-components/violation';
import WidgetHeader from './../professional/cards-and-bank/widget-header';
import TransactionWidget from './../professional/cards-and-bank/transaction-widget';
import {PAGINATION_ITEMS_COUNT} from "../../../frizo-shared-constants";

export default class PersonalMyCards extends Component {

    constructor(props) {
        super(props);

        this.updateCards = this.updateCards.bind(this);
        this.getStoreData = this.getStoreData.bind(this);
        this.openAddNewDialog = this.openAddNewDialog.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

        this.state = Object.assign({}, this.getStoreData());
        this.state.currentPageNumber = 1;
    }

    getStoreData() {
        let store = personalProfileMyCardsStore.getStore();

        return {
            defaultCardId: store.defaultCardId,
            cards: store.cards,
            personalCharges: store.personalCharges,
            violations: store.violations,
            showViolations: store.showViolations
        };
    }

    updateCards() {
        this.setState(this.getStoreData());
    }

    componentDidMount() {
        personalProfileMyCardsStore.addChangeListener(this.updateCards);
        let params = {
            mode: 'client',
            limit: PAGINATION_ITEMS_COUNT,
            offset: 0,
        };
        loadBilling(params);
    }

    componentWillUnmount() {
        personalProfileMyCardsStore.removeChangeListener(this.updateCards)
    }

    isDefault(cardId) {
        //console.log('cardId', cardId, this.state.defaultCardId);
        return cardId === this.state.defaultCardId
    }


    openAddNewDialog() {

        modalChange(ADD_NEW_CARD_MODAL, {
            noCard: this.state.cards.length === 0
        });
    }

    handleSelect(eventKey) {

        let params = {
            mode: 'client',
            limit: PAGINATION_ITEMS_COUNT,
            offset: (eventKey - 1) * PAGINATION_ITEMS_COUNT,
        };

        this.setState({
            currentPageNumber: eventKey
        });

        loadPersonalPayments(params);
    }

    render() {
        let totalPages = Math.ceil(this.state.personalCharges.count / PAGINATION_ITEMS_COUNT);
        let maxVisibleButtons = (window.innerWidth < 769 ? 3 : 7);

        return <div className=" my-cards-cnt">
            <Row>
                <Col md={6} xs={12} className="fr-mt fr-mb-xs  fr-pr-md">
                    <div className="personal-profile__billing-title">
                        Cards
                        <Button className="fr-btn-add btn pull-right" onClick={this.openAddNewDialog}>ADD NEW
                            CARD</Button>
                    </div>
                    <WidgetHeader/>
                    {this.state.cards.length === 0 &&
                    <div className="fr-mt fr-text-center">No cards yet</div>}

                    <div className="payment-sources">
                        {this.state.cards.map(card => <PaymentSource
                            key={card.id} {...card}
                            isDefault={this.isDefault(card.id)}
                            setDefaultSource={setDefaultCard.bind(this, card.id)}
                            removeSource={removeCard.bind(this, card.id)}
                        />)}
                    </div>
                </Col>
                <Col md={6} className="fr-mt fr-mb-xs">
                    <TransactionWidget
                        sources={this.state.personalCharges.sources} mode={"client"}
                    />
                    {totalPages > 1 && <Pagination
                        className="fr-pagination-standard fr-ml"
                        bsSize="medium"
                        items={totalPages}
                        activePage={this.state.currentPageNumber}
                        onSelect={this.handleSelect}
                        maxButtons={maxVisibleButtons} first={totalPages > 1 ? "<<" : null}
                        last={totalPages > 1 ? ">>" : null} next={totalPages > 1 ? ">" : null}
                        prev={totalPages > 1 ? "<" : null}
                        ellipsis={totalPages > 1 ? "..." : null}
                    />}
                </Col>
            </Row>
            <Row>
                <div>
                    <Violation violationSet={this.state.violations.general} visible={this.state.showViolations}/>
                </div>
            </Row>
        </div>;
    }
}
