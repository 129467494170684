/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 4/3/17
 * Time: 18:59
 */

import AppDispatcher from "../dispatcher";

import { FORM_VALIDATION_FAIL, INITIALIZE_FORM, REGISTRATION_FORM_CHANGE, USER_SESSION_UPDATE } from "../constants";
import { xhrSendClientSignUpData, xhrSendListYourBusiness, xhrSendProSignUpData } from "../xhr/sign-up-xhr";
import analytics from "../service/analytics";
import { HRC_FIELD_GENERIC } from "../frizo-shared-constants";
import { redirectAfterLogin } from "./redirect-after-login-actions";
import { events } from "../auth/events";

export function setListBusinessData(session) {
    AppDispatcher.dispatch({
        type: USER_SESSION_UPDATE,
        payload: { session }
    });
}

export function updateRegistrationData(data) {
    AppDispatcher.dispatch({
        type: REGISTRATION_FORM_CHANGE,
        payload: data
    });
}

export function saveSignUpData(data) {

    return xhrSendClientSignUpData(data).then(answer => {
        return answer;

    }, answer => {
        let { response } = answer;

        if (response.errors) {
            return Promise.reject({
                violations: response.errors,
                formIsValid: !response.errors
            });
        }

        return Promise.reject(response);
    });

}

export function saveProSignUpData(data) {

    return xhrSendProSignUpData(data).then(answer => {

        let { session, options } = answer;

        events.renewingFinished.dispatch(true, session, {
            groups: options.groups,
            request: answer.request
        });

        events.login.dispatch(true, session, {
            groups: options.groups,
            request: answer.request
        });


        localStorage.removeItem(HRC_FIELD_GENERIC);

        analytics.track("pro_signup_success");

        redirectAfterLogin(session);

        return { session, options };

    }, answer => {

        let { response } = answer;

        if (response.errors) {

            validationFail({
                violations: response.errors,
                formIsValid: !response.errors
            });
        }

        return Promise.reject(response);

    });
}

export function saveListYourBusinessData(data) {

    return xhrSendListYourBusiness(data).then(response => {

        localStorage.removeItem(HRC_FIELD_GENERIC);
        setListBusinessData(response);

        analytics.track("list_your_business_success");


        return response;
    }, response => {

        if (response.errors) {
            validationFail({
                violations: response.errors,
                formIsValid: !response.errors
            });
        }

        return Promise.reject(response);
    });
}

export function validationFail(data) {
    AppDispatcher.dispatch({
        type: FORM_VALIDATION_FAIL,
        payload: data
    });
}

export function initForm() {
    AppDispatcher.dispatch({
        type: INITIALIZE_FORM
    });
}
