/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 10.8.17
 */

import { browserHistory } from "react-router";
import { loadAllFavorites } from "./favorites/common-ac";

export function redirectAfterLogin(session) {
    loadAllFavorites();
    if (session.professional) {

        if (session.business) {

            if (session.salon && session.salon.isPublic) {
                browserHistory.push("/book");
            }
            else {
                browserHistory.push("/my-salon");
            }
        }
        else {

            if (session.isPublic) {
                browserHistory.push("/book");
            }
            else {
                browserHistory.push("/my-salon");
            }
        }
    }
}
