/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.19.7
 * Time: 09:53
 */

import printDate from "./../helper/print-date";
import convertSecondsToFormattedTime from "./../helper/convert-seconds-to-formatted-time";

export function unseenNormalize(rawItem) {

    return {
        id: rawItem.id,
        date: rawItem.date,
        formedDate: printDate(rawItem.date, "MMM DD, YYYY"),
        formedStartTime: convertSecondsToFormattedTime(rawItem.appointment.startTime),
        localDate: rawItem.appointment.localDate,
        startTime: rawItem.appointment.startTime,
        startTimestamp: rawItem.startTimestamp,
        artist: `${rawItem.appointment.masterUser.firstName} ${rawItem.appointment.masterUser.lastName}`,
        service: rawItem.appointment.service.i18nKey,
        client: `${rawItem.appointment.salonClient.firstName} ${rawItem.appointment.salonClient.lastName}`,
        aptId: rawItem.appointment.id
    };
}
