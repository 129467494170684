/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.6.4
 * Time: 13:24
 */

import AppDispatcher from "../../dispatcher";
import { xhrAddSalonService, xhrModifySalonService, xhrRemoveSalonService } from "../../xhr/profile-salon-xhr";
import {
    PROFILE_SALON_SERVICE_ADDED,
    PROFILE_SALON_SERVICE_MODIFIED,
    PROFILE_SALON_SERVICE_MODIFY_FAILED,
    PROFILE_SALON_SERVICE_REMOVE_FAILED,
    PROFILE_SALON_SERVICE_REMOVED
} from "../../constants";

import { notifyFail, notifySuccess } from "./../notify-actions";

export const addSalonServicesAction = (service, durationValue, processingValue) => {

    xhrAddSalonService(service.id, service.price, durationValue, processingValue).then(service => {
        AppDispatcher.dispatch({
            type: PROFILE_SALON_SERVICE_ADDED,
            payload: service
        });

        notifySuccess();

    }, (response) => {

        AppDispatcher.dispatch({
            type: PROFILE_SALON_SERVICE_MODIFY_FAILED,
            payload: response
        });

        notifyFail(response.message);
    });

};

export const removeSalonServiceAction = (service) => {

    xhrRemoveSalonService(service.salonServiceId).then(answer => {
        AppDispatcher.dispatch({
            type: PROFILE_SALON_SERVICE_REMOVED,
            payload: service
        });


        notifySuccess();

    }, response => {
        AppDispatcher.dispatch({
            type: PROFILE_SALON_SERVICE_REMOVE_FAILED,
            payload: response.message
        });

        notifyFail(response.message);
    });

};

export const modifySalonServiceAction = (service, durationValue, processingValue) => {
    xhrModifySalonService(service.salonServiceId, service.price, durationValue, processingValue).then(service => {
        AppDispatcher.dispatch({
            type: PROFILE_SALON_SERVICE_MODIFIED,
            payload: service
        });

        notifySuccess();

    }, (response) => {

        AppDispatcher.dispatch({
            type: PROFILE_SALON_SERVICE_MODIFY_FAILED,
            payload: response
        });

        notifyFail(response.message);
    });

};
