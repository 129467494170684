/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 30.1.17
 */

import ApiClient from "../service/api-client";
import { CUSTOM_HEADER_U6_GROUPS } from "./../frizo-shared-constants";
import { xhr } from "../service/api-client-2";
import { MODE_SIMULTANEOUS, MODE_TRANSACTIONAL } from "../frizo-shared-constants";

export function xhrLoadServices(salonId = null, artists = [], hasGalleryItem = 0) {

    let builder = xhr()
    .setMode(MODE_TRANSACTIONAL)
    .suppressNotifications();


    builder.get("@Q89_get_pure_services_list", null, "services").end();

    if (salonId) {
        builder.get("@Q88_2_get_salon_services", { salonId, hasGalleryItem }, "salonCorrections").end();
    }


    if (artists.length > 0) {

        builder.get("@Q88_3_get_artist_services", { artists, hasGalleryItem }, "artistsCorrections").end();
    }

    return builder.then(requestSet => {
        return Promise.resolve(requestSet.toHash());

    }, requestSet => {
        return Promise.reject(requestSet);
    });
}

export function xhrLoadServicesWithoutAttributes(salonId = null, artists = []) {

    let builder = xhr();

    builder.setMode(MODE_SIMULTANEOUS);

    builder
    .get("@Q89_get_pure_services_list", null, "services")
    .header(CUSTOM_HEADER_U6_GROUPS, "basic")
    .end();

    if (salonId) {

        builder
        .get("@Q88_2_get_salon_services", { salonId }, "salonCorrections")
        .header(CUSTOM_HEADER_U6_GROUPS, "basic, gallery-full")
        .end();
    }

    if (artists.length > 0) {

        builder
        .get("@Q88_3_get_artist_services", { artists }, "artistsCorrections")
        .end();
    }

    return builder.then(requestSet => requestSet.toHash());
}

export function xhrLoadArtistInfo(artistId) {
    const url = ApiClient.getRouting().generate("Q48_artist_by_id", { artistId });

    return ApiClient.get(url, null, {
        headers: {
            [CUSTOM_HEADER_U6_GROUPS]: "basic, user-additional-data"
        }
    }).then(
        answer => answer[0]);
}

export function xhrLoadProfileServices(salonId) {

    return xhr()
    .get("@Q89_get_pure_services_list", null, "services").end()
    .get("@Q50_duration_and_processing", null, "intervals").end()
    .get("@Q88_2_get_salon_services", { salonId }, "salonCorrections").end()
    .then(requestSet => {
        let hash = requestSet.toHash();
        hash.intervals = hash.intervals.response;

        return hash;
    });
}
