/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 10.5.17
 * Time: 14.36
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { SLOT_STATE_PAST } from '../../frizo-shared-constants';

export class Highlight extends Component {

    constructor(props) {
        super(props);

        this.div = undefined;
    }

    show() {
        if (this.div) {
            this.div.style.display = 'block';
        }
    }

    hide() {
        if (this.div) {
            this.div.style.display = 'none';
        }
    }


    render() {
        let className = 'schedule-td-dnd-highlight ';

        if (this.props.highlightState === SLOT_STATE_PAST) {
            className += 'schedule-td-dnd-highlight-wrong'
        }
        else {
            className += 'schedule-td-dnd-highlight-good'
        }

        return (<div ref={(node) => {
            this.div = node;
        }} draggable="false"
                     style={{display: this.props.display ? 'block' : 'none'}} className={className} />);
    }


}

Highlight.defaultProps = {
    display: false
};

Highlight.propTypes = {
    display: PropTypes.bool,
    highlightState: PropTypes.string.isRequired
};
