/**
 * Created by Alexey Ilasov
 * Company: U6 SIA
 * Date: 19.01.2017.
 */

import React, {Component} from 'react'
import BookFormClient from './book-form-client'
import BookFormProfessional from './book-form-professional'
import BookNoEdit from './book-no-edit'
import {MODE_CLIENT, MODE_PROFESSIONAL} from './../../frizo-shared-constants';
import {sessionStore, servicesStore, artistStore} from './../../stores/index';
import moment from 'moment';

export  default class BookFormComponent extends Component {

    render() {
        let props = this.props;
        let form = null;

        switch (this.props.mode) {
            case MODE_CLIENT:
                form = BookFormClient;

                break;

            case MODE_PROFESSIONAL:
                if (this.canEdit()) {
                    form = BookFormProfessional;
                }
                else {
                    form = BookNoEdit;
                    return React.createElement(form, this.makeProps())
                }

                break;

            default:
                console.warn('Invalid mode selected', this.props.mode);
                throw new Error("Invalid mode");
        }

        return React.createElement(form, props)
    }

    canEdit() {
        let {id:userId, salonAdmin} = sessionStore.getSession(),
            {artistId, dirty} = this.props;

        return (salonAdmin || (userId === artistId) || dirty || false);
    }

    makeProps() {

        let {showClientForm, date, artistId, serviceId, startTime, serviceNum} = this.props;

        const artist = artistStore.store.artistsIndex[artistId],
            service = servicesStore.store.servicesIndex[serviceId];

        return {
            showClientForm,
            artistName: `${artist.firstName} ${artist.lastName}`,
            serviceName: service.name,
            date,
            time: moment.utc(startTime * 1000).format('LT'),
            serviceNum
        };

    }
}
