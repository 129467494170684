/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.3.11
 * Time: 14:27
 */

import React, { Component } from "react";
import FindListList from "../components/find-list/find-list-list";

const withHintDecorator = (Element) => {

    return class WithHint extends Component {

        constructor(props) {
            super(props);

            this.state = {
                show: false,
                selected: null
            };

            this.onValueChange = this.onValueChange.bind(this);
            this.onItemClick = this.onItemClick.bind(this);
            this.onInputBlur = this.onInputBlur.bind(this);
            this.onDocClick = this.onDocClick.bind(this);
            this.onDocKeyDown = this.onDocKeyDown.bind(this);
            this.onArrowUp = this.onArrowUp.bind(this);
            this.onArrowDown = this.onArrowDown.bind(this);
            this.onEnter = this.onEnter.bind(this);
            this.onTab = this.onTab.bind(this);

        }

        onInputBlur() {
            this.setState({ show: false, selected: null });
        }

        onItemClick(phone) {
            this.setState({ show: false }, this.props.found(phone));
        }

        onDocClick(ev) {
            if (ev.target.parentNode.className !== this.props.itemClassName) this.setState({
                show: false,
                selected: null
            });

        }

        onEnter() {
            if (this.state.selected) this.onItemClick(this.state.selected.phone);

        }

        onTab() {
            this.setState({ show: false, selected: null });
        }

        onDocKeyDown(ev) {

            if (this.state.show && this.props.list.length) {
                switch (ev.key) {
                    case "ArrowDown":
                        this.onArrowDown();

                        break;

                    case "ArrowUp":
                        this.onArrowUp();

                        break;
                    case "Enter":
                        this.onEnter();

                        break;
                    case "Tab":
                        this.onTab();

                        break;

                    default:
                        return true;
                }
            }
        }

        onArrowUp() {
            if (this.state.selected) {
                let selectedIndex = this.props.list.findIndex(item => item.id === this.state.selected.id);
                if (selectedIndex > 0) {
                    this.setState({ selected: this.props.list[selectedIndex - 1] });
                }
                else {
                    this.setState({ selected: this.props.list[0] });
                }
            }
        }

        onArrowDown() {
            if (!this.state.selected) {
                this.setState({
                    selected: this.props.list[0]
                });
            }
            else {
                let selectedIndex = this.props.list.findIndex(item => item.id === this.state.selected.id);
                if (selectedIndex < this.props.list.length - 1) {
                    this.setState({ selected: this.props.list[selectedIndex + 1] });
                }
                else {
                    this.setState({
                        selected: this.props.list[this.props.list.length - 1]
                    });
                }
            }
        }


        getRef(node) {
            return node;
        }

        componentWillMount() {
            document.addEventListener("click", this.onDocClick);
            document.addEventListener("keydown", this.onDocKeyDown);
        }

        componentWillUnmount() {
            document.removeEventListener("click", this.onDocClick);
            document.removeEventListener("keydown", this.onDocKeyDown);
        }

        onValueChange(value) {
            this.setState({ show: true });
        }


        render() {

            return (<div ref={this.getRef}>
                    <Element
                        onValueChange={this.onValueChange}
                        onBlur={this.onInputBlur}
                        {...this.props}
                    />
                    <FindListList
                        onClick={this.onItemClick}
                        force={this.props.force}
                        {...this.state}
                        filtered={this.props.list}
                    />

                </div>

            );
        }
    };
};

export default withHintDecorator;
