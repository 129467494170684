/**
 * Created by fliak on 22.2.17.
 */

import BaseConstraint from "./base-constraint";

export const ARTIST_IS_NOT_SELECTED = "ARTIST_IS_NOT_SELECTED";
export const SERVICE_IS_NOT_SELECTED = "SERVICE_IS_NOT_SELECTED";
export const SELECTED_SERVICE_IS_MISSING = "SELECTED_SERVICE_IS_MISSING";
export const SELECTED_SERVICE_IS_NOT_A_LEAF = "SELECTED_SERVICE_IS_NOT_A_LEAF";

export default class ServiceIsLeaf extends BaseConstraint {

    /**
     *
     * @param options hash {
     *   useArtistSpecificService: true
     *   artistIdFieldName: "artistId"
     * }
     */
    constructor(options = null) {

        super();

        this.useArtistSpecificService = true;
        this.artistIdFieldName = "artistId";

        this.artistIsNotSelectedMessage = "Artist isn't selected";
        this.serviceIsNotSelectedMessage = "Service isn't selected";
        this.selectedServiceIsMissingMessage = "Selected service is missing"; //not a user case
        this.selectedServiceIsNotALeafeMessage = "Select service, not a category";

        this.serviceStore = null;

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        return "service-is-leaf";
    }

    getRequiredOptions() {
        return ["serviceStore"];
    }
}
