/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.7.11
 * Time: 12:49
 */
import React from 'react';
import PhoneStaticMask from '../../elements/phone-static-mask';


export default function ClientPhoneItem (props) {

    return <span> {`${props.firstName} ${props.lastName}, `} {PhoneStaticMask({phone:props.phone})} </span>

}