/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 25.02.2019
 * Time: 16:32
 */

import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import GoogleMapWithMarkers from '../maps/map-with-markers';
import config from './../../config';
import {getAllSalonAddresses} from '../../ac/salon-addresses-actions';
import {salonAddressesStore} from './../../stores/index';
import gradientFill from '../../helper/gradient-fill';
import imgSrc1 from './../../../../img/frizo-appointment-details.png';
import imgSrc2 from './../../../../img/frizo-meet-new-clients.png';
import imgSrc3 from './../../../../img/frizo-instagram-share.png';
import imgSrc4 from './../../../../img/frizo-choose-image-source.png';
import imgSrc5 from './../../../../img/frizo-book.png';


export default class GetClientsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salons: []
        };
        this.storeUpdate = this.storeUpdate.bind(this);
    }

    componentDidMount() {
        getAllSalonAddresses();
        salonAddressesStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        salonAddressesStore.removeChangeListener(this.storeUpdate);
    }

    storeUpdate() {
        this.setState(salonAddressesStore.getStore());
    }

    render() {
        let params = config.defaultFooter;

        return (
            <div className="fr-landing">
                <div className="fr-landing-flex">

                    <div className={"fr-landing-flex__item-wide  "} style={{'background': gradientFill()}}>
                        <Row className="fr-text-center">
                            <div className="fr-landing-flex__banner">
                                <h1>Why Frizo</h1>
                            </div>
                        </Row>
                    </div>

                    <div className={"fr-landing-flex__item-wide "}>
                        <div className={"fr-landing--content-box photo-background"}>
                            <div className="fr-landing-flex__item-title inverse first">Meet new clients. Learn new skills. Focus
                                on&nbsp;results
                            </div>
                            <div className="fr-title-centred inverse ">Frizo is&nbsp;all-in-one business tool for beauty
                                professionals.
                            </div>
                            <div className="fr-title-centred fr-link-bottom">
                                <a href={"https://frizo.page.link/SHrJ"} className="btn fr-btn-big__green landing">List your
                                    business</a>
                            </div>
                        </div>
                    </div>

                    <div className={"container"}>
                        <Row className={"row-flex"}>
                            <Col md={6} xs={12}>
                                <img src={imgSrc1} alt="What we do"/>
                            </Col>
                            <Col md={6} xs={12}>
                                <div className="fr-landing-flex__item">
                                    <div className="fr-landing-flex__item-title">What we&nbsp;do?</div>
                                    <div className="fr-title-centred">We&nbsp;bring new clients.</div>
                                    <div className="fr-title-centred fr-mt">
                                        <a href={"https://frizo.page.link/SHrJ"} className="btn fr-btn-big__green landing">List your
                                            business</a>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                        <Row className={"row-flex"}>

                            <Col xs={12} md={6} mdPush={6}>
                                <div className="fr-landing-flex__item">
                                    <img src={imgSrc2} alt="How we do that"/>
                                </div>
                            </Col>
                            <Col xs={12} md={6} mdPull={6}>
                                <div className="fr-landing-flex__item">
                                    <div className="fr-landing-flex__item-title">How we&nbsp;do&nbsp;that?</div>
                                    <div className={"fr-title-centered"}>Your clients have friends. They can be your clients too.
                                    </div>
                                    <div className="fr-title-centred">
                                        <a href={"https://frizo.page.link/SHrJ"} className="btn fr-btn-big__green landing">List your
                                            business</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={"row-flex"}>
                            <Col xs={12} md={6}>
                                <div className="fr-landing-flex__item">
                                    <img src={imgSrc3} alt="Simple truth"/>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="fr-landing-flex__item">
                                    <div className="fr-landing-flex__item-title">Simple truth</div>
                                    <div className={"fr-title-centered"}>Likes and followers mean nothing if&nbsp;they
                                        don&rsquo;t
                                        lead to&nbsp;new business.
                                        We&nbsp;get the digital word-of-mouth working for you.
                                    </div>
                                    <div className="fr-title-centred">
                                        <a href={"https://frizo.page.link/SHrJ"} className="btn fr-btn-big__green landing">List your
                                            business</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="fr-landing-flex__item-wide map">
                        <div className="fr-landing-flex__item-title map">
                            Thousands of&nbsp;talented beauty pros nationwide already&nbsp;with&nbsp;us
                        </div>
                        <GoogleMapWithMarkers
                            lat={config.googleMap.USA_center_lat}
                            lon={config.googleMap.USA_center_lng}
                            locations={this.state.salons}
                            render={true}
                            zoom={5}
                            showCenterMarker={false}
                            height={'82vh'}
                        />
                    </div>

                    <div className={"container"}>
                        <Row className={"row-flex"}>
                            <Col xs={12} md={6} mdPush={6}>
                                <div className="fr-landing-flex__item ">
                                    <img src={imgSrc4} alt="What will you have to do"/>
                                </div>
                            </Col>
                            <Col xs={12} md={6} mdPull={6}>
                                <div className="fr-landing-flex__item ">
                                    <div className="fr-landing-flex__item-title">What will you have to&nbsp;do?</div>
                                    &quot;List your business&quot; to&nbsp;upload your best work imagery.
                                    We’ll take it from there.
                                    <div className="fr-title-centred">
                                        <a href={"https://frizo.page.link/SHrJ"} className="btn fr-btn-big__green landing">List your
                                            business</a>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row className={"row-flex"}>
                            <Col xs={12} md={6}>
                                <div className="fr-landing-flex__item">
                                    <img src={imgSrc5} alt="Free basics"/>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="fr-landing-flex__item">
                                    <div className="fr-landing-flex__item-title">Free basics</div>
                                    Frizo is free until we bring you first client.
                                    <div className="fr-title-centred">
                                        <a href={"https://frizo.page.link/SHrJ"} className="btn fr-btn-big__green landing">List your
                                            business</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
                    <div className={"fr-landing-flex__item-wide"}>
                        <div className={"fr-landing--content-box"}>
                            <div className="fr-landing-flex__item-title">Razor sharp app</div>

                            <div className="fr-title-centred">Frizo app built for beauticians, with
                                beauticians. Just make sure your battery is&nbsp;charged.
                            </div>
                            <div className={"fr-landing--apps"}>
                                <a className="fr-iosicon large " href={params.appstore.url}
                                   target="_blank" rel="noopener noreferrer"
                                   title={params.appstore.title}
                                   alt={params.appstore.title}>
                                </a>
                                <a className="fr-google-icon large" href={params.googlePlay.url}
                                   target="_blank" rel="noopener noreferrer"
                                   title={params.googlePlay.title}
                                   alt={params.googlePlay.title}>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}