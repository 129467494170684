/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 8.11.17
 * Time: 11.22
 */

import type { TRawService } from "../normal-form/raw-types";
import type { TAddress, TService } from "../types";
import ApiClient from "./../service/api-client";
import { addressNormalize1 } from "../normal-form/address";
import { xhr } from "../service/api-client-2";

function getUrl(userUrl, tabName: "location" | "services") {
    return ApiClient.getRouting().generate("Q104_promo_tabs_by_url", {
            url: userUrl,
            tab: tabName
        }, {
            headers: [{
                name: "U6-groups",
                value: ["basic", "gallery-basic", "user-services", "share-url"]
            }]
        }
    );
}

export function xhrLoadSalonPromoLocationData(userUrl: string): {
    data: Array<TAddress>,
    salonId: number,
    salonName: string
} {

    type TQ104ResponseLocation = {
        data: Array<TRawAddress1>,
        salonId: number,
        salonName: string
    };

    return ApiClient.get(getUrl(userUrl, "location")).then((answer: [TQ104ResponseLocation]) => {
        let [response] = answer;

        let addresses = response.data.map(address => addressNormalize1(address));

        return {
            salonId: response.salonId,
            salonName: response.salonName,
            data: addresses
        };
    }, answer => {

        let [response] = answer;

        return Promise.reject(response);
    });
}

export function xhrLoadSalonPromoServicesData(userUrl: string): {
    data: Array<TService>,
    salonId: number,
    salonName: string
} {

    type TQ104ResponseServices = {
        data: Array<TRawService>,
        salonId: number,
        salonName: string
    };

    return ApiClient.get(getUrl(userUrl, "services")).then((answer: [TQ104ResponseServices]) => {
        let [response] = answer;

        return response;
    }, answer => {

        let [response] = answer;

        return Promise.reject(response);
    });
}


export function getPublicSalonData(id) {
    return xhr()
    .get("@Q2-18-get-public-salon-data", { id }).end()
    .then((request: Request) => {
        let { response } = request;

        return response;
    });
}

export function getPublicSalonDataByUrl(url) {
    return xhr()
    .get("@Q2-19-get-public-salon-data-by-url", { url }).end()
    .then((request: Request) => {
        let { response } = request;

        return response;
    });
}
