/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 3/31/17
 * Time: 19:04
 */

import IsPasswordMatch from "./is-password-match";
import violationBuilder from "./../violation-builder";
import sprintf from "./../../helper/sprintf";
import BaseValidator from "./base-validator";

export default class IsPasswordMatchValidator extends BaseValidator {

    static getValidConstraints() {
        return [IsPasswordMatch];
    }

    validate(constraint, field, root = null) {

        if (!constraint instanceof IsPasswordMatch) {
            throw new Error(sprintf("IsPasswordMatch should be passed to IsPasswordMatchValidator.validate(). %s given.",
                constraint.constructor.name));
        }

        let value = root ? root[field] : field;

        if (value !== root[constraint.fieldName]) {
            return violationBuilder.build(constraint.message, {}, field, root, constraint.errorCode, value);
        }

        return true;
    }
}