/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 12.11.2018
 * Time: 16:40
 */

import React, {Component} from 'react';
import GalleryImageTitle from "../../gallery/elements/gallery-image-title";
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type {TGalleryImage} from "../../../types";
import {
    IMAGE_SIZE_MEDIUM
} from "../../../frizo-shared-constants";
import AirtouchCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";


type Props = TGalleryImage & {
    storeParams: {}
};

export default class ArtistPromoGalleryItemLook extends Component<Props, any> {

    render() {

        return <GalleryItemContainer>
            {this.props.imageBefore && <div className="fr-double-photo" onClick={this.props.onClick}></div>}

            <GalleryItemImage {...this.props} size={IMAGE_SIZE_MEDIUM}/>
            {this.props.artist.airtouchCertified && <AirtouchCertificateBadge {...this.props.artist}/>}
            <GalleryItemCardInfo>
                <GalleryImageTitle
                    serviceName={this.props.service.title}
                    firstName={this.props.artist.firstName}
                    lastName={this.props.artist.lastName}
                />

            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}

ArtistPromoGalleryItemLook.defaultProps = {};