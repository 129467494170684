/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.10.17
 */

import IsEqual, { VALUE_IS_NOT_EQUAL } from "./is-equal";

import violationBuilder from "./../violation-builder";
import BaseValidator from "./base-validator";

export default class IsEqualValidator extends BaseValidator {

    static getValidConstraints() {
        return [IsEqual];
    }

    validate(constraint, field, root = null) {

        this.assertConstraintIsAllowed(constraint);

        let value = root ? root[field] : field;

        let isValid = value === constraint.value;
        if (!isValid) {
            return violationBuilder.build(constraint.message, {
                "{value}": constraint.value
            }, field, root, VALUE_IS_NOT_EQUAL, value);
        }

        return true;
    }
}
