/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.31.5
 * Time: 15:03
 */

import ApiClient from "../service/api-client";
import { xhr } from "../service/api-client-2";

export function xhrLoadBrandList() {
    return ApiClient.get(["Q6_items_brands_v1"]).then(answer => {
        let [response] = answer;

        return response.response;
    });
}

export function xhrLoadProfileBrands(salonId) {
    return xhr()
    .get("@Q52_2_salon_brands", { salonId }).end()
    .then((request: Request) => {
        return Promise.resolve(request.response);
    });
}

export function xhrAddProfileBrand(brandId) {
    return ApiClient.post(["Q51_add_brands"], { brandList: brandId }).then(answer => {
        let [response] = answer;

        return response;
    });
}

export function xhrRemoveProfileBrand(brandId) {
    return ApiClient.post(["Q53_remove_brand"], { brandList: brandId }).then(answer => {
        let [response] = answer;

        return response;
    });
}
