/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.05.2017
 * Time: 15:44
 */
import React, {Component} from 'react';
import {NavItem} from 'react-bootstrap';
import RoutedTab from '../../components/tabs/routed-tab';
import Banner from './../../elements/banner';

export default class Legal extends Component {

    render() {
        let titleText = <div className="fr-banner-top__text--legal">Terms & Privacy</div>;

        return (
            <div>
                <Banner left={titleText} leftLength={15} key={3}/>
                <div>
                    <RoutedTab route={'/legal/:tab'}>
                        <NavItem key={1} eventKey="terms-for-clients">Terms for Clients</NavItem>
                        <NavItem key={2} eventKey="terms-for-professionals">Terms for Professionals</NavItem>
                        <NavItem key={3} eventKey="privacy-policy">Privacy policy</NavItem>
                    </RoutedTab>
                    <div className="container">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}