/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.1.18
 */

export class RequestWrapper extends Array {

    merge = () => {
        return this.reduce((result, request) => {
            return Object.assign(result, request.response);
        }, {});
    };

    toArray = () => {
        return this.map(request => request.response);
    };

    toHash = (mapCallback = null) => {
        let hash = Object.create(null);
        let garbledFlag = false;

        this.forEach((request, key) => {
            let id = request.getRequestId();
            if (!id) garbledFlag = true;

            let item = request.response;
            if (mapCallback) {
                item = mapCallback(request, key);
            }

            hash[id || key] = item;
        });

        if (garbledFlag) console.warn("Not all requests has id, so hash may be garbled");

        return hash;
    };

}

export function wrap(requestSet, forceMultiple = false) {
    let wrapper = requestSet.length > 0 ? requestSet[0] : requestSet;

    requestSet.forEach(request => {
        wrapper.push(request);

        let requestId = request.getRequestId();
        if (requestId) {
            wrapper[requestId] = request;
        }
    });

    return wrapper;
}
