/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.6.17
 * Time: 16.42
 */

import config from "./../config";

class StripeWrapper {
    constructor(apiKey) {

        /* global Stripe */
        this.stripe = Stripe(apiKey);
    }

    getStripe() {
        return this.stripe;
    }
}

export default new StripeWrapper(config.stripeApiKey);
