/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 28.11.2018
 * Time: 19.22
 */

import { xhr } from "../service/api-client-2";

export const xhrSavePushToken = (token) => {
    return xhr()
    .post("@Q83_set_push_token")
    .json({
        pushToken: token
    })
    .end()
    .then((request: Request) => {
        let { response } = request;

        if (response !== "OK") {
            return Promise.reject(response);
        }

        return response;

    }, (request: Request) => {
        let { response } = request;

        return Promise.reject(response);
    });
};
