/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 4/1/17
 * Time: 00:18
 */

import React from 'react';
import SignUp from './sign-up';
import {Row, Col, FormGroup, ButtonGroup} from 'react-bootstrap';
import Recaptcha from 'react-recaptcha';
import config from '../../config';
import BtnToggle from '../../components/forms/btn-toggle';
import Validator from '../../validation/validator';
import Violation from '../helper-components/violation';
import SignUpName from './sign-up-name';
import SignUpLoginInfo from './sign-up-login-info';
import AddressForm from './address-form';
import Spinner from './../../elements/spinner';
import SpinnerGif from './../../elements/spinner-gif';
import {browserHistory} from 'react-router';
import {
    updateRegistrationData,
    initForm,
    saveProSignUpData,
    saveListYourBusinessData,
} from '../../ac/sign-up';
import stores from '../../stores';
import {Link} from 'react-router';
import PhoneInput from '../../elements/phone-input';
import {
    USER_TYPE_FREELANCER,
    USER_TYPE_SALON_MASTER,
    USER_TYPE_SALON_OWNER,
    HRC_FIELD_GENERIC
} from '../../frizo-shared-constants';
import {featureFlagStore} from './../../stores';
import {pricingStore, listYourBusinessStore} from './../../stores';
import Filtration from '../../filter/filtration';
import TrimFilter from '../../filter/trim-filter';
import gradientFill from "../../helper/gradient-fill";
import {redirectAfterLogin} from "../../ac/redirect-after-login-actions";


class ProSignUp extends SignUp {
    constructor(props) {
        super(props);
        this.sessionStore = stores.session;
        this.state = {
            userName: '',
            firstName: '',
            lastName: '',
            salonName: '',
            userTypeCode: '',
            zip: '',
            zipEnabled: true,
            address: '',
            suite: '',
            lat: '',
            lon: '',
            phone: '',
            password: '',
            confirmPassword: '',
            facebookID: '',
            referralCode: '',
            showRecaptchaError: false,
            recaptchaResponse: '',
            formIsValid: true,
            violations: {},
            isReady: false,
            formDisabled: false
        };

        this.userTypeCodeSwitch = this.userTypeCodeSwitch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.onInfoChange = this.onInfoChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleSalonNameChange = this.handleSalonNameChange.bind(this);
        this.handleFeatureFlag = this.handleFeatureFlag.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
        this.updateAddress = this.updateAddress.bind(this);

    }

    userTypeCodeSwitch() {
        let userTypeCode = BtnToggle.getActiveValue('userTypeCode');

        let fullName = (this.state.firstName + ' ' + this.state.lastName).trim();
        let salonName = this.state.salonName;

        if (this.sessionStore.isLogged()) {
            fullName = this.sessionStore.getSession().firstName + ' ' + this.sessionStore.getSession().lastName;
        }

        if (!this.state.salonName.length && (userTypeCode === USER_TYPE_FREELANCER) && (fullName !== ' ')) {
            salonName = fullName;
        }
        if ((userTypeCode !== USER_TYPE_FREELANCER) && (this.state.salonName === fullName)) {
            salonName = '';
        }

        updateRegistrationData({userTypeCode, salonName});

    }

    componentDidMount() {
        BtnToggle.addStateChangeListener('userTypeCode', this.userTypeCodeSwitch);
        listYourBusinessStore.addChangeListener(this.onStoreChange);
        initForm();

        let userType = pricingStore.getStore().userType;
        userType && this.setState({
            userTypeCode: userType
        });
        this.sessionStore.addChangeListener(this.handleChange);

        let that = this;
        featureFlagStore.getReadyPromise().then(store => {
            console.log('FF ready: ', store.ff);
            that.handleFeatureFlag(store.ff.skipSubscriptions);
        });

    }

    componentWillUnmount() {
        BtnToggle.removeStateChangeListener('userTypeCode', this.userTypeCodeSwitch);
        this.sessionStore.removeChangeListener(this.handleChange);
        listYourBusinessStore.removeChangeListener(this.onStoreChange);
    }

    onStoreChange() {
        let state = Object.assign(this.state, listYourBusinessStore.getStore());
        this.setState(state);
    }

    handleFeatureFlag(skipSubscriptions) {
        if (skipSubscriptions) {
            browserHistory.push(`/pricing`);
        } else {
            this.setState({isReady: true});
        }
    }

    handleChange() {
        this.setState({
            session: this.sessionStore.getSession()
        });
    }


    onInfoChange(field, value) {
        updateRegistrationData({[field]: value});

    }

    handlePhoneChange(e) {
        updateRegistrationData({phone: e.target.rawValue});
    }

    handleCodeChange(e) {
        updateRegistrationData({referralCode: e.target.value})
    }

    handleSalonNameChange(e) {
        updateRegistrationData({salonName: e.target.value});
    }


    updateAddress(data) {
        updateRegistrationData(Object.assign({}, data));
    }

    save() {

        this.toggleFormDisable(true);
        let hiddenReferralCode = localStorage.getItem(HRC_FIELD_GENERIC);

        if (!this.sessionStore.isLogged()) {

            let callback = this.props.location.state ? this.props.location.state.callback : () => {
            };

            saveProSignUpData({
                username: this.state.userName,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                userTypeCode: this.state.userTypeCode,
                salonName: this.state.salonName,
                streetAddress: this.state.address,
                suite: this.state.suite,
                lat: this.state.lat,
                lon: this.state.lon,
                zip: this.state.zip,
                salonPhone: this.state.phone,
                password: this.state.password,
                facebookID: '',
                referralCode: this.state.referralCode,
                hiddenReferralCode: hiddenReferralCode
            }).then(answer => {
                setTimeout(function () {
                    callback();
                }, 500);
            }, () => {
                //resolve
            }).then(() => {
                this.toggleFormDisable(false);
            });

        } else {
            let re = /\s*,\s*/;

            let city = this.state.city.split(re)[0];
            let state = this.state.city.split(re)[1];

            saveListYourBusinessData({
                userTypeId: this.state.userTypeCode,
                salonName: this.state.salonName,
                address: this.state.address,
                suite: this.state.suite,
                zip: this.state.zip,
                lat: this.state.lat,
                lon: this.state.lon,
                salonPhone: this.state.phone,
                city: city,
                state: state,
                referralCode: this.state.referralCode,
                hiddenReferralCode: hiddenReferralCode
            }).then((response) => {
                setTimeout(function () {
                    redirectAfterLogin(response);
                }, 500);
            }, () => {
                //resolve
            }).then(() => {
                this.toggleFormDisable(false);
            });
        }

    }

    filter() {
        const filtration = new Filtration();
        const trim = new TrimFilter();
        if (!this.sessionStore.isLogged()) {
            filtration.registerFilter('userName', trim);
            filtration.registerFilter('firstName', trim);
            filtration.registerFilter('lastName', trim);
        }
        filtration.registerFilter('salonName', trim);
        filtration.registerFilter('address', trim);

        let result = filtration.filtrate(this.state);

        return Promise.resolve(result);
    }


    buildValidation() {
        let validator = new Validator();

        if (!this.sessionStore.isLogged()) {
            //firstName
            validator.addConstraint('firstName', 'is-required');
            validator.addConstraint('firstName', 'is-name');
            validator.addConstraint('firstName', 'length', {
                min: 2,
                max: 50
            });

            //lastName
            validator.addConstraint('lastName', 'is-required');
            validator.addConstraint('lastName', 'is-name');
            validator.addConstraint('lastName', 'length', {
                min: 2,
                max: 50
            });

            //username
            validator.addConstraint('userName', 'is-required');
            validator.addConstraint('userName', 'is-email');

            //password
            validator.addConstraint('password', 'is-required');
            validator.addConstraint('password', 'is-password');
            validator.addConstraint('password', 'length', {
                max: 20,
                min: 5
            });

            //confirmPassword
            validator.addConstraint('confirmPassword', 'is-password-match');
        }


        //userTypeCode
        validator.addConstraint('userTypeCode', 'is-required');

        //salonName
        validator.addConstraint('salonName', 'is-required');
        validator.addConstraint('salonName', 'is-salon-name');
        validator.addConstraint('salonName', 'length', {
            min: 2,
            max: 50
        });

        //zip
        validator.addConstraint('zip', 'is-required');
        validator.addConstraint('zip', 'is-number');
        validator.addConstraint('zip', 'length', {
            max: 5,
            min: 5
        });

        //address
        validator.addConstraint('address', 'is-required');
        validator.addConstraint('address', 'length', {
            min: 2,
            max: 100
        });

        //phone
        validator.addConstraint('phone', 'is-required');
        validator.addConstraint('phone', 'is-number');
        validator.addConstraint('phone', 'length', {
            max: 10,
            min: 10
        });


        return validator;
    }

    render() {
        let title = this.state.userTypeCode === USER_TYPE_FREELANCER ? "Business" : "Salon";

        if (!this.state.isReady) {
            return <Spinner/>
        }

        let bannerText = this.sessionStore.isLogged()? 'List your business' : 'Professional Sign up';

        return (
            <div>
                <div className="fr-banner-top__margin--mobile">
                    <div className="fr-banner-top fr-full-width" style={{'background': gradientFill()}}>
                        <Row className="fr-container-width">
                            <Col xs={12} className="fr-banner-top__text--left">
                                <h1>{bannerText}</h1>
                            </Col>
                        </Row>
                    </div>
                </div>
                <form name="pro_sign_up" method="post" noValidate="novalidate" className="form-horizontal fr-masked">
                    <fieldset disabled={this.state.formDisabled}>
                        <Row>

                            {!this.sessionStore.isLogged() &&

                            <div className="col-md-6">

                                <SignUpName violations={this.state.violations}
                                            formIsValid={this.state.formIsValid}
                                            onChange={this.onInfoChange}/>

                                <SignUpLoginInfo violations={this.state.violations}
                                                 formIsValid={this.state.formIsValid}
                                                 onChange={this.onInfoChange}/>

                            </div>}

                            <div className="col-md-6">

                                <h2>Who are you?</h2>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <div className="fr-mb">
                                            <ButtonGroup justified>
                                                <BtnToggle name="userTypeCode" selected={this.state.userTypeCode}
                                                           value={USER_TYPE_FREELANCER}>Freelancer</BtnToggle>
                                                <BtnToggle name="userTypeCode" selected={this.state.userTypeCode}
                                                           value={USER_TYPE_SALON_MASTER}>Salon Artist</BtnToggle>
                                                <BtnToggle name="userTypeCode" selected={this.state.userTypeCode}
                                                           value={USER_TYPE_SALON_OWNER}>Salon
                                                    Owner</BtnToggle>
                                            </ButtonGroup>
                                            <Violation violationSet={this.state.violations.userTypeCode}
                                                       visible={!this.state.formIsValid}/>
                                        </div>
                                    </div>
                                </FormGroup>


                                <h2>{title + " name"}</h2>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <input type="text" name="salonName" required="required"
                                               className="form-control" placeholder={title + " name"}
                                               value={this.state.salonName}
                                               autoComplete="off" maxLength="100"
                                               onChange={this.handleSalonNameChange}/>
                                        <Violation violationSet={this.state.violations.salonName}
                                                   visible={!this.state.formIsValid}/>
                                    </div>
                                </FormGroup>

                                <h2>Phone &amp; location</h2>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <PhoneInput className="form-control"
                                                    type="text"
                                                    value={this.state.phone}
                                                    placeholder="Phone"
                                                    required="required"
                                                    name={"phone"}
                                                    autoComplete="off"
                                                    mask='(111) 111-1111'
                                                    onChange={this.handlePhoneChange}
                                        />
                                        <Violation violationSet={this.state.violations.phone}
                                                   visible={!this.state.formIsValid}/>
                                    </div>
                                </FormGroup>
                                <AddressForm
                                    city={this.state.city}
                                    address={this.state.address}
                                    zip={this.state.zip}
                                    suite={this.state.suite}
                                    onChange={this.updateAddress}
                                    onZipChange={this.updateAddress}
                                    violations={this.state.violations}
                                    formIsValid={this.state.formIsValid}
                                />

                                <h2>Referral code</h2>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <input type="text" name="referralCode" id="referralCode"
                                               onChange={this.handleCodeChange}
                                               className="form-control" placeholder="Referral code (optional)"/>
                                        <Violation violationSet={this.state.violations.referralCode}
                                                   visible={!this.state.formIsValid}/>
                                    </div>
                                </FormGroup>

                                {!this.sessionStore.isLogged() &&
                                <div className="form-disclaimer">
                                    <div>
                                        By pressing Sign up you agree with&nbsp;
                                    </div>
                                    <Link to="/pro-sign-up/terms-for-clients">User agreement</Link>,&nbsp;
                                    <Link to="/pro-sign-up/privacy-policy">Privacy policy</Link> and&nbsp;
                                    <Link to="/pro-sign-up/terms-for-professionals">Vendor agreement</Link>
                                </div>
                                }

                                {this.sessionStore.isLogged() &&
                                <div className="form-disclaimer">
                                    By pressing Sign up you agree with&nbsp;
                                    <Link to="/list-your-business/privacy-policy">Privacy policy</Link> and&nbsp;
                                    <Link to="/list-your-business/terms-for-professionals">Vendor agreement</Link>
                                </div>
                                }

                                <FormGroup>
                                    <div className="col-sm-8 col-xs-8">
                                        <Recaptcha
                                            sitekey={config.googleRecaptchaSiteKey}
                                            render="explicit"
                                            ref="recaptchaInstance"
                                            verifyCallback={this.verifyReCaptureCallback}
                                            onloadCallback={this.onLoadReCaptchaCallback}
                                        />
                                        <Violation violationSet={['Captcha is required']}
                                                   visible={this.state.showRecaptchaError}/>
                                    </div>
                                    <div className="col-sm-4 col-xs-4">
                                        {this.state.formDisabled && <SpinnerGif/>}
                                        <button type="submit" name="sign_up_button" onClick={this.process}
                                                disabled={this.state.formDisabled}
                                                className="btn center-block fr-mt fr-btn pull-right">Sign up
                                        </button>
                                    </div>
                                </FormGroup>
                            </div>
                        </Row>
                    </fieldset>
                </form>
            </div>

        );
    }
}

export default ProSignUp;
