/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.31.5
 * Time: 15:37
 */

import React, {Component} from 'react';
import DropdownWrapper from './../../../../elements/dropdown-wrapper';
import {Button, Row, Col} from 'react-bootstrap';
import {addSalonBrandAction} from '../../../../ac/profile/professional/professional-profile-brands-actions';

export default class AddBrandForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedId: 0,
            btnDisabled: false
        };

        this.onBrandChange = this.onBrandChange.bind(this);
        this.addBrand = this.addBrand.bind(this);
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            selectedId: 0,
            btnDisabled: false
        });
    }

    onBrandChange(id) {
        this.setState({selectedId: id});
    }


    addBrand() {
        if (this.state.selectedId) {
            this.setState({btnDisabled: true});
            addSalonBrandAction(this.state.selectedId);
        }
    }

    render() {

        if (this.props.unselectedBrands.length) {

            return <Row>
                <Col md={4}>
                    <DropdownWrapper
                        placeholder={"Select brand"}
                        selected={this.state.selectedId}
                        list={this.props.unselectedBrands}
                        onChange={this.onBrandChange}
                        optionTextPropName="title"
                    />

                </Col>
                <Col md={8}>
                    <Button
                        className="fr-btn-add btn fr-btn pull-left fr-mt-xs"
                        to="/my-salon/brands/add"
                        disabled={this.state.btnDisabled}
                        onClick={this.addBrand}>Add brand
                    </Button>
                </Col>
            </Row>
        }

        return null;
    }
}

AddBrandForm.defaultProps = {
    salonId: 0,
    unselectedBrands: []
};