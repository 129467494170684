/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.3.17
 * Time: 20.18
 */

import ApiClient from "../service/api-client";
import { CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";

export default function xhrLoadManagedArtists() {

    return ApiClient.get(["Q34_2_managed_atists"], null, {
        headers: [{
            name: CUSTOM_HEADER_U6_GROUPS,
            value: ["basic", "schedule", "userSalon"]
        }]
    }).then(answer => {
        let [response] = answer;

        return response;
    });
}
