/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 11.7.17
 */

import {
    PROFILE_PROFESSIONAL_CHARGES_LOADED,
    PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ADD,
    PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ADD_ERROR,
    PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ERROR,
    PROFILE_PROFESSIONAL_PAYMENT_DATA_COMPLETION_REPORT_LOADED,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED,
    PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
    PROFILE_PROFESSIONAL_TRANSACTIONS_LOADED,
    SALON_SUBSCRIPTION_CANCEL_ERROR,
    SALON_SUBSCRIPTION_CANCELED
} from "../../constants";

import AppDispatcher from "../../dispatcher/index";
import { xhrAddExternalAccount } from "../../xhr/payments/professional/external-account/add-external-account-xhr";
import { xhrRemoveExternalAccount } from "../../xhr/payments/professional/external-account/remove-external-account-xhr";
import { xhrSetDefaultExternalAccount } from "../../xhr/payments/professional/external-account/set-default-external-account-xhr";
import {
    xhrGetPaymentDataCompletionReport,
    xhrGetStripeAccount, xhrGetStripeAccountPersons
} from "../../xhr/payments/professional/get-stripe-account-xhr";
import {
    xhrCancelSubscription,
    xhrGetCustomerCharges,
    xhrGetPaidTransactions
} from "../../xhr/payments/professional/payment-profile-xhr";

import { xhrGetStripeSubscriptionCustomer } from "../../xhr/payments/professional/pay/get-stripe-subscription-customer-xhr";

export function loadStripeAccount() {
    return xhrGetStripeAccount().then(response => {
        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED,
            payload: response
        });

        return response;
    });
}

export function addNewExternalAccount(token) {
    if (typeof token === "object") token = token.id;

    return xhrAddExternalAccount(token).then(response => {

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ADD,
            payload: response
        });

        loadStripeAccount();

    }, response => {

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ADD_ERROR,
            payload: response
        });

        return Promise.reject(response);

    });
}


export function setDefaultExternalAccount(id) {

    return xhrSetDefaultExternalAccount(id).then(response => {
        console.log("set-default", response);
        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED,
            payload: response
        });
    }, response => {

        console.warn("fail", response);

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ERROR,
            payload: {
                general: ["Problem with default external account selecting"]
            }
        });

        return Promise.reject(response);

    });
}

export function removeExternalAccount(id) {

    return xhrRemoveExternalAccount(id).then(response => {
        console.log("remove", response);

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED,
            payload: response
        });
    }, response => {

        console.warn("fail", response);

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ERROR,
            payload: {
                general: ["Problem with external account removing"]
            }
        });

        return Promise.reject(response);

    });
}

export function loadSalonSubscriptionCharges(params) {

    return xhrGetCustomerCharges(params).then(response => {

            AppDispatcher.dispatch({
                type: PROFILE_PROFESSIONAL_CHARGES_LOADED,
                payload: response
            });
        },
        response => {
            return Promise.reject(response);
        });

}

export function cancelSalonSubscription() {

    return xhrCancelSubscription().then(response => {

        AppDispatcher.dispatch({
            type: SALON_SUBSCRIPTION_CANCELED,
            payload: response
        });
    }, response => {

        AppDispatcher.dispatch({
            type: SALON_SUBSCRIPTION_CANCEL_ERROR,
            payload: {
                general: ["Problem with subscription canceling"]
            }
        });

        return Promise.reject(response);
    });
}

export function loadSalonTransactions(params) {
    return xhrGetStripeSubscriptionCustomer().then(response => {
        AppDispatcher.dispatch({
                type: PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
                payload: response
            },
            response => {
                return Promise.reject(response);
            });

    }).then(response => {

        return xhrGetPaidTransactions(params).then(response => {
                AppDispatcher.dispatch({
                    type: PROFILE_PROFESSIONAL_TRANSACTIONS_LOADED,
                    payload: response
                });
            },
            response => {
                return Promise.reject(response);
            });
    });
}

export function getSalonSubscriptionCharges(params) {

    return xhrGetStripeSubscriptionCustomer().then(response => {
        AppDispatcher.dispatch({
                type: PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
                payload: response
            },
            response => {
                return Promise.reject(response);
            });

    }).then(response => {

        return xhrGetCustomerCharges(params).then(response => {
                AppDispatcher.dispatch({
                    type: PROFILE_PROFESSIONAL_CHARGES_LOADED,
                    payload: response
                });
            },
            response => {
                return Promise.reject(response);
            });
    });
}

export function loadPaymentDataCompletionReport(param) {
    return xhrGetPaymentDataCompletionReport(param).then(response => {
        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_PAYMENT_DATA_COMPLETION_REPORT_LOADED,
            payload: response
        });

        return response;
    });
}

export function loadStripeAccountPersons() {
    return xhrGetStripeAccountPersons().then(response => {
        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED,
            payload: response
        });

        return response;
    });
}