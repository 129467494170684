/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.1.18
 */

import { AbstractResponseHandler } from "../service/api-client-2/abstract-response-handler";
import { CUSTOM_HEADER_U6_AUTH } from "../frizo-shared-constants";
import { Request } from "../service/api-client-2/request";
import { renewToken } from "./index";
import config from "./../config";

export class AuthApiClient2ResponseHandler extends AbstractResponseHandler {
    constructor() {
        super();

        this.renewing = false;
    }

    handleResponse(request: Request, xhr: XMLHttpRequest): boolean {

        let authResult = xhr.getResponseHeader(CUSTOM_HEADER_U6_AUTH);
        let invalidResults = [
            config.auth_problems.token_missing,
            config.auth_problems.token_invalid
        ];

        if (~invalidResults.indexOf(authResult)) {

            this.renewing = true;

            renewToken().then(response => {
                this.renewing = false;

                console.log("Renew token successful");
            }, response => {
                this.renewing = false;

                console.error("Renew token fail, show login popup", response);
            });

            return false; //skip another handlers
        }
        else {
            return true;
        }
    }
}
