/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.2.17
 * Time: 17.12
 */

import IsDate, { VALUE_IS_NOT_A_DATE, VALUE_IS_NOT_IN_FUTURE, VALUE_IS_NOT_IN_PAST } from "./is-date";

import violationBuilder from "./../violation-builder";
import sprintf from "./../../helper/sprintf";
import BaseValidator from "./base-validator";
import moment from "moment";

export default class IsDateValidator extends BaseValidator {

    static getValidConstraints() {
        return [IsDate];
    }

    validate(constraint, field, root = null) {
        if (!(constraint instanceof IsDate)) {
            throw sprintf("IsDate should be passed to IsDateValidator.validate(). %s given.",
                constraint.constructor.name);
        }

        let value = root ? root[field] : field;

        let momentValue = moment(value);

        if (!momentValue.isValid()) {
            return violationBuilder.build(constraint.message, {}, field, root, VALUE_IS_NOT_A_DATE, value);
        }

        let now = (new Date()).valueOf() / 1000;

        console.log("now", now, momentValue.unix());

        if (momentValue.unix() > now) {
            if (constraint.inPastOnly) {
                return violationBuilder.build(constraint.messageIsNotInPast,
                    {},
                    field,
                    root,
                    VALUE_IS_NOT_IN_PAST,
                    value);
            }
        }
        else {
            if (constraint.inFutureOnly) {
                return violationBuilder.build(constraint.messageIsNotInFuture,
                    {},
                    field,
                    root,
                    VALUE_IS_NOT_IN_FUTURE,
                    value);
            }
        }

        return true;
    }
}
