/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.6.17
 * Time: 13.42
 */

import { xhr } from "../../../service/api-client-2";

export function xhrGetCustomer() {
    return xhr()
    .suppressNotifications()
    .get("@Q97_get_stripe_customer")
    .end()
    .then((request: Request) => {
            let { response } = request;
            return Promise.resolve(response);
        },
        (request: Request) => {
            let { response } = request;
            return Promise.reject(response.errors);
        });
}
