/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 24.7.17
 */

import BaseStore from "./base-store";
import {
    COMMON_MODAL_CLEAR,
    MODAL_CHANGE,
    MODAL_HIDE,
    PAYMENT_APPROVAL_CANCELED,
    PAYMENT_APPROVAL_CHANGE_PAYMENT_SOURCE,
    PAYMENT_APPROVAL_DISCOUNT_SOLUTION_LOADED,
    PAYMENT_APPROVAL_FAILED,
    PAYMENT_APPROVAL_MODAL,
    PAYMENT_APPROVAL_UPDATE_TIP,
    PROFILE_PERSONAL_MYCARDS_LOADED
} from "../constants";
import { DEFAULT_TIP_PERCENT } from "../frizo-shared-constants";

export default class PaidApprovalPopupStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case MODAL_CHANGE:
                    if (payload.type === PAYMENT_APPROVAL_MODAL) {
                        this.resetStore();
                        this.store.active = true;

                        Object.assign(this.store, payload.options.paymentOrder);
                        this.store.paymentOrderId = payload.options.paymentOrder.id;


                        this.updateTotal();

                        this.store.modalReady = true;

                    }
                    break;

                case MODAL_HIDE:
                    this.store.active = false;
                    this.store.btnDisabled = false;
                    break;

                case COMMON_MODAL_CLEAR:
                    if (!this.store.active) return true;
                    this.resetStore();

                    break;

                case PROFILE_PERSONAL_MYCARDS_LOADED:
                    this.store.cards = payload.sources ? payload.sources.data : [];
                    this.store.cardsReady = true;

                    if (this.store.cards && this.store.cards.length > 0) {
                        this.store.paymentSourceId = this.store.cards[0].id;
                    }

                    break;

                case PAYMENT_APPROVAL_UPDATE_TIP:

                    this.store.tipPercent = Math.abs(Number(payload.percent));
                    this.store.tipCustomSelected = payload.customSelected;

                    if (this.store.tipCustomSelected) {
                        this.store.tipCustomPercent = Math.abs(Number(payload.customPercent));
                    }

                    this.updateTotal();

                    break;

                case PAYMENT_APPROVAL_CHANGE_PAYMENT_SOURCE:
                    this.store.paymentSourceId = payload.paymentSourceId;

                    break;

                case PAYMENT_APPROVAL_FAILED:
                    this.store.violations = payload;
                    this.store.btnDisabled = false;
                    break;

                case PAYMENT_APPROVAL_CANCELED:
                    this.store.btnDisabled = false;
                    break;

                case PAYMENT_APPROVAL_DISCOUNT_SOLUTION_LOADED:
                    this.store.discountSolution = payload;
                    this.store.discountReady = true;
                    break;

                default:
                    return true;

            }

            this.emitChange();
        });
    }

    getInitialStore() {
        return {
            tipPercent: DEFAULT_TIP_PERCENT,
            tipCustomPercent: 0,
            tipCustomSelected: false,

            paymentOrderId: null,

            cards: [],
            paymentSourceId: "",

            tipValue: 0,
            taxValue: 0,
            taxPercent: 0,
            serviceAndTax: 0, //services sum + tax
            serviceTotalEntered: 0,
            total: 0, //services sum + tax + tip

            services: [],

            violations: {},

            cardsReady: false,
            modalReady: false,
            discountReady: false,
            btnDisabled: false,
            discountSolution: {
                reservedAmount: null,
                reason: ""
            }

        };
    }

    updateTotal() {
        this.store.tipValue = Math.round(this.store.tipPercent * this.store.serviceTotalEntered) / 100;
        this.store.total = Math.round((this.store.serviceAndTax + this.store.tipValue) * 100) / 100;
    }
}
