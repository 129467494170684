/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 10.5.17
 * Time: 14.33
 */

import React from 'react';
import PropTypes from 'prop-types';
import idGenerator from '../../helper/id-generator-component';

export function TableHead(props) {
    let columns = [];

    props.members.forEach(function (member) {

        /*let timezoneInfo = 'UTC';

        if (props.memberOffsetIndex && props.memberOffsetIndex[member.id]) {
            timezoneInfo += props.memberOffsetIndex[member.id];
        }*/


        columns.push(<th key={ idGenerator('col-head', member.id) }>
            { member.firstName } { member.lastName }
        </th>);
    });

    return (
        <thead>
        <tr>
            { columns }
        </tr>
        </thead>
    );
}

TableHead.propTypes = {
    members: PropTypes.array.isRequired,
    memberOffsetIndex: PropTypes.object
};
