/**
 * User: Ignat Kryshchyk
 * Username: ignat
 * Company: U6 SIA
 * Date: 13.3.17
 * Time: 10.09
 */

import React, {Component} from 'react';

import {professionalProfileBrandStore} from '../../../stores/index';
import AddBrandForm from  '../professional/brands/add-brand-form';
import {createGallery} from "../../gallery2/gallery-factory";
import {
    GALLERY2_PROFESSIONAL_PROFILE_BRAND_ID,
} from "../../../frizo-shared-constants";
import {loadBrandList, loadProfileBrands} from "../../../ac/profile/professional/professional-profile-brands-actions";
import {
    Row,
    Col
} from 'react-bootstrap';
import ProfessionalProfileBrandItem from "../../gallery2/items/professional-profile-brand-item";
import LoaderBar from '../../../elements/loader-bar';

export default class ProfessionalProfileBrands extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            params: {
                salonId: null
            },
            unselectedBrands: [],
            salonBrands: []
        };

        this.onGalleryStoreChange = this.onGalleryStoreChange.bind(this);

    }

    componentDidMount() {
        professionalProfileBrandStore.addChangeListener(this.onGalleryStoreChange);
        loadBrandList();

        this.gallery = this.createGallery();
        this.gallery.reset();

    }

    componentWillUnmount() {
        professionalProfileBrandStore.removeChangeListener(this.onGalleryStoreChange);
    }

    getStoreData() {
        let storeData = professionalProfileBrandStore.getStore();

        return {
            params: storeData.gallery.params,
            unselectedBrands: storeData.unselectedBrands
        }
    }

    onGalleryStoreChange() {
        let newState = Object.assign({}, this.getStoreData());

        if (newState.params.salonId)  {
            if (!this.state.ready)   {
                newState.ready = true;
                this.gallery.init();
            }

        }

        this.setState(newState);

    }



    createGallery() {

        return createGallery(GALLERY2_PROFESSIONAL_PROFILE_BRAND_ID)
            .setGalleryStore(professionalProfileBrandStore)
            .setFavoritesEnabled(false)
            .setNoItemsText('Yet to post images')
            .setItemClass(ProfessionalProfileBrandItem)
            .setItemClickHandler(() => {}) //block fullscreen
            .setGalleryLoader(loadProfileBrands);
    }

    render() {

        if (!this.state.ready) {
            return  <LoaderBar className="loader-msg-wrapper--full-height"/>;
        }

        return <div>
            <Row>
                <Col md={12}>
                    <AddBrandForm unselectedBrands={this.state.unselectedBrands}/>
                </Col>
            </Row>
            {this.gallery.getGallery()}
        </div>;


    }
}
