/**
 * Created by Eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 30-Mar-17
 * Time: 14:43
 */

import React, { Component } from 'react';
import { FormGroup } from 'react-bootstrap';
import Violation from '../helper-components/violation';


class SignUpName extends Component {


    constructor(props)  {
        super(props);

        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);


        this.state = {
            formIsValid: props.formIsValid,
            violations: props.violations
        };

    }

    handleFirstNameChange(e) {
        this.props.onChange('firstName', e.target.value);
    }

    handleLastNameChange(e) {
        this.props.onChange('lastName', e.target.value);
    }


    render() {
        return (
            <div>
                <h2>Name</h2>
                <FormGroup>
                    <div className="col-sm-12">
                        <input type="text" name="firstName" required="required" id="firstName"
                               className="form-control" placeholder="First" onChange={this.handleFirstNameChange}/>
                        <Violation violationSet={this.props.violations.firstName} visible={!this.props.formIsValid} />
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className="col-sm-12">
                        <input type="text" name="lastName" required="required" id="lastName"
                               className="form-control" placeholder="Last" onChange={this.handleLastNameChange}/>
                        <Violation violationSet={this.props.violations.lastName} visible={!this.props.formIsValid} />
                    </div>
                </FormGroup>
            </div>
        );
    }
}

export default SignUpName;
