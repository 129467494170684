/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 21.3.17
 * Time: 7.57
 */

import { sessionStore } from "./../stores/index";
import { browserHistory } from "react-router";

import { assertSession, getDataGroups, renewToken, setDataGroups } from "./index";

export default function authCheck(routes, nextUrl) {
    let requireData = [];

    routes.forEach(route => {
        if (route.requireAuth && route.requireNotAuth) throw new Error("Router configuration error for " + route.path);

        if (route.requireAuth) {
            sessionStore.getReadyPromise().then(() => {

                if (!sessionStore.isLogged()) {

                    browserHistory.replace({
                        pathname: "/log-in",
                        state: {
                            successCallback: () => {
                                setTimeout(() => {
                                    browserHistory.push(nextUrl);
                                }, 10);

                            }
                        }
                    });
                }
            });
        }


        if (route.requireNotAuth) {
            sessionStore.getReadyPromise().then(() => {

                if (sessionStore.isLogged()) {
                    browserHistory.replace({
                        pathname: "/"
                    });
                }
            });
        }

        if (route.component && route.component.requireData) {
            requireData = requireData.concat(route.component.requireData);
        }
    });

    let needPreload = true;
    if (sessionStore.isReady()) {
        let loadedGroups = getDataGroups();
        needPreload = requireData.filter(group => {
            return loadedGroups.indexOf(group) === -1;
        }).length > 0;
    }

    setDataGroups(requireData);

    let initSession = function() {
        if (needPreload) {
            //force to preload data
            renewToken();
        }
        else {
            //only ensure that session exists
            assertSession();
        }
    };

    if (sessionStore.getStore().loginSemaphore) {
        sessionStore.getReadyPromise().then(initSession, initSession);
    }
    else {
        initSession();
    }

}
