/**
 * Created by Ihnat
 *
 * @flow
 */

import React, {Component} from 'react';

import {withGoogleMap, GoogleMap, DirectionsRenderer, Marker} from 'react-google-maps';
import type {TCoor, TGmapDirections, TGmapMarker} from "../../types";
import {notifyFail} from "../../ac/notify-actions";


declare var google: any;

type TDirectionsGoogleMapProps = {
    center: TCoor,
    errors: string,
    directions: Array<TGmapDirections>,
    markers: Array<TGmapMarker>
};

const DirectionsGoogleMap = withGoogleMap((props: TDirectionsGoogleMapProps) => {
    let center = {
        lat: props.center.lat,
        lng: props.center.lon
    };

    return <GoogleMap defaultZoom={14} center={center}>
        {props.directions && <DirectionsRenderer directions={props.directions}
        />}
        {props.markers.map(marker => (
            <Marker
                {...marker}
            />
        ))}
        {props.errors && notifyFail(props.errors)}
    </GoogleMap>
});

type TProps = {
    userAddress: string,
    mapCenter: TCoor,
    markers: Array<TGmapMarker>,
    salonAddress: TCoor,
    statusChange: Function
};

type TState = {
    origin: string,
    destination: TCoor,
    directions: Array<TGmapDirections> | null,
    errors: string
};

export default class MapDirections extends Component<TProps, TState> {

    origin: string;
    destination: TCoor;


    constructor(props: TProps) {
        super(props);
        this.origin = props.userAddress;
        this.destination = props.mapCenter;
        this.state = {
            origin: props.userAddress,
            destination: this.destination,
            directions: null,
            errors: ''
        };

    }

    componentWillReceiveProps(nextProps: TProps) {
        this.setState({errors: ''});
        if (nextProps.salonAddress) {
            this.origin = nextProps.userAddress;
            this.destination = new google.maps.LatLng(nextProps.salonAddress.lat, nextProps.salonAddress.lon);
            this.setState({origin: this.origin, destination: this.destination});
        }

        this.origin && this.buildRoute();
    }

    buildRoute() {
        const DirectionsService = new google.maps.DirectionsService();

        DirectionsService.route({
            origin: this.origin,
            destination: this.destination,
            travelMode: google.maps.TravelMode.DRIVING
        }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                this.setState({
                    directions: result,
                    errors: ''
                });
            } else {
                if (status === google.maps.DirectionsStatus.NOT_FOUND) {
                    this.setState({
                        errors: 'Unable to build Directions. Check your current location',
                        directions: null
                    })
                }
                else {
                    this.setState({
                        errors: 'Error fetching directions',
                        directions: null
                    })
                }
            }
            this.props.statusChange(status);
        });
    }

    render() {
        let styles = {
            height: `500px`,
            width: '100%'
        };

        return (
            <DirectionsGoogleMap containerElement={< div style={
                styles
            }/>} mapElement={< div style={
                styles
            }
            />} center={this.props.mapCenter} directions={this.state.directions}
                                 markers={this.props.markers} errors={this.state.errors}/>

        );
    }
}
