/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.2.17
 * Time: 17.12
 */

import IsRequired, { VALUE_IS_REQUIRED } from "./is-required";
import violationBuilder from "./../violation-builder";
import sprintf from "./../../helper/sprintf";
import BaseValidator from "./base-validator";

export default class IsRequiredValidator extends BaseValidator {

    static getValidConstraints() {
        return [IsRequired];
    }

    validate(constraint, field, root = null) {
        if (!constraint instanceof IsRequired) {
            throw new Error(sprintf("IsRequired should be passed to IsRequiredValidator.validate(). %s given.",
                constraint.constructor.name));
        }

        let value = root ? root[field] : field;

        if (value === undefined || value === null || String(value).length === 0) {
            return violationBuilder.build(constraint.message, {}, field, root, VALUE_IS_REQUIRED, value);
        }

        return true;
    }
}
