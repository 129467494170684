/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 09.10.2017
 * Time: 10:46
 */

export default function printSum(num) {

    if (isNaN(num) || (num === 0)) return 0;

    return (Math.round(num * 100) / 100).toFixed(2);
}
