/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 16.02.2018
 * Time: 11:44
 */

import ApiClient from "../service/api-client";

export const xhrContactUs = function(params) {

    return ApiClient.post(["Q93_contact_us"], params, { run_directly: true })
    .then((answer) => {

        let response = answer[1];

        return Promise.resolve(response);

    }, answer => {
        let [response] = answer;

        return Promise.reject(response);

    });
};
