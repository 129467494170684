/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 19.09.2017
 * Time: 10:08
 */

import {
    GET_COUPON,
    LOAD_SUBSCRIPTION_PRODUCTS,
    PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR,
    PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
    SALON_SUBSCRIPTION_ADDED,
    SALON_SUBSCRIPTION_CARD_ADDED,
    SALON_SUBSCRIPTION_CHANGED,
    SALON_SUBSCRIPTION_CREATE_ERROR,
    SELECT_SUBSCRIPTION
} from "../constants";
import AppDispatcher from "../dispatcher";
import {
    xhrChangeSubscriptionPlan,
    xhrCreateNewSubscription,
    xhrGetAvailableSubscriptionProducts,
    xhrGetPromoCoupon
} from "../xhr/subscription-plans-xhr";
import { xhrAddPayCard } from "../xhr/payments/professional/pay/add-pay-card-xhr";
import { xhrGetStripeSubscriptionCustomer } from "../xhr/payments/professional/pay/get-stripe-subscription-customer-xhr";

export function selectSubscription(id) {
    AppDispatcher.dispatch({
        type: SELECT_SUBSCRIPTION,
        payload: id
    });
}

export function getSubscriptionPlans(userPlatform) {

    return xhrGetStripeSubscriptionCustomer().then(response => {
        AppDispatcher.dispatch({
                type: PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
                payload: response
            },
            response => {
                return Promise.reject(response);
            });

    }).then(response => {
        xhrGetAvailableSubscriptionProducts().then(data => {
            AppDispatcher.dispatch({
                    type: LOAD_SUBSCRIPTION_PRODUCTS,
                    payload: {
                        data: data,
                        userPlatform: userPlatform
                    }
                }
            );
        });
    });
}

export function getPromoCoupon(couponId) {
    xhrGetPromoCoupon(couponId).then(data => {
            AppDispatcher.dispatch({
                    type: GET_COUPON,
                    payload: data

                }
            );
        },
        () => {
            AppDispatcher.dispatch({
                    type: GET_COUPON,
                    payload: []
                }
            );
        });
}

export function createNewSubscription(params) {
    xhrCreateNewSubscription(params).then(data => {
            AppDispatcher.dispatch({
                    type: SALON_SUBSCRIPTION_ADDED,
                    payload: data
                }
            );
        },
        (errors) => {
            AppDispatcher.dispatch({
                    type: SALON_SUBSCRIPTION_CREATE_ERROR,
                    payload: errors
                }
            );
        });
}

export function addNewPayCard(token) {
    if (typeof token === "object") token = token.id;

    return xhrAddPayCard(token).then(response => {

        AppDispatcher.dispatch({
            type: SALON_SUBSCRIPTION_CARD_ADDED,
            payload: response
        });

    }, response => {

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR,
            payload: response
        });

        return Promise.reject(response);

    });
}


export function changeSubscriptionPlan(planId) {

    xhrChangeSubscriptionPlan(planId).then(data => {
            AppDispatcher.dispatch({
                    type: SALON_SUBSCRIPTION_CHANGED,
                    payload: data
                }
            );
        },
        (errors) => {
            AppDispatcher.dispatch({
                    type: SALON_SUBSCRIPTION_CREATE_ERROR,
                    payload: errors
                }
            );
        });
}
