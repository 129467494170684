/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.1.18
 */

import { singleAvatarNormalizer } from "../../normal-form/gallery/professional-profile/single-avatar-normalizer";
import { xhrLoadSalonArtists } from "../profile-artists-xhr";

type TParams = {
    salonId: number, count: number, offset: number, filter: {}
};

export function xhrLoadSalonArtistGallery({ salonId, count, offset, filter = {} }: TParams) {

    return xhrLoadSalonArtists(salonId, count, offset).then(items => {

        return {
            items: items.map(singleAvatarNormalizer)
        };
    });
}
