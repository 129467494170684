/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 04.04.2018
 * Time: 11:21
 */
import React, {Component} from 'react';
import {Dropdown, MenuItem} from 'react-bootstrap';
import {dashboardWhereStore} from '../../../stores/index';
import {whereHintSelect} from '../../../ac/dashboard-filter-actions';

export default class DashboardWhereMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: true,
            searchList: dashboardWhereStore.getSearchList(),
            searchValue: dashboardWhereStore.getSearchVal(),
            listLoaded: false
        };

        this.onChange = this.onChange.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
    }

    onStoreChange() {

        this.setState({
            searchList: dashboardWhereStore.getSearchList(),
            searchValue: dashboardWhereStore.getSearchVal(),
            active: dashboardWhereStore.getMenuActive()
        });
    }

    componentDidMount() {
        dashboardWhereStore.addChangeListener(this.onStoreChange);
    }

    componentWillUnmount() {
        dashboardWhereStore.removeChangeListener(this.onStoreChange);
    }

    onChange(ev) {
        this.setState({active: false});
        this.props.onSelect(ev);
        whereHintSelect(ev);
    }


    createMenuItems() {

        return this.state.searchList.map((item, i) => {

            let menuItemClass = null;
            let className = this.props.className;
            if (this.props.selected === i) {
                menuItemClass = className + ' active';
            }
            let zip = item.zip === null ? '' : item.zip + ', ';
            let menuItem = '';

            if (this.state.searchValue) {
                menuItem = zip + item.city + ', ' + item.state;
            }
            else {
                menuItem = item.city + ', ' + item.state;
            }
            let pos = menuItem.toLowerCase().indexOf(this.state.searchValue);
            if (pos > -1) {
                let part1 = menuItem.substring(0, pos);
                let part2 = menuItem.substring(pos, pos + this.state.searchValue.length);
                let part3 = menuItem.substring(pos + this.state.searchValue.length);

                menuItem = part1 + `<strong>${part2}</strong>` + part3;
            }


            return (
            <MenuItem
                    className={menuItemClass}
                    key={i}
                    eventKey={item.zip ? item.zip : item.city}
                    onSelect={this.onChange}>
                    <span dangerouslySetInnerHTML={{__html: menuItem}}></span>
                </MenuItem>
            )
        })
    }

    render() {
        if (this.props.show === false) return null;

        if (this.state.searchList.length && this.state.active) {
            let menuItems = this.createMenuItems();

            return (

                <Dropdown.Menu className={this.props.className + '--menu'}>
                    {menuItems}
                </Dropdown.Menu>
            )
        }
        return null
    }
}

DashboardWhereMenu.defaultProps = {
    searchValue: '',
    onChange: () => false
};
