/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 14.11.17
 * Time: 12.25
 */

import { LOGIN_PROCESS_SEMAPHORE_ENABLE, USER_AUTH } from "../constants";
import AppDispatcher from "../dispatcher";
import { xhrGetQuickLogin } from "../xhr/quick-login-xhr";
import { events } from "../auth/events";


export function performQuickLogin(token) {


    AppDispatcher.dispatch({
        type: LOGIN_PROCESS_SEMAPHORE_ENABLE,
        payload: true
    });

    return xhrGetQuickLogin(token).then(payload => {

        events.renewingFinished.dispatch(true, payload.session, {
            groups: payload.options.groups,
            request: payload.groups
        });

        events.login.dispatch(true, payload.session, {
            groups: payload.options.groups,
            request: payload.groups
        });

        return payload;

    }, payload => Promise.resolve(payload))
    .then(payload => {

        AppDispatcher.dispatch({
            type: USER_AUTH,
            payload: payload
        });

        return Promise.resolve(payload);
    });
}
