/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.27.3
 * Time: 14:55
 */
import React, { Component } from 'react';
import ServicesSelect from './services-select';
import AddServiceForm from './add-service-form';
import { Row, Col } from 'react-bootstrap';
import { ServiceListCreator } from './helpers';


export default  class RawServicesList extends Component {

    constructor(...args) {

        super(...args);

        this.state = {
            selectedId: 0,
        };
        this.onServiceChange = this.onServiceChange.bind(this);
    }

    onServiceChange(ev) {
        const selectedId = parseInt(ev.target.value, 10);
        this.setState({
            selectedId
        });
    }

    componentWillReceiveProps() {

        this.setState({selectedId: 0});
    }

    makeServicesHip(listOfLeafs) {
        let servicesIds = new Set();
        let services = [];
        const listCreator = new ServiceListCreator(this.props.services);
        listOfLeafs.forEach(leaf => {
            let branch = listCreator.createParentsBranch(this.props.services, leaf.id);
            branch.forEach(id => {
                servicesIds.add(id);
            });
        });

        servicesIds.forEach((id) => {
            services.push(this.props.servicesIndex[id]);
        });
        return services;
    }

    createServices(data) {
        if (Array.isArray(data)) {

            return data.map((service, index) => <ServicesSelect onChange={this.onServiceChange}
                                                                key={index} data={service}/>);
        }

        return <ServicesSelect onChange={this.onServiceChange} data={data}/>
    }

    prepareServicesData(services, selectedId) {

        const serviceCreator = new ServiceListCreator(...arguments);
        const list = serviceCreator.createList();
        const leafId = this.getLeafId(list);
        const leaf = serviceCreator.getServicesIndex()[leafId];

        return {list, leaf};
    }

    getLeafId(list) {
        const last = list[list.length - 1];

        return last.selectedId;
    }


    render() {


       const  hip = this.makeServicesHip(this.props.unselectedServices);
       const  servicesData = this.prepareServicesData(hip, this.state.selectedId);


        if (Object.keys(servicesData).length) {
            return <div>
                <div className="fr-profile__title">Select category and service to add</div>
                <Row className="fr-mt-ng">
                    <Col md={3}>
                        {this.createServices(servicesData.list[0])}
                    </Col>
                    <Col md={5}>
                        {this.createServices(servicesData.list.slice(1))}
                    </Col>
                    <Col md={4}>
                        <AddServiceForm addClickHandler={this.props.onServiceAdd} selectChangeHandler = {this.onIntervalChange} service={servicesData.leaf} duration={this.props.duration}
                                        processing={this.props.processing} />
                    </Col>
                </Row>

            </div>
        }

        return null;

    }
}
