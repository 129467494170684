/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.6.17
 * Time: 13.55
 */

import {
    PROFILE_PERSONAL_MYCARDS_ERROR,
    PROFILE_PERSONAL_MYCARDS_LOADED,
    PROFILE_PERSONAL_PAYMENTS_LOADED
} from "../../constants";
import AppDispatcher from "../../dispatcher/index";
import { xhrGetCustomer } from "../../xhr/payments/personal/get-customer-xhr";
import { xhrSetDefaultCard } from "../../xhr/payments/personal/set-default-card";
import { xhrRemoveCard } from "../../xhr/payments/personal/remove-card";
import { xhrAddPaymentSource } from "../../xhr/payments/personal/add-payment-source-xhr";
import { xhrGetCustomerCharges } from "../../xhr/payments/professional/payment-profile-xhr";

export function addNewPaymentSource(token) {
    if (typeof token === "object") token = token.id;

    return xhrAddPaymentSource(token).then(response => {

        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_MYCARDS_LOADED,
            payload: response
        });

        return response;

    }, response => {

        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_MYCARDS_ERROR,
            payload: response
        });

        return Promise.reject(response);

    });

}

export function loadMyCards() {


    return xhrGetCustomer().then(response => {

        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_MYCARDS_LOADED,
            payload: response
        });

    }, response => {

        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_MYCARDS_ERROR,
            payload: response
        });

        return Promise.reject(response);

    });
}

export function setDefaultCard(cardId) {

    return xhrSetDefaultCard(cardId).then(response => {

        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_MYCARDS_LOADED,
            payload: response
        });
    }, response => {

        console.warn("fail", response);

        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_MYCARDS_ERROR,
            payload: {
                general: ["Problem with default card selecting"]
            }
        });

        return Promise.reject(response);

    });
}

export function removeCard(cardId) {

    return xhrRemoveCard(cardId).then(response => {

        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_MYCARDS_LOADED,
            payload: response
        });
    }, response => {

        console.warn("fail", response);

        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_MYCARDS_ERROR,
            payload: {
                general: ["Problem with card removing"]
            }
        });

        return Promise.reject(response);

    });
}

export function loadPersonalPayments(params) {
    return xhrGetCustomerCharges(params).then(response => {
        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_PAYMENTS_LOADED,
            payload: response
        });
    }, response => {

        return Promise.reject(response);

    });
}

export function loadBilling(params) {

    return xhrGetCustomer().then(response => {

            AppDispatcher.dispatch({
                type: PROFILE_PERSONAL_MYCARDS_LOADED,
                payload: response
            });
        },
        response => {

            AppDispatcher.dispatch({
                type: PROFILE_PERSONAL_MYCARDS_ERROR,
                payload: response
            });
            return Promise.reject(response);
        })
    .then(response => {
        return xhrGetCustomerCharges(params).then(response => {
            AppDispatcher.dispatch({
                type: PROFILE_PERSONAL_PAYMENTS_LOADED,
                payload: response
            });
        }, response => {

            return Promise.reject(response);

        });
    });
}