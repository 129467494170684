/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.27.6
 * Time: 12:34
 */

import BaseStore from "./../base-store";
import {
    BOOK_CLIENT_FORM_RESET,
    CLIENT_FOUND,
    CLIENT_MISSING,
    LOAD_SALON_CLIENT_LIST_SUCCESS,
    SALON_CLIENT_LIST_RESET
} from "../../constants";

export default class BookAptClientListStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            clientList: [],
            listLoaded: false
        };

        this._registerActionSubscription((action) => {

            const { type, payload } = action;

            switch (type) {

                case CLIENT_FOUND:
                case BOOK_CLIENT_FORM_RESET:
                case SALON_CLIENT_LIST_RESET:
                    this.store.clientList = [];
                    this.store.listLoaded = false;

                    break;

                case LOAD_SALON_CLIENT_LIST_SUCCESS:
                    this.store.clientList = payload;
                    this.store.listLoaded = true;

                    break;

                case CLIENT_MISSING:
                    // this.resetSearchParams();

                    break;

                default:
                    return true;
            }

            this.emitChange();

        });
    }

    getClientList() {

        return this.store.clientList;
    }

    resetStore() {
        this.store = {
            clientList: [],
            listLoaded: false
        };
    }
}
