/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.1.18
 */

import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router';
import {professionalProfileArtistStore} from '../../../stores';
import {GALLERY2_PROFESSIONAL_PROFILE_ARTIST_ID} from '../../../frizo-shared-constants';
import {createGallery} from "../../gallery2/gallery-factory";
import SimpleGalleryFullscreenLook from "../../gallery2/fullscreen-items/simple-gallery-fullscreen-look";
import {xhrLoadSalonArtistGallery} from "../../../xhr/gallery/load-salon-artist-gallery-xhr";
import ProfessionalProfileArtistAvatar from "../../gallery2/items/professional-profile-artist-avatar";
import LoaderBar from '../../../elements/loader-bar';

export default class ProfessionalProfileArtist extends Component {
    constructor(props) {
        super(props);


        this.state = Object.assign({
            ready: false,
            params: {},
            salon: {},
        }, this.getStoreData());


        this.onGalleryStoreChange = this.onGalleryStoreChange.bind(this);

        this.gallery = this.createGallery();
        this.gallery.reset();
    }

    getStoreData() {
        let storeData = professionalProfileArtistStore.getStore();

        return {
            params: storeData.gallery.params
        }
    }

    componentDidMount() {
        professionalProfileArtistStore.addChangeListener(this.onGalleryStoreChange);

        this.onGalleryStoreChange();

    }

    componentWillUnmount() {
        professionalProfileArtistStore.removeChangeListener(this.onGalleryStoreChange);

    }

    createGallery() {

        return createGallery(GALLERY2_PROFESSIONAL_PROFILE_ARTIST_ID)
            .setGalleryStore(professionalProfileArtistStore)
            .setFavoritesEnabled(false)
            .setNoItemsText('Yet to add Artists')
            .setItemClass(ProfessionalProfileArtistAvatar)
            .setFullscreenClass(SimpleGalleryFullscreenLook)
            .setGalleryLoader(xhrLoadSalonArtistGallery);
    }


    onGalleryStoreChange() {
        let newState = Object.assign({}, this.getStoreData());

        if (newState.params.salonId)  {
            if (!this.state.ready)   {
                newState.ready = true;
                this.gallery.init();
            }

        }

        this.setState(newState);

    }

    render()    {

        if (!this.state.ready) {
            return <LoaderBar className="loader-msg-wrapper--full-height"/>;
        }

        return <div>
            <Row>
                <Col md={12}>
                    <Link className="fr-btn-add btn fr-btn" to="/my-salon/artists/add">Add artist</Link>
                </Col>
            </Row>
            {this.gallery.getGallery()}
        </div>;
    }
}

ProfessionalProfileArtist.requireData = ['userSalon'];