/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 13.04.2017
 * Time: 12:14
 */

import AppDispatcher from "../dispatcher";
import { DELETE_PERSONAL_APPOINTMENT, LOAD_PERSONAL_APPOINTMENTS } from "../constants";
import { xhrLoadPersonalAppointments } from "../xhr/personal-appointments-xhr";
import { xhrCancelAppointment } from "../xhr/appointments-xhr";

export const loadAppointments = (params) => {
    if (Object.keys(params).length) {
        xhrLoadPersonalAppointments(params).then(data => {
            AppDispatcher.dispatch({
                type: LOAD_PERSONAL_APPOINTMENTS,
                payload: {
                    requestData: data.requestData,
                    requestTotalCount: data.requestTotalCount,
                    requestParams: params
                }
            });
        });
    }
};

export const changeAptStatus = (aptId) => {
    xhrCancelAppointment(aptId).then(answer => {
        AppDispatcher.dispatch({
                type: DELETE_PERSONAL_APPOINTMENT,
                payload: aptId
            }
        );
    });
};
