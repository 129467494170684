import AppDispatcher from "./../dispatcher";
import {
    CLIENT_RECOMMENDATION_LINK_VALID,
    CLIENT_RECOMMENDATION_LINK_INVALID,
    PRO_RECOMMENDATION_LINK_VALID,
    PRO_RECOMMENDATION_LINK_INVALID
} from "./../constants";
import {
    getClientRecommendationDetailsInSecure,
    getProRecommendationDetailsInSecure
} from "./../xhr/recommendation-xhr";

export function checkClientRecommendationLink(hash) {
    getClientRecommendationDetailsInSecure(hash).then(data => {
        AppDispatcher.dispatch({
            type: CLIENT_RECOMMENDATION_LINK_VALID,
            payload: data
        });
    }, response => {

        AppDispatcher.dispatch({
            type: CLIENT_RECOMMENDATION_LINK_INVALID
        });

        return Promise.reject(response);
    });

}

export function checkProRecommendationLink(hash) {
    getProRecommendationDetailsInSecure(hash).then(data => {
        AppDispatcher.dispatch({
            type: PRO_RECOMMENDATION_LINK_VALID,
            payload: data
        });
    }, response => {

        AppDispatcher.dispatch({
            type: PRO_RECOMMENDATION_LINK_INVALID
        });

        return Promise.reject(response);
    });

}
