import { PRO_RECOMMENDATION_LINK_VALID, PRO_RECOMMENDATION_LINK_INVALID } from "../../constants";
import BaseStore from "../base-store";

class ProRecommendationStore extends BaseStore {
    constructor(...args) {
        super(...args);

        this.store = {
            linkValid: null,
            linkExist: null
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;
            switch (type) {
                case PRO_RECOMMENDATION_LINK_VALID:

                    this.store.linkValid = payload.isValid;
                    this.store.linkExist = true;

                    break;

                case PRO_RECOMMENDATION_LINK_INVALID:
                    this.store.linkValid = false;
                    this.store.linkExist = false;

                    break;


                default:
                    return true;
            }
            this.emitChange();
        });
    }

}

export default ProRecommendationStore;