/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 21.03.2018
 * Time: 5.58
 */

import { hotfixVersion, majorVersion, minorVersion } from "./../version";
import { xhrEvents } from "./api-client-2/events";
import { CUSTOM_HEADER_U6_PLATFORM, CUSTOM_HEADER_U6_VERSION } from "../frizo-shared-constants";

export function getVersion() {
    return [majorVersion, minorVersion, hotfixVersion].join(".");
}

xhrEvents.requestIssued.add((request: Request) => {
    request.header(CUSTOM_HEADER_U6_PLATFORM, "w");
    request.header(CUSTOM_HEADER_U6_VERSION, getVersion());
});
