/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.3.17
 * Time: 7.51
 */

import React, {Component} from 'react';

import {openTab, updateSchedule} from './../../../ac/profile/professional/wh';
import {persistForArtist, discard, selectArtist} from './../../../ac/profile/professional/wh';
import DropdownWrapper from './../../../elements/dropdown-wrapper';
import { sessionStore, profileWHStore, profileProfessionalStore} from './../../../stores';
import {USER_TYPE_FREELANCER, USER_TYPE_MASTER} from './../../../frizo-shared-constants';
import {Row, Col} from 'react-bootstrap';
import ScheduleDayRow from './wh/schedule-day-row';
import {Button} from 'react-bootstrap';
import Violation from './../../helper-components/violation';
import {Link} from 'react-router';
import LoaderBar from '../../../elements/loader-bar';

export default class ProfessionalProfileWH extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            ready: false,
            selectedArtistId: null,
            addresses: [],
            addressesPrinted: {},
            btnKey: 'a',
            artists: [],
            selectedArtist: {},
            userType: sessionStore.getUserType(),
            violations: {
                common: [],
                byArtist: {}
            },
            showViolations: false

        }, this.getStoreData());

        this.handleProfileStoreChange = this.handleProfileStoreChange.bind(this);
        this.handleWHStoreChange = this.handleWHStoreChange.bind(this);

        this.getSelectedArtist = this.getSelectedArtist.bind(this);
        this.onArtistChange = this.onArtistChange.bind(this);
        this.onScheduleChange = this.onScheduleChange.bind(this);

        this.nextGroup = this.nextGroup.bind(this);
        this.saveOne = this.saveOne.bind(this);

        openTab();
    }


    printAddress(address) {
        return [
            address.address,
            address.city,
            address.state,
            address.zip
        ].filter(value => value && value.length > 0).join(', ');
    }

    handleProfileStoreChange() {
        let whStore = profileProfessionalStore.getWHTab();

        this.setState({
            violations: whStore.violations,
            showViolations: whStore.showViolations,
        });
    }

    getStoreData()  {
        return profileWHStore.getStore();
    }

    handleWHStoreChange() {
        this.setState(this.getStoreData());
    }


    componentDidMount() {

        profileProfessionalStore.addChangeListener(this.handleProfileStoreChange);
        profileWHStore.addChangeListener(this.handleWHStoreChange);

        Promise.all([
            profileWHStore.getReadyPromise(),
            sessionStore.getReadyPromise()
        ]).then(store => {

            this.setState({
                ready: true
            })
        })
    }

    componentWillUnmount() {

        profileProfessionalStore.removeChangeListener(this.handleProfileStoreChange);
        profileWHStore.removeChangeListener(this.handleWHStoreChange);
    }


    onArtistChange(id) {

        discard();

        selectArtist(Number(id));
    }

    displayArtistsSelector() {
        return !(sessionStore.getUserType() === USER_TYPE_FREELANCER || sessionStore.getUserType() === USER_TYPE_MASTER);
    }

    getSelectedArtist() {
        return this.state.selectedArtist;
    }

    onScheduleChange(day, patch) {
        updateSchedule(this.state.selectedArtistId, day, patch);
    }


    nextGroup() {
        this.setState({
            btnKey: 'b'
        });
    }

    /**
     * required for virtual tab
     */
    onSelect() {
    }

    saveOne() {
        persistForArtist(this.state.selectedArtistId, this.state.salonId)
    }

    pageIsReadOnly() {
        return this.state.userType === USER_TYPE_MASTER
    }

    render() {

        if (!this.state.ready) {
            return <LoaderBar className="loader-msg-wrapper--full-height"/>;
        }

        if (!this.state.artists || this.state.artists.length === 0) {
            return <div><Link to="/my-salon/artists/add">Add artist</Link> before</div>;
        }

        let isReadOnly = this.pageIsReadOnly();

        let artistSelector;
        if (this.displayArtistsSelector()) {

            let list = this.state.artists.map(artist => {
                return {id: artist.id, name: `${artist.firstName} ${artist.lastName}`};
            });

            artistSelector = <DropdownWrapper
                placeholder="Select artist"
                showPlaceholder = {true}
                selected={this.state.selectedArtistId}
                list={list}
                onChange={this.onArtistChange}
            />;
        }


        let selectedArtist = this.getSelectedArtist();

        let rows = selectedArtist.schedule.map((whDay, key) => {

            return <ScheduleDayRow addresses={this.state.addressesPrinted}
                                   onChange={this.onScheduleChange.bind(this, whDay.day)}
                                   key={key}
                                   readOnly={isReadOnly}
                                   {...whDay} />
        });

        let violations = [];
        if (this.state.showViolations) {
            violations = this.state.artists.map(artist => {
                return <Violation
                    key={artist.id}
                    violationSet={this.state.violations.byArtist[artist.id]}
                    visible={Boolean(this.state.violations.byArtist[artist.id])}
                >{artist.firstName} {artist.lastName}: </Violation>;
            });
        }

        return <Row>
            {!isReadOnly &&
            <Row>
                <div className="col-md-4 fr-wh-artist__select">
                    {artistSelector}
                </div>
                <div className="col-md-4">
                    <img src="" alt=""/>
                </div>
            </Row>}
            <div className="">
                {rows}
            </div>

            { !isReadOnly &&

            (<Row className="fr-mt">
                <Col md={6}>
                    <Button className="fr-btn fr-big-button pull-right fr-mr" onClick={this.saveOne}>Save</Button>
                </Col>
                <Col>
                    { this.state.violations && <Violation violationSet={this.state.violations.common} visible={this.state.showViolations}/> }
                    {violations}
                </Col>
            </Row>)
            }
        </Row>;
    }
}
