/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.04.2017
 * Time: 18:25
 */
import React, { Component } from "react";
import { Form, FormControl, Button, Glyphicon } from "react-bootstrap";
import PropTypes from "prop-types";

export default class AppointmentsFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: ""
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onReset = this.onReset.bind(this);
    }

    handleFilterChange(e) {
        this.setState({
            filter: e.target.value
        });

    }

    onReset(e) {
        e.preventDefault();
        this.setState({
            filter: ""
        });
        this.props.searchString("");
    }

    searchApts(ev) {
        ev.preventDefault();
        this.props.searchString(this.state.filter);
    }

    render() {
        return (
            <div className="">
                <Form inline className="fr-appointments__filter" onSubmit={this.searchApts.bind(this)}>
                    <div className="input-group">
                        <FormControl className="fr-rounded" componentClass="input"
                                     placeholder={this.props.placeholder}
                                     value={this.state.filter} onChange={this.handleFilterChange}/>

                        <span className="input-group-btn">
                              {this.state.filter !== "" &&
                              <Button onClick={this.onReset} type="button"
                                      className="fr-apt-btn-reset fr-btn pull-right">
                                  <span className="glyphicon glyphicon-remove"></span></Button>}
                            <Button type="submit" className="fr-pr0 btn-link">
                            <span className="fr-glyphicon" type="submit"><Glyphicon
                                glyph="search"/></span>
                            </Button>
                        </span>
                    </div>
                </Form>

            </div>
        );
    }
}
AppointmentsFilter.propTypes = {
    placeholder: PropTypes.string
};