/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 04.04.2017
 * Time: 16:09
 */

import React, { Component } from 'react';
import { personalPaymentOrderStore } from '../../../../stores/index';
import AppointmentsFilter from '../../../appointments/appointments-filter';
import PaymentOrderList from './elements/payment-order-list';
import { filterOrders, loadPendingPaymentOrders } from '../../../../ac/personal-payment-orders-ac';

export default class PaymentOrdersPending extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({

        }, this.getStoreData());

        this.handleSearchStringUpdate = this.handleSearchStringUpdate.bind(this);
        this.storeChange = this.storeChange.bind(this);
    }


    componentDidMount() {

        loadPendingPaymentOrders();

        personalPaymentOrderStore.addChangeListener(this.storeChange);
    }

    componentWillUnmount() {
        personalPaymentOrderStore.removeChangeListener(this.storeChange);
    }

    getStoreData()  {
        return personalPaymentOrderStore.getStore();
    }
    storeChange() {
        this.setState(this.getStoreData());
    }

    handleSearchStringUpdate(filter) {
        console.log('handleSearchStringUpdate', filter);
        filterOrders(filter);
    }



    render() {

        return (
            <div>
                <AppointmentsFilter searchString={this.handleSearchStringUpdate}
                                    placeholder="Search for Salon"/>

                <PaymentOrderList elements={this.state.filteredOrders} />

            </div>);
    }
}
