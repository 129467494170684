/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 16.36
 */

import React from "react";

import PropTypes from "prop-types";
import { IMAGE_SIZE_LARGE, IMAGE_SIZE_MEDIUM, IMAGE_SIZE_SMALL } from "../frizo-shared-constants";
import { imageUrl } from "../helper/image-url";


export function Img(props) {

    let restProps = Object.assign({}, props);
    delete restProps.src;
    delete restProps.size;
    delete restProps.blockInflection;
    delete restProps.description;

    restProps["data-size"] = props.size;

    let src = props.src;
    if (!props.blockInflection && src.indexOf("data:") !== 0) {
        src = imageUrl(props.src, props.size);
    }

    return <img src={src} {...restProps} alt={props.description} title={props.description}/>;
}

Img.propTypes = {
    src: PropTypes.string.isRequired,
    size: PropTypes.oneOf([IMAGE_SIZE_SMALL, IMAGE_SIZE_MEDIUM, IMAGE_SIZE_LARGE]),
    description: PropTypes.string
};

Img.defaultProps = {
    size: IMAGE_SIZE_MEDIUM,
    blockInflection: false,
    description: ""
};
