/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 7.8.17
 */

import { NOTIFY_FAIL, NOTIFY_SUCCESS } from "../constants";
import BaseStore from "./base-store";

import notificationSystem from "./../service/notification-system";

export default class NotificationsStore extends BaseStore {


    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;
            switch (type) {
                case NOTIFY_SUCCESS:
                    notificationSystem.logSuccess(payload.message, payload.options);

                    break;

                case NOTIFY_FAIL:
                    notificationSystem.logError(payload.message, payload.options);

                    break;

                default:
                    return true;

            }
        });
    }
}
