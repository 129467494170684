/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.9.17
 */

import { HRC_FIELD_GENERIC, HRC_REDIRECT_DISABLED, HRC_REDIRECT_GUESS } from "./../frizo-shared-constants";

import { browserHistory } from "react-router";

function guessRedirectURL(route, paramName) {
    let findToken = "(/:" + paramName + ")";

    return route.replace(findToken, "");
}

export function getReferralCodeChecker(redirectUrl = HRC_REDIRECT_GUESS, paramName = "referralCode", refCodeField = HRC_FIELD_GENERIC) {
    return (prev, replace, callback) => {

        let refParam = prev.params[paramName];
        if (refParam) {
            localStorage.setItem(refCodeField, refParam);

            switch (true) {
                case typeof (redirectUrl) === "function":
                    redirectUrl = redirectUrl(prev, replace, callback);
                    break;

                case redirectUrl === HRC_REDIRECT_DISABLED:
                    return callback();

                case redirectUrl === HRC_REDIRECT_GUESS:
                    let route = prev.routes[prev.routes.length - 1].path;
                    redirectUrl = guessRedirectURL(route, paramName);
                    break;

                default:
                    break;

            }

            return browserHistory.replace(redirectUrl);

        }
        else {
            return callback();
        }
    };
}
