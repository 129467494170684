/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 30.6.17
 * Time: 19.03
 */

import React, {Component} from 'react';
import {sessionStore} from '../../../stores';

import ProfessionalProfileArtistServices from './professional-profile-services-tab/professional-profile-artist-services';
import ProfessionalProfileServices from './professional-profile-services-tab/professional-profile-services';
import {loadProfileServicesInitial} from "../../../ac/profile-services/init-load";
import LoaderBar from '../../../elements/loader-bar';

export default class ProfessionalProfileServiceTab extends Component {

    constructor(...args) {
        super(...args);

        this.state = {
            ready: false,
            artistId: null,
            isBusinessAccount: null
        };
    }

    componentDidMount() {

        sessionStore.getReadyPromise().then(store => {
            loadProfileServicesInitial(store.session.salon.id);

            this.setState({
                ready: true,
                artistId: sessionStore.getUserId(),
                isBusinessAccount: sessionStore.isBusinessAccount()
            });
        });

    }

    render()    {
        if (!this.state.ready) return  <LoaderBar className="loader-msg-wrapper--full-height"/>;

        if (this.state.isBusinessAccount)    {
            return <ProfessionalProfileServices />;
        }
        else {
            return <ProfessionalProfileArtistServices
                artistId = {this.state.artistId}
            />
        }


    }
}

ProfessionalProfileServiceTab.requireData = ['userSalon'];