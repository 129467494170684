/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 24.10.16.
 */

import React, { Component } from "react";
import { OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import * as tz from "moment-timezone"; //eslint-disable-line no-unused-vars
import idGenerator from "../../helper/id-generator-component";
import PopoverWrapper from "../popover/popover-wrapper-component";
import Time from "../helper-components/time-component";
import {
    JOURNAL_ELEMENT_APPOINTMENT,
    JOURNAL_ELEMENT_BLOCK
} from "../../frizo-shared-constants";

import printDate from "../../helper/print-date";
import PropTypes from "prop-types";

import { ScheduleElement } from "./schedule-element";
import { DurationShadowElement } from "./duration-shadow-element";
import { Highlight } from "./highlight-component";
import { TableHead } from "./table-head";

import {
    SLOT_STATE_DEFAULT,
    SLOT_STATE_WH_SET,
    SLOT_STATE_PAST,

    SECONDS_IN_15_MINUTES

} from "../../frizo-shared-constants";

import { calculateScheduleElementStatus } from "./../../service/schedule/element-status-calculator";

import notificationSystem from "./../../service/notification-system";

import sprintf from "./../../helper/sprintf";
import ConfirmationBadge from "./../../elements/confirmation-badge";

const SECONDS_IN_DAY = 24 * 60 * 60;

export default class Scheduler extends Component {


    constructor(props) {
        super(props);

        this.state = {
            tdHeight: null,
            timestampPopover: {
                data: ""
            },
            hightlightDisplay: false
        };

        this.mainTable = undefined; //filled after render


        this.clickOnField = this.clickOnField.bind(this);
        this.clickOnBlock = this.clickOnBlock.bind(this);

        this.onResize = this.onResize.bind(this);
        this.doResize = this.doResize.bind(this);
        this.startResize = this.startResize.bind(this);

        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDrop = this.onDrop.bind(this);

        this.calcTableSize = this.calcTableSize.bind(this);
        this.timeColumnMouseCreep = this.timeColumnMouseCreep.bind(this);
        this.handleDialog = this.handleDialog.bind(this);

        this.overlayTriggerRef = this.overlayTriggerRef.bind(this);

        this.createScheduleElement = this.createScheduleElement.bind(this);

        this.onScrollHandler = this.onScrollHandler.bind(this);

        this.num = 0;

        this.dropDefferred = false;
        this.dragTarget = null;

        window.scheduler = this;

        this.dev = {
            getArtist: id => this.props.members.find(member => member.id === id),

            getArtistAddress: (id, day = "selected") => {
                return this.getMemberAddress(this.dev.getArtist(id), day);
            },

            getArtistTime: (id, time) => {
                let tz = this.dev.getArtistAddress(id).timezone;
                //console.log('tz', tz);
                return moment.tz(time * 1000, tz).format("YYYY-MM-DD HH:mm");
            }

        };
    }


    clickOnField(event) {
        event.persist();
        console.log("clickOnField", event);

        if (this.props.clickOnField) {
            this.props.clickOnField(event);
        }

    }

    clickOnBlock(event) {
        console.log("clickOnBlock", arguments);

        if (this.props.clickOnBlock) {
            this.props.clickOnBlock(event);
        }
    }

    getNextElementInColumn(dataElementId, artistId, shift = 1) {
        let startStepNumber = this.elementIdStepNumberIndex[dataElementId];

        return this.getNextElementAfterStepInColumn(startStepNumber, artistId, shift);
    }

    getNextElementAfterStepInColumn(startStepNumber, artistId, shift = 1, skipBlocks = []) {
        let elementsInColumn = this.dataMemberIndex[artistId];

        if (!elementsInColumn) return false;

        let stepsInColumn = Object.keys(elementsInColumn);

        let nextStepIndex;

        if (elementsInColumn[startStepNumber] !== undefined) {
            let elementStepNumberPosition = stepsInColumn.indexOf(String(startStepNumber));
            nextStepIndex = elementStepNumberPosition + shift;
        }
        else {
            nextStepIndex = stepsInColumn.length + shift;

            for (let i = 0; i < stepsInColumn.length; i++) {
                let stepI = stepsInColumn[i];

                if (stepI > startStepNumber) {
                    nextStepIndex = i + shift;
                    if (shift > 0) nextStepIndex = nextStepIndex - 1;

                    break;
                }
            }

        }

        if (nextStepIndex >= stepsInColumn.length || nextStepIndex < 0) {
            //last element
            //limit to rBound

            return false;
        }
        else {
            let nextStep = stepsInColumn[nextStepIndex];
            let nextElement = elementsInColumn[nextStep];


            if (skipBlocks.indexOf(nextElement.id) !== -1) {
                return this.getNextElementAfterStepInColumn(nextStep, artistId, shift / Math.abs(shift), skipBlocks);
            }

            return nextElement;
        }
    }

    onResize(id, height, element, originEvent) {
        let dataElement = this.dataIndex[id];

        let heightInSeconds = Math.round(height / this.state.tdHeight) * Number(this.props.step);

        let endTimestamp = Number(dataElement.startTimestamp) + heightInSeconds;

        let limitTime;
        let nextElement = this.getNextElementInColumn(id, dataElement.artistId);
        if (nextElement === false) {
            limitTime = this.memberMidnightIndex[dataElement.artistId] + this.rBound;
        }
        else {
            limitTime = nextElement.startTimestamp;
        }

        if (endTimestamp > limitTime) {
            endTimestamp = limitTime;
        }

        if (typeof this.props.elementUpdateCallback === "function") {
            this.props.elementUpdateCallback([[dataElement, {
                endTimestamp: endTimestamp
            }]]);
        }

        this.mainTable.classList.remove("in-resize");

        let shadow = this.getDurationShadowElement(id);
        if (shadow) {
            shadow.style.display = "none";
        }

    }

    doResize(height, event) {
        let result = height / this.state.tdHeight;

        let number = Math.round(result);

        return number * this.state.tdHeight;
    }

    startResize(id, cnt, e) {
        this.mainTable.classList.add("in-resize");

        let shadow = this.getDurationShadowElement(id);
        if (shadow) {
            shadow.style.display = "block";
        }
    }

    getDurationShadowElement(id) {
        return this.mainTable.querySelector('.duration-shadow-element[data-id="' + id + '"]');
    }

    registerHightlight(node) {
        if (node) {
            this.highlightNodes.push(node);
        }
    }


    onDragStart(event) {
        event.dataTransfer.effectAllowed = "move";
        this.dropDefferred = false;
        this.dragTarget = event.target;

        let dataId = event.target.getAttribute("data-id");

        event.dataTransfer.setData("id", dataId);

        this.dragIdStore = event.target.getAttribute("data-id");

        event.persist();

        this.dragStarted = true;

        window.setTimeout((function() {

            this.highlightNodes.forEach(function(node) {
                node.show();
            });

            event.target.style.display = "none";
        }).bind(this), 1);

    }


    onDragEnd(event) {
        this.dragTarget = event.target;
        if (this.dropDefferred) return false;

        this.doDragEnd(event.target);

    }

    doDragEnd() {
        this.dragStarted = false;
        this.clearDndCursorShadow();
        this.dragTarget.style.display = "block";

        this.clearDndHighlight();
    }

    defferDrop(promise) {
        this.dropDefferred = true;

        promise.then(result => {
            this.doDragEnd();
        });
    }

    onDragOver(event) {
        event.preventDefault();
    }

    clearDndCursorShadow() {
        if (this.dndHighlight) {
            this.dndHighlight.classList.remove("schedule-td-dnd-shadow-good");
            this.dndHighlight.classList.remove("schedule-td-dnd-shadow-wrong");
            this.dndHighlight.classList.remove("schedule-td-dnd-shadow");
        }
    }

    clearDndHighlight() {
        this.highlightNodes.forEach(function(node) {
            node.hide();
        });
    }

    timeColumnMouseCreep(timestamp) {
        this.setState({
            timestampPopover: {
                data: timestamp
            }
        });
    }

    onDragEnter(event) {
        if (!this.dragStarted) return false;

        this.clearDndCursorShadow();

        let target = event.target;

        if (target.tagName !== "TD") {
            target = target.parentNode;
        }

        target.classList.add("schedule-td-dnd-shadow");
        event.preventDefault();

        this.dndHighlight = target;
    }

    /**
     * Proxy for outer function
     * result may be cached between render times to reduce calculations
     * @param element
     * @param nextElement
     * @returns {*}
     */
    getPaddingForElement(element, nextElement) {
        return this.props.getPaddingForElement(element, nextElement);
    }

    checkIntersection(element1, element2) {

        //not in one column
        if (Number(element1.artistId) !== Number(element2.artistId)) return false;


        if (element1.startTimestamp < element2.startTimestamp) {
            //element1 before element2

            let padding = this.getPaddingForElement(element1, element2);

            if (element1.endTimestamp + padding > element2.startTimestamp) {
                //overlap
                return true;
            }
        }
        else {

            let padding = this.getPaddingForElement(element2, element1);

            if (element2.endTimestamp + padding > element1.startTimestamp) {
                //overlap
                return true;
            }
        }

        return false;
    }

    onDrop(event) {
        if (!this.dragStarted) return false;
        this.dragStarted = false;

        this.clearDndCursorShadow();
        this.clearDndHighlight();

        event.persist();

        let id = event.dataTransfer.getData("id");
        if (!id) {
            return false;
        }

        let target = event.target;
        if (target.tagName !== "TD") {
            target = target.parentNode;
        }

        let newMember = target.getAttribute("data-member");
        let dataElement = this.dataIndex[id];


        console.log("drop event", target, id);

        if (target.classList.contains("schedule-td-past")) {
            return false;
        }


        let newTimeFromMidnight = Number(target.getAttribute("data-start-timestamp"));
        let newStepIndex = Number(target.getAttribute("data-step-index"));
        let startOfTheDayTimestamp = this.memberMidnightIndex[newMember];
        let newTimeTimestamp = startOfTheDayTimestamp + newTimeFromMidnight;

        let duration = dataElement.endTimestamp - dataElement.startTimestamp;

        let patch = {
            startTimestamp: newTimeTimestamp,
            endTimestamp: newTimeTimestamp + duration,
            artistId: newMember

            //Remove due to normalization
            // member: this.memberIndex[newMember]

        };

        let tempDataElement = Object.assign({}, dataElement, patch);
        console.log("newStepIndex", newStepIndex);

        let prevElement = this.getNextElementAfterStepInColumn(newStepIndex, newMember, -1, [dataElement.id]);
        let nextElement = this.getNextElementAfterStepInColumn(newStepIndex, newMember, 1, [dataElement.id]);
        console.log("prevElement", prevElement, nextElement);

        let pass1 = !(prevElement && this.checkIntersection(tempDataElement, prevElement));
        let pass2 = !(nextElement && this.checkIntersection(tempDataElement, nextElement));

        let collection = [[dataElement, patch]];

        let commit = () => {

            if (typeof this.props.elementUpdateCallback === "function") {
                this.props.elementUpdateCallback(collection);
            }
        };

        console.log("dataElement", dataElement);


        if (!pass1) {

            return false; //forbid to press up previous block

            /**
             * Uncomment this to enable previous block reducing
             *
             *
             if (!confirm('Сократить предыдущий блок?'))  {
                return false;
            }

             patches.push([prevElement, {
                endTime: tempDataElement.startTime
            }]);

             */
        }

        if (!pass2) {

            let tempDataElement2 = Object.assign({}, tempDataElement, {
                endTimestamp: nextElement.startTimestamp
            });

            let padding = this.getPaddingForElement(tempDataElement2, nextElement);
            let calculatedendTimestamp = nextElement.startTimestamp - padding;

            /*
             Removed due to review duration padding for one client apt
             if (calculatedendTimestamp - tempDataElement.startTimestamp <= this.props.step) {
             alert('С учетом duration отступа элемент не помещается в выбранный слот и не может быть сокращён');
             return false;
             }
             */


            let s;
            if (dataElement.type === JOURNAL_ELEMENT_APPOINTMENT) {
                s = "Reduce processing time to move the appointment?";
            }
            else {
                s = "Reduce block duration to avoid time conflict?";
            }

            let promise = notificationSystem.confirm(s, "Yes", "No").then(result => {
                if (result.buttonClicked === "ok") {

                    patch.endTimestamp = calculatedendTimestamp;
                    commit();
                }

                return result;
            }, result => Promise.resolve(result));

            this.defferDrop(promise);

            return true;

        }
        else {

            let nextMidnight = this.memberMidnightIndex[tempDataElement.artistId] + SECONDS_IN_DAY;
            console.log("nextMidnight", nextMidnight, tempDataElement.endTimestamp);
            if (tempDataElement.endTimestamp > nextMidnight) {

                let s;
                if (dataElement.type === JOURNAL_ELEMENT_APPOINTMENT) {
                    s = "Appointments may not extend to the next day. <br /> Reduce processing time to fit to a single day?";
                }
                else {
                    s = "Block may not extend to the next day.  <br /> Reduce block duration to fit to a single day?";
                }

                let promise = notificationSystem.confirm(s, "Yes", "No").then(result => {
                    if (result.buttonClicked === "ok") {

                        patch.endTimestamp = nextMidnight;
                        commit();

                    }

                    return result;
                }, result => Promise.resolve(result));

                this.defferDrop(promise);

                return true;
            }

        }


        commit();
    }

    getAppointmentElementNode(elementId) {
        let selector = sprintf('.schedule-element-appointment[data-id="%s"]', elementId);
        return document.querySelector(selector);
    }

    handleDialog(e) {
        e.persist();
        let action = e.target.getAttribute("data-btn-action");
        console.log("handleDialog",
            e,
            action,
            this.slotTriggeredMember,
            this.slotTriggeredStepIndex,
            this.slotTriggeredStartTime);

    }

    overlayTriggerRef(overlayTrigger) {
        this.overlayTrigger = overlayTrigger;
    }

    getSelectedDay() {
        return moment.utc(this.props.selectedDate).day();
    }

    getMemberAddress(member, day = "selected") {

        if (day === "selected") day = this.getSelectedDay();

        return member.addressSchedule[day];
    }

    /**
     * Define time boundaries of table based on WH
     * FIXME: Add to calculation selected date
     */
    boundaryDefine() {

        let selectedDay = this.getSelectedDay();

        //define absolute borders of the provided data range
        let lBoundAbs, rBoundAbs;
        this.props.data.forEach((dataElement) => {

            if (lBoundAbs === undefined || dataElement.startTimestamp < lBoundAbs) lBoundAbs = dataElement.startTimestamp;
            if (rBoundAbs === undefined || dataElement.endTimestamp > rBoundAbs) rBoundAbs = dataElement.endTimestamp;
        });

        //from 10 to 20 o'clock
        let lBound = 10 * 60 * 60, rBound = 20 * 60 * 60;

        let startPointTimestamp, startPointDate;

        this.props.members.forEach((function(member) {
            if (member.availableSchedule[selectedDay]) {
                member.availableSchedule[selectedDay].forEach(function(range) {

                    if (lBound === undefined || range[0] < lBound) lBound = range[0];
                    if (rBound === undefined || range[1] > rBound) rBound = range[1];
                });
            }

            let localMoment;
            if (lBoundAbs !== undefined) {
                localMoment = moment.tz(lBoundAbs * 1000, this.getMemberAddress(member).timezone).startOf("day");
            }
            else {
                localMoment = moment.tz(this.props.selectedDate, this.getMemberAddress(member).timezone).startOf("day");
            }
            let localMomentTimestamp = localMoment.unix();

            this.memberIndex[member.id] = member;
            this.memberMidnightIndex[member.id] = localMomentTimestamp;
            this.memberOffsetIndex[member.id] = localMoment.format("Z");

            if (startPointTimestamp === undefined || localMomentTimestamp < startPointTimestamp) {
                startPointTimestamp = localMomentTimestamp;
                startPointDate = localMoment.format("Y-MM-DD");
            }

        }).bind(this));


        this.props.data.forEach((dataElement) => {

            let memberMidnight = this.memberMidnightIndex[dataElement.artistId];
            let startTimestampRel = dataElement.startTimestamp - memberMidnight;
            let endTimestampRel = dataElement.endTimestamp - memberMidnight;

            if (lBound === undefined || startTimestampRel < lBound) lBound = startTimestampRel;
            if (rBound === undefined || endTimestampRel > rBound) rBound = endTimestampRel;
        });


        if (lBound === undefined || rBound === undefined) {
            console.warn("Nothing to show");
            this.lBound = 0;
            this.rBound = 0;
        }
        else {

            this.lBound = lBound;
            this.rBound = rBound;
        }

        this.lBoundAbs = lBoundAbs;
        this.rBoundAbs = rBoundAbs;

        this.startPointTimestamp = startPointTimestamp;
        this.startPointDate = startPointDate;

    }

    initIndexes() {

        /**
         * Member index
         * @type {{}}
         */
        this.memberIndex = {};

        /**
         * Apts by salonClient
         * @type {{}}
         */
        this.salonClientIndex = {};

        /**
         * Data element by id index
         *
         * @type {{}}
         */
        this.dataIndex = {};

        /**
         * Data elements processing in steps index
         * Update after render
         * {dataElementId: processingInSteps}
         * @type {{}}
         */
        this.processingInStepsIndex = {};


        /**
         * Data elements duration in steps index
         * Update after render
         * {dataElementId: durationInSteps}
         * @type {{}}
         */
        this.durationInStepsIndex = {};

        /**
         * Shadow of element duration
         * {
         *  artistId: {
         *      stepNumber: dataElement
         *  }
         * }
         * @type {{}}
         */
        this.durationShadowIndex = {};

        /**
         * Data elements indexed in nested hash
         * {
         *   artistId: {
         *      stepNumber: dataElement
         *      }
         * }
         * @type {{}}
         */
        this.dataMemberIndex = {};

        /**
         * Data elements indexed in nested hash
         * {
         *   stepNumber: {
         *      artistId: dataElement
         *      }
         * }
         * @type {{}}
         */
        this.dataStepIndex = {};


        /**
         * {
         *   elementId: startStepNumber
         * }
         * @type {{}}
         */
        this.elementIdStepNumberIndex = {};


        /**
         * {
         *   artistId: timestamp if 12AM
         * }
         * @type {{}}
         */
        this.memberMidnightIndex = {};

        /**
         * {
         *    artistId: offset
         * }
         * @type {{}}
         */
        this.memberOffsetIndex = {};
    }


    createScheduleElement(dataElement, extraProps, tz) {

        let body;
        let elementConfig = this.props.types[dataElement.type];
        let extraClasses = [];

        switch (dataElement.type) {

            case JOURNAL_ELEMENT_BLOCK:
                body = <div>
                    <div>
                        Block
                    </div>
                </div>;

                break;

            case JOURNAL_ELEMENT_APPOINTMENT:

                if (dataElement.processing === SECONDS_IN_15_MINUTES) {
                    extraClasses.push("schedule-element-layout-minimal");
                }


                body = <div id={`apt${dataElement.id}`}>
                    <div className="schedule-element-appointment-info">
                        <b>{dataElement.salonClient.firstName}&nbsp;
                            {dataElement.salonClient.lastName}</b>
                        <ConfirmationBadge confirmation={dataElement.confirmation} status={extraProps.status}/>
                    </div>
                    <div className="schedule-element-appointment-info schedule-element-service-name">
                        {dataElement.service.title}
                    </div>
                </div>;

                break;

            default:
                throw new Error("Unknown element type");

        }


        return <ScheduleElement
            uniqueId={dataElement.id}
            dirty={dataElement.dirty}
            config={elementConfig}
            onClick={this.clickOnBlock}
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
            extraClasses={extraClasses}

            doResize={this.doResize}
            onResize={this.onResize}
            startResize={this.startResize}

            {...extraProps}
        >
            {body}
        </ScheduleElement>;


    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScrollHandler);
    }

    componentDidUpdate() {
        this.stickyNative();

        if (this.props.scrollToApt) {

            this.props.scrollToApt();
        }
    }

    render() {

        let self = this;

        self.initIndexes();
        self.boundaryDefine();
        self.highlightNodes = [];

        let timeIterator = self.lBound;
        let cols;
        let rows = [],
            timerows = [],
            timecols = [];

        //save for later use
        let props = self.props;

        //go trough shedule elements (apts and blocks)
        self.props.data.forEach(function(element) {
            let memberMidnightOffset = self.memberMidnightIndex[element.artistId];
            let totalOffset = memberMidnightOffset + self.lBound;

            let startTimestamp = element.startTimestamp - totalOffset;

            let startStepNumber = Math.round(startTimestamp / self.props.step);
            let processingInSteps = Math.ceil((element.endTimestamp - element.startTimestamp) / self.props.step);
            let durationInSteps = Math.ceil(element.duration / self.props.step);

            if (self.dataStepIndex[startStepNumber] === undefined) self.dataStepIndex[startStepNumber] = {};
            self.dataStepIndex[startStepNumber][element.artistId] = element;

            self.elementIdStepNumberIndex[element.id] = startStepNumber;


            if (self.dataMemberIndex[element.artistId] === undefined) self.dataMemberIndex[element.artistId] = {};
            self.dataMemberIndex[element.artistId][startStepNumber] = element;

            self.dataIndex[element.id] = element;
            self.processingInStepsIndex[element.id] = processingInSteps;
            self.durationInStepsIndex[element.id] = durationInSteps;

            if (element.type === JOURNAL_ELEMENT_APPOINTMENT) {
                //init salon client index for salon client
                if (self.salonClientIndex[element.salonClientId] === undefined) self.salonClientIndex[element.salonClientId] = [];
                self.salonClientIndex[element.salonClientId].push(element);

                //init duration shadow index for member
                if (self.durationShadowIndex[element.artistId] === undefined) self.durationShadowIndex[element.artistId] = {};

                for (let stepI = startStepNumber; stepI < startStepNumber + durationInSteps; stepI++) {
                    self.durationShadowIndex[element.artistId][stepI] = true;
                }
            }


        });

        let dialogPopover;
        if (props.popoverElement) {
            dialogPopover = props.popoverElement;
        }
        else {
            dialogPopover = <PopoverWrapper ref={(node) => {
                self.popover = node;
            }} id="scheduler-popover"/>;
        }

        let stepNumberIterator = 0;

        do {

            let index = self.dataStepIndex[stepNumberIterator];
            cols = [];
            timecols = [];

            //time column
            timecols.push(<td
                className="noselect"
                key={idGenerator("col", timeIterator, "time")}
                data-time-from-midnight={timeIterator}
            ><Time startPointDate={self.startPointDate} timeFromStartPoint={timeIterator}/></td>);

            this.props.members.forEach(member => { //eslint-disable-line no-loop-func
                let _timeIterator = timeIterator;
                let _stepNumberIterator = stepNumberIterator;

                let memberMidnight = self.memberMidnightIndex[member.id];

                let tdUniqueKey = idGenerator("col", timeIterator, member.id);
                let tdElement;

                let slotState = SLOT_STATE_DEFAULT;

                let localNow = moment.tz(this.getMemberAddress(member).timezone).unix();

                let slotTimestamp = timeIterator + memberMidnight;
                if (slotTimestamp < localNow) {
                    slotState = SLOT_STATE_PAST;
                }
                else {
                    let selectedDay = this.getSelectedDay();
                    if (member.availableSchedule[selectedDay]) {
                        member.availableSchedule[selectedDay].forEach(function(range) {
                            if (timeIterator >= range[0] && timeIterator < range[1]) {
                                slotState = SLOT_STATE_WH_SET;

                                return false;
                            }
                        });
                    }

                }


                let classList = [];
                switch (slotState) {
                    case SLOT_STATE_PAST:
                        classList.push("schedule-td-past");
                        break;
                    case SLOT_STATE_WH_SET:
                        classList.push("schedule-td-wh-set");
                        break;
                    default:
                    case SLOT_STATE_DEFAULT:
                        classList.push("schedule-td-default");
                        break;

                }

                if (self.durationShadowIndex[member.id]) {
                    if (self.durationShadowIndex[member.id][stepNumberIterator]) {
                        classList.push("schedule-element-duration-shadow");
                    }
                }


                let artistId = member.id;
                let stepIndex = stepNumberIterator;

                if (index && index[member.id]) {

                    let dataElement = index[member.id];

                    let now = (new Date()).valueOf() / 1000;
                    let status = calculateScheduleElementStatus(dataElement,
                        now,
                        this.getMemberAddress(member).timezone);

                    let processingInSteps = self.processingInStepsIndex[dataElement.id];
                    let durationInSteps = self.durationInStepsIndex[dataElement.id];

                    let durationHeight = self.state.tdHeight ? self.state.tdHeight * durationInSteps : null;
                    let processingHeight = self.state.tdHeight ? self.state.tdHeight * processingInSteps : null;

                    tdElement = <OverlayTrigger
                        key={"overlay-trigger-" + tdUniqueKey}
                        ref={this.overlayTriggerRef}
                        rootClose={true}
                        trigger={["click", "focus"]}
                        placement="bottom"
                        overlay={dialogPopover}
                        onEnter={() => {
                            let nextElement = self.getNextElementAfterStepInColumn(stepIndex, artistId, 1);

                            let address = this.getMemberAddress(member);

                            let config = props.clickMenuConfigure({
                                triggeredCase: "schedule-element",
                                elementTriggeredId: dataElement.id,
                                elementTriggered: dataElement,
                                elementTriggeredStatus: status,

                                slotTriggeredMember: member,
                                slotTriggeredMemberKey: member.id,
                                slotTriggeredStepIndex: _stepNumberIterator,
                                slotTriggeredStartTime: _timeIterator,
                                slotTriggeredState: slotState,
                                slotTriggeredAddress: address,
                                slotTriggeredTimezone: address.timezone,


                                nextElementStepIndex: self.elementIdStepNumberIndex[nextElement.id],
                                nextElementStartTime: nextElement.startTimestamp - self.memberMidnightIndex[artistId],
                                nextElement: nextElement,
                                elementsInColumn: self.dataMemberIndex[member.id],
                                midnight: self.memberMidnightIndex[artistId],
                                startPointDate: self.startPointDate,
                                step: props.step,
                                overlayTrigger: this.overlayTrigger,
                                salonClientIndex: self.salonClientIndex
                            });

                            if (self.popover) {
                                self.popover.setContextData(config);
                            }

                        }}
                    >
                        <td
                            key={tdUniqueKey}
                            className={classList.join(" ")}
                        >
                            {self.createScheduleElement(dataElement, {
                                processingHeight, durationHeight, slotState, status
                            }, this.getMemberAddress(member).timezone)}

                            <DurationShadowElement
                                uniqueId={dataElement.id}
                                height={durationHeight}
                            />
                            <Highlight ref={node => self.registerHightlight(node)} highlightState={slotState}/>
                        </td>
                    </OverlayTrigger>;

                }
                else {

                    tdElement = <OverlayTrigger
                        key={"overlay-trigger-" + tdUniqueKey}
                        ref={this.overlayTriggerRef}
                        rootClose={true}
                        trigger={["click", "focus"]}
                        placement="bottom"
                        overlay={dialogPopover}
                        onEnter={() => {

                            let nextElement = self.getNextElementAfterStepInColumn(stepIndex, artistId, 1);

                            let address = this.getMemberAddress(member);

                            let config = props.clickMenuConfigure({
                                triggeredCase: "empty-slot",

                                slotTriggeredMember: member,
                                slotTriggeredMemberKey: member.id,
                                slotTriggeredStepIndex: _stepNumberIterator,
                                slotTriggeredStartTime: _timeIterator,
                                slotTriggeredState: slotState,
                                slotTriggeredAddress: address,
                                slotTriggeredTimezone: address.timezone,

                                nextElementStepIndex: self.elementIdStepNumberIndex[nextElement.id],
                                nextElementStartTime: nextElement.startTimestamp - self.memberMidnightIndex[artistId],
                                nextElement: nextElement,
                                elementsInColumn: self.dataMemberIndex[member.id],
                                midnight: self.memberMidnightIndex[artistId],
                                startPointDate: self.startPointDate,
                                step: props.step,
                                overlayTrigger: this.overlayTrigger
                            });

                            if (self.popover) {
                                self.popover.setContextData(config);
                            }

                        }}
                    >
                        <td
                            data-member={member.id}
                            data-step-index={stepNumberIterator}
                            data-start-timestamp={timeIterator}

                            onClick={self.clickOnField}
                            onDragOver={self.onDragOver}
                            onDragEnter={self.onDragEnter}
                            onDrop={self.onDrop}
                            key={tdUniqueKey}
                            className={classList.join(" ")}
                        >
                            <Highlight ref={node => self.registerHightlight(node)} highlightState={slotState}/>
                        </td>
                    </OverlayTrigger>;
                }

                cols.push(tdElement);
            });
            timerows.push(
                <tr className="noselect" key={stepNumberIterator}>
                    {timecols}
                </tr>
            );
            rows.push(
                <tr key={idGenerator("row", timeIterator)}>
                    {cols}
                </tr>
            );
            timeIterator += this.props.step;

            stepNumberIterator++;

        }
        while (timeIterator < this.rBound);


        let degenerated = this.rBound === 0;

        return (
            <div className="row">
                <div className='scheduler-component scheduler-component-container'>
                    <div className="inner">
                        <div className="sticky-wrap table-time">
                            <table className="sticky-enabled">
                                <thead>
                                <tr>
                                    <th>
                                        {printDate(this.props.selectedDate, "ddd, MMM DD")}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {timerows}
                                </tbody>
                            </table>
                            <table
                                style={{ display: degenerated ? "none" : "" }}
                                className="sticky-thead">
                                <thead>
                                <tr>
                                    <th>
                                        {printDate(this.props.selectedDate, "ddd, MMM DD")}
                                    </th>
                                </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="sticky-wrap" ref="sticky">
                            <table className='table-events sticky-enabled' ref={this.calcTableSize}>
                                <TableHead members={this.props.members} memberOffsetIndex={this.memberOffsetIndex}/>
                                <tbody>
                                {rows}
                                </tbody>
                            </table>
                            <table
                                style={{ display: degenerated ? "none" : "" }}
                                className="sticky-thead">
                                <TableHead members={this.props.members} memberOffsetIndex={this.memberOffsetIndex}/>
                                <tbody>
                                <tr>
                                    <td colSpan="100" className="scroll-wrapper">
                                        <div className="double-scroll" ref="doubleScroll">
                                            <div className="scroll" ref="scroll"></div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    stickyNative() {

        let el = document.querySelectorAll(".sticky-wrap");
        //let scrollTop = 0;

        let width = () => {
            [].forEach.call(el, function(value, index, ar) {
                let self = value;
                [].forEach.call(value.querySelectorAll(".sticky-thead tr th"), function(value, index, ar) {
                    let el = self.querySelectorAll(".sticky-enabled tr th")[index];
                    value.style.width = el.offsetWidth + "px";
                    value.style.maxWidth = el.offsetWidth + "px";
                });
            });
        };

        //   scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        width();


    };

    onScrollHandler() {

        if (document.querySelector(".scheduler-component-container .inner").getBoundingClientRect().top < 0) {
            [].forEach.call(document.querySelectorAll(".sticky-thead"), function(value, index, ar) {
                value.classList.add("fixed");
                value.style.top = -(document.querySelector(".scheduler-component-container .inner")
                .getBoundingClientRect().top) + "px";
                value.style.display = "block";
            });
        }
        else {
            [].forEach.call(document.querySelectorAll(".sticky-thead"), function(value, index, ar) {
                if (value.classList.contains("fixed")) {
                    value.classList.remove("fixed");
                    value.style.display = "none";

                }
            });
        }

    }

    componentDidMount() {

        this.stickyNative();
        window.addEventListener("scroll", this.onScrollHandler);

        if (this.refs.doubleScroll) {
            this.refs.doubleScroll.style.width = this.refs.sticky.getBoundingClientRect().width + "px";
        }

        if (this.refs.scroll) {
            this.refs.scroll.style.width = this.mainTable.getBoundingClientRect().width + "px";
        }

        // this.refs.doubleScroll.onscroll = function () {
        //     st.scrollLeft = this.refs.doubleScroll.scrollLeft
        // }.bind(this)
    }

    calcTableSize(table) {

        if (table) {
            this.mainTable = table;

            if (!this.state.tdHeight) {

                this.setState({
                    tdHeight: table.querySelector("tbody td").offsetHeight
                });

            }
        }
    }
}

Scheduler.propTypes = {
    step: PropTypes.number,
    selectedDate: PropTypes.string,
    members: PropTypes.array.isRequired,
    clickOnField: PropTypes.func,
    clickOnBlock: PropTypes.func,
    moveElementCallback: PropTypes.func,
    clickMenuConfigure: PropTypes.func,
    popoverElement: PropTypes.element
};

Scheduler.defaultProps = {
    step: 15 * 60, //15 min
    clickMenuConfigure: function(contextData) {
        return {
            originContext: contextData,
            buttons: []
        };
    },

    scrollToApt: null
};