import { CLIENT_RECOMMENDATION_LINK_VALID, CLIENT_RECOMMENDATION_LINK_INVALID } from "../../constants";
import BaseStore from "../base-store";

class ClientRecommendationStore extends BaseStore {
    constructor(...args) {
        super(...args);

        this.store = {
            linkValid: null,
            artistName: "",
            salonName: "",
            linkExist: null
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;
            switch (type) {
                case CLIENT_RECOMMENDATION_LINK_VALID:

                    this.store.linkValid =  payload.isValid;
                    this.store.artistName = payload.recommendedByArtistName;
                    this.store.salonName = payload.recommendedBySalonName;
                    this.store.linkExist = true;

                    break;

                case CLIENT_RECOMMENDATION_LINK_INVALID:
                    this.store.linkValid = false;
                    this.store.artistName = "";
                    this.store.salonName = "";
                    this.store.linkExist = false;

                    break;


                default:
                    return true;
            }
            this.emitChange();
        });
    }

}

export default ClientRecommendationStore;