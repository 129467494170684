/**
 * Created by fliak on 22.2.17.
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_NOT_A_NUMBER = "VALUE_IS_NOT_A_NUMBER";

export default class IsNumber extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Must be a number";

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        return "is-number";
    }
}
