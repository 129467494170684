/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.7.17
 */

import React from "react";
import {
    FORM_MODE_CREATE,
    FORM_MODE_UPDATE,
    FORM_MODE_VIEW
} from "./../../../../frizo-shared-constants";
import { Button, Col, Row } from "react-bootstrap";
import { CREATE_PAYMENT_PROFILE_MODAL } from "./../../../../constants";
import { modalChange } from "./../../../../ac/modals";

export default function PaymentProfileWidget(props) {

    let openPopupMode = FORM_MODE_VIEW;
    let fieldsNeeded= props.fieldsNeeded  && props.fieldsNeeded.filter((item)=>{
        return item !== 'external_account';
    });
    if (props.profileExist) {
        if (props.fieldsNeeded && fieldsNeeded.length) {
            openPopupMode = FORM_MODE_UPDATE;
        }
    }
    else {
        openPopupMode = FORM_MODE_CREATE;
    }

    let openPopup = function() {
        modalChange(CREATE_PAYMENT_PROFILE_MODAL, { mode: openPopupMode });
    };

    return <Row className="subscription-profile__section-separator">
        <Col md={12} xs={12} className="fr-mb-xs bank-card-profile">
            <div className="subscription-profile__plan-title">
                <div className="fr-inline">Payment profile</div>
                {openPopupMode === FORM_MODE_CREATE &&
                <Button onClick={openPopup} className="pull-right fr-btn-add fr-ml">CREATE</Button>}

                {openPopupMode === FORM_MODE_UPDATE &&
                <Button onClick={openPopup} className="pull-right fr-btn-add">UPDATE</Button>}

                {openPopupMode === FORM_MODE_VIEW &&
                <Button onClick={openPopup} className="pull-right fr-btn-edit">VIEW</Button>}
            </div>
        </Col>
    </Row>;
}