/**
 * Created by Tatjana Jankova on 12.01.2017.
 */
import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import config from '../config';
import ApiClient from '../service/api-client';
import {Link} from 'react-router';
import {modalChange} from "../ac/modals";
import {CONTACT_MODAL} from "../constants";

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frontVersion: '',
            backVersion: ''
        };
        this.requestVersion = this.requestVersion.bind(this);
    }

    componentDidMount() {
        this.requestVersion();
    }

    requestVersion() {
        let url = ApiClient.getRouting().generate('Q124_get_current_version');
        return ApiClient.get(url).then(answer => {
                let versions = answer[0];
                this.setState({
                    frontVersion: versions['version-front'],
                    backVersion: versions['version-back']
                })
            }
        )
    }

    openContactUs() {
        modalChange(CONTACT_MODAL);
    }

    close() {
        this.props.close();
    }

    render() {
        let params = config.defaultFooter;
        return (
            <div id="footer">
                <div className="fr-footer fr-mt">
                    <div className="container">
                        <Row className="row">
                            <Col xs={12} md={3}>
                                <ul className="fr-social">
                                    <li className="fr-ig">
                                        <a className="btn btn-block btn-social btn-instagram"
                                           href={params.instagram.url} target="_blank"
                                           rel="noopener noreferrer"
                                           title={params.instagram.title}>
                                        </a>
                                    </li>
                                    <li className="fr-yt">
                                        <a className="btn btn-block btn-social btn-youtube"
                                           href={params.youtube.url}
                                           rel="noopener noreferrer"
                                           target="_blank" title={params.youtube.title}>
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="fr-footer-contact-us">
                                    <button
                                        className="btn-link fr-link"
                                        onClick={this.openContactUs}>{params.contactus.title}</button>
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <Link to={params.legal.url} className="fr-footer-text">{params.legal.title}</Link>
                            </Col>
                            <Col xs={12} md={1}>
                                <Link to={params.faq.url} className="fr-footer-faq">{params.faq.title}</Link>
                            </Col>
                            <Col md={4} xs={12} className="fr-footer-store">
                                <a className="fr-iosicon " href={params.appstore.url}
                                   target="_blank" rel="noopener noreferrer"
                                   title={params.appstore.title}
                                   alt={params.appstore.title}>
                                </a>
                                <a className="fr-google-icon " href={params.googlePlay.url}
                                   target="_blank" rel="noopener noreferrer"
                                   title={params.googlePlay.title}
                                   alt={params.googlePlay.title}>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="container">
                    <Row>
                        <Col md={6} xs={12} className="fr-footer-copyright small">{params.copyright.title}</Col>
                        <Col md={6} xs={12} className="fr-footer-version small"><a className="fr-footer-array"
                                                                                   href="/array">&bull;</a>
                            Build: {this.state.frontVersion} / {this.state.backVersion}</Col>
                    </Row>
                </div>
            </div>
        )
    }
}