/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.27.3
 * Time: 14:55
 */

import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {ServiceListCreator} from './helpers';
import  TableRow from './components/table-row';
import TableToggle from './components/table-toggle';
import {updateIndex} from './helpers';

export default class SalonServicesList extends Component {

    constructor(props) {
        super(props);
        this.createLayout = this.createLayout.bind(this);
    }


    makeServicesHip(listOfLeafs) {
        let leafsIndex = updateIndex(listOfLeafs);
        let servicesIds = new Set();
        let services = [];
        const listCreator = new ServiceListCreator(this.props.services);
        listOfLeafs.forEach(leaf => {
            let branch = listCreator.createParentsBranch(this.props.services, leaf.id);
            branch.forEach(id => {
                servicesIds.add(id);
            });
        });

        servicesIds.forEach((id) => {
            if (leafsIndex[id]) {
                services.push(Object.assign(this.props.servicesIndex[id], leafsIndex[id]));
            }
            else {
                services.push(this.props.servicesIndex[id]);
            }
        });
        return services;
    }

    createLayout(data) {

        return data.map((service, index) => {
            let first = false;
            if (index === 0) {
                first = true;
            }
            if (Array.isArray(service.children)) {
                return <TableToggle first={first} key={service.id} name={service.i18nKey}
                                    count={service.children.length}>
                    {this.createLayout(service.children)}
                </TableToggle>
            }

            else {
                return <TableRow key={service.id}
                                 promo={this.props.promo}
                                 service={service}
                                 processing={this.props.processing}
                                 btnDisabled={this.props.btnDisabled}
                                 duration={this.props.duration}
                                 onServiceRemove={this.props.onServiceRemove}
                                 onServiceModify={this.props.onServiceModify}/>
            }

        });
    }


    prepareServicesData(services) {

        const serviceCreator = new ServiceListCreator(services);
        return serviceCreator.createTree();

    }


    render() {
        const hip = this.makeServicesHip(this.props.selectedServices);
        const servicesData = this.prepareServicesData(hip);

        if ((Object.keys(servicesData).length)) {

            if (this.props.promo) {
                return <div>
                    <div className="fr-table-container">
                        <div id="list_header">
                            <Row className="fr-list-header bordered">
                                <Col xs={6} md={6}>All services</Col>
                                <Col xs={3} md={2} className={"fr-serv-duration"}>Duration</Col>
                                <Col xs={3} md={3} className="fr-serv-price-title">Price</Col>
                            </Row>
                            {this.createLayout(servicesData)}
                        </div>

                    </div>
                </div>
            }

            return <div className="fr-table-container fr-mb">
                <div id="list_header">
                    <Row>
                        <Col md={12}>
                            <div className="fr-profile__title">Selected services</div>
                        </Col>
                    </Row>
                    <Row className="fr-list-header bordered fr-table-centred">
                        <Col xs={8} md={4}>Category and Service</Col>
                        <Col xs={2} md={2} className="fr-serv-price-title">Price</Col>
                        <Col xsHidden smHidden md={2} className={"fr-serv-duration"}>Duration</Col>
                        <Col xsHidden smHidden md={2} className={"fr-serv-price-title"}>Processing</Col>
                    </Row>
                    {this.createLayout(servicesData)}
                </div>

            </div>
        }
        return null;
    }

}
SalonServicesList.defaultProps = {
    promo: false
};