/**
 * Created by fliak on 10.1.17.
 */

import {
    BOOK_LOAD_TIMELINE_SUCCESS,
    LOAD_FILTERS_SERVICES_SUCCESS,
    LOAD_SALON_CLIENT_LIST_SUCCESS,
    LOAD_SELECT_SERVICES_SUCCESS,
    LOAD_SERVICES_SUCCESS,
    SALON_CLIENT_LIST_RESET
} from "../../constants";

import AppDispatcher from "../../dispatcher";
import { xhrLoadSalonClientList } from "../../xhr/appointments-xhr";
import { xhrSalonClientsAutoComplete } from "../../xhr/salon-clients-xhr";

export function servicesLoaded(services, salonCorrections, artistsCorrections) {
    AppDispatcher.dispatch({
        type: LOAD_SERVICES_SUCCESS,
        payload: { services, salonCorrections, artistsCorrections }
    });
}

export function filterServicesLoaded(salonId, services, salonCorrections, artistsCorrections) {
    AppDispatcher.dispatch({
        type: LOAD_FILTERS_SERVICES_SUCCESS,
        payload: { salonId, services, salonCorrections, artistsCorrections }
    });
}

export function servicesForSelectLoaded(salonId, services, salonCorrections, artistsCorrections) {
    AppDispatcher.dispatch({
        type: LOAD_SELECT_SERVICES_SUCCESS,
        payload: { salonId, services, salonCorrections, artistsCorrections }
    });
}

export function timelineLoaded(midnightTimestamp, artistIds, timelineSet) {
    AppDispatcher.dispatch({
        type: BOOK_LOAD_TIMELINE_SUCCESS,
        payload: { midnightTimestamp, artistIds, timelineSet }
    });
}


/**
 * @deprecated such as it load full list of clients
 */
export function loadSalonClientList() {
    xhrLoadSalonClientList().then(list => {
        AppDispatcher.dispatch({
            type: LOAD_SALON_CLIENT_LIST_SUCCESS,
            payload: list
        });
    });
}

export function salonClientsAutoComplete(search) {
    if (String(search).length > 0) {
        xhrSalonClientsAutoComplete(search).then(list => {
            AppDispatcher.dispatch({
                type: LOAD_SALON_CLIENT_LIST_SUCCESS,
                payload: list
            });
        });
    }
    else {
        AppDispatcher.dispatch({
            type: SALON_CLIENT_LIST_RESET
        });
    }
}