/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 06.07.2017
 * Time: 10:18
 */

export default function printAddress(address) {
    let addressString = [
        address.address,
        address.addressStr,
        address.suite && address.suite.length > 0 ? "Suite " + address.suite : "",
        address.city,
        address.state
    ].filter(value => value && value.length > 0).join(", ");
    if (address.zip.length) {
        addressString = addressString + " " + address.zip;
    }

    return addressString;
}
