/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.8.17
 */

import ApiClient from "../service/api-client";

export function checkProfileCompletion() {

    return ApiClient.get(["Q125_profile_completion_check"]).then(answer => {
        let [response] = answer;

        return Promise.resolve(response);
    });
}
