/**
 * User: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 30.3.17
 * Time: 15.18
 */

import React from 'react';
import {Col} from 'react-bootstrap';
import printAddress from '../../../helper/print-address';

export default function AddressItem(props) {
    let {address} = props;

    function handleClick() {
        props.onSalonAddressSelect(address);
    }

    let addressString = printAddress(address);

    return (
        <div>
            <Col xs={8}>
                <p className="fr-fl">{addressString}</p>
            </Col>
            <Col xs={4}>
                <button name="direction" type="submit" className={"fr-link fr-fr btn-link " + props.disabled}
                        onClick={handleClick}>
                    Directions
                </button>
            </Col>
        </div>
    )
}
