import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import config from "./../config";
import notificationSystem from "../service/notification-system";
import { xhrSavePushToken } from "../xhr/save-push-token-xhr";
import { events } from "../auth/events";

if (!firebase.apps.length) {
    firebase.initializeApp(config.firebase);

    console.log("firebase config", config, firebase.apps.length);


    if (config.pushNotificationsEnabled && navigator.serviceWorker) {
        navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
            console.log("Register SW");
            firebase.messaging().useServiceWorker(registration);
        });
    }
}

export {
    firebase
};

export const askForPermissionToReceiveNotifications = async () => {
    try {

        if (!config.pushNotificationsEnabled) {
            return;
        }

        if (!window.Notification) {
            return;
        }

        if (window.Notification.permission === "granted") {
            return;
        }

        if (localStorage.userDeniedPushNotification === "denied") {
            return;
        }

        let ret = await notificationSystem.confirm(
            "Включите нотификации, чтобы оставаться на связи"
        );

        if (ret.buttonClicked === "cancel") {
            localStorage.userDeniedPushNotification = "denied";

            return;
        }

        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();

        console.log("push token:", token);

        xhrSavePushToken(token);

        return token;

    }
    catch (error) {
        console.error(error);
    }
};


events.renewingFinished.add((success, session, options) => {
    if (success) {
        askForPermissionToReceiveNotifications();
    }
});
