/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 1.4.17
 * Time: 9.01
 */

import BaseSyncer from "./../../base-syncer";

import { modalHide } from "./../../../ac/modals";
import {
    paymentProfileSaveFail,
    paymentProfileSaveSuccess,
    paymentProfileUploadDocumentSuccess
} from "./../../../ac/profile/professional/cards-and-bank";

import {
    xhrCreatePaymentProfile,
    xhrUpdatePaymentProfile,
    xhrUploadLegalDocument
} from "../../../xhr/payments/professional/payment-profile-xhr";


import { FORM_MODE_CREATE, FORM_MODE_UPDATE } from "./../../../frizo-shared-constants";
import { paymentProfileFormStore } from "../../../stores/index";

export default class PaymentProfileSyncer extends BaseSyncer {

    constructor() {
        super();

        this.data = null;
        this.mode = FORM_MODE_CREATE;
    }

    setMode(mode) {
        this.mode = mode;
    }

    putFormData(data) {

        let date = new Date(data.dateOfBirth);
        this.data = {
            firstName: data.firstName,
            lastName: data.lastName,
            // ssnLast4: data.ssnLast4,
            // personalIdNumber: data.firstName,
            dobDay: date.getDate(),
            dobMonth: date.getMonth() + 1, // month correction for stripe
            dobYear: date.getFullYear(),
            type: data.legalEntityType,
            businessName: data.businessName,
            businessTaxId: data.businessTaxId,
            businessUrl: data.businessUrl,
            country: "us",
            city: data.city,
            address: data.address,
            state: data.state,
            postalCode: data.zip

        };
        if (data.ssnLast4 !== paymentProfileFormStore.getAsteriskString(4)) {
            this.data.ssnLast4 = data.ssnLast4;
        }
        if (data.personal_id_number && data.personal_id_number !== paymentProfileFormStore.getAsteriskString(9)) {
            this.data.personalIdNumber = data.personal_id_number.replace(/-/g, "");
        }

        if (data.legalDocument) {
            this.uploadLegalDocument(data.legalDocument);
        }
    }

    persist() {

        if (this.data === undefined) {
            console.warn("Persist skipped because not data enough", this);
            return;
        }

        console.log("this.data", this.data);

        let xhr;
        switch (this.mode) {
            case FORM_MODE_CREATE:
                xhr = xhrCreatePaymentProfile;
                break;

            case FORM_MODE_UPDATE:
                xhr = xhrUpdatePaymentProfile;
                break;

            default:
                throw new Error(`Mode ${this.mode} isn't supported`);
        }

        xhr(this.data).then(response => {

            paymentProfileSaveSuccess(response);
            modalHide();

        }, response => {
            paymentProfileSaveFail(response);
        });
    }

    uploadLegalDocument(image) {
        xhrUploadLegalDocument(image).then(response => {

            paymentProfileUploadDocumentSuccess(response);
            modalHide();

        }, response => {
            paymentProfileSaveFail(response);
        });
    }
}
