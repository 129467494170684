/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 11.4.17
 * Time: 5.53
 */

import AppDispatcher from "../dispatcher";
import { GALLERY_FILTER_LOAD_ARTISTS_SUCCESS, GALLERY_FILTER_RESET } from "./../constants";
import { xhrLoadServices } from "../xhr/services-xhr";
import xhrLoadSalonArtists from "../xhr/salon-artists-xhr";
import { filterServicesLoaded } from "./book-apt/data-load-actions";
import { GALLERY_ENTER_PARAMS, GALLERY_FILTER_LOAD_SERVICES_SUCCESS, GALLERY_FILTER_PRESET } from "../constants";
import { xhr } from "../service/api-client-2";
import { CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";
import { artistNormalizer1 } from "../normal-form/user-derived-forms/artist";
import { serviceNormalize } from "../normal-form/service";

export function loadGalleryFilterData(salonId, hasGalleryItem = 0, publicOnly) {

    xhr()
    .get("@Q39_3_salon_services_corrections", { salonId })
    .header(CUSTOM_HEADER_U6_GROUPS, ["basic", "gallery-basic"])
    .end()
    .then(request => {

        let { response } = request;

        let services = response.map(salonService => serviceNormalize(salonService.service));

        AppDispatcher.dispatch({
            type: GALLERY_FILTER_LOAD_SERVICES_SUCCESS,
            payload: services
        });

    });

    xhrLoadSalonArtists(salonId, hasGalleryItem, [
        "basic", "salonInfo", "user-services", "gallery-basic"
    ], publicOnly).then(artists => {

        AppDispatcher.dispatch({
            type: GALLERY_FILTER_LOAD_ARTISTS_SUCCESS,
            payload: { artists: artists.map(artistNormalizer1) }
        });

        return { artists };
    });
}

export function loadGalleryFilterServices(salonId, artistIds, hasGalleryItem = 0) {

    return xhrLoadServices(salonId, artistIds, hasGalleryItem).then((answer) => {
        filterServicesLoaded(salonId, answer.services, answer.salonCorrections, answer.artistsCorrections);
    });
}

export function onFilterChange(galleryId, field, value) {

    AppDispatcher.dispatch({
        type: GALLERY_ENTER_PARAMS,
        payload: {
            galleryId,
            params: {
                [field]: value
            }
        }
    });
}


export function onFilterReset(galleryId) {
    AppDispatcher.dispatch({
        type: GALLERY_FILTER_RESET,
        payload: {
            galleryId,
            artistId: 0,
            serviceId: 0
        }
    });
}

export function initFilter(galleryId, params) {
    AppDispatcher.dispatch({
        type: GALLERY_FILTER_PRESET,
        payload: Object.assign({
            galleryId
        }, params)
    });
}
