/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.5.17
 * Time: 6.36
 */

import BaseStore from "../../base-store";
import {
    LOAD_SESSION_SUCCESS,
    PROFILE_PERSONAL_ESSENTIALS_CHANGE,
    PROFILE_PERSONAL_ESSENTIALS_PERSIST,
    PROFILE_PERSONAL_ESSENTIALS_PERSIST_FAIL,
    UPDATE_SESSION_DATA
} from "../../../constants";
import { GENDER_NOT_SHARED } from "../../../frizo-shared-constants";
import Validator from "../../../validation/validator";
import { loadProfileCompletenessInfo } from "./../../../ac/profile-completeness-actions";
import { imageUrl } from "../../../helper/image-url";
import { sessionStore } from "../../../stores";

import Filtration from "../../../filter/filtration";
import TrimFilter from "../../../filter/trim-filter";

export default class ProfilePersonalEssentialsStore extends BaseStore {

    constructor(...args) {
        super(...args);
        this.store = {
            firstName: "",
            lastName: "",
            instagramUsername: "",
            genderID: GENDER_NOT_SHARED,
            phone: "",
            zip: "",
            address: "",
            imageUrl: "",
            imageId: 0,
            dirty: false,
            message: null,
            showMessage: false
        };

        this._registerActionSubscription((action) => {
            let {
                type,
                payload
            } = action;

            switch (type) {

                case PROFILE_PERSONAL_ESSENTIALS_PERSIST_FAIL:
                    this.store.showViolations = false;
                    this.store.showError = false;

                    if (payload.violations) {
                        this.store.violations = payload.violations;
                        this.store.showViolations = true;
                    }

                    if (payload.error) {
                        this.store.error = payload.error;
                        this.store.showError = true;
                    }

                    break;

                case PROFILE_PERSONAL_ESSENTIALS_PERSIST:

                    this.filtrate();

                    if (!this.validator) {
                        this.buildValidation();
                    }


                    if (this.validate()) {

                        payload.syncer.putData(this.store);
                        payload.syncer.persist();
                    }
                    else {
                        this.store.showViolations = true;
                    }

                    break;

                case UPDATE_SESSION_DATA: {

                    let data = payload.response;

                    data.imageUrl = data.imageUrl ? imageUrl(data.imageUrl) : "";
                    data.instagramUsername = data.instagramUsername ? data.instagramUsername : "";

                    Object.assign(this.store, data, {
                        address: this.formatAddress(data.address),
                        showMessage: true,
                        showViolations: false,
                        showError: false
                    });

                    if (this.store.session.professional) {
                        loadProfileCompletenessInfo();
                    }

                    break;
                }

                case PROFILE_PERSONAL_ESSENTIALS_CHANGE:
                    this.store.dirty = true;
                    Object.assign(this.store, payload, {
                        showMessage: false,
                        showError: false
                    });

                    break;

                case LOAD_SESSION_SUCCESS:

                    if (this.store.dirty) return true;
                    this.waitFor(this.stores.session.getDispatchToken());

                    let store = this.stores.session.getStore();

                    Object.assign(this.store, {
                        session: store.session,
                        userName: store.session.userName ? store.session.userName : "",
                        firstName: store.session.firstName ? store.session.firstName : "",
                        lastName: store.session.lastName ? store.session.lastName : "",
                        genderID: store.session.gender ? store.session.gender.code : "",
                        instagramUsername: store.session.instagramUsername ? store.session.instagramUsername : "",
                        phone: store.session.phone ? store.session.phone : "",
                        zip: store.session.address ? store.session.address.zip : "",
                        address: store.session.address ? this.formatAddress(store.session.address) : "",
                        imageUrl: store.session.image ? imageUrl(store.session.image.url) : "",
                        imageId: store.session.image ? store.session.image.id : ""
                    });
                    break;

                default:
                    return true;
            }

            this.emitChange();
        });

    }

    filtrate() {
        const filtration = new Filtration();
        const trim = new TrimFilter();

        filtration.registerFilter("firstName", trim);
        filtration.registerFilter("lastName", trim);

        this.store = filtration.filtrate(this.store);
    }

    /**
     * @protected
     */
    buildValidation() {
        let validator = new Validator();

        validator.addConstraint("firstName", "is-required");
        validator.addConstraint("firstName", "is-name");
        validator.addConstraint("firstName", "length", {
            min: 2,
            max: 50
        });

        validator.addConstraint("lastName", "is-required");
        validator.addConstraint("lastName", "is-name");
        validator.addConstraint("lastName", "length", {
            min: 2,
            max: 50
        });

        validator.addConstraint("genderID", "is-required");

        /*
         Removed during to zip and address field was removed from Me

         validator.addConstraint('zip', 'is-required');
         validator.addConstraint('zip', 'is-number');
         validator.addConstraint('zip', 'length', {
         max: 5,
         min: 5
         });

         validator.addConstraint('address', 'is-required');
         */
        validator.addConstraint("phone", "is-required");
        validator.addConstraint("phone", "is-number");
        validator.addConstraint("phone", "length", {
            max: 10,
            min: 10
        });

        if (sessionStore.isMaster()) {
            validator.addConstraint("imageId", "image");
        }

        this.validator = validator;
    }

    formatAddress(addressObject) {
        return addressObject.city + ", " + addressObject.state;
    }
}
