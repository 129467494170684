/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 10.38
 *
 * @flow
 */

import React, { Component } from "react";
import ReactDOM from "react-dom";
import Masonry from "react-masonry-component";
import LazyLoad from "react-lazyload";
import BaseStore from "../../stores/base-store";
import type { TGalleryElement } from "../../types";
import GalleryMessage from "../../elements/gallery-message";
import Loader from "./../../elements/loader";
import { galleryAdditiveModeEnable, galleryNextPage } from "../../ac/gallery/generic-gallery-actions";
import GalleryMoreButton from "./gallery-parts/gallery-more-button";
import SessionStore from "../../stores/session-store";

type Props = {
    galleryId: string,
    itemClassResolver: Function,
    store: BaseStore,
    sessionStore: SessionStore,
    favoritesEnabled: boolean,
    onMoreButtonHandler: Function,
    onItemClickHandler: Function,
    noItemsText?: string
};

type State = {
    items: Array<TGalleryElement>,
    itemsCount: number,
    showMoreButton: boolean,
    loading: boolean,
    endReached: boolean,
    scrollMode: boolean
};

export class GalleryWidget extends Component<Props, State> {

    static defaultProps: {};
    getStoreData: Function;
    storeUpdate: Function;
    galleryRef: Component<*>;
    onScrollHandler: Function;

    constructor(props: Props) {
        super(props);

        this.getStoreData = this.getStoreData.bind(this);
        this.storeUpdate = this.storeUpdate.bind(this);
        this.onScrollHandler = this.onScrollHandler.bind(this);

        this.state = {
            items: [],
            itemsCount: 0,
            showMoreButton: true,
            loading: false,
            endReached: false,
            scrollMode: false,
            session: {}
        };

        Object.assign(this.state, this.getStoreData.bind(this)());

    }


    componentDidMount() {
        this.props.store.addChangeListener(this.storeUpdate);

        if (this.props.sessionStore) {
            this.props.sessionStore.addChangeListener(this.storeUpdate);
        }
    }

    componentWillUnmount() {
        this.props.store.removeChangeListener(this.storeUpdate);

        if (this.props.sessionStore) {
            this.props.sessionStore.removeChangeListener(this.storeUpdate);
        }

        window.removeEventListener("scroll", this.onScrollHandler);
    }

    componentWillUpdate(nextProps: Props, nextState: State) {
        if (this.state.scrollMode) {
            if (!nextState.scrollMode) {
                window.removeEventListener("scroll", this.onScrollHandler);
            }
        }
        else {
            if (nextState.scrollMode) {
                window.addEventListener("scroll", this.onScrollHandler, false);
            }
        }
    }


    onScrollHandler() {

        if (this.state.loading) return false;

        const galleryRef = ReactDOM.findDOMNode(this.galleryRef);
        if (!galleryRef) {
            console.warn("galleryRef is missing");

            return;
        }

        //$FlowFixMe
        const galleryBottom = galleryRef.getBoundingClientRect().bottom;

        //$FlowFixMe
        let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        //TODO: make height  prop;
        windowHeight += 10;

        if (parseInt(windowHeight, 10) > parseInt(galleryBottom, 10)) {

            galleryNextPage(this.props.galleryId);

        }
    }


    /**
     * @protected
     * @returns {{}}
     */
    getParamsFromStore() {
        let storeData = this.props.store.getStore().gallery;
        if (typeof storeData.params === "object") {
            return storeData.params;
        }

        console.warn("Store initial params isn't set for gallery", this.props.galleryId, this.props.store);

        return {};

    }


    getStoreData() {
        let store = this.props.store.getStore().gallery;
        let filtered = {};

        ["items", "itemsCount", "showMoreButton", "loading", "endReached", "scrollMode"].forEach(param => {
            filtered[param] = store[param];
        });

        let sessionData = {};
        if (this.props.sessionStore) {
            let session = this.props.sessionStore.getStore().session;

            sessionData = {
                isLogged: session.isLogged
            };
        }

        return Object.assign(filtered, {
            session: sessionData
        });
    }

    storeUpdate() {
        this.setState(this.getStoreData());
    }


    itemClick(index: number) {
        this.props.onItemClickHandler(index);
    }

    createItem(item: TGalleryElement, index: number) {

        let props = Object.assign({}, item, {
            key: index,
            onClick: this.itemClick.bind(this, index),
            favoritesEnabled: this.props.favoritesEnabled,
            storeParams: this.getParamsFromStore(),
            galleryId: this.props.galleryId
        });


        let itemClass = this.props.itemClassResolver(item);

        if (typeof itemClass !== "function") {
            console.warn("Problem with resolving itemClass for item", item);
            console.warn("Resolved value:", itemClass);

            throw new Error("Problem with gallery configuration. Cannot resolve valid itemClass for item, see warning");
        }

        return React.createElement(itemClass, props);
    }

    setGalleryRef(node: any) {
        this.galleryRef = node;
    }


    scrollToTop() {
        let header = document.getElementById("header-wrap");
        if (header) {
            header.scrollIntoView({ behavior: "smooth" });
        }
    }

    clickMoreButton() {
        if (typeof this.props.onMoreButtonHandler === "function") {
            return this.props.onMoreButtonHandler();
        }

        galleryAdditiveModeEnable(this.props.galleryId);
    }

    createMoreBtnOrStatus() {

        switch (true) {

            case this.state.showMoreButton:
                return <GalleryMoreButton onClick={this.clickMoreButton.bind(this)}/>;

            case this.state.itemsCount === 0 && this.state.endReached:
                return <GalleryMessage message={this.props.noItemsText}/>;

            case this.state.endReached:
                return <div className="gallery-end-message">
                    <div>End of gallery.</div>
                    {(this.state.itemsCount > 7 || window.innerWidth < 768) &&
                    <button className="btn-link " onClick={this.scrollToTop}>Go up</button>}
                </div>;

            case this.state.loading:
                return <Loader/>;

            default:
                return null;
        }

    }

    render() {

        return <div ref={this.setGalleryRef.bind(this)} id={"gallery-start"}>
            <div className="fr-item-list">
                <LazyLoad height={0}>
                    <Masonry className={"row grid"} elementType={"div"}
                             options={{
                                 transitionDuration: "1s",
                                 columnWidth: ".col-md-3",
                                 percentPosition: true
                             }}>

                        {this.state.items.map(this.createItem.bind(this))}

                    </Masonry>
                </LazyLoad>
            </div>
            {this.createMoreBtnOrStatus.bind(this)()}
        </div>;
    }
}

GalleryWidget.defaultProps = {
    onItemClickHandler: () => {
    }
};