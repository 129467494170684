/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 9.12.16.
 *
 */

import React, {Component} from 'react';
import {Popover, Button, Row} from 'react-bootstrap';
import idGenerator from './../../helper/id-generator-component';
import {
    JOURNAL_ELEMENT_APPOINTMENT,
    JOURNAL_ELEMENT_BLOCK,
    SLOT_STATE_DEFAULT,
    SLOT_STATE_WH_SET,
    SLOT_STATE_PAST,
    SCHEDULE_ELEMENT_STATE_IN_PROGRESS,
    SCHEDULE_ELEMENT_STATE_PAST,
    SCHEDULE_ELEMENT_STATE_SUSPENSE,
    SCHEDULE_ELEMENT_STATE_NO_SHOW
} from './../../frizo-shared-constants';
import {LocalTime} from '../helper-components/time-component';
import moment from 'moment';
import PropTypes from 'prop-types';
import config from './../../config';
import PhoneStaticMask from './../../elements/phone-static-mask';
import {clientCommentStore} from '../../stores/index';


export default class FrizoBookPopover extends Component {

    constructor(props) {
        super(props);

        this.state = {
            context: {},
            showTimeSelector: false,
            show: true
        };

        this.blockAction = this.blockAction.bind(this);
        this.bookAction = this.bookAction.bind(this);
        this.bookAgainAction = this.bookAgainAction.bind(this);
        this.editAction = this.editAction.bind(this);
        this.cancelAction = this.cancelAction.bind(this);
        this.cancelAllAction = this.cancelAllAction.bind(this);
        this.noShowAction = this.noShowAction.bind(this);
        this.unblockAction = this.unblockAction.bind(this);
        this.editClientCommentAction = this.editClientCommentAction.bind(this);


        this.menuButtons = {
            book: (<Button
                onClick={this.bookAction}
                className="popover-btn popover-btn-book"
            >Book</Button>),

            bookAnother: (<Button
                onClick={this.bookAgainAction}
                className="popover-btn popover-btn-book ml-min"
            >Book again</Button>),

            block: (<Button
                onClick={this.blockAction}
                className="popover-btn popover-btn-block"
            >Block</Button>),

            unblock: (<Button
                onClick={this.unblockAction}
                className="popover-btn popover-btn-unblock"
            >Unblock</Button>),

            edit: (<Button
                onClick={this.editAction}
                className="popover-btn popover-btn-edit"
            >Modify</Button>),

            cancel: (<Button
                onClick={this.cancelAction}
                className="popover-btn popover-btn-block"
            >Cancel</Button>),

            cancelAll: (<Button
                onClick={this.cancelAllAction}
                className="popover-btn popover-btn-block ml-min"
            >Cancel All</Button>),

            noshow: (<Button
                onClick={this.noShowAction}
                className="popover-btn popover-btn-noshow"
            >No-show</Button>)
        };

        this.onBlockSelectorChange = this.onBlockSelectorChange.bind(this);
    }

    cancelAction(e) {
        this.props.cancelAction(this.state.context, e);
        this.hide();
    }

    cancelAllAction(e) {
        this.props.cancelAllAction(this.state.context, e);
        this.hide();
    }

    unblockAction(e) {
        this.props.unblockAction(this.state.context, e);
        this.hide();
    }

    noShowAction(e) {
        this.props.noShowAction(this.state.context, e);
        this.hide();
    }

    bookAction(e) {
        if (typeof this.props.bookAction === 'function') {
            this.props.bookAction(this.state.context, e);
        }
        this.hide()
    }

    bookAgainAction(e) {
        if (typeof this.props.bookAgainAction === 'function') {
            this.props.bookAgainAction(this.state.context, e);
        }
        this.hide();

    }

    editAction() {
        // console.log('BOOK EDIT ACTION', this.state.context);
        this.props.editAction(this.state.context);
        this.hide();
    }

    blockAction() {
        this.setState({
            showTimeSelector: true
        })
    }


    onBlockSelectorChange(e) {
        e.persist();
        if (typeof this.props.blockAction === 'function') {
            this.props.blockAction(
                this.state.context.slotTriggeredStartTime, e.target.value, this.state.context
            ).then(() => {
                this.hide();
            }, () => {
                this.hide();
            });
        }
        else {
            this.hide();
        }
    }

    /**
     * Configure popover buttons before open
     * @param context
     */
    setContextData(context) {
        console.log('set context', context);

        this.setState({
            context: context,
            showTimeSelector: false,
            show: true
        });

    }

    hide() {
        this.hidePopover();
        this.setState({
            show: false
        });
    }

    //FIXME: Actually it is a hack, it needs to be fixed by react way!!

    hidePopover() {
        let popover = document.getElementById('scheduler-popover');
        popover && popover.parentNode.click();
    }

    editClientCommentAction() {
        this.props.editClientCommentAction(this.state.context.elementTriggered.salonClient);
    }

    render() {
        if (!this.state.show) return null;
        let context = this.state.context;
        let cnt;


        switch (context.triggeredCase) {
            case 'schedule-element':


                switch (context.elementTriggered.type) {
                    case JOURNAL_ELEMENT_APPOINTMENT:

                        let bookAnotherBtn = React.cloneElement(this.menuButtons.bookAnother, {
                            key: idGenerator('button')
                        });
                        let commentElement;
                        let editedClient = clientCommentStore.getClientById(context.elementTriggered.salonClient.id);
                        let comment = editedClient !== undefined ? editedClient.comment : context.elementTriggered.salonClient.comment;

                        if (comment.length) {
                            commentElement = <div>
                                <div className={"comment-title"}>Comment:
                                    <button className={"btn-link fr-fr"} onClick={this.editClientCommentAction}>
                                        Edit...
                                    </button>
                                </div>
                                <div className={"comment fr-text-overflow"}>{comment}</div>
                            </div>
                        }
                        else {
                            commentElement = <div>
                                <div className={"comment-title"}>No comment.
                                    <button className={"btn-link fr-fr"} onClick={this.editClientCommentAction}>Add
                                    </button>
                                </div>
                            </div>;
                        }


                        switch (true) {
                            case context.elementTriggeredStatus === SCHEDULE_ELEMENT_STATE_PAST:

                                cnt = (<div>
                                    <button className={"close"} onClick={this.hidePopover}><span>×</span></button>
                                    <div
                                        className="title fr-text-overflow">{context.elementTriggered.salonClient.firstName} {context.elementTriggered.salonClient.lastName}</div>
                                    <PhoneStaticMask phone={context.elementTriggered.salonClient.phone}/>
                                    {commentElement}
                                    <div>{context.elementTriggered.service.i18nKey}</div>
                                    <div className="action-btn--mb">{bookAnotherBtn}</div>
                                </div>);

                                break;

                            case context.elementTriggeredStatus === SCHEDULE_ELEMENT_STATE_IN_PROGRESS:
                            case context.elementTriggeredStatus === SCHEDULE_ELEMENT_STATE_SUSPENSE:
                                let noShowBtn = React.cloneElement(this.menuButtons.noshow, {
                                    key: idGenerator('button')
                                });
                            //fallthrough
                            case context.elementTriggeredStatus === SCHEDULE_ELEMENT_STATE_NO_SHOW:
                                //no show element didn't have no show button

                                cnt = (<div>
                                    <button className={"close"} onClick={this.hidePopover}><span>×</span></button>
                                    <div
                                        className="title fr-text-overflow">{context.elementTriggered.salonClient.firstName} {context.elementTriggered.salonClient.lastName}</div>
                                    <PhoneStaticMask phone={context.elementTriggered.salonClient.phone}/>
                                    <div>{context.elementTriggered.service.i18nKey}</div>
                                    {commentElement}
                                    {noShowBtn && <div className="action-btn--mb">{noShowBtn}</div>}
                                    <div>{bookAnotherBtn}</div>
                                </div>);

                                break;

                            default:
                                let salonClientId = context.elementTriggered.salonClientId;
                                let clientApts = context.salonClientIndex[salonClientId];
                                let futureclientApts = clientApts.filter(function (app) {
                                    return app.startTimestamp > Math.round((new Date()).valueOf() / 1000);
                                });

                                let editBtn = React.cloneElement(this.menuButtons.edit, {
                                    key: idGenerator('button')
                                });
                                let cancelBtn = React.cloneElement(this.menuButtons.cancel, {
                                    key: idGenerator('button')
                                });
                                let cancelAllBtn;
                                if (futureclientApts.length > 1) {
                                    cancelAllBtn = React.cloneElement(this.menuButtons.cancelAll, {
                                        key: idGenerator('button')
                                    });
                                }

                                cnt = (<div>
                                    <button className={"close"} onClick={this.hidePopover}><span>×</span></button>
                                    <div
                                        className=" title fr-text-overflow">{context.elementTriggered.salonClient.firstName} {context.elementTriggered.salonClient.lastName}</div>
                                    <PhoneStaticMask phone={context.elementTriggered.salonClient.phone}/>
                                    <div>{futureclientApts.map((apt, key) => (
                                        <div key={key}
                                             className={apt.id === context.elementTriggered.id ? 'service-bold' : ''}>
                                            <LocalTime timestamp={apt.startTimestamp}
                                                       tz={context.slotTriggeredTimezone}/>
                                            &nbsp;-&nbsp;<LocalTime timestamp={context.elementTriggered.endTimestamp}
                                                                    tz={context.slotTriggeredTimezone}/> {apt.service.title}
                                        </div>
                                    ))}</div>
                                    {commentElement}
                                    <Row className="btn-container">
                                        <div className="action-btn--mb pull-left">{editBtn}</div>
                                        <div className="action-btn--mb">{cancelBtn}</div>
                                    </Row>
                                    <div>{cancelAllBtn}</div>
                                    <div>{bookAnotherBtn}</div>
                                </div>);

                                break;
                        }


                        break;
                    case JOURNAL_ELEMENT_BLOCK:

                        let unblockBtn = React.cloneElement(this.menuButtons.unblock, {
                            key: idGenerator('button')
                        });

                        cnt = (<div>
                            <button className={"close"} onClick={this.hidePopover}><span>×</span></button>
                            <div className="title">Blocked</div>
                            <div>
                                <LocalTime timestamp={this.state.context.elementTriggered.startTimestamp}
                                           tz={this.state.context.slotTriggeredTimezone}/> - <LocalTime
                                timestamp={this.state.context.elementTriggered.endTimestamp}
                                tz={this.state.context.slotTriggeredTimezone}/>
                            </div>
                            <div className="action-btn--mb">{unblockBtn}</div>
                        </div>);

                        break;

                    default:
                        break;

                }

                break;

            case 'empty-slot':
                switch (context.slotTriggeredState) {
                    case SLOT_STATE_PAST:
                        cnt = (<div>
                            <button className={"close"} onClick={this.hidePopover}><span>×</span></button>
                            <div className={"title"}>A little too late</div>
                        </div>);
                        break;


                    default:
                    case SLOT_STATE_DEFAULT:
                    case SLOT_STATE_WH_SET:

                        let bookBtn = React.cloneElement(this.menuButtons.book, {
                            key: idGenerator('button')
                        });

                        let blockBtn = React.cloneElement(this.menuButtons.block, {
                            key: idGenerator('button')
                        });

                        let selector;
                        if (this.state.showTimeSelector) {
                            let options = [
                                <option disabled key={idGenerator('option')} value={-1}>Choose end time</option>
                            ];

                            let occupied = [];
                            if (context.elementsInColumn) {
                                Object.keys(context.elementsInColumn).forEach((elementKey) => {
                                    if (elementKey > context.slotTriggeredStepIndex) {
                                        let element = context.elementsInColumn[elementKey];
                                        let startTimeRel = element.startTime - context.midnight;
                                        occupied.push(startTimeRel);
                                        for (let i = startTimeRel; i < startTimeRel + element.duration; i = i + context.step) {
                                            occupied.push(i);
                                        }
                                    }
                                });
                            }

                            for (let i = context.slotTriggeredStartTime + context.step; i <= 24 * 60 * 60; i = i + context.step) {
                                if (occupied.indexOf(i) === -1) {

                                    let momentValue = moment.utc(context.startPointDate).add(i, 'seconds');

                                    options.push(<option key={idGenerator('option')} value={i}>
                                        {momentValue.format(config.defaultTimeFormat)}
                                    </option>);
                                }
                            }

                            selector = <select
                                value={-1}
                                placeholder="Choose end time"
                                key="select-1"
                                onChange={this.onBlockSelectorChange}
                            >{options}</select>;
                        }

                        let member = this.state.context.slotTriggeredMember;
                        cnt = (<div>
                            <button className={"close"} onClick={this.hidePopover}><span>×</span></button>
                            <div className="title fr-text-overflow ">{member.firstName} {member.lastName}</div>
                            <div><LocalTime
                                timestamp={this.state.context.midnight + this.state.context.slotTriggeredStartTime}
                                tz={this.state.context.slotTriggeredTimezone}/></div>
                            <div className="action-btn pull-left">{bookBtn}</div>
                            <div className="action-btn pull-right">{blockBtn}</div>
                            <div>{selector}</div>
                        </div>);

                        break;
                }
                break;

            default:
                break;
        }

        let rest = Object.assign({}, this.props);
        delete rest.bookAction;
        delete rest.bookAgainAction;
        delete rest.blockAction;
        delete rest.unblockAction;
        delete rest.editAction;
        delete rest.cancelAction;
        delete rest.cancelAllAction;
        delete rest.noShowAction;
        delete rest.editClientCommentAction;

        return (
            <Popover className="popover" {...rest}>
                <div>
                    {cnt}
                </div>
            </Popover>
        );
    }

}


FrizoBookPopover.defaultProps = {
    id: idGenerator('popover'),
    bookAction: () => {
    },
    blockAction: () => {
    },
    unblockAction: () => {
    },
    editAction: () => {
    },
    cancelAction: () => {
    },
    cancelAllAction: () => {
    },
    noShowAction: () => {
    },
    editClientCommentAction: () => {
    }
};

FrizoBookPopover.propTypes = {
    id: PropTypes.string,
    bookAction: PropTypes.func,
    blockAction: PropTypes.func,
    unblockAction: PropTypes.func,
    editAction: PropTypes.func,
    cancelAction: PropTypes.func,
    cancelAllAction: PropTypes.func,
    noShowAction: PropTypes.func,
    editClientCommentAction: PropTypes.func
};