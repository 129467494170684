/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 08.11.2018
 * Time: 12:15
 */

import { xhr } from "../service/api-client-2";
import { CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";

export function getPublicArtistDataByUrl(artistUrl) {
    return xhr()
    .suppressNotifications()
    .get("@Q2-42-Get-Public-Artist-Data-By-Url", { url: artistUrl })
    .headers([{
        name: CUSTOM_HEADER_U6_GROUPS,
        value: ["basic", "userSalon"]
    }])
    .end()
    .then((request: Request) => {
            let { response } = request;
            return Promise.resolve(response);
        },
        (request: Request) => {
            let { response } = request;
            return Promise.reject(response.errors);
        });
}
