/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 07.09.2017
 * Time: 17:42
 */

import React, { Component } from "react";
import { Link } from "react-router";
import { Col, Row } from "react-bootstrap";
import GoogleMapWithMarkers from "../maps/map-with-markers";
import config from "./../../config";
import { getAllSalonAddresses } from "../../ac/salon-addresses-actions";
import { salonAddressesStore } from "./../../stores/index";
import gradientFill from "../../helper/gradient-fill";
import imgSrc1 from "./../../../../img/frizo-how-it-works.png";
import imgSrc2 from "./../../../../img/frizo-appointment-book.png";
import imgSrc3 from "./../../../../img/frizo-app.png";
import imgSrc4 from "./../../../../img/frizo-pro.png";


export default class ProLandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salons: []
        };
        this.storeUpdate = this.storeUpdate.bind(this);
    }

    componentDidMount() {
        getAllSalonAddresses();
        salonAddressesStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        salonAddressesStore.removeChangeListener(this.storeUpdate);
    }

    storeUpdate() {
        this.setState(salonAddressesStore.getStore());
    }

    render() {
        let params = config.defaultFooter;

        return (
            <div className="fr-landing">
                <div className="fr-landing-flex">

                    <div className={"fr-landing-flex__item-wide"} style={{ "background": gradientFill() }}>
                        <Row className="fr-text-center">
                            <div className="fr-landing-flex__banner">
                                <h1>Why Frizo</h1>
                            </div>
                        </Row>
                    </div>

                    <div className={"fr-landing-flex__item-wide"}>
                        <div className={"fr-landing--content-box photo-background"}>
                            <div className="fr-landing-flex__item-title inverse first top-offset">We&nbsp;bring extra income
                                to&nbsp;beauty professionals
                            </div>
                            <div className="fr-title-centred fr-link-bottom">
                                <Link to={"/pricing"} className="btn fr-btn-big__green landing">List your
                                    business</Link>
                            </div>
                        </div>
                    </div>

                    <div className={"container"}>
                        <Row className={"row-flex"}>
                            <Col md={6} xs={12}>
                                <img src={imgSrc1} alt="How it works"/>
                            </Col>
                            <Col md={6} xs={12}>
                                <div className="fr-landing-flex__item">
                                    <div className="fr-landing-flex__item-title">How it&nbsp;works?</div>
                                    <div className="fr-title-centred">Let's talk business.
                                    </div>
                                    <div className="fr-title-centred fr-mt">
                                        <Link to={"/pricing"} className="btn fr-btn-big__green landing">List your
                                            business</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={"row-flex"}>
                            <Col xs={12} md={6} mdPush={6}>
                                <div className="fr-landing-flex__item">
                                    <img src={imgSrc2} alt="Anything else"/>
                                </div>
                            </Col>
                            <Col xs={12} md={6} mdPull={6}>
                                <div className="fr-landing-flex__item">
                                    <div className="fr-landing-flex__item-title">Anything else?</div>
                                    <div className={"fr-title-centred"}>Never pay for booking software again. Manage
                                        appointments, send reminders, collect payments. It&rsquo;s always free!
                                    </div>
                                    <div className="fr-title-centred fr-mt">
                                        <Link to={"/pricing"} className="btn fr-btn-big__green landing">List your
                                            business</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={"row-flex"}>
                            <Col md={6} xs={12}>
                                <img src={imgSrc3} alt="What do I have to do?"/>
                            </Col>
                            <Col md={6} xs={12}>
                                <div className="fr-landing-flex__item">
                                    <div className="fr-landing-flex__item-title">What do&nbsp;I have to&nbsp;do?</div>
                                    <div className="fr-title-centred">Get Frizo app.<br/>
                                        List your business under 7&nbsp;minutes.<br/>
                                        Have your best unmarked work imagery ready. You&rsquo;ll need&nbsp;it.
                                    </div>
                                    <div className="fr-title-centred fr-mt">
                                        <div className={"fr-mt"}>
                                            <a className="fr-iosicon large" href={params.appstore.url}
                                               target="_blank" rel="noopener noreferrer"
                                               title={params.appstore.title}
                                               alt={params.appstore.title}>
                                            </a>
                                            <a className="fr-google-icon large" href={params.googlePlay.url}
                                               target="_blank" rel="noopener noreferrer"
                                               title={params.googlePlay.title}
                                               alt={params.googlePlay.title}>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="fr-landing-flex__item-wide map blue">
                        <div className="fr-landing-flex__item-title map inverse">
                            Thousands of&nbsp;talented beauty pros nationwide already&nbsp;with&nbsp;us
                        </div>
                        <GoogleMapWithMarkers
                            lat={config.googleMap.USA_center_lat}
                            lon={config.googleMap.USA_center_lng}
                            locations={this.state.salons}
                            render={true}
                            zoom={5}
                            showCenterMarker={false}
                            height={"82vh"}
                        />
                    </div>
                    <div className={"container"}>
                        <Row className={"row-flex"}>
                            <Col xs={12} md={6} mdPush={6}>
                                <div className="fr-landing-flex__item">
                                    <img src={imgSrc4} alt="Frizo Pro"/>
                                </div>
                            </Col>
                            <Col xs={12} md={6} mdPull={6}>
                                <div className="fr-landing-flex__item">
                                    <div className="fr-landing-flex__item-title">Frizo Pro</div>
                                    <div className="fr-title-centred">Shop professional beauty supplies.</div>
                                    <div className="fr-title-centred fr-mt">
                                        <a href="https://frizopro.com/collections/all" className="btn fr-btn-big__green landing">Explore</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}
