/**
 * Created by Ignat Kryshchyk
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 23.07.2017
 * Time: 11:22
 */

import { LOAD_PRICING_PRODUCTS, SELECT_PLAN, SELECT_PRICING_PLAN } from "../constants";
import AppDispatcher from "../dispatcher";
import { xhrGetSubscriptionProducts } from "../xhr/subscription-plans-xhr";

export function selectPlan(plan) {
    AppDispatcher.dispatch({
        type: SELECT_PLAN,
        payload: plan
    });
}

export function getSubscriptionProducts(userAppType) {
    xhrGetSubscriptionProducts().then(data => {
        AppDispatcher.dispatch({
                type: LOAD_PRICING_PRODUCTS,
                payload: {
                    data: data,
                    userAppType: userAppType
                }
            }
        );
    });
}

export function selectPricingPlan(plan) {
    AppDispatcher.dispatch({
        type: SELECT_PRICING_PLAN,
        payload: plan
    });
}