/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.13.6
 * Time: 16:48
 */
import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { Link } from 'react-router';
import noPhoto from '../../../../../../img/frizo-no-photo-medium.png';
import PhoneInput from '../../../../elements/phone-input';


export default function ArtistStatic (props) {

    let createImageUrl = ()=>{
        return props.image.url || noPhoto;
    };
    let fullName=`${props.firstName} ${props.lastName}`;

    return(<Row className="fr-mb">
        <div className="fr-artist-form-title">
          <Link to="/my-salon/artists" className="fr-glyphicon" >
              <span className="glyphicon glyphicon-circle-arrow-left"></span>
          </Link>
          <div className="fr-profile__title">{props.formTitle}</div>
        </div>
        <Col md={12} xs={12}>
            <Row>
                <Col md={4} xs={12}>
                    <div className="fr-profile__title">Name</div>
                    <input className="form-control" readOnly={true} value={fullName} type="text"/>
                    <div className="fr-profile__title">Social</div>
                    <div>
                        <input className="form-control" readOnly={true} value={props.instagramUsername} type="text"
                               placeholder={"Instagram username"}
                        />
                    </div>
                    <div className="fr-profile__title">E-mail & phone</div>
                    <div>
                        <input className="form-control" readOnly={true} value={props.email} type="text"/>
                        <PhoneInput className="form-control fr-mt" type="text"
                                    value={props.phone}
                                    disabled={true}
                                    mask='(111) 111-1111'
                        />

                    </div>
                </Col>
                <Col md={2}/>
                <Col md={6} xs={12}>
                    <div className="fr-profile__title-photo-static">Artist's photo</div>
                    <img src={createImageUrl()} alt={createImageUrl()}/>
                </Col>
            </Row>
        </Col>
    </Row>);
}
