/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.2.17
 * Time: 17.12
 */

import Length, { VALUE_IS_TOO_LONG, VALUE_IS_TOO_SHORT } from "./length";
import violationBuilder from "./../violation-builder";
import sprintf from "./../../helper/sprintf";
import BaseValidator from "./base-validator";

export default class LengthValidator extends BaseValidator {

    static getValidConstraints() {
        return [Length];
    }

    validate(constraint, field, root = null) {
        if (!constraint instanceof Length) {
            throw new Error(sprintf("Length should be passed to LengthValidator.validate(). %s given.",
                constraint.constructor.name));
        }

        let exact = constraint.max === constraint.min;

        let value = String(root ? root[field] : field);

        let length = value.length;

        if (null !== constraint.max && length > constraint.max) {
            let message = exact ? constraint.exactMessage : constraint.maxMessage;

            return violationBuilder.build(message, {
                "{limit}": constraint.max
            }, field, root, VALUE_IS_TOO_LONG, value);
        }

        if (null !== constraint.min && length < constraint.min) {
            let message = exact ? constraint.exactMessage : constraint.minMessage;

            return violationBuilder.build(message, {
                "{limit}": constraint.min
            }, field, root, VALUE_IS_TOO_SHORT, value);
        }

        return true;
    }
}
