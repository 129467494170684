/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.01.2019
 * Time: 11:17
 */
import React, {Component} from 'react';
import {Modal, Button, Form, FormGroup} from 'react-bootstrap';
import {
    editClientAction,
} from '../../ac/clients';
import {modalHide} from "../../ac/modals";
import {clientCommentStore} from '../../stores/index';
import PropTypes from 'prop-types';
import gradientFill from './../../helper/gradient-fill';

export default class ClientCommentModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            comment: '',
            clientExist: false,
            btnDisabled: false
        };

        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.close = this.close.bind(this);
        this.storeUpdate = this.storeUpdate.bind(this);

    }

    componentDidMount() {
        clientCommentStore.addChangeListener(this.storeUpdate);
        let editedClient = clientCommentStore.getClientById(this.props.client.id);
        let comment = editedClient !== undefined ? editedClient.comment : this.props.client.comment;
        this.setState({comment: comment});
    }

    componentWillUnmount() {
        clientCommentStore.removeChangeListener(this.storeUpdate);
    }


    storeUpdate() {
        this.setState({
            client: clientCommentStore.getClient(),
            btnDisabled: clientCommentStore.getStore().btnDisabled
        });
    }

    handleCommentChange(e) {
        this.setState({
                comment: e.target.value
            }
        )
    }


    saveComment() {

        if (this.props.client.id) {
            this.setState({btnDisabled: true});
            editClientAction(this.props.client.id,
                {
                    firstName: this.props.client.firstName,
                    lastName: this.props.client.lastName,
                    phone: this.props.client.phone,
                    comment: this.state.comment
                });
        }
    }


    close() {
        modalHide();
    }

    render() {

        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2 className={"fr-text-overflow"}>Comment for {this.props.client.firstName} {this.props.client.lastName}</h2>
                </Modal.Header>
                <Modal.Body className="client-modal__body">
                    <Form>
                        <FormGroup>
                            <textarea className="form-control fr-textarea-as-input" type="text"
                                      value={this.state.comment || ''}
                                      placeholder="Comment"
                                      onChange={this.handleCommentChange}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="client-comment-modal__footer">
                    <Button className={"btn fr-btn pull-right"}
                            onClick={this.saveComment}
                            disabled={this.state.btnDisabled}>Save</Button>
                    <Button className="btn fr-btn fr-btn-remove pull-left"
                            onClick={this.close}
                            disabled={this.state.btnDisabled}>Cancel</Button>
                </Modal.Footer>
            </div>
        )
    }
}
ClientCommentModal.propTypes = {
    client: PropTypes.object
};

ClientCommentModal.defaultProps = {
    client: {
        id: 0,
        firstName: '',
        lastName: '',
        phone: '',
        comment: ''
    }
};