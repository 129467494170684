/**
 * User: Ignat Kryshchyk
 * Username: ignat
 * Company: U6 SIA
 * Date: 13.3.17
 * Time: 10.09
 */

import React, { Component } from "react";
import { Button, Row, Col, Glyphicon } from "react-bootstrap";
import { sessionStore, professionalProfileGalleryStore } from "../../../stores/index";
import { loadGallerySalonInfo } from "../../../ac/profile/professional/professional-profile-gallery-actions";
import {
    GALLERY2_PROFESSIONAL_PROFILE_GALLERY_ID,
    LIMIT_ADD_GALLERY_IMAGE,
    MODE_ARTIST_SELECTABLE,
    USER_TYPE_MASTER, USER_TYPE_SALON_OWNER, USER_TYPE_SALON_ADMIN, USER_TYPE_FREELANCER, USER_TYPE_SALON_MASTER
} from "../../../frizo-shared-constants";
import { browserHistory, Link } from "react-router";
import { xhrLoadSalonGallery } from "../../../xhr/gallery/load-salon-gallery-xhr";
import { createGallery } from "../../gallery2/gallery-factory";
import SimpleGalleryFullscreenLook from "../../gallery2/fullscreen-items/simple-gallery-fullscreen-look";
import ProfessionalProfileGalleryItemLook from "../../gallery2/items/professional-profile-gallery-item-look";
import GalleryFilter from "../../gallery-filter";
import { loadGalleryFilterData, onFilterChange, onFilterReset } from "../../../ac/gallery-filter-actions";
import notificationSystem from "../../../service/notification-system";
import GoUpButton from "../../../elements/go-up-button";
import LoaderBar from "../../../elements/loader-bar";


export default class ProfessionalProfileGallery extends Component {
    constructor(props) {
        super(props);

        let session = sessionStore.getSession();

        this.state = Object.assign({
            ready: false,
            granted: this.canAddImages(),
            isBusinessAccount: session.business,
            userType: session.rawUserType,
            salonInfo: {},
            isEmptyGallery: false,
            params: {
                artistId: null,
                categoryId: null,
                serviceId: null
            },

            artists: [],
            categories: [],
            services: [],

            artistsLoaded: false,
            servicesLoaded: false,

            haveImageService: false,
            haveArtists: false,
            galleryEmpty: true

        }, this.getStoreData());


        this.onGalleryStoreChange = this.onGalleryStoreChange.bind(this);
        this.onSessionStoreChange = this.onSessionStoreChange.bind(this);

        this.gallery = this.createGallery();

    }

    getStoreData() {
        let storeData = professionalProfileGalleryStore.getStore();

        return {
            salonInfo: storeData.gallery.params.salonInfo,
            params: storeData.gallery.params,
            isEmptyGallery: storeData.gallery.itemsCount === 0,

            artists: storeData.artists,
            categories: storeData.categories,
            services: storeData.services,
            haveImageService: storeData.haveImageService,
            haveArtists: storeData.haveArtists,
            haveServices: storeData.haveServices,
            artistsLoaded: storeData.artistsLoaded,
            servicesLoaded: storeData.servicesLoaded,
            galleryEmpty: storeData.galleryEmpty
        };
    }

    canAddImages() {
        let subscriptionTier = sessionStore.getSession().subscriptionTier;

        let granted = true;
        if (subscriptionTier && Array.isArray(subscriptionTier.limits)) {
            granted = !~subscriptionTier.limits.indexOf(LIMIT_ADD_GALLERY_IMAGE);
        }

        return granted;
    }

    onSessionStoreChange() {
        this.setState({
            granted: this.canAddImages()
        });
    }

    componentDidMount() {
        professionalProfileGalleryStore.addChangeListener(this.onGalleryStoreChange);
        sessionStore.addChangeListener(this.onSessionStoreChange);

        loadGallerySalonInfo();

    }

    componentWillUnmount() {
        professionalProfileGalleryStore.removeChangeListener(this.onGalleryStoreChange);
        sessionStore.removeChangeListener(this.onSessionStoreChange);
    }

    createGallery() {
        let message = <div><strong>Work imagery No-No's</strong><br/><br/>
            <Glyphicon className="glyphicon glyphicon-check" glyph="check"/> No collages<br/>
            <Glyphicon className="glyphicon glyphicon-check" glyph="check"/> No watermarks<br/>
            <Glyphicon className="glyphicon glyphicon-check" glyph="check"/> No product placement<br/> <br/>
            Click <Link to="/faq/for-professionals">FAQ</Link> for details <br/>
        </div>;

        return createGallery(GALLERY2_PROFESSIONAL_PROFILE_GALLERY_ID)
        .setGalleryStore(professionalProfileGalleryStore)
        .setFavoritesEnabled(false)
        .setNoItemsText(message)
        .setItemClass(ProfessionalProfileGalleryItemLook)
        .setFullscreenClass(SimpleGalleryFullscreenLook)
        .setGalleryLoader(xhrLoadSalonGallery);
    }


    onGalleryStoreChange() {
        let newState = Object.assign({}, this.getStoreData());

        if (!this.state.ready) {
            if (newState.salonInfo) {
                newState.ready = true;

                this.gallery.init();


                loadGalleryFilterData(
                    newState.salonInfo.id
                );
            }
        }

        if (!this.state.filterReady) {
            if (newState.artistsLoaded && newState.servicesLoaded) {
                newState.filterReady = true;
            }
        }


        this.setState(newState);

    }

    onChange(field, value) {
        onFilterChange(GALLERY2_PROFESSIONAL_PROFILE_GALLERY_ID, field, value);
    }

    onReset() {
        onFilterReset(GALLERY2_PROFESSIONAL_PROFILE_GALLERY_ID);
    }

    shopAddImageForbiddenPopup() {
        if (this.state.isBusinessAccount) {
            let s = [
                "<p class=\"title__p\">Subscription request</p>",
                "<p>Quality images is your best promotion.</p>",
                "<p>More images, better results.<br />Upload more images?</p>"
            ];
            notificationSystem.alert(s.join(""), "SUBSCRIBE NOW")
            .then(() => {
                browserHistory.push("/subscription");
            });
        }
        else {
            let s = [
                "<p class=\"title__p\">Subscription required</p>",
                "<p>Please ask your salon owner to subscribe.</p>"
            ];
            notificationSystem.alert(s.join(""), "OK");
        }
    }

    addBeforeAndAfterImages() {
        if (!this.state.granted) {
            this.shopAddImageForbiddenPopup.call(this);
        }
        else {
            browserHistory.push("/my-salon/gallery/add-before-and-after-images");
        }
    }

    addSingleImage() {
        if (!this.state.granted) {
            this.shopAddImageForbiddenPopup.call(this);
        }
        else {
            browserHistory.push("/my-salon/gallery/add-single-image");
        }
    }

    render() {
        if (!this.state.ready || !this.state.filterReady) {
            return <LoaderBar className="loader-msg-wrapper--full-height"/>;
        }

        if (this.state.userType === USER_TYPE_MASTER) {
            if (!this.state.haveServices) {
                return <div className={"profile-message"}>
                    Ask Salon admin to assign services
                </div>;
            }
            if (this.state.haveServices && !this.state.haveImageService) {
                return <div className={"profile-message"}>
                    Ask Salon admin to assign Hair, Makeup or Nails service
                </div>;
            }
        }

        if (this.state.userType === USER_TYPE_FREELANCER || (this.state.userType === USER_TYPE_SALON_MASTER)) {
            if (!this.state.haveServices) {
                return <div className={"profile-message"}>
                    Add <Link to={"/my-salon/services"}>Services</Link> first
                </div>;
            }
            if (this.state.haveServices && !this.state.haveImageService) {
                return <div className={"profile-message"}>
                    Add Hair, Makeup or Nails service
                </div>;
            }
        }

        if (this.state.userType === USER_TYPE_SALON_ADMIN || (this.state.userType === USER_TYPE_SALON_OWNER)) {
            if (!this.state.haveArtists) {
                return <div>Add <Link
                    to={"/my-salon/artists"}>Artists</Link> first</div>;
            }
            if (!this.state.haveServices) {
                return <div className={"profile-message"}>
                    Add <Link to={"/my-salon/services"}>Services</Link> first
                </div>;
            }

            if (this.state.haveServices && this.state.haveArtists && !this.state.haveImageService) {
                return <div className={"profile-message"}>
                    Assign Hair, Makeup or Nails service
                </div>;
            }
            if (this.state.haveServices && !this.state.haveImageService) {
                return <div className={"profile-message"}>
                    Add Hair, Makeup or Nails service
                </div>;
            }
        }

        if (this.state.salonInfo.isServicePaused) {
            return <div className={"profile-message"}>
                <div>Congrats! You're Google favorite<br/>
                    We sent you email (JIC check Junk).<br/>
                    Let's grow you business!
                </div>
                <div className={"fr-mt"}>
                    <Link className="fr-btn-add btn fr-btn" to="/my-salon/subscription">
                        Proceed
                    </Link>
                </div>
            </div>;
        }

        return (
            <div>
                <Row>
                    <Col md={5} xs={12}>
                        <Button className="fr-gallery-btn-add fr-mr"
                                onClick={this.addSingleImage.bind(this)}
                        >
                            Add image
                        </Button>
                        <Button className="fr-gallery-btn-add fr-fr-xs"
                                onClick={this.addBeforeAndAfterImages.bind(this)}
                        >
                            Add Before & After images
                        </Button>
                    </Col>
                    <Col md={7} xs={12}>
                        {(this.state.haveImageService && !this.state.galleryEmpty) &&
                        <GalleryFilter
                            showArtistSelector={this.state.params.mode === MODE_ARTIST_SELECTABLE}

                            artistId={this.state.params.artistId}
                            categoryId={this.state.params.categoryId}
                            serviceId={this.state.params.serviceId}

                            artists={this.state.artists}
                            categories={this.state.categories}
                            services={this.state.services}

                            onChange={this.onChange}
                            onReset={this.onReset}

                        />}
                    </Col>

                </Row>
                {this.gallery.getGallery()}
                <GoUpButton/>
            </div>
        );

    }
}
