/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 4.7.17
 * Time: 19.44
 */

import BaseStore from "../../base-store";
import {
    COMMON_MODAL_CLEAR,
    COMMON_MODAL_SAVE,
    CREATE_PAYMENT_PROFILE_MODAL,
    MODAL_CHANGE,
    MODAL_HIDE,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_FORM_UPDATE,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_SAVE_FAIL
} from "../../../constants";
import PaymentProfileSyncer from "./../../../sync/profile/professional/payment-profile-syncer";
import Validator from "./../../../validation/validator";
import moment from "moment";
import config from "./../../../config";
import {
    FORM_MODE_CREATE,
    LEGAL_ENTITY_TYPE_COMPANY,
    LEGAL_ENTITY_TYPE_INDIVIDUAL
} from "./../../../frizo-shared-constants";
import Filtration from "./../../../filter/filtration";
import TrimFilter from "./../../../filter/trim-filter";

export default class PaymentProfileFormStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case MODAL_CHANGE:
                    if (payload.type === CREATE_PAYMENT_PROFILE_MODAL) {
                        this.store.active = true;
                        this.store.mode = payload.options.mode;

                        if (this.store.mode === FORM_MODE_CREATE) {
                            this.copyDataFromSession();
                        }
                    }
                    break;

                case MODAL_HIDE:
                    this.store.active = false;
                    break;

                case COMMON_MODAL_SAVE:
                    if (!this.store.active) return true;
                    this.filterStore();
                    this.buildValidation(this.store.legalEntityType);

                    if (this.validate()) {

                        let syncer = new PaymentProfileSyncer();
                        syncer.setMode(this.store.mode);
                        syncer.putFormData(this.store);
                        syncer.persist();
                    }

                    break;

                case COMMON_MODAL_CLEAR:
                    if (!this.store.active) return true;
                    this.resetStore();
                    break;

                case PROFILE_PROFESSIONAL_PAYMENT_PROFILE_FORM_UPDATE:
                    Object.assign(this.store, payload);
                    Object.keys(payload).forEach(propName => {
                        delete this.store.violations[propName];
                    });

                    break;

                case PROFILE_PROFESSIONAL_PAYMENT_PROFILE_SAVE_FAIL:
                    this.store.violations = payload.violations;
                    break;

                //load payment profile
                case PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED:

                    if (payload.type === "custom") {
                        let accountType = payload.business_type;

                        let legalEntity = payload[accountType];


                        this.store.legalEntityType = accountType;


                        if (accountType === LEGAL_ENTITY_TYPE_INDIVIDUAL) {
                            this.store.firstName = legalEntity.first_name;
                            this.store.lastName = legalEntity.last_name;
                            legalEntity.dob.month = legalEntity.dob.month - 1; //correction  month from stripe (stripe month 1-12)
                            this.store.dateOfBirth = moment(legalEntity.dob).format(config.defaultDateFormat);
                            this.store.dateOfBirthText = moment(legalEntity.dob).format(config.defaultDateTextFormat);
                            this.store.ssnLast4Provided = legalEntity.ssn_last_4_provided;
                        }
                        if (accountType === LEGAL_ENTITY_TYPE_COMPANY) {
                            let person = payload.persons ? payload.persons.data[0] : null;
                            if (person) {
                                this.store.firstName = person.first_name;
                                this.store.lastName = person.last_name;
                                person.dob.month = person.dob.month - 1; //correction  month from stripe (stripe month 1-12)
                                this.store.dateOfBirth = moment(person.dob).format(config.defaultDateFormat);
                                this.store.dateOfBirthText = moment(person.dob)
                                .format(config.defaultDateTextFormat);
                                this.store.ssnLast4Provided = person.ssn_last_4_provided;
                            }
                        }
                        this.store.businessName = payload.business_profile.name;
                        this.store.businessUrl = payload.business_profile.url || "";

                        this.store.address = legalEntity.address.line1;
                        this.store.zip = legalEntity.address.postal_code;
                        this.store.city = legalEntity.address.city;
                        this.store.state = legalEntity.address.state;


                        this.store.verification = payload.requirements;
                        this.store.fieldsNeeded = payload.requirements.currently_due;

                        if (this.store.ssnLast4Provided) {
                            this.store.ssnLast4 = this.getAsteriskString(4);
                        }

                        if (legalEntity.business_tax_id_provided) {
                            this.store.businessTaxId = this.getAsteriskString(9);
                        }

                        if (legalEntity.personal_id_number_provided) {
                            this.store.personalIdNumber = this.getAsteriskString(4);
                        }

                        break;
                    }

                    return true;

                default:
                    return true;
            }

            this.emitChange();
        });

        this.copyDataFromSession = this.copyDataFromSession.bind(this);
    }

    getInitialStore() {
        return {
            legalEntityType: LEGAL_ENTITY_TYPE_INDIVIDUAL,
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            dateOfBirthText: "",
            ssnLast4: "",
            businessName: "",
            businessTaxId: "",
            businessUrl: "",
            address: "",
            zip: "",
            city: "",
            state: "",
            verification: {},
            legalDocument: null,
            fieldsNeeded: {},
            violations: {},
            active: this.store ? this.store.active : false,
            ssnLast4Provided: false,
            mode: FORM_MODE_CREATE
        };
    }

    copyDataFromSession() {
        let data = this.stores.session.getSession();
        if (!this.store.firstName) this.store.firstName = data.firstName;
        if (!this.store.lastName) this.store.lastName = data.lastName;
        if (data.business && data.salon) {

            let salon = data.salon;
            this.store.businessName = salon.title;
            this.store.businessUrl = window.location.protocol + "//" + window.location.hostname + "/promo/" + salon.url;

            if (salon.addresses && salon.addresses.length > 0) {
                let address = salon.addresses[0];

                this.store.address = address.address;
                this.store.city = address.city;
                this.store.state = address.state;
                this.store.zip = address.zip;
            }

        }
    }

    getAsteriskString(count) {
        let s = "";

        //bullet
        let char = String.fromCharCode(8226);
        //let char = '0';

        for (let i = 0; i < count; i++) {
            s += char;
        }

        return s;
    }


    /**
     * @protected
     */
    buildValidation(legalEntityType) {
        this.validator = new Validator();

        this.validator.addConstraint("legalEntityType", "is-required");
        this.validator.addConstraint("firstName", "is-required");
        this.validator.addConstraint("lastName", "is-required");
        this.validator.addConstraint("dateOfBirth", "is-required");
        this.validator.addConstraint("dateOfBirth", "is-date", {
            inPastOnly: true,
            breakOnError1: true
        });
        this.validator.addConstraint("dateOfBirth", "is-older-than", {
            value: 13,
            unit: "year"
        });
        this.validator.addConstraint("ssnLast4", "is-required");
        this.validator.addConstraint("address", "is-required");

        this.validator.addConstraint("zip", "is-required");
        this.validator.addConstraint("city", "is-required", {
            message: "Enter an existing zip"
        });
        this.validator.addConstraint("businessUrl", "is-required");
        this.validator.addConstraint("businessUrl", "is-url");

        if (legalEntityType === LEGAL_ENTITY_TYPE_COMPANY) {
            this.validator.addConstraint("businessName", "is-required");
            this.validator.addConstraint("businessTaxId", "is-required");
        }
    }

    /**
     * @protected
     */
    filterStore() {
        const filtration = new Filtration();
        const trimFilter = new TrimFilter();

        filtration.registerFilter("firstName", trimFilter)
        .registerFilter("lastName", trimFilter)
        .registerFilter("dateOfBirth", trimFilter)
        .registerFilter("ssnLast4", trimFilter)
        .registerFilter("businessName", trimFilter)
        .registerFilter("businessTaxId", trimFilter)
        .registerFilter("businessUrl", trimFilter)
        .registerFilter("address", trimFilter)
        .registerFilter("zip", trimFilter)
        .registerFilter("city", trimFilter)
        .registerFilter("state", trimFilter);

        this.store = filtration.filtrate(this.store);
    }
}
