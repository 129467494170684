/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 04.04.2017
 * Time: 16:11
 */
import React, { Component } from "react";
import { loadAppointments } from "../../ac/salon-apt";
import { salonAptFilterStore, salonAptStore } from "../../stores/index";
import AppointmentsList from "./appointments-list";
import { Pagination } from "react-bootstrap";
import { PAGINATION_ITEMS_COUNT } from "../../frizo-shared-constants";

export default class HistoryAppointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            currentPageNumber: 1,
            aptTotalCount: 0,
            searchString: ""
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.filterStoreChange = this.filterStoreChange.bind(this);
        this.fetchAppointments = this.fetchAppointments.bind(this);
        this.storeChange = this.storeChange.bind(this);
    }

    componentDidMount() {
        let params = {
            tab: "history",
            limit: PAGINATION_ITEMS_COUNT,
            offset: 0,
            combinedSearch: salonAptFilterStore.store.filter
        };
        this.fetchAppointments(params);
        salonAptStore.addChangeListener(this.storeChange);
        salonAptFilterStore.addChangeListener(this.filterStoreChange);
    }

    componentWillUnmount() {
        salonAptStore.removeChangeListener(this.storeChange);
        salonAptFilterStore.removeChangeListener(this.filterStoreChange);
    }

    storeChange() {
        this.setState({
            appointments: salonAptStore.store.appointments,
            aptTotalCount: salonAptStore.store.aptTotalCount
        });
    }

    handleSelect(eventKey) {
        let params = {
            "tab": "history",
            "limit": PAGINATION_ITEMS_COUNT,
            "offset": eventKey - 1,
            "combinedSearch": this.state.searchString
        };

        this.setState({
            currentPageNumber: eventKey
        });
        this.fetchAppointments(params);

    }

    filterStoreChange() {
        let filter = salonAptFilterStore.store.filter;

        this.setState({
            searchString: filter,
            currentPageNumber: 1
        });
        let params = {
            "tab": "history",
            "limit": PAGINATION_ITEMS_COUNT,
            "offset": 0,
            "combinedSearch": filter
        };
        this.fetchAppointments(params);
    }

    fetchAppointments(params) {
        loadAppointments(params);
    }

    render() {
        let totalPages = Math.ceil(this.state.aptTotalCount / PAGINATION_ITEMS_COUNT);
        let maxVisibleButtons = (window.innerWidth < 769 ? 3 : 10);
        return (
            <div>
                <AppointmentsList appointments={this.state.appointments} aptList="History"
                                  aptsCount={this.state.aptTotalCount}/>
                <Pagination
                    className="fr-pagination-standard"
                    bsSize="medium"
                    items={totalPages > 1 ? totalPages : 0}
                    activePage={totalPages > 0 ? this.state.currentPageNumber : 0}
                    onSelect={this.handleSelect}
                    maxButtons={maxVisibleButtons} first={totalPages > 1 ? "<<" : null}
                    last={totalPages > 1 ? ">>" : null} next={totalPages > 1 ? ">" : null}
                    prev={totalPages > 1 ? "<" : null}
                    ellipsis={totalPages > 1 ? "..." : null}
                />
            </div>);
    }
}