/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.6.17
 * Time: 7.08
 *
 * @flow
 */

import { JOURNAL_ELEMENT_APPOINTMENT, TYPE_APPOINTMENT } from "./../frizo-shared-constants";
import type { TRawApt } from "./raw-types";
import { serviceNormalize } from "./service";
import type { TApt } from "../types";
import { salonNormalizer1 } from "./salon";
import { salonClientNormalize } from "./user-derived-forms/salon-client";
import { addressNormalize1 } from "./address";
import { artistNormalizer1 } from "./user-derived-forms/artist";

export function appointmentNormalize(rawApt: TRawApt): TApt {

    // console.log("appointmentNormalize", rawApt);

    let salon = salonNormalizer1(rawApt.salon);
    let service = serviceNormalize(rawApt.service);
    let salonClient = salonClientNormalize(rawApt.salonClient);
    let address = addressNormalize1(rawApt.address);
    let artist = artistNormalizer1(rawApt.masterUser);
    let confirmation = {
        channel: rawApt.confirmationChannel,
        issued: rawApt.confirmationIssued,
        issuedChannel: rawApt.confirmationIssuedChannel,
        issuedTimestamp: rawApt.confirmationIssuedTimestamp,
        confirmationTimestamp: rawApt.confirmationTimestamp,
        confirmed: rawApt.confirmed,
        timezone: address.timezone
    };

    return {
        entityType: TYPE_APPOINTMENT,
        id: rawApt.id,
        type: JOURNAL_ELEMENT_APPOINTMENT,

        startTimestamp: rawApt.startTimestamp,
        endTimestamp: rawApt.procInterval.endTimestamp,
        processing: rawApt.procInterval.endTimestamp - rawApt.startTimestamp,
        durationEndTimestamp: rawApt.durInterval.endTimestamp,
        duration: rawApt.durInterval.endTimestamp - rawApt.startTimestamp,

        artistId: Number(rawApt.masterUserId),
        artist: artist,

        clientId: rawApt.userId,

        localDate: rawApt.localDate,

        timezone: address.timezone,
        addressId: address.id,
        address: address,

        status: rawApt.status,
        paymentStatus: rawApt.paymentStatus,

        salonId: salon.id,
        salonName: salon.title,
        salon: salon,

        salonClient: salonClient,
        salonClientId: salonClient.id,

        serviceId: service.id,
        serviceName: service.title,
        service: service,

        confirmation: confirmation
    };
}
