/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.11.17
 * Time: 13.29
 *
 * @flow
 */

import type { TGalleryImage, TImage, TServiceBase, TUserBase } from "../../../types";
import { GALLERY_ITEM_TYPE_LOOK, TYPE_GALLERY_IMAGE, TYPE_SERVICE, TYPE_USER } from "../../../frizo-shared-constants";
import { salonNormalizer1 } from "../../salon";
import type { TRawSalon1 } from "../../raw-types";

export type TRawUser = {
    id: number,
    firstName: string,
    lastName: string,
    shareUrl: string,
    salon: Array<TRawSalon1>,
    airtouchCertified?: number,
    airtouchCertUrl?: string
};

export type TRawService = {
    id: number,
    parent: {
        id: number,
        parent: number,
        i18nKey: string
    },
    i18nKey: string
};

export type TRawSalonService = {
    id: number,
    service: TRawService
};

export type TRawLook = {
    id: number,
    priceLevel: number,
    instagramMention: any,
    salonService: TRawSalonService,
    image: TImage,
    imageBefore: TImage,
    user: TRawUser,
    attribute: Array<{
        id: number,
        i18nKey: string,
        parent: {
            id: number,
            i18nKey: string,
            parent: number
        }
    }>,
    shareUrl: string,
    salon?: Array<TRawSalon1>
};

function fetchService(rawSalonService: TRawSalonService): TServiceBase {
    return {
        entityType: TYPE_SERVICE,
        id: rawSalonService.service.id,
        title: rawSalonService.service.i18nKey
    };
}

function normalizeUser(rawUser: TRawUser): TUserBase {
    return {
        entityType: TYPE_USER,
        id: rawUser.id,
        firstName: rawUser.firstName,
        lastName: rawUser.lastName,
        airtouchCertified: rawUser.airtouchCertified,
        airtouchCertUrl: rawUser.airtouchCertUrl
    };
}

export function singleLookNormalizer(look: TRawLook): TGalleryImage {
    console.log("singleLookNormalizer", look);

    let object: TGalleryImage = {
        id: look.id,
        entityType: TYPE_GALLERY_IMAGE,
        galleryItemType: GALLERY_ITEM_TYPE_LOOK,
        image: look.image,
        imageBefore: look.imageBefore,
        shareUrl: look.shareUrl,
        artist: normalizeUser(look.user),
        service: fetchService(look.salonService),
        priceLevel: look.priceLevel
    };

    if (look.user.salon && look.user.salon.length > 0) {
        object.salon = salonNormalizer1(look.user.salon[0]);
    }

    return object;
}
