/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 11.4.17
 * Time: 6.52
 */

import ApiClient from "../service/api-client";

export default function xhrLoadSalonArtists(
    salonId, hasGalleryItem = 0, groups = ["basic", "schedule", "contact", "salonInfo"], publicOnly = 0) {

    const url = ApiClient.getRouting().generate("Q34_5_salon_artists", { salonId });

    return ApiClient.get(url, { hasGalleryItem, publicOnly }, {
        headers: [{
            name: "U6-groups",
            value: groups
        }]
    }).then((answer) => {
        let [artists] = answer;

        return Promise.resolve(artists);
    });
}
