/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 3/31/17
 * Time: 17:33
 */

import IsPassword from "./is-password";
import IsSalonName from "./is-salon-name";
import IsEmail from "./is-email";
import IsName from "./is-name";
import IsPhone from "./is-phone";
import violationBuilder from "./../violation-builder";
import BaseValidator from "./base-validator";
import IsUrl from "./is-url-validator";

export default class RegexValidator extends BaseValidator {

    static getValidConstraints() {
        return [IsPassword, IsSalonName, IsEmail, IsName, IsPhone, IsUrl];
    }

    validate(constraint, field, root = null) {

        let value = root ? root[field] : field;

        let regexp = constraint.regex;

        if (!regexp.test(value)) {
            return violationBuilder.build(constraint.message, {}, field, root, constraint.errorCode, value);
        }

        return true;
    }
}
