/**
 * Created by fliak on 25.1.17.
 */

import {
    APPOINTMENT_ADD,
    APPOINTMENT_CHANGE,
    APPOINTMENT_REMOVE,
    BOOK_APPOINTMENTS_LOAD_SUCCESS,
    BOOK_LOAD_SALON_INFO_SUCCESS,
    BOOK_LOAD_TIMELINE_SUCCESS,
    BOOK_PERSIST_FAIL,
    BOOK_PERSIST_SUCCESS,
    BOOK_START_BOOKING,
    LOAD_SERVICES_SUCCESS
} from "../../constants";
import BaseStore from "./../base-store";
import xhrLoadTimeline from "../../xhr/book-timeline-xhr";
import { timelineLoaded } from "../../ac/book-apt/data-load-actions";
import moment from "moment";

import { JOURNAL_ELEMENT_APPOINTMENT, JOURNAL_ELEMENT_BLOCK, MODE_CLIENT } from "./../../frizo-shared-constants";
import {
    DROPDOWN_PLACEHOLDER_VALUE,
    TIME_INTERVAL_BLOCK,
    TIME_INTERVAL_DURATION,
    TIME_INTERVAL_PROCESSING
} from "../../frizo-shared-constants";

const MINUTES_IN_SECONDS_15 = 900;
const SECONDS_IN_DAY = 60 * 60 * 24;

const TIMELINE_PERSISTED = "persisted";
const TIMELINE_DIRTY = "dirty";

export default class BookTimelineStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case BOOK_START_BOOKING:
                    this.resetStore();
                    this.store.startBooking = true;
                    this.store.bookConfig = payload;
                    this.setReady(false);

                    return true;

                case BOOK_PERSIST_SUCCESS:
                case BOOK_PERSIST_FAIL:
                    this.store.startBooking = false;
                    break;

                case BOOK_LOAD_SALON_INFO_SUCCESS:

                    if (!this.store.startBooking) return true;

                    this.waitFor(this.stores.artist.getDispatchToken());

                    let date = this.store.bookConfig.date;

                    let timeLineLoader = xhrLoadTimeline(date);

                    //let salonId = this.store.bookConfig.salonId;
                    let colleagues = this.stores.artist.getStore().artists;

                    colleagues.forEach((artist) => {
                        //let schedule = this.stores.artist.getArtistScheduleForDate(artist.id, date);

                        timeLineLoader.addArtist(artist.id);
                    });

                    timeLineLoader.then(answer => {
                        timelineLoaded(answer.midnightTimestamp, answer.artistIds, answer.timelineSet);
                    });

                    break;

                case BOOK_LOAD_TIMELINE_SUCCESS:

                    payload.artistIds.forEach(artistId => {
                        this.markAsLoaded(payload.midnightTimestamp, artistId);
                    });

                    console.log("timelineSet", payload.timelineSet);

                    Object.keys(payload.timelineSet).forEach(artistId => {
                        payload.timelineSet[artistId].busyIntervals.forEach(interval => {
                            this.putOrigin(payload.midnightTimestamp, artistId, interval);
                        });
                    });

                    if (this.stores.services.isReady()) {
                        this.buildTimeline();
                        this.buildDirtyTimeline();

                        this.setReady(true);
                    }

                    break;

                case LOAD_SERVICES_SUCCESS:
                    this.waitFor(this.stores.services.getDispatchToken());

                    if (!this.isReady()) {
                        this.buildTimeline();
                        this.buildDirtyTimeline();

                        this.setReady(true);
                    }

                    break;


                case APPOINTMENT_ADD:
                    this.waitFor(this.stores.apt.getDispatchToken());

                    if (this.stores.services.isReady()) {
                        if (payload.artistId) this.processChange(payload.id, payload);
                    }

                    break;


                case APPOINTMENT_CHANGE:
                    this.waitFor(this.stores.apt.getDispatchToken());

                    this.processChange(payload.id, payload.patch);

                    break;

                case APPOINTMENT_REMOVE:
                    this.buildDirtyTimeline();

                    break;

                case  BOOK_APPOINTMENTS_LOAD_SUCCESS:
                    this.waitFor(this.stores.apt.getDispatchToken());

                    this.store.dirtyApts = [];

                    const appointments = this.stores.apt.store.appointments;
                    appointments.forEach(apt => {
                        this.processChange(apt.id, apt);
                        this.store.inProcess.appointments.add(apt.id);
                    });

                    break;

                default:
                    return true;

            }


            this.emitChange();
        });

    }

    getInitialStore() {
        return {
            bookConfig: {},

            inProcess: {
                artists: new Set(),
                midnightTimestamps: new Set(),
                appointments: new Set()
            },

            registry: Object.create(null),
            dirtyRegistry: Object.create(null)

        };
    }

    /**
     * @protected
     */
    putOrigin(midnightTimestamp, artistId, normalizedElement) {

        this.ensureRegistrySlot(this.store.registry, midnightTimestamp, artistId);

        this.store.registry[midnightTimestamp][artistId].origin.push(normalizedElement);

    }

    setTimelineProperty(registry, midnightTimestamp, artistId, time, properties) {
        this.ensureRegistrySlot(registry, midnightTimestamp, artistId, time);

        Object.assign(registry[midnightTimestamp][artistId].timeline[time], properties);
    }

    /**
     * @protected
     */
    putTimeline(registry, midnightTimestamp, artistId, time, element) {

        this.ensureRegistrySlot(registry, midnightTimestamp, artistId, time);

        let slot = registry[midnightTimestamp][artistId].timeline[time];
        if (!slot.elements) {
            slot.elements = [];
        }

        slot.elements.push(element);
    }

    /**
     * @protected
     */
    ensureRegistrySlot(registry, midnightTimestamp, artistId, time = undefined) {
        if (registry[midnightTimestamp] === undefined) {
            registry[midnightTimestamp] = Object.create(null);
        }
        if (registry[midnightTimestamp][artistId] === undefined) {

            let initial = Object.create(null);
            initial.timeline = Object.create(null);
            initial.dirtyTimeline = Object.create(null);

            initial.origin = [];

            registry[midnightTimestamp][artistId] = initial;
        }

        if (time !== undefined && registry[midnightTimestamp][artistId].timeline[time] === undefined) {
            registry[midnightTimestamp][artistId].timeline[time] = {
                time: moment.utc((midnightTimestamp + time) * 1000).format("HH:mm"),
                time2: moment.utc((midnightTimestamp + time) * 1000).format("LLLL")
            };
        }

    }

    /**
     * @protected
     */
    markAsLoaded(midnightTimestamp, artistId) {
        this.store.inProcess.artists.add(artistId);
        this.store.inProcess.midnightTimestamps.add(midnightTimestamp);
    }

    /**
     * @protected
     */
    isLoaded(midnightTimestamp, artistId) {
        return this.store.inProcess.artists.has(artistId) &&
            this.store.inProcess.midnightTimestamps.has(midnightTimestamp);
    }

    /**
     * @protected
     */
    buildTimeline() {
        this.store.inProcess.midnightTimestamps.forEach(midnightTimestamp => {
            let date = moment.utc(midnightTimestamp * 1000).format("YYYY-MM-DD");

            this.store.inProcess.artists.forEach(artistId => {
                let artistMidnight = this.stores.artist.getArtistMidnight(artistId, date);
                let now = Math.round((new Date()).valueOf() / 1000);
                let artistHorizon = now - artistMidnight;

                if (artistHorizon < 0) artistHorizon = 0;

                if (artistHorizon < SECONDS_IN_DAY - MINUTES_IN_SECONDS_15) {
                    let schedule = this.stores.artist.getArtistScheduleForDate(artistId, midnightTimestamp);

                    let i = SECONDS_IN_DAY - MINUTES_IN_SECONDS_15;
                    do {
                        let wh = schedule.dayOff ? false : i >= schedule.from && i < schedule.to;
                        this.setTimelineProperty(this.store.registry, midnightTimestamp, artistId, i, {
                            wh: wh,
                            timestamp: artistMidnight + i
                        });

                        i -= MINUTES_IN_SECONDS_15;

                    }
                    while (i >= artistHorizon);
                }

                let origin = [];
                if (this.store.registry[midnightTimestamp]) {
                    if (this.store.registry[midnightTimestamp][artistId]) {
                        origin = this.store.registry[midnightTimestamp][artistId].origin || [];
                    }
                }

                origin.forEach(element => {

                    switch (element.type) {
                        case TIME_INTERVAL_BLOCK:
                        case TIME_INTERVAL_PROCESSING:
                            for (let i = element.start; i < element.end; i += MINUTES_IN_SECONDS_15) {
                                let coercedIterator = i - artistMidnight;
                                this.putTimeline(this.store.registry, midnightTimestamp, artistId, coercedIterator, {
                                    busyForClient: true,
                                    busyForArtist: true,
                                    id: element.id,
                                    type: element.type,
                                    origin: element,
                                    dirty: false
                                });
                            }

                            break;

                        case TIME_INTERVAL_DURATION:
                            for (let i = element.start; i < element.end; i += MINUTES_IN_SECONDS_15) {
                                let coercedIterator = i - artistMidnight;
                                this.putTimeline(this.store.registry, midnightTimestamp, artistId, coercedIterator, {
                                    busyForClient: true,
                                    busyForArtist: false,
                                    id: element.id,
                                    type: element.type,
                                    origin: element,
                                    dirty: false
                                });
                            }

                            break;

                        default:
                            console.warn("element", element);
                            throw new Error(`Unknown time interval type "${element.type}"`);

                    }
                });


            });

        });

        this.setReady(true);
    }

    isAptTimelineReady(apt) {
        if (!apt || !apt.artistId || !apt.serviceId || !apt.addressId || !apt.date) {
            return false;
        }

        let service = this.stores.services.getArtistService(apt.artistId, apt.serviceId);
        if (!service || !service.durationValue) {
            return false;
        }

        return true;
    }

    buildDirtyTimeline(midnightTimestamp = null, artistId = null) {
        let apts;

        if (!midnightTimestamp) {
            apts = this.stores.apt.getAppointments();
        }
        else {
            apts = this.stores.apt.filterAppointments(midnightTimestamp, artistId);
        }

        let registry = {};

        apts.forEach(element => {

            if (!this.isAptTimelineReady(element) || element.startTime === DROPDOWN_PLACEHOLDER_VALUE) {
                console.warn("Skip apt from dirty timeline as not completed", element);

                return true;
            }

            let midnightTimestamp = convertDateToUTCTimestamp(element.date);

            this.ensureRegistrySlot(registry, midnightTimestamp, element.artistId);

            let service = this.stores.services.getArtistService(element.artistId, element.serviceId);

            let durationEnd = element.startTime + service.durationValue;

            let processingEnd;
            if (element.processingTime) {
                processingEnd = element.startTime + element.processingTime;
            }
            else {
                processingEnd = durationEnd;
            }

            //consider start-startTime, duration = serviceStore.get().duration;

            for (let i = element.startTime; i < durationEnd; i += MINUTES_IN_SECONDS_15) {
                this.putTimeline(registry, midnightTimestamp, element.artistId, i, {
                    busyForClient: true,
                    busyForArtist: i < processingEnd,
                    id: element.id,
                    type: TIME_INTERVAL_PROCESSING,
                    origin: element,
                    dirty: true
                });
            }
        });

        for (let midnightTimestamp in registry) {
            for (let artistId in registry[midnightTimestamp]) {
                this.ensureRegistrySlot(this.store.registry, midnightTimestamp, artistId);

                this.store.registry[midnightTimestamp][artistId].dirtyTimeline = registry[midnightTimestamp][artistId].timeline;
            }
        }

    }


    /**
     * @protected
     */
    processChange(aptId, patch) {
        let apt = this.stores.apt.getByAptId(aptId);
        let tempAssign = Object.assign({}, apt, patch);

        let date = tempAssign.date;
        let timestamp = convertDateToUTCTimestamp(date);

        console.log("[TIMELINE] processChange", aptId, patch, date);

        switch (true) {
            case "artistId" in patch:
            case "date" in patch:

                let artistId = tempAssign.artistId;

                if (!this.isLoaded(timestamp, artistId)) {
                    //preload

                    let timeLineLoader = xhrLoadTimeline(date);

                    timeLineLoader.addArtist(artistId);

                    timeLineLoader.then(answer => {
                        console.log("--Timeline loaded", answer);

                        timelineLoaded(answer.midnightTimestamp, answer.artistIds, answer.timelineSet);
                    });


                }

                break;

            default:
                console.log("Changes ignored");
        }

        this.buildDirtyTimeline(timestamp, apt.artistId);
    }

    getRegistryFor(apt) {
        let dateTimestamp = convertDateToUTCTimestamp(apt.date);

        if (!this.store.registry[dateTimestamp]) {
            console.warn("Time line missing for: ", this.store.registry, dateTimestamp, apt);
            return null;
        }
        if (!this.store.registry[dateTimestamp][apt.artistId]) {
            console.warn("Time line missing for: ", this.store.registry, dateTimestamp, apt);
            return null;
        }

        return this.store.registry[dateTimestamp][apt.artistId];
    }

    getAvailableProcessingFor(apt) {

        let intervals = [];

        if (typeof apt !== "object") {
            apt = this.stores.apt.getByAptId(apt);
        }

        if (!this.isAptTimelineReady(apt)) {
            return intervals;
        }

        let service = this.stores.services.getArtistService(apt.artistId, apt.serviceId);

        let registry = this.getRegistryFor(apt);
        if (!registry) return intervals;


        let duration = service.durationValue;

        let start = apt.startTime + MINUTES_IN_SECONDS_15;

        for (let iterator = start; iterator <= apt.startTime + duration; iterator += MINUTES_IN_SECONDS_15) {

            let timeSlot = registry.timeline[iterator];

            //check for timeslot existence added to avoid under horizon calculations
            if (timeSlot) {

                intervals.push(iterator - apt.startTime);
                if (this.isFreeForProfessional(apt.id, timeSlot, TIMELINE_PERSISTED)) {
                    let dirtyTimeSlot = registry.dirtyTimeline[iterator];

                    if (dirtyTimeSlot) {
                        if (!this.isFreeForProfessional(apt.id, dirtyTimeSlot, TIMELINE_DIRTY)) {
                            break;
                        }
                    }
                }
                else {
                    break;
                }
            }
        }

        return intervals;
    }

    getAvailableTimesForApt(aptId) {

        let apt = this.stores.apt.getByAptId(aptId);

        /**
         * Validate apt for completeness
         */
        if (!apt || !apt.artistId || !apt.addressId) return new Set();

        let registry = this.getRegistryFor(apt);
        if (!registry) return new Set();

        console.log("timeline", this.dumpTimeline(registry.timeline));
        console.log("dirty-timeline", this.dumpTimeline(registry.dirtyTimeline));

        if (this.store.bookConfig.mode === MODE_CLIENT) {

            let midnightTimestamp = convertDateToUTCTimestamp(apt.date);
            let wh = this.stores.artist.getArtistScheduleForDate(apt.artistId, midnightTimestamp);

            if (wh.addressId !== apt.addressId) {
                return new Set();
            }

            return this.mergeClientAvailableTime(apt, registry.timeline, registry.dirtyTimeline);
        }
        else {
            return this.mergeProfessionalAvailableTime(apt, registry.timeline, registry.dirtyTimeline);
        }
    }

    /**
     * FIXME: For debug only
     * @param timeline
     * @returns {string}
     */
    dumpTimeline(timeline) {

        let s = "";
        Object.keys(timeline).forEach(time => {
            let slot = timeline[time];

            if (slot.elements) {
                s += slot.time;

                slot.elements && slot.elements.forEach(el => {
                    if (el.busyForArtist) {
                        s += "(" + el.id + ")";
                    }
                    else if (el.busyForClient) {
                        s += "[" + el.id + "]";
                    }

                });

                s += ", ";
            }
        });

        return s;
    }

    mergeProfessionalAvailableTime(apt, timeline, dirtyTimeline) {
        let available = new Set();

        let now = Math.floor(Date.now() / 1000);

        for (let iterator in timeline) {

            let time = Number(iterator);
            let timelineElement = timeline[time];

            if (timelineElement.timestamp > now) { //check for past
                if (this.isFreeForProfessional(apt.id, timelineElement, TIMELINE_PERSISTED)) {
                    let free3 = dirtyTimeline[time] ? this.isFreeForProfessional(apt.id,
                        dirtyTimeline[time],
                        TIMELINE_DIRTY) : true;
                    if (free3) {
                        available.add(time);
                    }
                }
            }
        }

        return available;
    }

    /**
     *
     * @param apt
     * @param timeline
     * @param dirtyTimeline
     * @private
     */
    mergeClientAvailableTime(apt, timeline, dirtyTimeline) {
        let available = new Set();

        if (!this.isAptTimelineReady(apt)) {
            return available;
        }

        let service = this.stores.services.getArtistService(apt.artistId, apt.serviceId);

        let duration = service.durationValue;

        let durationInSteps = duration / MINUTES_IN_SECONDS_15;

        let range = [];

        let rangeTerminator = (range) => {
            //range of free slots discontinued
            //check is enough for this service
            if (range.length >= durationInSteps) {
                //fit
                let startSlotsCount = range.length - durationInSteps + 1;

                //save slots to result array (commit range)
                for (let i = 0; i < startSlotsCount; i++) {
                    available.add(range[i]);
                }
            }
        };

        for (let iterator in timeline) {

            let time = Number(iterator);

            if (timeline[time].wh) {  //free1
                if (this.isFreeForClient(apt.id, timeline[time])) {  //free2
                    if (!dirtyTimeline[time] || this.isFreeForClient(apt.id, dirtyTimeline[time])) { //free3
                        //timeslot is free, add it to range
                        range.push(time);

                        continue;
                    }
                }
            }

            rangeTerminator(range);

            //reset range always
            range = [];
        }

        if (range.length > 0) {
            rangeTerminator(range);
        }

        return available;
    }

    /**
     *
     * @private
     * @param aptId
     * @param timelineElement
     * @returns {boolean}
     */
    isFreeForClient(aptId, timelineElement) {

        if (Array.isArray(timelineElement.elements)) {
            for (let i = 0; i < timelineElement.elements.length; i++) {

                let element = timelineElement.elements[i];

                if (element.type === TIME_INTERVAL_BLOCK) {
                    return false;
                }

                if (element.busyForClient) {

                    let isDirty = this.store.inProcess.appointments.has(element.id);

                    if (!isDirty && element.id !== aptId) {

                        return false;
                    }
                }
            }
        }

        return true;
    }

    /**
     *
     * @private
     * @param aptId
     * @param timelineElement
     * @param timelineType
     * @returns {boolean}
     */
    isFreeForProfessional(aptId, timelineElement, timelineType = TIMELINE_PERSISTED) {

        if (Array.isArray(timelineElement.elements)) {
            for (let i = 0; i < timelineElement.elements.length; i++) {

                let element = timelineElement.elements[i];

                if (element.type === TIME_INTERVAL_BLOCK) {
                    return false;
                }

                if (element.type === TIME_INTERVAL_PROCESSING) {
                    if (element.busyForArtist) {

                        let isDirty = false;

                        if (timelineType === TIMELINE_PERSISTED) {
                            isDirty = this.store.inProcess.appointments.has(element.id);
                        }

                        if (!isDirty && element.id !== aptId) {
                            return false;
                        }
                    }

                }
            }
        }

        return true;
    }

    correctPatch(apt, aptPatch) {
        let correctPatch = {};

        let tempApt = Object.assign({}, apt, aptPatch);

        let available = this.getAvailableProcessingFor(tempApt);

        if (available.length > 0) {
            let maxAvailable = available[available.length - 1];
            if (maxAvailable < tempApt.processingTime) {
                correctPatch.processingTime = maxAvailable;

                console.warn("Correction made");
            }
            else {
                console.log("Correction not needed");
            }
        }
        else {
            console.warn("Empty available set return", apt, aptPatch);
        }

        return correctPatch;
    }
}

export function getUTCMidnight(timestamp) {
    return moment.utc(timestamp * 1000).startOf("day").unix();
}

export function convertDateToUTCTimestamp(dateString) {
    return Date.parse(dateString) / 1000;
}

export function normalizeApt(apt) {

    return {
        type: JOURNAL_ELEMENT_APPOINTMENT,
        id: apt.id,
        start: apt.startTimestamp,
        duration: apt.durInterval.endTimestamp - apt.startTimestamp,
        processing: apt.procInterval.endTimestamp - apt.startTimestamp,
        artistId: apt.masterUserId,
        clientId: apt.userId
    };
}

export function normalizeBlock(block) {

    return {
        type: JOURNAL_ELEMENT_BLOCK,
        id: block.id,
        start: block.startTimestamp,
        duration: block.endTimestamp - block.startTimestamp,
        artistId: block.userId
    };
}
