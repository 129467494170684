/**
 * User: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 22.3.17
 * Time: 17.13
 */

import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import MapDirections from './../maps/map-directions';
import AddressItem from './components/address-item';

import {loadSalonLocation} from './../../ac/salon-promo-action';
import {salonPromoStore} from './../../stores/index';
import config from '../../config';
import marker from '../../../../img/map-pin.svg';
import {denormalizeCoor} from "../../normal-form/coor";
import {loadPromoInitials} from "../../ac/salon-promo-action";

export default class SalonPromoLocation extends Component {

    constructor(props) {
        super(props);

        loadSalonLocation(props.params.url);

        this.state = {
            userTempAddress: '',
            userAddress: '',
            salonAddress: null,
            showViolation: false,
        }
        ;
        this.salonAddressList = [];

        this.selectUserAddress = this.selectUserAddress.bind(this);
        this.selectSalonAddress = this.selectSalonAddress.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.handleMapDirectionsStatus = this.handleMapDirectionsStatus.bind(this);
    }

    onLoad() {
        let store = salonPromoStore.getStore();
        this.salonAddressList = store.location.data;
        this.setState({
            salonAddress: this.salonAddressList[0]
        });
    }

    componentDidMount() {
        salonPromoStore.addChangeListener(this.onLoad);
        loadPromoInitials(this.props.params.url);
    }

    componentWillUnmount() {
        salonPromoStore.removeChangeListener(this.onLoad);
    }

    selectSalonAddress(address) {
        let userAddress = this.state.userTempAddress;
        if (userAddress) {
            this.setState({
                salonAddress: address,
                userAddress: userAddress
            });
        }
    }

    selectUserAddress(e) {
        this.setState({
            userTempAddress: e.target.value
        })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.setState({
                userAddress: e.target.value
            });
        }
    }

    handleMapDirectionsStatus() {
        this.setState({userAddress: ''});
    }

    render() {
        let userTempAddress = this.state.userTempAddress;
        let salonAddress = this.state.salonAddress;
        let markers = this.salonAddressList.map((address, i) => {
            return {
                position: denormalizeCoor({
                    lat: address.lat,
                    lon: address.lon,
                }),
                key: i,
                animation: 2,
                icon: marker,
            };
        });
        let params = config.googleMap;
        let mapCenter = {lat: params.NY_lat, lon: params.NY_lng};
        if (markers.length) {
            //markers is a gmap oriented object, so use TGmapCoor type
            mapCenter = {lat: markers[0].position.lat, lon: markers[0].position.lng};
        }

        return (
            <div className="row">
                <div className="form-horizontal fr-map-wide">
                    <div className="fr-promo-location">
                        <div className="fr-promo-location-adr">
                            <Col xs={12}>
                                <input name="start_point" className="form-control fr-mb" type="text"
                                       placeholder="Input your current location" onChange={this.selectUserAddress}
                                       onKeyPress={this.handleKeyPress} value={userTempAddress}/>
                            </Col>

                            {this.salonAddressList.map((address, i) => {
                                return <AddressItem key={i} address={address}
                                                    disabled={this.state.userTempAddress.length < 3 ? true : false}
                                                    onSalonAddressSelect={this.selectSalonAddress}/>
                            })}
                        </div>
                    </div>
                    <MapDirections userAddress={this.state.userAddress} salonAddress={salonAddress}
                                   markers={markers}
                                   mapCenter={mapCenter}
                                   statusChange={this.handleMapDirectionsStatus}
                    />
                </div>
            </div>
        )
    }
}
