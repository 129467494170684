/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.9.6
 * Time: 14:06
 */

import GalleryForm, { ARTIST_REQUIRED, SERVICE_REQUIRED } from "./gallery-form";
import BaseValidator from "./base-validator";
import violationBuilder from "./../violation-builder";
import sprintf from "./../../helper/sprintf";

export default class GalleryFormValidator extends BaseValidator {

    static getValidConstraints() {
        return [GalleryForm];
    }

    validate(constraint, field, root = null) {

        if (!(constraint instanceof GalleryForm)) {
            throw new Error(sprintf("GalleryForm should be passed to GalleryForm.validate(). %s given.",
                constraint.constructor.name));
        }
        let value = root ? root[field] : field;


        if (!value["artistId"]) {
            return violationBuilder.build(constraint.messageNoArtist, {}, field, root, ARTIST_REQUIRED, value);
        }

        if (!value["serviceId"]) {
            return violationBuilder.build(constraint.messageNoCategory, {}, field, root, SERVICE_REQUIRED, value);
        }

        if (!value["salonServiceId"]) {
            return violationBuilder.build(constraint.messageNoService, {}, field, root, SERVICE_REQUIRED, value);
        }

        return true;
    }

    checkAllAttributes(attributes, selectedAttributes) {
        let attributesCategories = {};

        attributes.forEach(attribute => {
            attributesCategories[attribute.parent.i18nKey] = attribute.parent;
        });

        return (Object.keys(attributesCategories).length === Object.keys(selectedAttributes).length) || false;
    }
}
