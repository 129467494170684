/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.4.17
 * Time: 21.09
 */

import AppDispatcher from "../../../dispatcher";
import {
    PROFESSIONAL_PROFILE_LOAD_SALON_INFO_SUCCESS,
    PROFESSIONAL_PROFILE_WH_SYNC_COMPLETE,
    PROFESSIONAL_PROFILE_WH_SYNC_ERROR
} from "../../../constants";
import { sessionStore } from "./../../../stores/index";
import xhrLoadSalonInfoByArtist from "../../../xhr/salon-info-xhr";
import { notifySuccess } from "./../../notify-actions";

export function whSyncComplete(result) {

    AppDispatcher.dispatch({
        type: PROFESSIONAL_PROFILE_WH_SYNC_COMPLETE,
        payload: {}
    });

    xhrLoadSalonInfoByArtist(sessionStore.getUserId()).then(salonInfo => {
        AppDispatcher.dispatch({
            type: PROFESSIONAL_PROFILE_LOAD_SALON_INFO_SUCCESS,
            payload: salonInfo
        });
    });

    notifySuccess();

}

export function whSyncError(report) {

    AppDispatcher.dispatch({
        type: PROFESSIONAL_PROFILE_WH_SYNC_ERROR,
        payload: {
            report
        }
    });

}

