/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.1.18
 */

import { GALLERY2_SALON_PROMO_BRANDS_ID } from "../../frizo-shared-constants";
import AbstractGalleryStore from "../gallery2/abstract-gallery-store";
import { GALLERY_LOAD, GALLERY_RESET } from "../../constants";
import { loadGallery } from "../../ac/gallery/generic-gallery-actions";

export default class PromoBrandStore extends AbstractGalleryStore {

    constructor(...args) {
        super(GALLERY2_SALON_PROMO_BRANDS_ID, ...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case GALLERY_LOAD:
                    this.store.gallery.params.salonId = payload.salonId;

                    //fall to super
                    return true;

                case GALLERY_RESET:
                    this.resetGallery({
                        offset: 0,
                        count: 12,
                        salonId: this.store.gallery.params.salonId
                    });
                    break;


                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialGallery() {
        return Object.assign(super.getInitialGallery(), {
            totalIsKnown: false,
            params: {
                offset: 0,
                count: 12,
                salonId: null
            }
        });
    }

    nextPage() {
        this.store.gallery.params.offset++;
        this.store.gallery.loading = true;
        this.store.gallery.scrollMode = true;

        return loadGallery(this.galleryId, this.store.gallery.params);
    }
}
