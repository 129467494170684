/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.5.10
 * Time: 15:10
 */
import RegistrationStore from "./registration-store";

export default class ClientSignUpStore extends RegistrationStore {

    /**
     * @protected
     */
    getInitialStore() {
        this.store = {
            userName: "",
            firstName: "",
            lastName: "",
            genderID: "",
            zip: "",
            address: "",
            phone: "",
            password: "",
            confirmPassword: "",
            referralCode: "",
            formIsValid: true,
            violations: {},
            showRecaptchaError: false,
            formDisabled: false
        };
    }
}
