/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.7.17
 * Time: 16.25
 */

import BaseStore from "../../base-store";
import {
    PROFILE_PROFESSIONAL_CHARGES_LOADED,
    PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ERROR,
    PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR,
    PROFILE_PROFESSIONAL_PAYMENT_DATA_COMPLETION_REPORT_LOADED,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_PERSONS_LOADED,
    PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
    PROFILE_PROFESSIONAL_TRANSACTIONS_LOADED,
    SALON_SUBSCRIPTION_CANCEL_ERROR,
    SALON_SUBSCRIPTION_CANCELED
} from "../../../constants";

export default class PaymentProfileStore extends BaseStore {
    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR:
                case PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ERROR:
                case SALON_SUBSCRIPTION_CANCEL_ERROR:
                    this.store.violations = payload;
                    this.store.btnDisabled = false;

                    break;
                case PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED:
                case SALON_SUBSCRIPTION_CANCELED:

                    let sources = payload.sources ? payload.sources.data.sort((a, b) => {
                        return Number(a.last4) - Number(b.last4);
                    }) : [];

                    this.store.subscriptionCustomer = {
                        sources: sources,
                        defaultSourceId: payload.default_source
                    };
                    this.store.subscriptionPlan =  payload.subscriptions ? payload.subscriptions.data[0] :[];
                    this.store.btnDisabled = false;
                    break;

                case PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED:

                    if (payload.type === "custom") {
                        if (payload.external_accounts && payload.external_accounts.data) {
                            this.store.externalAccounts = payload.external_accounts.data.map(account => {
                                return account;
                            });
                        }
                        this.store.fieldsNeeded = payload.requirements.currently_due;
                    }

                    this.store.profileExist = !!payload.id;

                    break;

                case PROFILE_PROFESSIONAL_PAYMENT_PROFILE_PERSONS_LOADED:
                    this.store.persons = payload.data;

                    break;
                case PROFILE_PROFESSIONAL_PAYMENT_DATA_COMPLETION_REPORT_LOADED:

                    this.store.profileExist = payload.accountExists;
                    this.store.verified = payload.verificationStatus === "verified";
                    this.store.status = payload.verificationStatus;


                    break;
                case PROFILE_PROFESSIONAL_CHARGES_LOADED:

                    this.store.subscriptionCharges = {
                        sources: payload.data,
                        count: payload.count

                    };
                    break;

                case PROFILE_PROFESSIONAL_TRANSACTIONS_LOADED:

                    this.store.transactions = {
                        sources: payload.data,
                        count: payload.count
                    };
                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialStore() {
        return {
            ready: false,
            profileExist: false,
            verified: false,
            status: null,
            externalAccounts: [],
            verification: {
                errors: {},
                disabledReason: null,
                dueBy: null,
                fieldsNeeded: []
            },

            subscriptionCustomer: {
                sources: [],
                defaultSourceId: null
            },
            subscriptionPlan: [],
            subscriptionCharges: [],
            transactions: [],
            violations: {},
            persons: []

        };
    }
}
