/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.19.4
 * Time: 00:41
 */
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { ServiceListCreator, updateIndex } from "../../professional/services/helpers";
import TableToggle from "../services/components/table-toggle";
import TableRowArtist from "./components/table-row-artist";

export default class ArtistServices extends Component {
    constructor(props) {
        super(props);
        this.createLayout = this.createLayout.bind(this);
    }


    makeServicesHip(listOfLeafs, leafsIndex) {
        let servicesIds = new Set();
        let services = [];
        const listCreator = new ServiceListCreator(this.props.services);
        listOfLeafs.forEach(leaf => {
            let branch = listCreator.createParentsBranch(this.props.services, leaf.id);
            branch.forEach(id => {
                servicesIds.add(id);
            });
        });

        servicesIds.forEach((id) => {
            if (leafsIndex[id]) {
                services.push(Object.assign(this.props.servicesIndex[id], leafsIndex[id]));
            }
            else {
                services.push(this.props.servicesIndex[id]);
            }
        });

        return services;
    }

    createLayout(data) {
        let self = this;
        return data.map((service, index) => {
            if (Array.isArray(service.children)) {
                let first = self.props.showFirst;
                if (index === 0) {
                    first = self.props.showFirst;
                }
                else {
                    first = false;
                }

                let commonServices = [];
                service.children.forEach(function(service, i) {
                    if (self.props.artistServicesIndex[service.id]) {
                        commonServices.push(service);
                    }
                });

                return <TableToggle first={first} key={service.id} name={service.i18nKey}
                                    count={commonServices.length}>
                    {self.createLayout(service.children)}
                </TableToggle>;
            }
            else {
                let assigned = false;
                let staticView = false;
                let individualPrice = "";
                if (self.props.artistServicesIndex[service.id]) {
                    assigned = true;
                    staticView = true;
                    individualPrice = this.props.artistServicesIndex[service.id].price || "";
                }

                return <TableRowArtist key={service.id}
                                       {...service}
                                       actions={!self.props.disableActions}
                                       artistId={self.props.artistId}
                                       assigned={assigned}
                                       staticView={staticView}
                                       individualPrice={individualPrice}
                                       onRemove={self.props.onRemove}
                                       onAssign={self.props.onAssign}
                                       onModify={self.props.onModify}
                                       onEdit={self.props.onEdit}
                />;
            }
        });
    }

    isReady() {
        return (this.props.salonServices.length && this.props.artistId);
    }

    render() {
        if (this.isReady()) {
            let salonServices = this.props.salonServices;
            let salonServicesIndex = this.props.salonServicesIndex;

            if (this.props.master) {
                salonServices = this.props.salonServices.filter(item =>
                    this.props.artistServicesIndex[item.id]
                );

                salonServicesIndex = updateIndex(salonServices);
            }

            if (this.props.master && !this.props.artistServices.length) {
                return <div className={"profile-message"}>
                    Ask Salon admin to assign services
                </div>;
            }

            const hip = this.makeServicesHip(salonServices, salonServicesIndex);

            const serviceCreator = new ServiceListCreator(hip);
            const tree = serviceCreator.createTree();

            if (this.props.disableActions) {
                return <div>
                    <Row className={"fr-list-header bordered"}>
                        <Col sm={6} xs={6}>{"Services"}</Col>
                        <Col sm={2} xs={3} className="fr-serv-price-title">{"Regular price"}</Col>
                        <Col sm={2} xs={3} className="fr-serv-price-title">{"Individual price"}</Col>
                    </Row>
                    {this.createLayout(tree)}
                </div>;
            }

            return <div>
                <Row className={"fr-list-header bordered"}>
                    <Col xs={8} md={6} sm={5}>{"Services"}</Col>
                    <Col xsHidden={true} sm={2} md={2} className="fr-serv-price-title">{"Regular price"}</Col>
                    <Col xsHidden={true} sm={2} md={2} className="fr-serv-price-title">{"Individual price"}</Col>
                    <Col xsHidden={true} sm={3} md={2}>{"Action"}</Col>
                </Row>
                {this.createLayout(tree)}
            </div>;
        }

        return null;
    }
}

ArtistServices.defaultProps = {
    showFirst: false,
    disableActions: false
};