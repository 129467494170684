/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 13.29
 */


import React, {Component} from 'react';
import GalleryImageTitle from "../../gallery/elements/gallery-image-title";
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type {TGalleryImage} from "../../../types";
import {FavoriteMark} from "../../favorites/favorite-mark";
import {
    BOOK_APPOINTMENT_NEW,
    BUTTON_DANGER_TRASH, BUTTON_DEFAULT, DROPDOWN_PLACEHOLDER_VALUE, GALLERY_ITEM_TYPE_LOOK, IMAGE_SIZE_MEDIUM,
    MODE_CLIENT
} from "../../../frizo-shared-constants";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import GalleryItemButton from "../gallery-parts/gallery-item-button";
import {removeItemAction} from "../../../ac/profile/personal/personal-favorites-actions";
import {startBooking} from "../../../ac/book-apt/common-actions";

import moment from "moment/moment";
import {imageUrl} from "../../../helper/image-url";

type Props = TGalleryImage & {};

export default class PersonalProfileFavoritesLook extends Component<Props, any> {


    bookAction(favorite) {
        //console.log('favorite', favorite);

        startBooking(
            MODE_CLIENT,
            favorite.artist.id,
            favorite.salon.id,
            moment(new Date()).format('YYYY-MM-DD'),
            DROPDOWN_PLACEHOLDER_VALUE,
            favorite.service.id,
            favorite.image.id,
            imageUrl(favorite.image.url),
            0,
            null,
            BOOK_APPOINTMENT_NEW
        );
    }


    render() {

        return <GalleryItemContainer>
            {this.props.favoritesEnabled && <FavoriteMark id={this.props.id} type={GALLERY_ITEM_TYPE_LOOK} /> }
            {this.props.imageBefore &&  <div className="fr-double-photo" onClick={this.props.onClick}></div>}

            <GalleryItemImage {...this.props} size={IMAGE_SIZE_MEDIUM} />
            <GalleryItemCardInfo>
                <GalleryImageTitle
                    serviceName = {this.props.service.title}
                    firstName = {this.props.artist.firstName}
                    lastName = {this.props.artist.lastName}
                />

                <GalleryItemButtonsContainer>
                    <GalleryItemButton title="Book" type={BUTTON_DEFAULT} onClick={this.bookAction.bind(this, this.props)} />
                    <GalleryItemButton type={BUTTON_DANGER_TRASH} onClick={removeItemAction.bind(this, this.props.id)} />

                </GalleryItemButtonsContainer>

            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}

PersonalProfileFavoritesLook.defaultProps = {
    favoritesEnabled: false
};
