/**
 * Created by Alexey Ilasov
 * Company: U6 SIA
 * Date: 30.01.2017.
 */

import React, {Component} from 'react';
import {Col, FormGroup} from 'react-bootstrap';
// import BookImage from 'book-image-view';

export  default class BookNoEdit extends Component {

    createClient() {
        if (this.props.showClientForm) {
            return <div>Client</div>
        }
        return null;
    }

    render() {

        let client = this.createClient();

        return (
            <div>
                <div className="clearfix">
                    <div className="fr-book__title">
                        {`Service ${this.props.serviceNum}`}
                    </div>
                </div>
                <FormGroup>
                    <Col md={4}>
                        {client}
                    </Col>
                    <Col md={4}>
                        <div>{this.props.artistName}</div>
                        <div>{this.props.serviceName}</div>
                    </Col>
                    <Col md={4}>
                        <div>{`${this.props.date} ${this.props.time}`}</div>
                    </Col>
                </FormGroup>
            </div>
        );
    }
}
