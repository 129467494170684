/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 01.01.2019
 * Time: 23.19
 */

import { xhr } from "../../../service/api-client-2";

export function xhrGetDiscountSolution(paymentOrderId) {

    return xhr()
    .suppressNotifications()
    .get("@Q2-51-Get-Discount-Solution", {
        paymentOrderId
    })
    .end()
    .then((request: Request) => {
            let { response } = request;
            return Promise.resolve(response);
        },
        (request: Request) => {
            let { response } = request;
            return Promise.reject(response.errors);
        });
}
