/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.09.2018
 * Time: 12:50
 */

import { LOAD_ALL_SALON_ADDRESSES } from "../constants";
import BaseStore from "./base-store";
import { salonNormalizer2 } from "./../normal-form/salon";

class SalonAddressesStore extends BaseStore {
    constructor(...args) {
        super(...args);
        this.store = {
            salons: []
        };

        this._registerActionSubscription((action) => {
                const { type, payload } = action;

                switch (type) {


                    case LOAD_ALL_SALON_ADDRESSES:

                        let salons = payload.data.map(salon => salonNormalizer2((salon)));

                        this.store.salons = salons;

                        break;

                    default:
                        return true;
                }

                this.emitChange();
            }
        );
    }
}

export default SalonAddressesStore;
