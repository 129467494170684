/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.10.17
 */

import GreaterThan, { VALUE_IS_TO_LOW } from "./is-greater-than";
import GreaterThanOrEqual from "./is-greater-than-or-equal";
import LessThan, { VALUE_IS_TO_HIGH } from "./is-less-than";
import LessThanOrEqual from "./is-less-than-or-equal";

import violationBuilder from "./../violation-builder";
import BaseValidator from "./base-validator";

export default class NumberComparisonValidator extends BaseValidator {

    static getValidConstraints() {
        return [GreaterThan, GreaterThanOrEqual, LessThan, LessThanOrEqual];
    }

    validate(constraint, field, root = null) {

        this.assertConstraintIsAllowed(constraint);

        let value = root ? root[field] : field;

        let isValid;
        switch (true) {
            case constraint instanceof GreaterThan:
                isValid = value > constraint.value;
                if (!isValid) {
                    return violationBuilder.build(constraint.message, {
                        "{value}": constraint.value
                    }, field, root, VALUE_IS_TO_LOW, value);
                }

                break;

            case constraint instanceof GreaterThanOrEqual:
                isValid = value >= constraint.value;
                if (!isValid) {
                    return violationBuilder.build(constraint.message, {
                        "{value}": constraint.value
                    }, field, root, VALUE_IS_TO_LOW, value);
                }

                break;

            case constraint instanceof LessThan:
                isValid = value < constraint.value;
                if (!isValid) {
                    return violationBuilder.build(constraint.message, {
                        "{value}": constraint.value
                    }, field, root, VALUE_IS_TO_HIGH, value);
                }

                break;

            case constraint instanceof LessThanOrEqual:
                isValid = value <= constraint.value;
                if (!isValid) {
                    return violationBuilder.build(constraint.message, {
                        "{value}": constraint.value
                    }, field, root, VALUE_IS_TO_HIGH, value);
                }

                break;

            default:
                throw new Error("Unknown constraint given to NumberComparisonValidator");
        }

        return true;
    }
}
