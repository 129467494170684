/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 4.7.17
 * Time: 19.23
 */

import AppDispatcher from "./../../../dispatcher";
import {
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_DOCUMENT_UPLOADED,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_FORM_UPDATE,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_SAVE_FAIL
} from "../../../constants";

import { xhrZipResolve } from "./../../../xhr/my-salon-xhr";

export function formDataChanged(field, value) {
    let payload = {
        [field]: value
    };

    if (field === "zip") {
        if (value.length >= 5) {
            xhrZipResolve(value).then(parsedLocation => {
                AppDispatcher.dispatch({
                    type: PROFILE_PROFESSIONAL_PAYMENT_PROFILE_FORM_UPDATE,
                    payload: {
                        city: parsedLocation.city,
                        state: parsedLocation.state
                    }
                });
            });
        }

        payload.city = "";
        payload.state = "";
    }


    AppDispatcher.dispatch({
        type: PROFILE_PROFESSIONAL_PAYMENT_PROFILE_FORM_UPDATE,
        payload
    });
}

export function paymentProfileSaveSuccess(response) {
    AppDispatcher.dispatch({
        type: PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED,
        payload: response
    });
}

export function paymentProfileSaveFail(response) {
    AppDispatcher.dispatch({
        type: PROFILE_PROFESSIONAL_PAYMENT_PROFILE_SAVE_FAIL,
        payload: {
            violations: {
                general: [response.message]
            }
        }
    });
}

export function paymentProfileUploadDocumentSuccess(response) {
    AppDispatcher.dispatch({
        type: PROFILE_PROFESSIONAL_PAYMENT_PROFILE_DOCUMENT_UPLOADED,
        payload: response
    });
}