/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.8.11
 * Time: 11:50
 *
 *@flow
 *
 */

import BaseStore from "../../base-store";

import {
    PROFILE_PERSONAL_EMAIL_CHANGE_ERROR,
    PROFILE_PERSONAL_EMAIL_CHANGE_SUCCESS,
    PROFILE_PERSONAL_EMAIL_FORM_CHANGE,
    PROFILE_PERSONAL_EMAIL_FORM_CLEAR,
    PROFILE_PERSONAL_EMAIL_FORM_PROCESS
} from "../../../constants";

import Validator from "../../../validation/validator";

import Filtration from "../../../filter/filtration";
import TrimFilter from "../../../filter/trim-filter";

import { sendNewEmailData } from "../../../ac/profile/personal/email-change-form-actions";


type TStore = {
    newEmail: string,
    password: string,
    showMessage: boolean,
    showError: boolean,
    formIsValid: boolean,
    violations: {
        newEmail: string,
        password: string
    }
};

export default class ChangeEmailStore extends BaseStore {

    store: TStore;

    constructor(...args: any) {
        super(...args);

        this._registerActionSubscription((action) => {

            let { type, payload } = action;

            switch (type) {

                case PROFILE_PERSONAL_EMAIL_FORM_CHANGE:

                    this.store = Object.assign(this.store, {
                            [payload.name]: payload.value,
                            showError: false,
                            showMessage: false,
                            formIsValid: true
                        }
                    );

                    break;

                case PROFILE_PERSONAL_EMAIL_FORM_PROCESS:

                    this.filtrate();

                    if (this.validate()) {
                        sendNewEmailData({
                            newEmail: this.store.newEmail,
                            password: this.store.password
                        });

                        return true;

                    }
                    else {
                        this.store.formIsValid = false;
                    }

                    break;

                case PROFILE_PERSONAL_EMAIL_FORM_CLEAR:

                    this.resetStore();

                    break;

                case PROFILE_PERSONAL_EMAIL_CHANGE_SUCCESS:

                    this.onSuccess();

                    break;

                case PROFILE_PERSONAL_EMAIL_CHANGE_ERROR:

                    this.onError();

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    buildValidation(): void {
        this.validator = new Validator();

        this.validator.addConstraint("newEmail", "is-required");
        this.validator.addConstraint("newEmail", "is-email");

        this.validator.addConstraint("password", "is-required");
        this.validator.addConstraint("password", "is-password");
        this.validator.addConstraint("password", "length", {
            max: 20,
            min: 5
        });
    }


    filtrate() {
        const filtration = new Filtration();
        const trim = new TrimFilter();

        filtration.registerFilter("newEmail", trim);
        filtration.registerFilter("password", trim);

        this.store = filtration.filtrate(this.store);
    }

    onError() {
        this.store.showError = true;
        this.store.showMessage = false;
    }

    onSuccess() {
        this.resetStore();
        this.store.showMessage = true;
    }

    getInitialStore(): TStore {
        return {
            newEmail: "",
            password: "",
            showMessage: false,
            showError: false,
            formIsValid: true,
            violations: {
                newEmail: "",
                password: ""
            }
        };
    }
}
