/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.1.18
 */

import { AbstractResponseHandler } from "./abstract-response-handler";
import { Request } from "./request";
import { parseResponse } from "./response-parser";
import { REQUEST_STATE_DONE } from "../../frizo-shared-constants";

export class DefaultResponseHandler extends AbstractResponseHandler {

    handleResponse(request: Request, xhr: XMLHttpRequest): boolean {
        request.setStatusCode(xhr.status);
        request.setState(REQUEST_STATE_DONE);

        let body = parseResponse(xhr.responseText, xhr.getResponseHeader("Content-Type"));

        request.setResponseBody(body);
        request.setOriginResponseBody(xhr.responseText);

        let headers = xhr.getAllResponseHeaders().split("\n")
        .filter(headerString => !!headerString)
        .map(headerString => {
            let pair = headerString.split(":");
            return {
                name: pair[0].trim(),
                value: pair[1].trim()
            };
        });

        request.setResponseHeaders(headers);
        request.setXhr(xhr);
    }
}
