/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.9.6
 * Time: 14:06
 */

import BaseConstraint from "./base-constraint";

export const ARTIST_REQUIRED = "ARTIST_REQUIRE";
export const SERVICE_REQUIRED = "SERVICE_REQUIRED";
export const ATTRIBUTES_REQUIRED = "ATTRIBUTES_REQUIRED";


export default class GalleryForm extends BaseConstraint {

    constructor(options = null) {
        super();

        this.servicesStore = null;
        this.messageNoArtist = "Please select Artist";
        this.messageNoCategory = "Please select Category";
        this.messageNoService = "Please select Service";

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        return "gallery-form";
    }

    getRequiredOptions() {
        return ["servicesStore"];
    }
}
