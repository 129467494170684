/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 05.04.2017
 * Time: 09:23
 */
import React, {Component} from 'react';
import {Col, Row, Button, Badge} from 'react-bootstrap';
import {LocalTime} from './../../../helper-components/time-component';

export default class AptListRow extends Component {
   /* constructor(props) {
        super(props);

    }*/

    onModifyHandler() {
        this.props.onModify(this.props.aptId);
    }

    onDeleteHandler() {
        this.props.onDelete(this.props.aptId);
    }

    onBookHandler() {
        this.props.onBook(this.props.aptId);
    }

    onPaidHandler() {
        this.props.onPaid(this.props.aptId);
    }

    onDeclineHandler() {
        this.props.onDecline(this.props.aptId);
    }

    onDetailHandler() {
        this.props.onShowPersonalAppointment(this.props.aptId, this.props.aptList)
    }

    getButtonGroup(aptList) {

        if (aptList === "Pending") {
            return (
                <div>
                    <button className="btn fr-btn fr-pers-apt__action fr-btn-danger"
                            onClick={this.onPaidHandler.bind(this)}>
                        Pay
                    </button>
                    <button className="btn fr-btn fr-pers-apt__action fr-btn-danger"
                            onClick={this.onDeclineHandler.bind(this)}>
                        Decline
                    </button>
                </div>
            )
        }
        else if (aptList !== "Upcoming") {
            return (
                <button className="btn fr-btn fr-pers-apt__action fr-book-btn"
                        onClick={this.onBookHandler.bind(this)}>
                    Book again
                </button>
            )
        } else {
            return (
                <div>
                    <button className="btn fr-btn fr-pers-apt__action fr-book-btn"
                            onClick={this.onModifyHandler.bind(this)}>Modify
                    </button>

                    <button className="fr-link-delete fr-pers-apt__action btn-link btn "
                            onClick={this.onDeleteHandler.bind(this)}>Cancel
                    </button>
                </div>
            )
        }
    }

    render() {
        let {
            aptId,
            aptStartTimestamp, aptTZ,
            aptService, aptSalon,
            aptArtistFirstName, aptArtistLastName,
            aptList, aptStatus
        } = this.props;

        let statusClass = aptList;
        if (aptList === "History") {
            statusClass = (aptStatus === 2 ? "No-show" : aptStatus === 1 ? "Canceled" : "Done");
        }
        return (
            <div>
                <Row className="fr-table-content fr-table-centred__bordered">
                    <Col xsHidden={true} smHidden={true} md={2}>
                        <Badge className={"fr-apt-" + statusClass.toLowerCase()}>{statusClass}</Badge>
                    </Col>
                    <Col xs={1} mdHidden={true} lgHidden={true}>
                        <span className={"fr-apt-span__" + statusClass.toLowerCase()}>&bull;</span>
                    </Col>

                    <Col xsHidden={true} smHidden={true} md={2} className="fr-apt-date-cell">
                        <span>
                            <LocalTime
                                timestamp={aptStartTimestamp}
                                tz={aptTZ}
                                format="ll, hh:mm A"
                            />
                        </span>
                    </Col>
                    <Col xs={4} mdHidden={true} lgHidden={true} className="fr-apt-date-cell">
                        <span>
                            <LocalTime
                                timestamp={aptStartTimestamp}
                                tz={aptTZ}
                                format="ll"
                            />
                        </span><br/>
                        <span>
                            <LocalTime
                                timestamp={aptStartTimestamp}
                                tz={aptTZ}
                                format="hh:mm A"
                            />
                        </span>
                    </Col>


                    <Col xs={5} md={2} className="fr-apt-cell">
                        <span>{aptService}</span>
                    </Col>
                    <Col xsHidden={true} smHidden={true} md={2} className="fr-apt-cell">
                        <span>{aptArtistFirstName}{' '}{aptArtistLastName}</span>
                    </Col>
                    <Col xsHidden={true} smHidden={true} md={2} className="fr-apt-cell">
                        <span>{aptSalon}</span>
                    </Col>


                    <Col xsHidden={true} smHidden={true} md={2}>
                        {this.getButtonGroup(aptList, aptId)}
                    </Col>

                    <Col xs={2} mdHidden={true} lgHidden={true}>
                        <Button className="fr-btn fr-btn-small fr-table-clients__btn visible-xs visible-sm"
                                onClick={this.onDetailHandler.bind(this)}
                        >
                            <span className="glyphicon glyphicon-option-horizontal"></span>
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}




