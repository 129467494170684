/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 2.1.18
 *
 */

import { xhr } from "../index";
import { MODE_TRANSACTIONAL } from "../../../frizo-shared-constants";

export function makeRelayRequest(requestBuilder) {
    let formData = new FormData();
    let isSecure = false;

    requestBuilder.getRequestSet().forEach((request, key) => {

        formData.append("resource[]", request.getUrl());
        formData.append("method[]", request.getMethod());
        formData.append("requestId[]", request.getRequestId() || key);
        formData.append("data[]", request.getRequestBody());
        formData.append("headers[]", JSON.stringify(request.getRequestHeadersHash()));

        if (request.isSecure()) {
            isSecure = true;
        }
    });

    let baseRouteName = isSecure ? "request_relay_secure" : "request_relay";

    return xhr()
    .suppressNotifications(requestBuilder.isNotificationsSuppressed())
    .post("@" + baseRouteName)
    .header("U6-transactional", Number(requestBuilder.getMode() === MODE_TRANSACTIONAL))
    .body(formData)
    .end();
}
