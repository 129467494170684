/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 23.03.2018
 * Time: 16.34
 */

import "offline-js";

import "offline-js/themes/offline-theme-slide.css";
import "offline-js/themes/offline-language-english.css";
import { renewToken } from "../auth";

window.Offline.options = {
    checkOnLoad: true,
    interceptRequests: true,
    reconnect: {
        initialDelay: 15,
        delay: 50
    },
    requests: false,
    game: false
};

window.Offline.on("up", () => {
    console.log("Connection restored. Try to reconnect.");

    renewToken();
});
