/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 2.1.18
 *
 * @flow
 */

import EventEmitter from "events";
import { Request } from "./request";
import {
    Q_PROCESSED,
    REQUEST_STATE_DONE,
    REQUEST_STATE_RUNNING,
    REQUEST_STATE_SCHEDULED
} from "../../frizo-shared-constants";
import { Gateway } from "./gateway";
import { Queue } from "../queue";
import { AbstractResponseHandler } from "./abstract-response-handler";

import { lowLevelEvents, xhrEvents } from "./events";

const STATE_IDLE = "idle";
const STATE_PAUSE = "pause";
const STATE_RUNNING = "running";

export class Processor extends EventEmitter {
    queue: Queue;
    state: string;
    gateway: Gateway;
    responseHandlers: Array<AbstractResponseHandler>;

    constructor(queue: Queue, responseHandlers: Array<AbstractResponseHandler>) {
        super();

        this.gateway = new Gateway();

        this.responseHandlers = responseHandlers;

        this.queue = queue;
        this.reset();
    }

    reset() {
        this.state = STATE_IDLE;

        this.queue.reset();
    }

    setGateway(gateway: Gateway) {
        this.gateway = gateway;
    }

    pause(): void {
        this.state = STATE_PAUSE;
        lowLevelEvents.processorPaused.dispatch(this);
    }

    resume(): void {
        this.state = STATE_IDLE;
        this.run();
        lowLevelEvents.processorContinued.dispatch(this);
    }

    send(request: Request): Promise<XMLHttpRequest> {
        request.hop();
        if (request.getHopsCount() > 5) {
            return new Promise((success: Function) => {
                request.setState(REQUEST_STATE_DONE);
                request.events.failed.dispatch(request);

                success(request.getXhr());
            });
        }

        lowLevelEvents.xhrStart.dispatch(request);

        return this.gateway.send(
            request.getUrl(),
            request.getMethod(),
            request.getRequestHeaders(),
            request.getRequestBody()
        ).then((xhr: XMLHttpRequest) => {

            lowLevelEvents.xhrDone.dispatch(request, xhr);

            for (let i = 0; i < this.responseHandlers.length; i++) {

                let handler = this.responseHandlers[i];

                lowLevelEvents.startResponseHandler.dispatch(request, xhr, handler);

                let ret = handler.handleResponse(request, xhr);

                lowLevelEvents.finishResponseHandler.dispatch(request, xhr, handler, ret);

                //false talk about response was handled
                if (ret === false) {
                    //do not call least handlers

                    break;
                }
            }

            lowLevelEvents.responseHandled.dispatch(request);

            if (request.isDone()) {

                request.events.succeeded.dispatch(request);

                if (request.isSuccess()) {
                    xhrEvents.requestSucceeded.dispatch(request);
                }
                else {
                    xhrEvents.requestFailed.dispatch(request);
                }

                return Promise.resolve(xhr);
            }
            else {
                //enqueue request if response received but request isn't marked as done
                this.enqueue(request);

                return Promise.resolve(xhr);
            }

        }); //may be you should catch it for tests
    }

    enqueue(request: Request): void {
        request.setState(REQUEST_STATE_SCHEDULED);
        this.queue.push(request);
        lowLevelEvents.requestScheduled.dispatch(request);
        this.run();
    }

    issue(request: Request): void {
        xhrEvents.requestIssued.dispatch(request, this);

        if (this.state === STATE_PAUSE) {
            this.enqueue(request);
        }
        else {
            this.send(request).then(xhr => {
                //catch and do nothing
            }, xhr => {
                //catch and do nothing
            });
        }
    }

    run() {
        if (this.state === STATE_PAUSE) {
            console.log("Cannot run because processor is paused");

            return false;
        }

        this.state = STATE_RUNNING;

        let exists;
        do {
            exists = this.queue.consume(request => {
                request.setState(REQUEST_STATE_RUNNING);
                this.send(request).then(xhr => {
                    //catch and do nothing
                }, xhr => {
                    //catch and do nothing
                });

                return Q_PROCESSED;
            });
        }
        while (exists);

        this.state = STATE_IDLE;
    }
}
