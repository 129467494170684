/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.01.2019
 * Time: 15:54
 */

import { CLIENT_COMMENT_MODAL, CLIENT_EDITED, MODAL_CHANGE } from "../constants";
import BaseStore from "./base-store";

class ClientsCommentStore extends BaseStore {
    constructor(...args) {
        super(...args);
        this.store = {
            btnDisabled: false,
            client: {},
            clientsList: []

        };

        this._registerActionSubscription((action) => {
                const { type, payload } = action;

                switch (type) {
                    case MODAL_CHANGE:
                        if (payload.type === CLIENT_COMMENT_MODAL) {
                            this.store.client = payload.options.client;
                        }
                        break;

                    case CLIENT_EDITED:
                        this.store.client = Object.assign({}, payload.client);
                        this.store.clientsList = this.addClientToList(payload.client);
                        this.store.btnDisabled = false;
                        break;

                    default:
                        return true;

                }

                this.emitChange();
            }
        );

    }

    getClient() {
        return this.store.client;
    }

    getClientList() {
        return this.store.clientsList;
    }

    getClientById(clientId) {
        return this.store.clientsList.find(client => client.id === clientId);
    }

    addClientToList(client) {
        let clientsList = this.store.clientsList;
        let index = clientsList.findIndex(clients => clients.id === client.id);
        if (index > -1) {
            clientsList.splice(index, 1, client);
        }
        else {
            clientsList.push(client);
        }
        return clientsList;
    }

}

export default ClientsCommentStore;
