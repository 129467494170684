/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.4.4
 * Time: 12:32
 */

import AppDispatcher from "../../dispatcher";
import { PROFILE_SERVICES_DATA_LOADED } from "../../constants";
import { xhrLoadProfileServices } from "../../xhr/services-xhr";

export const loadProfileServicesInitial = (salonId) => {

    const profileServicesInfo = xhrLoadProfileServices(salonId);

    profileServicesInfo.then(answer => {
        const { intervals, salonCorrections, services } = answer;
        AppDispatcher.dispatch({
            type: PROFILE_SERVICES_DATA_LOADED,
            payload: {
                duration: normalizeIntervals(intervals.duration, "durationID", "durationName"),
                processing: normalizeIntervals(intervals.processing, "processingID", "processingName"),
                salonCorrections,
                services
            }
        });

    });

};

const normalizeIntervals = (intervals, idField, nameField) => {
    let interval = intervals.map(elem => {
        return { id: elem[idField], value: elem.value, name: elem[nameField] };
    });

    return interval.sort((a, b) => a.value - b.value);
};
