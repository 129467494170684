/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 08.11.2018
 * Time: 12:10
 */

import { ARTIST_PROMO_DATA_LOAD_ERROR, ARTIST_PROMO_DATA_LOADED } from "../constants";

import BaseStore from "./base-store";

export default class ArtistPromoStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            artist: {},
            salon: {},
            loaded: false,
            errors: {}
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case ARTIST_PROMO_DATA_LOADED:
                    this.store.artist = payload;
                    this.store.salon = payload.salon[0];
                    this.store.loaded = true;

                    break;

                case ARTIST_PROMO_DATA_LOAD_ERROR:
                    this.store.loaded = false;
                    this.store.errors = payload;
                    break;

                default:
                    return this.store;
            }

            this.emitChange();
        });
    }
}
