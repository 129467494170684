/**
 * Created by sn on 20.09.2016.
 * User: Zakhar Shushkevich
 * Company: U6 SIA
 */

let config = {
    defaultHeaders: [],

    paramsMainScreen: {
        count: 12,
        offset: 0,
        categoryID: "",
        serviceList: "",
        cityZip: "",
        latitude: "",
        longitude: "",
        salonArtist: "",
        date: "",
        from: 0,
        to: 86400
    },

    defaultFooter: {
        flag: { title: "en", url: "/" },
        contactus: { title: "Contact us", url: "/contact-us" },
        legal: { title: "Terms & privacy", url: "/legal" },
        faq: { title: "FAQ", url: "/faq" },
        facebook: { title: "Facebook", url: "https://www.facebook.com/frizocom" },
        instagram: { title: "Instagram", url: "https://www.instagram.com/frizocom" },
        pinterest: { title: "Pinterest", url: "https://www.pinterest.com/frizocom/" },
        youtube: { title: "YouTube", url: "https://www.youtube.com/channel/UCCAgep_EV0u9g1eFJkaRQlA" },
        appstore: {
            title: "Frizo on App Store",
            url: "https://itunes.apple.com/us/app/frizo-the-beauty-matchers/id986765191?mt=8",
            img: "/img/appstore-badge.svg",
            width: 135,
            height: 40
        },
        googlePlay: {
            title: "Frizo on Google Play",
            url: "https://play.google.com/store/apps/details?id=com.frizo.frizo",
            img: "/img/google-play-badge.svg",
            width: 151,
            height: 58
        },
        r14LEVSHAssistantRecommendation: "https://frizo.page.link/7nvM",
        copyright: { title: "Operated with care by UnitedMinds Corp." }
    },

    defaultDateFormat: "MM/DD/YYYY",
    defaultTimeFormat: "hh:mm A",
    defaultDateTimeFormat: "MM/DD/YYYY hh:mm A",
    defaultDateTextFormat: "ll",

    maxUploadFileSize: 10485760,

    googleMap: {
        NY_lat: 40.7128,
        NY_lng: -74.0059,
        USA_center_lat: 39.709,
        USA_center_lng: -98.35
    },

    paymentOrderExpireDays: 7,


    notification: {
        delay: 4000
    },
    autocompleteOptions: {
        componentRestrictions: { country: "us" }
    }

    /*
     Should passed from parameters.yml
     websocket_frontend_url: 'http://io.braidsandfreckles.com'
     */
};

export default Object.assign(config, window.backendConfig);
