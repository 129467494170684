/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 15.01.2018
 * Time: 14:53
 */

import React, {Component} from 'react';

import {loadFavoriteArtists} from '../../../../ac/profile/personal/personal-favorites-actions';
import {Link} from 'react-router';
import {personalProfileFavoritesArtistsStore} from "../../../../stores";
import {
    GALLERY2_PERSONAL_PROFILE_FAVORITES_ARTISTS_ID,
} from "../../../../frizo-shared-constants";
import {createGallery} from "../../../gallery2/gallery-factory";
import PersonalProfileFavoritesArtistAvatar from "../../../gallery2/items/personal-profile-favorites-avatar";
import PersonalProfileFavoritesFullscreenAvatar from "../../../gallery2/fullscreen-items/personal-profile-favorites-fullscreen-avatar";

export default class FavoritesArtistsContainer extends Component {
    constructor(props) {
        super(props);


        this.state = Object.assign({
            ready: false,
            params: {},
            salon: {},
            showEmptyMessage: false
        }, this.getStoreData());


        this.onGalleryStoreChange = this.onGalleryStoreChange.bind(this);

        this.gallery = this.createGallery();
        this.gallery.reset();
        this.gallery.init();
    }

    getStoreData() {
        let storeData = personalProfileFavoritesArtistsStore.getStore();

        return {
            params: storeData.gallery.params,
            showEmptyMessage: storeData.gallery.items.length === 0
        }
    }

    componentDidMount() {
        personalProfileFavoritesArtistsStore.addChangeListener(this.onGalleryStoreChange);
    }

    componentWillUnmount() {
        personalProfileFavoritesArtistsStore.removeChangeListener(this.onGalleryStoreChange);

    }

    createGallery() {

        return createGallery(GALLERY2_PERSONAL_PROFILE_FAVORITES_ARTISTS_ID)
            .setGalleryStore(personalProfileFavoritesArtistsStore)
            .setFavoritesEnabled(false)
            .setNoItemsText('Yet to post images')
            .setItemClass(PersonalProfileFavoritesArtistAvatar)
            .setFullscreenClass(PersonalProfileFavoritesFullscreenAvatar)
            .setGalleryLoader(loadFavoriteArtists);
    }


    onGalleryStoreChange() {
        let newState = Object.assign({}, this.getStoreData());

        this.setState(newState);

    }

    render() {
        if (this.state.showEmptyMessage) {
            return (<div className="no-fav-message "><Link to='/'>Let me see again</Link></div>);
        } else {
            return (
                <div>
                    <div className="fr-mt-xs">
                        {this.gallery.getGallery()}
                    </div>
                </div>
            );
        }
    }
}