/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.3.17
 * Time: 16.01
 */

import AppDispatcher from "../../dispatcher";

import { BOOK_APPOINTMENTS_LOAD_SUCCESS } from "../../constants";


export function appointmentsLoaded(appointments) {
    AppDispatcher.dispatch({
        type: BOOK_APPOINTMENTS_LOAD_SUCCESS,
        payload: appointments
    });
}