/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 8.3.17
 * Time: 6.34
 */

import { Link } from "react-router";
import React, { Component } from "react";
import { sessionStore } from "./../stores/index";
import { modalChange } from "./../ac/modals";
import { LOGIN_MODAL } from "./../constants";

export default class AuthLink extends Component {

    constructor(props) {
        super(props);

        this.saveRef = this.saveRef.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.persist();

        if (sessionStore.isReady()) {
            if (!sessionStore.isLogged()) {
                event.preventDefault();

                let originalClickHandler = this.originalClickHandler;
                return modalChange(LOGIN_MODAL, {
                    inTransition: true,
                    previous: () => {
                        event.defaultPrevented = false;
                        setTimeout(() => {
                            originalClickHandler(event);
                        });

                    }
                });
            }
        }

        return this.originalClickHandler(event);
    }

    saveRef(link) {
        if (link) {
            this.link = link;
            this.originalClickHandler = this.link.handleClick;
            this.link.handleClick = this.handleClick;
        }
    }

    render() {
        return <Link ref={this.saveRef} {...this.props}>{this.props.children}</Link>;
    }
}
