/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 5.12.17
 * Time: 19.25
 */


import React, {Component} from 'react';
import type {FAVORITE_TYPES} from "../../types";
import {favoriteMarkSet, favoriteMarkUnSet} from "../../ac/favorites/common-ac";
import {favoritesStore} from "../../stores/index";
import {GALLERY_ITEM_TYPE_AVATAR, GALLERY_ITEM_TYPE_LOOK} from "../../frizo-shared-constants";


type TProps = {
    id: number,
    type: FAVORITE_TYPES,
    inModal: boolean
};

export class FavoriteMark extends Component<TProps> {

    constructor(props) {
        super(props);

        this.state = {
            favorites: this.getFavoritesStoreData(),
            linkDisabled: false
        };

        this.updateStore = this.updateStore.bind(this);

    }

    componentDidMount() {
        favoritesStore.addChangeListener(this.updateStore);
    }

    componentWillUnmount() {
        favoritesStore.removeChangeListener(this.updateStore);
    }

    getFavoritesStoreData() {
        return favoritesStore.getStore();
    }

    updateStore() {
        this.setState({
            favorites: this.getFavoritesStoreData(),
            linkDisabled: favoritesStore.store.linkDisabled
        })

    }

    isFavorite() {

        switch (this.props.type) {
            case GALLERY_ITEM_TYPE_LOOK:
                return !!this.state.favorites.galleryFavorites[this.props.id];

            case GALLERY_ITEM_TYPE_AVATAR:
                return !!this.state.favorites.artistsFavorites[this.props.id];

            default:
                return false;
        }
    }

    toggle(e) {
        e.preventDefault();

        this.setState({linkDisabled: true});

        if (this.isFavorite()) {
            favoriteMarkUnSet(this.props.id, this.props.type);
        }
        else {
            favoriteMarkSet(this.props.id, this.props.type);
        }

        return false;
    }

    render() {
        if (!(this.state.favorites.enabled && this.state.favorites.ready)) return <div/>;

        let containerClasses = ['fr-favorite'];


        if (this.props.inModal) {
            containerClasses.push('fr-favorite-large');
        }


        let hrefClasses = !this.state.linkDisabled ? [] : ['fr-favorite-disabled'];
        if (this.isFavorite()) {
            hrefClasses.push('fr-favorite-marked');
        }
        else {
            hrefClasses.push('fr-favorite-not-marked');
        }

        return (<div className={containerClasses.join(' ')}>
            <button style={{cursor: 'pointer'}}
               className={hrefClasses.join(' ')}
               onClick={this.toggle.bind(this)}> </button>
        </div>);
    }
}