/**
 * Created by fliak
 * Name: George Novik
 * Company: U6 SIA
 * Date: 05.12.2017
 * Time: 14:40
 */

import React, {Component} from 'react';


import ServicePriceRange from '../../modal/components/service-price-range';
import {PRICE_RANGE_SIGNS} from '../../../constants';
import {Img} from "../../../elements/img";
import {
    DROPDOWN_PLACEHOLDER_VALUE,
    IMAGE_SIZE_LARGE,
    MODE_CLIENT, BUTTON_LINK_PROMO
} from "../../../frizo-shared-constants";
import {FavoriteMark} from "../../favorites/favorite-mark";
import GalleryFullscreenItemContainer from "../gallery-parts/gallery-fullscreen-item-container";
import GalleryImageTitle from "../../gallery/elements/gallery-image-title";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import AuthButton from "../../../require-auth/auth-button";
import {imageUrl} from "../../../helper/image-url";
import {startBooking} from "../../../ac/book-apt/common-actions";
import moment from 'moment';
//import { setPromoInitials } from "../../../ac/salon-promo-action";
import {modalHide} from "../../../ac/modals";
import GalleryItemButton from "../gallery-parts/gallery-item-button";

import {browserHistory} from 'react-router';
//import {initFilter} from "../../../ac/gallery-filter-actions";
import GalleryShareButton from '../gallery-parts/gallery-share-button';
import getImageTitle from "../../../helper/get-image-title";
import ArtistCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";

export default class DashboardGalleryFullscreenLook extends Component {

    createPriceRange() {
        if (this.props.currentItem.priceLevel) {

            return (<ServicePriceRange
                priceRange={this.props.currentItem.priceLevel}
                priceRangeSigns={PRICE_RANGE_SIGNS}/>);
        }

        return null;
    }

    bookAction(e) {
        e.preventDefault();

        //console.log('this.props.currentItem', this.props.currentItem);
        let date = moment(new Date()).format('YYYY-MM-DD');
        startBooking(
            MODE_CLIENT,
            this.props.currentItem.artist.id,
            this.props.currentItem.salon.id,
            date, DROPDOWN_PLACEHOLDER_VALUE, this.props.currentItem.service.id, this.props.currentItem.image.id, imageUrl(this.props.currentItem.image.url));


        return false;
    }


    redirectToPromo(artistId, url) {

        browserHistory.push({
            pathname: `/promo/${url}/gallery`,
            state: {
                artistId
            }
        });

        modalHide();
    }

    render() {
        let imgSrcUrl = this.props.showImageAfter ? this.props.currentItem.image.url : this.props.currentItem.imageBefore.url;
        let imgDescription = getImageTitle(this.props.currentItem);
        let artist=this.props.currentItem.artist;

        return <GalleryFullscreenItemContainer>
            {this.props.showImageAfter && this.createPriceRange()}

            {this.props.showImageAfter && this.props.favoritesEnabled &&
            <FavoriteMark id={this.props.currentItem.id} type={this.props.currentItem.galleryItemType} inModal={true}/>}
            {artist.airtouchCertified && <ArtistCertificateBadge {...artist}/>}
            <Img size={IMAGE_SIZE_LARGE} className="img-responsive" src={imgSrcUrl} description={imgDescription}/>
            <div className="img-slider"></div>

            {this.props.showPrev && this.props.prevArrow}
            {this.props.showNext && this.props.nextArrow}

            <GalleryItemCardInfo>
                <GalleryImageTitle
                    serviceName={this.props.currentItem.service.title}
                    firstName={this.props.currentItem.artist.firstName}
                    lastName={this.props.currentItem.artist.lastName}
                />

                <GalleryItemButtonsContainer style={{visibility: this.props.showImageAfter ? '' : 'hidden'}}>
                    <GalleryItemButton title="Gallery" type={BUTTON_LINK_PROMO}
                                       onClick={this.redirectToPromo.bind(this, this.props.currentItem.artist.id, this.props.currentItem.salon.url)}/>
                    <GalleryShareButton title="Share" className="fr-btn" shareUrl={this.props.currentItem.shareUrl}
                                        imageUrl={this.props.currentItem.image.url}/>
                    <AuthButton autoHideModal={false} onClick={this.bookAction.bind(this)}
                                className="btn fr-btn">Book</AuthButton>
                </GalleryItemButtonsContainer>
            </GalleryItemCardInfo>

        </GalleryFullscreenItemContainer>
    }

}
