/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.18.7
 * Time: 10:20
 */

import React, { Component } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { dismissAppointment, toggleUnseenList } from "./../../ac/unseen-appointments-actions";
import { unseenAppointmentsStore } from "./../../stores";
import { unseenNormalize } from "./../../normal-form/unseen";
import moment from "moment";
import $ from "jquery";
import gradientFill from "./../../helper/gradient-fill";


export default class UnseenAppointmentsList extends Component {
    constructor(props) {

        super(props);
        this.state = {
            open: false,
            list: []
        };

        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }

    onItemClick(aptDate, id, aptId) {
        let newDate = moment(aptDate);

        return (ev) => {
            ev.preventDefault();
            dismissAppointment(id);
            $(".datepicker").datepicker("setDate", newDate.toDate());
            this.props.onItemClick({ date: newDate.format("MM/DD/YYYY") }, aptId);
        };
    }

    dismissAppointment(id = 0) {

        return (ev) => {
            ev.preventDefault();
            dismissAppointment(id);
        };
    }

    handleStoreChange() {
        let rawList = unseenAppointmentsStore.store.list;
        let list = rawList.length ? rawList.map(rawItem => unseenNormalize(rawItem)) : [];
        this.setState({
            open: unseenAppointmentsStore.store.open,
            list: list
        });
    }

    createUnseenList() {

        return this.state.list.map((item, index) => {

            return (<Row key={item.id} className="fr-unseen-apt-row">
                <Col md={10} xs={8} className="fr-pl0">
                    <button className={"btn-link full-width"}
                            onClick={this.onItemClick(item.date, item.id, item.aptId)}>
                        <Col md={3} xs={12} className={"fr-pl0-lg fr-text-xs-left"}>
                            {item.formedDate}<strong>&nbsp;&#8226;&nbsp;</strong>{item.formedStartTime}
                        </Col>
                        <Col md={4} xs={12} className={"fr-text-left"}>
                            {item.artist}<strong>&nbsp;&#8226;&nbsp;</strong>{item.service}
                        </Col>
                        <Col md={5} xs={12} className={"fr-text-xs-left"}>
                            {item.client}
                        </Col>
                    </button>

                </Col>
                <Col md={2} xs={4} className="fr-text-right">
                    <button className="fr-btn-dismiss btn btn-sm btn-default fr-unseen-apt-mt"
                            onClick={this.dismissAppointment(item.id)}>Dismiss
                    </button>
                </Col>
            </Row>);
        });
    }

    componentDidMount() {
        unseenAppointmentsStore.addChangeListener(this.handleStoreChange);
    }

    componentWillUnmount() {
        unseenAppointmentsStore.removeChangeListener(this.handleStoreChange);
    }

    close() {
        toggleUnseenList();
    }

    render() {
        if (!this.state.list.length) {

            return null;
        }

        return (<Modal show={this.state.open} bsSize="large" onHide={this.close} restoreFocus={false}>
            <Modal.Header className="fr-unseen-apt-header" closeButton style={{ "background": gradientFill() }}>
                <h2>New appointments</h2>
                <Row className={"table-header"}><Col md={3} xs={12}>Date<strong>&nbsp;&#8226;&nbsp;</strong>Time</Col>
                    <Col md={4} xs={12}>Artist<strong>&nbsp;&#8226;&nbsp;</strong>Service</Col>
                    <Col md={3} xs={12}>Client</Col>
                </Row>
            </Modal.Header>
            <Modal.Body className={"fr-unseen-apt__body"}>
                {this.createUnseenList()}
            </Modal.Body>
            <Modal.Footer className="fr-unseen-apt__footer">
                <button className="btn btn-sm fr-btn-remove" onClick={this.dismissAppointment()}>Dismiss all</button>
            </Modal.Footer>
        </Modal>);
    }
}


UnseenAppointmentsList.defaultProps = {
    onItemClick: () => false
};

