/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.3.17
 * Time: 7.07
 */

import React, {Component} from 'react';
import ProfileArtist from '../professional/artists/profile-artist';
import {ARTIST_FORM_MODE_CREATE} from "../../../frizo-shared-constants";

export default class ProfessionalProfileArtistsAdd extends Component {

    render() {
        return <ProfileArtist artistId={this.props.params.id} mode={ARTIST_FORM_MODE_CREATE}/>

    }
}