/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.11.17
 * Time: 15.16
 *
 * @flow
 */

import ApiClient from "./../service/api-client";
import { xhr } from "./../service/api-client-2";

import { CUSTOM_HEADER_U6_GROUPS } from "./../frizo-shared-constants";
import { Request } from "../service/api-client-2/request";

function getUrl() {
    return ApiClient.getRouting().generate("Q87_auth_get_current_user");
}

export function xhrGetCurrentUser() {

    return ApiClient.get(getUrl()).then(answer => {
        let [response] = answer;

        return response;
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}

export function xhrGetCurrentUserFavouriteGalleryItems() {

    return ApiClient.get(getUrl(), null, {
        headers: {
            [CUSTOM_HEADER_U6_GROUPS]: ["gallery-favorite"]
        }
    }).then(answer => {
        let [response] = answer;

        return response;
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}

export function xhrGetCurrentUserFavouriteArtists() {

    return ApiClient.get(getUrl(), null, {
        headers: {
            [CUSTOM_HEADER_U6_GROUPS]: ["artist-favorite"]
        }
    }).then(answer => {
        let [response] = answer;

        return response;
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}

export function xhrGetCurrentUserFavourites() {

    return xhr()
    .get(getUrl())
    .header(CUSTOM_HEADER_U6_GROUPS, "gallery-favorite")
    .end()
    .get(getUrl())
    .header(CUSTOM_HEADER_U6_GROUPS, "artist-favorite")
    .end()
    .then((requestSet: Request) => {

        return requestSet.merge();

    }, (requestSet: Request) => {

        return Promise.reject(requestSet.merge());

    });
}
