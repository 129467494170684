/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 25.11.17
 * Time: 8.14
 */

import TagManager from "react-gtm-module";

export function runGTM(gtmId) {

    TagManager.initialize({
        gtmId,
        dataLayerName: "dataLayer"
    });
}
