/**
 * Created by fliak on 21.12.16.
 */

import React, {Component} from 'react';
import DropdownWrapper from './../../elements/dropdown-wrapper';
import {Button} from 'react-bootstrap';
import moment from 'moment';
import {
    MODE_PROFESSIONAL,
    SALON_TYPE_FREELANCER, SALON_TYPE_SALON
} from './../../frizo-shared-constants';
import bookAptFormActions from '../../ac/book-apt/form-actions';
import {bookTimelineStore} from './../../stores/index';
import config from './../../config';
import PropTypes from 'prop-types';

export default class BookForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableTimes: [],
            availableProcessingIntervals: [],
            markers: [],
            displayMonth: undefined,
            displayYear: undefined,
            userType: null
        };

        this.onRemoveClickHandler = this.onRemoveClickHandler.bind(this);
        this.getNum = this.getNum.bind(this);
        this.handleAvailableTimeChange = this.handleAvailableTimeChange.bind(this);
        this.changeMonth = this.changeMonth.bind(this);
    }

    changeMonth(month, year) {

        this.displayMonth = month;
        this.displayYear = year;

        this.updateMarkers();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.serviceId !== this.props.serviceId ||
            prevProps.addressId !== this.props.addressId ||
            prevProps.artistId !== this.props.artistId ||
            prevProps.date !== this.props.date

        ) {

            this.updateMarkers();
        }
    }

    getNum() {
        return this.props.num;
    }


    createArtistSelect() {
        if (this.props.salonType === SALON_TYPE_FREELANCER) return null;

        let {options, selected} = this.props.artistsData;
        let placeholder = 'Select artist';

        return <div>
            <DropdownWrapper
                list={options}
                className={"dropdown-book-apt"}
                selected={selected}
                placeholder={placeholder}
                onChange={this.onAptChange('artistId', 'number')}/>
        </div>;
    }

    createAddress() {

        if (this.props.artistsData.multiAddress) {
            let placeholder = 'Select address';
            return (
                <DropdownWrapper
                    className={"dropdown-book-apt"}
                    placeholder={placeholder}
                    list={this.props.addresses}
                    selected={this.props.addressId}
                    onChange={this.onAptChange('addressId', 'number')}
                />
            );

        }

        return null;

    }

    createRemoveButton() {
        if (this.props.showRemove) {
            return (<Button onClick={this.onRemoveClickHandler} bsStyle="danger">
                Remove
            </Button>);
        }
        return null;
    }


    createServiceSelects() {
        let servicesSelectData = this.props.servicesData;

        return servicesSelectData.map((select, i) => {

            let placeholder = select.isCategory ? 'Select category' : 'Select service';

            return <DropdownWrapper
                key={i}
                className={"dropdown-book-apt"}
                list={select.options}
                placeholder={placeholder}
                selected={select.selected}
                optionTextPropName="i18nKey"
                onChange={this.onAptChange('serviceId', 'number')}
            />
        });
    }

    handleAvailableTimeChange() {
        let availableTimeSet = bookTimelineStore.getAvailableTimesForApt(this.props.aptId);

        let state = {
            availableTimes: availableTimeSet
        };

        if (this.props.mode === MODE_PROFESSIONAL) {
            state.availableProcessingIntervals = bookTimelineStore.getAvailableProcessingFor(this.props.aptId);
        }

        this.setState(state);
    }

    componentDidMount() {
        bookTimelineStore.addChangeListener(this.handleAvailableTimeChange);
        this.updateMarkers();
    }

    componentWillUnmount() {
        bookTimelineStore.removeChangeListener(this.handleAvailableTimeChange);
    }

    onRemoveClickHandler() {
        this.props.onRemoveElement(this.props.aptId);
    }

    /**
     * Generate on-Method for each control
     * @param field
     * @param castToType
     *
     * @returns {function(*)}
     */
    onAptChange(field, castToType = 'no-cast') {

        return (value) => {

            let patch = {};
            patch[field] = this._castToType(value, castToType);

            bookAptFormActions.aptChange(this.props.aptId, patch);
        };
    }


    onCalendarChange(data) {
        bookAptFormActions.aptChange(this.props.aptId, {
            date: data.date
        });
    }


    _castToType(value, type) {
        switch (type) {
            case 'number':
                return Number(value);
            case 'string':
                return String(value);
            case 'no-cast':
                return value;
            default:
                throw new Error('Unknown cast type `' + type + '`');
        }
    }


    createTimeSelect() {
        let options = [];

        this.state.availableTimes.forEach((time) => {

            let value = moment.utc(this.props.date).add(time, 'seconds');

            options.push({
                id: Number(time),
                name: value.format(config.defaultTimeFormat)
            });
        });

        return <DropdownWrapper
            className={"dropdown-book-apt"}
            selected={this.props.startTime}
            list={options}
            placeholder={"Select time"}
            onChange={this.onAptChange('startTime', 'number')}
        />
    }

    render() {
        return null;
    }


}

BookForm.propTypes = {
    num: PropTypes.number.isRequired,
    aptId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    salonType: PropTypes.oneOf([SALON_TYPE_FREELANCER, SALON_TYPE_SALON]).isRequired,
    showClientForm: PropTypes.bool,
    processingTimeCorrected: PropTypes.bool,
    aptViolations: PropTypes.object,
    showViolations: PropTypes.bool,
    addresses: PropTypes.array,
    address: PropTypes.object,
    addressId: PropTypes.number
};

BookForm.defaultProps = {
    showClientForm: true,
    aptViolations: {},
    showViolations: false

};
