/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 10.52
 */

import React from 'react';

import {GalleryWidget} from "./gallery-widget";
import {
    initGallery, loadGallery, openFullScreen, presetGallery,
    resetGallery
} from "../../ac/gallery/generic-gallery-actions";


class GalleryConfigurator {

    constructor(galleryId)   {
        this.galleryId = galleryId;

        this.onItemClickHandler = (elementIndex) => {

            openFullScreen({
                galleryId,
                startIndex: elementIndex,
                store: this.store,
                favoritesEnabled: this.favoritesEnabled,
                classResolver: this.defineFullscreenClassResolver()

            });
        };

        this.favoritesEnabled = false;
    }

    setGalleryStore(store) {
        this.store = store;

        return this;
    }

    setFavoritesEnabled(enabled = true) {
        this.favoritesEnabled = enabled;

        return this;
    }

    setSessionStore(store)  {
        this.sessionStore = store;

        return this;
    }


    onMoreButtonClick(fn)   {
        this.onMoreButtonHandler = fn;

        return this;
    }

    setItemClass(Constructor) {
        this.itemClass = Constructor;

        return this;
    }

    setItemClassResolver(resolver: Function)    {
        this.itemClassResolver = resolver;

        return this;
    }

    setFullscreenClass(Constructor) {
        this.fullscreenClass = Constructor;

        return this;
    }

    setFullscreenClassResolver(resolver: Function)  {
        this.fullscreenClassResolver = resolver;

        return this;
    }


    setItemClickHandler(fn: Function)  {
        this.onItemClickHandler = fn;

        return this;
    }

    setNoItemsText(text)    {
        this.noItemsText = text;

        return this;
    }

    /**
     * @private
     */
    defineItemClassResolver()    {
        if (this.itemClass)   {
            return item => {
                return this.itemClass;
            }
        }
        else {
            if (this.itemClassResolver)   {
                return this.itemClassResolver;
            }
            else {
                throw new Error("Gallery configuration issue. `itemClass` or `itemClassResolver` should be specified");
            }
        }
    }

    /**
     * @private
     */
    defineFullscreenClassResolver()    {
        if (this.fullscreenClass)   {
            return item => {
                return this.fullscreenClass;
            }
        }
        else {
            if (this.fullscreenClassResolver)   {
                return this.fullscreenClassResolver;
            }
            else {
                throw new Error("Gallery configuration issue. `fullscreenClass` or `fullscreenClassResolver` should be specified");
            }
        }
    }

    setGalleryLoader(fn: Function)  {
        this.galleryLoader = fn;

        return this;
    }

    /**
     * @protected
     * @returns {{}}
     */
    getParamsFromStore()    {
        let storeData = this.store.getStore().gallery;
        if (typeof storeData.params === 'object') {
            return storeData.params;
        }

        console.warn("Store initial params isn't set for gallery", this.galleryId, this.store);

        return {};

    }

    preset(presetParams = {}) {
        presetGallery(this.galleryId, presetParams);
    }

    reset() {
        resetGallery(this.galleryId);
    }

    init(initialParams = {})  {
        initGallery(this.galleryId, this.galleryLoader);

        initialParams = Object.assign(this.getParamsFromStore(), initialParams);

        loadGallery(this.galleryId, initialParams);
    }

    getGallery() {

        let widget = <GalleryWidget
            galleryId={this.galleryId}
            store={this.store}
            favoritesEnabled={this.favoritesEnabled}
            sessionStore={this.sessionStore}
            onMoreButtonHandler={this.onMoreButtonHandler}
            onItemClickHandler={this.onItemClickHandler}
            itemClassResolver={this.defineItemClassResolver()}
            noItemsText={this.noItemsText}
        />;

        return widget;
    }
}

export function createGallery(id) {
    return new GalleryConfigurator(id);
}