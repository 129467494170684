/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 13.04.2017
 * Time: 12:25
 */
import {
    BOOK_PERSIST_SUCCESS,
    DELETE_PERSONAL_APPOINTMENT,
    LOAD_PERSONAL_APPOINTMENTS,
    MODAL_CHANGE,
    PERSONAL_APPOINTMENT_DETAIL_MODAL,
    REFRESH_APPOINTMENT_LIST
} from "../constants";

import { loadAppointments } from "../ac/personal-apt";
import { modalHide } from "../ac/modals";
import BaseStore from "./base-store";

class PersonalAptStore extends BaseStore {


    constructor(...args) {
        super(...args);

        this.store = {
            appointments: [],
            aptTotalCount: 0,
            filterParams: [],
            appointment: {},
            btnDisabled: false
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;
            switch (type) {

                case REFRESH_APPOINTMENT_LIST:
                case BOOK_PERSIST_SUCCESS:
                    loadAppointments(this.store.filterParams);
                    break;

                case LOAD_PERSONAL_APPOINTMENTS:
                    console.log(payload.requestData, "ANSWER");

                    this.store.appointments = payload.requestData;
                    this.store.aptTotalCount = payload.requestTotalCount;
                    this.store.filterParams = payload.requestParams;
                    this.store.btnDisabled = false;
                    this.emitChange();
                    break;

                case DELETE_PERSONAL_APPOINTMENT:
                    this.deleteApt(payload);
                    this.emitChange();
                    break;

                case MODAL_CHANGE:
                    if (payload.type === PERSONAL_APPOINTMENT_DETAIL_MODAL) {
                        this.store.appointment = this.getAppointmentById(payload.options.aptId);
                    }
                    this.store.btnDisabled = false;
                    break;


                default:
                    return true;
            }
        });
    }

    deleteApt(aptID) {
        const aptList = this.store.appointments;
        const indexToRemove = aptList.findIndex(apt => apt.id === aptID);
        aptList.splice(indexToRemove, 1);
        this.store.appointments = aptList;
        this.store.aptTotalCount = this.store.aptTotalCount - 1;
        this.store.btnDisabled = false;
        setTimeout(() => modalHide(), 0);
    }

    getAppointmentById(aptId) {
        let aptList = this.store.appointments;
        let appointment = aptList.filter(function(apt) {
                return apt.id === aptId;
            }
        );
        return appointment[0];
    }

}

export default PersonalAptStore;
