/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.15.11
 * Time: 17:37
 */

export function violationNormalize(rawViolation) {

    let result = {};

    const repl = {
        username: "userName"
    };

    for (let prop in rawViolation) {
        if (repl.hasOwnProperty(prop)) {
            result[repl[prop]] = rawViolation[prop];
        }
        else {
            result[prop] = rawViolation[prop];
        }
    }

    return result;
}
