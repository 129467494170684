/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 7.5.17
 * Time: 13.15
 */

import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { toggleUnseenList } from "./../../ac/unseen-appointments-actions";
import { unseenAppointmentsStore } from "./../../stores";

export default class AlertDismissable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            unseenAptsCount: this.getStoreData()
        };

        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.toggleListState = this.toggleListState.bind(this);
        this.getStoreData = this.getStoreData.bind(this);

    }

    toggleListState(ev) {
        ev.preventDefault();
        toggleUnseenList();
    }

    getStoreData() {
        return unseenAppointmentsStore.store.list.length;
    }

    handleStoreChange() {
        this.setState({
            unseenAptsCount: unseenAppointmentsStore.store.list.length
        });
    }

    componentDidMount() {
        unseenAppointmentsStore.addChangeListener(this.handleStoreChange);
    }

    componentWillUnmount() {
        unseenAppointmentsStore.removeChangeListener(this.handleStoreChange);
    }

    render() {
        let visibility = !this.state.unseenAptsCount ? "hidden" : "";

        return (
            <Alert bsStyle="info" style={{ "visibility": visibility }}>
                <button className={"btn-link"}
                        onClick={this.toggleListState}>{`${this.state.unseenAptsCount} new`}</button>
            </Alert>
        );
    }
}

