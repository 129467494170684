/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.8.11
 * Time: 10:50
 *
 * @flow
 *
 */

import AppDispatcher from "./../../../dispatcher";

import { xhrChangeEmail } from "../../../xhr/personal-profile-xhr";


import {
    PROFILE_PERSONAL_EMAIL_CHANGE_ERROR,
    PROFILE_PERSONAL_EMAIL_CHANGE_SUCCESS,
    PROFILE_PERSONAL_EMAIL_FORM_CHANGE,
    PROFILE_PERSONAL_EMAIL_FORM_CLEAR,
    PROFILE_PERSONAL_EMAIL_FORM_PROCESS
} from "../../../constants";


export function profileEmailFormChange(patch: { name: string, value: string }): void {

    AppDispatcher.dispatch({
        type: PROFILE_PERSONAL_EMAIL_FORM_CHANGE,
        payload: patch
    });

}

export function processChangeEmailForm(): void {

    AppDispatcher.dispatch({
        type: PROFILE_PERSONAL_EMAIL_FORM_PROCESS
    });

}

export function clearEmailChangeForm(): void {

    AppDispatcher.dispatch({
        type: PROFILE_PERSONAL_EMAIL_FORM_CLEAR
    });

}

export function sendNewEmailData(data: { newEmail: string, password: string }): void {
    xhrChangeEmail(data).then(
        () => {
            AppDispatcher.dispatch({
                type: PROFILE_PERSONAL_EMAIL_CHANGE_SUCCESS
            });
        },
        () => {
            AppDispatcher.dispatch({
                type: PROFILE_PERSONAL_EMAIL_CHANGE_ERROR
            });
        }
    );
}

