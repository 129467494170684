/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.9.17
 */

class MetaTagRenderer {

    render(tagSpecSet) {
        this.clearAllMeta();

        let elements = tagSpecSet.map(tagSpec => {
            let element = document.createElement(tagSpec.tag);
            if (tagSpec.body) {
                element.innerHTML = tagSpec.body;
            }

            tagSpec.attrs.forEach(attrSpec => {
                element.setAttribute(attrSpec.name, attrSpec.value);
            });

            return element;

        });

        let head = document.querySelector("head");
        elements.forEach(element => head.appendChild(element));


    }

    clearAllMeta() {
        let metaTags = document.querySelectorAll("meta");
        for (let i = 0; i < metaTags.length; i++) {
            if (metaTags[i]) {
                metaTags[i].remove();
            }
        }

        this.queryRemove("title");
        this.queryRemove("link[rel=canonical]");
    }

    queryRemove(query) {
        let element = document.querySelector(query);
        if (element) {
            element.remove();
        }
    }
}

export default new MetaTagRenderer({
    frontend: true
});
