/**
 * User: eleonora
 * Username: x_a_x_a
 * Company: U6 SIA
 * Date: 28.3.17
 * Time: 20.29
 */

import { sessionNormalize } from "../normal-form/user-derived-forms/session";
import { violationNormalize } from "../normal-form/violations-normalize";
import { xhr } from "../service/api-client-2";
import { alterAuthRequest } from "../auth";
import { Request } from "../service/api-client-2/request";

export const xhrSendClientSignUpData = function(formdata) {
    return xhr()
    .skipAuth()
    .suppressNotifications()
    .post("@Q12_client_sign_up")
    .data(formdata)
    .alterWith(alterAuthRequest)
    .end()
    .then((request: Request) => {
        //console.log('ok1');

        return Promise.resolve({
            request,
            response: sessionNormalize(request.response.response),
            options: {
                groups: request.getDataGroups()
            }
        });
    }, (request: Request) => {
        //  console.log('fail1');
        //catch server problem
        let { response } = request;
        response.errors = violationNormalize(response.errors);

        //make a reject
        return Promise.reject({
            response,
            options: {
                groups: request.getDataGroups()
            }
        });
    });

};

export const xhrSendProSignUpData = function(formdata) {
    return xhr()
    .skipAuth()
    .suppressNotifications()
    .post("@Q13_pro_sign_up")
    .data(formdata)
    .alterWith(alterAuthRequest, "userSalon")
    .end()
    .then((request: Request) => {
        return {
            session: sessionNormalize(request.response.response),
            options: {
                groups: request.getDataGroups()
            },
            request
        };
    }, (request: Request) => {
        //catch server problem
        let { response } = request;
        response.errors = violationNormalize(response.errors);

        //make a reject
        return Promise.reject({
            response,
            options: {
                groups: request.getDataGroups()
            }
        });
    });
};

export const xhrSendListYourBusiness = function(formData) {
    return xhr()
    .suppressNotifications()
    .post("@Q105_list_your_business")
    .data(formData)
    .end()
    .then((request: Request) => {
        let { response } = request;

        return sessionNormalize(response);

    }, (request: Request) => {
        let { response } = request;

        response.errors = violationNormalize(response.errors);

        //make a reject
        return Promise.reject(response);

    });
};
