/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 01.10.2020
 * Time: 21:51
 */

import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { modalHide } from "../../ac/modals";

export default class ProProductsVideoModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        modalHide();
    }

    render() {

        return (
            <div>
                <Modal.Header>
                    <div className="close-layer">
                        <Button className="close" onClick={this.closeModal}>
                            <span>×</span>
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body className="video-modal__body">
                    <div className={"iframe-container"}>
                        <iframe className="iframe-responsive"
                                src={this.props.source}
                                frameBorder="0"
                                title={"pro-product"}
                                allow={"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}
                                allowFullScreen={true}
                                tabIndex="-1"/>
                    </div>
                </Modal.Body>
            </div>
        );
    }
}
