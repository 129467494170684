/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 15.11.2017
 * Time: 10:50
 */

import React, { Component } from 'react';
import ModalComponent from './modal/modal-component';

export default class AppMobile extends Component {


    render() {

        return (
            <div className="frizo-app">
                <div className='container main-content'>
                    {this.props.children}
                </div>
                <ModalComponent />
            </div>
        )
    }
}