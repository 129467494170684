/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.9.17
 */

import config from "./../config";

class Analytics {
    constructor(config) {
        this.enabled = !config.analytics_disable;
    }

    trackNavigation() {
        if (!this.enabled) return;

        //custom navigation analytics (not necessary when GTM in action)
    }

    track(actionName) {
        console.log('track', actionName, this.enabled);
        if (!this.enabled) return;

        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            "event": actionName,
            "category": "custom_events"
        });

        /*global FB */
        if (typeof(FB) !== 'undefined') {
            if (typeof(FB.AppEvents) !== 'undefined') {
                if (typeof(FB.AppEvents.logEvent) !== 'undefined') {
                    let ret = FB.AppEvents.logEvent(actionName);
                    console.log('ret', ret);
                }
            }
        }

    }

    getTrackCallback(actionName) {
        return () => {
            this.track(actionName);
        };
    }
}

export default new Analytics(config);
