/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.10.17
 */

import IsNotEqual, { VALUE_IS_EQUAL } from "./is-not-equal";
import IsNotDefaultDropdownValue from "./is-not-default-dropdown-value";

import violationBuilder from "./../violation-builder";
import BaseValidator from "./base-validator";

export default class IsNotEqualValidator extends BaseValidator {

    static getValidConstraints() {
        return [IsNotEqual, IsNotDefaultDropdownValue];
    }

    validate(constraint, field, root = null) {

        this.assertConstraintIsAllowed(constraint);

        let value = root ? root[field] : field;

        let isValid = value !== constraint.value;
        if (!isValid) {
            return violationBuilder.build(constraint.message, {
                "{value}": constraint.value
            }, field, root, VALUE_IS_EQUAL, value);
        }

        return true;
    }
}
