/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.29.9
 * Time: 16:10
 */

export default class AbstractFilter {

    constructor() {

        if (this.constructor.name === "AbstractFilter") {
            throw new TypeError("Cannot construct Abstract instances directly");
        }

        if (this.getName === undefined) {
            throw new TypeError("Must override method");
        }

        if (this.filter === undefined) {
            throw  new TypeError("Must override method");
        }
    }
}
