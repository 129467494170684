/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.01.18
 * Time: 11.00
 */

import AbstractGalleryStore from "../gallery2/abstract-gallery-store";
import { GALLERY2_SALON_PROMO_GALLERY_ID, MODE_ARTIST_SELECTABLE } from "../../frizo-shared-constants";
import { GALLERY_ENTER_PARAMS, GALLERY_FILTER_PRESET, GALLERY_FILTER_RESET, GALLERY_LOAD } from "../../constants";

export default class PromoGalleryStore extends AbstractGalleryStore {

    constructor(...args) {
        super(GALLERY2_SALON_PROMO_GALLERY_ID, ...args);

        this.setGenericFilterAware(true);


        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case GALLERY_LOAD:
                    if (!payload || payload.galleryId !== this.galleryId) return false;
                    this.store.gallery.params.salonId = payload.salonId;

                    //fall to super
                    return true;

                case GALLERY_FILTER_RESET:
                    if (!payload || payload.galleryId !== this.galleryId) return false;
                    if (this.store.gallery.loading) return false;

                    this.reloadGallery({
                        artistId: null,
                        categoryId: null,
                        serviceId: null
                    });

                    break;

                case GALLERY_FILTER_PRESET:

                    if (payload.artistId) {
                        this.store.gallery.params.artistId = payload.artistId;
                    }

                    if (payload.serviceId) {
                        this.store.gallery.params.serviceId = payload.serviceId;
                    }

                    break;


                case GALLERY_ENTER_PARAMS:
                    if (!payload || payload.galleryId !== this.galleryId) return false;

                    let filter = Object.assign({}, this.store.gallery.params);
                    if (payload.params.artistId) {
                        filter.artistId = Number(payload.params.artistId);
                        filter.categoryId = 0;
                        filter.serviceId = 0;
                    }
                    else {
                        if (payload.params.categoryId) {
                            filter.categoryId = Number(payload.params.categoryId);
                            filter.serviceId = 0;
                        }
                        if (payload.params.serviceId) {
                            filter.serviceId = Number(payload.params.serviceId);
                        }
                    }
                    console.log("result params", filter);
                    this.reloadGallery(filter);
                    this.updateFilter();

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialGallery() {
        return Object.assign(super.getInitialGallery(), {
            totalIsKnown: false,
            params: {
                offset: 0,
                count: 12,
                mode: MODE_ARTIST_SELECTABLE,
                salonId: null,

                artistId: 0,
                categoryId: 0,
                serviceId: 0
            }
        });
    }

    // loadGallery()   {
    //
    //     let filter = Object.assign({}, this.store.gallery.params);
    //     ['artistId', 'serviceId'].forEach(key => {
    //         if (this.stores.galleryFilterStore.getStore().filter[key])  {
    //             filter[key] = this.stores.galleryFilterStore.getStore().filter[key];
    //         }
    //     });
    //
    //     console.log('filter', filter);
    //
    //     loadGallery(this.galleryId, filter);
    // }

    nextPage() {
        this.store.gallery.params.offset++;
        this.store.gallery.loading = true;
        this.store.gallery.scrollMode = true;

        return this.loadGallery();
    }
}
