/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.7.17
 */

import React from 'react';
import { Button } from 'react-bootstrap';
import WidgetHeader from './widget-header';
import PaymentSource from '../../../payments/elements/payment-source';
import {
    addNewPayCard,
    setDefaultPayCard,
    removePayCard
} from '../../../../ac/payments/payments-professional-pay';
import { ADD_NEW_CARD_MODAL } from './../../../../constants';
import { modalChange } from './../../../../ac/modals';

function openAddCard() {

    modalChange(ADD_NEW_CARD_MODAL, {
        noCard: false,
        action: addNewPayCard,
        professional: true
    });
}

export default function PayWidget(props) {

    return <div>
        <div className="subscription-profile__plan-title ">
            {props.title}
            <Button onClick={openAddCard} className="fr-btn-add pull-right">ADD CARD</Button>
        </div>

        <WidgetHeader/>
        {props.sources.length === 0 &&
        <div className="fr-mt fr-text-center">No cards yet</div>}
        <div className="bank-card-profile__payment-sources">

            {props.sources.map(account => <PaymentSource
                key={account.id} {...account}
                isDefault={account.id === props.defaultSourceId}
                setDefaultSource={setDefaultPayCard.bind(this, account.id)}
                removeSource={removePayCard.bind(this, account.id)}
            />)}
        </div>

    </div>;

}