/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 24.7.17
 */

import React, {Component} from 'react';
import {Button, Col, Row, Modal, FormGroup} from 'react-bootstrap';
import {paidApprovalPopupStore} from './../../stores';
import {updateTip, orderApprove, orderDecline, changePaymentSource} from './../../ac/payment-approval-ac';
import {
    FORM_MODE_CREATE,
    ADD_NEW_OPTION
} from './../../frizo-shared-constants';
import {
    ADD_NEW_CARD_MODAL,
    PAYMENT_APPROVAL_MODAL
} from './../../constants';
import ChargesPanel from './elements/charges-panel';
import TipSelector from './elements/tip-selector';
import CardSelector from './elements/card-selector';
import {loadMyCards} from './../../ac/payments/payments-personal';
import Violation from '../helper-components/violation';
import {modalChange} from './../../ac/modals';
import {buildSequence} from './../../ac/modal-sequence';
import {addNewPaymentSource} from '../../ac/payments/payments-personal';
import gradientFill from './../../helper/gradient-fill';
import {printCentToUsd} from "../../helper/money-converter";

export default class PaymentApprovalPopup extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            tipPercent: 0,
            tipCustomPercent: 0,
            tipCustomSelected: false,

            paymentOrderId: null,

            cards: [],
            paymentSourceId: '',

            tipValue: 0,
            taxValue: 0,
            taxPercent: 0,
            serviceAndTax: 0,
            serviceTotalEntered: 0,
            total: 0,

            services: [],

            mode: FORM_MODE_CREATE,

            violations: {},
            cardsReady: false,
            modalReady: false,
            discountReady: false,
            btnDisabled: false,

            discountSolution: {
                reservedAmount: null,
                reason: ''
            }

        }, this.getStoreData());

        this.storeUpdate = this.storeUpdate.bind(this);
        this.decline = this.decline.bind(this);
        this.payNow = this.payNow.bind(this);
        this.addFirstCard = this.addFirstCard.bind(this);
    }


    componentDidMount() {
        paidApprovalPopupStore.addChangeListener(this.storeUpdate);

        loadMyCards()
    }

    componentWillUnmount() {
        paidApprovalPopupStore.removeChangeListener(this.storeUpdate);
    }

    getStoreData() {

        return paidApprovalPopupStore.getStore();
    }

    storeUpdate() {
        let object = Object.assign({
            ready: true
        }, this.getStoreData());

        console.log('store data', object);
        this.setState(object);
    }

    decline() {
        this.setState({btnDisabled: true});
        orderDecline(this.state.paymentOrderId, this.state.total);
    }

    payNow() {
        this.setState({btnDisabled: true});
        orderApprove(
            this.state.paymentOrderId,
            this.state.paymentSourceId,
            this.state.tipValue,
            this.state.total,
            this.state.discountSolution
        );
    }

    cardChange(cardId) {
        changePaymentSource(cardId);
    }

    newCardInterceptor(token) {
        return addNewPaymentSource(token).then(positiveResult => {
            console.log('positiveResult', positiveResult);

            changePaymentSource(positiveResult.default_source);

            return positiveResult;
        })
    }

    addFirstCard() {

        buildSequence()
            .add(ADD_NEW_CARD_MODAL)
            .withOptions({
                noCard: false,
                action: this.newCardInterceptor
            })
            .add(PAYMENT_APPROVAL_MODAL)
            .run();
    }

    addAnotherCard() {

        modalChange(ADD_NEW_CARD_MODAL, {
            noCard: false
        });
    }

    render() {
        if (!this.state.modalReady) return <div className={"loading-message"}>Loading...</div>;

        let showCardLackLabel = this.state.cardsReady && this.state.cards.length === 0;

        return <div>
            <Modal.Header closeButton style={{'background': gradientFill()}}>
                <h2>Payment</h2>
            </Modal.Header>

            <Modal.Body className="payment-approval">
                {showCardLackLabel && <Row>
                    <Col xs={12} className="fr-text-center error-message">
                        <button className="btn-link no-card" onClick={this.addFirstCard}>
                            No valid card on file. Click to add one...
                        </button>
                    </Col>
                </Row>}
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <CardSelector
                                value={this.state.paymentSourceId}
                                onChange={this.cardChange}
                                cards={this.state.cards}
                                addNewOptionValue={ADD_NEW_OPTION}
                            />
                            <Violation violationSet={this.state.violations.paymentSourceId} visible={true}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <TipSelector
                            percentValue={this.state.tipPercent}
                            customPercentValue={this.state.tipCustomPercent}
                            custom={this.state.tipCustomSelected}
                            sum={this.state.serviceTotalEntered}
                            onChange={updateTip}
                        />
                        <Violation violationSet={this.state.violations.tipValue} visible={true}/>

                    </Col>
                    <FormGroup>
                        <Col md={12} xs={12} className="charges-panel">
                            <ChargesPanel
                                serviceTotalEntered={this.state.serviceTotalEntered}
                                serviceAndTax={this.state.serviceAndTax}
                                tax={this.state.taxValue}
                                tip={this.state.tipValue}
                                total={this.state.total}
                                services={this.state.services}
                            />
                        </Col>
                    </FormGroup>

                </Row>
                <Row>
                    <Violation violationSet={this.state.violations.general} visible={true}/>
                </Row>
                {this.state.discountReady && this.state.discountSolution.reservedAmount !== 0 &&
                <Row className={"bonus-panel"}>
                    <Col md={7} xs={7}>
                        Frizo referral bonus
                    </Col>
                    <Col md={5} xs={5}>
                        <div
                            className="fr-serv-price price-span">{printCentToUsd(this.state.discountSolution.reservedAmount)}</div>
                    </Col>
                    <Col md={7} xs={7}>
                        <div className={"title"}>You pay</div>
                    </Col>
                    <Col md={5} xs={5} className={"title"}>
                        <div
                            className="fr-serv-price price-span pay">{printCentToUsd(this.state.total - this.state.discountSolution.reservedAmount)}</div>
                    </Col>
                </Row>}
            </Modal.Body>
            <Modal.Footer className="card-add-footer payment-approval">
                <div>
                    <div className="pull-left">
                        <Button onClick={this.decline} className="btn fr-btn-remove"
                                disabled={this.state.btnDisabled}>DECLINE</Button>
                    </div>
                    <div className="pull-right">
                        <Button onClick={this.payNow} className="btn fr-btn-add"
                                disabled={this.state.btnDisabled}>PAY NOW</Button>
                    </div>
                </div>

            </Modal.Footer>
        </div>
    }
}