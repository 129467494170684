/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 27.04.2018
 * Time: 11:56
 */

import React, {Component} from 'react';
import {FormGroup, Button} from 'react-bootstrap';
import MaskedInput from 'react-bootstrap-maskedinput';
import {xhrZipResolve} from '../../xhr/my-salon-xhr';
import config from './../../config';
import GeoCode from './../../service/geo-code';
import Violation from '../helper-components/violation';

export default class AddressForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            streetAddress: '',
            suite: '',
            zip: '',
            city: '',
            zipDisabled: true,
            streetAddressDisabled: false,
            suiteDisabled: true
        };

        this.handleZipChange = this.handleZipChange.bind(this);
        this.zipResolve = this.zipResolve.bind(this);
        this.handleStreetAddressChange = this.handleStreetAddressChange.bind(this);
        this.onStreetAddressReset = this.onStreetAddressReset.bind(this);
        this.onSuiteChange = this.onSuiteChange.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }


    zipResolve(value) {
        xhrZipResolve(value).then(parsedLocation => {
                if (parsedLocation) {
                    this.props.onZipChange({
                        'city': parsedLocation.city + ', ' + parsedLocation.state
                    });
                }
                else {
                    this.props.onZipChange({'city': ""});
                }
            },

            response => {
                this.setState({zipDisabled: false});
            });
    }


    fetchData(address) {
        return GeoCode.getLocationFromAddress(address);
    }

    handleZipChange(e) {
        let rawValue = e.target.value.replace(/\D+/g, '');
        if (rawValue.length === 5) {
            this.zipResolve(rawValue);
        }
        this.props.onChange({zip: e.target.value});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.address !== this.props.address) {
            this.setState({
                streetAddress: nextProps.address,
                streetAddressDisabled: nextProps.address.length,
                zipDisabled: nextProps.zip.length === 5
            });
        }
    }

    handleStreetAddressChange(e) {
        this.setState({streetAddress: e.target.value});
        let google = window.google;

        if (typeof google === 'undefined') {
            console.warn('Google Places was not initialized.');
            return;
        }

        let {places} = google.maps;

        let options = {
            types: ['geocode'],
            componentRestrictions: config.autocompleteOptions.componentRestrictions,
            key: config.googleGeocoderApiKey
        };

        let input = this.refs.addressInput;

        if (!input._autocomplete) {
            input._autocomplete = new places.Autocomplete(input, options);
            input._autocomplete.addListener('place_changed', () => {
                let place = input._autocomplete.getPlace();
                //console.log("GOOGLE", place.address_components);
                let addressArray = [];
                let address = '';
                let zip = '';
                let data = {};
                if (place.address_components) {
                    for (let i = 0; i < place.address_components.length; i++) {
                        let type = place.address_components[i].types[0];
                        addressArray[type] = place.address_components[i].long_name || '';
                    }
                    address = [
                        ( addressArray['street_number'] || addressArray['locality']),
                        ( addressArray['route']),
                        //(addressArray['locality']),
                    ].join(' ');
                    zip = addressArray['postal_code'] || '';

                    if (zip.length === 5) {
                        this.zipResolve(zip);
                    }

                    data.zip = zip;
                    data.address = address;
                    this.fetchData(place.formatted_address).then((newState) => {
                        if (newState.location !== null) {
                            data.lat = newState.location.lat || '';
                            data.lon = newState.location.lon || '';
                        }
                        this.props.onChange(data)
                    });

                    this.setState({
                        streetAddress: address,
                        zip: zip,
                        zipDisabled: zip.length === 5 || !zip.length,
                        streetAddressDisabled: true,
                        suiteDisabled: false
                    });
                }
            })
        }
    }

    onStreetAddressReset() {
        let data = {
            address: '',
            zip: '',
            city: '',
            suite: '',
            suiteDisabled: true,
        };
        this.setState(Object.assign({
            zipDisabled: false,
            streetAddressDisabled: false,
            streetAddress: ''
        }, data));
        this.props.onChange(data);

    }

    onSuiteChange(e) {
        this.setState({suite: e.target.value});
        this.props.onChange({suite: e.target.value});
    }

    render() {
        return (
            <div>
                <FormGroup>
                    <div className="col-sm-8 col-xs-8">
                        <input id="place_autocomplete" type="text" name="address"
                               onChange={this.handleStreetAddressChange}
                               ref={'addressInput'}
                               className="form-control" placeholder="Street address"
                               readOnly={this.state.streetAddressDisabled ? "readOnly" : ""}
                               value={this.state.streetAddress}
                        />
                        {this.state.streetAddress.length > 0 && <Button onClick={this.onStreetAddressReset}
                                                                        className="btn-reset address fr-btn pull-right">
                            <span className="glyphicon glyphicon-remove"></span></Button>}
                        <Violation violationSet={this.props.violations.address}
                                   visible={!this.props.formIsValid}/>
                    </div>
                    <div className="col-sm-4 col-xs-4">
                        <input type="text" name="suite" onChange={this.onSuiteChange}
                               value={this.props.suite}
                               className="form-control" placeholder="Suite"
                               disabled={this.state.suiteDisabled}
                        maxLength={5}/>
                    </div>

                </FormGroup>
                <FormGroup>
                    <div className="col-sm-4 col-xs-4">
                        <MaskedInput
                            id="zip"
                            value={this.props.zip}
                            type={"text"}
                            name={"zip"}
                            required={"required"}
                            className={"form-control fr-masked-zip"}
                            placeholder={"ZIP"}
                            autoComplete={"off"}
                            mask={"11111"}
                            maxLength={"5"}
                            readOnly={this.state.zipDisabled ? "readOnly" : ""}
                            disabled={this.state.zipDisabled}
                            onChange={this.handleZipChange.bind(this)}

                        />
                        <Violation violationSet={this.props.violations.zip} visible={!this.props.formIsValid}/>


                    </div>
                    <div className="col-sm-8 col-xs-8">
                        <input type="text" name="city"
                               required="required" className="form-control" readOnly="readOnly"
                               placeholder="City, State" value={this.props.city}/>
                    </div>
                </FormGroup>
            </div>
        );
    }
}


AddressForm.defaulProps = {
    zip: '',
    city: '',
    address: '',
    suite: '',
    onChange: () => {},
    onZipChange: () => {}
};