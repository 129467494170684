/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 4/25/17
 * Time: 14:21
 *
 * @flow
 *
 */

import React, {Component} from 'react';
import {Modal, FormGroup} from 'react-bootstrap';
import Violation from '../helper-components/violation';
import {modalHide} from '../../ac/modals';


import {
    profilePasswordFormChange,
    clearPasswordChangeForm,
    processChangeEmailForm
} from '../../ac/profile/personal/password-change-form-actions';

import {changePasswordStore} from '../../stores';
import gradientFill from './../../helper/gradient-fill';

type TState = {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
    formIsValid: boolean,
    violations: {
        oldPassword: string,
        newPassword: string,
        confirmPassword: string
    },
    showMessage: boolean,
    showError: boolean,
    modal: {
        show: boolean,
        data: string
    }

};


export default class ChangePasswordModal extends Component<null, TState> {


    constructor(props: any) {
        super(props);

        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            formIsValid: true,
            violations: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            showMessage: false,
            showError: false,
            modal: {
                show: false,
                data: ''
            }
        };
        (this: any).clear = this.clear.bind(this);
        (this: any).handleStoreChange = this.handleStoreChange.bind(this);
        (this: any).changePassword = this.changePassword.bind(this);
        (this: any).close = this.close.bind(this);

    }


    handleStoreChange() {
        this.setState(changePasswordStore.getStore());
    }

    close() {
        modalHide()
    }

    handleChange(event: SyntheticEvent<HTMLButtonElement>) {

        let name = event.currentTarget.name;
        let value = event.currentTarget.value;

        profilePasswordFormChange({name, value})
    }

    clear(e: Event) {
        e.preventDefault();
        clearPasswordChangeForm();
    }

    changePassword(e: Event) {
        e.preventDefault();
        processChangeEmailForm();
    }


    componentDidMount() {
        changePasswordStore.addChangeListener(this.handleStoreChange);
    }

    componentWillUnmount() {
        changePasswordStore.removeChangeListener(this.handleStoreChange);
    }

    render() {
        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>Change password</h2>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form name="changePassword" method="post" className="form-horizontal">
                            <FormGroup>
                                <div className="col-sm-12">
                                    <input type="password" name="oldPassword" required="required"
                                           value={this.state.oldPassword}
                                           className="form-control" placeholder="Current Frizo password"
                                           onChange={this.handleChange}/>
                                    <Violation violationSet={this.state.violations.oldPassword}
                                               visible={!this.state.formIsValid}/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="col-sm-12">
                                    <input type="password" name="newPassword" required="required"
                                           value={this.state.newPassword}
                                           className="form-control" placeholder="New password"
                                           onChange={this.handleChange}/>
                                    <Violation violationSet={this.state.violations.newPassword}
                                               visible={!this.state.formIsValid}/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="col-sm-12">
                                    <input type="password" name="confirmPassword" required="required"
                                           value={this.state.confirmPassword}
                                           className="form-control" placeholder="Retype new password"
                                           onChange={this.handleChange}/>
                                    <Violation violationSet={this.state.violations.confirmPassword}
                                               visible={!this.state.formIsValid}/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="col-sm-12">
                                    <button name="clear_button" className="btn fr-btn--clear pull-left clear-button "
                                            onClick={this.clear}>Clear
                                    </button>
                                    <button name="email_change" className="btn fr-btn pull-right"
                                            onClick={this.changePassword}>Change
                                    </button>
                                </div>

                            </FormGroup>
                            {this.state.showMessage && <div className="info-message fr-text-center">Password changed.</div>}

                            {this.state.showError && <div className="error-message fr-text-center">Something went wrong, please try again.</div>}
                        </form>
                    </div>
                </Modal.Body>
            </div>
        );
    }
}

