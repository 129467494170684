/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 11.7.17
 */

import BaseStore from "../base-store";
import {
    ADD_NEW_BANK_ACCOUNT_MODAL,
    COMMON_MODAL_CLEAR,
    COMMON_MODAL_SAVE,
    COMMON_MODAL_SAVE_FAIL,
    COMMON_MODAL_UPDATE_DATA,
    MODAL_CHANGE,
    MODAL_HIDE
} from "../../constants";
import Validator from "./../../validation/validator";
import { FORM_MODE_CREATE, LEGAL_ENTITY_TYPE_INDIVIDUAL } from "./../../frizo-shared-constants";

export default class PaymentSourceFormStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case MODAL_CHANGE:
                    if (payload.type === ADD_NEW_BANK_ACCOUNT_MODAL) {
                        this.store.active = true;

                        if (payload.options && payload.options.mode) {
                            this.store.mode = payload.options.mode;
                        }

                        if (this.store.mode === FORM_MODE_CREATE) {
                            this.copyDataFromSession();
                        }
                    }
                    break;

                case MODAL_HIDE:
                    this.store.active = false;
                    break;

                case COMMON_MODAL_SAVE:
                    if (!this.store.active) return true;

                    this.buildValidation(this.store.legalEntityType);

                    if (this.validate()) {

                        console.log("SYNC", this.store);

                        // let syncer = new PaymentProfileSyncer();
                        // syncer.setMode(this.store.mode);
                        // syncer.putFormData(this.store);
                        // syncer.persist();
                    }

                    break;

                case COMMON_MODAL_CLEAR:
                    if (!this.store.active) return true;
                    this.resetStore();
                    console.log("reset", this.store);
                    break;

                case COMMON_MODAL_UPDATE_DATA:
                    if (!this.store.active) return true;

                    Object.assign(this.store, payload);
                    Object.keys(payload).forEach(propName => {
                        delete this.store.violations[propName];
                    });

                    break;

                case COMMON_MODAL_SAVE_FAIL:
                    this.store.violations = payload.violations;
                    break;

                default:
                    return true;
            }

            this.emitChange();
        });

        this.copyDataFromSession = this.copyDataFromSession.bind(this);
    }

    getInitialStore() {
        return {
            legalEntityType: LEGAL_ENTITY_TYPE_INDIVIDUAL,
            businessAccountName: "",
            accountNumber: "",
            routingNumber: "",

            violations: {},
            active: this.store ? this.store.active : false,
            mode: FORM_MODE_CREATE
        };
    }

    copyDataFromSession() {
        let data = this.stores.session.getSession();

        if (data.business && data.salon) {
            this.store.businessAccountName = data.salon.title;
        }
        else {
            this.store.businessAccountName = data.firstName + " " + data.lastName;
        }
    }

    /**
     * @protected
     */
    buildValidation(legalEntityType) {
        this.validator = new Validator();

        this.validator.addConstraint("legalEntityType", "is-required");
        this.validator.addConstraint("businessAccountName", "is-required");
        this.validator.addConstraint("accountNumber", "is-required");
        this.validator.addConstraint("routingNumber", "is-required");
    }
}
