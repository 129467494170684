/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.05.2017
 * Time: 15:46
 */
import React, {Component} from 'react';
import stores from '../../stores';
import {getLegalDoc} from '../../ac/legal'


export default class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            privacyPolicy: stores.docs.store.legalDoc || {}
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        getLegalDoc('pp');
        stores.docs.addChangeListener(this.handleChange);
    }

    componentWillUnmount() {
        stores.docs.removeChangeListener(this.handleChange);
    }

    handleChange() {
        this.setState({
            privacyPolicy: stores.docs.store.legalDoc || {}
        });
    }

    render() {

        return (
            <div className="row">
                <div className="col-md-12">
                    <div dangerouslySetInnerHTML={{__html: this.state.privacyPolicy.content}}></div>
                </div>
            </div>
        );
    }
}