/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.2.17
 * Time: 17.12
 */

import IsAlpha, { VALUE_IS_NOT_ALPHA } from "./is-alpha";
import violationBuilder from "./../violation-builder";
import sprintf from "./../../helper/sprintf";
import BaseValidator from "./base-validator";

export default class IsAlphaValidator extends BaseValidator {

    static getValidConstraints() {
        return [IsAlpha];
    }

    validate(constraint, field, root = null) {
        if (!(constraint instanceof IsAlpha)) {
            throw new Error(sprintf("IsAlpha should be passed to IsAlphaValidator.validate(). %s given.",
                constraint.constructor.name));
        }

        let value = root ? root[field] : field;

        let regexp;

        switch (true) {
            case constraint.allowSpaces:
                regexp = /^[a-zA-Z\s]*$/;

                break;

            default:
                regexp = /^[a-zA-Z]*$/;

                break;
        }

        if (!regexp.test(value)) {
            return violationBuilder.build(constraint.message, {}, field, root, VALUE_IS_NOT_ALPHA, value);
        }

        return true;
    }
}
