/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.28.3
 * Time: 10:10
 */

import BaseStore from "./../../base-store";
import {
    PROFILE_SALON_SERVICE_ADDED,
    PROFILE_SALON_SERVICE_MODIFIED,
    PROFILE_SALON_SERVICE_REMOVE_FAILED,
    PROFILE_SALON_SERVICE_REMOVED,
    PROFILE_SERVICES_DATA_LOADED
} from "../../../constants";
import { findLeafs, isLeaf, updateIndex } from "../../../components/profile/professional/services/helpers";

export default class ProfileServiceStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            selectedServiceId: 0,

            services: [],
            servicesIndex: {},

            selectedServices: [],
            selectedServicesIndex: {},

            unselectedServices: [],
            unselectedServicesIndex: {},


            duration: [],
            processing: [],

            salonInfo: {},

            btnDisabled: false
        }
        ;


        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {

                case PROFILE_SERVICES_DATA_LOADED:
                    this.store.services = this.makeLeafsCorrections(payload.services);
                    this.updateServicesIndex();

                    this.store.duration = payload.duration;
                    this.store.processing = payload.processing;

                    this.store.selectedServices = this.makeSalonCorrection(payload.salonCorrections);
                    this.updateSelectedServicesIndex();

                    this.store.unselectedServices = this.makeUnselectedServices();
                    this.updateUnselectedServicesIndex();

                    break;

                case PROFILE_SALON_SERVICE_ADDED:
                    let rawService = this.store.servicesIndex[payload.serviceId];
                    let selected = Object.assign({}, rawService, payload);

                    this.store.selectedServices.push(selected);

                    this.updateSelectedServicesIndex();

                    this.store.unselectedServices = this.makeUnselectedServices();
                    this.updateUnselectedServicesIndex();

                    break;

                case PROFILE_SALON_SERVICE_REMOVED:
                    this.store.selectedServices = this.store.selectedServices.filter(service => Number(service.id) !== Number(
                        payload.id));
                    this.updateSelectedServicesIndex();

                    this.store.unselectedServices = this.makeUnselectedServices();
                    this.updateUnselectedServicesIndex();

                    this.store.btnDisabled = false;

                    break;
                case PROFILE_SALON_SERVICE_REMOVE_FAILED:
                    this.store.btnDisabled = false;

                    break;
                case PROFILE_SALON_SERVICE_MODIFIED:

                    this.store.selectedServices = this.store.selectedServices.map((service, index) => {
                        if (service.id === payload.serviceId) {
                            let sanitedPayload = Object.assign({}, payload);
                            delete sanitedPayload.id;

                            service = Object.assign(service, sanitedPayload);
                        }
                        return service;
                    });

                    this.updateSelectedServicesIndex();

                    break;

                default:
                    return true;

            }

            this.emitChange();

        });

    }


    updateServicesIndex() {
        this.store.servicesIndex = updateIndex(this.store.services);
    }

    updateSelectedServicesIndex() {
        this.store.selectedServicesIndex = updateIndex(this.store.selectedServices);

    }

    updateUnselectedServicesIndex() {
        this.store.unselectedServicesIndex = updateIndex(this.store.unselectedServices);
    }

    makeSalonCorrection(corrections) {

        return corrections.map(element => {
            let rawService = this.store.servicesIndex[element.serviceId];

            return Object.assign(rawService, element);
        });
    }

    makeLeafsCorrections(servicesList) {
        servicesList.forEach(service => {
            service.leaf = isLeaf(service, servicesList);
        });

        return servicesList;
    }

    makeUnselectedServices() {
        const leafs = findLeafs(this.store.services);
        const selected = this.store.selectedServicesIndex;

        return leafs.filter(leaf => {
            return !selected[leaf.id];
        });
    }

    getServices() {

        return this.store.services;
    }

    getServicesIndex() {

        return this.store.servicesIndex;
    }

    getSelectedServices() {

        return this.store.selectedServices;
    }

    getUnselectedServices() {

        return this.store.unselectedServices;
    }

    getDuration() {

        return this.store.duration;
    }

    getProcessing() {

        return this.store.processing;
    }

    getSalonInfo() {

        return this.store.salonInfo;
    }

    getBtnDisabled() {
        return this.store.btnDisabled;
    }
}
