/**
 * Created by Eleonora on 02-Feb-17.
 */

import AppDispatcher from "../dispatcher";
import { DOCS_LOADED, LOAD_LEGAL_DOC, SIGN_DOC } from "../constants";
import { xhrLoadLegalDoc, xhrLoadUnsignedDoc, xhrSignDocument } from "../xhr/legal-xhr";


export function getUnsignedDocuments() {
    xhrLoadUnsignedDoc().then(answer => {
        AppDispatcher.dispatch({
            type: DOCS_LOADED,
            payload: answer
        });
    });
}

export function signDocument(docId) {
    xhrSignDocument(docId).then(() => {
        AppDispatcher.dispatch({
                type: SIGN_DOC
            }
        );
    });
}

export function getLegalDoc(docType) {
    xhrLoadLegalDoc(docType).then(data => {
        AppDispatcher.dispatch({
                type: LOAD_LEGAL_DOC,
                payload: data
            }
        );
    });
}
