/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 30.3.17
 * Time: 7.48
 */

let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];

export default function printDayName(dayNumber) {
    return days[dayNumber];
}
