/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.7.17
 * Time: 9.24
 */

import ApiClient from "../../../service/api-client";

export function xhrRemoveCard(sourceId) {

    let url = ApiClient.getRouting().generate("Q100_delete_payment_source", { sourceId });

    return ApiClient.del(url).then(answer => {
        let [response] = answer;

        //console.log('response', response);

        return Promise.resolve(response);
    });
};
