/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.31.3
 * Time: 15:58
 */
import React, {Component} from 'react';
import {Row, Badge, Glyphicon} from 'react-bootstrap';
import toggleListDecorator  from '../decorators/toggleListDecorator'

class TableToggle extends Component {

    render() {

        let content  = null;
        if (this.props.open){
            content = this.props.children;
        }

        return <div>
            <Row className="fr-table-header">
                {this.props.name}
                <Badge className="fr-serv-badge">{this.props.count}</Badge> <Glyphicon glyph="menu-down" className="fr-fr fr-table-icon"
                                                             onClick={this.props.toggleOpen}/>
            </Row>
            {content}
        </div>
    }
}

export default toggleListDecorator(TableToggle);
