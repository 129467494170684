/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 4.7.17
 * Time: 19.17
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, FormGroup, FormControl, Image } from "react-bootstrap";
import Violation from "../../../helper-components/violation";
import ToggleGroup from "./../../../forms/toggle-group";
import { paymentProfileFormStore } from "./../../../../stores";
import { formDataChanged } from "./../../../../ac/profile/professional/cards-and-bank";
import {
    LEGAL_ENTITY_TYPE_INDIVIDUAL,
    LEGAL_ENTITY_TYPE_COMPANY,
    FORM_MODE_CREATE,
    FORM_MODE_UPDATE,
    FORM_MODE_VIEW
} from "./../../../../frizo-shared-constants";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { save, clear } from "./../../../../ac/common-modal-actions";
import { modalHide } from "./../../../../ac/modals";
import gradientFill from "./../../../../helper/gradient-fill";
import { loadStripeAccount } from "../../../../ac/payments/payments-professional";

export default class PaymentProfileForm extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            legalEntityType: LEGAL_ENTITY_TYPE_INDIVIDUAL,
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            dateOfBirthText: "",
            ssnLast4: "",
            businessName: "",
            businessUrl: "",
            businessTaxId: "",
            address: "",
            zip: "",
            city: "",
            state: "",
            violations: {},
            imageViolation: ""
        }, this.getStoreData());

        this.handlersCache = {};

        this.getOnChangeHandler = this.getOnChangeHandler.bind(this);
        this.createControl = this.createControl.bind(this);
        this.createMaskedControl = this.createMaskedControl.bind(this);
        this.storeUpdate = this.storeUpdate.bind(this);
        this.fieldIsEnable = this.fieldIsEnable.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.fieldIsNeeded = this.fieldIsNeeded.bind(this);
        this.onImageRemove = this.onImageRemove.bind(this);
    }

    fieldIsEnable(field) {
        return this.props.mode !== FORM_MODE_VIEW;
    }

    componentDidMount() {
        loadStripeAccount();
        paymentProfileFormStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        paymentProfileFormStore.removeChangeListener(this.storeUpdate);
    }

    getStoreData() {
        return paymentProfileFormStore.getStore();
    }

    storeUpdate() {
        //console.log('store update', this.getStoreData())
        this.setState(this.getStoreData());
    }

    inputHandler(field, e) {
        let value = typeof e === "object" ? e.target.value : e;

        formDataChanged(field, value);
    }

    getOnChangeHandler(field) {
        if (!this.handlersCache[field]) {
            this.handlersCache[field] = this.inputHandler.bind(this, field);
        }

        return this.handlersCache[field];
    }

    createControl(name, title, type = "text", style = "") {
        return <div>
            <FormControl disabled={!this.fieldIsEnable(name)} type={type} placeholder={title}
                         className={style}
                         value={this.state[name]}
                         onChange={this.getOnChangeHandler(name)} autoComplete="off"/>
            <Violation violationSet={this.state.violations[name]} visible={true}/>
        </div>;
    }

    createMaskedControl(name, title, mask, style = "") {
        return <div>
            <MaskedFormControl disabled={!this.fieldIsEnable(name)} type={"text"} placeholder={title}
                               className={`form-control ${style}`}
                               value={this.state[name]}
                               onChange={this.getOnChangeHandler(name)} mask={mask} autoComplete="off"/>
            <Violation violationSet={this.state.violations[name]} visible={true}/>
        </div>;
    }

    onImageChange(e) {

        let types = ["image/png", "image/jpeg"];
        this.setState({
            imageViolation: ""
        });
        if (e.target.files[0] !== undefined) {
            if (types.every(type => e.target.files[0].type !== type)) {
                this.setState({
                    imageViolation: "JPG or PNG files only"
                });
            }
            else {
                formDataChanged("legalDocument", e.target.files[0]);
            }
        }
    }

    onImageRemove() {
        URL.revokeObjectURL(this.state.legalDocument);
        document.getElementById("image-upload").value = null;
        formDataChanged("legalDocument", null);

    }


    fieldIsNeeded(field) {
        if (this.state.fieldsNeeded.length) {
            return this.state.fieldsNeeded.find(item => item === field) !== undefined;
        }
    }

    render() {
        return <div>
            <Modal.Header closeButton style={{ "background": gradientFill() }}>
                <h2>Payment profile</h2>
            </Modal.Header>

            <Modal.Body>

                <form id="form" className="form-horizontal">
                    <p style={{ textAlign: "center" }}>
                        Stripe helps us to process payments. To keep it secure please establish your business identity.
                    </p>


                    <div style={{ textAlign: "center" }} className="fr-mb">
                        <ToggleGroup disabled={!this.fieldIsEnable("legalEntityType")}
                                     selected={this.state.legalEntityType}
                                     onSelect={this.getOnChangeHandler("legalEntityType")}>
                            <Button value={LEGAL_ENTITY_TYPE_INDIVIDUAL}>Individual</Button>
                            <Button value={LEGAL_ENTITY_TYPE_COMPANY}>Company</Button>
                        </ToggleGroup>
                        <Violation violationSet={this.state.violations.legalEntityType} visible={true}/>
                    </div>

                    <FormGroup>
                        <Col md={6} xs={12}>
                            {this.createControl("firstName", "First name")}
                        </Col>
                        <Col md={6} xs={12}>
                            {this.createControl("lastName", "Last name")}
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col md={6} xs={12}>
                            {this.fieldIsEnable("dateOfBirth") && this.createMaskedControl("dateOfBirth",
                                "Date of birth",
                                "11/11/1111")}
                            {!this.fieldIsEnable("dateOfBirth") && this.createControl("dateOfBirthText",
                                "Date of birth")}
                        </Col>
                        <Col md={6} xs={12}>
                            {this.state.ssnLast4Provided &&
                            <FormControl disabled type={"text"}
                                         className=""
                                         value={this.state.ssnLast4}/>}
                            {!this.state.ssnLast4Provided && this.createMaskedControl("ssnLast4",
                                "SSN, last 4 digits",
                                "1111")}
                        </Col>
                    </FormGroup>

                    {this.state.legalEntityType === LEGAL_ENTITY_TYPE_COMPANY &&
                    <div>
                        <FormGroup controlId="business-name">
                            <Col md={12} xs={12}>
                                {this.createControl("businessName", "Business name")}
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="business-tax-id">
                            <Col md={12} xs={12}>
                                {this.fieldIsEnable("businessTaxId") && this.createMaskedControl("businessTaxId",
                                    "Business tax ID",
                                    "11-1111111")}
                                {!this.fieldIsEnable("businessTaxId") && this.createControl("businessTaxId",
                                    "Business tax ID")}
                            </Col>
                        </FormGroup>
                    </div>
                    }
                    <FormGroup controlId="business-url">
                        <Col md={12} xs={12}>
                            {this.createControl("businessUrl", "Business website", "text",
                                this.props.mode !== FORM_MODE_CREATE && !this.state.businessUrl.length ? "fr-border-red" : "")}
                            <p className={"hint-msg fr-ml"}>No&nbsp;website? Enter link like instagram.com/frizocom</p>
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="street-address">
                        <Col md={12} xs={12}>
                            {this.createControl("address", "Street Address")}
                        </Col>
                    </FormGroup>


                    <FormGroup>
                        <Col md={4} xs={12}>
                            {this.createMaskedControl("zip", "ZIP", "11111")}
                        </Col>
                        <Col md={8} xs={12}>
                            <input type="text" name="city"
                                   className="form-control" readOnly="readonly"
                                   placeholder="City, State" value={this.state.city && this.state.city + ", "
                            + this.state.state}/>

                            <div>
                                <Violation violationSet={this.state.violations.city} visible={true}/>
                            </div>

                        </Col>
                    </FormGroup>
                    <FormGroup>
                        {this.fieldIsNeeded("individual.id_number") && this.props.mode !== FORM_MODE_CREATE &&
                        <Col md={12} xs={12}>
                            {this.props.mode !== FORM_MODE_VIEW && this.createMaskedControl("personal_id_number",
                                "Social security number",
                                "111-11-1111", "fr-border-red")}
                            {this.props.mode === FORM_MODE_VIEW && this.createControl("personalIdNumber",
                                "Social security number", "fr-border-red")}
                        </Col>}
                    </FormGroup>
                    <FormGroup>
                        {this.fieldIsNeeded("individual.verification.document") &&
                        <Col md={12} xs={12}>
                            <p className={"fr-txt-red"}>Add a scan or photo of your passport or driver’s license</p>
                            <FormControl type={"file"} componentClass={"input"} accept='image/jpeg, image/png'
                                         onChange={this.onImageChange} className="fr-max-width" id='image-upload'/>
                            {this.state.legalDocument !== null &&
                            <Image src={URL.createObjectURL(this.state.legalDocument)} rounded
                                   className={"fr-mt fr-max-width"}/>}
                            {this.state.legalDocument !== null &&
                            <Button onClick={this.onImageRemove} className="btn-link fr-link-delete">Cancel</Button>}
                            {this.state.imageViolation !== "" &&
                            <li key={"image-violation"} className="fr-error fr-alert-msg fr-mt">
                                {this.state.imageViolation}
                            </li>}
                        </Col>}

                    </FormGroup>

                    <div style={{ textAlign: "center" }} className={"fr-mt"}>

                        {this.props.mode === FORM_MODE_VIEW && "Here are legal documents to review."}
                        {this.props.mode !== FORM_MODE_VIEW && "By pressing Save you agree with"}
                        <br/>

                        <a rel="noopener noreferrer" target="_blank" href="https://stripe.com/us/legal">STRIPE SERVICES
                            AGREEMENT</a> and <br/>
                        <a rel="noopener noreferrer" target="_blank" href="https://stripe.com/us/connect-account/legal">STRIPE
                            CONNECTED ACCOUNT AGREEMENT</a>
                    </div>


                </form>

                <div>
                    {<Violation violationSet={this.state.violations.general} visible={true}/>}
                </div>
            </Modal.Body>


            <Modal.Footer className="card-add-footer">
                <div>
                    {this.props.mode === FORM_MODE_CREATE &&
                    <div className="pull-left">
                        <Button onClick={clear} bsStyle="danger" className="">CLEAR</Button>
                    </div>
                    }

                    {this.props.mode !== FORM_MODE_VIEW &&
                    <div className="pull-right">
                        <Button onClick={save} bsStyle="success" className=""
                                disabled={this.state.imageViolation !== ""}>SAVE</Button>
                    </div>
                    }
                </div>
                <div>
                    {this.props.mode === FORM_MODE_VIEW &&
                    <div className="pull-center">
                        <Button onClick={modalHide} bsStyle="success" className="">OK</Button>
                    </div>
                    }
                </div>
            </Modal.Footer>
        </div>;
    }
}

PaymentProfileForm.defaultProps = {
    mode: FORM_MODE_CREATE
};

PaymentProfileForm.propTypes = {
    mode: PropTypes.oneOf([FORM_MODE_CREATE, FORM_MODE_UPDATE, FORM_MODE_VIEW])
};