/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.27.3
 * Time: 15:03
 */

import React, {Component} from 'react';
import {FormControl} from 'react-bootstrap';

export default class ServicesSelect extends Component {

    createOptions() {
        return this.props.data.list.map(elem => <option key={elem.id} value={elem.id}>{elem.i18nKey
        }</option>);
    }

    render() {

        return <FormControl className="fr-serv-select" componentClass="select" size = {7}
                            value={this.props.data.selectedId}
                            onChange={this.props.onChange}> {this.createOptions()}</FormControl>
    }
}

ServicesSelect.defaultProps = {
    data: [],
    onChange: (ev) => {
        console.log(`Service select change event: ${ev.target.value}`)
    }
};
