/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 05.04.2017
 * Time: 06:14
 */
import React, {Component} from 'react'
import AptListRow from '../appointments/apt-list-row-component';
import HeaderAppointment from '../appointments/appointments-header';
import {
    MODE_PROFESSIONAL, BOOK_APPOINTMENT_NEW,
    PAYMENT_STATUS_DECLINED, PAYMENT_STATUS_NO_STATUS,
    APPOINTMENT_MISSING_TIME_VALUE
} from '../../frizo-shared-constants';
import {startEditing, startBooking} from '../../ac/book-apt/common-actions';
import moment from 'moment';
import {changeAptStatus} from '../../ac/salon-apt';
import {createNewPaymentOrder, alterExistedPaymentOrder} from '../../ac/payment-order-ac';
import {modalChange} from "../../ac/modals";
import {SALON_APPOINTMENT_DETAIL_MODAL} from "../../constants";
import notificationSystem from './../../service/notification-system';

export default class AppointmentsList extends Component {
    constructor(props) {
        super(props);

        this.onDelete = this.onDelete.bind(this);
        this.onModify = this.onModify.bind(this);
        this.onBook = this.onBook.bind(this);
        this.onPaid = this.onPaid.bind(this);
        this.onDecline = this.onDecline.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.getAptById = this.getAptById.bind(this);
        this.onShowAppointmentDetail = this.onShowAppointmentDetail.bind(this);

    }

    onModify(aptId) {
        let apt = this.getAptById(aptId);
        startEditing({
            mode: MODE_PROFESSIONAL,
            addressId: apt.addressId,
            salonId: apt.salonId,
            date: apt.localDate,
            artistId: apt.artist.id,
            salonClientId: apt.salonClient.id
        });
    }

    onDelete(aptId) {

        let message = '<p class="title">Appointment cancel</p>' +
            'Are you sure you wish to cancel? </br> ';


        notificationSystem.confirm(message).then(result => {
            if (result.buttonClicked !== 'ok') {
                return false;
            }
            else {
                changeAptStatus(aptId);
            }
        });
    }

    onBook(aptId) {
        let apt = this.getAptById(aptId);
        startBooking(
            MODE_PROFESSIONAL,
            apt.artist.id,
            apt.salonId,
            moment(new Date()).format('YYYY-MM-DD'), APPOINTMENT_MISSING_TIME_VALUE,
            apt.serviceId,
            0, 0,
            apt.addressId, apt.salonClient, BOOK_APPOINTMENT_NEW);
    }


    onPaid(aptId) {
        let apt = this.getAptById(aptId);

        if (apt.paymentStatus === PAYMENT_STATUS_NO_STATUS || apt.paymentStatus === PAYMENT_STATUS_DECLINED) {
            createNewPaymentOrder(aptId)
        }
        else {
            alterExistedPaymentOrder(aptId);
        }

    }

    onDecline(aptId) {
        console.log('onDecline====', aptId);
    }

    onShowAppointmentDetail(aptId, aptList) {
        modalChange(SALON_APPOINTMENT_DETAIL_MODAL, {
            aptId: aptId,
            aptList: aptList,
        })
    }

    getAptById(aptID) {
        let aptList = this.props.appointments;
        let index = aptList.findIndex(apt => apt.id === aptID);

        return aptList[index];
    }

    render() {
        let appointmentsList = this.props.appointments === undefined ? [] : this.props.appointments;
        let AptItems = [];
        if (appointmentsList.length) {
            AptItems = appointmentsList.map((apt, index) => {
                return <AptListRow key={apt.id}
                                   aptId={apt.id}
                                   aptStartTimestamp={apt.startTimestamp}
                                   aptTZ={apt.timezone}
                                   clientFirstName={apt.salonClient.firstName}
                                   clientLastName={apt.salonClient.lastName}
                                   clientPhone={apt.salonClient.phone}
                                   aptService={apt.serviceName}
                                   aptArtistId={apt.artist.id}
                                   artistFirstName={apt.artist.firstName}
                                   artistLastName={apt.artist.lastName}
                                   aptStatus={apt.status}
                                   onPaid={this.onPaid}
                                   onModify={this.onModify}
                                   onDelete={this.onDelete}
                                   onBook={this.onBook}
                                   aptList={this.props.aptList}
                                   confirmation={apt.confirmation}
                                   onShowAppointment={this.onShowAppointmentDetail}
                />
            });
        }

        return (
            <div className="fr-mt-ng-xs">
                <HeaderAppointment/>
                {AptItems}
            </div>
        )
    }
}