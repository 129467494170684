/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.7.17
 */

import { xhr } from "../../../../service/api-client-2";

export function xhrGetStripeSubscriptionCustomer(token) {
    return xhr()
    .suppressNotifications()
    .get("@Q137_get_stripe_subscription_customer")
    .end()
    .then((request: Request) => {
            let { response } = request;
            return Promise.resolve(response);
        },
        (request: Request) => {
            let { response } = request;
            return Promise.reject(response.errors);
        });
}
