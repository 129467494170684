/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.5.17
 * Time: 7.01
 */

import AppDispatcher from "./../../../dispatcher";

import {
    PROFILE_PERSONAL_ESSENTIALS_CHANGE,
    PROFILE_PERSONAL_ESSENTIALS_PERSIST,
    PROFILE_PERSONAL_ESSENTIALS_PERSIST_FAIL,
    UPDATE_SESSION_DATA
} from "../../../constants";

import EssentialsSyncer from "./../../../sync/profile/personal/essentials-syncer";

import { notifySuccess } from "./../../notify-actions";

export function profileDataChange(patch) {
    AppDispatcher.dispatch({
        type: PROFILE_PERSONAL_ESSENTIALS_CHANGE,
        payload: patch
    });
}

export function persist() {

    let syncer = new EssentialsSyncer();

    AppDispatcher.dispatch({
        type: PROFILE_PERSONAL_ESSENTIALS_PERSIST,
        payload: {
            syncer
        }
    });

    syncer.getPromise().then(response => {
        AppDispatcher.dispatch({
            type: UPDATE_SESSION_DATA,
            payload: response
        });

        notifySuccess();


    }, response => {
        let payload = {};

        if (response.errors) {
            payload.violations = response.errors;
        }
        else {
            switch (response.errorKey) {
                case "zip.not_exists":
                    payload.violations = {
                        zip: [response.message]
                    };
                    break;

                default:
                    payload.error = response.message;
            }
        }

        AppDispatcher.dispatch({
            type: PROFILE_PERSONAL_ESSENTIALS_PERSIST_FAIL,
            payload
        });

    });


}