/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.7.17
 */

import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {printCentToUsd} from "../../../helper/money-converter";

export default function ServiceRow(props) {

    return <Row className="charges-services">
        <div>
            <Col md={7} xs={7} >
                {props.serviceName}<br/>
                <div className="services-artist" >by {props.artistName}</div>
            </Col>
            <Col md={5}  xs={5}>
                <div className="fr-serv-price price-span">{printCentToUsd(props.priceEntered)}</div>
            </Col>
        </div>
    </Row>;
}


ServiceRow.propTypes = {
    artistName: PropTypes.string,
    serviceName: PropTypes.string,
    priceEntered: PropTypes.number
};