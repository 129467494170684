/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.13.4
 * Time: 12:03
 */

import React, {Component} from 'react';
import {Row, Col, FormControl, Button} from 'react-bootstrap';
import {Link} from 'react-router';
import DropCrop from '../../../../elements/drop-crop';
import Violation from '../../../helper-components/violation';
import PhoneInput from '../../../../elements/phone-input';
import {
    INVITATION_STATUS_SENT,
    INVITATION_STATUS_ACCEPTED,
    ARTIST_FORM_MODE_CREATE
} from '../../../../frizo-shared-constants';

export default class ArtistForm extends Component {


    onImageChange(data) {
        this.props.onChange({
            image: {
                id: data.imageId,
                url: data.imageURL
            }
        });
    }


    onInputChange(e) {
        this.props.onChange({
            [e.target.name]: e.target.value
        });
    }

    onPhoneChange(e) {
        this.props.onChange({
            phone: e.target.rawValue
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.violations !== this.props.violations) {
            Violation.scrollToFirstOnPage();
        }
    }

    render() {
        let formTitle = this.props.formMode === ARTIST_FORM_MODE_CREATE ? 'Add Artist' : 'Edit Artist';

        return <Row>
            <div className="fr-artist-form-title">
                <Link to="/my-salon/artists" className="fr-glyphicon">
                    <span className="glyphicon glyphicon-circle-arrow-left"></span>
                </Link>
                <div className="fr-profile__title">{formTitle}</div>
            </div>
            <Col md={6} xs={12}>
                <div className="fr-profile__title">Name</div>

                <FormControl className="fr-mb" type={"text"} name={"firstName"} value={this.props.firstName}
                             placeholder={"First"}
                             onChange={this.onInputChange.bind(this)}
                />
                <Violation violationSet={this.props.violations.firstName}/>


                <FormControl className="fr-mb" type={"text"} componentClass="input" value={this.props.lastName}
                             placeholder={"Last"} name={"lastName"}
                             onChange={this.onInputChange.bind(this)}
                />
                <Violation violationSet={this.props.violations.lastName}/>
                <div className="fr-profile__title">Social</div>
                <div>
                    Why do we ask?<br/>
                    So we can credit your salon and artist on every image share from Frizo to Instagram.
                </div>
                <FormControl className="fr-mb fr-mt" type={"text"} name={"instagramUsername"}
                             value={this.props.instagramUsername}
                             placeholder={"Instsgram username (optional)"}
                             onChange={this.onInputChange.bind(this)}
                />
                <div className="fr-profile__title">E-mail & phone</div>

                <div>
                    Why do we need it?
                    {this.props.invitationStatus !== INVITATION_STATUS_ACCEPTED &&
                    <Button className="fr-btn-add fr-fr"
                            disabled={this.props.btnDisabled}
                            onClick={this.props.sendInvitationHandler}
                            style={{'display': this.props.formMode === ARTIST_FORM_MODE_CREATE ? 'none' : ''}}
                    >Invite</Button>}
                    <div>
                        To share latest schedule updates with your Artist.<br/>
                        Not for a public eye.
                    </div>
                </div>


                <FormControl className="fr-mt fr-mb" componentClass="input" name={"email"} value={this.props.email}
                             type="text"
                             placeholder="e-mail" onChange={this.onInputChange.bind(this)}/>
                <Violation violationSet={this.props.violations.email}/>

                <PhoneInput className="form-control" type="text"
                            value={this.props.phone}
                            placeholder="Phone"
                            name={"phone"}
                            mask='(111) 111-1111'
                            onChange={this.onPhoneChange.bind(this)}
                />
                <Violation violationSet={this.props.violations.phone}/>

            </Col>
            <Col md={6} xs={12}>
                <div className="fr-profile__title-photo">Artist's photo</div>
                <DropCrop imgType={'profile'}
                          style={{width: '100%', height: window.innerWidth> 769 ? 420 :320}}
                          imageURL={this.props.image.url}
                          imageId={this.props.image.id}
                          onImageChange={this.onImageChange.bind(this)}/>
            </Col>
            <Col md={12}><Button className="fr-mt fr-btn fr-big-button fr-fr"
                                 onClick={this.props.onSaveHandler}>Save</Button></Col>
            <Col md={12}>
                <Violation violationSet={this.props.violations.general}/>
            </Col>
        </Row>
    }
}

ArtistForm.defaultProps = {
    id: 0,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    instagramUsername: '',
    image: {
        id: '',
        url: ''
    },
    invitationStatus: INVITATION_STATUS_SENT,
    onSaveHandler: () => false,
    onChange: () => false,
    sendInvitationHandler: () => false,
    violations: {},
    btnDisabled: false,
    formMode: ARTIST_FORM_MODE_CREATE
};
