/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 22.01.2019
 * Time: 10:16
 */

import React, {Component} from 'react';
import {Modal, Button, Form, FormGroup, Label} from 'react-bootstrap';
import Recaptcha from 'react-recaptcha';
import config from '../../config';
import {contactUs} from './../../ac/contact-us-actions';
import Validator from './../../validation/validator';
import gradientFill from '../../helper/gradient-fill';


export default class AffiliateFormModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recaptchaResponse: '',
            violations: {},
            btnDisabled: false,
            showError: {},
            email: '',
            instaName: '',
            facebookName: '',
            userName: '',
            zipCode: '',
            selectedType: '',
            otherType: ''
        };


        this.verifyCallback = this.verifyCallback.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.close = this.close.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onFocus = this.onFocus.bind(this);

    }

    handleFieldChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.name === 'otherType') {
            this.setState({selectedType: 'other'});
        }
    }

    handleOptionChange(e) {
        this.setState({selectedType: e.target.value});
        if (e.target.value !== 'other') {
            this.setState({otherType: ''});
        }
    }

    onFocus() {
        this.setState({selectedType: 'other'});
    }

    verifyCallback(response) {
        let showError = this.state.showError;
        showError.recaptcha = response > 0;
        this.setState({
            recaptchaResponse: response,
            showError: showError
        });
    }

    validateEmail() {
        let validEmail = Validator.validateAtomic('is-email', this.state.email) === true;
        let showError = this.state.showError;
        showError.email = !validEmail;
        this.setState({showError: showError});

    }


    sendMessage() {

        let validEmail = Validator.validateAtomic('is-email', this.state.email) === true;

        // let validRecaptcha = this.state.recaptchaResponse.length > 0;

        let type = this.state.selectedType !== 'other' ? this.state.selectedType : this.state.otherType;
        let message = 'First and last name: ' + this.state.userName + "\n" +
            'Type: ' + type + "\n" +
            'E-mail: ' + this.state.email + "\n" +
            'Insta name: ' + this.state.instaName + "\n" +
            'Facebook name: ' + this.state.facebookName + "\n" +
            'ZIP: ' + this.state.zipCode + "\n";

        if (validEmail) {
            this.setState({btnDisabled: true});
            let params = {
                email: this.state.email,
                phone: '0000000000',
                message: message
            };
            contactUs(params);

        }
        let showError = this.state.showError;
        showError.email = !validEmail;
        //showError.recaptcha = !validRecaptcha;
        this.setState({
            showError: showError
        });
        this.resetRecaptcha();
    }


    onLoadReCaptchaCallback() {   //required!
        // console.log('Done !!');
    }

    resetRecaptcha() {
        this.refs.recaptchaAffiliateInstance.reset();
    };

    close() {
        this.props.close();
    }

    render() {
        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>Share basics to&nbsp;get started</h2>
                </Modal.Header>
                <Modal.Body className={"affiliate-body"}>
                    <Form>
                        <FormGroup>
                            <input className="form-control" value={this.state.userName}
                                   ref={'userName'}
                                   type="text" placeholder="First and last name" name={'userName'}
                                   onChange={this.handleFieldChange}
                            />
                        </FormGroup>
                        <FormGroup className={"affiliate"}>
                            <div className="radio affiliate">
                                <label>
                                    <input type="radio" className="affiliate-radio" value="professional"
                                           checked={this.state.selectedType === 'professional'}
                                           onChange={this.handleOptionChange}

                                    />
                                    Pro beautician
                                </label>
                            </div>
                            <div className="radio affiliate">
                                <label>
                                    <input type="radio" className="affiliate-radio" value="educator"
                                           checked={this.state.selectedType === 'educator'}
                                           onChange={this.handleOptionChange}
                                    />
                                    Beauty educator
                                </label>
                            </div>
                            <div className="radio affiliate">
                                <label>
                                    <input type="radio" className="affiliate-radio" value="blogger"
                                           checked={this.state.selectedType === 'blogger'}
                                           onChange={this.handleOptionChange}
                                    />
                                    Blogger
                                </label>
                            </div>
                            <div className="radio affiliate">
                                <label>
                                    <input type="radio" className="affiliate-radio" value="marketer"
                                           checked={this.state.selectedType === 'marketer'}
                                           onChange={this.handleOptionChange}
                                    />
                                    Social marketer
                                </label>
                            </div>
                        </FormGroup>
                        <FormGroup className={"affiliate"}>

                            <input className="affiliate-radio other" value={'other'}
                                   type="radio" placeholder="Other" name={'other'}
                                   checked={this.state.selectedType === 'other'}
                                   onChange={this.handleOptionChange}
                            />

                            <input className="form-control affiliate-other" value={this.state.otherType}
                                   type="text" placeholder="Other" name={'otherType'}
                                   onChange={this.handleFieldChange}
                                   onFocus={this.onFocus}
                            />


                        </FormGroup>

                        <FormGroup className={"fr-mt"}>
                            <input className="form-control" value={this.state.email}
                                   type="text" placeholder="E-mail" name={'email'}
                                   ref="email"
                                   onChange={this.handleFieldChange}
                                   onBlur={this.validateEmail.bind(this)}

                            />
                            {this.state.showError.email &&
                            <div className="error-message">The entered e-mail address is incorrect</div>}
                        </FormGroup>
                        <FormGroup>
                            <input className="form-control" value={this.state.instaName}
                                   type="text" placeholder="Insta name" name={'instaName'}
                                   onChange={this.handleFieldChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <input className="form-control" value={this.state.facebookName}
                                   type="text" placeholder="Facebook name" name={'facebookName'}
                                   onChange={this.handleFieldChange}
                            />
                        </FormGroup>

                        <FormGroup>
                            <input className="form-control" value={this.state.zipCode}
                                   type="number" placeholder="ZIP" name={'zipCode'}
                                   onChange={this.handleFieldChange}
                            />
                        </FormGroup>

                    </Form>
                </Modal.Body>
                <Modal.Footer className={"affiliate-footer"}>

                    <div id="Recaptcha2" hidden={true}>
                        <Recaptcha
                            sitekey={config.googleRecaptchaSiteKey}
                            render="explicit"
                            elementID='g-recaptcha-affiliate'
                            ref="recaptchaAffiliateInstance"
                            verifyCallback={this.verifyCallback}
                            onloadCallback={this.onLoadReCaptchaCallback}
                        />
                    </div>
                    <div className={"fr-text-center"}>
                        <Button className="btn fr-btn-more"
                                disabled={this.state.btnDisabled}
                                onClick={this.sendMessage}>BECOME A PARTNER</Button>
                        {this.state.showError.recaptcha &&
                        <Label className="fr-span-message" bsStyle="danger">Captcha required</Label>}
                        {this.state.showError.sendMessage &&
                        <Label className="fr-span-message" bsStyle="danger">Server error</Label>}
                    </div>

                    <div className="fr-affiliate-title">What happens next?</div>
                    <div className={"fr-text-center"}>
                        You will hear from&nbsp;us within 3&nbsp;business days
                    </div>
                </Modal.Footer>
            </div>
        );

    }
}