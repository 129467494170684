/**
 * Created by fliak on 22.2.17.
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_TOO_SHORT = "VALUE_IS_TOO_SHORT";
export const VALUE_IS_TOO_LONG = "VALUE_IS_TOO_LONG";

export default class Length extends BaseConstraint {

    constructor(options = null) {
        super();

        this.min = null;
        this.max = null;

        this.maxMessage = "Too long. Must be {limit} characters or less";
        this.minMessage = "Too short. Must be {limit} character or more";
        this.exactMessage = "Must be exactly {limit} characters";

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        return "length";
    }
}
