/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.1.18
 */

import Signal from "signals";

export const events = {
    renewingStarted: new Signal(),
    renewingFinished: new Signal(),
    logout: new Signal(),
    login: new Signal()
};
