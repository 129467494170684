/**
 * Created by Ihnat, Kryshchyk
 * Company: U6 SIA
 * Date: 17.03.2017
 * Time: 17:28
 */
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export default class ImagePreview extends Component {
    constructor(props) {
        super(props);
        this.removeImg = this.removeImg.bind(this);
    }

    removeImg() {
        let remove = this.props.onRemoveImg;
        remove();
    }

    render() {
        let uploadedImg = '';
        if (this.props.img != null) {
            uploadedImg =
                <div className="image-container" style={this.props.style}>
                    <div className="image-wrapper">
                        <img style={{maxWidth: '100%', maxHeight: '100%'}} src={this.props.img} alt="Uploaded by Client" />
                    </div>
                </div>;
        }
        let removeBtn = (
            <div className="button-wrapper text-center">
              <Button onClick={this.removeImg} className="fr-uploader-remove-btn btn-link btn">{ this.props.removeBtnCaption || 'Remove'}</Button>
          </div>
        )
        if (this.props.noRemove === true) {
            removeBtn = null;
        }
        let styles = this.props.styles;
        return (
            <div id={this.props.previewId} style={styles}>
                {uploadedImg}
                {removeBtn}
            </div>
        );
    }
}
