/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 04.04.2018
 * Time: 11:26
 */

import BaseStore from "./base-store";
import {
    DASHBOARD_WHERE_HINT_LIST_LOADED,
    DASHBOARD_WHERE_HINT_SELECT,
    DASHBOARD_WHERE_INPUT_CHANGE,
    DASHBOARD_WHERE_IS_NEAREST_CHANGE,
    GALLERY_ENTER_PARAMS,
    GALLERY_RESET
} from "../constants";

export default class DashboardWhereStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            searchValue: "",
            searchList: [],
            listLoaded: false,
            menuActive: false,
            selected: -1
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case DASHBOARD_WHERE_HINT_LIST_LOADED: {
                    this.store.searchList = payload.data;
                    this.store.listLoaded = true;
                    this.store.menuActive = true;

                    break;
                }

                case DASHBOARD_WHERE_INPUT_CHANGE: {
                    if (payload === "") {
                        this.store.searchList = [];
                        this.store.searchValue = "";
                    }
                    else {
                        this.store.searchValue = payload;
                        //this.store.menuActive = true;
                    }

                    break;
                }

                case DASHBOARD_WHERE_HINT_SELECT: {
                    this.store.searchValue = payload;
                    this.store.menuActive = false;

                    break;
                }
                case GALLERY_RESET:
                    this.store.searchList = [];
                    this.store.searchValue = "";
                    this.store.menuActive = false;

                    break;

                case DASHBOARD_WHERE_IS_NEAREST_CHANGE: {
                    this.store.isNearestChecked = payload;
                    this.store.searchValue = "";
                    this.store.menuActive = false;

                    break;
                }


                case GALLERY_ENTER_PARAMS: {
                    this.store.menuActive = false;
                    this.store.searchList = [];
                    break;
                }

                default:
                    return true;
            }

            this.emitChange();
        });

    }

    getSearchList() {
        return this.store.searchList;
    }


    getSearchVal() {
        return this.store.searchValue;
    }

    getMenuActive() {
        return this.store.menuActive;
    }
}
