/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.3.17
 * Time: 7.07
 */

import React, {Component} from 'react';
import MySalonBaseInfoForm from './my-salon/my-salon-base-info-form';
import MySalonDisplayAddressForm from './my-salon/my-salon-display-address-form';
import MySalonAddressForm from './my-salon/my-salon-address-form';


import {
    xhrRemoveAddress,
    xhrAddAddress,
    xhrUpdateAddress,
    xhrUpdateSalonBaseInfo
} from '../../../xhr/my-salon-xhr';

import {sessionStore} from './../../../stores/index';

import {
    USER_TYPE_FREELANCER,
    USER_TYPE_MASTER,
} from './../../../frizo-shared-constants';

import {notifyFail, notifySuccess} from './../../../ac/notify-actions';

import Filtration from './../../../filter/filtration';
import TrimFilter from './../../../filter/trim-filter';
import {updateSessionData} from "../../../auth";


export default class ProfessionalProfileNameAndLocation extends Component {
    constructor(props) {
        super(props);

        this.state = Object.assign({
            id: null,
            name: '',
            phone: '',
            instagramUsername: '',
            addresses: [],
            userType: sessionStore.getUserType(),
            showEditForm: false,
            btnUpdateDisabled: false,
            dirtyAddress: {
                address: '',
                city: '',
                zip: '',
                suite: '',
                onLocation: false
            },
            zipEnabled: true,
            removeAddressLock: false
        }, this.getStoreData());

        this.handleSessionChange = this.handleSessionChange.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.addressUpdate = this.addressUpdate.bind(this);

        this.onBaseInfoChange = this.onBaseInfoChange.bind(this);
        this.onBaseInfoUpdate = this.onBaseInfoUpdate.bind(this);

        this.resetFields = this.resetFields.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);
    }


    componentDidMount() {
        sessionStore.addChangeListener(this.handleSessionChange);
    }

    componentWillUnmount() {
        sessionStore.removeChangeListener(this.handleSessionChange);
    }


    getStoreData() {
        let data = {};
        let session = sessionStore.getSession();

        if (session.salon) {

            let salon = session.salon;

            data.id = salon.id;
            data.name = salon.title;
            data.phone = salon.phone;
            data.instagramUsername = salon.instagramUsername || '';

            if (Array.isArray(salon.addresses)) {
                data.addresses = salon.addresses;

                if (data.addresses.length > 0) {
                    let firstAddress = data.addresses[0];

                    data.dirtyAddress = {
                        id: firstAddress.id
                    };
                    data.addressId = firstAddress.id;
                }
            }

        }

        data.userType = sessionStore.getUserType();
        data.btnUpdateDisabled = false;

        return data;
    }

    handleSessionChange() {

        this.setState(this.getStoreData());
    }

    lockBtn() {
        this.setState({
            removeAddressLock: true
        });
    }

    releaseBtn() {
        this.setState({
            removeAddressLock: false
        });
    }

    addressRemove(addressId) {

        if (this.state.removeAddressLock) return false;

        this.lockBtn();

        xhrRemoveAddress(addressId).then(newAddressList => {

            notifySuccess();
            this.releaseBtn();
            updateSessionData();

        }, response => {

            notifyFail(response.message);

            this.releaseBtn();
        })
    }

    addressUpdate() {
        this.setState({btnUpdateDisabled: true});
        const filtration = new Filtration();
        const trimFilter = new TrimFilter();

        filtration.registerFilter('address', trimFilter);

        let filtered = filtration.filtrate(this.state.dirtyAddress);
        let address = Object.assign({}, filtered);
        if (address.onLocation) {
            address.address = '';
        }
        address.id = this.state.addressId;

        if (this.isAccountMultiaddress()) {
            //add
            return xhrAddAddress(address).then(newAddressList => {

                notifySuccess();

                updateSessionData();
                this.setState({btnUpdateDisabled: false});
                this.resetFields();
            })
        }
        else {
            //update

            return xhrUpdateAddress(address).then(newAddressList => {

                this.setState({
                    showEditForm: false,
                });

                notifySuccess();
                this.setState({btnUpdateDisabled: false});
                updateSessionData();

            })

        }
    }


    /**
     * serve child form MySalonAddressForm
     * @param data
     */

    onAddressChange(data) {
        let dirtyAddress = this.state.dirtyAddress;
        if (data.zip === '') {
            data.city = '';
        }
        this.setState({
                dirtyAddress: Object.assign(dirtyAddress, data)
            }
        );
    }


    resetFields() {
        if (this.state.dirtyAddress.onLocation) {
            this.setState({
                dirtyAddress: {
                    zip: ''
                }
            });
        }
        else {
            this.setState({
                dirtyAddress: {
                    address: '',
                    city: '',
                    zip: '',
                    suite: ''
                }
            });
        }
        this.setState({
            zipEnabled: true,
        });
    }

    toggleVisibility() {
        this.resetFields();
        this.setState({
            showEditForm: !this.state.showEditForm
        })
    }

    isAccountMultiaddress() {
        return this.state.userType === USER_TYPE_FREELANCER;
    }

    pageIsReadOnly() {
        return this.state.userType === USER_TYPE_MASTER
    }

    onBaseInfoChange(field, value) {

        this.setState({
            [field]: value
        });

    }

    onBaseInfoUpdate() {

        const filtration = new Filtration();
        const trimFilter = new TrimFilter();

        filtration.registerFilter('name', trimFilter);
        let filtered = filtration.filtrate(this.state);

        return xhrUpdateSalonBaseInfo(filtered.name, filtered.phone, filtered.instagramUsername).then(response => {

            updateSessionData();

            notifySuccess();
            this.resetFields();

        });
    }


    render() {
        let multiaddress = this.isAccountMultiaddress();
        let isReadOnly = this.pageIsReadOnly();

        let addressesRemovable = multiaddress && this.state.addresses.length > 1 && !isReadOnly;

        let addressesOnLocation = [];
        let salonAddresses = [];
        this.state.addresses.forEach((address, key) => {
            let element = <MySalonDisplayAddressForm
                key={key}
                removable={addressesRemovable}
                readOnly={isReadOnly}
                address={address}
                onRemove={this.addressRemove.bind(this, address.id)}
                onUpdate={this.toggleVisibility}
                locked={this.state.removeAddressLock}
            />;
            if (address.onLocation) {
                addressesOnLocation.push(element);
            }
            else {
                salonAddresses.push(element);
            }

        });

        let addresses1 = null;
        let addresses2 = null;
        let buttonTitle = this.isAccountMultiaddress() ? 'Add' : 'Update';
        if (salonAddresses.length > 0) {
            addresses1 = <div className={isReadOnly ? "fr-mt-ng" : ""}>
                <div className="fr-profile__title">Address</div>
                <button style={{'display': isReadOnly || !addressesRemovable ? 'none' : ''}}
                        className="fr-btn-add btn fr-btn fr-ml"
                        onClick={this.toggleVisibility}>{buttonTitle}</button>
                {salonAddresses}
            </div>;
        }
        if (addressesOnLocation.length > 0) {
            addresses2 = <div>
                <div className="fr-profile__title">On location</div>
                <button style={{'display': salonAddresses.length ? 'none' : ''}} className="fr-btn-add btn fr-btn fr-ml"
                        onClick={this.toggleVisibility}>{buttonTitle}</button>
                {addressesOnLocation}
            </div>;
        }

        let addressEditForm = null;


        if (!isReadOnly && this.state.showEditForm) {
            addressEditForm = <div className="col-md-6 col-xs-12 fr-address-edit-form">

                <MySalonAddressForm
                    showOnLocationSwitcher={multiaddress}

                    address={this.state.dirtyAddress.address}
                    zip={this.state.dirtyAddress.zip}
                    city={this.state.dirtyAddress.city}
                    suite={this.state.dirtyAddress.suite}
                    onLocation={this.state.dirtyAddress.onLocation}

                    onChange={this.onAddressChange}
                    onSave={this.addressUpdate}
                    onHideForm={this.toggleVisibility}
                    btnUpdateDisabled={this.state.btnUpdateDisabled}
                />

            </div>;
        }


        return <div>
            <div className="row">
                <div className="col-md-6">
                    <MySalonBaseInfoForm
                        name={this.state.name}
                        phone={this.state.phone}
                        instagramUsername={this.state.instagramUsername}
                        isFreelancer={this.isAccountMultiaddress()}
                        readOnly={isReadOnly}
                        onChange={this.onBaseInfoChange}
                        onSave={this.onBaseInfoUpdate}
                    />

                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-xs-12 fr-fl">
                    {addresses1}
                    {addresses2}
                </div>

                {addressEditForm}

            </div>
        </div>;

    }
}

ProfessionalProfileNameAndLocation.requireData = ['userSalon', 'salonInfo'];