/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 16.02.2018
 * Time: 11:30
 */

import AppDispatcher from "../dispatcher";
import { CONTACT_US_MESSAGE_FAIL, CONTACT_US_MESSAGE_SENT } from "../constants";
import { xhrContactUs } from "../xhr/contact-us-xhr";
import { notifySuccess } from "./notify-actions";

export const contactUs = (params) => {
    xhrContactUs(params).then(answer => {

        AppDispatcher.dispatch({
            type: CONTACT_US_MESSAGE_SENT,
            payload: answer
        });
        notifySuccess("Message sent");

    }, answer => {
        AppDispatcher.dispatch({
            type: CONTACT_US_MESSAGE_FAIL,
            payload: answer
        });

    });
};