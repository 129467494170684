/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.11.17
 * Time: 16.47
 */

import { singleLookNormalizer } from "./single-look-normalizer";
import type { TRawLook } from "../../raw-types";

export function plainLooks(rawItems: Array<TRawLook>) {
    let items = [];

    for (let i = 0; i < rawItems.length; i++) {
        let item = singleLookNormalizer(rawItems[i]);

        items.push(item);

    }

    return items;
}
