/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.10.17
 */

import BaseConstraint from "./base-constraint";
import { DROPDOWN_PLACEHOLDER_VALUE } from "./../../frizo-shared-constants";

export const VALUE_IS_DEFAULT_DROPDOWN_VALUE = "VALUE_IS_DEFAULT_DROPDOWN_VALUE";

export default class IsNotDefaultDropdownValue extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Required field";

        this.value = DROPDOWN_PLACEHOLDER_VALUE;

        this.setOptions(options);
    }

    static getName() {
        return "is-not-default-dropdown-value";
    }
}
