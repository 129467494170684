/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 02.11.2017
 * Time: 14:10
 */

import React from 'react';
import {Col, Row, Badge} from 'react-bootstrap';
import printedSum from '../../../../helper/print-sum';
import {LocalTime} from '../../../helper-components/time-component';
import idGenerator from "../../../../helper/id-generator-component";
import PropTypes from 'prop-types';

export default function SalonTransactionsWidget(props) {

    return <div>
        <div className="subscription-profile__plan-title">
            Transactions
        </div>
        <Row className="fr-list-header bordered">
            <Col xs={4} md={4}>Date</Col>
            <Col xs={3} md={3}>Amount</Col>
            <Col xs={5} md={5}>Status</Col>
        </Row>
        {props.sources !== undefined &&
        <div className="bank-card-profile__payment-sources">
            {props.sources.map(transaction => <Row key={idGenerator('salon-transactions')}
                                                   className="bank-card-profile__payment-sources-table fr-table__border-bottom">

                <Col md={4} xs={4} className="info-column">
                      <span>
                            <LocalTime
                                timestamp={transaction.date}
                                format="ll"
                            />
                          <br/>
                          <LocalTime timestamp={transaction.date} format={"hh:mm A"}/>
                        </span>
                </Col>

                <Col md={3} xs={3} className="info-column fr-capitalize fr-text-overflow">
                    $ {printedSum(transaction.amount / 100)} <br/>
                    {transaction.amountOrigin}
                </Col>
                {transaction.type === 'cash' && <Col md={5} xs={5}>
                    Cash
                </Col>}
                {transaction.type !== 'cash' && transaction.amountOrigin !== 'payout' && <Col md={5} xs={5}>
                    {transaction.status !== 'failed' && transaction.fundsRoute.brand !== undefined && transaction.fundsRoute.brand + '\u00A0\u2022\u00A0' + transaction.fundsRoute.last4}
                    {transaction.status !== 'failed' && <br/>}
                    <Badge
                        className={transaction.status === 'succeeded' || transaction.status === 'paid' ? 'transaction-status__success' : transaction.status === 'failed' ? 'transaction-status__fail' : 'transaction-status__other'}>{transaction.status}</Badge>
                </Col>}

            </Row>)}
        </div>}
        {props.sources.length === 0 &&
        <div className="fr-mt fr-text-center">No transactions yet</div>
        }
    </div>;
}
SalonTransactionsWidget.propTypes = {
    sources: PropTypes.array,
};


SalonTransactionsWidget.defaultProps = {
    sources: []
};