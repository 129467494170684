/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 6/20/17
 * Time: 19:32
 */

import React, {Component} from 'react';
import {faqStore} from '../../stores';
import {loadFaqForProfessionals} from './../../ac/faq-actions';
import {Accordion, Panel} from 'react-bootstrap';
import {modalChange} from '../../ac/modals';
import {
    CONTACT_MODAL
} from '../../constants';


export default class FAQProfessionals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            faq: []
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        loadFaqForProfessionals();
        faqStore.addChangeListener(this.handleChange);
    }

    componentWillUnmount() {
        faqStore.removeChangeListener(this.handleChange);
    }

    handleChange() {
        this.setState({faq: faqStore.getStore().faqProfessionals});
    }

    createQuestions(questions){
        return questions.map((question, index) => (<Accordion>
                <Panel header={question.questionEn} eventKey={question.id} collapsible>
                    <div dangerouslySetInnerHTML={{__html: question.answerEn}}></div></Panel>
            </Accordion>)
        )
    }

    createTopic() {
        return this.state.faq.map((topic, index) => (<div>
                <div className="fr-profile__title">{topic[0].titleEn}</div>
                {this.createQuestions((topic))}
            </div>)
        )
    }

    openContactUs() {
        modalChange(CONTACT_MODAL);
    }

    render() {
        return (<div>
            {this.createTopic()}
            <div className={"fr-mt"}>If you did not find an answer to your question, please <button type="button" className="btn btn-link p0" onClick={this.openContactUs}>contact us</button>.</div>
        </div>);
    }
}