/**
 * Created by Ignat Kryshchyk
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 23.07.2017
 * Time: 12:25
 */
import { LOAD_PRICING_PRODUCTS, SELECT_PLAN, SELECT_PRICING_PLAN } from "../constants";

import BaseStore from "./base-store";

class PricingStore extends BaseStore {


    constructor(...args) {
        super(...args);

        this.store = {
            userType: null,
            products: [],
            userAppType: null,
            selectedPlan: 0
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;
            switch (type) {
                case LOAD_PRICING_PRODUCTS:

                    this.store.products = payload.data || [];
                    this.store.userAppType = payload.userAppType;

                    break;

                case SELECT_PLAN:
                    this.store.userType = payload;
                    break;

                case SELECT_PRICING_PLAN:
                    this.store.selectedPlan = payload;
                    break;

                default:
                    return true;
            }
            this.emitChange();
        });

    }

    getFilteredProducts(productFilter) {
        return this.store.products.filter(product => product.productType === productFilter);

    }

    getUserFilteredPlans(data, userType) {
        let products = data || [];
        products = products.filter(function(plan) {
                let planType = plan.metadata["supported-user-types"].split(",");
                switch (userType) {
                    case "freelancer":
                    case "salon-artist":
                        return planType.indexOf("2") > -1 || planType.indexOf("6") > -1;
                    case "salon-owner":
                        return planType.indexOf("7") > -1;
                    default:
                        return [];
                }
            }
        );

        return products;
    }

}

export default PricingStore;
