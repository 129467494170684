/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.7.17
 */

import BaseStore from "../base-store";
import {
    COMMON_MODAL_CLEAR,
    CREATE_PAYMENT_ORDER_MODAL,
    MODAL_CHANGE,
    MODAL_HIDE,
    PAYMENT_ORDER_APTS_LOADED,
    PAYMENT_ORDER_CREATE_ERROR,
    PAYMENT_ORDER_LOAD,
    PAYMENT_ORDER_SUGGEST_TAX,
    PAYMENT_ORDER_UPDATE_PRICE,
    PAYMENT_ORDER_UPDATE_TAX,
    PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED
} from "../../constants";
import Validator from "./../../validation/validator";
import { FORM_MODE_CREATE } from "./../../frizo-shared-constants";
import { suggestTaxPercent } from "./../../ac/payment-order-ac";
import { decimalToPercent, usdToCent } from "../../helper/money-converter";

export default class GetPaidPopupStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case MODAL_CHANGE:
                    if (payload.type === CREATE_PAYMENT_ORDER_MODAL) {
                        this.resetStore();
                        this.store.active = true;
                        this.store.mode = payload.options.mode;
                        this.store.btnDisabled = false;
                    }
                    break;

                case MODAL_HIDE:
                    this.store.active = false;
                    break;

                case COMMON_MODAL_CLEAR:
                    if (!this.store.active) return true;
                    this.resetStore();
                    console.log("reset", this.store);
                    break;

                case PAYMENT_ORDER_SUGGEST_TAX:
                    this.store.taxPercentDefault = payload.taxPercent;
                //fall through

                case PAYMENT_ORDER_UPDATE_TAX:
                    //do not convert to number, because user input can be incomplete
                    this.store.taxPercent = payload.taxPercent;

                    this.calculateTotalAndTax();

                    break;

                case PAYMENT_ORDER_UPDATE_PRICE:
                    this.store.services.forEach(service => {
                        if (service.id === payload.aptId) {
                            service.price = usdToCent(Math.abs(Number(payload.price)));

                            return false;
                        }
                    });

                    this.calculateTotalAndTax();

                    break;

                case PAYMENT_ORDER_LOAD:
                    Object.assign(this.store, payload);
                    this.store.paymentOrderId = payload.id;
                    this.store.salonClientId = payload.salonClient.id;
                    this.store.taxPercent = decimalToPercent(payload.taxPercent);

                    if (this.store.services) {
                        this.store.services = this.store.services.map(service => {

                            let apt = service.appointment;

                            return {
                                id: apt.id,
                                aptNo: apt.aptNo,
                                price: service.priceEnteredCent,
                                service: apt.service.i18nKey,
                                artist: apt.masterUser.firstName + " " + apt.masterUser.lastName
                            };

                        });
                    }

                    this.setReady(true);
                    this.calculateTotalAndTax();

                    break;

                case PAYMENT_ORDER_CREATE_ERROR:
                    this.store.violations = payload;
                    this.store.btnDisabled = false;

                    break;

                case PAYMENT_ORDER_APTS_LOADED:
                    if (payload.apts && payload.apts.length > 0) {
                        let first = payload.apts[0];

                        this.store.addressId = first.address.id;

                        suggestTaxPercent(this.store.addressId);


                        this.store.salonClient = first.salonClient;
                        this.store.salonClientId = first.salonClient.id;
                        this.store.date = first.localDate;

                        this.store.services = payload.apts.map(apt => {

                            return {
                                id: apt.id,
                                aptNo: apt.aptNo,
                                price: apt.priceCent,
                                service: apt.service.i18nKey,
                                artist: apt.masterUser.firstName + " " + apt.masterUser.lastName
                            };
                        });

                        this.calculateTotalAndTax();

                        this.setReady(true);
                    }
                    else {
                        alert("Invalid state of appointment");
                        this.store.services = {};
                    }

                    break;

                case PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED:
                    if (payload.type === "custom") {
                        this.store.paymentProfileExists = true;
                        this.store.externalAccountExists = payload.external_accounts.total_count > 0;
                    }
                    else {
                        this.store.paymentProfileExists = false;
                        this.store.externalAccountExists = false;
                    }

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });

        this.copyDataFromSession = this.copyDataFromSession.bind(this);
    }

    getInitialStore() {
        return {
            paymentOrderId: null,
            taxValue: 0,
            taxPercentDefault: 0,

            //user input, can be string, because input can be incomplete
            taxPercent: 0,
            serviceAndTax: 0,
            serviceTotalEntered: 0,
            services: [],
            salonClient: null,
            salonClientId: null,
            date: null,
            addressId: null,

            violations: {},
            btnDisabled: false,

            active: this.store ? this.store.active : false,

            mode: FORM_MODE_CREATE,

            paymentProfileExists: null,
            externalAccountExists: null

        };
    }

    calculateTotalAndTax() {
        let total = 0;

        this.store.services.forEach(service => {
            total += Number(service.price);
        });

        this.store.serviceTotalEntered = total;

        let taxPercent = Number(this.store.taxPercent);
        this.store.taxValue = Math.round(total * taxPercent / 100);

        this.store.serviceAndTax = total + this.store.taxValue;
    }

    copyDataFromSession() {
        let data = this.stores.session.getSession();

        if (data.business && data.salon) {
            this.store.businessAccountName = data.salon.title;
        }
        else {
            this.store.businessAccountName = data.firstName + " " + data.lastName;
        }
    }

    /**
     * @protected
     */
    buildValidation(legalEntityType) {
        this.validator = new Validator();

        this.validator.addConstraint("legalEntityType", "is-required");
        this.validator.addConstraint("businessAccountName", "is-required");
        this.validator.addConstraint("accountNumber", "is-required");
        this.validator.addConstraint("routingNumber", "is-required");

    }
}
