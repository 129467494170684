/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 21.01.2019
 * Time: 11:34
 */

import React, {Component} from 'react';
import {Link} from 'react-router';
import {Row, Image, Button, Badge, Col} from "react-bootstrap";
import gradientFill from '../../helper/gradient-fill';
import imgSrc1 from '../../../../img/frizo-affiliate-inessa-mendoza.jpg';
import {AFFILIATE_FORM_MODAL} from "../../constants";
import {modalChange} from "../../ac/modals";


export default class AffiliatePage extends Component {

    openModalForm() {
        modalChange(AFFILIATE_FORM_MODAL);
    }

    render() {
        let colors = ['#11711787', '#30a1e8', '#e830a1', '#a1e830'];
        let uniqueColors = [];
        while (uniqueColors.length < colors.length) {
            let index = Math.floor(Math.random() * colors.length);
            if (uniqueColors.indexOf(colors[index]) === -1) uniqueColors.push(colors[index]);
        }

        return (
            <div className={"fr-affiliate"}>
                <div className="fr-banner-top fr-full-width" style={{'background': gradientFill()}}>
                    <Row className="fr-container-width fr-text-center">
                        <div className="fr-banner-top__text--content">
                            <h1>Affiliate</h1>
                        </div>
                    </Row>
                </div>
                <div className="fr-affiliate-flex">
                    <div className={"fr-affiliate-flex__item "}>
                        <div className={"mail-box"}>
                            <div className={"mail-box--content"}>
                                <Image src={imgSrc1} rounded className={"avatar"}/>
                                "Olivia, thanks for the opportunity to&nbsp;<strong>partner</strong> with Frizo!<br/>
                                Selling over <strong>$3,200</strong> worth of&nbsp;annual subscription with just
                                <strong> two Insta posts </strong>
                                is&nbsp;unbelievable.<br/>
                                You have a&nbsp;great sales page, which clearly spoke to&nbsp;my&nbsp;audience of&nbsp;beauticians.
                                Your transparent business proposal translated into <strong>real sales</strong>.
                                Frizo team&rsquo;s enthusiasm is&nbsp;highly contagious:)
                                You guys have created a&nbsp;highly effective sales machine, and have once again proved
                                yourselves as&nbsp;a&nbsp;responsible
                                and trustworthy partner."

                            </div>
                            <div className={"signature"}>
                                &mdash; Inessa Mendoza, Pro&nbsp;MUA/blogger, <a href={'https://www.instagram.com/inessamendoza'} rel="noopener noreferrer" target="_blank">@inessamendoza</a>
                            </div>
                        </div>
                        <div className={"content-box left"}>
                            It was December 11th when we&nbsp;received a&nbsp;friendly message from our friend and talented make up&nbsp;artist Inessa Mendoza.
                            She went straight and told&nbsp;us that she wanted to&nbsp;promote
                            our Frizo app to&nbsp;her friends and followers. With just two Insta posts about Frizo, Inessa got 312 sales page visitors leading to&nbsp;8&nbsp;pro subscriptions.<br/><br/>

                        </div>
                    </div>
                    <div className={"fr-affiliate-flex__item blue"}>
                        <div className="fr-text-center">
                            <div className="fr-item--shadow-title"> Here&rsquo;s how it&rsquo;s
                                going to&nbsp;work
                            </div>
                        </div>
                        <div className={"content-text"}>
                            The monthly subscription price for all our affiliate partners
                            will be&nbsp;&mdash; $35 for single artists, $75 for salons.<br/>
                            <br/>
                            Frizo Sales page: <Link to={"why-frizo"}>frizo.com/why-frizo</Link>
                        </div>
                    </div>
                    <div className={"fr-affiliate-flex__item shadow"}>
                        <div className="fr-text-center">
                            <div className="fr-item--shadow-title"> What’s in it for me?</div>
                        </div>
                        <div className={"reasons-box"}>
                            <div className={"reasons-list list-group"}>
                                <Row className={"fr-mb-xs"}>

                                    <Col xs={2} md={1}>
                                        <Badge className={"list-group-badge"}
                                               style={{'background': uniqueColors[0]}}>1</Badge>
                                    </Col>
                                    <Col xs={10} md={11}>
                                        <div className={"list-inline-block"}><b>25% commission</b><br/>You get 25%
                                            on every future pro subscription payment. Forever.
                                        </div>
                                    </Col>


                                </Row>
                                <Row className={"fr-mb-xs"}>
                                    <Col xs={2} md={1}>
                                        <Badge className={"list-group-badge"}
                                               style={{'background': uniqueColors[1]}}>2</Badge>
                                    </Col>
                                    <Col xs={10} md={11}>
                                        <div className={"list-inline-block"}><b>Accurate
                                            tracking</b><br/>We&nbsp;track each sign&nbsp;up, subscription, booking
                                            within Frizo, even if&nbsp;a&nbsp;user connects from multiple
                                            devices.<br/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={"fr-mb-xs"}>
                                    <Col xs={2} md={1}>
                                        <Badge className={"list-group-badge"}
                                               style={{'background': uniqueColors[2]}}>3</Badge>
                                    </Col>
                                    <Col xs={10} md={11}>
                                        <div className={"list-inline-block"}><b>Regular payments</b><br/>In&nbsp;30&nbsp;days
                                            from the end of&nbsp;each month, you will get a&nbsp;Paypal
                                            payment amounting to&nbsp;100% of&nbsp;commission earned last month.
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={"fr-mb-xs"}>
                                    <Col xs={2} md={1}>
                                        <Badge className={"list-group-badge"}
                                               style={{'background': uniqueColors[3]}}>4</Badge>
                                    </Col>
                                    <Col xs={10} md={11}>
                                        <div className={"list-inline-block"}><b>Personalized
                                            sales page</b><br/>If&nbsp;you would like, we&nbsp;can customize the
                                            sales
                                            page with your branding.<br/>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className={"fr-affiliate-flex__item"}>
                        <div className={"content-box"}>
                            <div className={"content-box--block"}>
                                <Button className={"fr-btn-more basic-form"} onClick={this.openModalForm}>LET'S
                                    TRY</Button>
                            </div>
                            <div className={"fr-text-center"}>If&nbsp;you have questions, reach out&nbsp;to
                                olivia [at] frizo.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}