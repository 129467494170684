/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.5.17
 * Time: 7.12
 */

import BaseSyncer from "./../../base-syncer";
import { xhrUpdateProfileInfo } from "../../../xhr/personal-profile-xhr";

export default class EssentialsSyncer extends BaseSyncer {
    constructor() {
        super();

        this.data = undefined;
    }

    putData(essentialsData) {
        this.data = essentialsData;
    }

    persist() {

        if (this.data === undefined) {
            console.warn("Persist skipped because not data enough", this);
            return;
        }

        xhrUpdateProfileInfo({
            firstName: this.data.firstName,
            lastName: this.data.lastName,
            genderID: this.data.genderID,
            instagramUsername: this.data.instagramUsername,
            phone: this.data.phone,
            imageId: this.data.imageId,
            referralCode: this.data.referralCode
        }).then(answer => {
            this._resolveCallback(answer);
        }, answer => {
            this._rejectCallback(answer);
        });
    }
}
