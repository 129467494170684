/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.4.17
 * Time: 22.04
 */

export default function arrayToHash(arr, callback) {
    let hash = Object.create(null);

    arr.forEach((element, index) => {
        let [key, value] = callback(element, index);

        hash[key] = value;
    });

    return hash;
}

/**
 example:

 let report = arrayToHash(relayResponse, (partial, key) => {
                if (partial.statusCode !== 200) {
                    return [key, [partial.payload.message]];
                }
                else {
                    return [key, []];
                }
            });

 */
