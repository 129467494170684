/**
 * Created by ignat
 * Name: Ignat Kryshcyk
 * Company: U6 SIA
 * Date: 08.08.2017
 * Time: 9:26
 */
import React from "react";


export default function GalleryMessage(props) {
    let message = props.message || "Nothing found. We are here to change that.";

    return (
        <div className="gallery-message">
            <div className="gallery-message__block">{message}</div>
        </div>
    );
}
