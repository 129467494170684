/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 24.7.17
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormControl, ButtonToolbar, ButtonGroup, Button} from 'react-bootstrap';
import {TIP_CONFIG} from "../../../frizo-shared-constants";
import {printCentToUsd} from "../../../helper/money-converter";

export default class TipSelector extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            customPercent: ''
        });

        this.tipConfig = TIP_CONFIG;
        this.clickHandlers = {};

        this.getClickHandler = this.getClickHandler.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.updateCustomTip = this.updateCustomTip.bind(this);
    }


    clickHandler(percent) {
        this.props.onChange({
            percent: Number(percent),
            customSelected: false,
        });
        this.setState({
            customPercent: ''
        });
    }

    updateCustomTip(e) {
        let tip = e.target.value;
        if (tip.indexOf('.') > -1 || tip.indexOf(',') > -1 || tip.length > 3) {
            return false;
        }
        if (parseInt(tip, 10)) {
            tip = tip.replace(/\D+/g, '');
        }

        this.setState({
            customPercent: isNaN(tip) ? '' : tip
        });

        this.props.onChange({
            percent: isNaN(tip) ? 0 : Number(tip),
            customPercent: isNaN(tip) ? 0 : Number(tip),
            customSelected: true,
        });

    }

    getClickHandler(percent) {
        if (!this.clickHandlers[percent]) {
            this.clickHandlers[percent] = this.clickHandler.bind(this, percent);
        }

        return this.clickHandlers[percent];
    }


    render() {

        let percentValue = this.props.percentValue;
        let sum = this.props.sum;


        return <div className="tip-selector tip-selector-cnt fr-mb fr-text-center">
            <ButtonToolbar>
                {this.tipConfig.map(percent => {
                    let selected = percentValue === percent && !this.props.custom;

                    return <ButtonGroup bsSize="small"
                                        key={percent}
                                        onClick={this.getClickHandler(percent)}
                                        value={percent}
                                        className={"tip-selector-cnt"}>
                        <Button ref={'tip' + percent}
                                className={selected ? " tip-selector-cnt btn-select tip-selected" : "tip-selector-cnt btn-select"}><Percent
                            percent={percent}/></Button>
                    </ButtonGroup>
                })

                }

                <div style={{'display': 'flex'}}>
                    <FormControl bsSize="small"
                                 value={this.state.customPercent}
                                 onChange={this.updateCustomTip}
                                 componentClass="input"
                                 type="tel"
                                 pattern="[0-9]*"
                                 placeholder="Other"
                                 className={this.props.custom && this.tipConfig.indexOf(this.props.customPercentValue) === -1 ? "tip-selector-cnt tip-input tip-selected" : "tip-selector-cnt tip-input"}
                    />
                </div>

            </ButtonToolbar>
            <Sum sum={sum} percent={percentValue}/>

        </div>;
    }
}
TipSelector.propTypes = {
    percentValue: PropTypes.number,
    customPercentValue: PropTypes.number,
    customSelected: PropTypes.bool,
    onChange: PropTypes.func,
    sum: PropTypes.number
};

function Percent(props) {
    if (props.percent === 0) {
        return <span>No tip</span>
    }

    return <span>{props.percent}%</span>
}

function Sum(props) {
    let value = printCentToUsd(Math.round(props.sum * props.percent / 100));
    value = value === 0 ? '' : '$ ' + value;

    return (
        <div className="span-sum">
            {props.percent !== 0 && <div>or <br/></div>}
            <span> {value}</span>
        </div>
    )
}