/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.12.17
 * Time: 15.19
 *
 * @flow
 */

import { Processor } from "./processor";
import { Queue } from "../queue";
import { RequestSetBuilder } from "./request-set-builder";
import { AuthApiClient2ResponseHandler } from "../../auth/auth-api-client2-response-handler";
import { DefaultResponseHandler } from "./default-response-handler";
import { xhrEvents } from "./events";
import { events as authEvents } from "./../../auth/events";

let authResponseHandler = new AuthApiClient2ResponseHandler();
let defaultResponseHandler = new DefaultResponseHandler();

export const secureProcessor = new Processor(new Queue(), [authResponseHandler, defaultResponseHandler]);
export const inSecureProcessor = new Processor(new Queue(), [defaultResponseHandler]);

authEvents.renewingStarted.add(() => {
    secureProcessor.pause();
});

authEvents.renewingFinished.add(success => {
    if (success) secureProcessor.resume();
});

export const xhr = function(): RequestSetBuilder {
    return new RequestSetBuilder(builder => {

        builder.getRequestSet().forEach(request => {
            if (builder.isAuthSkipped() || !request.isSecure()) {
                inSecureProcessor.issue(request);
            }
            else {
                secureProcessor.issue(request);
            }
        });
    });
};

xhr.events = xhrEvents;

window.xhr = xhr;
