/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.18.7
 * Time: 11:13
 */

import AppDispatcher from "../dispatcher";
import {
    LOAD_UNSEEN_APPOINTMENTS_LIST_SUCCESS,
    TOGGLE_UNSEEN_APPOINTMENTS_LIST,
    UNSEEN_APPOINTMENT_DISMISS
} from "./../constants";
import { xhrDismissAppointment, xhrLoadUnseenAppointments } from "./../xhr/unseen-appointments-xhr";

export function loadUnseenAppointments() {

    xhrLoadUnseenAppointments().then(answer => {
        AppDispatcher.dispatch({
            type: LOAD_UNSEEN_APPOINTMENTS_LIST_SUCCESS,
            payload: answer
        });
    });

}

export function toggleUnseenList() {
    AppDispatcher.dispatch({
        type: TOGGLE_UNSEEN_APPOINTMENTS_LIST
    });
}

export function dismissAppointment(id = 0) {

    xhrDismissAppointment(id).then(() => {
        AppDispatcher.dispatch({
            type: UNSEEN_APPOINTMENT_DISMISS,
            payload: id
        });
    });

}