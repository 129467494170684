/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.12.17
 * Time: 13.48
 */

import React from 'react';

export default function GalleryItemButtonsContainer(props)  {
    return <div className="fr-card-btn-bottom" style={props.style}>{props.children}</div>;
}