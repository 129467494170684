/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.29.6
 * Time: 15:05
 */

import ApiClient from "../service/api-client";

export const xhrWhoHintInfo = function() {

    return ApiClient.get(["Q129_get_salon_and_artist_names"]).then(answer => answer[0]);
};
