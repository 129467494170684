/**
 * Created by fliak on 10.1.17.
 */

import { BOOK_LOAD_SALON_INFO_SUCCESS } from "../../constants";
import BaseStore from "./../base-store";

import { SALON_TYPE_FREELANCER, SALON_TYPE_SALON, USER_TYPE_FREELANCER } from "./../../frizo-shared-constants";

class BookAptSalonStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            salonSet: {}
        };

        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case BOOK_LOAD_SALON_INFO_SUCCESS:

                    let salonType;
                    if (payload.user && payload.user.length > 0) {
                        if (payload.user.length > 1) {
                            salonType = SALON_TYPE_SALON;
                        }
                        else {
                            if (payload.user[0].rawUserType === USER_TYPE_FREELANCER) {
                                salonType = SALON_TYPE_FREELANCER;
                            }
                            else {
                                salonType = SALON_TYPE_SALON;
                            }
                        }
                    }
                    else {
                        console.error("Salon loaded without users");
                    }

                    this.store.salonId = payload.id;
                    this.store.salonSet[payload.id] = {
                        name: payload.name,
                        addresses: payload.address,
                        users: payload.user,
                        type: salonType
                    };

                    this.setReady(true);

                    break;

                default:
                    return true;
            }


            this.emitChange();
        });
    }

    getSalonById(salonId = "current") {
        if (salonId === "current") {
            if (!this.store.salonId) throw new Error("Default salon id isn't set");
            salonId = this.store.salonId;
        }
        return this.store.salonSet[salonId];
    }
}

export default BookAptSalonStore;
