/**
 * Created by fliak
 * Name: George Novik
 * Company: U6 SIA
 * Date: 05.12.2017
 * Time: 14:40
 */

import React, {Component} from 'react';
import {Img} from "../../../elements/img";
import {
    BUTTON_LINK_PROMO,
    IMAGE_SIZE_LARGE,
    MODE_CLIENT
} from "../../../frizo-shared-constants";
import {FavoriteMark} from "../../favorites/favorite-mark";
import GalleryFullscreenItemContainer from "../gallery-parts/gallery-fullscreen-item-container";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import AuthButton from "../../../require-auth/auth-button";
import {startBooking} from "../../../ac/book-apt/common-actions";
import moment from 'moment';
import ArtistImageTitle from "../../gallery/elements/artist-image-title";
import {browserHistory, withRouter} from 'react-router'
import {modalHide} from "../../../ac/modals";
import GalleryItemButton from "../gallery-parts/gallery-item-button";
//import {initFilter} from "../../../ac/gallery-filter-actions";
import GalleryShareButton from '../gallery-parts/gallery-share-button';
import getImageTitle from '../../../helper/get-image-title';
import AirtouchCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";

class DashboardGalleryFullscreenAvatar extends Component {

    bookAction(e) {
        e.preventDefault();

        //console.log('this.props.currentItem', this.props.currentItem);
        let date = moment(new Date()).format('YYYY-MM-DD');
        startBooking(
            MODE_CLIENT,
            this.props.currentItem.artist.id,
            this.props.currentItem.salon.id,
            date, 0, 0, 0, null);


        return false;
    }


    redirectToPromo(artistId, url) {

        browserHistory.push({
            pathname: `/promo/${url}/gallery`,
            state: {
                artistId
            }
        });

        modalHide();
    }

    render() {

        //console.log('this.props.currentItem', this.props.currentItem);
        let imgDescription = getImageTitle(this.props.currentItem);

        return <GalleryFullscreenItemContainer>
            {/*{this.createPriceRange()}*/}

            {this.props.favoritesEnabled &&
            <FavoriteMark id={this.props.currentItem.id} type={this.props.currentItem.galleryItemType} inModal={true}/>}
            {this.props.currentItem.artist.airtouchCertified && <AirtouchCertificateBadge {...this.props.currentItem.artist}/>}
            <Img size={IMAGE_SIZE_LARGE} className="img-responsive" src={this.props.currentItem.image.url}
                 description={imgDescription}/>

            {this.props.showPrev && this.props.prevArrow}
            {this.props.showNext && this.props.nextArrow}

            <GalleryItemCardInfo>
                <ArtistImageTitle
                    firstName={this.props.currentItem.artist.firstName}
                    lastName={this.props.currentItem.artist.lastName}
                />

                <GalleryItemButtonsContainer>
                    <GalleryItemButton title="Gallery" type={BUTTON_LINK_PROMO}
                                       onClick={this.redirectToPromo.bind(this, this.props.currentItem.artist.id, this.props.currentItem.salon.url)}/>

                    <GalleryShareButton title="Share" className="fr-btn" shareUrl={this.props.currentItem.shareUrl}
                              imageUrl={this.props.currentItem.image.url}/>
                    <AuthButton autoHideModal={false} onClick={this.bookAction.bind(this)}
                                className="btn fr-btn">Book</AuthButton>
                </GalleryItemButtonsContainer>
            </GalleryItemCardInfo>

        </GalleryFullscreenItemContainer>
    }

}

export default withRouter(DashboardGalleryFullscreenAvatar);