/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 5.4.17
 * Time: 13.40
 */


import AppDispatcher from "../../dispatcher";
import { BOOK_LOAD_SALON_INFO_SUCCESS } from "../../constants";

export function salonInfoLoadSuccess(salonInfo) {
    AppDispatcher.dispatch({
        type: BOOK_LOAD_SALON_INFO_SUCCESS,
        payload: salonInfo
    });
}