/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 15.11.17
 * Time: 10.56
 *
 * @flow
 */

import React from 'react';
import {Col} from 'react-bootstrap';

type Props = {
    children: *,
    onClick: Function
};

export default function GalleryItemContainer(props: Props) {
    return <Col xs={6} sm={6} md={3}>
        <div className="fr-card" onClick={props.onClick}>
            {props.children}
        </div>
    </Col>
}

GalleryItemContainer.defaulProps = {
    onClick: () => {}
};