/**
 * Created by George Novik
 * Name: George Novik
 * Company: U6 SIA
 * Date: 07.12.2018
 * Time: 11:00
 */
import React, {Component} from 'react';
import {Link} from 'react-router';
import imgSrc1 from './../../../../img/invitation-accept-1.jpg';
import imgSrc2 from './../../../../img/invitation-accept-2.jpg';
import imgSrc3 from './../../../../img/invitation-accept-3.jpg';
import imgSrc4 from './../../../../img/invitation-accept-4.jpg';


export default class InvitationAcceptPage extends Component {

    render() {
        let bgImages = [imgSrc1, imgSrc2, imgSrc3, imgSrc4];
        let bgImage = bgImages[Math.floor(Math.random() * bgImages.length)];

        return (
            <div className={"fr-invitation"}>
                <div className="fr-invitation-flex" style={{'backgroundImage': 'url(' + bgImage + ')'}}>
                    <div className="fr-invitation-flex__item-wide">
                        <div className="fr-title-centered">
                            <div className="fr-invitation-flex__item-wide-title">What best describes you?
                            </div>
                        </div>
                    </div>
                    <div className="fr-invitation-flex__item ">
                        <div className="fr-invitation-flex__item-title right">
                            <Link to={"/client-sign-up"}
                                  className="btn fr-btn-big__green client text-uppercase ">Client</Link>
                            <div className={"note"}>Looking for inspiring<br/>beauty service</div>
                        </div>
                    </div>
                    <div className="fr-invitation-flex__item ">
                        <div className="fr-invitation-flex__item-title left">
                            <Link to={"/pro-sign-up"}
                                  className="btn fr-btn-big__green professional text-uppercase">Professional</Link>
                            <div className={"note"}>Beauty artist<br/>open for business</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}