/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 23.11.2020
 * Time: 20:30
 */

import React, { Component } from "react";
// import browserHistory from "react-router";
import { Link, withRouter } from "react-router";
import { Col, Row } from "react-bootstrap";
import { checkProRecommendationLink } from "../../ac/recommendation-actions";
import { proRecommendationStore } from "../../stores/index";
import LoaderBar from "../../elements/loader-bar";

class ProRecommendation extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({
            ready: false
        }, this.getStoreData());
        this.hash = this.props.params.hash || null;
        this.storeChange = this.storeChange.bind(this);
    }

    componentDidMount() {
        if (this.hash) {
            checkProRecommendationLink(this.hash);
        }
        proRecommendationStore.addChangeListener(this.storeChange);
    }

    componentWillUnmount() {
        proRecommendationStore.removeChangeListener(this.storeChange);
    }

    getStoreData() {
        let storeData = proRecommendationStore.getStore();

        return {
            linkValid: storeData.linkValid,
            linkExist: storeData.linkExist
        };
    }

    storeChange() {
        this.setState(Object.assign({}, this.getStoreData(), {
            ready: true
        }));
        if (this.state.linkExist && this.state.linkValid) {
            // browserHistory.push("/pro-products");
            window.location.href="https://frizopro.com/collections/all";
        }
    }

    render() {
        if (!this.state.ready) {
            return <LoaderBar className={"loader-msg-wrapper--full-height"}/>;
        }

        return (
            <div className="fr-banner-top__margin--mobile">
                <Row className="fr-banner-main--landing">
                    <Col xs={12} md={8} mdOffset={2}>
                        {!this.state.linkExist &&
                        <div className={"recommendation"}>
                            <h1 className={"recommendation__title"}>Product link is not valid.</h1>
                            <div className={"recommendation__msg"}>
                                [We’ll take you to <Link className="recommendation__link" to={"/"}>home</Link> homepage]
                            </div>
                        </div>}
                        {this.state.linkExist && !this.state.linkValid &&
                        <div className={"recommendation"}>
                            <h1 className={"recommendation__title"}>Product link is&nbsp;no&nbsp;longer valid.</h1>
                            <div className={"recommendation__msg"}>By&nbsp;the&nbsp;way, download our razor sharp app
                                for unforgettable experience.<br/>
                                So&nbsp;why wait? Get Frizo app now!
                            </div>
                        </div>}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(ProRecommendation);
