/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 18.09.2017
 * Time: 10:11
 */

import React, {Component} from 'react';
import {pricingStore} from '../../stores/index';
import SubscriptionList from "../../components/subscriptions/components/subscription-list";
import {MODE_PRICING, PRICING_SINGLE_ARTIST} from '../../frizo-shared-constants';

export default class PricingSingleArtist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: pricingStore.getFilteredProducts('subscription-single-artist')
        };

        this.storeChange = this.storeChange.bind(this);

    }

    componentDidMount() {
        pricingStore.addChangeListener(this.storeChange);
    }

    componentWillUnmount() {
        pricingStore.removeChangeListener(this.storeChange);
    }

    storeChange() {
        this.setState({
            products: pricingStore.getFilteredProducts('subscription-single-artist')
        });
    }


    render() {

        return (
            <div>
                <SubscriptionList products={this.state.products}
                                  mode={MODE_PRICING} pricing={PRICING_SINGLE_ARTIST}/>
            </div>);
    }
}