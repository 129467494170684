/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.7.17
 * Time: 9.24
 */

import ApiClient from "../../../../service/api-client";

export function xhrSetDefaultExternalAccount(id) {

    let url = ApiClient.getRouting().generate("Q110_set_default_external_account", { extAccountId: id });

    return ApiClient.put(url).then(answer => {
        let [response] = answer;

        //console.log('response', response);

        return Promise.resolve(response);
    });
};
