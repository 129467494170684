/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 30.11.2018
 * Time: 16:30
 */

import React from "react";
import { Link } from "react-router";

export default function FreeSubscriptionPlan(props) {
    let basicSingle = <p className="pricing-plan-item__quality pricing">
        Client products &#8212; we pay you!<br/>
        Online appointment book<br/>
        Client management<br/>
        Appointment <br/>
        Client card payments & tips*<br/>
        Unlimited pro gallery<br/>
        Multiple locations for Freelancers<br/>
        Professional products
    </p>;

    return (
        <div className={"fr-pricing-flex__item bordered"}>
            <div className={`pricing-plan-item--top`}>
                <h3 className="pricing-plan-item__name free">
                    <b>Main features</b>
                </h3>
            </div>
            <div>
                <div className="pricing-plan-item__quality--padding-top">
                    {basicSingle}
                    <div className={"pricing-plan-item__quality--free pricing-plan-item__quality--features "}>
                    </div>
                </div>
                <div className={`pricing-plan-item--bottom `}>
                    <Link className="btn fr-btn-link pricing-plan-item__start" to={"/pro-sign-up"}>SIGN UP</Link>
                </div>
            </div>
            <div className="pricing-plan-item__quality--note">
                <p>*We trust Stripe to securely process payments. Fees apply.
                    <br/><br/><br/>
                </p>
            </div>
        </div>
    );
}
