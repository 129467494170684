/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 06.02.2019
 * Time: 8.25
 */

import React from 'react';
import {sessionStore} from "../../stores";
import LoaderBar from "../../elements/loader-bar";
import NonProDisclaimer from '../../elements/non-pro-disclaimer';


export default function withNonProDisclaimer(WrappedComponent, config = {
    nonPro: true,
    limits: []
}) {

    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                isReady: this.getIsReady(),
                session: this.getSessionData()
            };

            this.updateSessionData = this.updateSessionData.bind(this);
        }


        componentDidMount() {
            sessionStore.addChangeListener(this.updateSessionData);
        }

        componentWillUnmount() {
            sessionStore.removeChangeListener(this.updateSessionData);
        }

        updateSessionData() {
            this.setState({
                session: this.getSessionData(),
                isReady: this.getIsReady()
            })
        }

        getSessionData()    {
            return {
                professional: sessionStore.getStore().session.professional,
            };
        }

        getIsReady()    {
            return sessionStore.isReady();
        }

        render() {

            switch (true) {
                case !this.state.isReady:
                    return <LoaderBar/>;

                case config.nonPro && !this.state.session.professional:
                    return <NonProDisclaimer/>;

                default:
                    return <WrappedComponent {...this.props} />;
            }
        }

    };
};