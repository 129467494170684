/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 27.2.17
 * Time: 17.22
 */

import ApiClient from "../service/api-client";
import { CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";

export default function xhrLoadSalonInfoByArtist(artistId) {
    return ApiClient.get(["Q34_4_salon_info_by_artist", { artistId }], {}, {
        headers: [{
            name: CUSTOM_HEADER_U6_GROUPS,
            value: ["basic", "schedule", "contact", "salonInfo", "salonUsers"]
        }]
    }).then((answer) => {
        let [colleagues] = answer;
        return Promise.resolve(colleagues);
    });
}
