/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 13.29
 */


import React, {Component} from 'react';
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type {TGalleryImage} from "../../../types";
import {BUTTON_DANGER, IMAGE_SIZE_MEDIUM} from "../../../frizo-shared-constants";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import GalleryItemButton from "../gallery-parts/gallery-item-button";
import ArtistImageTitle from "../../gallery/elements/artist-image-title";
import {removeArtistAction} from "../../../ac/profile-artists/common-actions";
import ArtistCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";

type Props = TGalleryImage & {};

export default class ProfessionalProfileArtistAvatar extends Component<Props, any> {

    render() {
        let id = this.props.id;

        return <GalleryItemContainer>
            {this.props.artist.airtouchCertified && <ArtistCertificateBadge {...this.props.artist}/>}
            <GalleryItemImage {...this.props} size={IMAGE_SIZE_MEDIUM} />
            {this.props.artist.airtouchCertified && <ArtistCertificateBadge {...this.props.artist}/>}
            <GalleryItemCardInfo>
                <ArtistImageTitle
                    firstName = {this.props.artist.firstName}
                    lastName = {this.props.artist.lastName}
                />

                <GalleryItemButtonsContainer>
                    <GalleryItemButton title="Remove" type={BUTTON_DANGER} onClick={removeArtistAction.bind(this, this.props.id)} />
                    <GalleryItemButton href={`/my-salon/artists/edit/${id}`} title="Edit" />


                </GalleryItemButtonsContainer>

            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}
