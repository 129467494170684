/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 29.07.2017
 * Time: 11:18
 */

import React, {Component} from 'react';
import {FormControl} from 'react-bootstrap';
import {dashboardWhenStore} from "../../../stores/index";
import {whenDateReset} from "../../../ac/dashboard-filter-actions";


export default class DashboardWhen extends Component {
    constructor(props) {
        super(props);


        this.state = Object.assign({
            location: {},
            data: null,
            firstTime: true,
            searchValue: '',
            isNearestChecked: false,
            selected: -1
        }, this.getStoreData());

        this.onStoreChange = this.onStoreChange.bind(this);
        this.getStoreData = this.getStoreData.bind(this);
        this.onReset = this.onReset.bind(this);

    }

    componentDidMount() {

        dashboardWhenStore.addChangeListener(this.onStoreChange);
    }

    componentWillUnmount() {
        dashboardWhenStore.removeChangeListener(this.onStoreChange);
    }

    onStoreChange() {
        let object = Object.assign({}, this.getStoreData());
        this.setState(object);
    }

    getStoreData() {
        return dashboardWhenStore.getStore();
    }


    onReset() {
        whenDateReset(null);
        this.props.onReset({date: '', from: 0, to: 86400});
    }

    render() {
        return (

            <div className={this.props.className + ' dashboard-when when '}>
                <FormControl
                    readOnly
                    placeholder="Date or time"
                    value={this.props.value}
                    onFocus={this.props.onFocus}/>
                {this.props.value !== '' &&
                <button onClick={this.onReset} className="dashboard-btn__reset fr-btn pull-right">
                    <span className="glyphicon glyphicon-remove"></span>
                </button>}
            </div>
        )
    }
}
