/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 4.06.2017
 * Time: 22:18
 */

import React from 'react';
import { Link } from 'react-router';
import printAddress from '../../../helper/print-address';
import type {TGallerySeparator} from "../../../types";

export default function DashboardGallerySeparator(props: TGallerySeparator) {

    let artistText = '';

    if (props.artist) {
        artistText = props.artist.firstName + ' ' + props.artist.lastName + ' at ';
    }

    let addressBlock = ', ' + printAddress(props.salon.addresses[0]);

    let url = props.salon.url;
    let salonName = props.salon.title;

    return (
        <div
          className="dashboard-gallery__separator"
          style={{width: '100%'}}>
          {artistText}
          <Link to={`/promo/${url}/services`}>{salonName}</Link>
          {addressBlock}
        </div>
    )
}