/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 29.03.2018
 * Time: 13:02
 */

export default function gradientFill(gradientStyle = "top") {

    let colors = ["#b6d5e9", "#c9b7e8", "#e8b7d5", "#b7e8c9", "#d5e8b7", "#e8c9b7"];
    let random_color = colors[Math.floor(Math.random() * colors.length)];
    let random_color2 = gradientStyle === "top" ? colors[Math.floor(Math.random() * colors.length)] : "#fff";

    return "linear-gradient(to " + gradientStyle + "," + random_color + "," + random_color2 + ")";
}
