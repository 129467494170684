/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.11.17
 * Time: 14.10
 */

import { salonNormalizerLegacy } from "../../salon";
import { singleAvatarNormalizer } from "./single-avatar-normalizer";

type TRawArtistsGroupedBySalon = {
    salon: TRawSalonLegacy,
    artists: Array<{
        artist: TRawArtistLegacyBase
    }>
};

export function* artistsGroupedBySalon(rawItems: Array<TRawArtistsGroupedBySalon>) {

    let items = [];

    for (let i1 = 0; i1 < rawItems.length; i1++) {
        let { salon: rawSalon, artists: rawArtists } = rawItems[i1];

        let salon = salonNormalizerLegacy(rawSalon);

        let artists = [];

        for (let i2 = 0; i2 < rawArtists.length; i2++) {
            let artist = singleAvatarNormalizer(rawArtists[i2].artist);
            artists.push(artist);

            yield {
                structure: [salon],
                element: artist
            };
        }

        items.push({ salon, artists });
    }

    return items;
}
