/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 1.4.17
 * Time: 9.01
 */

import BaseSyncer from "./../../base-syncer";

import { whSyncComplete, whSyncError } from "./../../../ac/profile/professional/wh-sync";
import { xhr } from "../../../service/api-client-2";
import { MODE_SIMULTANEOUS } from "../../../frizo-shared-constants";
import { Request } from "../../../service/api-client-2/request";

export default class WHSyncer extends BaseSyncer {

    putArtistSet(artists) {
        this.artists = artists;
    }

    persist() {

        if (this.artists === undefined) {
            console.warn("Persist skipped because not data enough", this);
            return;
        }

        let builder = xhr()
        .alwaysMultiple()
        .suppressNotifications()
        .setMode(MODE_SIMULTANEOUS);

        this.artists.forEach(artist => {
            let timetable = artist.schedule.map(day => {
                return {
                    addressID: day.address.id,
                    start: day.from,
                    end: day.to,
                    isDayOff: day.dayOff,
                    dayIndex: day.day
                };
            });

            builder
            .post("@Q55_set_artist_schedule", null, artist.id)
            .data({
                userID: artist.id,
                timetable: JSON.stringify(timetable)
            })
            .end();
        });

        return builder.then((responseSet: Request) => {

            let errorReport = {};
            let allOk = true;

            responseSet.forEach(partial => {

                if (partial.getStatusCode() !== 200) {
                    errorReport[partial.getRequestId()] = [partial.response.message];

                    allOk = false;
                }
            });

            if (allOk) {
                whSyncComplete();
            }
            else {
                whSyncError(errorReport);
            }

        }, (responseSet: Request) => {

            let report = responseSet.toHash((partial: Request) => {
                if (partial.getStatusCode() !== 200) {
                    return partial.response.message;
                }
                else {
                    return [];
                }
            });

            whSyncError(report);
        });
    }
}
