/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.24.4
 * Time: 15:25
 */
import ApiClient from "../service/api-client";

export function xhrAddGalleryFavorite(galleryItemId) {
    return ApiClient.post(["Q18_add_gallery_favorite"], { galleryItemID: galleryItemId }).then(answer => answer);
}

export function xhrAddUserFavorite(userId) {
    return ApiClient.post(["Q25_add_user_favorite"], { userID: userId }).then(answer => answer);
}

export function xhrRemoveGalleryFavorite(galleryItemId) {
    return ApiClient.post(["Q19_remove_gallery_favorite"], { galleryItemID: galleryItemId }).then(answer => answer);
}

export function xhrRemoveUserFavorite(userId) {
    return ApiClient.post(["Q26_remove_user_favorite"], { userID: userId }).then(answer => answer);
}
