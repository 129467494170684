/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.01.2018
 * Time: 11:48
 */
import BaseStore from "../../base-store";
import {
    MODAL_CHANGE,
    MODAL_HIDE,
    PROFILE_SALON_SERVICE_MODIFIED,
    PROFILE_SALON_SERVICE_MODIFY_FAILED,
    PROFILE_SALON_SERVICE_REMOVE_FAILED,
    PROFILE_SALON_SERVICE_REMOVED,
    SERVICE_EDIT_MODAL
} from "../../../constants";
import { modalHide } from "../../../ac/modals";

export default class SelectedServiceStore extends BaseStore {
    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case MODAL_CHANGE:
                    if (payload.type === SERVICE_EDIT_MODAL) {

                        this.store.service = payload.options.service;
                        this.store.duration = payload.options.duration;
                        this.store.processing = payload.options.processing;

                        this.store.btnDisabled = false;
                        this.store.violations = {};
                    }
                    break;

                case PROFILE_SALON_SERVICE_MODIFIED:

                    setTimeout(modalHide);

                    break;


                case PROFILE_SALON_SERVICE_MODIFY_FAILED:
                    this.store.btnDisabled = false;
                    this.store.violations = payload.errors;
                    this.store.violations.general = payload.message;

                    break;


                case PROFILE_SALON_SERVICE_REMOVED:
                    setTimeout(modalHide);

                    break;

                case PROFILE_SALON_SERVICE_REMOVE_FAILED:
                    this.store.btnDisabled = false;
                    setTimeout(modalHide);

                    break;

                case MODAL_HIDE:
                    break;

                default:
                    return true;
            }

            this.emitChange();
        });

    }

    getInitialStore() {
        return {

            service: {},
            processing: [],
            duration: [],
            violations: {},
            btnDisabled: false
        };
    }
}
