/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 21.01.2019
 * Time: 20.21
 */

export function usdToCent(usd) {
    return Math.round(usd * 100);
}

export function centToUsd(cent) {
    return cent / 100;
}

export function centToUsdInteger(cent) {
    return (cent / 100).toFixed(0);
}

export function printCentToUsd(cent) {
    return centToUsd(cent).toFixed(2);
}

export function getPercentDecimalLength() {
    return 5;
}

export function percentToDecimal(percent, length = null) {
    if (length === null) {
        length = getPercentDecimalLength();
    }

    let power = Math.pow(10, length);

    return Math.round(power * percent / 100) / power;
}

export function decimalToPercent(decimal, length = null) {
    if (length === null) {
        length = getPercentDecimalLength();
    }

    let power = Math.pow(10, length);

    return Math.round(power * decimal * 100) / power;
}
