/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 26.7.17
 */

import ApiClient from "../../../service/api-client";

import { CUSTOM_HEADER_U6_GROUPS } from "./../../../frizo-shared-constants";

export function xhrDeclineOrder(orderId) {

    let url = ApiClient.getRouting().generate("Q115_decline_payment_order", {
        id: orderId
    });

    return ApiClient.put(url, null, {
        headers: {
            [CUSTOM_HEADER_U6_GROUPS]: "payment-order"
        }
    }).then(answer => {
        let [response] = answer;

        return Promise.resolve(response);
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}
