/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.7.17
 * Time: 9.24
 */

import ApiClient from "../../../../service/api-client";

export function xhrRemovePayCard(id) {

    let url = ApiClient.getRouting().generate("Q140_delete_subscription_payment_source", { sourceId: id });

    return ApiClient.del(url).then(answer => {
        let [response] = answer;

        //console.log('response', response);

        return Promise.resolve(response);
    });
};
