/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 12.09.2017
 * Time: 15:28
 */

import ApiClient from "../service/api-client";
import { xhr } from "../service/api-client-2";
import { CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";

/**
 * @deprecated Use xhrGetSubscriptionProducts instead
 */
export const xhrGetSubscriptionPlans = function() {
    let url = ApiClient.getRouting().generate("Q128_get_subscription_plans");

    return ApiClient.get(url, null)
    .then((answer) => {

        let plans = answer[0];

        if (Object.keys(plans).length === 0) {
            return Promise.resolve([]);
        }

        return Promise.resolve(plans);
    });
};

export const xhrGetSubscriptionProducts = function() {
    return xhr()
    .get("@Q2-45-Get-Subscription-Products")
    .header(CUSTOM_HEADER_U6_GROUPS, "basic, product-plans")
    .end()
    .then((request: Request) => {
        let products = request.response.data;

        if (Object.keys(products).length === 0) {
            return Promise.resolve([]);
        }

        return Promise.resolve(products);
    });
};

export const xhrGetPromoCoupon = function(couponId) {
    let newApiClientURL = ApiClient.getRouting().generate("Q154_get_coupon_data", { couponId });

    return xhr()
    .suppressNotifications()
    .get(newApiClientURL, null)
    .end()
    .then((request: Request) => {
            let { response } = request;
            return Promise.resolve(response);
        },
        (request: Request) => {
            let { response } = request;
            return Promise.reject(response.errors);
        });

};

export const xhrCreateNewSubscription = function(params) {
    let newApiClientURL = ApiClient.getRouting().generate("Q150_create_subscription");

    return ApiClient.post(newApiClientURL, params)
    .then((answer) => {

        let response = answer[0];

        return Promise.resolve(response);

    }, answer => {
        let [response] = answer;

        return Promise.reject(response);

    });
};

export const xhrChangeSubscriptionPlan = function(params) {

    let newApiClientURL = ApiClient.getRouting().generate("Q152_change_subscription_main_plan", { planId: params });

    return ApiClient.put(newApiClientURL, null)
    .then((answer) => {

        let response = answer[0];

        return Promise.resolve(response);

    }, answer => {
        let [response] = answer;

        return Promise.reject(response);

    });
};

export const xhrGetAvailableSubscriptionProducts = function() {

    let mode = "available";

    return xhr()
    .get("@Q2-46-Get-Subscription-Products-For-User", { mode })
    .header(CUSTOM_HEADER_U6_GROUPS, "basic, product-plans")
    .end()
    .then((request: Request) => {
            let { response } = request;
            return Promise.resolve(response.data);
        },
        (request: Request) => {
            let { response } = request;
            return Promise.reject(response.errors);
        });
};
