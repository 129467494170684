/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 4/4/17
 * Time: 10:43
 */

import React, {Component} from 'react';
import {FormGroup, Button, Row, Col} from 'react-bootstrap';
import ToggleGroup from '../../../components/forms/toggle-group';
import DropCrop from '../../../elements/drop-crop';
import Violation from '../../helper-components/violation';
import stores from '../../../stores';
import {modalChange} from '../../../ac/modals';
import {CHANGE_PASSWORD_MODAL, CHANGE_EMAIL_MODAL} from '../../../constants';
import {
    GENDER_FEMALE,
    GENDER_MALE,
    GENDER_NOT_SHARED
} from '../../../frizo-shared-constants';
import {persist, profileDataChange} from './../../../ac/profile/personal/essentials';
import {profilePersonalEssentialsStore} from './../../../stores';
import PhoneInput from './../../../elements/phone-input';
import PropTypes from 'prop-types';
import ProfileCompletenessBadge from './../../badge/profile-completeness-badge';

class PersonalInfo extends Component {

    constructor(props, context) {
        super(props, context);

        this.sessionStore = stores.session;

        this.state = {
            formIsValid: false,
            violations: {},
            showMessage: false,
            showError: false,
            error: null,


            userName: '',
            firstName: '',
            lastName: '',
            genderID: '',
            instagramUsername: '',
            phone: '',
            imageUrl: '',
            imageId: '',

        };

        Object.assign(this.state, profilePersonalEssentialsStore.getStore());

        this.handleChange = this.handleChange.bind(this);
        this.genderIDSwitch = this.genderIDSwitch.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleInstagramNameChamge = this.handleInstagramNameChamge.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.saveInfo = this.saveInfo.bind(this);
    }

    componentDidMount() {
        profilePersonalEssentialsStore.addChangeListener(this.handleChange);
    }

    componentWillUnmount() {
        profilePersonalEssentialsStore.removeChangeListener(this.handleChange);
    }

    genderIDSwitch(genderId) {
        profileDataChange({genderID: genderId});
    }

    handleChange() {
        this.setState(profilePersonalEssentialsStore.getStore());
    }

    handleFirstNameChange(e) {
        profileDataChange({
            firstName: e.target.value,
            showMessage: false,
            showError: false
        });
    }

    handleLastNameChange(e) {
        profileDataChange({
            lastName: e.target.value,
            showMessage: false,
            showError: false
        });
    }

    handlePhoneChange(e) {
        profileDataChange({
            phone: e.target.rawValue,
            showMessage: false,
            showError: false
        });
    }

    handleInstagramNameChamge(e) {
        profileDataChange({
            instagramUsername: e.target.value,
            violations: false,
            showMessage: false,
            showError: false
        });
    }


    onImageChange(patch) {
        if (patch.imageId === 0) {
            profileDataChange({
                imageUrl: '',
                imageId: '',
                showMessage: false,
                showError: false
            });
        } else {
            profileDataChange({
                imageUrl: patch.imageURL,
                imageId: patch.imageId,
                showMessage: false,
                showError: false
            });
        }
    }

    changeEmail() {
        modalChange(CHANGE_EMAIL_MODAL);
    }

    changePassword() {
        modalChange(CHANGE_PASSWORD_MODAL);
    }

    saveInfo(e) {
        e.preventDefault();
        persist();
    }

    render() {

        if (this.sessionStore.isLogged()) {

            return (
                <div className="fr-mt-ng">

                    {this.state.showError && <div className="error-message">{this.state.error}</div>}

                    <form name="personalInfo" method="post" noValidate="novalidate"
                          className="form-horizontal fr-masked">
                        <Row>
                            <Col md={6} xs={12}>
                                <div className="fr-profile__title">E-mail</div>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <input type="text" name="city"
                                               className="form-control" readOnly="readonly"
                                               value={this.state.userName}/>
                                    </div>
                                </FormGroup>


                                <div className="fr-profile__title">Change<Button bsClass="btn-link"
                                                                                 onClick={this.changeEmail}>e-mail</Button>or
                                    <Button bsClass="btn-link" onClick={this.changePassword}>password</Button></div>

                                <div className="fr-profile__title" style={{'display': 'block'}}>Name</div>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <input type="text" name="firstName" required="required"
                                               value={this.state.firstName}
                                               className="form-control" placeholder="First"
                                               onChange={this.handleFirstNameChange}/>
                                        <Violation violationSet={this.state.violations.firstName}
                                                   visible={!this.state.formIsValid}/>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <input type="text" name="lastName" required="required"
                                               value={this.state.lastName}
                                               className="form-control" placeholder="Last"
                                               onChange={this.handleLastNameChange}/>
                                        <Violation violationSet={this.state.violations.lastName}
                                                   visible={!this.state.formIsValid}/>
                                    </div>
                                </FormGroup>

                                <div className="fr-profile__title">Gender</div>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <div className="fr-mb">

                                            <ToggleGroup justified selected={this.state.genderID}
                                                         onSelect={this.genderIDSwitch}>
                                                <Button href="#" value={GENDER_FEMALE}>Female</Button>
                                                <Button href="#" value={GENDER_MALE}>Male</Button>
                                                <Button href="#" value={GENDER_NOT_SHARED}>Not sharing</Button>
                                            </ToggleGroup>

                                            <Violation violationSet={this.state.violations.genderID}
                                                       visible={!this.state.formIsValid}/>
                                        </div>
                                    </div>
                                </FormGroup>
                                <div className="fr-profile__title">Phone</div>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <PhoneInput className="form-control" type="phone"
                                                    required="required"
                                                    name="phone"
                                                    value={this.state.phone}
                                                    mask='(111) 111-1111'
                                                    onChange={this.handlePhoneChange}
                                                    readOnly={this.props.readOnly}
                                                    placeholder="Phone"
                                                    autoComplete="off"
                                        />

                                        <Violation violationSet={this.state.violations.phone}
                                                   visible={!this.state.formIsValid}/>
                                    </div>
                                </FormGroup>
                                <div className="fr-profile__title">Social</div>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <input type="text" name="instagramUsername"
                                               className="form-control" placeholder={"Instagram username"}
                                               value={this.state.instagramUsername}
                                               autoComplete="off" maxLength="100"
                                               onChange={this.handleInstagramNameChamge}/>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="col-sm-12 hidden-xs hidden-sm">
                                        <button name="saveInfo" className="btn fr-btn fr-big-button pull-right"
                                                onClick={this.saveInfo}>Save
                                        </button>
                                    </div>
                                </FormGroup>
                            </Col>

                            <Col md={6} xs={12}>
                                <div className="fr-profile__title-photo">
                                    Photo&nbsp;
                                    <ProfileCompletenessBadge
                                        strength={this.context.completeness.profilePersonal.status}/>
                                </div>
                                <FormGroup>
                                    <div className="col-sm-12">
                                        <DropCrop
                                            imgType={'profile'}
                                            style={{width: '100%', height: 400}}
                                            imageURL={this.state.imageUrl}
                                            imageId={this.state.imageId}
                                            removeBtnCaption={'Replace'}
                                            onImageChange={this.onImageChange}/>
                                    </div>
                                </FormGroup>
                                <Violation violationSet={this.state.violations.imageId}
                                           visible={!this.state.formIsValid}/>
                                <FormGroup>
                                    <div className="col-sm-12 hidden-lg hidden-md ">
                                        <button name="saveInfo" className="btn fr-btn fr-big-button pull-right"
                                                onClick={this.saveInfo}>Save
                                        </button>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </div>
            );
        } else return null;
    }
}

PersonalInfo.contextTypes = {
    completeness: PropTypes.object
};

export default PersonalInfo;
