/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.26.5
 * Time: 13:02
 */

import ApiClient from "../service/api-client";

export function xhrRemoveGalleryItem(itemId) {

    return ApiClient.post(["Q29_delete_gallery_item"], { galleryItemID: itemId });
}
