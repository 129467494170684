/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 31.01.2018
 * Time: 15:10
 */

import React, {Component} from 'react';
import {Modal, Button, Form, FormControl, Col, Row} from 'react-bootstrap';
import Violation from '../helper-components/violation';
import {artistServiceStore} from '../../stores/index';
import {modalHide} from "../../ac/modals";
import PropTypes from 'prop-types';
import {
    removeArtistServiceAction,
    modifyArtistServiceAction,
    addArtistServiceAction
} from '../../ac/profile-artists/common-actions';
import gradientFill from './../../helper/gradient-fill';

export default class ArtistServiceModal extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            violations: {},
            btnDisabled: false,
            service: Object.assign({}, this.props.service)
        }, this.getStoreData());

        this.storeUpdate = this.storeUpdate.bind(this);
        this.assignService = this.assignService.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.removeService = this.removeService.bind(this);
        this.modifyService = this.modifyService.bind(this);
        this.checkbasePrice = this.checkbasePrice.bind(this);

    }

    componentDidMount() {
        artistServiceStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        artistServiceStore.removeChangeListener(this.storeUpdate);
    }


    getStoreData() {

        return artistServiceStore.getStore();
    }

    storeUpdate() {
        let object = Object.assign({}, this.getStoreData());

        this.setState(object);
    }


    checkbasePrice() {
        return Number(this.state.service.price) === Number(this.state.service.individualPrice);
    }

    removeService() {

        this.setState({btnDisabled: true});
        removeArtistServiceAction(this.props.artistId, this.props.service.serviceId);
    }

    assignService() {
        this.setState({btnDisabled: true});
        const service = {
            id: this.props.service.serviceId,
            price: Number(this.state.service.individualPrice) === Number(this.state.service.price) ? '' : this.state.service.individualPrice,
            serviceId: this.props.service.serviceId,
            userId: this.props.artistId
        };

        addArtistServiceAction(this.props.artistId, service);

    }

    modifyService() {
        this.setState({btnDisabled: true});
        const service = {
            id: this.props.service.serviceId,
            serviceId: this.props.service.serviceId,
            userId: this.props.artistId,
            price: Number(this.state.service.individualPrice) === Number(this.state.service.price) ? '' : this.state.service.individualPrice,
        };


        modifyArtistServiceAction(this.props.artistId, service);
    }

    changeHandler(ev) {
        let value = ev.target.value;
        if ((value.indexOf('.') > -1) || (value.indexOf(',') > -1) || (value.length > 4)) {
            return false;
        }
        let service = this.state.service;
        service['individualPrice'] = '';

        value.replace(/\D+/g, '');
        if ((parseInt(value, 10) && value.length < 5) || (this.state.service.allowZeroPrice && (value === '0'))) {
            service['individualPrice'] = value;
        }

        this.setState({service});
    }


    close() {
        modalHide();
    }

    render() {
        let title = this.props.assigned ? 'Edit service' : 'Assign service';

        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>{title}</h2>
                </Modal.Header>
                <Modal.Body className="service-modal__body">
                    <Form>
                        <Row className="fr-mb ">
                            <Col xs={5} md={5}>
                                <div className="fr-title">Service</div>
                            </Col>
                            <Col xs={7} md={7}>
                                <div className="fr-service-title">{this.state.service.i18nKey}</div>
                            </Col>
                        </Row>
                        <Row className="fr-mb ">
                            <Col xs={5} md={5}>
                                <div className="fr-title">Regular price</div>
                            </Col>
                            <Col xs={7} md={7}>
                                <div className="fr-service-title">{"$ "}{this.state.service.price}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={5} md={5}>
                                <div className="fr-title__edit-price">Individual price</div>
                            </Col>
                            <Col xs={7} md={7}>
                                <FormControl placeholder="Individual price, $" name="price"
                                             value={this.state.service.individualPrice} type="tel"
                                             onChange={this.changeHandler} className=""/>
                                <Violation violationSet={this.state.violations.individualPrice}
                                           visible={!this.state.formIsValid}/>
                            </Col>
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="client-modal__footer">
                    {this.props.assigned &&
                    <Button className="client-modal__footer fr-btn-client__remove" disabled={this.state.btnDisabled}
                            onClick={this.removeService}>Remove</Button>}
                    {this.props.assigned &&
                    <Button className="client-modal__footer fr-btn-client__save" disabled={this.state.btnDisabled}
                            onClick={this.modifyService}>Save</Button>}
                    {!this.props.assigned && <Button className="client-modal__footer fr-btn-client__remove "
                                                     onClick={this.close}
                                                     disabled={this.state.btnDisabled}>Cancel</Button>}
                    {!this.props.assigned && <Button className="client-modal__footer fr-btn-client__edit "
                                                     onClick={this.assignService} disabled={this.state.btnDisabled}>Assign</Button>}
                </Modal.Footer>
            </div>
        )
    }
}
ArtistServiceModal.propTypes = {
    service: PropTypes.object,
    artistId: PropTypes.number,
    assigned: PropTypes.bool
};

ArtistServiceModal.defaultProps = {
    service: {},
    artistId: 0,
    assigned: false
};
