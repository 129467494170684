/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.7.17
 */

import {
    CREATE_PAYMENT_ORDER_MODAL,
    PAYMENT_ORDER_APTS_LOADED,
    PAYMENT_ORDER_CREATE_ERROR,
    PAYMENT_ORDER_LOAD,
    PAYMENT_ORDER_SUGGEST_TAX,
    PAYMENT_ORDER_UPDATE_PRICE,
    PAYMENT_ORDER_UPDATE_TAX,
    REFRESH_APPOINTMENT_LIST
} from "./../constants";
import { FORM_MODE_CREATE, FORM_MODE_UPDATE, PAYMENT_TYPE_CASH } from "./../frizo-shared-constants";
import { modalChange, modalHide } from "./modals";
import { xhrGetVisitApts } from "./../xhr/payment-order/get-visit-apts-xhr";
import { xhrGetPaymentOrderForApt } from "./../xhr/payment-order/get-payment-order-for-apt-xhr";
import { xhrGetSuggestedTax } from "../xhr/payment-order/get-suggested-tax-xhr";
import { xhrCreatePaymentOrder } from "../xhr/payment-order/create-paymen-order-xhr";
import { xhrClosePaymentOrderWithCash } from "../xhr/payment-order/close-payment-order-with-cash-xhr";
import AppDispatcher from "../dispatcher";
import notificationSystem from "../service/notification-system";
import { decimalToPercent, percentToDecimal } from "../helper/money-converter";

export function createNewPaymentOrder(aptId) {
    modalChange(CREATE_PAYMENT_ORDER_MODAL, {
        aptId: aptId,
        mode: FORM_MODE_CREATE
    });

    xhrGetVisitApts(aptId).then(apts => {
        AppDispatcher.dispatch({
            type: PAYMENT_ORDER_APTS_LOADED,
            payload: {
                apts
            }
        });
    });
}

export function alterExistedPaymentOrder(aptId) {
    modalChange(CREATE_PAYMENT_ORDER_MODAL, {
        aptId: aptId,
        mode: FORM_MODE_UPDATE
    });

    xhrGetPaymentOrderForApt(aptId).then(response => {
        AppDispatcher.dispatch({
            type: PAYMENT_ORDER_LOAD,
            payload: response
        });
    });
}

export function updateServicePrice(aptId, price) {
    AppDispatcher.dispatch({
        type: PAYMENT_ORDER_UPDATE_PRICE,
        payload: {
            aptId,
            price
        }
    });
}

export function updateTax(taxPercent) {
    AppDispatcher.dispatch({
        type: PAYMENT_ORDER_UPDATE_TAX,
        payload: {
            taxPercent
        }
    });
}

export function suggestTaxPercent(addressId) {
    return xhrGetSuggestedTax(addressId).then(response => {

        AppDispatcher.dispatch({
            type: PAYMENT_ORDER_SUGGEST_TAX,
            payload: {
                taxPercent: decimalToPercent(response.taxRate)
            }
        });
    });
}

export function createPaymentOrder(data, paymentType = PAYMENT_TYPE_CASH) {
    let request = Object.assign({}, data, {
        //convert percent to decimal with configured zero count after point
        taxPercent: percentToDecimal(data.taxPercent),
        paymentType
    });

    return xhrCreatePaymentOrder(request).then(response => {

        AppDispatcher.dispatch({
            type: PAYMENT_ORDER_LOAD,
            payload: response
        });

        AppDispatcher.dispatch({
            type: REFRESH_APPOINTMENT_LIST
        });

        notificationSystem.logSuccess("Payment order created successfully");

        modalHide();


    }, response => {

        let payload = {};
        if (response.errors) {
            payload = response.errors;
        }
        else {
            payload = {
                general: response.message
            };
        }

        AppDispatcher.dispatch({
            type: PAYMENT_ORDER_CREATE_ERROR,
            payload
        });
    });
}

export function closePaymentOrderWithCash(paymentOrderId) {
    xhrClosePaymentOrderWithCash(paymentOrderId).then(response => {
        AppDispatcher.dispatch({
            type: PAYMENT_ORDER_LOAD,
            payload: response
        });

        AppDispatcher.dispatch({
            type: REFRESH_APPOINTMENT_LIST
        });

        modalHide();
    }, response => {

        let payload = {};
        if (response.errors) {
            payload = response.errors;
        }
        else {
            payload = {
                general: response.message
            };
        }

        AppDispatcher.dispatch({
            type: PAYMENT_ORDER_CREATE_ERROR,
            payload
        });
    });
}