/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 4.7.17
 * Time: 19.59
 */


import { COMMON_MODAL_CLEAR, COMMON_MODAL_SAVE, COMMON_MODAL_UPDATE_DATA } from "../constants";


import AppDispatcher from "../dispatcher/index";


export function save() {
    AppDispatcher.dispatch({
        type: COMMON_MODAL_SAVE
    });
}

export function clear() {
    AppDispatcher.dispatch({
        type: COMMON_MODAL_CLEAR
    });
}

export function update(data) {
    AppDispatcher.dispatch({
        type: COMMON_MODAL_UPDATE_DATA,
        payload: data
    });
}