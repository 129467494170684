/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.19.4
 * Time: 01:20
 */

import BaseStore from "../../base-store";
import {
    ARTIST_ADDED,
    ARTIST_INVITATION_SENT,
    ARTIST_SAVE_ERROR,
    ARTIST_SERVICES_LOADED,
    PROFILE_ARTIST_SERVICE_ADDED,
    PROFILE_ARTIST_SERVICE_MODIFIED,
    PROFILE_ARTIST_SERVICE_REMOVED,
    UPDATE_ARTIST_FORM
} from "../../../constants";
import { updateIndex } from "../../../components/profile/professional/services/helpers";
import Validator from "./../../../validation/validator";

export default class ProfileArtistStore extends BaseStore {
    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case UPDATE_ARTIST_FORM:

                    Object.assign(this.store.artistInfo, payload);

                    break;

                case ARTIST_SERVICES_LOADED:
                    this.store.services = payload.services;
                    this.updateServicesIndex();

                    this.store.salonServices = this.makeSalonServices(payload.salonCorrections);
                    this.updateSalonServicesIndex();

                    if (payload.artistsCorrections) {

                        this.store.artistServices = payload.artistsCorrections.map(service => {
                            service.id = service.serviceId;
                            return service;
                        });
                        this.updateArtistServicesIndex();
                    }

                    break;

                case ARTIST_ADDED:
                    this.store.artistInfo = payload;
                    this.store.violations = {};
                    this.store.btnDisabled = false;

                    break;

                case PROFILE_ARTIST_SERVICE_REMOVED:
                    this.store.artistServices = this.store.artistServices.filter(service => Number(service.id) !== Number(
                        payload));
                    this.updateArtistServicesIndex();

                    break;

                case PROFILE_ARTIST_SERVICE_ADDED:
                    this.store.artistServices.push(payload);
                    this.updateArtistServicesIndex();

                    break;

                case PROFILE_ARTIST_SERVICE_MODIFIED:
                    this.store.artistServices = this.store.artistServices.map(service => {
                        if (service.id === payload.id) {
                            service = payload;
                        }
                        return service;
                    });
                    this.updateArtistServicesIndex();

                    break;

                case ARTIST_SAVE_ERROR:
                    if (payload.errors) {
                        this.store.violations = payload.errors;

                        if (payload.errors.username) {
                            this.store.violations.email = payload.errors.username;
                        }
                    }
                    else {
                        this.store.violations = {
                            general: payload.message
                        };
                    }


                    break;
                case ARTIST_INVITATION_SENT:

                    this.store.btnDisabled = payload.message === undefined;
                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialStore() {
        return {
            artistInfo: {
                id: 0,
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                instagramUsername: "",
                image: {
                    id: "",
                    url: ""
                }
            },
            artistServices: [],
            artistServicesIndex: {},
            salonServices: [],
            salonServicesIndex: {},
            services: [],
            servicesIndex: {},
            violations: {},
            btnDisabled: false
        };
    }

    getArtistInfo() {
        return this.store.artistInfo;
    }

    getServicesInfo() {
        const { services, artistServices, salonServices, servicesIndex, salonServicesIndex, artistServicesIndex } = this.store;
        return {
            services,
            artistServices,
            salonServices,
            servicesIndex,
            salonServicesIndex,
            artistServicesIndex
        };
    }

    updateServicesIndex() {
        this.store.servicesIndex = updateIndex(this.store.services);
    }

    updateSalonServicesIndex() {
        this.store.salonServicesIndex = updateIndex(this.store.salonServices);

    }

    updateArtistServicesIndex() {
        this.store.artistServicesIndex = updateIndex(this.store.artistServices);
    }

    makeSalonServices(corrections) {

        return corrections.map(element => {
            element = Object.assign(this.store.servicesIndex[element.serviceId], element, { leaf: true });

            return element;
        });
    }


    /**
     * @protected
     */
    buildValidation() {
        this.validator = new Validator();

        this.validator.addConstraint("firstName", "is-required");
        this.validator.addConstraint("firstName", "is-name");
        this.validator.addConstraint("firstName", "length", {
            min: 2,
            max: 50
        });

        this.validator.addConstraint("lastName", "is-required");
        this.validator.addConstraint("lastName", "is-name");
        this.validator.addConstraint("lastName", "length", {
            min: 2,
            max: 50
        });

        this.validator.addConstraint("email", "is-required");
        this.validator.addConstraint("email", "is-email");

        this.validator.addConstraint("phone", "is-required");
        this.validator.addConstraint("phone", "is-number");
        this.validator.addConstraint("phone", "length", {
            max: 10,
            min: 10
        });

        this.validationScope = this.store.artistInfo;
    }
}
