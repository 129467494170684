import React from "react";

type TProps = {
    airtouchCertUrl?: string,
    airtouchCertified: number,
    firstName: string,
    lastName: string
};

export default function AirtouchCertificateBadge(props: TProps) {
    let className = `fr-certificate stage-${props.airtouchCertified}`;
    if (props.className !== undefined) {
        className = className + props.className;
    }
    let part = "";
    switch (props.airtouchCertified) {
        case 10:
            part = "certified as AirTouch® Foundation";
            break;
        case 20:
            part = "certified as AirTouch® Intensive";
            break;
        case 30:
            part = "certified as AirTouch® Limited";
            break;
        case 40:
            part = "certified as AirTouch® Business";
            break;
        case 99:
            part = "is creator of AirTouch® technique";
            break;
        default:
            part = "";
    }

    let title = `${props.firstName} ${props.lastName} ${part}`;

    return (<div className={className} title={title}>
        {props.airtouchCertUrl && <a href={props.airtouchCertUrl} rel="noopener noreferrer" target="_blank">{null}</a>}
    </div>);
}
