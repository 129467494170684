/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.15.3
 * Time: 12:49
 */

import BaseStore from "../../base-store";
import {
    ARTIST_LIST_CLICK,
    ARTIST_LIST_INIT,
    ARTIST_LIST_LOADED,
    ARTIST_LIST_SCROLL,
    ARTIST_REMOVED,
    PROFILE_ARTIST_SALON_INFO_LOADED
} from "../../../constants";


export default class ProfileArtistStore extends BaseStore {

    constructor(...args) {
        super(...args);
        this.store = {
            galleryData: [],
            complete: false,
            more: true,
            stop: false,
            salonInfo: {}
        };

        this.imagesPerSection = 4;

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case ARTIST_LIST_LOADED:
                    this.setGalleryState(payload);

                    break;

                case PROFILE_ARTIST_SALON_INFO_LOADED:
                    this.store.salonInfo = payload.salonInfo;

                    break;

                case ARTIST_REMOVED:
                    this.store.galleryData = this.store.galleryData.filter(artist => artist.id !== payload);

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });

    }

    updateGalleryData(data) {
        this.store.galleryData = [...this.store.galleryData, ...data];

    }

    setImagesPerSection(images) {
        this.imagesPerSection = images;
    }

    resetStore() {
        this.store = {
            galleryData: [],
            complete: false,
            more: true,
            stop: false,
            salonInfo: {}
        };
    }

    getSalonInfo() {

        return this.store.salonInfo;
    }

    checkStop(payload) {

        if (!payload.galleryData || payload.galleryData.length < this.imagesPerSection) {

            return true;
        }

        return false;
    }


    setGalleryState(payload) {
        if (!this.checkStop(payload) && payload.action === ARTIST_LIST_INIT) {
            this.store.more = true;
            this.store.scroll = false;
        }
        if (!this.checkStop(payload) && payload.action === ARTIST_LIST_CLICK) {
            this.store.more = false;
            this.store.scroll = true;
        }

        if (!this.checkStop(payload) && payload.action === ARTIST_LIST_SCROLL) {
            this.store.more = false;
            this.store.scroll = true;
        }

        if (this.checkStop(payload)) {
            this.store.complete = true;
            this.store.more = false;

        }

        this.updateGalleryData(payload.galleryData);
    }
}
