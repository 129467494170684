/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 31.7.17
 */

import { APPOINTMENT_CHANGE } from "../../constants";

import AppDispatcher from "../../dispatcher";

export function innerAptChange(aptId, patch) {
    AppDispatcher.dispatch({
        type: APPOINTMENT_CHANGE,
        payload: {
            id: aptId,
            patch: patch
        }
    });
}