/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 06.02.2019
 * Time: 7.53
 */

import React from "react";
import { Link } from "react-router";

export default function NonProDisclaimer() {

    return <div className={"loader-msg-wrapper"}>
        <div className={"loader-msg-wrapper--item"}>
            This page’s accessible to registered beauty Pros only.
            <br/>
            <Link to="/list-your-business">List your business</Link>&nbsp;to become one
            <br/>
        </div>
    </div>;

}
