/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 17.22
 *
 * @flow
 *
 */

import { addressNormalize1 } from "./address";
import type { TRawSalon1, TRawSalonLegacy, TRawSalonLegacy2 } from "./raw-types";
import type { TSalon } from "../types";
import { TYPE_SALON } from "../frizo-shared-constants";

export function salonNormalizer1(rawSalon: TRawSalon1): TSalon {

    return {
        id: rawSalon.id,
        entityType: TYPE_SALON,
        title: rawSalon.name,
        phone: rawSalon.phone,
        url: rawSalon.url,
        addresses: rawSalon.address ? rawSalon.address.map(addressNormalize1) : [],
        type: rawSalon.businessType,
        instagramUsername: rawSalon.instagramUsername,


        servicePaused: rawSalon.servicePaused,
        bookable: rawSalon.isBookable,
        public: rawSalon.isPublic

    };
}

export function salonNormalizerLegacy(rawSalon: TRawSalonLegacy): TSalon {
    console.debug("salonNormalizerLegacy", rawSalon);

    return {
        id: rawSalon.salonID,
        entityType: TYPE_SALON,
        title: rawSalon.salonName,
        url: rawSalon.salonUrl,
        addresses: rawSalon.addresses ? rawSalon.addresses.map(addressNormalize1) : [],
        type: rawSalon.salonType,

        imageUrl: rawSalon.salonImageUrl,

        servicePaused: rawSalon.servicePaused
    };
}

export function salonNormalizer2(rawSalon: TRawSalonLegacy2): TSalon {

    return {
        entityType: TYPE_SALON,
        id: rawSalon.salonId,
        name: rawSalon.salonName,
        title: rawSalon.salonName,
        salonUrl: rawSalon.salonUrl,
        lat: rawSalon.lat,
        lon: rawSalon.lon
    };
}
