/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 21.8.17
 */

import { checkProfileCompletion } from "./../xhr/profile-completion-xhr";

import AppDispatcher from "./../dispatcher";

import { PROFILE_COMPLETENESS_UPDATE } from "./../constants";


export function loadProfileCompletenessInfo() {

    checkProfileCompletion().then(response => {
        AppDispatcher.dispatch({
            type: PROFILE_COMPLETENESS_UPDATE,
            payload: response
        });
    });

}