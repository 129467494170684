/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.7.17
 */

import ApiClient from "../../service/api-client";

export function xhrGetSuggestedTax(addressId) {

    let url = ApiClient.getRouting().generate("Q116_get_suggested_tax", {
        addressId
    });

    return ApiClient.get(url, null, {
        enableDefaultNotifications: false
    }).then(answer => answer[0]);
}
