/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 06.03.2018
 * Time: 10:24
 */

import notificationSystem from "./notification-system";
import informerConfig from "../informer-config";

let usedClientId = [];

export default function aptInformer() {

    let bookIntervals = ["Just now", "seconds ago", "minutes ago"];
    let clients = informerConfig.clients;
    let services = informerConfig.services;
    let addresses = informerConfig.addresses;

    let service = services[Math.floor(Math.random() * Object.keys(services).length)];
    let address = addresses[Math.floor(Math.random() * Object.keys(addresses).length)];
    let bookInterval = Math.floor(Math.random() * 3);

    let timesRandom = "";
    if (bookInterval !== 0) {
        timesRandom = bookInterval === 1 ? Math.floor(Math.random() * 55) + 5 : Math.floor(Math.random() * 7) + 1;
    }
    timesRandom = timesRandom + " " + bookIntervals[bookInterval];

    if (usedClientId.length === Object.keys(clients).length) {
        usedClientId = [];
    }

    let client = clients[Math.floor(Math.random() * Object.keys(clients).length)];


    while ((client.gender !== service.gender) || (usedClientId[client.identifier] !== undefined)) {
        client = clients[Math.floor(Math.random() * Object.keys(clients).length)];
    }

    usedClientId.push(client.identifier);


    let message = `<p class="informer-title">${timesRandom}</p>` +
        service.service + " booked by " + client.name + " " + client.surname + "<br/>in " +
        address.address;

    return (
        notificationSystem.logSuccess(message, { position: "left bottom", delay: 4000 })
    );
}
