/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.1.11
 * Time: 11:46
 */

import React, {Component} from 'react';
import {GENDER_NOT_SHARED} from '../../frizo-shared-constants';
import ClientPhoneInput from '../../components/book-apt/client-phone-input';
import ClientNameInput from '../../components/book-apt/client-name-input';


export default class FindClientForm extends Component {

    constructor(props) {
        super(props);
        this.createGender = this.createGender.bind(this);
    }

    createGender() {
        return this.props.createGender(this.props.gender)
    }

    render() {

        return <div className="find-client-form">

            <ClientPhoneInput
                itemClassName = {"find-client-list--item"}
                list={this.props.list}
                change={this.props.change}
                found = {this.props.found}
                phone={this.props.phone}
                force = {this.props.forceHideHint}
                showViolations={this.props.showViolations}
                violations={this.props.violations}
                readOnly={this.props.lockClientSearchForm}
            />

            <ClientNameInput
                itemClassName = {"find-client-list--item"}
                list={this.props.list}
                change={this.props.change}
                found = {this.props.found}
                force = {this.props.forceHideHint}
                name={"firstName"}
                value={this.props.firstName}
                placeholder={"First name"}
                showViolations={this.props.showViolations}
                violations={this.props.violations}
                readOnly={this.props.lockClientSearchForm}
            />

            <ClientNameInput
                itemClassName = {"find-client-list--item"}
                list={this.props.list}
                change={this.props.change}
                name={"lastName"}
                found = {this.props.found}
                force = {this.props.forceHideHint}
                value={this.props.lastName}
                placeholder={"Last name"}
                showViolations={this.props.showViolations}
                violations={this.props.violations}
                readOnly={this.props.lockClientSearchForm}
            />

            { this.props.gender !== GENDER_NOT_SHARED && <input className="form-control" value={this.createGender(true)}
                                                                disabled={true}
                                                                type="text"/>}
        </div>
    }
}