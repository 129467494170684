/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.6.17
 * Time: 5.47
 */

import React from 'react';
import {Button, Badge} from 'react-bootstrap';


export default function PaymentSourceAction(props) {
    if (props.clickable) {
        return <Button
            className={props.title.indexOf('Remove') ? "btn-sm fr-btn fr-btn-table" : "fr-link-delete fr-btn-table btn-link"}
            onClick={props.onClick}>
            {props.title}
        </Button>;
    }
    else {
        return <Badge className="profile-status__default">
            {props.title}
        </Badge>;
    }
}