/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 14.20
 *
 */

import { singleLookNormalizer } from "./single-look-normalizer";
import { artistNormalizerLegacy } from "../../user-derived-forms/artist";
import { salonNormalizerLegacy } from "../../salon";
import type { TRawArtistLegacyBase, TRawLook, TRawSalonLegacy } from "../../raw-types";

type TRawLooksGroupedByArtist = {
    artist: TRawArtistLegacyBase,
    galleryItems: Array<TRawLook>
};

type TRawLooksGroupedByArtistBySalon = {
    salon: TRawSalonLegacy,
    artists: Array<TRawLooksGroupedByArtist>
};

export function* looksGroupedBySalonByArtist(rawItems: Array<TRawLooksGroupedByArtistBySalon>) {

    let items = [];

    for (let i1 = 0; i1 < rawItems.length; i1++) {
        let { salon: rawSalon, artists: rawArtists } = rawItems[i1];
        let salon = salonNormalizerLegacy(rawSalon);

        let artists = [];
        for (let i2 = 0; i2 < rawArtists.length; i2++) {
            let { artist: rawArtist, galleryItems: rawGalleryItems } = rawArtists[i2];

            let artist = artistNormalizerLegacy(rawArtist);

            let galleryItems = [];

            for (let i3 = 0; i3 < rawGalleryItems.length; i3++) {

                let rawLook = rawGalleryItems[i3];

                let look = singleLookNormalizer(rawLook, salon, artist);

                yield {
                    structure: [salon, artist],
                    element: look
                };

                galleryItems.push(look);
            }

            artists.push({ artist, galleryItems });
        }

        items.push({ salon, artists });
    }

    return items;

}
