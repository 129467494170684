/**
 * Created by Eleonora on 01-Feb-17.
 */

import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import stores from '../../stores';
import { signDocument } from '../../ac/legal'
import gradientFill from './../../helper/gradient-fill';

export default  class LegalDocsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDoc: stores.docs.store.docs[0]
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        stores.docs.addChangeListener(this.handleChange);
    }

    componentWillUnmount() {
        stores.docs.removeChangeListener(this.handleChange);
    }

    handleChange() {
        this.setState({
            currentDoc: stores.docs.store.docs.length > 0 ? stores.docs.store.docs[0] : null
        });
    }

    signDoc() {
        signDocument(this.state.currentDoc.legalId);
    }

    render() {
        if (!this.state.currentDoc) {
            return null;
        } else {
            return (
                <div>
                    <Modal.Header style={{'background': gradientFill()}}>
                        <h2 className="legal-header">Notice of Terms & Privacy Updates</h2>
                        <Button bsClass="btn btn-default btn-proceed" onClick={this.signDoc.bind(this)}>Proceed</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{__html: this.state.currentDoc.content}}></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" onClick={this.signDoc.bind(this)}>Proceed</Button>
                    </Modal.Footer>
                </div>
            );
        }
    }
}

