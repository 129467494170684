/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.6.17
 * Time: 14.18
 */

import React from 'react';

export default function PaymentSource(props) {
    //console.log(props);

    let now = (new Date()).valueOf();
    let expTimestamp = Date.UTC(props.exp_year, props.exp_month);

    let expired = now >= expTimestamp;

    return <option value={props.id} className={"fr-text-overflow"}>
        { props.brand}
        &nbsp; **** &nbsp; **** &nbsp; **** &nbsp;
        { props.last4}
        &nbsp;
        { expired && "(expired)" }
    </option>;


}

PaymentSource.defaultProps = {
};
