/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.10.17
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_TO_LOW = "VALUE_IS_TO_LOW";

export default class GreaterThanOrEqual extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Must be equal or greater than {value}";

        this.value = null;

        this.setOptions(options);
    }

    static getName() {
        return "is-greater-than-or-equal";
    }

    getRequiredOptions() {
        return ["value"];
    }
}
