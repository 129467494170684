/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 24.7.17
 */

import {
    PAYMENT_APPROVAL_CANCELED,
    PAYMENT_APPROVAL_CHANGE_PAYMENT_SOURCE,
    PAYMENT_APPROVAL_DISCOUNT_SOLUTION_LOADED,
    PAYMENT_APPROVAL_FAILED,
    PAYMENT_APPROVAL_MODAL,
    PAYMENT_APPROVAL_UPDATE_TIP
} from "./../constants";
import { ADD_NEW_OPTION } from "./../frizo-shared-constants";
import AppDispatcher from "../dispatcher";
import { modalChange, modalHide } from "./modals";
import { personalPaymentOrderStore } from "./../stores";
import { xhrAcceptOrder } from "./../xhr/payments/personal/accept-order-xhr";
import { xhrDeclineOrder } from "./../xhr/payments/personal/decline-order-xhr";
import { loadPendingPaymentOrders } from "./../ac/personal-payment-orders-ac";
import notificationSystem from "./../service/notification-system";
import { openAddPersonalCardModal } from "./payments/add-new-card-personal-actions";
import { xhrGetDiscountSolution } from "../xhr/payments/discount/get-discount-solution-xhr";
import { updateSessionData } from "../auth";
import { printCentToUsd } from "../helper/money-converter";


export function openOrderApprovePopup(orderId) {

    let paymentOrder = personalPaymentOrderStore.getStore().orders.find(element => {
        return element.id === orderId;
    });

    console.log("paymentOrder", paymentOrder);
    xhrGetDiscountSolution(paymentOrder.id).then(solution => {
        AppDispatcher.dispatch({
            type: PAYMENT_APPROVAL_DISCOUNT_SOLUTION_LOADED,
            payload: solution
        });
    });

    modalChange(PAYMENT_APPROVAL_MODAL, {
        paymentOrder
    });

}


export function orderDecline(orderId, total) {

    /*global confirm */
    /*eslint no-restricted-globals: 0 */

    let message = `You're about to decline payment of <strong>$${printCentToUsd(total)}.</strong> `;
    notificationSystem.confirm(message).then(result => {
            if (result.buttonClicked !== "ok") {
                AppDispatcher.dispatch({
                    type: PAYMENT_APPROVAL_CANCELED
                });
                return false;
            }

            xhrDeclineOrder(orderId).then(response => {

                loadPendingPaymentOrders();

                modalHide();

            }, response => {

                let payload = {};
                if (response.errors) {
                    payload = response.errors;
                }
                else {
                    payload = {
                        general: response.message
                    };
                }

                AppDispatcher.dispatch({
                    type: PAYMENT_APPROVAL_FAILED,
                    payload
                });

            });
        }
    );
}

export function orderApprove(orderId, paymentSourceId, tipValue, totalValue, discountSolution) {


    /*global confirm */
    /*eslint no-restricted-globals: 0 */

    let message = `You're about to pay <stong>$${printCentToUsd(totalValue - discountSolution.reservedAmount)}.</stong></br> You will get respective receipt</br>by&nbsp;e-mail.`;
    notificationSystem.confirm(message).then(result => {

        if (result.buttonClicked !== "ok") {
            let payload = {};
            AppDispatcher.dispatch({
                type: PAYMENT_APPROVAL_CANCELED,
                payload
            });
            return Promise.reject(result);
        }

        return result;

    }).then(result => {

        let promise;
        if (paymentSourceId === ADD_NEW_OPTION) {
            promise = openAddPersonalCardModal();
        }
        else {
            promise = new Promise(success => success(paymentSourceId));
        }

        promise.then(paymentSource => {
            if (typeof paymentSource === "object") paymentSource = paymentSource.token.card.id;

            xhrAcceptOrder(orderId, paymentSource, tipValue, totalValue, discountSolution).then(response => {

                loadPendingPaymentOrders();

                modalHide();
                updateSessionData();

            }, response => {


                let payload = {};
                if (response.errors) {
                    payload = response.errors;
                }
                else {
                    payload = {
                        general: response.message
                    };
                }

                AppDispatcher.dispatch({
                    type: PAYMENT_APPROVAL_FAILED,
                    payload
                });

            });
        });
    });

}

export function updateTip({ percent, customPercent, customSelected }) {
    AppDispatcher.dispatch({
        type: PAYMENT_APPROVAL_UPDATE_TIP,
        payload: { percent, customPercent, customSelected }
    });
}

export function changePaymentSource(paymentSourceId) {

    AppDispatcher.dispatch({
        type: PAYMENT_APPROVAL_CHANGE_PAYMENT_SOURCE,
        payload: {
            paymentSourceId
        }
    });
}