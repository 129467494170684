/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.12.17
 * Time: 15.19
 *
 * @flow
 */

import {
    HTTP_DELETE,
    HTTP_GET,
    HTTP_POST,
    HTTP_PUT,
    MODE_ONE_BY_ONE,
    MODE_SIMULTANEOUS,
    MODE_TRANSACTIONAL
} from "../../frizo-shared-constants";
import { Request } from "./request";
import hashUrlencode from "../hash-urlencode";
import buildUrl from "./url-builder";
import { RequestBuilder } from "./request-builder";
import { wrap } from "./request-wrapper";
import { wrapRelayResponse } from "./relay/relay-response-handler";
import { makeRelayRequest } from "./relay/make-relay-request";

type TMode = MODE_ONE_BY_ONE | MODE_SIMULTANEOUS | MODE_TRANSACTIONAL;

export class RequestSetBuilder {

    processor: Function;

    requestSet: Array<Request>;
    mode: TMode;

    /**
     * @private
     */
    _skipAuth: boolean;
    _suppressNotifications: boolean | null;
    running: boolean;
    _alwaysMultiple: boolean;

    promise: Promise<*>;

    successCallback: Function;
    failCallback: Function;
    then: Function;

    constructor(processor: Function) {
        this.processor = processor;
        this.running = false;

        this.requestSet = [];
        this.mode = MODE_ONE_BY_ONE;
        this._skipAuth = false;
        this._suppressNotifications = null;
        this._alwaysMultiple = false;

        this.promise = new Promise((success, fail) => {
            this.successCallback = success;
            this.failCallback = fail;
        });

        this.then = (success, fail) => {
            this.run();
            return this.promise.then(success, fail);
        };

    }

    alwaysMultiple(value: boolean = true): RequestSetBuilder {
        this._alwaysMultiple = value;

        return this;
    }

    isAlwaysMultiple() {
        return this._alwaysMultiple;
    }

    checkIsRunning(): void {
        if (this.running) throw new Error("Request is already running, to late to modify");
    }

    setMode(mode: TMode): RequestSetBuilder {
        this.checkIsRunning();
        this.mode = mode;

        return this;
    }

    getMode(): string {
        return this.mode;
    }

    skipAuth(skip: boolean = true): RequestSetBuilder {
        this.checkIsRunning();
        this._skipAuth = skip;

        return this;
    }

    isAuthSkipped(): boolean {
        return this._skipAuth;
    }

    suppressNotifications(suppress: boolean = true): RequestSetBuilder {
        this.checkIsRunning();
        this._suppressNotifications = suppress;

        return this;
    }

    isNotificationsSuppressed(): boolean | null {
        return this._suppressNotifications;
    }

    setFailNotificationMessage(message: string): RequestSetBuilder {
        this.checkIsRunning();

        return this;
    }


    get(url: string, params: {} | null = null, requestId: string = "none"): RequestBuilder {
        return this.request(HTTP_GET, url, params, requestId);
    }

    post(url: string, params: {} | null = null, requestId: string = "none"): RequestBuilder {
        return this.request(HTTP_POST, url, params, requestId);
    }

    put(url: string, params: {} | null = null, requestId: string = "none"): RequestBuilder {
        return this.request(HTTP_PUT, url, params, requestId);
    }

    delete(url: string, params: {} | null = null, requestId: string = "none"): RequestBuilder {
        return this.request(HTTP_DELETE, url, params, requestId);
    }

    /**
     * @protected
     *
     * @param method
     * @param routeOrUrl
     * @param getParams
     * @param requestId
     * @returns {null|RequestBuilder}
     */
    request(method: string, routeOrUrl: string, getParams: {} | null = null, requestId: string = "none"): RequestBuilder {

        this.checkIsRunning();

        let url;
        if (routeOrUrl[0] === "@") {
            let route = routeOrUrl.slice(1);

            url = buildUrl(route, getParams);
        }
        else {
            if (getParams) {
                let encodedData = hashUrlencode(getParams);
                if (encodedData.length > 0) {
                    if (~routeOrUrl.indexOf("?")) {
                        url = routeOrUrl + "&" + encodedData;
                    }
                    else {
                        url = routeOrUrl + "?" + encodedData;
                    }
                }
                else {
                    url = routeOrUrl;
                }
            }
            else {
                url = routeOrUrl;
            }
        }

        // if (!~url.indexOf('http'))  {
        //     url = config.base_url + url;
        // }


        return new RequestBuilder(this, url, method, requestId);
    }

    add(request: Request): RequestSetBuilder {
        this.checkIsRunning();

        if (typeof this._suppressNotifications === "boolean") {
            request.suppressNotifications(this._suppressNotifications);
        }

        this.requestSet.push(request);

        return this;
    }

    getRequestSet(): Array<Request> {
        return this.requestSet;
    }

    runRelay() {
        makeRelayRequest(this).then((carrier: Request) => {

            return Promise.resolve(carrier);

        }, (carrier: Request) => {

            let message = "Processor promise rejected (carrier)";
            console.error(message, carrier);

            return Promise.resolve(carrier);

        }).then((carrier: Request) => {

            console.log("carrier", carrier);

            let wrapper = wrapRelayResponse(carrier, this.requestSet);

            if (carrier.isSuccess()) {
                this.successCallback(wrapper);

            }
            else {
                this.failCallback(wrapper);
            }

            return Promise.resolve(carrier);
        });
    }


    runOneByOne() {

        let promises = this.requestSet.map((request: Request) => {
            return new Promise((success, fail) => {
                request.events.succeeded.add(success);
                request.events.failed.add(fail);
            });
        });

        Promise.all(promises).then(requestSet => {
            let wrapped = wrap(requestSet, this.isAlwaysMultiple());

            let success = true;
            wrapped.forEach((request: Request) => {
                if (!request.isSuccess()) {
                    success = false;

                    return false;
                }
            });

            if (success) {
                this.successCallback(wrapped);
            }
            else {
                this.failCallback(wrapped);
            }

            return Promise.resolve(wrapped);

        }, requestSet => {

            //should be never called
            let message = "Processor promise rejected";
            console.error(message, requestSet);
            throw new Error(message);
        });

    }

    run(): RequestSetBuilder {
        if (this.running) return this;

        this.running = true;

        if (~[MODE_SIMULTANEOUS, MODE_TRANSACTIONAL].indexOf(this.getMode())) {
            this.runRelay();

        }
        else {
            this.runOneByOne();
            this.processor(this);
        }

        return this;
    }
}
