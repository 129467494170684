/**
 * Created by fliak on 17.2.17.
 */

import BaseSyncer from "./base-syncer";
import { APT_STATUS_REMOVED, MODE_CLIENT, MODE_PROFESSIONAL } from "./../frizo-shared-constants";
import moment from "moment";
import { xhr } from "../service/api-client-2";
import { MODE_TRANSACTIONAL } from "../frizo-shared-constants";
import { Request } from "../service/api-client-2/request";

export default class BookAptSyncer extends BaseSyncer {

    constructor(artistStore, servicesStore) {
        super();

        this.artistStore = artistStore;
        this.servicesStore = servicesStore;

        this.appointments = undefined;
        this.clientData = undefined;
        this.mode = undefined;

        this.persistSuccessCallbackSet = [];
        this.persistFailCallbackSet = [];
    }

    putAppointments(appointments) {
        this.appointments = appointments;
    }

    putClientData(clientData) {
        this.clientData = clientData;
    }

    setMode(mode) {
        this.mode = mode;
    }

    /**
     *
     * @param action CRUD
     */
    getUrl(action) {

        let mode = this.mode;

        let map = {
            "CREATE": {
                [MODE_CLIENT]: "@Q2-11-book-apt-post-cli",
                [MODE_PROFESSIONAL]: "@Q2-15-book-apt-post-pro"
            },
            "UPDATE": {
                [MODE_CLIENT]: "@Q2-12-book-apt-put-cli",
                [MODE_PROFESSIONAL]: "@Q2-16-book-apt-put-pro"
            },
            "DELETE": {
                [MODE_CLIENT]: "@Q2-13-book-apt-delete-cli",
                [MODE_PROFESSIONAL]: "@Q2-17-book-apt-delete-pro"
            }
        };

        return map[action][mode];
    }

    persist() {

        if (this.appointments === undefined || this.clientData === undefined || this.mode === undefined) {
            console.warn("Persist skipped because not data enough", this);
            return new Promise((resolve, reject) => {
                reject("Not data enough");
            });
        }

        let mode = this.mode;
        let clientData = this.clientData;

        let builder = xhr()
            .suppressNotifications()
            .setMode(MODE_TRANSACTIONAL)
        ;

        this.appointments.forEach(apt => {
            let isNew = apt.dirty;

            let appointmentData = this.normalizeAppointment(apt, mode);

            if (mode === MODE_PROFESSIONAL) {
                appointmentData.client = clientData;
            }


            if (apt.status === APT_STATUS_REMOVED) {
                if (isNew) return; //skip removed dirty apts

                builder.delete(this.getUrl("DELETE"), {
                    id: apt.id
                }).end();
            }
            else {
                if (isNew) {
                    builder.post(this.getUrl("CREATE"))
                    .json(appointmentData).end();
                }
                else {
                    builder.put(this.getUrl("UPDATE"), {
                        id: apt.id
                    }).json(appointmentData).end();
                }
            }
        });


        return builder.then(requestSet => {

            let combinedData = requestSet.toHash();

            this.persistSuccessCallbackSet.forEach(fn => {
                if (typeof fn === "function") {
                    fn(combinedData);
                }
            });

        }, requestSet => {

            let report = requestSet.toHash((request: Request) => {
                let response = request.response;

                if (!request.isSuccess()) {
                    if (response.message) {
                        return {
                            general: response.message
                        };
                    }
                    else if (response.errors) {
                        return response.errors;
                    }
                }

                return request.response;
            });

            this.persistFailCallbackSet.forEach(fn => {
                if (typeof fn === "function") {
                    fn(report);
                }
            });
        });
    }

    addPersistSuccessCallback(callback) {
        this.persistSuccessCallbackSet.push(callback);
    }

    addPersistFailCallback(callback) {
        this.persistFailCallbackSet.push(callback);
    }

    getStartTimestamp(date, seconds, timezone) {
        return moment.tz(date, timezone)
        .startOf("day")
        .add(seconds, "seconds")
        .unix();

    }

    getAddressTimezone(addressId) {
        let address = this.artistStore.getAddressById(addressId);
        if (!address) {
            console.warn("Address id ", addressId);
            throw new Error("Address is missing");
        }
        return address.timezone;
    }

    /**
     * @protected
     *
     * @param apt
     * @param mode
     * @returns {{addressId: (*|number), artistId: (*|number|null), serviceId: (*|number|null), date: string, startTime: number, processingTime: *, durationTime}}
     */
    normalizeAppointment(apt, mode = MODE_PROFESSIONAL) {

        let data = {};

        data.startTimestamp = this.getStartTimestamp(apt.date, apt.startTime, this.getAddressTimezone(apt.addressId));
        data.artistId = apt.artistId;
        data.image = apt.imageId || 0;
        data.addressId = apt.addressId;
        data.serviceId = apt.serviceId;

        if (mode === MODE_PROFESSIONAL) {
            data.processingTime = apt.processingTime;
        }

        return data;
    }
}
