/**
 * Created by fliak on 23.2.17.
 */

import sprintf from "./../../helper/sprintf";

export default class BaseConstraint {

    constructor(options = null) {
        this.breakOnError1 = false;
        this.breakOnError2 = false;

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        throw new Error("getName method is abstract and should be defined in child class");
    }

    static getValidatorClassName() {
        throw new Error("getValidatorClassName method is abstract and should be defined in child class");
    }

    setOptions(options) {
        if (this.options === undefined) this.options = {};

        let givenOptions = [];

        let extraOptions = [];

        if (typeof options === "object") {

            let validOptions = Object.getOwnPropertyNames(this);

            for (let optionName in options) {
                if (validOptions.indexOf(optionName) === -1) {
                    extraOptions.push(optionName);

                    continue;
                }

                givenOptions.push(optionName);

            }
        }
        else {
            let defaultOption = this.getDefaultOption();
            if (!defaultOption) {
                throw new Error(sprintf("Default option is not set for constraint '%s'", this.constructor.getName()));
            }

            this[defaultOption] = options;
            givenOptions.push(defaultOption);
        }

        let requiredOptions = this.getRequiredOptions();
        let missingOptions = requiredOptions.filter(option => {
            return givenOptions.indexOf(option) === -1;
        });

        if (missingOptions.length > 0) {
            throw new Error(sprintf("Missing options '%s' for constraint '%s'",
                missingOptions.join(", "),
                this.constructor.getName()));
        }

        if (extraOptions.length > 0) {
            throw new Error(sprintf("Extra options '%s' specified for constraint '%s'",
                extraOptions.join(", "),
                this.constructor.getName()));
        }

        Object.assign(this, options);

    }

    getRequiredOptions() {
        return [];
    }

    getDefaultOption() {
        return "value";
    }
}
