/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.6.17
 * Time: 13.53
 */

import BaseStore from "./../../base-store";
import {
    PROFILE_PERSONAL_MYCARDS_ERROR,
    PROFILE_PERSONAL_MYCARDS_LOADED,
    PROFILE_PERSONAL_PAYMENTS_LOADED
} from "../../../constants";

export default class MyCardsStore extends BaseStore {

    constructor(...args) {
        super(...args);
        this.store = {
            cards: [],
            cardsCount: 0,
            violations: {},
            showViolations: false,
            personalCharges: {
                sources: []
            }
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case PROFILE_PERSONAL_MYCARDS_LOADED:
                    this.store.cards = payload.sources ? payload.sources.data :[];
                    this.store.cardsCount = payload.sources ? payload.sources.total_count : 0;
                    this.store.defaultCardId = payload.default_source;

                    this.store.violations = {};
                    this.store.showViolations = false;


                    break;

                case PROFILE_PERSONAL_MYCARDS_ERROR:
                    this.store.violations = payload;
                    this.store.showViolations = true;

                    break;

                case PROFILE_PERSONAL_PAYMENTS_LOADED:
                    this.store.personalCharges = {
                        sources: payload.data,
                        count: payload.count
                    };
                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }
}
