/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 28.05.2017
 * Time: 11:32
 */

import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import WhatFilterServices from './../elements/what-filter-services';
import classNames from 'classnames/bind';


export default class DashboardFilterWhat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNoPopularHidden: true,
            isServiceAll: true,
        };
        this.changeServicesCheckbox = this.changeServicesCheckbox.bind(this);
        this.servicesShow = this.servicesShow.bind(this);
        this.closeWhatTabHandler = this.closeWhatTabHandler.bind(this);
    }

    servicesShow() {
        //  $('.services-filter .no-popular').toggleClass('hidden');
        this.setState({
            isNoPopularHidden: !this.state.isNoPopularHidden,
            isServiceAll: !this.state.isServiceAll
        });
        //  $('.service_all').toggleClass('visible');
    }

    changeServicesCheckbox(e) {
        this.props.changeServicesCheckbox(e)
    }

    filterByID(obj) {
        if ('categoryID' in obj && obj.categoryID === parseFloat(this.props.selectValue)) {
            return true;
        } else {
            return false;
        }
    }

    closeWhatTabHandler() {
        this.props.closeWhatTab(true);
    }

    render() {
        let categoryArr = this.props.data.filter(this.filterByID.bind(this)),
            services = [],
            attrH = [],
            that = this;
        if (categoryArr.length > 0) {
            let serArray = categoryArr[0].services;
            let num = 1;
            serArray.forEach(function (item, i) {
                if (item.popular === 0) {
                    item.popular = 1000 + num;
                    num++;
                }
            });
            let servicesSort = serArray.sort(
                function compareNumeric(a, b) {
                    if (a.popular > b.popular) return 1;
                    if (a.popular < b.popular) return -1;
                    return 0;
                }
            );
            let serviceMap = function (func) {
                servicesSort.map(function (data, i) {
                    return services.push(
                        <WhatFilterServices
                            changServicesCheckbox={func}
                            data={data}
                            key={i + data.serviceName}
                            isNoPopularHidden={that.state.isNoPopularHidden}/>
                    )
                })
            };

            new serviceMap(this.changeServicesCheckbox);

            let serviceAllClasses = classNames({
                'btn-link': true,
                'dashboard-what__service_all': true,
                'visible': this.state.isServiceAll
            });

            if (attrH.length === 0) {
                attrH.push(
                    <div key={1}>
                        <div className="dashboard-what__close-layer ">
                            <Button className="close" onClick={this.closeWhatTabHandler}>
                                <span>×</span>
                            </Button>
                        </div>
                        <span className="services_head" style={{
                            verticalAlign: "middle",
                            "margin": "10px 0 5px",
                            "overflow": "hidden",
                            "display": "inline-block"
                        }}>Services</span>
                        <div className="toggle_popular" style={{
                            position: "relative",
                            overflow: "hidden",
                            width: "100px",
                            display: "inline-block",
                            verticalAlign: "middle"
                        }}>
                            <button className={serviceAllClasses} onClick={this.servicesShow}>Show all</button>
                            <button className="btn-link" onClick={this.servicesShow}>Show popular</button>
                        </div>
                    </div>
                )
            }
        }

        return ( attrH.length === 0 && services.length === 0) ? null : (
            <div className="dashboard-filter-what__attrs services-filter clearfix">
                <div>
                    <div className="col-md-12">
                        {attrH}
                    </div>
                    <div className="col-md-12 dashboard-what__services">
                        {services}
                    </div>
                </div>
            </div>
        )
    }
}


DashboardFilterWhat.defaultProps = {
     data: []
};
