/**
 * Created by fliak on 22.2.17.
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_NOT_ALPHA = "VALUE_IS_NOT_ALPHA";

export default class IsAlpha extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Must be alpha";

        this.allowSpaces = true;

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        return "is-alpha";
    }
}
