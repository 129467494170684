/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 02.05.2017
 * Time: 15:04
 */

import ApiClient from "../service/api-client";

import { appointmentNormalize } from "./../normal-form/appointment";
import { CUSTOM_HEADER_U6_GROUPS } from "./../frizo-shared-constants";

export const xhrLoadPersonalAppointments = function(params) {
    let newApiClientURL = ApiClient.getRouting().generate("Q102_client_appointment_tabs", params);
    return ApiClient.get(newApiClientURL, params, {
        headers: {
            [CUSTOM_HEADER_U6_GROUPS]: ["basic", "payment-order"]
        }
    })
    .then((answer) => {
        let [response] = answer;
        return Promise.resolve({
            requestData: response.data.map(appointmentNormalize),
            requestTotalCount: response.count,
            requestParams: params
        });
    });
};
