/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 25.7.17
 */

import ApiClient from "../service/api-client";
import { CUSTOM_HEADER_U6_GROUPS } from "./../frizo-shared-constants";

import { paymentOrderNormalize } from "./../normal-form/payment-order";

export const MODE_CLIENT = "cli";
export const MODE_PRO = "pro";

export function xhrGetPaymentOrders(mode = MODE_CLIENT) {

    let url = ApiClient.getRouting().generate("Q118_get_pending_payment_orders", { mode });

    return ApiClient.get(url, null, {
        headers: {
            [CUSTOM_HEADER_U6_GROUPS]: "payment-order"
        }
    }).then(answer => {
        let [response] = answer;

        if (response.count === 0) return [];

        return response.data.map(paymentOrderNormalize);
    });
}
