/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 30.3.17
 * Time: 7.51
 */

import React, {Component} from 'react';
import OnOffSwitcher from './../../../../components/forms/on-off-switcher';
import printDayName from './../../../../helper/print-day-name';
import Time from './../../../helper-components/time-component';
import {Row, Col} from 'react-bootstrap'
import {mapHashToArray} from './../../../../helper/map-hash-to-array';
import PropTypes from 'prop-types';
import {WH_START_TIME, WH_END_TIME} from './../../../../frizo-shared-constants';
import DropdownWrapper from './../../../../elements/dropdown-wrapper';

import ReactBootstrapSlider from 'react-bootstrap-slider';


export default class ScheduleDayRow extends Component {

    constructor(props) {
        super(props);

        this.dayOffSwitch = this.dayOffSwitch.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
        this.onStop = this.onStop.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.getAddressSelector = this.getAddressSelector.bind(this);

        this.state = {
            range: [props.from, props.to]
        };

    }

    componentWillReceiveProps(props) {
        this.setState({
            range: [props.from, props.to]
        });
    }

    dayOffSwitch(value) {
        this.props.onChange({
            dayOff: value
        });
        if (!value) {
            this.props.onChange({
                from: WH_START_TIME,
                to: WH_END_TIME
            });
        }
    }

    setMinimumRange(low, high) {

        if (high === low) {

            if (low === 0) {
                high = low + 15 * 60;

            } else {
                low = high - 15 * 60;
            }
        }

        return [low, high];
    }


    onTimeChange(e) {
        let range = this.setMinimumRange(e.target.value[0], e.target.value[1]);
        this.props.onChange({from: range[0], to: range[1]});
        this.setState({
            range: range
        });
    }

    onStop() {
        this.props.onChange({
            from: this.state.range[0],
            to: this.state.range[1]
        });
    }


    onAddressChange(id) {
        this.props.onChange({
            addressId: id
        });

    }

    getAddressSelector() {

        let list = mapHashToArray(this.props.addresses, (address, id) => {
            return {id: Number(id), name: address};
        });

        if (list.length > 1) {

            return <div className="col-md-12 col-xs-12">
                <div className="row fr-wh-address-container">
                    <div className="col-md-12">
                        <label htmlFor="wh_form_Sunday_address_address" className="required">Address</label>
                        <DropdownWrapper
                            selected={this.props.addressId}
                            list={list}
                            onChange={this.onAddressChange}
                            showPlaceholder={false}
                        />
                    </div>
                </div>
            </div>;
        }

        return null;

    }

    render() {
        let slider = null, timeSpan;
        if (!this.props.dayOff && !this.props.readOnly) {
            slider = <div>
                <ReactBootstrapSlider
                    value={this.state.range}
                    change={this.onTimeChange}
                    slideStop={this.onStop}
                    step={15 * 60}
                    max={60 * 60 * 24}
                    min={0}
                    tooltip="none"
                />
            </div>;
        }
        if (!this.props.dayOff) {
            timeSpan = <span className="fr-wh-span"><span className="fr-time-value">
                                         <Time className="fr-wh-span" timeFromStartPoint={this.state.range[0]}/>
                                     </span> — <span className="fr-time-value">
                                         <Time className="fr-wh-span" timeFromStartPoint={this.state.range[1]}/>
                                     </span></span>;
        }
        if (this.props.dayOff && this.props.readOnly) {
            timeSpan = <span className="fr-wh-span">Day Off</span>;
        }

        return (<Row>
                <Col xs={12} md={6}
                     className={this.props.readOnly ? "fr-wh-time-container-readOnly" : "fr-wh-time-container"}>
                    <div>
                        <Col xs={12}>
                            <Col xs={6}>
                                <label
                                    className={!this.props.dayOff ? "fr-wh-day" : "fr-wh-dayoff"}>{printDayName(this.props.day)}</label>
                            </Col>
                            <div className="fr-fr fr-mb">
                                {timeSpan}
                                {!this.props.readOnly &&
                                <OnOffSwitcher className="fr-pl" invert={true} value={this.props.dayOff}
                                               onChange={this.dayOffSwitch}/>}
                            </div>
                            {slider}
                        </Col>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    {this.getAddressSelector()}
                </Col>

            </Row>
        );

    }
}

ScheduleDayRow.propTypes = {
    onChange: PropTypes.func,
    addresses: PropTypes.object
};

ScheduleDayRow.defaultProps = {
    onChange: () => {
    },
    addresses: {}
};