/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.18.7
 * Time: 10:36
 */

import BaseStore from "./base-store";

import {
    LOAD_SESSION_SUCCESS,
    LOAD_UNSEEN_APPOINTMENTS_LIST_SUCCESS,
    REMOVE_UNSEEN_APPOINTMENT_NOTIFICATION,
    REMOVE_UNSEEN_APPOINTMENT_SET_NOTIFICATION,
    TOGGLE_UNSEEN_APPOINTMENTS_LIST,
    UNSEEN_APPOINTMENT_DISMISS,
    USER_LOGOUT
} from "./../constants";

import { loadUnseenAppointments } from "./../ac/unseen-appointments-actions";

export default class UnseenAppointmentsStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            list: [],
            open: false,
            interval: null
        };

        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case REMOVE_UNSEEN_APPOINTMENT_NOTIFICATION:
                    this.store = Object.assign(this.store, {
                        list: this.store.list.filter(element => {
                            return element.appointment.id !== payload.aptId;
                        })
                    });

                    break;

                case REMOVE_UNSEEN_APPOINTMENT_SET_NOTIFICATION:
                    this.store = Object.assign(this.store, {
                        list: this.store.list.filter(element => {
                            return payload.aptIdSet.indexOf(element.appointment.id) === -1;
                        })
                    });

                    break;

                case LOAD_UNSEEN_APPOINTMENTS_LIST_SUCCESS:

                    this.store.list = payload;

                    break;

                case TOGGLE_UNSEEN_APPOINTMENTS_LIST:
                    this.store.open = !this.store.open;

                    break;

                case UNSEEN_APPOINTMENT_DISMISS:
                    this.store.list = !payload ? [] : this.store.list.filter(item => item.id !== payload);
                    this.store.open = false;

                    break;

                case LOAD_SESSION_SUCCESS:
                    this.waitFor(this.stores.session.getDispatchToken());
                    if (this.stores.session.isProfessional()) {
                        loadUnseenAppointments();

                        if (!this.store.interval) {
                            this.store.interval = setInterval(loadUnseenAppointments, 100000);
                        }
                    }

                    return true;

                case USER_LOGOUT:
                    if (this.store.interval) {
                        clearInterval(this.store.interval);
                    }

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }
}
