/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.8.11
 * Time: 09:53
 *
 *
 * @flow
 *
 */

import BaseStore from "../../base-store";
import {
    PROFILE_PERSONAL_PASSWORD_CHANGE_ERROR,
    PROFILE_PERSONAL_PASSWORD_CHANGE_SUCCESS,
    PROFILE_PERSONAL_PASSWORD_FORM_CHANGE,
    PROFILE_PERSONAL_PASSWORD_FORM_CLEAR,
    PROFILE_PERSONAL_PASSWORD_FORM_PROCESS
} from "../../../constants";

import Filtration from "../../../filter/filtration";
import TrimFilter from "../../../filter/trim-filter";

import Validator from "../../../validation/validator";

import { sendNewPasswordData } from "../../../ac/profile/personal/password-change-form-actions";

type  TStore = {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
    showMessage: boolean,
    showError: boolean,
    formIsValid: boolean,
    violations: {
        oldPassword: string,
        newPassword: string,
        confirmPassword: string
    },
}

export default class ChangePasswordStore extends BaseStore {

    store: TStore;

    constructor(...args: any) {
        super(...args);

        this._registerActionSubscription((action) => {

            let { type, payload } = action;

            switch (type) {

                case PROFILE_PERSONAL_PASSWORD_FORM_CHANGE:

                    this.store = Object.assign(this.store, {
                            [payload.name]: payload.value,
                            showError: false,
                            showMessage: false,
                            formIsValid: true
                        }
                    );

                    break;

                case PROFILE_PERSONAL_PASSWORD_FORM_PROCESS:

                    this.filtrate();

                    if (this.validate()) {
                        sendNewPasswordData({
                            oldPassword: this.store.oldPassword,
                            newPassword: this.store.newPassword
                        });

                        return true;
                    }
                    else {
                        this.store.formIsValid = false;
                    }

                    break;

                case PROFILE_PERSONAL_PASSWORD_FORM_CLEAR:

                    this.resetStore();

                    break;

                case PROFILE_PERSONAL_PASSWORD_CHANGE_SUCCESS:

                    this.onSuccess();

                    break;

                case PROFILE_PERSONAL_PASSWORD_CHANGE_ERROR:

                    this.onError();

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });

    }


    buildValidation(): void {
        this.validator = new Validator();

        this.validator.addConstraint("oldPassword", "is-required");

        this.validator.addConstraint("newPassword", "is-required");
        this.validator.addConstraint("newPassword", "is-password");
        this.validator.addConstraint("newPassword", "length", {
            max: 20,
            min: 5
        });

        this.validator.addConstraint("confirmPassword", "is-password-match", {
            fieldName: "newPassword"
        });
    }


    filtrate(): void {
        const filtration = new Filtration();
        const trim = new TrimFilter();

        filtration.registerFilter("oldPassword", trim);
        filtration.registerFilter("newPassword", trim);
        filtration.registerFilter("confirmPassword", trim);

        this.store = filtration.filtrate(this.store);
    }

    onError(): void {
        this.store.showError = true;
        this.store.showMessage = false;
    }

    onSuccess(): void {
        this.resetStore();
        this.store.showMessage = true;
    }

    getInitialStore(): TStore {

        return {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            showMessage: false,
            showError: false,
            formIsValid: true,
            violations: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            }
        };
    }
}
