/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 4.7.17
 * Time: 19.17
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, FormGroup, FormControl } from 'react-bootstrap';
import Violation from '../helper-components/violation';
import stripe from './../../service/stripe';
import { addNewExternalAccount } from '../../ac/payments/payments-professional';
import ToggleGroup from './../forms/toggle-group';
import {
    LEGAL_ENTITY_TYPE_INDIVIDUAL,
    LEGAL_ENTITY_TYPE_COMPANY
} from './../../frizo-shared-constants';
import { modalHide } from './../../ac/modals';
import { clear } from './../../ac/common-modal-actions';
import {
    PROFILE_PROFESSIONAL_PAY_PAYMENTSOURCE_LOADED,
    PROFILE_PROFESSIONAL_GETPAID_PAYMENTSOURCE_LOADED
} from './../../constants';
import { update } from './../../ac/common-modal-actions';
import { paymentSourceFormStore } from './../../stores';
import gradientFill from './../../helper/gradient-fill';

export default class AddNewBankAccountForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            businessAccountName: '',
            accountNumber: '',
            routingNumber: '',
            legalEntityType: LEGAL_ENTITY_TYPE_INDIVIDUAL,

            violations: {},
            btnDisabled: false
        };

        this.handlersCache = {};

        this.getOnChangeHandler = this.getOnChangeHandler.bind(this);
        this.save = this.save.bind(this);
        this.storeUpdate = this.storeUpdate.bind(this);
    }


    componentDidMount() {
        paymentSourceFormStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        paymentSourceFormStore.removeChangeListener(this.storeUpdate);
    }

    getStoreData() {
        return paymentSourceFormStore.getStore();
    }

    storeUpdate() {
        console.log('store update', this.getStoreData())
        this.setState(this.getStoreData());
    }

    inputHandler(field, e) {
        let value = typeof e === 'object' ? e.target.value : e;

        update({
            [field]: value
        });

    }


    placeGeneralViolation(message) {
        this.setState({
            violations: {
                general: message
            },
            btnDisabled: false
        })
    }


    getOnChangeHandler(field)    {
        if (!this.handlersCache[field]) {
            this.handlersCache[field] = this.inputHandler.bind(this, field);
        }

        return this.handlersCache[field];
    }

    save() {
        console.log('stripe', stripe.getStripe());
        this.setState({btnDisabled: true});
        let bankAccountParams = {
            country: 'us',
            // currency: 'usd',
            routing_number: this.state.routingNumber,
            account_number: this.state.accountNumber,
            account_holder_name: this.state.businessAccountName,
            account_holder_type: this.state.legalEntityType
        };

        stripe.getStripe().createToken('bank_account', bankAccountParams).then(result => {
            console.log('result', result);
            if (result.error) {
                return Promise.reject(result);
            }

            addNewExternalAccount(result.token.id).then(postitiveResult => {

                modalHide()

            }, negativeResult => {

                console.warn(negativeResult);
                this.placeGeneralViolation('Cannot place given account to frizo system');

            });

        }).catch(response => {

            console.warn(response);

            if (response.error &&  response.error.message) {
                this.placeGeneralViolation(response.error.message);
            }
            else {
                this.placeGeneralViolation('Cannot place given account to payment system');
            }

        });
    }



    clear() {
        clear();
    }

    render() {
        return <div>
            <Modal.Header closeButton style={{'background': gradientFill()}}>
                <h2>Add new bank account</h2>
            </Modal.Header>

            <Modal.Body>
                <form id="form" className="form-horizontal">
                    <FormGroup controlId="business-account-name">
                        <Col md={12} xs={12}>
                            <FormControl type="text" placeholder="Business account name" value={this.state.businessAccountName}
                                onChange={this.getOnChangeHandler('businessAccountName')} />
                            <Violation violationSet={this.state.violations.businessAccountName} visible={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup controlId="account-number">
                        <Col md={12} xs={12}>
                            <FormControl type="text" placeholder="Account number" value={this.state.accountNumber}
                                onChange={this.getOnChangeHandler('accountNumber')} />
                            <Violation violationSet={this.state.violations.accountNumber} visible={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup controlId="routing-number">
                        <Col md={12} xs={12}>
                            <FormControl type="text" placeholder="Routing number" value={this.state.routingNumber}
                                onChange={this.getOnChangeHandler('routingNumber')} />
                            <Violation violationSet={this.state.violations.routingNumber} visible={true}/>
                        </Col>
                    </FormGroup>

                    <ToggleGroup selected={this.state.legalEntityType}
                                 onSelect={this.getOnChangeHandler('legalEntityType')}>
                        <Button value={LEGAL_ENTITY_TYPE_INDIVIDUAL}>Individual</Button>
                        <Button value={LEGAL_ENTITY_TYPE_COMPANY}>Company</Button>
                    </ToggleGroup>
                    <Violation violationSet={this.state.violations.legalEntityType} visible={true}/>

                    <FormGroup>
                        <Violation violationSet={this.state.violations.general} visible={true}/>
                    </FormGroup>

                </form>
            </Modal.Body>

            <Modal.Footer className="card-add-footer">
                <div className="pull-left">
                    <Button onClick={this.clear} bsStyle="danger" className="">CLEAR</Button>
                </div>
                <div className="pull-right">
                    <Button onClick={this.save} bsStyle="success" className=""
                            disabled={this.state.btnDisabled}>SAVE</Button>
                </div>
            </Modal.Footer>
        </div>;
    }
}

AddNewBankAccountForm.defaultProps = {
    action: PROFILE_PROFESSIONAL_PAY_PAYMENTSOURCE_LOADED,
};

AddNewBankAccountForm.propTypes = {
    action: PropTypes.oneOf([
        PROFILE_PROFESSIONAL_PAY_PAYMENTSOURCE_LOADED,
        PROFILE_PROFESSIONAL_GETPAID_PAYMENTSOURCE_LOADED
    ])
};