/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.7.17
 */

import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import {
    addNewExternalAccount,
    setDefaultExternalAccount,
    removeExternalAccount
} from '../../../../ac/payments/payments-professional';
import PaymentSource from '../../../payments/elements/payment-source';
import WidgetHeader from './widget-header';
import {
    ADD_NEW_BANK_ACCOUNT_MODAL,
    ADD_NEW_CARD_MODAL
} from './../../../../constants';
import { modalChange } from './../../../../ac/modals';

function openAddCard() {

    modalChange(ADD_NEW_CARD_MODAL, {
        noCard: false,
        action: addNewExternalAccount,
        professional: true
    });
}

function openAddBankAccount() {
    modalChange(ADD_NEW_BANK_ACCOUNT_MODAL);
}

export default function GetPaidWidget(props) {
    return <div>
        <div className="subscription-profile__plan-title">
            Cards & bank
            <ButtonToolbar className="bank-card-profile__button-toolbar">
                <Button onClick={openAddCard} className="fr-btn-add">ADD CARD</Button>
                <Button onClick={openAddBankAccount} className="fr-btn-add">ADD ACCOUNT</Button>
            </ButtonToolbar>
        </div>

            <WidgetHeader/>
            <div className="bank-card-profile__payment-sources">
                        {props.externalAccounts.map(account => <PaymentSource
                        key={account.id} {...account}
                        isDefault={account.default_for_currency}
                        setDefaultSource={setDefaultExternalAccount.bind(this, account.id)}
                        removeSource={removeExternalAccount.bind(this, account.id)}
                    />)}
                </div>

    </div>;
}