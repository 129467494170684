/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 18.9.17
 */

import React, {Component} from 'react';
import {clientStreamStore} from './../../stores';
import Message from './message';
import {markMessageAsRead} from './../../ac/client-stream-actions';

import config from './../../config';

export default class ClientStreamWidget extends Component {

    constructor(props)  {
        super(props);

        this.state = Object.assign({
            stream: []
        }, this.getStoreData());

        this.updateStoreData = this.updateStoreData.bind(this);
    }

    componentDidMount() {
        clientStreamStore.addChangeListener(this.updateStoreData);
    }

    componentWillUnmount() {
        clientStreamStore.removeChangeListener(this.updateStoreData);
    }

    getStoreData()  {
        return clientStreamStore.getStore();
    }
    updateStoreData()   {
        this.setState(this.getStoreData());
    }


    render()    {
        if (!config.show_stream_widget) {
            return false;
        }

        if (this.state.stream.length === 0)   {
            return false;
        }

        return <ul>
            { this.state.stream.map((message, key)=> <Message key={key}
                {...message}
                onRead={markMessageAsRead}
            />) }
        </ul>;
    }
}