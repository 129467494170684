/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.20.4
 * Time: 15:49
 */

import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";

export default class TableRowArtistStatic extends Component {

    createPrice() {
        if (this.props.individualPrice) {

            return this.props.individualPrice;
        }

        return "";
    }

    onEditHandler() {
        let service = {
            i18nKey: this.props.i18nKey,
            price: this.props.price,
            individualPrice: this.props.individualPrice,
            serviceId: this.props.serviceId,
            allowZeroPrice: this.props.allowZeroPrice
        };
        this.props.onEdit(this.props.artistId, service, this.props.assigned);
    }

    render() {

        let btnStyle = this.props.assigned ? "fr-btn fr-btn-small fr-serv-action__more" : "fr-btn-assign fr-btn-small fr-serv-action__more";

        if (!this.props.actions) {
            return <Row className="fr-serv-row fr-table-centred">
                <Col xs={6} sm={6}>{this.props.i18nKey}</Col>
                <Col xs={3} sm={2} className="fr-serv-price">{this.props.price}</Col>
                <Col xs={3} sm={2} className={this.props.individualPrice ? "fr-serv-price" : ""}>
                    {this.createPrice()}
                </Col>

            </Row>;
        }

        return <Row className="fr-table-centred__bordered  services">
            <Col xs={10} sm={5} md={6}>{this.props.i18nKey}</Col>
            <Col xsHidden={true} sm={2} md={2} className="fr-serv-price__artist">{this.props.price}</Col>
            <Col xsHidden={true} sm={2} md={2} className={this.props.individualPrice ? "fr-serv-price__artist" : ""}>
                {this.createPrice()}
            </Col>
            <Col xsHidden={true} md={2} sm={3}>
                <Button bsSize={"small"} className={"fr-btn fr-serv-btn__edit"} onClick={this.onEditHandler.bind(this)}>Edit</Button>
                <Button className={"btn-link fr-btn-serv-remove "}
                        onClick={this.props.onRemove(this.props.id, this.props.artistId)}>Remove</Button>
            </Col>
            <Col xs={2} smHidden={true} mdHidden={true} lgHidden={true}>
                <Button className={btnStyle}
                        onClick={this.onEditHandler.bind(this)}
                ><span className="glyphicon glyphicon-option-horizontal"></span></Button>
            </Col>
        </Row>;
    }

}

TableRowArtistStatic.defaulProps = {
    name: "",
    price: "",
    actions: true,
    individualPrice: "",
    onRemove: () => false
};
