import AppDispatcher from "../dispatcher";
import { LOAD_SESSION_SUCCESS, USER_LOGOUT } from "../constants";
import { events } from "./../auth/events";

function logout() {
    AppDispatcher.dispatch({
        type: USER_LOGOUT,
        payload: {}
    });

}

events.renewingFinished.add((success, session, options) => {
    if (success) {
        AppDispatcher.dispatch({
            type: LOAD_SESSION_SUCCESS,
            payload: { session, request: options.request, options }
        });
    }
    else {
        logout();
    }
});

events.logout.add(logout);
