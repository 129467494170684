/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.01.18
 * Time: 11.24
 */

import React, {
    Component
} from 'react';
import {
    Row,
    Col
} from 'react-bootstrap';
import {promoGalleryStore, sessionStore} from "../../stores";
import {
    GALLERY2_SALON_PROMO_GALLERY_ID,
    MODE_ARTIST_SELECTABLE
} from "../../frizo-shared-constants";
import {createGallery} from "../gallery2/gallery-factory";
import {loadSalonPromoGallery} from "../../ac/salon-promo-action";
import GalleryFilter from "../gallery-filter";
import {loadGalleryFavorites} from "../../ac/favorites/common-ac";
import SalonPromoGalleryItemLook from "../gallery2/items/salon-promo-gallery-item-look";
import SalonPromoFullscreenLook from "../gallery2/fullscreen-items/salon-promo-fullscreen-look";
import {loadGalleryFilterData, onFilterChange, onFilterReset} from "../../ac/gallery-filter-actions";
import GoUpButton from "../../elements/go-up-button";
import LoaderBar from "../../elements/loader-bar";


export default class SalonPromoGallery extends Component {
    constructor(props) {
        super(props);

        this.state = Object.assign({
            ready: false,
            isEmptyGallery: false,
            params: {
                offset: 0,
                count: 12,
                mode: MODE_ARTIST_SELECTABLE,
                salonId: null,

                artistId: 0,
                categoryId: 0,
                serviceId: 0
            },
        }, this.getStoreData());


        this.onStoreChange = this.onStoreChange.bind(this);

        this.gallery = this.createGallery();
        setTimeout(() => {
            this.gallery.reset();

            let state = this.props.location.state;

            if (state)  {
                this.gallery.preset(state);
            }

            this.gallery.init({
                salonUrl: this.props.params.url,
                tab: this.props.route.tab
            });

        });

    }

    getStoreData()  {
        let storeData = promoGalleryStore.getStore();

        return {
            params: storeData.gallery.params,

            artists: storeData.artists,
            categories: storeData.categories,
            services: storeData.services
        }
    }

    componentDidMount() {

        sessionStore.getReadyPromise().then(store => {
            if (!!store.session.isLogged) {
                loadGalleryFavorites();
            }
        });

        promoGalleryStore.addChangeListener(this.onStoreChange);

    }

    componentWillUnmount() {
        promoGalleryStore.removeChangeListener(this.onStoreChange);
    }

    createGallery() {

        return createGallery(GALLERY2_SALON_PROMO_GALLERY_ID)
            .setGalleryStore(promoGalleryStore)
            .setFavoritesEnabled(true)
            .setNoItemsText('Yet to post images')
            .setItemClass(SalonPromoGalleryItemLook)
            .setFullscreenClass(SalonPromoFullscreenLook)
            .setGalleryLoader(loadSalonPromoGallery);
    }


    onStoreChange() {
        let newState = Object.assign({}, this.getStoreData());

        if (newState.params.salonId) {
            if (!this.state.ready) {

                newState.ready = true;

                loadGalleryFilterData(
                    newState.params.salonId, 1, true
                );
            }
        }

        this.setState(newState);
    }


    onChange(field, value)  {
        onFilterChange(GALLERY2_SALON_PROMO_GALLERY_ID, field, value);
    }

    onReset()  {
        onFilterReset(GALLERY2_SALON_PROMO_GALLERY_ID);
    }

    render() {
        if (!this.state.ready) {
            return <LoaderBar className={"loader-msg-wrapper--full-height"}/>
        }

        return (
            <div>
                <Row>

                    <Col md={12}>

                        <GalleryFilter
                            showArtistSelector={this.state.params.mode === MODE_ARTIST_SELECTABLE}

                            artistId={this.state.params.artistId}
                            categoryId={this.state.params.categoryId}
                            serviceId={this.state.params.serviceId}

                            artists={this.state.artists}
                            categories={this.state.categories}
                            services={this.state.services}

                            onChange={this.onChange}
                            onReset={this.onReset}
                        />
                    </Col>

                </Row>
                {this.gallery.getGallery()}
                <GoUpButton/>
            </div>
        )

    }
}
