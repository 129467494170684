/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.3.17
 * Time: 7.03
 */

import React, {Component} from 'react';
import BtnToggle from './btn-toggle';
import {ButtonGroup} from 'react-bootstrap';

import idGenerator from './../../helper/id-generator-component';
import PropTypes from 'prop-types';

export default class OnOffSwitcher extends Component {

    constructor(props)   {
        super(props);

        this.instanceId = idGenerator('on-off-switcher');
        this.state = {
            selected: props.selected
        };

        this.btnSwitch = this.btnSwitch.bind(this);

        if (props.invert)   {
            this.positiveValue = false;
            this.negativeValue = true;
        }
        else {
            this.positiveValue = true;
            this.negativeValue = false;
        }
    }

    btnSwitch()    {
        let value = BtnToggle.getActiveValue(this.instanceId);
        this.props.onChange(value);
    }

    componentDidMount() {
        BtnToggle.addStateChangeListener(this.instanceId, this.btnSwitch);
    }

    componentWillUnmount()  {
        BtnToggle.removeStateChangeListener(this.instanceId, this.btnSwitch);
    }


    render()    {
        return <ButtonGroup className="fr-btn-onoff">
            <BtnToggle name={this.instanceId} selected={this.props.value} value={this.positiveValue}>On</BtnToggle>
            <BtnToggle name={this.instanceId} selected={this.props.value} value={this.negativeValue}>Off</BtnToggle>
        </ButtonGroup>;
    }
}

OnOffSwitcher.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
    invert: PropTypes.bool,
};
OnOffSwitcher.defaultProps = {
    onChange: () => {},
    invert: false
};