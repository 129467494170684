/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.4.17
 * Time: 19.09
 */

export default function hashUrlencode(hash) {
    let preparedData = "";

    for (let i in hash) {
        if (hash.hasOwnProperty(i)) {
            if (0 < preparedData.length) {
                preparedData += "&";
            }
            preparedData += encodeURIComponent(i) + "=" + encodeURIComponent(hash[i]);
        }
    }

    return preparedData;
}
