/**
 * Created by x_a_x_a on 05.01.2017
 * User: Eleonora Kolodinskaya
 * Company: U6 SIA
 */

import React, {Component} from 'react';
import {Modal, Button, Form, FormGroup} from 'react-bootstrap';
import {sendLoginData} from '../../ac/login';
import stores from '../../stores';
import {modalHide, modalChange} from '../../ac/modals';
import {FORGOT_MODAL} from '../../constants';
import {withRouter} from 'react-router'
import {redirectAfterLogin} from './../../ac/redirect-after-login-actions';
import gradientFill from './../../helper/gradient-fill';

class Login extends Component {
    constructor(props) {
        super(props);

        this.sessionStore = stores.session;

        this.state = {
            name: '',
            password: '',

            isLogged: this.sessionStore.isLogged(),
            session: this.sessionStore.getSession(),
            authResult: this.sessionStore.getAuthResult()
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onListYourBusiness = this.onListYourBusiness.bind(this);
        this.onClickSignUp = this.onClickSignUp.bind(this);
    }

    componentDidMount() {
        this.refs.email.focus();
        this.sessionStore.addChangeListener(this.handleChange);
        window.addEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(ev) {
        if (ev.which === 13) {
            this.login();
        }
    }

    componentWillUnmount() {
        this.sessionStore.removeChangeListener(this.handleChange);
        window.removeEventListener("keydown", this.handleKeyDown);
        if (this.props.router.isActive('/log-in')) {
            this.props.router.push('/');
        }
        this.sessionStore.resetAuthResult();
    }

    handleChange() {
        this.setState({
            isLogged: this.sessionStore.isLogged(),
            session: this.sessionStore.getSession(),
            authResult: this.sessionStore.getAuthResult()
        });
    }

    handleInputChange(key) {
        return function (e) {
            let state = {};
            state[key] = e.target.value;
            this.setState(state);
        }.bind(this);
    }

    close() {
        this.props.close();
    }

    login() {
        sendLoginData({username: this.state.name.trim(), password: this.state.password})
            .then(answer => {

                if (!answer.authorized)    {
                    return Promise.resolve(answer);
                }

                let callback;

                if (this.props.previous) {
                    if (typeof this.props.previous === 'function') {
                        callback = this.props.previous;
                    }
                    else {
                        if (this.props.previous.location && this.props.previous.location.state) {
                            if (typeof this.props.previous.location.state.successCallback === 'function') {
                                callback = this.props.previous.location.state.successCallback;
                            }
                        }
                    }
                }

                if (callback)   {
                    return callback();
                }
                else {
                    redirectAfterLogin(answer.session);
                }

                return Promise.resolve(answer);
            }, answer => {console.log('catch fail', answer)});
    }

    openModal() {
        modalChange(FORGOT_MODAL);
    }

    onListYourBusiness() {
        let callback = ()=>false;

        if (this.props.previous) {
            if (typeof this.props.previous === 'function') {
                callback = this.props.previous;
            }
        }

        this.props.router.push({
            pathname: '/pro-sign-up',
            state: {callback: callback}
        });

        modalHide();
    }

    onClickSignUp (e) {
        e.preventDefault();
        let callback = ()=>false;

        if (this.props.previous) {
            if (typeof this.props.previous === 'function') {
                callback = this.props.previous;
            }
        }

        this.props.router.push({
            pathname: '/client-sign-up',
            state: {callback: callback}
        });
        modalHide();
    }

    render() {

        let errorMessage = '';

        switch (true)   {
            case this.state.authResult.problemKey === 0:
                errorMessage = <div className="error-message">Server side problem</div>;
                break;
            case this.state.authResult.problemKey === undefined:
                break;
            default:
                errorMessage = <div className="error-message">{this.state.authResult.problem}</div>;
                break;
        }

        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>Log in</h2>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {errorMessage}
                        <FormGroup>
                            <input className="form-control" type="text" value={this.state.name}
                                   required placeholder="E-mail" name="name" ref="email"
                                   onChange={this.handleInputChange('name')}/>
                        </FormGroup>
                        <FormGroup>
                            <input className="form-control" type="password" value={this.state.password} required
                                   placeholder="Password" name="password"
                                   onChange={this.handleInputChange('password')}/>
                        </FormGroup>
                        <Button bsClass="forgot-link btn-link" onClick={this.openModal.bind(this)}> Forgot
                            password </Button>
                        <FormGroup>
                            <Button  className="fr-btn btn" onClick={this.login.bind(this)}>Log in</Button>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <p>Not a member? <button className={"btn-link fr-pl0"} onClick={this.onClickSignUp}>Sign up</button></p>
                    <p>Business owner? <button className={"btn-link fr-pl0"} onClick={this.onListYourBusiness}>List your
                        business</button></p>
                </Modal.Footer>
            </div>
        );
    }
}

Login.defaultProps = {};

export default withRouter(Login);