/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.4.17
 * Time: 21.28
 */

import BaseStore from "./base-store";
import {
    PROFESSIONAL_PROFILE_WH_OPEN,
    PROFESSIONAL_PROFILE_WH_SYNC_COMPLETE,
    PROFESSIONAL_PROFILE_WH_SYNC_ERROR
} from "./../constants";

export default class ProfileProfessionalStore extends BaseStore {
    constructor(...args) {
        super(...args);

        this.store = {
            wh: {
                violations: {
                    common: [],
                    byArtist: {}
                },
                showViolations: false

            }
        };


        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case PROFESSIONAL_PROFILE_WH_OPEN:
                    this.store.wh = {
                        violations: {
                            common: [],
                            byArtist: {}
                        }
                    };

                    break;

                case PROFESSIONAL_PROFILE_WH_SYNC_COMPLETE:
                    this.store.wh.violations = {
                        common: [],
                        byArtist: {}
                    };
                    this.store.wh.showViolations = false;

                    break;

                case PROFESSIONAL_PROFILE_WH_SYNC_ERROR:

                    this.store.wh.violations = {
                        common: ["Problem with save some of artists. Please review form and try again"],
                        byArtist: payload.report
                    };

                    console.log("this.store.wh.violations", this.store.wh.violations);


                    this.store.wh.showViolations = true;

                    break;

                default:
                    return true;

            }

            this.emitChange();
        });

    }

    getWHTab() {
        return this.store.wh;
    }
}
