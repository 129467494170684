/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 11.1.18
 */

/*
 global Routing
 */

/**
 * This is interface adapter for backward compatibility with api-client2
 *
 * Currently implemented
 * @method queryApi
 * @method get
 * @method post
 * @method put
 * @method delete
 * @method getRouting
 *
 * Another features, such as relay should be migrated to new interface
 *
 */

import { xhr } from "./api-client-2";

export default {
    queryApi(url, method, data, options) {
        let params = null;
        if (Array.isArray(url)) {
            params = url[1] || {};
            url = "@" + url[0];
        }

        let requestSetBuilder = xhr();

        requestSetBuilder.skipAuth(!!options.run_directly);

        if (options.enableDefaultNotifications === false) {
            requestSetBuilder.suppressNotifications();
        }

        let requestBuilder = requestSetBuilder.request(method, url, params);

        if (options.headers) {
            if (Array.isArray(options.headers)) {
                options.headers.forEach(({ name, value }) => {
                    requestBuilder.header(name, value);
                });
            }
            else if (typeof options.headers === "object") {
                Object.keys(options.headers).forEach(name => {
                    requestBuilder.header(name, options.headers[name]);
                });
            }
        }

        switch (true) {
            case typeof data === "string":
                requestBuilder.body(data);
                break;

            case data instanceof FormData:
                requestBuilder.formData(data);
                break;

            default:
                requestBuilder.data(data);
                break;
        }

        return requestBuilder.end().then(request => {
            let payload = [request.getResponseBody(), request.getXhr()];

            return Promise.resolve(payload);

        }, request => {

            return Promise.reject([request.getResponseBody(), request.getXhr()]);

        });
    },

    get(url, data = {}, options = {}) {
        return this.queryApi(url, "GET", data, options);
    },

    post(url, data = {}, options = {}) {
        return this.queryApi(url, "POST", data, options);
    },

    put(url, data = {}, options = {}) {
        return this.queryApi(url, "PUT", data, options);
    },

    del(url, data = {}, options = {}) {
        return this.queryApi(url, "DELETE", data, options);
    },

    getRouting() {
        return Routing;
    },

    relay() {

    }

};
