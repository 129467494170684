/**
 * User: Alexey Ilasov
 * Company: U6 SIA
 * Date: 18.01.2017.
 */
import React from 'react'
import BookForm from './book-form'
import { FormGroup, Col } from 'react-bootstrap';
import Calendar from './../calendar-component'
import { bookAptClientStore } from './../../stores/index'
import bookAptFormActions from '../../ac/book-apt/form-actions'
import BookImage from './book-image-view';
import BookClient from './book-client';
import Violation from './../helper-components/violation';
import DropdownWrapper from './../../elements/dropdown-wrapper';
import drawTime from './../../helper/draw-time';
import { xhrGetCalendarMarkers } from './../../xhr/calendar-markers-xhr';
import moment from 'moment';

export default class BookFormProfessional extends BookForm {

    constructor(props) {
        super(props);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.onClientChange = this.onClientChange.bind(this);
        this.onClientFound = this.onClientFound.bind(this);
        this.onCalendarChange = this.onCalendarChange.bind(this);


        this.state = {
            client: {
                phone: '',
                firstName: '',
                lastName: '',
                violations: {}
            },

            availableTimes: [],
            availableProcessingIntervals: [],
            newClient: false,



        };

    }


    handleClientChange() {

        this.setState({
            client: bookAptClientStore.getStore()
        });

    }


    componentDidMount() {
        super.componentDidMount();
        bookAptClientStore.addChangeListener(this.handleClientChange);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        bookAptClientStore.removeChangeListener(this.handleClientChange);
    }

    onClientChange({field, value}) {

        let client = {};
        client[field] = value;

        bookAptFormActions.clientDataChange(client);

    }

    onClientFound(client) {
        bookAptFormActions.clientDataChange(client);
    }

    updateMarkers() {
        let date = this.props.date;

        if (this.displayMonth !== undefined && this.displayYear !== undefined) {
            date = Date.UTC(this.displayYear, this.displayMonth);
        }

        let start = moment.utc(date).startOf('month').unix();
        let end = moment.utc(date).endOf('month').unix();

        xhrGetCalendarMarkers(
            start,
            end,
            this.props.artistId,
            this.props.addressId
        ).then(response => {
            this.setState({
                markers: response
            });
        }, response => {

            this.setState({
                markers: []
            });
        })
    }


    createProcessingSelect() {
        let options = [];

        this.state.availableProcessingIntervals.forEach((time) => {

            options.push({
                id: Number(time),
                name: drawTime(time)
            });
        });

        return <DropdownWrapper
            className={"dropdown-book-apt"}
            selected={this.props.processingTime}
            list={options}
            placeholder="Select processing"
            placeholderOptionValue={-1}
            selectType="processing"
            onChange={this.onAptChange('processingTime', 'number')}
        />
    }

    createProcessingTimeCorrectedLabel() {
        return <div style={{color: 'red', backgroundColor: 'yellow'}}>Processing time shortened to fit this
            service</div>
    }

    prepareClientData() {
        return bookAptClientStore.getStore();
    }

    render() {

        let service = 'Service ' + this.props.serviceNum;
        //let previewId = 'chosen-style' + this.props.serviceNum;

        let servicesSelects = this.createServiceSelects();
        let timeSelect = this.createTimeSelect();

        let processingSelect =this.createProcessingSelect() ;
        // if (this.props.serviceId) {
        //     let service = servicesStore.getArtistService(this.props.artistId, this.props.serviceId);
        //     if (service && service.durationValue) {
        //         processingSelect = this.createProcessingSelect();
        //     }
        // }

        let processingTimeCorrectedLabel = '';

        if (this.props.processingTimeCorrected) {
            processingTimeCorrectedLabel = this.createProcessingTimeCorrectedLabel();
        }


        let clientAddressSelect = '';
        let clientDataForm = '';
        let artistsSelect = this.createArtistSelect();
        let salonAddressSelect = this.createAddress();

        if (this.props.showClientForm) {
            let clientData = this.prepareClientData();
            clientDataForm = <BookClient
                {...clientData} //pass violations directly from store
                found={this.onClientFound}
                change={this.onClientChange.bind(this)}
                showViolations={this.props.showViolations}
            />;
        }
        let isMobile = window.innerWidth < 992;
        let serviceTitle = <div className="fr-book__title ">
            {service}
            {this.createRemoveButton()}
        </div>;

        return (

            <div>
                <div className="clearfix">
                    <Col md={4} className="fr-pl0">
                        {this.props.serviceNum === 1 ? <div
                            className="fr-book__title">{this.state.client.newClient ? 'New client' : 'Client'}</div> : ''}
                    </Col>
                    <Col md={4} className="fr-pl0-xs">
                        {!isMobile && serviceTitle}
                    </Col>
                    <Col md={4}/>
                </div>

                <FormGroup>
                    <Col md={4} className="fr-mb-xs">
                        {clientDataForm}
                        <BookImage imageId={this.props.imageId} imageUrl={this.props.imageURL}/>
                    </Col>

                    <Col md={4}>
                        {isMobile && serviceTitle}
                        {salonAddressSelect}
                        <Violation violationSet={this.props.aptViolations.addressId}
                                   visible={this.props.showViolations}/>
                        {artistsSelect}
                        <Violation violationSet={this.props.aptViolations.artistId}
                                   visible={this.props.showViolations}/>
                        {servicesSelects}

                        <Violation violationSet={this.props.aptViolations.serviceId}
                                   visible={this.props.showViolations}/>

                        {clientAddressSelect}
                    </Col>
                    <Col md={4}>

                        <Calendar
                            key={this.props.num}
                            static
                            change={this.onCalendarChange.bind(this)}
                            changeMonth={this.changeMonth}
                            timezone={this.props.timezone}
                            forbidPast={true}

                            date={this.props.date}
                            markers={this.state.markers}
                        />

                        {timeSelect}
                        <Violation violationSet={this.props.aptViolations.startTime}
                                   visible={this.props.showViolations}/>

                        {processingSelect}
                        <Violation violationSet={this.props.aptViolations.processingTime} visible={this.props.showViolations}/>

                        {processingTimeCorrectedLabel}
                    </Col>
                </FormGroup>
                <div>
                    <Violation violationSet={this.props.aptViolations.general} visible={this.props.showViolations}/>
                </div>
            </div>
        );
    }
};


