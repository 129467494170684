/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 14.6.17
 * Time: 13.33
 */

import ApiClient from "../service/api-client";

export const CALENDAR_MARKERS_MODE_PROFESSIONAL = 1;
export const CALENDAR_MARKERS_MODE_CLIENT = 2;

export function xhrGetCalendarAvailability(
    salonId,
    fromDate, toDate,
    addressId,
    artistList,
    serviceId, clientId = "", mode = CALENDAR_MARKERS_MODE_PROFESSIONAL) {


    return ApiClient.get(["Q90_available_time_markers", {
        salonID: salonId,
        addressID: addressId,
        serviceID: serviceId,
        clientID: clientId,
        artistList,
        fromDate,
        toDate,
        mode

    }], null, {
        enableDefaultNotifications: false
    }).then(answer => {
        let [response] = answer;

        return response.response;
    });
}
