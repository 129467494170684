/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 17.12.4
 * Time: 9:57
 */

import AppDispatcher from "./../../../dispatcher";
import {
    BRAND_HINT_LIST_LOAD,
    BRAND_HINT_SELECT,
    BRAND_INPUT_CHANGE,
    BRAND_LIST_LOAD,
    PROFILE_BRAND_ADDED,
    PROFILE_BRAND_REMOVED
} from "../../../constants";

import {
    xhrAddProfileBrand,
    xhrLoadBrandList,
    xhrLoadProfileBrands,
    xhrRemoveProfileBrand
} from "../../../xhr/profile-brands-xhr";
import { brandNormalize } from "../../../normal-form/brand";
import { notifySuccess } from "../../notify-actions";


export function loadBrandList() {
    return xhrLoadBrandList().then(brandList => {
        AppDispatcher.dispatch({
            type: BRAND_LIST_LOAD,
            payload: brandList.map(brandNormalize)
        });
    });
}

export function loadProfileBrands({ salonId }) {
    return xhrLoadProfileBrands(salonId).then(response => {

        return Promise.resolve({
            items: response
        });
    });
}

export const addSalonBrandAction = (brandId) => {

    return xhrAddProfileBrand(brandId).then(() => {
        AppDispatcher.dispatch({
            type: PROFILE_BRAND_ADDED,
            payload: brandId
        });

        notifySuccess();
    });
};

export const removeSalonBrandAction = (brandId) => {
    return xhrRemoveProfileBrand(brandId).then(() => {
        AppDispatcher.dispatch({
            type: PROFILE_BRAND_REMOVED,
            payload: brandId
        });

        notifySuccess();
    });
};

export const brandInputChange = val => {
    AppDispatcher.dispatch({
        type: BRAND_INPUT_CHANGE,
        payload: val
    });
};

export const getBrandHintList = val => {
    AppDispatcher.dispatch({
        type: BRAND_HINT_LIST_LOAD,
        payload: val
    });
};

export const brandHintSelect = (brand) => {
    AppDispatcher.dispatch({
        type: BRAND_HINT_SELECT,
        payload: brand
    });
};
