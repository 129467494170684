/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.03.17
 * Time: 06.50
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_TO_HIGH = "VALUE_IS_TO_HIGH";

export default class OlderThan extends BaseConstraint {

    constructor(options = null) {
        super();

        this.invalidValueMessage = "Invalid value";
        this.message = "Must be older than {value} {unit}";

        this.value = null;
        this.unit = null;

        this.setOptions(options);
    }

    static getName() {
        return "is-older-than";
    }

    getRequiredOptions() {
        return ["value", "unit"];
    }

    getUnitPluralForm(value, unit) {
        let units = {
            "year": ["years"],
            "month": ["months"],
            "day": ["days"],
            "second": ["seconds"],
            "minute": ["minutes"],
            "hour": ["hours"]
        };

        switch (true) {
            case value > 1:
                return units[unit][0];

            default:
            case value === 1:
                return unit;
        }
    }
}
