/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 10.1.17.
 */

import {
    APPOINTMENT_ADD,
    APPOINTMENT_CHANGE,
    APPOINTMENT_REMOVE,
    BOOK_ANOTHER,
    BOOK_APPOINTMENTS_LOAD_SUCCESS,
    BOOK_PERSIST,
    BOOK_PERSIST_FAIL,
    BOOK_PERSIST_SUCCESS,
    BOOK_START_BOOKING,
    BOOK_START_EDIT,
    MODAL_HIDE
} from "../../constants";
import { APT_STATUS_REMOVED, MODE_PROFESSIONAL } from "./../../frizo-shared-constants";
import BaseStore from "./../base-store";
import { convertDateToUTCTimestamp } from "./book-timeline-store";
import xhrLoadAppointments from "../../xhr/appointments-xhr";
import { appointmentsLoaded } from "../../ac/book-apt/apts-load-success";
import { innerAptChange } from "../../ac/book-apt/inner-apt-change";
import { persistStop } from "../../ac/book-apt/common-actions";
import { hashFilter } from "./../../helper/hash-filter";
import { mapHashToArray } from "./../../helper/map-hash-to-array";
import Validator from "./../../validation/validator";
import notificationSystem from "../../service/notification-system";
import idGenerator from "./../../helper/id-generator-component";
import { APT_STATUS_NORMAL } from "../../frizo-shared-constants";

const normalizeSet = function(rawAppointments, artistStore) {

    return rawAppointments.map((apt) => {

        let midnight = artistStore.getArtistMidnight(apt.masterUser.id, apt.localDate);

        let imageURL = apt.imageUrl || apt.image ? apt.image.url : null;
        if (imageURL && imageURL.indexOf(".jpg") < 0) {
            imageURL += "-medium.jpg";
        }

        let imageId = apt.imageId || apt.image ? apt.image.id : null;

        return {
            aptId: apt.id,
            id: apt.id,
            salonId: apt.salon.id,
            addressId: apt.address.id,
            clientId: apt.salonClient.id,
            serviceId: apt.service.id,
            artistId: apt.masterUser.id,
            imageId: imageId,
            imageURL: imageURL,
            durationTime: apt.durInterval.endTimestamp - apt.durInterval.startTimestamp,
            processingTime: apt.procInterval.endTimestamp - apt.procInterval.startTimestamp,
            date: apt.localDate,
            startTime: apt.startTimestamp - midnight
        };
    });
};


class BookAptStore extends BaseStore {


    constructor(...args) {
        super(...args);

        Object.assign(this.store, {
            appointments: [],
            idIndex: {},
            midnightTimestampIndex: {},
            showViolations: false,
            syncInProcess: false,
            mode: null
        });

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case BOOK_ANOTHER:
                    if (this.store.appointments.length === 0) return true;
                    let origin = this.getFirstApt() || {};
                    let newApt = Object.assign({}, origin);
                    newApt.status = APT_STATUS_NORMAL;
                    newApt.startTime = -1;
                    newApt.processingTime = -1;
                    newApt.durationTime = -1;
                    newApt.dirty = true;
                    newApt.imageId = null;
                    newApt.imageURL = null;


                    let categoryId = this.checkArtistSingleCategory(newApt.artistId);
                    if (categoryId) {
                        newApt.serviceId = categoryId;
                    }
                    else {
                        newApt.serviceId = 0;
                    }

                    newApt.id = idGenerator("dirty-apt");

                    this.store.appointments.push(newApt);
                    this.store.idIndex[newApt.id] = this.store.appointments.length - 1;

                    break;

                case APPOINTMENT_ADD:

                    let override = {};
                    if (this.store.appointments.length > 0) {
                        if (!payload.artistId) {
                            override.artistId = this.store.appointments[0].artistId;
                            override.date = this.store.appointments[0].date;
                        }
                    }

                    let apt = Object.assign({}, payload, override);

                    this.store.appointments.push(apt);
                    this.store.idIndex[payload.id] = this.store.appointments.length - 1;
                    this.setReady(true);

                    if (!payload.serviceId) {
                        this.stores.services.getReadyPromise().then(store => {
                            let categoryId = this.checkArtistSingleCategory(apt.artistId);
                            if (categoryId) {
                                innerAptChange(apt.id, {
                                    serviceId: categoryId
                                });
                            }
                        });
                    }

                    let fullAddresses = this.stores.artist.getStore()
                    .addresses
                    .filter(address => address.address !== null);

                    if (fullAddresses.length === 1) {
                        setTimeout(() => {
                            innerAptChange(apt.id, {
                                addressId: fullAddresses[0].id
                            });
                        });
                    }


                    break;

                case APPOINTMENT_REMOVE: {

                    let aptId = payload["id"];
                    let index = this.store.idIndex[aptId];
                    let apt = this.store.appointments[index];

                    if (apt.dirty) {
                        this.store.appointments.splice(index, 1);
                        this.store.idIndex = this.buildIdIndex();

                    }
                    else {
                        apt.status = APT_STATUS_REMOVED;
                    }

                    break;
                }

                case APPOINTMENT_CHANGE: {
                    let aptId = payload.id;
                    let aptIndex = this.store.idIndex[aptId];
                    let patch = payload["patch"];
                    let apt = this.store.appointments[aptIndex];

                    if (patch.artistId) {
                        patch.serviceId = 0;
                    }

                    //if service changed
                    if (apt.artistId && patch.serviceId) {
                        let service = this.stores.services.getArtistService(apt.artistId, patch.serviceId);
                        if (service) {
                            patch.processingTime = service.processingValue;
                        }

                    }

                    if (patch.processingTime) {
                        patch.processingTimeCorrected = false;
                    }
                    else if (!apt.processingTime || apt.processingTime <= 0) {
                        //case when all fields selected, but processingTime not

                        let tempApt = Object.assign({}, apt, patch);

                        if (tempApt.startTime && tempApt.artistId && tempApt.serviceId) {
                            let service = this.stores.services.getArtistService(tempApt.artistId, tempApt.serviceId);

                            if (service) {
                                patch.processingTime = service.processingValue;
                            }
                        }
                    }


                    //validate change

                    let correctPatch = this.stores.timeline.correctPatch(
                        this.store.appointments[aptIndex],
                        patch
                    );

                    switch (true) {
                        case correctPatch.processingTime !== undefined:
                            correctPatch.processingTimeCorrected = true;
                            break;

                        default:
                    }

                    if (patch.artistId) {
                        if (!patch.serviceId) {
                            let categoryId = this.checkArtistSingleCategory(patch.artistId);
                            if (categoryId) {
                                patch.serviceId = categoryId;
                            }
                        }
                    }

                    Object.assign(apt, patch, correctPatch);

                    break;
                }

                case MODAL_HIDE: {
                    this.store.appointments = [];

                    break;
                }

                case BOOK_START_BOOKING: {
                    this.store.appointments = [];
                    this.store.mode = payload.mode;
                    this.setReady(false);

                    break;
                }

                case BOOK_START_EDIT: {
                    this.store.appointments = [];
                    this.setReady(false);

                    xhrLoadAppointments(payload).then((response) => {
                        let appointments = response.appointments;
                        appointmentsLoaded(appointments);
                    });

                    break;
                }

                case BOOK_APPOINTMENTS_LOAD_SUCCESS:
                    let artistStore = this.stores.artist;
                    this.store.appointments = normalizeSet(payload, artistStore);

                    this.store.idIndex = this.buildIdIndex();

                    this.setReady(true);

                    break;

                case BOOK_PERSIST:

                    this.waitFor(this.stores.client.getDispatchToken());
                    this.waitFor(this.stores.conf.getDispatchToken());

                    this.store.appointments.forEach(apt => {
                        let availableTime = this.stores.timeline.getAvailableTimesForApt(apt.id);

                        if (!availableTime.has(apt.startTime)) {
                            apt.startTime = -1;
                        }
                    });


                    if (this.validate()) {

                        this.store.syncInProcess = true;

                        payload.syncer.putAppointments(this.store.appointments);
                        payload.syncer.persist().catch(reason => reason).then(reason => {
                            setTimeout(() => {
                                persistStop(reason);
                            });
                        });

                    }
                    else {
                        this.store.showViolations = true;
                        setTimeout(persistStop);
                    }

                    break;

                case BOOK_PERSIST_SUCCESS:
                    this.store.syncInProcess = false;

                    notificationSystem.logSuccess("Appointment booked");
                    break;

                case BOOK_PERSIST_FAIL:
                    this.store.syncInProcess = false;

                    if (!payload.fatal) {
                        for (let aptId in payload) {
                            let violationSet = payload[aptId];

                            this.setViolationsForApt(aptId, violationSet);
                        }
                    }

                    this.store.showViolations = true;

                    break;

                default:
                    return true;
            }


            this.emitChange();
        });

    }

    /**
     * @protected
     *
     * @returns {null}
     */
    getFirstApt() {
        for (let i = 0; i < this.store.appointments.length; i++) {
            if (this.store.appointments[i] !== undefined) {
                if (this.store.appointments[i].status !== APT_STATUS_REMOVED) {
                    return this.store.appointments[i];
                }
            }
        }

        return null;
    }

    checkArtistSingleCategory(artistId) {
        let services = this.stores.services.createArtistIndexServices(artistId);

        let topCategories = mapHashToArray(hashFilter(services, service => service.parentId === 0));

        if (topCategories.length === 1) {
            return topCategories[0].id;
        }

        return false;
    }


    /**
     * FIXME: Optimize timestamp calculation (add index or precalculated value)
     * @param midnightTimestamp
     * @param artistId
     * @returns {Array.<*>}
     */
    filterAppointments(midnightTimestamp, artistId) {
        return this.store.appointments.filter(apt => {
            let aptMidnightTimestamp = convertDateToUTCTimestamp(apt.date);

            return midnightTimestamp === aptMidnightTimestamp && artistId === apt.artistId;
        });
    }


    getAppointments() {
        return this.store.appointments;
    }

    getByAptId(aptId) {
        let index = this.store.idIndex[aptId];
        if (index === undefined) return null;

        return this.store.appointments[index];
    }


    /**
     * @protected
     */
    buildValidation() {
        this.validator = new Validator();

        this.validator
        .addConstraint("artistId", "is-required")
        .addConstraint("addressId", "is-not-default-dropdown-value")
        .addConstraint("addressId", "is-required")
        .addConstraint("addressId", "is-not-default-dropdown-value")
        .addConstraint("date", "is-required")
        .addConstraint("startTime", "is-required")
        .addConstraint("startTime", "is-number")
        .addConstraint("startTime", "is-not-default-dropdown-value")
        .addConstraint("serviceId", "is-not-default-dropdown-value")
        .addConstraint("serviceId", "service-is-leaf", {
            useArtistSpecificService: true,
            artistIdFieldName: "artistId",
            serviceStore: this.stores.services
        });


        if (this.store.mode === MODE_PROFESSIONAL) {
            this.validator
            .addConstraint("processingTime", "is-required")
            .addConstraint("processingTime", "is-not-default-dropdown-value");
        }

    }

    validate() {
        this.ensureValidator();

        this.resetValidationResult();

        let valid = true;

        this.store.appointments.forEach(apt => {
            let result = this.validator.validate(apt);
            if (result !== true) {
                this.setViolationsForApt(apt.id, result);
                valid = false;
            }
        });

        return valid;
    }


    getViolationsForApt(aptId) {
        return this.store.violations[aptId] || {};
    }


    setViolationsForApt(aptId, violationSet) {
        this.store.violations[aptId] = violationSet;
    }

    violationsVisible() {

        return this.store.showViolations;
    }

    buildIdIndex() {

        let apts = this.store.appointments;
        let idIndex = {};

        apts.forEach((apt, index) => {
            idIndex[apt.id] = index;
        });

        return idIndex;

    }
}

export default BookAptStore;
