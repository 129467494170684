/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 18.8.17
 */

import ApiClient from "../service/api-client";

export function getFeatureFlags(ffParams) {

    let url = ApiClient.getRouting().generate("Q131_get_feature_flags", ffParams);

    return ApiClient.get(url, null, {
        run_directly: true
    }).then(answer => {
        let [response] = answer;

        return response;
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}
