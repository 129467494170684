/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 13.01
 */

import ApiClient from "./../service/api-client";

export function xhrDashboardGallery(options) {
    return ApiClient.get(["Q70_main_screen_gallery", options]).then(answer => {
        let [response] = answer;

        return response.response;
    }, answer => {
        let [response] = answer;

        console.log("fail response", response);

        return Promise.reject(response);
    });
}
