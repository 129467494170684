/**
 * Created by Alexey Ilasov on 07.12.2016.
 */
import React, {Component} from 'react'
import {Form, Button, Col} from 'react-bootstrap'
import moment from 'moment';
import BookFormComponent from './book-form-component';

import {MODE_CLIENT} from './../../frizo-shared-constants';

import {
    bookAptStore,
    artistStore,
    servicesStore,
    bookAptConfStore,
    bookAptSalonStore,
    bookAptClientStore,
} from './../../stores';
import {addApt, removeApt} from '../../ac/book-apt/common-actions';

import {APT_STATUS_NORMAL, APT_STATUS_REMOVED} from './../../frizo-shared-constants';
import notificationSystem from './../../service/notification-system';
import printAddress from "../../helper/print-address";

export default class BookWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            artists: null,
            services: null,
            aptViolations: {},
            showViolations: false,
            salonType: null
        };

        this.aptDefaults = {
            aptId: 0,
            id: 0,
            salonId: 0,
            galleryItem: 0,
            artistId: 0,
            clientUser: 0,
            addressId: 0,
            serviceId: 0,
            date: moment(new Date()).format('YYYY-MM-DD'),
            startTime: 0,
            processingTime: 0,
            durationTime: 0,
            image: '',
            method: '',
            status: APT_STATUS_NORMAL
        };

        this.handleStoresChange = this.handleStoresChange.bind(this);
        this.onSalonDataChange = this.onSalonDataChange.bind(this);
    }

    handleStoresChange() {
        let systemReady = servicesStore.isReady() && artistStore.isReady() && bookAptStore.isReady() && bookAptSalonStore.isReady();

        let patch = {
            systemReady: systemReady,
            mode: bookAptConfStore.getMode()
        };

        if (systemReady) {

            let salonId = this.props.bookData.salonId;
            let salon = bookAptSalonStore.getStore().salonSet[salonId];
            let showViolations = bookAptStore.violationsVisible() || bookAptClientStore.violationsVisible() || false;

            this.aptDefaults.salonId = salonId;

            Object.assign(patch, {
                appointments: bookAptStore.getStore().appointments,
                mode: bookAptConfStore.getMode(),
                aptViolations: bookAptStore.getViolations(),
                showViolations: showViolations,
                salonType: salon.type
            });
        }

        else {
            // console.log('ready:', servicesStore.isReady(), artistStore.isReady(), bookAptStore.isReady())
        }

        this.setState(patch);

    }

    componentWillUnmount() {
        bookAptStore.removeChangeListener(this.handleStoresChange);
        artistStore.removeChangeListener(this.handleStoresChange);
        servicesStore.removeChangeListener(this.handleStoresChange);
        bookAptSalonStore.removeChangeListener(this.handleStoresChange);
        bookAptClientStore.removeChangeListener(this.handleStoresChange);
    }

    componentDidMount() {
        bookAptStore.addChangeListener(this.handleStoresChange);
        artistStore.addChangeListener(this.handleStoresChange);
        servicesStore.addChangeListener(this.handleStoresChange);
        bookAptSalonStore.addChangeListener(this.handleStoresChange);
        bookAptClientStore.addChangeListener(this.handleStoresChange);
    }

    onSalonDataChange() {

    }


    onRemoveElement(aptId) {
        removeApt(aptId);
    }


    onAddElement() {
        let appointment = Object.assign({}, this.aptDefaults);
        addApt(appointment);
    }

    showRemove() {
        return this.state.appointments.length > 1 || false;
    }

    render() {

        if (this.state.systemReady) {

            let addressChanges = [];

            let serviceNum = 0;
            let showClientForm = true;
            let multiAddress = false;

            let clientFormElements = this.state.appointments.filter(apt => {
                return Number(apt.status) !== APT_STATUS_REMOVED;
            }).map((apt, i) => {
                serviceNum++;

                let servicesData = servicesStore.prepareServicesData(apt.artistId, apt.serviceId);
                let artistsData = artistStore.prepareArtistData(
                    apt.artistId,
                    this.props.bookData.mode
                );
                multiAddress = artistsData.multiAddress;
                let addresses = artistStore.prepareAddressData(apt.artistId);

                let address;
                if (apt.addressId) {
                    try {
                        address = artistStore.getAddressById(apt.addressId);
                    }
                    catch (e)   {
                        address = artistStore.guessAddress(apt.artistId, apt.date);
                        apt.addressId = address.id;

                        if (!addressChanges.includes(address)) {
                            addressChanges.push(address);
                        }
                    }
                }

                let timezone = artistStore.guessTimezone(address.id, apt.artistId);

                let form = <BookFormComponent
                    key={apt.id}
                    num={i}

                    aptId={apt.id}
                    date={apt.date}
                    salonType={this.state.salonType}
                    salonId={this.props.bookData.salonId}

                    mode={this.props.bookData.mode}
                    action={this.props.bookData.action}

                    artistId={apt.artistId}
                    addressId={apt.addressId}
                    serviceId={apt.serviceId}

                    servicesData={servicesData}
                    artistsData={artistsData}
                    addresses={addresses}
                    address={address}
                    timezone={timezone}

                    imageId={apt.imageId}
                    imageURL={apt.imageURL}

                    processingTimeCorrected={apt.processingTimeCorrected}

                    serviceNum={serviceNum}
                    showClientForm={showClientForm}

                    onRemoveElement={this.onRemoveElement.bind(this)}
                    startTime={apt.startTime}
                    processingTime={apt.processingTime}

                    aptViolations={this.state.aptViolations[i]}
                    showViolations={this.state.showViolations}

                    showRemove={this.showRemove()}

                    dirty={apt.dirty}
                />;

                showClientForm = false; //show only for first form

                return form;
            });

            if (addressChanges.length > 0)  {
                let s = addressChanges.map(address => printAddress(address));

                if (multiAddress)   {
                    notificationSystem.alert('Please note the change of address. The new address is<br />' + s);

                }
                else {
                    notificationSystem.alert('The salon location has changed. The new address is<br />' + s);
                }
            }

            let serviceNext = 'Service ' + (serviceNum + 1);
            let serviceHeader = <div className="fr-book__title">
                {serviceNext}
                <Button bsStyle="primary" className="btn-add"
                        onClick={this.onAddElement.bind(this)}>Add</Button>
            </div>;

            return (
                <Form horizontal className="form clearfix" role="form">
                    {clientFormElements}
                    <Col md={4} className="fr-pl0-xs">{this.props.bookData.mode === MODE_CLIENT ? serviceHeader : ''}
                    </Col>
                    <Col md={4} className="fr-pl0-xs"> {this.props.bookData.mode === MODE_CLIENT ? '' : serviceHeader }</Col>
                    <Col md={4}/>
                </Form>
            );
        }
        return (<div className={"loading-message"}>Loading...</div>);
    }
}


BookWrapper.defaultProps = {
    bookData: {
        aptId: 0,
        salonId: 0,
        galleryItem: 0,
        artistId: 0,
        clientUser: 0,
        addressId: 0,
        address: null,
        serviceId: 0,
        service: null,
        startTime: 0,
        processingTime: 0,
        durationTime: 0,
        image: '',
        method: '',
        status: APT_STATUS_NORMAL
    }
};


