/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.8.17
 */

if (!Array.prototype.find) {
    Array.prototype.find = function(predicate) { // eslint-disable-line no-extend-native
        if (this == null) {
            throw new TypeError("Array.prototype.find called on null or undefined");
        }
        if (typeof predicate !== "function") {
            throw new TypeError("predicate must be a function");
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }

        return undefined;
    };

}
