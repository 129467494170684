/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.12.17
 * Time: 19.53
 */


import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {modalHide} from '../../ac/modals';

import imgLeft from './../../../../img/scroll_gallery_left.svg';
import imgRight from './../../../../img/scroll_gallery_right.svg';
import {galleryFullscreenNext, galleryFullscreenPrev} from "../../ac/gallery/generic-gallery-actions";
import BaseStore from "../../stores/base-store";


type TProps = {
    store: BaseStore,
    classResolver: Function
};

export default class GalleryFullscreenWidget extends Component<TProps> {

    prev: Function;
    next: Function;

    constructor(props) {
        super(props);
        this.state = Object.assign({
            currentIndex: 0,
            currentItem: null,
            storeParams: {},
            swiped: false,
            startX: 0,
            startY: 0,
            showNext: true,
            showPrev: false,
            galleryId: null,
            showImageAfter: true
        }, this.getStoreData());
        this.keyDownHandler = this.keyDownHandler.bind(this);
        this.onCloseClickHandler = this.onCloseClickHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.updateStore = this.updateStore.bind(this);

        this.prev = galleryFullscreenPrev.bind(this, this.state.galleryId);
        this.next = galleryFullscreenNext.bind(this, this.state.galleryId);

        this.imageHandler = this.imageHandler.bind(this);
    }

    getStoreData() {
        let store = this.props.store.getStore();

        return Object.assign({}, store.gallery.fullScreen, {
            galleryId: store.galleryId,
            storeParams: store.gallery.params
        });
    }

    updateStore() {
        this.setState(this.getStoreData());
    }


    componentDidMount() {
        window.addEventListener('keydown', this.keyDownHandler);
        window.addEventListener('mousedown', this.handleClick);

        this.props.store.addChangeListener(this.updateStore);
    }

    componentWillUnmount() {

        window.removeEventListener('keydown', this.keyDownHandler);
        window.removeEventListener('mousedown', this.handleClick);

        this.props.store.removeChangeListener(this.updateStore);
    }

    keyDownHandler(e) {
        if (e.keyCode === 39) {
            this.next();
        }
        if (e.keyCode === 37) {
            this.prev();
        }
    }

    onCloseClickHandler() {
        modalHide();
    }

    handleClick(e) {
        let imageModal = this.refs.fullscreenModal;
        let popover = document.querySelector('#popover-share');
        let isPopover = popover ? popover.contains(e.target) : false;
        if (imageModal.contains(e.target) || isPopover) {
            return false;
        }
        modalHide();
    }

    onDragStart(e) {

        let startX = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        let startY = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;

        this.setState({
            swiped: true,
            startX: startX,
            startY: startY
        });
    }

    onDragMove(e) {
        if (!this.state.swiped) {
            return;
        }

        // Get the new x coordinates
        let x = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        let y = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;

        let dx = x - this.state.startX;
        let dy = y - this.state.startY;
        if (dx > 0) {
            this.prev();
        }
        else {
            this.next();
        }

        if (this.state.currentItem.imageBefore) {
            if (dy > 0) {
                this.setState({showImageAfter: false});
            }
            else {
                this.setState({showImageAfter: true});
            }
        }

        this.setState({
            startX: x,
            startY: y
        });

    }


    onDragEnd(e) {
        this.setState({swiped: false});
    }

    getPrevArrow() {
        return <button title="Previous" className="btn-link image-nav image-prev" onClick={this.prev}>
            <img src={imgLeft} alt="Show previous"/>
        </button>;
    }

    getNextArrow() {
        return <button title="Next" className="btn-link image-nav image-next" onClick={this.next}>
            <img src={imgRight} alt="Show next"/>
        </button>;
    }

    imageHandler() {
        let slider = document.querySelector('.img-slider');
        if (slider) {
            if (slider.classList.contains('slider-up')) {
                slider.classList.remove('slider-up');
                slider.classList.add("slider-down");
            }
            else {
                slider.classList.remove('slider-down');
                slider.classList.add("slider-up");
            }
        }
        this.setState({
            showImageAfter: !this.state.showImageAfter,
        });
    }

    getBeforeButton() {
        return <button className={"btn-link fr-btn-double-photo-before"} onClick={this.imageHandler}>
        </button>
    }

    getAfterButton() {
        return <button className={"btn-link fr-btn-double-photo-after"} onClick={this.imageHandler}>
        </button>
    }

    render() {
        if (!this.state.currentItem) return false;

        let ElementClass = this.props.classResolver(this.state.currentItem);
        let showDoublePhoto = this.state.currentItem.imageBefore && this.state.currentItem.imageBefore.id !== null;


        return (<div ref="fullscreenModal">
            <Modal.Header className="image-fullscreen-modal__header"/>
            <Modal.Body className="image-fullscreen-modal__body">
                <div className="close-layer" onClick={this.onCloseClickHandler}>
                    <Button className="close">
                        <span>×</span>
                    </Button>
                </div>
                {showDoublePhoto && <div className="img-handler">

                    {this.state.showImageAfter ? this.getBeforeButton() : this.getAfterButton()}

                </div>}
                <ElementClass {...this.props}
                              {...this.state}

                              showPrev={this.state.showPrev}
                              showNext={this.state.showNext}

                              prevArrow={this.getPrevArrow()}
                              nextArrow={this.getNextArrow()}


                              onPrevious={this.prev}
                              onNext={this.next}
                              onTouchStart={this.onDragStart.bind(this)}
                              onTouchMove={this.onDragMove.bind(this)}
                              onTouchEnd={this.onDragEnd.bind(this)}
                />
            </Modal.Body>
        </div>)
    }
}