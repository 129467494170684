/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 22.01.2018
 * Time: 19.03
 */

import { TImage } from "../types";

type TRawBrand = {
    brandID: number,
    brandImageURL: string,
    brandName: string
};

export function brandNormalize(rawBrand: TRawBrand): TBrand {
    return {
        id: rawBrand.brandID,
        image: {
            url: rawBrand.brandImageURL
        },
        title: rawBrand.brandName
    };
}

export type TRawBrand2 = {
    id: number,
    image: TImage,
    name: string
};

export function brandNormalize2(rawBrand: TRawBrand2): TBrand {
    return {
        id: rawBrand.id,
        image: rawBrand.image,
        title: rawBrand.name
    };
}
