/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 26.1.17
 */

import moment from "moment";
import { xhr } from "../service/api-client-2";
import { Request } from "../service/api-client-2/request";

export default function xhrLoadTimeline(dateString) {
    let artists = [];

    return {
        addArtist(userId) {
            artists.push(userId);
        },

        then(successCallback, failCallback) {

            let builder = xhr();

            let midnightTimestamp = moment.utc(dateString).unix();
            let ymd = dateString.replace(/-/g, "");

            artists.forEach(artistId => {
                builder.get("@Q2-21-time-intervals", {
                    artistId, ymd
                }, artistId).end();
            });

            return builder.then((responseSet: Request) => {
                console.log("responseSet", responseSet.toHash());

                let ret = successCallback({
                    midnightTimestamp: midnightTimestamp,
                    artistIds: artists,
                    timelineSet: responseSet.toHash()
                });

                return Promise.resolve(ret);

            }, (responseSet: Request) => {
                //fail
                console.warn("Timeline loading fail", responseSet.merge());

                if (typeof failCallback === "function") {
                    return Promise.reject(failCallback(responseSet.merge()));
                }
                else {
                    return Promise.reject(responseSet.merge());
                }
            });
        }
    };
}