/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.1.18
 */

import Signal from "signals";

export const xhrEvents = {
    /**
     * @param Request $request
     * @param Processor $processor
     */
    requestIssued: new Signal(),

    requestSucceeded: new Signal(),
    requestFailed: new Signal()
};

export const lowLevelEvents = {
    xhrStart: new Signal(),
    xhrDone: new Signal(),
    startResponseHandler: new Signal(),
    finishResponseHandler: new Signal(),
    responseHandled: new Signal(),

    requestScheduled: new Signal(),
    processorContinued: new Signal(),
    processorPaused: new Signal()
};
