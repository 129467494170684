/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 13.07.2018
 * Time: 12:36
 */

export default function getImageTitle(data) {

    if (data.artist === undefined) return "";

    let serviceTitle = "";
    if (data.service) {
        serviceTitle = data.service.title + " by";
    }

    let artistTitle = data.artist.firstName + " " + data.artist.lastName;
    let addressIn = "";
    if (data.location) {
        addressIn = "in " + data.location.city + ", " + data.location.state + " " + data.location.zip;
    }
    let salonTitlePostfix = "";
    if (data.salon) {
        salonTitlePostfix = data.salon.type === 2 ? "" : "at " + data.salon.title;
    }

    let descriptionString = [
        serviceTitle,
        artistTitle,
        salonTitlePostfix,
        addressIn
    ].filter(value => value && value.length > 0).join(" ");

    return descriptionString + " on Frizo";
}
