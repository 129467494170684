/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 7.11.17
 * Time: 17.54
 *
 * @flow
 *
 */

import type { TClient, TSalonClient } from "../../types";
import type { TRawSalonClient, TRawSalonClient2, TRawUser } from "../raw-types";
import { normalizeGender } from "../gender";
import { addressNormalize1 } from "../address";
import { TYPE_SALON_CLIENT, TYPE_USER } from "../../frizo-shared-constants";

export function salonClientNormalize(rawSalonClient: TRawSalonClient): TSalonClient {
    return {
        entityType: TYPE_SALON_CLIENT,

        id: rawSalonClient.id,
        comment: rawSalonClient.comment,

        email: rawSalonClient.email,
        phone: rawSalonClient.phone,

        firstName: rawSalonClient.firstName,
        lastName: rawSalonClient.lastName,
        activated: rawSalonClient.isActiveUser,

        user: clientNormalize(rawSalonClient.user)
    };
}

function clientNormalize(rawSession: TRawUser): TClient {
    let literal: TClient = {
        entityType: TYPE_USER,
        id: rawSession.id,
        firstName: rawSession.firstName,
        lastName: rawSession.lastName,

        userName: rawSession.username,
        phone: rawSession.phone,

        activated: rawSession.activated

    };

    if (rawSession.gender) {
        literal.gender = normalizeGender(rawSession.gender);
    }

    if (rawSession.address) {
        literal.address = addressNormalize1(rawSession.address);
    }


    if (rawSession.image) {
        literal.image = {
            id: rawSession.image.id,
            url: rawSession.image.url
        };
    }

    return literal;
}

export function salonClientNormalize2(rawSalonClient: TRawSalonClient2): TSalonClient {
    return {
        entityType: TYPE_SALON_CLIENT,
        id: rawSalonClient.clientID,
        comment: rawSalonClient.clientComment,

        email: rawSalonClient.clientEmail,
        phone: rawSalonClient.clientPhone,

        firstName: rawSalonClient.clientFirstName,
        lastName: rawSalonClient.clientLastName,
        activated: rawSalonClient.isActiveUser

    };
}
