/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 8.3.17
 * Time: 8.16
 */

import React, { Component } from "react";
import { sessionStore } from "./../stores/index";
import { modalChange } from "./../ac/modals";
import { LOGIN_MODAL } from "./../constants";

export default class AuthButton extends Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

        this.originClickHandler = props.onClick;
    }

    handleClick(event) {
        event.persist();

        if (sessionStore.isReady()) {
            if (!sessionStore.isLogged()) {
                event.preventDefault();

                let originClickHandler = this.originClickHandler;
                return modalChange(LOGIN_MODAL, {
                    inTransition: true,
                    autoHide: this.props.autoHideModal,
                    previous: () => {
                        event.defaultPrevented = false;
                        originClickHandler(event);
                    }
                });
            }
        }

        return this.originClickHandler(event);
    }

    render() {
        let props = Object.assign({}, this.props, {
            onClick: this.handleClick
        });

        let tag = props.tag;
        delete props.tag;
        delete props.autoHideModal;

        return React.createElement(tag, props, this.props.children);
    }
}

AuthButton.defaultProps = {
    tag: "a",
    autoHideModal: true
};
