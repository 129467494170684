/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 13.09.2017
 * Time: 15:14
 */

import AppDispatcher from "../dispatcher";
import { LOAD_SALON_INFO, REMOVE_UNSEEN_APPOINTMENT_SET_NOTIFICATION } from "./../constants";
import { xhrLoadNameAndLocation } from "./../xhr/my-salon-xhr";
import { REMOVE_UNSEEN_APPOINTMENT_NOTIFICATION } from "../constants";

export const loadSalonInfo = () => {
    xhrLoadNameAndLocation().then(data => {
        AppDispatcher.dispatch({
            type: LOAD_SALON_INFO,
            payload: data
        });
    });
};

export const removeUnseenAppointmentNotification = (aptId) => {
    AppDispatcher.dispatch({
        type: REMOVE_UNSEEN_APPOINTMENT_NOTIFICATION,
        payload: {
            aptId
        }
    });

};

export const removeUnseenAppointmentSetNotification = (aptIdSet) => {
    AppDispatcher.dispatch({
        type: REMOVE_UNSEEN_APPOINTMENT_SET_NOTIFICATION,
        payload: {
            aptIdSet
        }
    });

};