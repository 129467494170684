/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 15.01.2018
 * Time: 14:58
 */
import React, {Component} from 'react';

import {
    loadFavoriteItems
} from '../../../../ac/profile/personal/personal-favorites-actions';
import {Link} from 'react-router';
import {
    GALLERY2_PERSONAL_PROFILE_FAVORITES_ITEMS_ID
} from "../../../../frizo-shared-constants";
import {personalProfileFavoritesItemsStore} from "../../../../stores";
import {createGallery} from "../../../gallery2/gallery-factory";
import PersonalProfileFavoritesLook from "../../../gallery2/items/personal-profile-favorites-look";
import PersonalProfileFavoritesFullscreenLook from "../../../gallery2/fullscreen-items/personal-profile-favorites-fullscreen-look";


export default class FavoritesItemsContainer extends Component {

    constructor(props) {
        super(props);


        this.state = Object.assign({
            ready: false,
            params: {},
            salon: {},
            showEmptyMessage: false
        }, this.getStoreData());


        this.onGalleryStoreChange = this.onGalleryStoreChange.bind(this);

        this.gallery = this.createGallery();
        this.gallery.reset();
        this.gallery.init();
    }

    getStoreData() {
        let storeData = personalProfileFavoritesItemsStore.getStore();

        return {
            params: storeData.gallery.params,
            showEmptyMessage: storeData.gallery.items.length === 0
        }
    }

    componentDidMount() {
        personalProfileFavoritesItemsStore.addChangeListener(this.onGalleryStoreChange);
    }

    componentWillUnmount() {
        personalProfileFavoritesItemsStore.removeChangeListener(this.onGalleryStoreChange);

    }

    createGallery() {

        return createGallery(GALLERY2_PERSONAL_PROFILE_FAVORITES_ITEMS_ID)
            .setGalleryStore(personalProfileFavoritesItemsStore)
            .setFavoritesEnabled(false)
            .setNoItemsText('Yet to post images')
            .setItemClass(PersonalProfileFavoritesLook)
            .setFullscreenClass(PersonalProfileFavoritesFullscreenLook)
            .setGalleryLoader(loadFavoriteItems);
    }


    onGalleryStoreChange() {
        let newState = Object.assign({}, this.getStoreData());

        this.setState(newState);

    }

    render() {
        if (this.state.showEmptyMessage) {
            return (<div className="no-fav-message "><Link to='/'>Let me see again</Link></div>);
        } else {
            return (
                <div>
                    <div className="fr-mt-xs">
                        {this.gallery.getGallery()}
                    </div>
                </div>
            );
        }
    }

}