/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 19.02.2018
 * Time: 6.55
 */

import { REQUEST_STATE_DONE } from "../../../frizo-shared-constants";
import { parseResponse } from "../response-parser";

function handleRelayResponse(request: Request, { statusCode, payload, headers }) {

    request.setStatusCode(statusCode);
    request.setState(REQUEST_STATE_DONE);

    Object.keys(headers).forEach(header => {
        let values = headers[header];

        values.forEach(value => {
            request.setResponseHeader(header, value);
        });
    });

    let cType = request.getResponseHeader("Content-Type");
    if (!/application\/json/.test(cType)) {
        payload = parseResponse(payload, cType);
    }

    request.setResponseBody(payload);
    request.setOriginResponseBody(payload);
}

export function wrapRelayResponse(carrier: Request, requestSet: Array<Request>) {

    let wrapper = requestSet[0];

    let i = 0;
    requestSet.forEach((request: Request) => {
        wrapper.push(request);

        let requestId = request.getRequestId();

        let relayEncodedResponse;
        if (requestId) {
            wrapper[requestId] = request;
            relayEncodedResponse = carrier.response[requestId];
        }
        else {
            relayEncodedResponse = carrier.response[i];
        }


        if (relayEncodedResponse !== undefined) {
            handleRelayResponse(request, relayEncodedResponse);
        }
        else {
            request.setStatusCode(carrier.getStatusCode());
        }

        i++;
    });

    return wrapper;
}
