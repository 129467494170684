/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.1.18
 */

export function parseResponse(responseText, cType = "application/json") {

    switch (true) {
        case /application\/json/.test(cType):
            return JSON.parse(responseText);

        default:
            console.warn("Unknown mime type in parseResponse(), fallback to plain/text", cType);
        //fallthrough
        case /plain\/text/.test(cType):
            return responseText;
    }
}
