/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 4.9.17
 */

import io from "socket.io-client";
import { sessionStore } from "./../stores";

export default class SocketIOClient {

    constructor(config, events) {

        this.socket = null;
        this.user = sessionStore.getSession();
        this.config = config;
        this.events = events;

        this.establishConnection = this.establishConnection.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.toggleConnection = this.toggleConnection.bind(this);


        sessionStore.addChangeListener(this.handleStoreChange);

        this.toggleConnection();

    }


    handleStoreChange() {
        this.user = sessionStore.getSession();
        this.toggleConnection();
    };

    establishConnection() {
        if (!this.socket) {
            this.socket = io(this.config.url);
            this.socket.on("connect", () => {
                console.log("SOCKET CONNECTED");
            });
            this.socket.on("disconnect", () => {
                console.log("SOCKET DISCONNECTED");
            });
            Object.keys(this.events).forEach(key => {
                this.socket.on(key, this.events[key]);
            });
        }
    }

    disconnect() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    }

    toggleConnection() {
        if (this.user && this.user.isLogged) this.establishConnection();
        if (false === this.user.isLogged) this.disconnect();
    }

    getSocket() {
        return this.socket;
    }
}
