/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.11.17
 * Time: 11.24
 */

import AbstractGalleryStore from "../../gallery2/abstract-gallery-store";
import {
    GALLERY_ENTER_PARAMS,
    GALLERY_FILTER_LOAD_ARTISTS_SUCCESS,
    GALLERY_FILTER_LOAD_SERVICES_SUCCESS,
    GALLERY_FILTER_PRESET,
    GALLERY_FILTER_RESET,
    LOAD_SESSION_SUCCESS,
    PROFILE_COMPLETENESS_UPDATE,
    PROFILE_GALLERY_ITEM_REMOVED,
    PROFILE_GALLERY_SALON_INFO_LOADED
} from "../../../constants";
import {
    GALLERY2_PROFESSIONAL_PROFILE_GALLERY_ID,
    MODE_ARTIST_LOCKED,
    MODE_ARTIST_SELECTABLE,
    PROFILE_FULLY_INCOMPLETE
} from "../../../frizo-shared-constants";
import { loadGallery } from "../../../ac/gallery/generic-gallery-actions";

export default class ProfessionalProfileGalleryStore extends AbstractGalleryStore {

    constructor(...args) {
        super(GALLERY2_PROFESSIONAL_PROFILE_GALLERY_ID, ...args);

        this.setGenericFilterAware(true);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case LOAD_SESSION_SUCCESS:
                    this.waitFor(this.stores.session.getDispatchToken());
                    let session = this.stores.session.getStore().session;

                    if (session.isLogged) {

                        if (session.master) {
                            this.store.gallery.params.mode = MODE_ARTIST_LOCKED;
                            this.store.gallery.params.artistId = session.id;
                        }
                        else {
                            this.store.gallery.params.mode = MODE_ARTIST_SELECTABLE;
                        }
                    }

                    break;

                case PROFILE_GALLERY_ITEM_REMOVED:
                    if (!payload || payload.galleryId !== this.galleryId) return true;

                    this.reloadGallery();

                    break;

                case PROFILE_GALLERY_SALON_INFO_LOADED:
                    this.resetGallery({
                        salonId: payload.salonInfo.id,
                        salonInfo: payload.salonInfo,
                        filter: {},
                        offset: 0,
                        count: 12,

                        galleryItemsCount: payload.salonInfo.galleryItemsCount
                    });

                    break;

                case GALLERY_FILTER_RESET:
                    if (!payload || payload.galleryId !== this.galleryId) return true;
                    if (this.store.gallery.loading) return false;

                    let filterParams = {
                        categoryId: null,
                        serviceId: null
                    };

                    if (this.store.gallery.params.mode !== MODE_ARTIST_LOCKED) {
                        filterParams["artistId"] = null;
                    }

                    this.reloadGallery(filterParams);

                    break;

                case GALLERY_FILTER_PRESET:
                    if (!payload || payload.galleryId !== this.galleryId) return true;

                    Object.keys(this.store.filter).forEach(key => {
                        if (payload[key]) {
                            this.store.filter[key] = Number(payload[key]);
                        }
                    });

                    break;


                case GALLERY_ENTER_PARAMS:
                    if (!payload || payload.galleryId !== this.galleryId) return true;

                    let filter = Object.assign({}, this.store.gallery.params);
                    if (payload.params.artistId) {
                        filter.artistId = Number(payload.params.artistId);
                        filter.categoryId = 0;
                        filter.serviceId = 0;
                    }
                    else {
                        if (payload.params.categoryId) {
                            filter.categoryId = Number(payload.params.categoryId);
                            filter.serviceId = 0;
                        }
                        if (payload.params.serviceId) {
                            filter.serviceId = Number(payload.params.serviceId);
                        }
                    }
                    this.reloadGallery(filter);
                    this.updateFilter();

                    break;


                case PROFILE_COMPLETENESS_UPDATE:
                    this.waitFor(this.stores.profileCompletenessStore.getDispatchToken());
                    let status = this.stores.profileCompletenessStore.getStore().status;

                    if (this.store.gallery.params.mode === MODE_ARTIST_LOCKED) {
                        this.store.gallery.params.artistsAvailable = true;
                    }
                    else {
                        this.store.gallery.params.artistsAvailable = status.tabArtists.status !== PROFILE_FULLY_INCOMPLETE;
                    }
                    this.store.gallery.params.servicesAvailable = status.tabServices.status !== PROFILE_FULLY_INCOMPLETE;
                    this.store.galleryEmpty = status.tabGallery.status === PROFILE_FULLY_INCOMPLETE;

                    break;

                case GALLERY_FILTER_LOAD_ARTISTS_SUCCESS:

                    let haveImageService = false;
                    let artists = this.store.gallery.params.artistId ? payload.artists.filter(el => el.id === this.store.gallery.params.artistId) : payload.artists;
                    for (let i = 0; i < artists.length; i++) {
                        for (let j = 0; j < artists[i].services.length; j++) {
                            let service = artists[i].services[j];
                            if (service.canHaveImage) {
                                haveImageService = true;

                                break;
                            }
                        }

                        if (haveImageService) break;
                    }
                    this.store.haveImageService = haveImageService;
                    this.store.haveArtists = payload.artists.length > 0;
                    this.store.artistsLoaded = true;
                    this.store.haveServices = artists.map(item => item.services)[0].length > 0;

                    return true;

                case GALLERY_FILTER_LOAD_SERVICES_SUCCESS:
                    this.store.servicesLoaded = true;

                    return true;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialGallery() {
        return Object.assign(super.getInitialGallery(), {
            totalIsKnown: false,
            params: {
                offset: 0,
                count: 12,
                mode: MODE_ARTIST_SELECTABLE,
                salonId: null,

                artistId: null,
                categoryId: null,
                serviceId: null,

                salonInfo: null
            }
        });
    }

    getInitialStore() {
        return Object.assign(super.getInitialStore(), {
            haveImageService: false,
            haveArtists: false,
            haveServices: false,
            galleryEmpty: true,

            artistsLoaded: false,
            servicesLoaded: false
        });
    }

    nextPage() {
        this.store.gallery.params.offset++;
        this.store.gallery.loading = true;
        this.store.gallery.scrollMode = true;

        return loadGallery(this.galleryId, this.store.gallery.params);
    }
}
