/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.11.17
 * Time: 14.50
 */

import type { TGalleryImage } from "../../../types";
import { GALLERY_ITEM_TYPE_AVATAR, TYPE_GALLERY_IMAGE } from "../../../frizo-shared-constants";
import type { TRawArtistLegacy } from "../../raw-types";
import { artistServiceNormalizer } from "../../service";
import { artistNormalizerLegacy } from "../../user-derived-forms/artist";

export function singleAvatarNormalizer(artist: TRawArtistLegacy): TGalleryImage {
    return {
        id: artist.artistID,
        entityType: TYPE_GALLERY_IMAGE,
        galleryItemType: GALLERY_ITEM_TYPE_AVATAR,
        image: artist.image,
        shareUrl: artist.shareUrl,
        artist: artistNormalizerLegacy(artist),
        artistServices: artist.artistServices.map(artistServiceNormalizer)
    };
}
