/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.17.3
 * Time: 11:50
 */

import React, {Component} from 'react';
import {Button, Col} from 'react-bootstrap';

/**
 *
 * @param props
 * @returns {*}
 */

export default class GalleryMoreButton extends Component {

    render(){
        if (this.props.show)  {
            return <Col className="text-center" sm={12}>
                <Button className="fr-btn-more"  onClick={this.props.onClick}>MORE</Button>
            </Col>;
        }

        return null;
    }
}

GalleryMoreButton.defaultProps = {
    show: true,
    onClick: () => false
};
