/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 15.11.17
 * Time: 10.59
 *
 * @flow
 *
 */

import React from 'react';

import {Img} from "../../../elements/img";
import type {TGalleryImage} from "../../../types";
import {IMAGE_SIZE_LARGE, IMAGE_SIZE_MEDIUM, IMAGE_SIZE_SMALL} from "../../../frizo-shared-constants";
import noPhoto from './../../../../../img/frizo-no-photo-medium.png';
import getImageTitle from '../../../helper/get-image-title';

type Props = TGalleryImage & {
    onClick? : Function,
    blockInflection: boolean,
    size: IMAGE_SIZE_LARGE | IMAGE_SIZE_MEDIUM | IMAGE_SIZE_SMALL,
};

export default function GalleryItemImage(props: Props) {
    let description=getImageTitle(props);

    return <Img
        className="img-responsive"
        src={props.image ? props.image.url : noPhoto}
        onClick={props.onClick}
        size={props.size}
        blockInflection={props.blockInflection}
        description={description}
    />;
}

GalleryItemImage.defaultProps = {
    onClick: () => {
    },
    blockInflection: false,
    size: IMAGE_SIZE_MEDIUM
};