/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 24.7.17
 */

import React from 'react';
import {Accordion, Panel, Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ServiceRow from './service-row';
import {printCentToUsd} from "../../../helper/money-converter";

export default function ChargesPanel(props) {

    let header = 'Total: $ ' + printCentToUsd(props.total);
    return <Accordion>
        <Panel header={header} eventKey={1} collapsible>
            <div className={"title"}>
                Services
            </div>
            <div>
                {props.services.map(service => {
                    return <ServiceRow key={service.id} {...service} />
                })}
            </div>
            <Row className="hr-line">
                <Col md={7} xs={7}>
                   Service total
                </Col>
                <Col md={5} xs={5}>
                    <div className="fr-serv-price price-span"> {printCentToUsd(props.serviceTotalEntered )}</div>
                </Col>
            </Row>
            <Row>
                <Col md={7} xs={7}>
                    Tax
                </Col>
                <Col md={5} xs={5}>
                    <div className="fr-serv-price price-span">{printCentToUsd(props.tax)}</div>
                </Col>
            </Row>
            <Row>
                <Col md={7} xs={7}>
                    Service & tax
                </Col>
                <Col md={5} xs={5}>
                    <div className="fr-serv-price price-span">{printCentToUsd(props.serviceAndTax)}</div>
                </Col>
            </Row>
            <Row>
                <Col md={7} xs={7}>
                    Tip
                </Col>
                <Col md={5} xs={5}>
                    <div className="fr-serv-price price-span">{printCentToUsd(props.tip)}</div>
                </Col>
            </Row>
            <Row className="hr-line">
                <Col md={7} xs={7} className={"title"}>
                    Total
                </Col>
                <Col md={5} xs={5}>
                    <div className="fr-serv-price price-span">{printCentToUsd(props.total)}</div>
                </Col>
            </Row>
        </Panel>
    </Accordion>;
}

ChargesPanel.propTypes = {
    services: PropTypes.array,
    serviceTotalEntered: PropTypes.number,
    total: PropTypes.number,
    tax: PropTypes.number,
    serviceAndTax: PropTypes.number,
    tip: PropTypes.number,
};