/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 21.8.17
 */

import BaseStore from "./base-store";
import { PROFILE_COMPLETENESS_UPDATE } from "./../constants";


import { PROFILE_COMPLETE } from "./../frizo-shared-constants";

export default class ProfileCompletenessStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case PROFILE_COMPLETENESS_UPDATE:
                    Object.assign(this.store.origin, payload);

                    this.store.status = this.parseState(payload);

                    this.setReady(true);

                    break;

                default:
                    return true;

            }

            this.emitChange();
        });
    }

    getInitialStore() {
        return {
            origin: {},
            status: {
                profileGeneric: {
                    status: 2,
                    errors: []
                },
                profileProfessional: {
                    status: 2,
                    errors: []
                },
                profilePersonal: {
                    status: 2,
                    errors: []
                },

                tabArtists: {
                    status: 2,
                    errors: []
                },
                tabBrands: {
                    status: 2,
                    errors: []
                },
                tabGallery: {
                    status: 2,
                    errors: []
                },
                tabProfile: {
                    status: 2,
                    errors: []
                },
                tabServices: {
                    status: 2,
                    errors: []
                },
                tabWh: {
                    status: 2,
                    errors: []
                }
            }
        };
    }

    parseState(origin) {
        let status = this.store.status;
        status.profileProfessional.status = Number(origin.complete);

        if (origin.tabs.image) {
            status.profilePersonal.status = origin.tabs.image.status;
            status.profilePersonal.errors = origin.tabs.image.errors;
        }
        else {
            status.profilePersonal.status = PROFILE_COMPLETE;
        }

        status.profileGeneric.status = Math.min(
            status.profileProfessional.status,
            status.profilePersonal.status
        );

        let tabs = origin.tabs;
        Object.keys(tabs).forEach((tab) => {
            let tabName = "tab" + tab.charAt(0).toUpperCase() + tab.substr(1).toLowerCase();
            status[tabName] = tabs[tab];
            status[tabName].errors = (status[tabName].errors === null ? [] : status[tabName].errors);
        });

        status.profileGeneric.errors.concat(status.profileProfessional.errors);
        status.profileGeneric.errors.concat(status.profilePersonal.errors);

        return status;
    }
}
