/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.6.17
 * Time: 5.47
 */

import React from 'react';

export default function PaymentOrderAction(props) {

    if (props.clickable) {
        if (window.innerWidth > 769) {
            return <div className="fr-apt-inv__action">
                <button className="btn fr-btn-table fr-btn-book" style={{cursor: "pointer"}} onClick={props.onClick}>
                    Pay now
                </button>
                <label className="fr-apt-pay-label"> {props.title}</label>
            </div>
        }
        else {
            return <div>
                <button className="btn fr-btn-table fr-btn-book" style={{cursor: "pointer"}} onClick={props.onClick}>
                    Pay
                </button>
            </div>
        }
    }
    else {
        return <span>
            {props.title}
        </span>;
    }
}

PaymentOrderAction.defaultProps = {
    clickable: true
};