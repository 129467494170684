/**
 * User: Ignat Kryshchyk
 * Username: ignat
 * Company: U6 SIA
 * Date: 18.4.17
 * Time: 17.22
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {loadGallerySelectData} from './../../ac/gallery-select-actions';
import {
    MODE_ARTIST_SELECTABLE,
    MODE_ARTIST_LOCKED
} from './../../frizo-shared-constants';
import GallerySelectForm from './gallery-select-form';
import {gallerySelectStore, servicesStore} from './../../stores';
import {createDataForServicesSelects} from '../profile/professional/services/helpers';
import {loadBrandList} from "./../../ac/profile/professional/professional-profile-brands-actions";


export default class GallerySelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            artists: [],
            services: {},
        };

        this.handleServicesUpdate = this.handleServicesUpdate.bind(this);
        this.handleGallerySelectUpdate = this.handleGallerySelectUpdate.bind(this);

        this.createArtists = this.createArtists.bind(this);

        this.onArtistChange = this.onArtistChange.bind(this);
        this.onServiceChange = this.onServiceChange.bind(this);

        this.onBrandSelect = this.onBrandSelect.bind(this);
    }


    generateSalonServiceId(serviceId) {

        if (Object.keys(this.state.services).length) {
            const artistUserServicesIndex = this.state.services.artistUserServicesIndex[this.props.artistId];

            if (artistUserServicesIndex[serviceId]) {
                return artistUserServicesIndex[serviceId].salonServiceId;
            }
        }

        return 0
    }


    onArtistChange(artistId) {
        this.props.onChange({artistId, serviceId: 0, salonServiceId: 0});
    }

    onServiceChange(serviceId) {
        let salonServiceId = this.generateSalonServiceId(serviceId);
        this.props.onChange({serviceId, salonServiceId});
    }


    handleServicesUpdate() {
        this.setState({services: servicesStore.getStore()});
    }

    handleGallerySelectUpdate() {
        let data = gallerySelectStore.getStore();

        let patch = {
            artists: data.artists,
        };

        this.setState(patch);
    }

    componentDidMount() {
        servicesStore.addChangeListener(this.handleServicesUpdate);
        gallerySelectStore.addChangeListener(this.handleGallerySelectUpdate);

        let defaultsValues = (this.props.defaults ? this.props.defaults : {});
        loadGallerySelectData(this.props.salonId, this.props.artist, defaultsValues);
        loadBrandList();
    }

    onBrandSelect(brand) {
        this.props.onChange({brand: brand});
    }

    componentWillUnmount() {
        servicesStore.removeChangeListener(this.handleServicesUpdate);
        gallerySelectStore.removeChangeListener(this.handleGallerySelectUpdate);
    }


    createArtists() {
        const artistIndex = this.state.services.artistServicesIndex;

        return this.state.artists.filter(artist => {

            return artistIndex[artist.id];
        });
    }

    createGroupedServices() {
        if (!this.props.artistId) {

            return [];
        }
        let artistId = this.props.artistId;
        let serviceId = this.props.serviceId;
        let rawServices = this.state.services.services;
        let artistServices = this.state.services.artistCorrections.filter(service => service.userId === artistId);

        let servicesLeafs = [];
        artistServices.forEach(service => {
            servicesLeafs.push(Object.assign(this.state.services.servicesIndex[service.serviceId], service, {leaf: true}))
        });

        return createDataForServicesSelects(rawServices, servicesLeafs, serviceId, true);
    }


    isReady() {

        return (Object.keys(this.state.services).length && this.state.artists.length);
    }


    render() {
        if (!this.isReady()) {

            return null
        }

        return <GallerySelectForm
            artists={this.createArtists()}
            artistId={this.props.artistId}
            groupedServices={this.createGroupedServices()}
            showArtistSelector={this.props.mode === MODE_ARTIST_SELECTABLE}
            onArtistChange={this.onArtistChange}
            onServiceChange={this.onServiceChange}
            brand={this.props.brand}
            onBrandSelect={this.onBrandSelect}
        />
    }
}

GallerySelect.propTypes = {
    mode: PropTypes.oneOf([MODE_ARTIST_SELECTABLE, MODE_ARTIST_LOCKED]),
    artist: PropTypes.object,
    salonId: PropTypes.number,
    brand: PropTypes.object,
    onChange: PropTypes.func,
    defaults: PropTypes.object
};

GallerySelect.defaultProps = {
    mode: MODE_ARTIST_SELECTABLE,
    serviceId: 0,
    artistId: 0,
    brand: {},
    onChange: () => {
    }
};
