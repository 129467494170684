/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 28.05.2017
 * Time: 11:18
 */
import React, {Component} from 'react';
import {FormControl} from 'react-bootstrap';
import {whoInputChange, whoHintChange} from '../../../ac/dashboard-filter-actions';
import {dashboardWhoHintStore} from '../../../stores/';

import DashboardFilterWho from './../components/dashboard-filter-who';

export default class DashboardWho extends Component {
    constructor(...props) {
        super(...props);
        this.state = {
            searchVal: '',
            isSuggest: false,
            whoList: [],
            isDropdown: true
        };
        this.onEnter = this.onEnter.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    onEnter(e) {
        if (e.key === 'Enter') {
            this.setState({
                isSuggest: false,
                isDropdown: false
            });
            this.props.onChange({salonArtist: e.target.value});
        }
    }

    onFocus() {
        this.props.onFocus();
    }

    onInputChange(ev) {
        this.setState({
            isSuggest: true,
            isDropdown: true
        });
        whoInputChange(ev.target.value);
    }

    componentDidMount() {
        dashboardWhoHintStore.addChangeListener(this.onStoreChange);
    }

    handleKeyDown(e) {
        let hintStore = dashboardWhoHintStore.getStore(),
            searchValIndex = hintStore.filteredList.findIndex((item) => item.name === hintStore.searchVal);
        if (this.state.isDropdown && this.state.searchVal.length > 1) {
            if (e.key === 'ArrowDown') {
                this.handleKeyArrowDown(hintStore, searchValIndex);
            } else if (e.key === 'Escape') {
                this.setState({isSuggest: false});
            } else if (e.key === 'ArrowUp') {
                this.handleKeyArrowUp(hintStore, searchValIndex);
            }
        }
    }

    handleKeyArrowDown(hintStore, searchValIndex) {
        if (searchValIndex !== -1 && searchValIndex + 1 < hintStore.filteredList.length) {
            whoHintChange(hintStore.filteredList[searchValIndex + 1].name);
        } else if (searchValIndex === -1 && searchValIndex + 1 < hintStore.filteredList.length) {
            whoHintChange(hintStore.filteredList[0].name);
        }
    }

    componentWillUnmount() {
        dashboardWhoHintStore.removeChangeListener(this.onStoreChange);
    }

    handleKeyArrowUp(hintStore, searchValIndex) {
        if (searchValIndex !== -1 && searchValIndex > 0) {
            whoHintChange(hintStore.filteredList[searchValIndex - 1].name);
        }
    }

    onStoreChange() {
        this.setState({
            searchVal: dashboardWhoHintStore.getSearchVal(),
            whoList: dashboardWhoHintStore.getStore().searchList.map((item, i) => {
                item.id = i + 1;
                return item
            })
        });
    }

    onReset() {
        this.setState({
            isSuggest: false
        });
        whoInputChange('');
        this.props.onChange({salonArtist: ''});
    }

    handleFilterUpdate(filter) {
        this.props.onChange({salonArtist: filter});
    }

    render() {
        return (
            <div className={this.props.className + ' dashboard-who'}>
                <FormControl
                    value={this.state.searchVal}
                    onChange={this.onInputChange}
                    className={'dashboard-who__input'}
                    placeholder={'Salon or Artist'}
                    onFocus={this.onFocus}
                    onKeyPress={this.onEnter}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.closeDropdown}
                />

                {this.state.searchVal !== '' &&
                <button onClick={this.onReset} className="dashboard-btn__reset fr-btn pull-right"><span
                    className="glyphicon glyphicon-remove"></span></button>}
                {(this.props.isDrowdown && this.state.isSuggest && this.state.searchVal.length > 1 ) &&
                <DashboardFilterWho obtainParams={this.props.obtainParams} className="dashboard-who__input"
                                    onSelect={this.handleFilterUpdate}/>}
            </div>
        )
    }

}
