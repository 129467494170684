/**
 * Created by Eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 30-Mar-17
 * Time: 14:29
 */

import React from 'react';
import SignUp from './sign-up';
import {Row, FormGroup, Col} from 'react-bootstrap';
import Recaptcha from 'react-recaptcha';
import config from '../../config';
import Validator from '../../validation/validator';
import Violation from '../helper-components/violation';
import SignUpName from './sign-up-name';
import SignUpLoginInfo from './sign-up-login-info';
import {browserHistory} from 'react-router';
import {
    updateRegistrationData,
    saveSignUpData,
    validationFail,
    initForm
} from '../../ac/sign-up';
import {Link} from 'react-router';
import PhoneInput from '../../elements/phone-input';
import {
    HRC_FIELD_GENERIC
} from '../../frizo-shared-constants';
import {clientSignUpStore} from '../../stores';
import Filtration from '../../filter/filtration';
import TrimFilter from '../../filter/trim-filter';
import analytics from './../../service/analytics';
import {events} from "../../auth/events";
import gradientFill from "../../helper/gradient-fill";
import SpinnerGif from './../../elements/spinner-gif';


class ClientSignUp extends SignUp {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            firstName: '',
            lastName: '',
            phone: '',
            password: '',
            confirmPassword: '',
            facebookID: '',
            referralCode: '',
            showRecaptchaError: false,
            recaptchaResponse: '',
            formIsValid: true,
            violations: {},
            formDisabled: false
        };

        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.onInfoChange = this.onInfoChange.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
    }

    componentDidMount() {
        clientSignUpStore.addChangeListener(this.onStoreChange);
        initForm();
    }

    componentWillUnmount() {
        clientSignUpStore.removeChangeListener(this.onStoreChange);
    }


    save() {
        this.toggleFormDisable(true);
        let hiddenReferralCode = localStorage.getItem(HRC_FIELD_GENERIC);

        saveSignUpData({
            username: this.state.userName,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phone,
            password: this.state.password,
            facebookID: '',
            referralCode: this.state.referralCode,
            hiddenReferralCode: hiddenReferralCode
        }).then(
            (data) => {

                events.renewingFinished.dispatch(true, data.response, {
                    groups: data.options.groups,
                    request: data.request
                });

                events.login.dispatch(true, data.response, {
                    groups: data.options.groups,
                    request: data.request
                });

                localStorage.removeItem(HRC_FIELD_GENERIC);

                analytics.track('client_signup_success');

                if (this.props.location.state && this.props.location.state.callback) {

                    let callback = this.props.location.state.callback;

                    browserHistory.replace({
                        pathname: '/'
                    });

                    setTimeout(function () {
                        callback();
                    }, 500);
                }
                else {

                    browserHistory.replace({
                        pathname: '/'
                    });
                }

            },
            (data) => {
                validationFail({
                    violations: data.violations,
                    formIsValid: data.formIsValid,
                    formDisabled: false
                });
                this.resetRecaptcha();
            });
    }

    onStoreChange() {
        let state = Object.assign(this.state, clientSignUpStore.getStore());
        this.setState(state);
    }

    onInfoChange(field, value) {
        updateRegistrationData({[field]: value})
    }

    handlePhoneChange(e) {
        updateRegistrationData({phone: e.target.rawValue});
    }

    handleCodeChange(e) {
        updateRegistrationData({referralCode: e.target.value});
    }

    filter() {
        const filtration = new Filtration();
        const trim = new TrimFilter();

        filtration.registerFilter('userName', trim);
        filtration.registerFilter('firstName', trim);
        filtration.registerFilter('lastName', trim);

        let result = filtration.filtrate(this.state);

        return Promise.resolve(result);
    }


    buildValidation() {
        let validator = new Validator();

        //firstName
        validator.addConstraint('firstName', 'is-required');
        validator.addConstraint('firstName', 'is-name');
        validator.addConstraint('firstName', 'length', {
            min: 2,
            max: 50
        });

        //lastName
        validator.addConstraint('lastName', 'is-required');
        validator.addConstraint('lastName', 'is-name');
        validator.addConstraint('lastName', 'length', {
            min: 2,
            max: 50
        });

        //username
        validator.addConstraint('userName', 'is-required');
        validator.addConstraint('userName', 'is-email');


        //phone
        validator.addConstraint('phone', 'is-required');
        validator.addConstraint('phone', 'is-number');
        validator.addConstraint('phone', 'length', {
            max: 10,
            min: 10
        });

        //password
        validator.addConstraint('password', 'is-required');
        validator.addConstraint('password', 'is-password');
        validator.addConstraint('password', 'length', {
            max: 20,
            min: 5
        });

        //confirmPassword
        validator.addConstraint('confirmPassword', 'is-password-match');

        return validator;
    }

    componentDidUpdate(prevState) {
        if (prevState.violations !== this.state.violations) {
            let violations = this.state.violations;
            if (Object.keys(violations)[0] !== undefined) {
                let failedElementId = Object.keys(violations)[0];
                if (failedElementId !== null) {
                    document.getElementById(failedElementId).scrollIntoView({behavior: "smooth"});
                }
                else {
                    document.getElementById('topSignUp').scrollIntoView({behavior: "smooth"});
                }
            }
        }
    }

    render() {

        return (
            <div>
                <div id="topSignUp" className="fr-banner-top__margin--mobile">
                    <div className="fr-banner-top fr-full-width" style={{'background': gradientFill()}}>
                        <Row className="fr-container-width">
                            <Col xs={12} className="fr-banner-top__text--left ">
                                <h1>Client Sign up</h1>
                            </Col>
                        </Row>
                    </div>
                </div>
                <form name="client_sign_up" method="post" noValidate="novalidate" className="form-horizontal fr-masked">
                    <fieldset disabled={this.state.formDisabled}>
                    <Row>
                        <div className="col-md-6">

                            <SignUpName violations={this.state.violations}
                                        formIsValid={this.state.formIsValid}
                                        onChange={this.onInfoChange}/>

                            <SignUpLoginInfo violations={this.state.violations}
                                             formIsValid={this.state.formIsValid}
                                             onChange={this.onInfoChange}/>

                        </div>

                        <div className="col-md-6">
                            <h2>Phone</h2>
                            <FormGroup>
                                <div className="col-sm-12" id="phone">
                                    <PhoneInput className="form-control" type="text"
                                                value={this.state.phone}
                                                placeholder="Phone"
                                                required="required"
                                                name={"phone"}
                                                autoComplete="off"
                                                mask='(111) 111-1111'
                                                onChange={this.handlePhoneChange}
                                    />
                                    <Violation violationSet={this.state.violations.phone}
                                               visible={!this.state.formIsValid}/>
                                </div>
                            </FormGroup>

                            <h2>Referral code</h2>
                            <FormGroup>
                                <div className="col-sm-12">
                                    <input id="referralCode" type="text" name="referralCode"
                                           onChange={this.handleCodeChange}
                                           className="form-control" placeholder="Referral code (optional)"/>
                                    <Violation violationSet={this.state.violations.referralCode}
                                               visible={!this.state.formIsValid}/>
                                </div>
                            </FormGroup>

                            <div className="form-disclaimer">
                                By pressing Sign up you agree with&nbsp;
                                <Link to="/client-sign-up/terms-for-clients">User agreement</Link> and&nbsp;
                                <Link to="/client-sign-up/privacy-policy">Privacy policy</Link>
                            </div>

                            <FormGroup>
                                <div className="col-sm-8 col-xs-8">
                                    <Recaptcha
                                        sitekey={config.googleRecaptchaSiteKey}
                                        render="explicit"
                                        ref="recaptchaInstance"
                                        verifyCallback={this.verifyReCaptureCallback}
                                        onloadCallback={this.onLoadReCaptchaCallback}
                                    />
                                    <Violation violationSet={['Captcha is required']}
                                               visible={this.state.showRecaptchaError}/>
                                </div>
                                <div className="col-sm-4  col-xs-4">
                                    {this.state.formDisabled && <SpinnerGif/>}
                                    <button type="submit" name="sign_up_button" onClick={this.process}
                                            disabled={this.state.formDisabled}
                                            className="btn center-block fr-mt fr-btn pull-right">Sign up
                                    </button>
                                </div>
                            </FormGroup>
                        </div>
                    </Row>
                    </fieldset>
                </form>

            </div>

        );
    }
}

export default ClientSignUp;