/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 15.11.17
 * Time: 11.05
 *
 * @flow
 *
 */

import React from 'react';
import type { Element } from 'react'
import type {TGalleryImage} from "../../../types";

type TProps = TGalleryImage & {
    children?: Element<any>
}

export default function GalleryItemCardInfo(props: TProps)  {
    return <div className="fr-card-info">
        {props.children}
    </div>
}
