/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.28.3
 * Time: 15:58
 */

import drawTime from './../../../../helper/draw-time';

export class ServiceListCreator {

    constructor(services, id = 0) {

        this.services = services;
        this.servicesIndex = {};
        this.id = id;

        this.updateServiceIndex();

        this.createTree = this.createTree.bind(this);

    }

    updateServiceIndex() {
        this.services.forEach(service => {
            this.servicesIndex[service.id] = service;
        });
    }

    createParentsBranch(servicesList, serviceId = 0) {

        if (!serviceId) {
            let list = this.getSiblings(servicesList);
            serviceId = list[0].id;
        }

        let result = [];
        let service = this.servicesIndex[serviceId];
        let _go = true;
        if (!service) {
            service = servicesList.find(service => service.serviceId === serviceId);
        }

        do {
            result.push(service.id);
            if (!service.parentId) {
                _go = false;
            }
            else {
                service = this.servicesIndex[service.parentId];
            }
        } while (_go);

        return result.reverse();
    }

    createChildrenBranch(servicesList, serviceId = 0) {

        let children = [];

        if (!serviceId) {
            let list = this.getSiblings(servicesList);
            serviceId = list[0].id;
        }

        if (serviceId) {
            let service = this.servicesIndex[serviceId];

            while (!service.leaf) {
                let list = this.getChildren(servicesList, service.id);
                service = list[0];
                children.push(service.id);
            }
        }

        return children;
    }


    createBranch(servicesList, serviceId = 0) {

        return [...this.createParentsBranch(...arguments), ...this.createChildrenBranch(...arguments)];
    }


    getSiblings(services, parentId = 0) {
        let list = services.filter(service => service.parentId === parentId);
        return list.sort((a, b) => a.num - b.num);
    }

    getChildren(services, serviceId) {

        let list = services.filter(service => service.parentId === serviceId);
        return list.sort((a, b) => a.num - b.num);
    }


    getServicesIndex() {
        return this.servicesIndex;
    }

    createList() {

        const branch = this.createBranch(this.services, this.id);

        let dataLists = [];

        branch.forEach(id => {
            const service = this.servicesIndex[id];
            const list = this.getSiblings(this.services, service.parentId);
            dataLists.push({list: list, selectedId: service.id});
        });

        return dataLists;
    }

    createFilterServicesList() {
        const branch = this.createParentsBranch(this.services, this.id);
        let children = {};
        if (this.id) {
            const selectedService = this.servicesIndex[this.id];
            if (selectedService) {
                if (!selectedService.leaf) {
                    let options = this.getChildren(this.services, this.id);
                    let selectedId = 0;
                    // if (options.length === 1) {
                    //     selectedId = options[0].id;
                    // }
                    children = {
                        options: options,
                        selected: selectedId,
                        isCategory: false,
                        placeholder: 'Select service'
                    }
                }
            }
        }
        let dataLists = [];

        branch.forEach((id, index) => {

            const service = this.servicesIndex[id];
            const list = this.getSiblings(this.services, service.parentId);
            let selected = !this.id ? 0 : service.id;
            let isCategory = (index === 0);
            let placeholder = index === 0 ? 'Select category' : 'Select service';

            dataLists.push({options: list, selected: selected, isCategory, placeholder});
        });

        if (Object.keys(children).length) {
            dataLists.push(children);
        }

        return dataLists;
    }

    createTree(parentId = 0) {

        let tree = this.getChildren(this.services, parentId);

        tree.forEach(service => {
            if (!service.leaf) {
                service.children = this.createTree(service.id);
            }
        });

        return tree;
    }

}

export function updateIndex(elementsArray) {
    let index = {};

    elementsArray.forEach(element => {
        index[element.id] = element;
    });

    return index;
}

export function createIntervalsList(optionsData, limit = 0) {
    if (limit) {
        optionsData = optionsData.filter(option => option.value <= limit);
    }


    return optionsData.sort((a, b) => a.value - b.value).map(option => {

        return {id: option.id, name: drawTime(option.value)};
    });
}

export function getIdbyValue(list, value) {
    let item = list.find(elem => elem.value === value);
    return Number(item.id);
}

export function isLeaf(element, list) {
    let children = list.filter(elem => elem.parentId === element.id);
    return !(!!children.length)

}

export function findLeafs(services) {
    let leafs = [];
    services.forEach(service => {
        if (isLeaf(service, services)) {
            leafs.push(service);
        }
    });

    return leafs;
}

export function getRoot(servicesIndex, serviceId) {
    let go = true;
    let root = {};
    let id = serviceId;
    do {
        root = servicesIndex[id];
        if (root.parentId) {
            id = root.parentId;
        }
        else go = false;
    }
    while (go);

    return root;
}

export const objectToPlain = function (obj) {

    if (!Object.keys(obj).length) {
        return [];
    }

    return Object.keys(obj).map(key => {
        key = parseInt(key, 10);

        return obj[key]
    });
};


const makeServicesHip = function (rawServices, listOfLeafs) {
    let leafsIndex = updateIndex(listOfLeafs);
    let rawIndex = updateIndex(rawServices);
    let servicesIds = new Set();
    let services = [];
    const listCreator = new ServiceListCreator(rawServices);
    listOfLeafs.forEach(leaf => {
        let branch = listCreator.createParentsBranch(rawServices, leaf.id);
        branch.forEach(id => {
            servicesIds.add(id);
        });
    });

    servicesIds.forEach((id) => {
        if (leafsIndex[id]) {
            services.push(Object.assign(rawIndex[id], leafsIndex[id]));
        }
        else {
            services.push(rawIndex[id]);
        }
    });

    return services;
};

function checkOneParent(list) {
    let result = true;
    if (list.length > 0) {
        let parentId = list[0].parentId;
        list.forEach((item) => {
            result = item.parentId === parentId;
        });
    }

    return result;
}


export const createDataForServicesSelects = function (rawServices, listOfLeafs, serviceId = 0, preselect = false) {

    let selectedService = serviceId;

    if (!serviceId && preselect) {
        if (checkOneParent(listOfLeafs)) {
            selectedService = listOfLeafs[0].parentId
        }
    }

    let imagesLeaf = listOfLeafs.filter(leaf => leaf.image === true);
    let hip = makeServicesHip(rawServices, imagesLeaf);

    const listCreator = new ServiceListCreator(hip, selectedService);

    return listCreator.createFilterServicesList(hip, selectedService);

};
