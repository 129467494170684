/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.02.2018
 * Time: 10:35
 */

import React from "react";
import { Col, Row } from "react-bootstrap";
/* import { Link } from "react-router"; */

export default function SubscriptionBanner() {

    return (
        <Row className="fr-banner-main--landing pricing">
            <Col xs={12} md={12}>
                <h1>It's not just free. We pay you!</h1>
                {/*<Link to="/why-frizo">Tell me more</Link>*/}
            </Col>
        </Row>
    );
}
