/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.29.6
 * Time: 14:29
 */

import BaseStore from "./base-store";
import {
    DASHBOARD_WHO_HINT_CHANGE,
    DASHBOARD_WHO_HINT_LIST_LOADED,
    DASHBOARD_WHO_INPUT_CHANGE,
    GALLERY_RESET
} from "../constants";

export default class DashboardWhoHintStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            searchVal: "",
            searchList: [],
            filteredList: [],
            listLoaded: false
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case DASHBOARD_WHO_HINT_LIST_LOADED: {
                    this.store.searchList = payload;

                    break;
                }

                case DASHBOARD_WHO_INPUT_CHANGE: {
                    if (!payload) {
                        this.store.filteredList = [];
                        this.store.searchVal = "";
                    }
                    else {
                        this.store.searchVal = payload;

                        this.store.filteredList = this.store.searchList.filter(item => {
                            let name = item.name.toLowerCase();

                            return (item.normalName.indexOf(payload.toLowerCase()) > -1 || name.indexOf(payload.toLowerCase()) > -1);
                        });
                    }

                    break;
                }

                case DASHBOARD_WHO_HINT_CHANGE: {
                    this.store.searchVal = payload;

                    break;
                }
                case GALLERY_RESET:
                    this.store.filteredList = [];
                    this.store.searchVal = "";

                    break;
                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getSearchList() {

        return this.store.searchList;
    }

    getFilteredList() {

        return this.store.filteredList;
    }

    getSearchVal() {

        return this.store.searchVal;
    }
}
