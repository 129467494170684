/**
 * User: Ignat Kryshchyk
 * Username: ignat
 * Company: U6 SIA
 * Date: 23.5.17
 * Time: 9.36
 */

import BaseStore from "./base-store";

import { DASHBOARD_FILTER_SERVICE_TREE } from "./../constants";
import { MODAL_CHANGE, MODAL_HIDE } from "../constants";

export default class DashboardFilterStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            serviceTree: [],
            btnGoTopVisible: false
        };

        this._registerActionSubscription(action => {
            const { type, payload } = action;

            switch (type) {
                case DASHBOARD_FILTER_SERVICE_TREE:
                    this.store.serviceTree = payload;
                    break;
                case MODAL_CHANGE:
                    this.store.btnGoTopVisible = false;
                    break;
                case MODAL_HIDE:
                    this.store.btnGoTopVisible = true;
                    break;
                default:
                    return true;
            }

            this.emitChange();
        });
    }

    resetStore() {
        this.store = {
            serviceTree: []
        };
    }
}
