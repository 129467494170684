/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.3.17
 * Time: 17.36
 */

import AppDispatcher from "../../../dispatcher";
import { sessionStore } from "./../../../stores/index";
import {
    PROFESSIONAL_PROFILE_LOAD_SALON_INFO_SUCCESS,
    PROFESSIONAL_PROFILE_WH_OPEN,
    PROFESSIONAL_PROFILE_WH_PERSIST,
    PROFESSIONAL_PROFILE_WH_SELECT_ARTIST,
    PROFESSIONAL_PROFILE_WH_UPDATE
} from "../../../constants";
import WHSyncer from "./../../../sync/profile/professional/wh-syncer";
import xhrLoadSalonInfoByArtist from "../../../xhr/salon-info-xhr";

export function openTab() {

    sessionStore.getReadyPromise().then(store => {

        xhrLoadSalonInfoByArtist(sessionStore.getUserId()).then(salonInfo => {
            AppDispatcher.dispatch({
                type: PROFESSIONAL_PROFILE_LOAD_SALON_INFO_SUCCESS,
                payload: salonInfo
            });
        });

        AppDispatcher.dispatch({
            type: PROFESSIONAL_PROFILE_WH_OPEN,
            payload: {
                artistId: sessionStore.getUserId()
            }
        });

    });

}

export function updateSchedule(artistId, day, patch) {
    AppDispatcher.dispatch({
        type: PROFESSIONAL_PROFILE_WH_UPDATE,
        payload: {
            artistId: artistId,
            day: day,
            patch: patch
        }
    });
}

/**
 * Save WH for all artists of salon
 * @param salonId
 */
export function persistAll(salonId = null) {
    let syncer = new WHSyncer();

    AppDispatcher.dispatch({
        type: PROFESSIONAL_PROFILE_WH_PERSIST,
        payload: {
            salonId: salonId,
            syncer: syncer
        }
    });
}

/**
 * Save WH for currently selected artist
 * @param artistId
 * @param salonId
 */
export function persistForArtist(artistId, salonId = null) {
    let syncer = new WHSyncer();

    AppDispatcher.dispatch({
        type: PROFESSIONAL_PROFILE_WH_PERSIST,
        payload: {
            salonId: salonId,
            artistId: artistId,
            syncer: syncer
        }
    });
}

/**
 * Select another artist
 * @param artistId
 */
export function selectArtist(artistId) {

    AppDispatcher.dispatch({
        type: PROFESSIONAL_PROFILE_WH_SELECT_ARTIST,
        payload: {
            artistId: artistId
        }
    });
}


export function discard() {
    openTab();
}