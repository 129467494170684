/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.9.17
 */

import { autoArray, autoHash } from "./../helper/auto-hash";

class TagBuilder {

    constructor() {
        this.tags = autoArray();
        this.tagsIndex = autoHash();

        this.exposeInstructions = {
            title: [{
                generator: "og",
                name: "og:title"
            }, {
                generator: "title",
                name: "title"
            }],
            description: [{
                generator: "og",
                name: "og:description"
            }, {
                generator: "meta",
                name: "description"
            }],
            canonical: [{
                generator: "og",
                name: "og:url"
            }, {
                generator: "link",
                name: "url"
            }],
            type: [{
                generator: "og",
                name: "og:type"
            }],
            image: [{
                generator: "og",
                name: "og:image"
            }, {
                generator: "meta",
                name: "image"
            }],
            locale: [{
                generator: "og",
                name: "og:locale"
            }, {
                generator: "meta",
                name: "locale"
            }]
        };

        this.generators = {
            og: function(name, value) {
                return {
                    tag: "meta",
                    attrs: {
                        [name]: value
                    }
                };
            },
            meta: function(name, value) {
                return {
                    tag: "meta",
                    attrs: {
                        [name]: value
                    }
                };
            },
            link: function(name, value) {
                if (name !== "url") throw new Error("Only url param supported with generator Link");

                return {
                    tag: "link",
                    attrs: {
                        rel: "canonical",
                        href: value
                    }
                };
            },
            title: function(name, value) {
                return {
                    tag: "title",
                    attrs: {
                        "%body%": value
                    }

                };
            }

        };

    }

    /**
     * {
     *   title: [{
     *      prop: value
     *   }]
     * }
     *
     * @param tagName
     * @returns {TagBuilder}
     */
    addTag(tagName) {
        this.tags.ensureHash({
            tag: tagName,
            attrs: autoArray()
        });

        this.tagsIndex.ensureArray(tagName);

        return this;
    }

    /**
     *
     * @param name
     * @returns {TagBuilder}
     */
    setFieldName(name) {
        this.tags.last().attrs.ensureHash({
            name, value: ""
        });

        this.tagsIndex[this.tags.last().tag].push(name);

        return this;
    }

    /**
     *
     * @param value
     * @returns {TagBuilder}
     */
    setFieldValue(value) {
        this.tags.last().attrs.last().value = value;

        return this;
    }

    /**
     *
     * @param body
     * @returns {TagBuilder}
     */
    setBody(body) {
        this.tags.last().body = body;
        this.tagsIndex[this.tags.last().tag].push("%body%");

        return this;
    }

    addDataParam(name, value) {

        if (!this.exposeInstructions[name]) {
            if (!this.checkTag("meta", name)) return false;
            this
            .addTag("meta")
            .setFieldName(name)
            .setFieldValue(value);

            return true;
        }


        let instructionSet = this.exposeInstructions[name];

        instructionSet.forEach(instruction => {
            let generator = this.getGenerator(instruction.generator);
            let spec = generator(instruction.name, value);

            if (this.checkTag(spec.tag, Object.keys(spec.attrs))) {
                this.addTag(spec.tag);

                Object.keys(spec.attrs).forEach(attr => {
                    if (attr === "%body%") {
                        this.setBody(spec.attrs[attr]);
                    }
                    else {
                        this.setFieldName(attr);
                        this.setFieldValue(spec.attrs[attr]);
                    }

                });

            }
        });

    }

    getGenerator(generatorName) {
        if (!this.generators[generatorName]) throw new Error("Generator " + generatorName + " doesn't exists");

        return this.generators[generatorName];
    }


    checkTag(tagName, attrs = []) {
        let existedAttrs = this.tagsIndex[tagName];

        if (!existedAttrs) return true;

        for (let i = 0; i < attrs.length; i++) {
            if (existedAttrs.indexOf(attrs[i]) !== -1) {
                return false;
            }
        }

        return true;
    }

    getTags() {
        return this.tags;
    }

    getTagIndex() {
        return this.tagsIndex;
    }
}


/**
 *
 * @returns {TagBuilder}
 */
export const tagBuilder = function() {
    return new TagBuilder();
};
