import { xhr } from "../service/api-client-2";
import { CUSTOM_HEADER_U6_GROUPS } from "../frizo-shared-constants";

export function getClientRecommendationDetailsInSecure(hash) {
    return xhr()
    .suppressNotifications()
    .get("@Q2-66-R14-ClientRelated-ClientFlow-Get-Recommendation-Details-InSecure", { hash: hash })
    .headers([{
        name: CUSTOM_HEADER_U6_GROUPS,
        value: ["basic"]
    }])
    .end()
    .then((request: Request) => {
            let { response } = request;
            return Promise.resolve(response);
        },
        (request: Request) => {
            let { response } = request;
            return Promise.reject(response.errors);
        });
}

export function getProRecommendationDetailsInSecure(hash) {
    return xhr()
    .suppressNotifications()
    .get("@Q2-66-2-R14-ClientRelated-ProFlow-Get-Recommendation-Details-InSecure", { hash: hash })
    .headers([{
        name: CUSTOM_HEADER_U6_GROUPS,
        value: ["basic"]
    }])
    .end()
    .then((request: Request) => {
            let { response } = request;
            return Promise.resolve(response);
        },
        (request: Request) => {
            let { response } = request;
            return Promise.reject(response.errors);
        });
}