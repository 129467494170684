/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.29.6
 * Time: 14:26
 */

import React, {Component} from 'react';
import {Dropdown, MenuItem} from 'react-bootstrap';
import {dashboardWhoHintStore} from '../../../stores/index';
import {whoHintChange} from '../../../ac/dashboard-filter-actions';

import idGenerator from "../../../helper/id-generator-component";

export default class DashboardFilterWho extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: true,
            searchList: dashboardWhoHintStore.getSearchList(),
            filteredList:  dashboardWhoHintStore.getFilteredList(),
            searchVal: dashboardWhoHintStore.getSearchVal(),
            listLoaded: false
        };

        this.onChange = this.onChange.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
    }

    onStoreChange() {
        let searchVal = dashboardWhoHintStore.getSearchVal();
        this.setState({
            searchList: dashboardWhoHintStore.getSearchList(),
            filteredList: dashboardWhoHintStore.getFilteredList(),
            searchVal: searchVal
        });
        // if (this.state.searchValue !== searchVal) {
        //   this.setState({active: true});
        // }
    }

    componentDidMount() {
        dashboardWhoHintStore.addChangeListener(this.onStoreChange);
    }

    componentWillUnmount() {
        dashboardWhoHintStore.removeChangeListener(this.onStoreChange);
    }

    onChange(ev) {
        this.setState({active: false});
        this.props.onSelect(ev);
        whoHintChange(ev);
    }

    createMenuItems() {
        let that = this;
        return this.state.filteredList.map((item, i) => {

            let menuItemClass = null;
            if (item.name === that.state.searchVal) {
                menuItemClass = `active`;
            }
            return (
                <MenuItem
                    className={menuItemClass}
                    key={i}
                    eventKey={item.name}
                    onSelect={that.onChange}>
                    {item.name}
                </MenuItem>
            )
        })
    }

    render() {

        if (this.state.filteredList.length && this.state.active) {
            let menuItems = this.createMenuItems();
            menuItems.push(<li key={idGenerator('dashboard-who-menu')} className={this.props.className + '--menu__disabled'}>
                <button className="btn-link" role="menuItem" value={'and more...'}>and more...</button></li>);

            return (
                <Dropdown.Menu className={this.props.className + '--menu'}>
                    {menuItems}
                </Dropdown.Menu>
            )
        }
        return null
    }
}

DashboardFilterWho.defaultProps = {
    searchVal: '',
    onChange: () => false
};
