/**
 * Created by fliak on 10.1.17.
 */

import {
    BOOK_APPOINTMENTS_LOAD_SUCCESS,
    BOOK_CLIENT_FORM_RESET,
    BOOK_INITIALS_LOADED,
    BOOK_PERSIST,
    BOOK_PERSIST_FAIL,
    BOOK_START_BOOKING,
    CLIENT_DATA_CHANGED,
    CLIENT_FOUND,
    CLIENT_MISSING,
    MODAL_HIDE
} from "../../constants";

import {
    BOOK_APPOINTMENT_EDIT,
    BOOK_APPOINTMENT_NEW,
    GENDER_NOT_SHARED,
    MODE_CLIENT,
    MODE_PROFESSIONAL
} from "../../frizo-shared-constants";

import BaseStore from "./../base-store";
import Validator from "./../../validation/validator";

class BookAptClientStore extends BaseStore {


    constructor(...args) {
        super(...args);

        this.resetStore();

        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case BOOK_START_BOOKING:
                    this.buildValidation(payload.mode);

                    if (payload.salonClient) {
                        Object.keys(this.store).forEach((key) => {
                            if (payload.salonClient[key] !== undefined) {
                                this.store[key] = payload.salonClient[key];
                            }
                        });

                        this.store.salonClientSpecified = true;
                    }

                    break;


                case CLIENT_FOUND:
                    this.store.found = true;

                    this.store.firstName = payload.firstName;
                    this.store.lastName = payload.lastName;
                    this.store.phone = payload.phone;
                    this.store.gender = payload.gender;
                    this.store.newClient = false;

                    break;

                case CLIENT_MISSING:
                    // this.store.firstName = '';
                    // this.store.lastName = '';
                    // this.store.gender = GENDER_NOT_SHARED;
                    this.store.newClient = true;

                    break;

                case CLIENT_DATA_CHANGED:
                    Object.keys(this.store).forEach((key) => {
                        if (payload[key] !== undefined) {
                            this.store[key] = payload[key];
                        }
                    });

                    break;

                case BOOK_PERSIST:
                    if (this.validate()) {
                        payload.syncer.putClientData(this.store);
                    }
                    break;

                case BOOK_PERSIST_FAIL:
                    for (let aptId in payload) {
                        let violationSet = payload[aptId];

                        Object.assign(this.store.violations, violationSet);

                    }

                    break;

                case BOOK_INITIALS_LOADED:
                    if (payload.mode === MODE_CLIENT) {
                        this.store.phone = payload.userInfo.phone;
                        this.store.firstName = payload.userInfo.firstName;
                        this.store.lastName = payload.userInfo.lastName;
                    }
                    break;

                case BOOK_APPOINTMENTS_LOAD_SUCCESS: {
                    if (payload && payload.length > 0) {
                        let { salonClient } = payload[0];
                        let { user } = salonClient;

                        Object.keys(this.store).forEach((key) => {
                            if (salonClient[key] !== undefined) {
                                this.store[key] = salonClient[key];
                            }
                        });

                        this.store = Object.assign({}, this.store, {
                            gender: user.gender ? user.gender.code : null,
                            action: BOOK_APPOINTMENT_EDIT
                        });

                        break;
                    }

                    return true;
                }

                case MODAL_HIDE: {
                    this.resetStore();
                    break;
                }

                case BOOK_CLIENT_FORM_RESET: {
                    this.resetStore();

                    break;
                }


                default:
                    return true;

            }

            this.emitChange();
        });

    }

    resetStore() {
        super.resetStore();

        Object.assign(this.store, {
            phone: "",
            firstName: "",
            lastName: "",
            gender: GENDER_NOT_SHARED,
            zip: "",
            streetAddress: "",
            action: BOOK_APPOINTMENT_NEW,
            found: false,
            newClient: false
        });
    }

    /**
     * @protected
     */
    buildValidation(mode) {
        this.validator = new Validator();

        if (mode === MODE_PROFESSIONAL) {
            this.validator.addConstraint("phone", "is-required");
            this.validator.addConstraint("phone", "is-number");
            this.validator.addConstraint("phone", "length", {
                max: 10,
                min: 10
            });
            this.validator.addConstraint("firstName", "is-required");
            this.validator.addConstraint("firstName", "is-name");
            this.validator.addConstraint("firstName", "length", {
                min: 2,
                max: 50
            });
            this.validator.addConstraint("lastName", "is-required");
            this.validator.addConstraint("lastName", "is-name");
            this.validator.addConstraint("lastName", "length", {
                min: 2,
                max: 50
            });
        }

        // this.validator.addConstraint('gender', 'is-required');
        // this.validator.addConstraint('zip', 'is-required');
        // this.validator.addConstraint('streetAddress', 'is-required');
    }

    validate() {
        let ret = super.validate();

        return ret;
    }

    violationsVisible() {
        return !!Object.keys(this.store.violations).length;
    }
}

export default BookAptClientStore;
