/**
 * Created by sn on 19.09.2016.
 * User: Zakhar Shushkevich
 * Company: U6 SIA
 */

//uncomment to enable api-client2 debug output
// import './service/api-client-2/test/console-xhr-events';

import React from "react";

import ReactDOM from "react-dom";

import { browserHistory, IndexRoute, Redirect, Route, Router } from "react-router";

import "./firebase/firebase";

import App from "./components/app";
import AppMobile from "./components/app-mobile";
import Dashboard from "./components/dashboard";

import Journal from "./components/book/journal-component";

import ProfessionalProfile from "./components/profile/professional";
import PersonalProfile from "./components/profile/personal";

import ProfessionalProfileNameAndLocation from "./components/profile/professional/name-and-location";
import ProfessionalProfileServiceTab from "./components/profile/professional/professional-profile-services-tab";

import ProfessionalProfileWH from "./components/profile/professional/wh";
import ProfessionalProfileArtists from "./components/profile/professional/professional-profile-artist";
import ProfessionalProfileArtistsAdd from "./components/profile/professional/artists-add";
import ProfessionalProfileArtistsEdit from "./components/profile/professional/artists-edit";
import ProfessionalProfileGallery from "./components/profile/professional/professional-profile-gallery";
import ProfessionalProfileGalleryAdd from "./components/profile/professional/gallery-add";
import ProfessionalProfileGalleryEdit from "./components/profile/professional/gallery-edit";
import ProfessionalProfileBrands from "./components/profile/professional/professional-profile-brands";
import ProfessionalProfileSubscription from "./components/profile/professional/professional-profile-subscription";
import ProfessionalProfilePayments from "./components/profile/professional/professional-profile-payments";

import PersonalProfileMe from "./components/profile/personal/me";
import PersonalProfileAppointments from "./components/profile/personal/appointments";
import PersonalProfileFavorites from "./components/profile/personal/favorites";
import PersonalMyCards from "./components/profile/personal/my-cards";

import SalonAppointments from "./components/appointments/salon-appointments";
import PendingAppointments from "./components/appointments/salon-appointments-pending";
import UpcomingAppointments from "./components/appointments/salon-appointments-upcoming";
import HistoryAppointments from "./components/appointments/salon-appointments-history";

import SalonPromo from "./components/salon-promo";
import SalonPromoLocation from "./components/salon-promo/salon-promo-location";
import SalonPromoGallery from "./components/salon-promo/salon-promo-gallery";
import SalonPromoServices from "./components/salon-promo/salon-promo-services";
import SalonPromoArtists from "./components/salon-promo/salon-promo-artists";
import SalonPromoBrands from "./components/salon-promo/salon-promo-brands";

import ArtistPromo from "./components/artist-promo/index";

import ClientSignUp from "./components/sign-up/client-sign-up";
import ProSignUp from "./components/sign-up/pro-sign-up";

import ClientsContainer from "./components/clients/clients-container";

import Legal from "./components/legal/legal";
import TermsClients from "./components/legal/terms-for-clients";
import TermsProfessionals from "./components/legal/terms-for-professionals";
import PrivacyPolicy from "./components/legal/privacy-policy";

import Pricing from "./components/pricing/pricing";

import FAQComponent from "./components/faq/faq-component";
import FAQClients from "./components/faq/faq-for-clients";
import FAQProfessionals from "./components/faq/faq-for-professionals";

import { modalChange } from "./ac/modals";

import { routeMatched, setFFParams } from "./ac/feature-flag-actions";

import { CONTACT_MODAL, LOGIN_MODAL } from "./constants";

import authCheck from "./auth/auth-check";
import AuthChecker from "./auth/auth-checker";

import PaymentOrdersPending from "./components/profile/personal/payment-orders/payment-orders-pending";
import PersonalAppointmentsUpcoming from "./components/profile/personal/appointments/appointments-upcoming";
import PersonalAppointmentsHistory from "./components/profile/personal/appointments/appointments-history";

import FavoritesItemsContainer from "./components/profile/personal/favorites/favorites-items-container";
import FavoritesArtistsContainer from "./components/profile/personal/favorites/favorites-artists-container";

import ClientLandingPage from "./components/landing/client-landing-page";
import ProLandingPage from "./components/landing/pro-landing-page";
import AffiliatePage from "./components/affiliate-pages/affiliate-page";
import GetClientsPage from "./components/landing/get-clients-page";


import { sessionStore } from "./stores/index";

import { awareModal } from "./service/modal/aware-modal";
import { adaptToModal } from "./service/modal/adapt-to-modal";

import config from "./config";

import "./polyfill/array.prototype.find";
import "./client-stream/web-socket-client";
import "./service/versioning";

import { getReferralCodeChecker } from "./service/hidden-referral-code";
import { HRC_REDIRECT_DISABLED } from "./frizo-shared-constants";


import { initClientStream } from "./ac/client-stream-actions";

import metadataService from "./service/meta-data-service";

import analytics from "./service/analytics";
import arrayToHash from "./helper/array-to-hash";
import { performQuickLogin } from "./ac/quick-login-ac";
import { runGTM } from "./service/google-tag-manager";

import "./ac/session";

import "./service/offline";

import "./service/facebook-sdk";

import InvitationAcceptPage from "./components/landing/invitation-accept-page";
//import ProProductsPage from "./components/pro-products/pro-products";
import ClientRecommendation from "./components/recommendation/client-recommendation";
import ProRecommendation from "./components/recommendation/pro-recommendation";

function showLogin(previous) {
    sessionStore.getReadyPromise().then(() => {
        if (!sessionStore.isLogged()) {
            modalChange(LOGIN_MODAL, {
                previous
            });
        }
    });
}

function onChange(prev, nextState, replace) {
    authCheck(nextState.routes, nextState.location.pathname);

    metadataService.matchRoute(nextState);
}

function onEnter(state) {
    metadataService.matchRoute(state);
}


function showContactUs() {
    sessionStore.getReadyPromise().then(() => {
        modalChange(CONTACT_MODAL);
    });
}

function onPageEnter() {

    routeMatched();

    analytics.trackNavigation();
    window.scrollTo(0, 0);
}


function handleRedirect(prev, replace, nextState) {
    //console.log('handleRedirect', prev)
    let fetchMP = /(.*)\/mp\/(.*)/.exec(prev.location.pathname);
    if (fetchMP) {

        let [, originUrl, paramString] = fetchMP;

        let paramChunks = paramString.split("/").filter(element => element.length > 0);

        let params = [];

        for (let i = 0; i < paramChunks.length; i = i + 2) {
            // params[paramChunks[i]] = paramChunks[i + 1]
            params.push({
                name: paramChunks[i],
                value: paramChunks[i + 1]
            });
        }

        let featureFlags = params.filter(pair => {
            return pair.name.indexOf("ff-") === 0;
        }).map(pair => {
            return {
                name: pair.name.substr(3),
                value: pair.value
            };
        });
        //console.log('featureFlags', featureFlags);

        if (featureFlags.length > 0) {
            setFFParams(arrayToHash(featureFlags, pair => {
                return [pair.name, pair.value];
            }));
        }

        let paramsPromises = [];

        params.forEach(pair => {

            switch (pair.name) {
                case "qa":
                    //console.log('QUICK AUTH TOKEN', pair.value);
                    paramsPromises.push(performQuickLogin(pair.value));

                    break;

                default:
                    break;
            }

        });

        Promise.all(paramsPromises).then(ok => {
            browserHistory.replace(originUrl);
        });
    }
}

if (!config.analytics_disable) {
    runGTM(config.gtm_id);
}

initClientStream();

// initFacebookSdk();

//loadProfileCompletenessInfo();

ReactDOM.render(
    <Router history={browserHistory} onUpdate={onPageEnter}>
        <Route component={AuthChecker} onChange={onChange} onEnter={onEnter}>

            <Route path="/" component={App}>
                <IndexRoute component={Dashboard}/>
                <Route path="/book" component={Journal} requireAuth={true}/>
                <Route path="/clients" component={ClientsContainer} requireAuth={true}/>

                <Route path="/contact-us" component={Dashboard} onEnter={showContactUs}/>

                <Redirect from="/faq" to="/faq/for-clients"/>
                <Route path="/faq" component={FAQComponent}>
                    <Route path="/faq/for-clients" tab="for-clients" component={FAQClients}/>
                    <Route path="/faq/for-professionals" tab="for-professionals" component={FAQProfessionals}/>
                </Route>

                <Redirect from="/my-salon" to="/my-salon/name-and-location"/>
                <Route path="/my-salon" requireAuth={true} component={ProfessionalProfile}>

                    <Route path="/my-salon/name-and-location" tab="name-and-location"
                           component={ProfessionalProfileNameAndLocation}/>
                    <Route path="/my-salon/services" tab="services" component={ProfessionalProfileServiceTab}/>

                    <Route path="/my-salon/artists" tab="artists" component={ProfessionalProfileArtists}/>
                    <Route path="/my-salon/artists/add" tab="artists" component={ProfessionalProfileArtistsAdd}/>
                    <Route path="/my-salon/artists/edit/:id" tab="artists" component={ProfessionalProfileArtistsEdit}/>

                    <Route path="/my-salon/working-hours" tab="working-hours" component={ProfessionalProfileWH}/>

                    <Route path="/my-salon/gallery" tab="gallery" component={ProfessionalProfileGallery}/>
                    <Route path="/my-salon/gallery/add-single-image"
                           tab="gallery"
                           component={ProfessionalProfileGalleryAdd}/>
                    <Route path="/my-salon/gallery/add-before-and-after-images"
                           tab="gallery"
                           component={ProfessionalProfileGalleryAdd}/>
                    <Route path="/my-salon/gallery/edit/:id" tab="gallery" component={ProfessionalProfileGalleryEdit}/>

                    <Route path="/my-salon/brands" tab="brands" component={ProfessionalProfileBrands}/>
                    <Route path="/my-salon/brands/add" tab="brands" component={ProfessionalProfileBrands}/>

                    <Route path="/my-salon/subscription" tab="subscription"
                           component={ProfessionalProfileSubscription}/>
                    <Route path="/my-salon/payments" tab="payments" component={ProfessionalProfilePayments}/>
                </Route>

                <Redirect from="/appointments" to="/appointments/upcoming"/>
                <Route path="/appointments" requireAuth={true} component={SalonAppointments}>
                    <Route path="/appointments/pending" component={PendingAppointments}/>
                    <Route path="/appointments/upcoming" component={UpcomingAppointments}/>
                    <Route path="/appointments/history" component={HistoryAppointments}/>
                </Route>

                <Redirect from="/me" to="/me/essentials"/>
                <Route path="/me" requireAuth={true} component={PersonalProfile}>

                    <Route path="/me/essentials" tab="essentials" component={PersonalProfileMe}/>

                    <Redirect from="/me/my-appointments" to="/me/my-appointments/upcoming"/>
                    <Route path="/me/my-appointments" tab="my-appointments" component={PersonalProfileAppointments}>
                        <Route path="/me/my-appointments/pending" tab="my-appointments"
                               component={PaymentOrdersPending}/>
                        <Route path="/me/my-appointments/upcoming" tab="my-appointments"
                               component={PersonalAppointmentsUpcoming}/>
                        <Route path="/me/my-appointments/history" tab="my-appointments"
                               component={PersonalAppointmentsHistory}/>
                    </Route>

                    <Redirect from="/me/favorites" to="/me/favorites/my-styles"/>
                    <Route path="/me/favorites" tab="favorites" component={PersonalProfileFavorites}>
                        <Route path="/me/favorites/my-styles" tab="favorites"
                               component={FavoritesItemsContainer}/>
                        <Route path="/me/favorites/my-artists" tab="favorites"
                               component={FavoritesArtistsContainer}/>
                    </Route>

                    <Route path="/me/billing" tab="billing" component={awareModal(PersonalMyCards)}/>
                </Route>

                <Route path="/log-in" component={Dashboard} requireNotAuth={true} onEnter={showLogin}/>

                <Route path="/client-sign-up" modalRelative component={awareModal(ClientSignUp)}>
                    <Route path="/client-sign-up/terms-for-clients"
                           component={adaptToModal(TermsClients, "User Agreement")}/>
                    <Route path="/client-sign-up/privacy-policy"
                           component={adaptToModal(PrivacyPolicy, "Privacy Policy")}/>
                </Route>


                <Route path="/pricing" component={Pricing}/>

                <Route path="/pro-sign-up" modalRelative component={awareModal(ProSignUp)}
                       onEnter={analytics.getTrackCallback("list_your_business")}>
                    <Route path="/pricing" component={Pricing}/>

                    <Route path="/pro-sign-up/terms-for-clients"
                           component={adaptToModal(TermsClients, "User Agreement")}/>
                    <Route path="/pro-sign-up/privacy-policy"
                           component={adaptToModal(PrivacyPolicy, "Privacy Policy")}/>
                    <Route path="/pro-sign-up/terms-for-professionals"
                           component={adaptToModal(TermsProfessionals, "Vendor Agreement")}/>
                </Route>

                <Route path="/list-your-business" modalRelative requireAuth={true} component={awareModal(ProSignUp)}
                       onEnter={analytics.getTrackCallback("list_your_business")}>
                    <Route path="/list-your-business/privacy-policy"
                           component={adaptToModal(PrivacyPolicy, "Privacy Policy")}/>
                    <Route path="/list-your-business/terms-for-professionals"
                           component={adaptToModal(TermsProfessionals, "Vendor Agreement")}/>
                </Route>

                <Redirect from="/legal" to="/legal/terms-for-clients"/>
                <Route path="/legal" component={Legal}>
                    <Route path="/legal/terms-for-clients" tab="terms-for-clients" component={TermsClients}/>
                    <Route path="/legal/terms-for-professionals" tab="terms-for-professionals"
                           component={TermsProfessionals}/>
                    <Route path="/legal/privacy-policy" tab="privacy-policy" component={PrivacyPolicy}/>
                </Route>

                <Route path="/how-it-works(/:referralCode)" component={ClientLandingPage}
                       onEnter={getReferralCodeChecker()}
                />

                <Route path="/get-clients(/:referralCode)" component={GetClientsPage}
                       onEnter={getReferralCodeChecker()}
                />

                <Route path="/why-frizo/by(/:referralCode)" component={ProLandingPage}
                       onEnter={getReferralCodeChecker(
                           "/why-frizo"
                       )}
                />

                <Route path="/why-frizo(/:referralCode)" component={ProLandingPage}
                       onEnter={getReferralCodeChecker()}
                />

                <Route path="/invitation-accept(/:referralCode)" component={InvitationAcceptPage}
                       onEnter={getReferralCodeChecker()}
                />

                <Route path="/affiliate(/:user)" component={AffiliatePage}/>


                <Redirect from="/promo/:url" to="/promo/:url/gallery"/>
                <Route path="/promo/:url" component={SalonPromo}>
                    <Route path="/promo/:url/invite/:referralCode" component={SalonPromo}
                           onEnter={getReferralCodeChecker((prev, replace) => {
                               let promoPage = /(\/promo\/[a-zA-Z0-9-]+)\//.exec(prev.location.pathname)[0];

                               replace(promoPage);

                               return promoPage;
                           })}
                    />
                    <Route path="/promo/:url/location" tab="location" component={SalonPromoLocation}/>
                    <Route path="/promo/:url/gallery" tab="gallery" component={SalonPromoGallery}/>
                    <Route path="/promo/:url/services" tab="services" component={SalonPromoServices}/>
                    <Route path="/promo/:url/artists" tab="artists" component={SalonPromoArtists}/>
                    <Route path="/promo/:url/brands" tab="brands" component={SalonPromoBrands}/>
                </Route>

                <Route path="/pro/:url" component={ArtistPromo}
                       onEnter={getReferralCodeChecker(
                           HRC_REDIRECT_DISABLED,
                           "url"
                       )}
                />

                <Route path="/recommendation(/:hash)" component={ClientRecommendation}/>
                    {/* <Route path={"/pro-products"} components={ProProductsPage}/> */}
                <Route path={"/product(/:hash)"} components={ProRecommendation}/>
            </Route>
            <Route path="/" component={AppMobile}>
                 <Route path="/recommendation/:hash" component={ClientRecommendation}/>
            </Route>

            <Route path="/*" onEnter={handleRedirect}/>

        </Route>
    </Router>, document.getElementById("react-app"));
