/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.9.11
 * Time: 15:36
 *
 * @flow
 *
 */

import AppDispatcher from "./../../../dispatcher";

import {
    PROFILE_PERSONAL_PASSWORD_CHANGE_ERROR,
    PROFILE_PERSONAL_PASSWORD_CHANGE_SUCCESS,
    PROFILE_PERSONAL_PASSWORD_FORM_CHANGE,
    PROFILE_PERSONAL_PASSWORD_FORM_CLEAR,
    PROFILE_PERSONAL_PASSWORD_FORM_PROCESS
} from "../../../constants";

import { xhrChangePassword } from "../../../xhr/personal-profile-xhr";

export function profilePasswordFormChange(patch: { name: string, value: string }): void {

    AppDispatcher.dispatch({
        type: PROFILE_PERSONAL_PASSWORD_FORM_CHANGE,
        payload: patch
    });

}

export function processChangeEmailForm(): void {

    AppDispatcher.dispatch({
        type: PROFILE_PERSONAL_PASSWORD_FORM_PROCESS
    });

}

export function clearPasswordChangeForm(): void {

    AppDispatcher.dispatch({
        type: PROFILE_PERSONAL_PASSWORD_FORM_CLEAR
    });

}


export function sendNewPasswordData(data: { oldPassword: string, newPassword: string }) {
    xhrChangePassword(data).then(
        () => {
            AppDispatcher.dispatch({
                type: PROFILE_PERSONAL_PASSWORD_CHANGE_SUCCESS
            });
        },
        () => {
            AppDispatcher.dispatch({
                type: PROFILE_PERSONAL_PASSWORD_CHANGE_ERROR
            });
        }
    );
}


