/**
 * Created by ignat
 * Name: Ignat Kryshcyk
 * Company: U6 SIA
 * Date: 25.07.2017
 * Time: 11:26
 */
import React from "react";


export default function Spinner() {
    return (
        <div className="spinner">
            <div className="spinner__block">Loading...</div>
        </div>
    );
}
