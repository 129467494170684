/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 2.1.18
 *
 * @flow
 */

import type { THeader } from "../../types";
import { XHR_READY_STATE_DONE } from "../../frizo-shared-constants";

export class Gateway {

    send(url: string, method: string, headers: Array<THeader>, body: string | FormData): Promise<XMLHttpRequest> {

        return new Promise((success) => {
            let xhr = new XMLHttpRequest();
            xhr.open(method, url, true);

            headers.forEach(({ name, value }) => {
                xhr.setRequestHeader(name, value);
            });

            xhr.send(body);

            xhr.onreadystatechange = () => {
                if (xhr.readyState === XHR_READY_STATE_DONE) {
                    success(xhr);
                }
            };
        });
    }
}
