/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 20.02.2018
 * Time: 15.48
 *
 * @flow
 */

import React, { Component } from "react";
import { Col, Glyphicon, Row } from "react-bootstrap";
import DropdownWrapper from "../../elements/dropdown-wrapper";
import type { TArtist, TService } from "../../types";

type TProps = {
    showArtistSelector: boolean,
    salonId: number,
    artistId: number,
    categoryId: number,
    serviceId: number,
    onChange: Function,
    onReset: Function,
    artists: Array<TArtist>,
    services: Array<TService>,
    categories: Array<TService>,
    showButtonAdd: boolean
};


export default class GalleryFilter extends Component<TProps> {

    static defaultProps: {};

    getArtistSelector() {
        let artistsList = this.props.artists.map(artist => {
            return { id: artist.id, name: `${artist.firstName} ${artist.lastName}` };
        });

        return <Col xs={12} md={3} className={this.props.showButtonAdd ? "fr-ml-ng" : ""}>
            <DropdownWrapper
                list={artistsList}
                placeholder={"Select artist"}
                selected={this.props.artistId}
                onChange={this.props.onChange.bind(this, "artistId")}
                className={"dropdown-gallery-filter"}
            />
        </Col>;
    }

    getCategorySelector() {

        return <Col xs={12} md={3} className="fr-mt-xs">
            <DropdownWrapper
                list={this.props.categories}
                placeholder={"Select category"}
                selected={this.props.categoryId}
                onChange={this.props.onChange.bind(this, "categoryId")}
                optionTextPropName="title"
                className={"dropdown-gallery-filter"}
            />
        </Col>;
    }

    getServiceSelector() {
        return <Col xs={12} md={3} className="fr-mt-xs fr-ml-xs">
            <DropdownWrapper
                list={this.props.services}
                placeholder={"Select service"}
                selected={this.props.serviceId}
                onChange={this.props.onChange.bind(this, "serviceId")}
                optionTextPropName="title"
                className={"dropdown-gallery-filter"}
            />
        </Col>;
    }

    getResetButton() {
        return <Col md={1} xs={1}>
            <button className="btn-link fr-glyphicon" onClick={this.props.onReset}>
                <Glyphicon glyph="refresh glyphicon-refresh__gallery"/>
            </button>
        </Col>;
    }

    render() {

        return (<Row className="fr-mb">
            {this.props.showArtistSelector && this.getArtistSelector()}
            {this.props.categories.length > 0 && this.getCategorySelector()}
            {!!this.props.categoryId && this.getServiceSelector()}
            {this.getResetButton()}

        </Row>);
    }
}

GalleryFilter.defaultProps = {
    artistLocked: false
};