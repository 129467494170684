/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 8.11.17
 * Time: 16.18
 *
 * @flow
 */

import type { TRawUser } from "../raw-types";
import type { TSession } from "../../types";
import { normalizeGender } from "../gender";
import { addressNormalize1 } from "../address";
import { salonNormalizer1 } from "../salon";
import { TYPE_USER } from "../../frizo-shared-constants";

export function sessionNormalize(rawSession: TRawUser): TSession {
    let literal: TSession = {
        entityType: TYPE_USER,
        id: rawSession.id,
        firstName: rawSession.firstName,
        lastName: rawSession.lastName,

        userName: rawSession.username,
        instagramUsername: rawSession.instagramUsername || "",
        discountTotal: rawSession.discountTotal,

        phone: rawSession.phone,

        activated: rawSession.activated,
        business: rawSession.business,
        master: rawSession.master,
        professional: rawSession.professional,
        salonAdmin: rawSession.salonAdmin,
        bookable: rawSession.isBookable,
        public: rawSession.isPublic,

        rawUserType: rawSession.rawUserType,

        legalUnsigned: rawSession.legalUnsigned
    };

    if (rawSession.address) {
        literal.address = addressNormalize1(rawSession.address);
    }

    if (rawSession.subscriptionTier) {
        literal.subscriptionTier = rawSession.subscriptionTier;
    }

    if (rawSession.image) {
        literal.image = {
            id: rawSession.image.id,
            url: rawSession.image.url
        };
    }

    if (rawSession.gender) {
        literal.gender = normalizeGender(rawSession.gender);
    }

    if (rawSession.salon && rawSession.salon.length > 0) {
        literal.salon = salonNormalizer1(rawSession.salon[0]);
    }

    return literal;
}
