/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 21.05.2017
 * Time: 12:48
 */

import React, {Component} from "react";
import ReactDOM from 'react-dom';
import moment from 'moment';
import classNames from 'classnames/bind';
import {Row, Col} from "react-bootstrap";
import config from './../../config';
import {getServiceTree} from './../../ac/dashboard-filter-actions';
import {dashboardFilterStore, dashboardGalleryStore} from './../../stores/index';
import convertSecondsToFormattedTime from './../../helper/convert-seconds-to-formatted-time';
import DashboardWhat from './elements/dashboard-what';
import DashboardWhere from './elements/dashboard-where';
import DashboardWho from './elements/dashboard-who';
import DashboardWhen from './elements/dashboard-when';
import DashboardFilterWhat from './components/dashboard-filter-what';
import GoogleMapWithMarkers from '../maps/map-with-markers';
import DashboardFilterWhen from './components/dashboard-filter-when';
import {getWhoHintList} from '../../ac/dashboard-filter-actions';
import idGenerator from "../../helper/id-generator-component";
import GoUpButton from "../../elements/go-up-button";

const MOBILE_VIEW = 768;

function FilterHeader(props) {
    return <div className="items-filter-title">{props.label}</div>
}


export default class DashboardFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            childSelectValue: '0',
            serviceTree: [],
            selectedService: -1,

            // visiable or hidden menu
            whatMenu: false,
            whereMenu: false,
            whoMenu: false,
            whenMenu: false,

            // currentAddress: 'New York, USA',
            mapCoordinates: {
                lat: config.googleMap.NY_lat,
                lon: config.googleMap.NY_lng,
                render: false
            },
            defaultMapCoordinates: {
                lat: config.googleMap.NY_lat,
                lon: config.googleMap.NY_lng,
                render: false
            },

            // where value
            whereValue: '',
            // whenMenu
            whenInputText: null,

            params: props.defaultParams,
            locations: [],

            btnUpBottom: 0,
            btnUpLeft: 0,
            btnGoTopVisible: false
        };


        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.handleWhatSelectChange = this.handleWhatSelectChange.bind(this);
        this.handleWhatSelectFocus = this.handleWhatSelectFocus.bind(this);
        this.handleParamsChange = this.handleParamsChange.bind(this);

        this.changeServicesCheckbox = this.changeServicesCheckbox.bind(this);

        this.updateMap = this.updateMap.bind(this);

        this.onWhenFocus = this.onWhenFocus.bind(this);
        this.onWhoFocus = this.onWhoFocus.bind(this);
        this.createWhenValue = this.createWhenValue.bind(this);
        this.onWhereFocus = this.onWhereFocus.bind(this);
        this.handleOpenMap = this.handleOpenMap.bind(this);
        this.updateWhereInput = this.updateWhereInput.bind(this);

        this.openMobilePanel = this.openMobilePanel.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.closeAllTabs = this.closeAllTabs.bind(this);

        this.obtainParams = this.obtainParams.bind(this);

        this.onScrollResizeHandler = this.onScrollResizeHandler.bind(this);
        this.handleGalleryStoreChange = this.handleGalleryStoreChange.bind(this);

        this.closeWhatTab = this.closeWhatTab.bind(this);
    }

    handleWhatSelectChange(e) {
        let whatValue = (e !== '0') ? e : '';
        this.setState({
            selectedService: whatValue,
            whatMenu: true,
            whenMenu: false,
            mapCoordinates: Object.assign({}, this.state.mapCoordinates, {render: false}),
        });
        this.handleParamsChange({
            categoryID: whatValue,
            serviceList: ''
        })
    }

    handleWhatSelectFocus() {
        this.setState({
            whatMenu: true,
            whenMenu: false,
            whoMenu: false,
            mapCoordinates: Object.assign({}, this.state.mapCoordinates, {render: false})
        });

    }

    handleStoreChange() {
        this.setState(Object.assign({}, dashboardFilterStore.getStore()));
    }

    handleGalleryStoreChange() {
        let locations = dashboardGalleryStore.getStore().gallery.items.map((item) => {
            return item.location
        });
        this.setState({
            locations: locations
        })
        // this.setState(Object.assign(this.state, dashboardFilterStore.getStore()));
    }

    handleOpenMap() {
        this.setState({
            whereMenu: !this.state.whereMenu,
            mapCoordinates: Object.assign({}, this.state.mapCoordinates, {render: true})
        })
    }

    closeWhatTab() {
        this.setState({whatMenu: false});
    }

    componentDidMount() {
        getServiceTree();
        getWhoHintList();
        dashboardFilterStore.addChangeListener(this.handleStoreChange);
        dashboardGalleryStore.addChangeListener(this.handleGalleryStoreChange);

        window.addEventListener('click', this.handleDocumentClick);
        window.addEventListener('scroll', this.onScrollResizeHandler);
        window.addEventListener('resize', this.onScrollResizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleDocumentClick);
        window.removeEventListener('scroll', this.onScrollResizeHandler);
        window.removeEventListener('resize', this.onScrollResizeHandler);

        dashboardFilterStore.removeChangeListener(this.handleStoreChange);
        dashboardGalleryStore.removeChangeListener(this.handleGalleryStoreChange);
    }

    onScrollResizeHandler() {
        let headerWrap= document.querySelector("#header-wrap");
        let filterWrap = document.querySelector("#dashboard-filter");
        let filterWidth = document.querySelector('#tabs-with-dropdown').getBoundingClientRect().width + 30;
        let headerHeight = document.querySelector('#header-wrap').getBoundingClientRect().height;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;


        if (scrollTop -5 >= headerHeight) {
            filterWrap.classList.add("dashboard-fix-filter");
            headerWrap.setAttribute('display','none')

        } else {
            filterWrap.classList.remove("dashboard-fix-filter");
            filterWrap.classList.add("dashboard-filter");
            headerWrap.setAttribute('display','')
        }

        filterWrap.style.width = filterWidth + 'px';
    }


    handleDocumentClick(e) {
        const dashboardFilter = ReactDOM.findDOMNode(this.refs.filter),
            whenTab = ReactDOM.findDOMNode(this.refs.whenTab),
            dashboardFilterWhen = ReactDOM.findDOMNode(this.refs.dashboardFilterWhen);

        if (!dashboardFilter.contains(e.target)) {
            this.closeAllTabs();
        }
        if (whenTab.contains(e.target) && !dashboardFilterWhen.contains(e.target)) {
            this.closeAllTabs();
        }
    }

    closeAllTabs() {
        this.setState(Object.assign({}, this.state, {
            whatMenu: false,
            mapCoordinates: Object.assign({}, this.state.mapCoordinates, {render: false}),
            whoMenu: false,
            whenMenu: false
        }));
    }


    changeServicesCheckbox(e) {
        let attributeCheck = e.target;
        let objectValue = Object.assign({}, this.state.params);
        let arr = [];
        if (attributeCheck.checked === true) {
            if (objectValue.serviceList !== "") {
                arr = objectValue.serviceList.split(',');
            }
            arr.push(attributeCheck.id);
        } else {
            let array = objectValue.serviceList.split(',');
            arr = array.filter((id) => id !== attributeCheck.id);
        }
        objectValue.serviceList = arr.join(',');
        this.setParams(objectValue);
    }

    setParams(newParams) {
        //console.log('setPArams', newParams);
        this.setState({params: newParams}, () => {
            this.props.onChange(this.state.params);
        })
    }

    updateMap(data) {
        // this.setState({})
        if (data.location !== null) {
            let mapCoordinates = data.location;
            mapCoordinates.render = true;
            this.setState({
                mapCoordinates: mapCoordinates,
                whereValue: data.value
            });
        } else {
            this.setState({
                mapCoordinates: this.state.defaultMapCoordinates,
                whereValue: data.value
            });
        }
    }

    updateWhereInput(data) {
        this.setState({
            whereValue: data
        });
    }

    onWhereFocus() {
        this.setState({
            whenMenu: false,
            whatMenu: false,
            whoMenu: false,
            mapCoordinates: Object.assign({}, this.state.mapCoordinates, {render: true})
        });
    }

    onWhoFocus() {
        this.setState({
            whenMenu: false,
            whatMenu: false,
            whoMenu: true,
            mapCoordinates: Object.assign({}, this.state.mapCoordinates, {render: false})
        });
    }

    onWhenFocus() {
        this.setState({
            whenMenu: true,
            whatMenu: false,
            whoMenu: false,
            mapCoordinates: Object.assign({}, this.state.mapCoordinates, {render: false})
        });
    }

    handleParamsChange(data) {
        let newParams = Object.assign({}, this.state.params, data);
        this.setParams(newParams);
    }

    createWhenValue() {
        let date = (this.state.params.date !== '') ? moment.unix(this.state.params.date).format(config.defaultDateTextFormat) : '';
        let fromTo = ((this.state.params.from !== 0) || (this.state.params.to !== 86400)) ? convertSecondsToFormattedTime(this.state.params.from) + ' - ' + convertSecondsToFormattedTime(this.state.params.to) : '';

        if (date === '' && fromTo === '') {
            return ''
        } else if (date === '' && fromTo !== '') {
            return fromTo
        } else if (date !== '' && fromTo === '') {
            return date
        }
        return `${date}, ${fromTo}`
    }

    openMobilePanel(e) {
        let mobile = (window.innerWidth < MOBILE_VIEW);
        let attrName = e.target.classList.contains('main-tab__item') ? e.target.attributes.getNamedItem('data-tab').value : e.target.parentElement.attributes.getNamedItem('data-tab').value,
            newState = Object.assign({}, this.state, {
                whatMenu: false,
                mapCoordinates: Object.assign({}, this.state.mapCoordinates, {render: false}),
                whoMenu: false,
                whenMenu: false
            });

        switch (attrName) {
            case 'what-tab':
                if ((this.state.selectedService !== -1) || mobile) {
                    newState.whatMenu = !this.state.whatMenu;
                }

                break;
            case 'where-tab':
                newState.mapCoordinates = Object.assign({}, this.state.mapCoordinates, {render: !this.state.mapCoordinates.render});

                break;
            case 'who-tab':
                newState.whoMenu = !this.state.whoMenu;

                break;
            case 'when-tab':
                newState.whenMenu = !this.state.whenMenu;
                break;

            default:
                throw new Error("Unknown tab " + attrName);
        }

        this.setState(newState);
        // }
    }

    obtainParams(data) {
        this.setState({whoMenu: false});
        this.handleParamsChange({
            salonArtist: data
        })
    }

    render() {

        let mobile = (window.innerWidth < MOBILE_VIEW);

        let whatMenuClasses = classNames({
            'tab-pane': true,
            'active': this.state.whatMenu && (this.state.selectedService !== -1 || mobile)
        });
        let whereMenuClasses = classNames({
            'tab-pane': true,
            'active': this.state.mapCoordinates.render
        });
        let whoMenuClasses = classNames({
            'tab-who': true,
            'tab-pane': true,
            'active': this.state.whoMenu
        });
        let whenMenuClasses = classNames({
            'tab-pane': true,
            'clearfix': true,
            'active': this.state.whenMenu
        });


        let whatActive = this.state.whatMenu ? ' active' : '';
        let whereActive = this.state.mapCoordinates.render ? ' active' : '';
        let whoActive = this.state.whoMenu ? ' active' : '';
        let whenActive = this.state.whenMenu ? ' active' : '';

        let whenValue = this.createWhenValue();

        return (

            <div ref="filter" id="tabs-with-dropdown">
                <Row className="clearfix grid dashboard-filter" id="dashboard-filter">
                    <Col sm={12}>
                        <ul role="tablist" className="fr-main-tabs fr-main-tabs-fixed row nav nav-tabs">
                            <li role="presentation" className="main-tab dropdown col-xs-3"
                                onFocus={this.handleWhatSelectFocus}>
                                <div className={'main-tab__item ' + whatActive} data-tab="what-tab"
                                     onClick={this.openMobilePanel}>
                                    <FilterHeader label="What"/>
                                </div>
                                <DashboardWhat
                                    data={this.state.serviceTree}
                                    onFocus={this.handleWhatSelectFocus}
                                    onChange={this.handleWhatSelectChange}
                                    selected={this.state.selectedService}
                                    className={'hidden-xs'}/>
                            </li>
                            <li role="presentation" className="main-tab dropdown col-xs-3">
                                <div className={'main-tab__item ' + whereActive} data-tab="where-tab"
                                     onClick={this.openMobilePanel}>
                                    <FilterHeader label="Where"/>
                                </div>
                                <DashboardWhere
                                    isWhereTabActive={this.state.mapCoordinates.render}
                                    defaultValue={this.state.whereValue}
                                    onLocationChange={this.updateMap}
                                    openMap={this.handleOpenMap}
                                    onEnter={this.handleParamsChange}
                                    onFocus={this.onWhereFocus}
                                    onInputChange={this.updateWhereInput}
                                    className={'hidden-xs'}
                                    idInput={'dropdown-input'}
                                    idIsNearest={'isNearest'}
                                    idGeocodingForm={idGenerator("geocoding_form")}
                                />
                            </li>
                            <li role="presentation" className="main-tab dropdown col-xs-3">
                                <div className={'main-tab__item ' + whoActive} data-tab="who-tab"
                                     onClick={this.openMobilePanel}>
                                    <FilterHeader label="Who"/>
                                </div>
                                <DashboardWho
                                    className={'hidden-xs'}
                                    obtainParams={this.obtainParams}
                                    isDrowdown={this.state.whoMenu}
                                    onFocus={this.onWhoFocus}
                                    onChange={this.handleParamsChange}
                                />
                            </li>
                            <li role="presentation" className="main-tab dropdown toggle-calendar col-xs-3">
                                <div className={'main-tab__item ' + whenActive} data-tab="when-tab"
                                     onClick={this.openMobilePanel}>
                                    <FilterHeader label="When"/>
                                </div>

                                <DashboardWhen
                                    onReset={this.handleParamsChange}
                                    className={'hidden-xs'}
                                    value={whenValue}
                                    onFocus={this.onWhenFocus}/>
                            </li>
                        </ul>
                        <Row>
                            <div className="tab-content">
                                <div role="tabpanel" className={whatMenuClasses} id="services-filter">
                                    <div className="fr-filter-panel clearfix what-tab-select">
                                        <DashboardWhat
                                            data={this.state.serviceTree}
                                            onChange={this.handleWhatSelectChange}
                                            className={'visible-xs dashboard'}
                                            selected={this.state.selectedService}
                                        />
                                        <DashboardFilterWhat
                                            data={this.state.serviceTree}
                                            selectValue={this.state.selectedService}
                                            changeServicesCheckbox={this.changeServicesCheckbox}
                                            closeWhatTab={this.closeWhatTab}
                                        />
                                    </div>
                                </div>
                                <div role="tabpanel" className={whereMenuClasses} id="map-filter">
                                    <div className="fr-filter-panel clearfix">
                                        <DashboardWhere

                                            isWhereTabActive={this.state.mapCoordinates.render}
                                            onLocationChange={this.updateMap}
                                            onEnter={this.handleParamsChange}
                                            openMap={this.handleOpenMap}
                                            defaultValue={this.state.whereValue}
                                            onFocus={this.onWhereFocus}
                                            onInputChange={this.updateWhereInput}
                                            className={'visible-xs'}
                                            idInput={'dropdown-input'}
                                            idIsNearest={'isNearestMobile'}
                                            idGeocodingForm={idGenerator("geocoding_form")}
                                        />
                                        <div>
                                            <GoogleMapWithMarkers
                                                render={this.state.whereMenu}
                                                locations={this.state.locations}
                                                lat={this.state.mapCoordinates.lat}
                                                lon={this.state.mapCoordinates.lon}
                                                showCenterMarker={true}
                                                zoom={11}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className={whoMenuClasses}>
                                    <div className="who-tab-select fr-filter-panel clearfix">
                                        <DashboardWho
                                            className={'visible-xs'}
                                            test={'test'}
                                            isDrowdown={this.state.whoMenu}
                                            defaultValue={this.state.params.salonArtist}
                                            obtainParams={this.obtainParams}
                                            onFocus={this.onWhoFocus}
                                            onChange={this.handleParamsChange}/>
                                    </div>
                                </div>
                                <div ref="whenTab" className={whenMenuClasses}>
                                    <div
                                        ref="dashboardFilterWhen"
                                        className="fr-filter-panel clearfix  pull-right">
                                        <DashboardWhen
                                            className={'visible-xs'}
                                            value={whenValue}
                                            onReset={this.handleParamsChange}
                                            onFocus={this.onWhenFocus}/>

                                        <DashboardFilterWhen
                                            onChange={this.handleParamsChange}/>
                                    </div>

                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
               <GoUpButton/>
            </div>
        )
    }

}
