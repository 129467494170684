/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 30.05.17
 * Time: 10:50
 */

/**
 * Analogue Array.prototype.map for hashes
 * Transform hash to array
 *
 * @param hash
 * @param map
 * @returns Array
 */
export function mapHashToArray(hash, map = "transparent") {

    if (map === "transparent") {
        map = element => element;
    }

    let transform = [];

    Object.keys(hash).forEach(key => {
        transform.push(map(hash[key], key));
    });

    return transform;
}
