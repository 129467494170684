/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 13.06.2017
 * Time: 11:18
 */
import React, { Component } from 'react';
import classNames from 'classnames/bind';

export default class WhatFilterServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
      this.setState({
        checked: e.target.checked
      })
      this.props.changServicesCheckbox(e);
    }

    render(){
        let classHide;
        if (this.props.data.popular > 1000) {
            classHide = classNames({
              'hidden': this.props.isNoPopularHidden,
              'no-popular': true
            })
        }

        return (
            <div className={classHide || ""}>
                <input type="checkbox"
                       id={this.props.data.serviceID}
                       value={this.props.data.serviceID}
                       onChange={this.handleChange}
                       checked={this.state.checked}
                />
                <label htmlFor={this.props.data.serviceID}>
                    {this.props.data.serviceName}
                </label>
            </div>
        )
    }
}
