/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 25.7.17
 */

import AppDispatcher from "../dispatcher";
import { FILTER_PERSONAL_PAYMENT_ORDERS, LOAD_PERSONAL_PAYMENT_ORDERS } from "../constants";

import { xhrGetPaymentOrders } from "../xhr/get-payment-orders-xhr";


export function filterOrders(searchString) {
    AppDispatcher.dispatch({
        type: FILTER_PERSONAL_PAYMENT_ORDERS,
        payload: {
            searchString
        }
    });
}


export function loadPendingPaymentOrders() {
    xhrGetPaymentOrders().then(paymentOrders => {
        AppDispatcher.dispatch({
            type: LOAD_PERSONAL_PAYMENT_ORDERS,
            payload: {
                paymentOrders
            }
        });
    });
}
