/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.6.17
 * Time: 14.18
 */

import React from 'react';
import {Row, Col} from 'react-bootstrap';
import PaymentSourceAction from './payment-source-action';
export default function PaymentSource(props) {

    let actions = [];
    let now = (new Date()).valueOf();
    let expTimestamp = Date.UTC(props.exp_year, props.exp_month);
    let expired = now >= expTimestamp;

    if (props.isDefault) {
        actions.push({
            clickable: false,
            title: 'default'
        })
    }
    else {

        if (!expired) {
            actions.push({
                clickable: true,
                title: 'Set default',
                onClick: props.setDefaultSource
            });
        }
        //cannot remove default card
        if (!props.disableRemove) {
            actions.push({
                clickable: true,
                title: 'Remove',
                onClick: props.removeSource
            })
        }
    }


    let icon;
    if (props.object === "bank_account") {
        icon = <div className={props.isDefault ? "bank-green" : "bank-gray"}/>;
    }
    else {
        icon = expired ? <div className={"bank-card-red"}/> : <div className={props.isDefault ? "bank-card-green" : "bank-card-gray"}/>;
    }

    return <Row className="bank-card-profile__payment-sources-table fr-table__border-bottom">
        <Col md={2} xs={2} className="icon-column">
            {icon}
        </Col>
        <Col md={5} xs={5} className="info-column">
            {props.object === "bank_account" && drawBankAccountInfo(props)}
            {props.object === "card" && drawCardInfo(props, expired)}
        </Col>

        <Col md={5} xs={5} className="actions">

            {actions.map((action, i) => {

                return <div key={i} className="action fr-inline">
                    <PaymentSourceAction {...action} />
                </div>

            })}
        </Col>

    </Row>;
}

PaymentSource.defaultProps = {
    disableRemove: false,
    isDefault: false,
    setDefaultSource: () => {
    },
    removeSource: () => {
    }
};

function drawCardInfo(props, isExpired) {
    let expire = isExpired ? <strong
        className="expired">Expired {props.exp_month}/{props.exp_year}</strong> : `Expires ${props.exp_month}/${props.exp_year}`;
    return <div>
        <strong>{props.brand}
            &nbsp;&bull;&nbsp;
            {props.last4}</strong> <br/>
        {expire}
    </div>
}

function drawBankAccountInfo(props) {
    return <div>
        <strong>{props.bank_name}
            &nbsp;&bull;&nbsp;
            {props.last4}</strong>
    </div>
}