/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 2.10.17
 */

import { authenticationAssertion } from "./user-assertions/authentication-assertion";
import { cardAssertion } from "./user-assertions/card-assertion";

class UserAssert {
    constructor() {
        this.requireAssertions = {};
    }

    static registerAssertion(name, callable) {
        UserAssert.assertions[name] = callable;
    }

    assert(assertionName) {
        this.requireAssertions[assertionName] = true;

        return this;
    }

    then(success, fail) {
        let assertions = Object.keys(this.requireAssertions)
        .filter(name => UserAssert.assertions[name])
        .map(name => UserAssert.assertions[name]);

        return this.runAssertions(assertions).then(response => {
            success(response);
        }, response => {
            fail(response);
        });

    }

    /**
     * @protected
     * @param chain
     */
    runAssertions(chain) {

        let first = chain[0];

        return first().then(response => {

            if (chain.length === 1) {
                return true;
            }
            else {

                //recursion call
                return this.runAssertions(chain.slice(1));
            }
        }, response => {
            return Promise.reject(response);
        });
    }
}

UserAssert.assertions = {};
UserAssert.registerAssertion("authenticated", authenticationAssertion);
UserAssert.registerAssertion("card-exists", cardAssertion);

/**
 *
 * @returns {UserAssert}
 */
export default function() {
    return new UserAssert();
}
