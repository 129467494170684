/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.8.17
 */

import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import gradientFill from "../helper/gradient-fill";

export default function Banner(props) {

    let leftLength = props.leftLength;
    if (!leftLength) {
        if (typeof props.left === "string") {
            leftLength = props.left.length;
        }
    }

    let gradientStyle = props.gradientStyle || "top";
    let textStyle = !props.middle ? "fr-text-h1" : "fr-text-h1__big";
    let attrHover = props.rightBadge ? "long" : "short";

    return (
        <div className="fr-banner-top fr-full-width" style={{ "background": gradientFill(gradientStyle) }}>
            <Row className="fr-container-width">
                <Col xs={leftLength > 3 ? 8 : 4} sm={6} className={textStyle}>
                    <h1>{props.left}</h1>
                </Col>
                {props.right && <Col xs={leftLength > 3 ? 4 : 8} sm={6} className="fr-pl0">
                    <a href={props.rightUrl} className="fr-link-h1 pull-right " data-hover={attrHover}>{props.right}
                    </a>
                </Col>}
            </Row>
            <Row>
                <Col xs={12} sm={12}>
                    {props.middle}
                </Col>
            </Row>
        </div>
    );

}

Banner.propTypes = {
    left: PropTypes.any,
    leftLength: PropTypes.number,
    right: PropTypes.any,
    rightUrl: PropTypes.string,
    gradientStyle: PropTypes.string,
    rightBadge: PropTypes.bool
};
