/**
 * Created by fliak on 17.2.17.
 */

import ApiClient from "./../service/api-client";
import buildUrl from "../service/api-client-2/url-builder";

export default class BaseSyncer {

    constructor() {
        this.promise = null;
        this._resolveCallback = null;
        this._rejectCallback = null;
    }

    getClient() {
        return ApiClient;
    }

    buildURL(route, params = {}, absolute = false) {
        return buildUrl(route, params, absolute);
    }

    getPromise() {
        if (!this.promise) {
            this.promise = new Promise((resolve, reject) => {
                this._resolveCallback = resolve;
                this._rejectCallback = reject;
            });
        }

        return this.promise;
    }
}
