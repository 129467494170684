/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 18.9.17
 */

import React from 'react';
import {Button, Badge} from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function Message(props)  {

    let onRead = () => {
        props.onRead(props.message_id);
    };

    return <li>
        { !props.read && <Badge>NEW</Badge> }
        <div className="message-header">{props.header}</div>
        <div className="message-body">{props.message}</div>
        { !props.read && <Button onClick={onRead}>Read</Button> }
    </li>
}

Message.propTypes = {
    onRead: PropTypes.func
};

Message.defaultProps = {
    onRead: () => {}
};