/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 31.01.2018
 * Time: 16:04
 */

import BaseStore from "../../base-store";
import {
    ARTIST_SERVICE_MODAL,
    MODAL_CHANGE,
    PROFILE_ARTIST_SERVICE_ADDED,
    PROFILE_ARTIST_SERVICE_MODIFIED,
    PROFILE_ARTIST_SERVICE_REMOVED
} from "../../../constants";
import { modalHide } from "../../../ac/modals";

export default class ArtistServiceStore extends BaseStore {
    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case MODAL_CHANGE:
                    if (payload.type === ARTIST_SERVICE_MODAL) {
                        this.store.service = payload.options.service;
                        this.store.btnDisabled = false;
                    }

                    break;

                case PROFILE_ARTIST_SERVICE_MODIFIED:
                    this.store.service["individualPrice"] = payload.price;
                    this.store.btnDisabled = false;
                    setTimeout(modalHide);

                    break;

                case PROFILE_ARTIST_SERVICE_REMOVED:
                    this.store.btnDisabled = false;
                    setTimeout(modalHide);
                    break;

                case PROFILE_ARTIST_SERVICE_ADDED:
                    this.store.btnDisabled = false;
                    setTimeout(modalHide);

                    break;


                default:
                    return true;
            }

            this.emitChange();
        });

    }

    getInitialStore() {
        return {

            service: {},
            violations: {},
            btnDisabled: false
        };
    }
}
