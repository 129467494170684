/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.10.17
 */

import violationBuilder from "./../violation-builder";
import BaseValidator from "./base-validator";
import OlderThan, { VALUE_IS_TO_HIGH } from "./is-older-than";
import moment from "moment";
import { VALUE_IS_NOT_A_DATE } from "./is-date";

export default class DateComparisonValidator extends BaseValidator {

    static getValidConstraints() {
        return [OlderThan];
    }

    validate(constraint, field, root = null) {

        this.assertConstraintIsAllowed(constraint);

        let value = root ? root[field] : field;

        let momentValue = moment(value);

        if (!momentValue.isValid()) {
            return violationBuilder.build(constraint.invalidValueMessage, {}, field, root, VALUE_IS_NOT_A_DATE, value);
        }

        let isValid;
        switch (true) {
            case constraint instanceof OlderThan:
                let compareWith = moment().subtract(constraint.value, constraint.unit).unix();

                isValid = momentValue.unix() < compareWith;

                if (!isValid) {
                    return violationBuilder.build(constraint.message, {
                        "{value}": constraint.value,
                        "{unit}": constraint.getUnitPluralForm(constraint.value, constraint.unit)
                    }, field, root, VALUE_IS_TO_HIGH, value);
                }
                break;

            default:
                throw new Error("Unknown constraint given to DateComparisonValidator");
        }

        return true;
    }
}
