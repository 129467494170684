/**
 * Created by alexey on 17.6.3.
 */
import React, {Component} from 'react';
import {
    BOOK_APPOINTMENT_NEW,
    GENDER_FEMALE,
    GENDER_MALE,
    GENDER_NOT_SHARED
} from '../../frizo-shared-constants';

import {Button} from 'react-bootstrap';
import xhrLoadClientByPhone from '../../xhr/client-xhr';
import PhoneInput from './../../elements/phone-input';
import {clientFound, clientMissing, clientFormReset} from './../../ac/book-apt/form-actions';
import {bookAptClientListStore} from '../../stores/';
import FindClientForm from '../find-list/find-client-form';
import {salonClientsAutoComplete} from "../../ac/book-apt/data-load-actions";


class BookClient extends Component {

    timer: null;

    constructor(...args) {

        super(...args);
        this.updateClientData = this.updateClientData.bind(this);
        this.onSearchParamChange = this.onSearchParamChange.bind(this);
        this.onClientFound = this.onClientFound.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.createGender = this.createGender.bind(this);

        this.state = {
            list: [],
            resetButtonDisplay: false,
            showClientSearchForm: true,
            lockClientSearchForm: false,
            forceHideHint: false,
            newClient: false
        }
    }

    componentDidMount() {
        if (this.props.phone) {
            this.updateClientData(this.props.phone);
        }
        bookAptClientListStore.addChangeListener(this.onStoreChange);
    }

    componentWillUnmount() {
        bookAptClientListStore.removeChangeListener(this.onStoreChange);
    }

    onStoreChange() {
        this.setState({list: bookAptClientListStore.getClientList()});
    }

    updateClientData(phone) {
        let promise = new Promise((resolve, reject) => {
                xhrLoadClientByPhone(phone).then(response => {
                    if (response) {
                        resolve(response)
                    }
                    else {
                        reject(response);
                    }
                }, response => {
                    reject(response);
                });
        });

        promise.then(client => {
            this.setState({
                showClientSearchForm: true,
                lockClientSearchForm: true,
                resetButtonDisplay: true,
                newClient: false

            }, clientFound({
                firstName: client.clientFirstName || client.firstName,
                lastName: client.clientLastName || client.lastName,
                phone: client.clientPhone || client.phone,
                gender: client.clientGender || client.gender
            }));

        }, () => {

            this.setState({
                showClientSearchForm: true,
                lockClientSearchForm: false,
                resetButtonDisplay: true,
                forceHideHint: true,
                newClient: true

            }, clientMissing());

        });
    }


    createGender(hideIfNotShared = false) {

        let gender;
        switch (this.props.gender) {
            case GENDER_MALE:
                gender = 'Male';
                break;

            case GENDER_FEMALE:
                gender = 'Female';
                break;

            default:
                if (!hideIfNotShared) {
                    gender = 'Not shared';
                }
        }

        return gender;

    }

    createStatic() {

        return (<div>
            <input className="form-control" value={`${this.props.firstName} ${this.props.lastName}`}
                   disabled={true}
                   type="text"/>

            <PhoneInput className="form-control" type="text"
                        value={this.props.phone}
                        disabled={true}
                        mask='(111) 111-1111'
            />
            {this.props.gender !== GENDER_NOT_SHARED && <input className="form-control" value={this.createGender(true)}
                                                               disabled={true} placeholder="gender"
                                                               type="text"/>}
        </div>);

    }

    resetForm() {
        this.setState({
                showClientSearchForm: true,
                lockClientSearchForm: false,
                resetButtonDisplay: false,
                forceHideHint: false,
                newClient: false
            }, clientFormReset()
        );
    }

    createForm() {

        return (<div>
            <FindClientForm
                change={this.onSearchParamChange}
                found={this.onClientFound}
                createGender={this.createGender}
                showViolations={this.props.showViolations}
                violations={this.props.violations}
                gender={this.props.gender}
                firstName={this.props.firstName}
                lastName={this.props.lastName}
                phone={this.props.phone}
                {...this.state}
            />

            <Button
                onClick={this.resetForm}
                style={{display: this.state.resetButtonDisplay ? '' : 'none'}}>
                Reset
            </Button>

        </div>);

    }

    onSearchParamChange({field, value}) {
        if ((field === 'phone') && (value.length === 10)) {
            this.props.change({field, value});
            this.updateClientData(value);
        }
        else {

            this.setState({
                resetButtonDisplay: true,
                forceHideHint: this.props.phone.length === 10,
            }, this.props.change({field, value}));

            if (this.timer) {
                clearTimeout(this.timer);
            }
            
            this.timer = setTimeout(() => {
                salonClientsAutoComplete(value);
            }, 500);
        }
    }

    onClientFound(phone) {
        this.updateClientData(phone);
    }


    render() {
        if (this.props.action === BOOK_APPOINTMENT_NEW) return this.createForm();
        return this.createStatic();

    }

}

BookClient.defaultProps = {
    phone: '',
    firstName: '',
    lastName: '',
    gender: 3
};

export default BookClient;