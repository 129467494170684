/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 28.05.2017
 * Time: 11:18
 */
import React, { Component } from 'react';

import DashboardCalendar from './../elements/dashboard-calendar';
import DashboardSlider from './../elements/dashboard-slider';

export default class DashboardFilterWhen extends Component {
    constructor(props) {
        super();
        this.change = this.change.bind(this);
    }
    change(data) {
        this.props.onChange(data);
    }
    render() {
        let calWrap = {
            background: '#e6e6e6',
            display: 'inline-block',
            float: 'right',
            padding: '5px 25px 15px 25px'
        };
        return (
            <div style={calWrap}>
              <DashboardCalendar onChange={this.change}/>
              <DashboardSlider onChange={this.change}/>
            </div>
        )
    }
}
