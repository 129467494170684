/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 30.6.17
 * Time: 18.09
 */

import React, {Component} from 'react';
import {profileArtistServicesStore} from '../../../../stores/index';
import PropTypes from 'prop-types';
import ArtistServices from '../../professional/artists/artist-services';

import {
    loadArtistInfoAction,
    loadArtistServicesAction,
} from '../../../../ac/profile-artists/common-actions';
import LoaderBar from "../../../../elements/loader-bar";


export default class ProfessionalProfileArtistServices extends Component {

    constructor(...args) {
        super(...args);

        this.state = {
            ready: false,
            servicesInfo: {}
        };

        this.storeChange = this.storeChange.bind(this);
    }


    componentDidMount() {
        profileArtistServicesStore.addChangeListener(this.storeChange);
        if (this.props.artistId) {
            loadArtistInfoAction(this.props.artistId);
        }
        loadArtistServicesAction(this.props.artistId);
    }

    storeChange() {
        this.setState({
            artistInfo: profileArtistServicesStore.getArtistInfo(),
            servicesInfo: profileArtistServicesStore.getServicesInfo(),
            ready: true
        });
    }

    render()    {

        if (!this.state.ready) return <LoaderBar/>;

        return <div>
            <ArtistServices
                artistId={this.props.artistId}
                disableActions={true}
                master = {true}
                showFirst={true}
                {...this.state.servicesInfo}
            />
        </div>
    }
}



ProfessionalProfileArtistServices.propTypes = {
    artistId: PropTypes.number,
};