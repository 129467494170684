/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 28.05.2017
 * Time: 18:18
 */
import React, {Component} from 'react';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import {dashboardWhenStore} from '../../../stores/index';
import {whenTimeIntervalChange} from '../../../ac/dashboard-filter-actions';

export default class DashboardSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentInterval: dashboardWhenStore.getTimeInterval(),
            step: 15 * 60,
            min: 0,
            max: 60 * 60 * 24,
            timeInterval: dashboardWhenStore.getDateTimeInterval()
        };

        this.handleStop = this.handleStop.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);

    }

    onStoreChange() {
        this.setState({
            currentInterval: dashboardWhenStore.getTimeInterval(),
            timeInterval: dashboardWhenStore.getDateTimeInterval(),
        });
    }

    componentDidMount() {
        dashboardWhenStore.addChangeListener(this.onStoreChange);
    }

    componentWillUnmount() {
        dashboardWhenStore.removeChangeListener(this.onStoreChange);
    }


    handleStop(e) {
        whenTimeIntervalChange(e.target.value);
        this.props.onChange({
            from: e.target.value[0],
            to: e.target.value[1]
        });
    }

    render() {
        let containerStyles = {
            padding: '10px 0 2px 0'
        };
        return (
            <div className="dashboard-slider" style={containerStyles}>
                <p className="dashboard-slider__text">Am available for appointment</p>
                <p className="dashboard-slider__value">{this.state.timeInterval}</p>
                <ReactBootstrapSlider
                    value={this.state.currentInterval}
                    slideStop={this.handleStop}
                    step={this.state.step}
                    max={this.state.max}
                    min={this.state.min}
                />
            </div>
        )
    }
}
