/**
 * User:  Ignat Kryshchyk
 * Username: ignt
 * Company: U6 SIA
 * Date: 17.4.17
 * Time: 17.36
 */

import BaseStore from "./base-store";

import { GALLERY_SELECT_LOAD_ARTISTS_SUCCESS, GALLERY_SELECT_ONCHANGE } from "./../constants";

export default class GallerySelectStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            salonId: 0,

            artistId: 0,
            artists: [],

            serviceId: 0,
            services: []

        };

        this._registerActionSubscription(action => {
            const { type, payload } = action;

            switch (type) {
                case GALLERY_SELECT_ONCHANGE:
                    if (payload.artistId) {
                        this.store.artistId = Number(payload.artistId);
                        this.store.serviceId = 0;
                    }
                    if (payload.serviceId) {
                        this.store.serviceId = Number(payload.serviceId);
                    }

                    break;

                case GALLERY_SELECT_LOAD_ARTISTS_SUCCESS:
                    this.store.artists = payload.artists;

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }
}
