/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.7.17
 */

import {
    PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR,
    PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED
} from "../../constants";

import AppDispatcher from "../../dispatcher/index";
import { xhrAddPayCard } from "../../xhr/payments/professional/pay/add-pay-card-xhr";
import { xhrRemovePayCard } from "../../xhr/payments/professional/pay/remove-pay-card-xhr";
import { xhrSetDefaultPayCard } from "../../xhr/payments/professional/pay/set-default-pay-card-xhr";
import { xhrGetStripeSubscriptionCustomer } from "../../xhr/payments/professional/pay/get-stripe-subscription-customer-xhr";

export function loadStripeSubscriptionCustomer() {
    return xhrGetStripeSubscriptionCustomer().then(response => {
        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
            payload: response
        });

    });
}

export function addNewPayCard(token) {
    if (typeof token === "object") token = token.id;

    return xhrAddPayCard(token).then(response => {

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
            payload: response
        });

    }, response => {

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR,
            payload: response
        });

        return Promise.reject(response);

    });
}


export function setDefaultPayCard(id) {

    return xhrSetDefaultPayCard(id).then(response => {
        console.log("set-default", response);
        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
            payload: response
        });
    }, response => {

        console.warn("fail", response);

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR,
            payload: {
                general: ["Problem with default pay source selecting"]
            }
        });

        return Promise.reject(response);

    });
}

export function removePayCard(id) {

    return xhrRemovePayCard(id).then(response => {
        console.log("remove", response);

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED,
            payload: response
        });
    }, response => {

        console.warn("fail", response);

        AppDispatcher.dispatch({
            type: PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR,
            payload: {
                general: ["Problem with pay source removing"]
            }
        });

        return Promise.reject(response);

    });
}
