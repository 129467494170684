/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 3/30/17
 * Time: 23:53
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_NOT_PHONE = "VALUE_IS_NOT_PHONE";

export default class IsPhone extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Invalid phone";
        this.regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        this.errorCode = VALUE_IS_NOT_PHONE;
    }

    static getName() {
        return "is-phone";
    }
}
