/**
 * Created by fliak on 22.2.17.
 */

import ServiceIsLeafConstraint, {
    ARTIST_IS_NOT_SELECTED,
    SELECTED_SERVICE_IS_MISSING,
    SELECTED_SERVICE_IS_NOT_A_LEAF,
    SERVICE_IS_NOT_SELECTED
} from "./service-is-leaf";
import violationBuilder from "./../violation-builder";
import sprintf from "./../../helper/sprintf";
import BaseValidator from "./base-validator";

export default class ServiceIsLeafValidator extends BaseValidator {

    static getValidConstraints() {
        return [ServiceIsLeafConstraint];
    }

    validate(constraint, field, root = null) {
        if (!constraint instanceof ServiceIsLeafConstraint) {
            throw new Error(sprintf("ServiceIsLeafConstraint should be passed to ServiceIsLeaf.validate(). %s given.",
                constraint.constructor.name));
        }

        let serviceStore = constraint.serviceStore;

        let value = root ? root[field] : field;

        if (!value) {
            return violationBuilder.build(
                constraint.serviceIsNotSelectedMessage, {}, field, root, SERVICE_IS_NOT_SELECTED, value
            );
        }

        let service;

        if (constraint.useArtistSpecificService) {
            if (!root) throw new Error("Root should be specified for case of using artist specific service");

            let artistId = root[constraint.artistIdFieldName];

            if (!artistId) {
                return violationBuilder.build(
                    constraint.artistIsNotSelectedMessage, {}, "artistId", root, ARTIST_IS_NOT_SELECTED, artistId
                );
            }

            service = serviceStore.getArtistService(artistId, value);
        }
        else {
            service = serviceStore.getServiceById(value);
        }

        if (!service) {
            return violationBuilder.build(
                constraint.selectedServiceIsMissingMessage, {}, field, root, SELECTED_SERVICE_IS_MISSING, value
            );
        }

        if (!service.durationValue) {
            return violationBuilder.build(
                constraint.selectedServiceIsNotALeafeMessage, {}, field, root, SELECTED_SERVICE_IS_NOT_A_LEAF, value
            );
        }

        return true;
    }
}
