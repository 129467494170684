/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 30.10.2018
 * Time: 10:00
 */

import React, {Component} from 'react';
import {Dropdown, MenuItem, FormControl} from 'react-bootstrap';
import {brandSelectStore} from '../../../../stores/index';
import {
    brandInputChange,
    getBrandHintList,
    brandHintSelect
} from '../../../../ac/profile/professional/professional-profile-brands-actions';

export default class BrandSelectionMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: brandSelectStore.getMenuActive(),
            hintList: brandSelectStore.getHintList(),
            searchValue: brandSelectStore.getSearchValue(),
        };

        this.onSelect = this.onSelect.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    onStoreChange() {

        this.setState({
            hintList: brandSelectStore.getHintList(),
            searchValue: brandSelectStore.getSearchValue(),
            active: brandSelectStore.getMenuActive()
        });
    }

    componentDidMount() {
        brandSelectStore.addChangeListener(this.onStoreChange);
    }

    componentWillUnmount() {
        brandSelectStore.removeChangeListener(this.onStoreChange);
    }

    onSelect(e) {
        brandHintSelect(e);
        this.props.onSelect(e);
    }

    onEnter(e) {

        if (e.key === 'Enter') {
            let hintStore = brandSelectStore.getStore(),
                brand = hintStore.brandList.find(item => item.title === hintStore.searchValue);
            if (brand) {
                brandHintSelect(brand);
                this.props.onSelect(brand);
            }
            e.preventDefault();
        }
    }

    onInputChange(e) {
        brandInputChange(e.target.value);
        getBrandHintList(e.target.value);
    }

    handleKeyDown(e) {
        let hintStore = brandSelectStore.getStore(),
            searchValIndex = hintStore.hintList.findIndex((item) => item.title === hintStore.searchValue);
        if (this.state.searchValue.length) {
            if (e.key === 'ArrowDown') {
                this.handleKeyArrowDown(hintStore, searchValIndex);
            } else if (e.key === 'Escape') {
                this.setState({active: false});
            } else if (e.key === 'ArrowUp') {
                this.handleKeyArrowUp(hintStore, searchValIndex);
            }
        }
    }

    handleKeyArrowDown(hintStore, searchValIndex) {
        if ( searchValIndex + 1 < hintStore.hintList.length) {
            brandInputChange(hintStore.hintList[searchValIndex + 1].title);
        }
    }


    handleKeyArrowUp(hintStore, searchValIndex) {
        if (searchValIndex) {
            brandInputChange(hintStore.hintList[searchValIndex - 1].title);
        }
    }

    onReset() {
        this.setState({active: false});
        brandInputChange('');
        this.props.onSelect({});
    }


    createMenuItems() {

        return this.state.hintList.map((item, i) => {

            let menuItemClass = null;
            let className = this.props.className;

            if (item.title === this.state.searchValue) {
                menuItemClass = className + ' active';
            }
            let menuItem = item.title;

            let pos = menuItem.toLowerCase().indexOf(this.state.searchValue);
            if (pos > -1) {
                let part1 = menuItem.substring(0, pos);
                let part2 = menuItem.substring(pos, pos + this.state.searchValue.length);
                let part3 = menuItem.substring(pos + this.state.searchValue.length);

                menuItem = part1 + `<strong>${part2}</strong>` + part3;
            }


            return (
                <MenuItem
                    className={menuItemClass}
                    key={i}
                    eventKey={item}
                    onSelect={this.onSelect}>
                    <span dangerouslySetInnerHTML={{__html: menuItem}}></span>
                </MenuItem>
            )
        })
    }

    render() {

        let menuItems = null;
        if (this.state.hintList.length && this.state.active) {
            menuItems = this.createMenuItems();
        }

        return (
            <div className={"dropdown-gallery dropdown btn-group"}>
                <FormControl value={this.state.searchValue}
                             onChange={this.onInputChange}
                             className={'brand-input'}
                             placeholder={'Enter brand name'}
                             onKeyDown={this.handleKeyDown}
                             onKeyPress={this.onEnter}

                />
                {this.state.searchValue !== '' &&
                <button onClick={this.onReset} className="dashboard-btn__reset brand-select fr-btn pull-right"><span
                    className="glyphicon glyphicon-remove"></span></button>}
                {this.state.active && this.state.hintList.length > 0 &&
                <Dropdown.Menu className={'dropdown-brand-selector--menu'}>
                    {menuItems}
                </Dropdown.Menu>}
            </div>
        )

    }

}

BrandSelectionMenu.defaultProps = {
    searchValue: '',
    selectedBrand: {},
    onSelect: () => false
};