/**
 * Created by fliak on 10.1.17.
 */

import { BOOK_LOAD_SALON_INFO_SUCCESS, BOOK_START_BOOKING } from "../../constants";

import { MODE_CLIENT, MODE_PROFESSIONAL, USER_TYPE_FREELANCER } from "../../frizo-shared-constants";

import BaseStore from "./../base-store";
import moment from "moment";
import { createArtistIndexer } from "./../../service/artist-indexer";

export default class BookAptArtistStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            salonId: null,
            artistId: null,
            artists: [],
            artistsIndex: {},
            artistMidnightCache: {},
            addresses: [],
            addressIndex: {}
        };


        this._registerActionSubscription((action) => {
            const { type, payload } = action;


            switch (type) {
                case BOOK_START_BOOKING:
                    // this.store.bookConfig = payload;
                    this.store.salonId = payload.salonId;

                    this.store.artistId = payload.artistId;

                    this.setReady(false);

                    return true;

                case BOOK_LOAD_SALON_INFO_SUCCESS:

                    this.store.salonId = payload.id;

                    this.store.artists = payload.user.filter(user => {
                        return user.master && user.isBookable;
                    });

                    this.store.addresses = payload.address;

                    this.buildIndex();

                    this.setReady(true);

                    break;


                default:
                    return true;
            }


            this.emitChange();
        });
    }

    getAddressById(addressId) {
        let address = this.store.addressIndex[addressId];
        if (!address) throw new Error("Provided address `" + addressId + "` is missing");

        return address;
    }

    buildIndex() {
        this.store.artistsIndex = {};
        this.store.addressIndex = {};


        let indexer = createArtistIndexer()
        .putAddressSet(this.store.addresses)
        .putArtistSet(this.store.artists)
        .index();


        this.store.artistsIndex = indexer.getArtistIndex();
        this.store.addressIndex = indexer.getAddressIndex();
        this.store.addresses = indexer.getAddressSet();

    }

    getSalonType() {
        return this.store.artists[0].rawUserType;
    }


    /**
     * Return timestamp of midnight for artist on the date
     * Function result can be cached
     *
     * @param artistId
     * @param date
     * @returns integer timestamp
     */
    getArtistMidnight(artistId, date) {

        ///need to ensure cache consistency
        if (!artistId || !date) throw new Error("Both params should be set");

        let key = date + "-" + artistId;

        if (this.store.artistMidnightCache[key] !== undefined) {
            return this.store.artistMidnightCache[key];
        }

        let schedule = this.getArtistScheduleForDate(artistId, date);
        let tz = schedule.address.timezone;

        let value = moment.tz(date, tz).startOf("day").unix();
        this.store.artistMidnightCache[key] = value;

        return value;
    }

    getArtistScheduleForDate(artistId, date) {

        if (typeof date === "number") {
            //treat as timestamp
            date *= 1000;
        }

        let dayOfWeek = moment.utc(date).day();

        if (!this.store.artistsIndex[artistId]) throw new Error("Artist is missing");
        let artist = this.store.artistsIndex[artistId];

        let schedule = artist.scheduleIndex[dayOfWeek];
        if (!schedule) throw new Error("Schedule for this day is missing");

        return schedule;
    }

    getArtistsIndex() {
        return this.store.artistsIndex;
    }

    artistExist(artistId) {
        return this.store.artistsIndex[artistId] !== undefined;
    }

    getArtistById(id) {
        return this.store.artistsIndex[id];
    }

    isSalonMultiAddress() {
        return this.getSalonType() === USER_TYPE_FREELANCER;
    }


    prepareArtistData(artistId = 0, mode = MODE_PROFESSIONAL) {

        let artists = this.store.artists;
        let selected = artistId;
        let multiAddress = this.isSalonMultiAddress();

        if (mode === MODE_CLIENT) {
            artists = artists.filter(artist => artist.isPublic);
        }

        let options = artists.map(artist => {
            let name = artist.firstName + " " + artist.lastName;
            let id = artist.id;

            return { name, id };
        });

        return { options, selected, multiAddress };
    }


    prepareAddressDataByDate(artistId, date) {
        if (!artistId) return [];
        let schedule = this.getArtistScheduleForDate(artistId, date);

        let addressString = [
            schedule.address.address,
            schedule.address.city,
            schedule.address.state,
            schedule.address.zip
        ].filter(value => value && value.length > 0).join(", ");


        return [{ name: addressString, id: schedule.address.id }];
    }

    guessAddress(artistId, date) {
        if (this.isSalonMultiAddress()) {

            try {
                let schedule = this.getArtistScheduleForDate(artistId, date);

                if (schedule) {
                    return schedule.address;
                }

            }
            catch (e) {
                //depress exception
            }

            return this.store.addresses[0];


        }
        else {
            return this.store.addresses[0];
        }
    }

    guessTimezone(addressId = null, artistId = null) {
        switch (true) {
            case !!addressId:
                return this.getAddressById(addressId).timezone;

            case !!artistId:
                let { schedule } = this.getArtistsIndex()[artistId];
                if (schedule.length > 0) {
                    return schedule[0].address.timezone;
                }
            //fallthrough

            default:
                if (this.store.addresses && this.store.addresses.length > 0) {
                    return this.store.addresses[0].timezone;
                }

                return "America/New_York";

        }
    }

    prepareAddressData(artistId) {

        return this.store.addresses.map(address => {
            let addressString = [
                address.address,
                address.city,
                address.state,
                address.zip
            ].filter(value => value && value.length > 0).join(", ");


            return { name: addressString, id: address.id };
        });
    }

    getSalonInfo() {
        return true;
    }
}
