/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.3.17
 * Time: 7.07
 */

import React, {Component} from 'react';
import {withRouter, browserHistory} from 'react-router';

import {Link} from 'react-router';
import {Button, Row, Col} from 'react-bootstrap';
import ImagePreview from './../../../components/book-apt/image-preview';
import GallerySelect from './../../gallery-select/gallery-select';
import {profileGalleryStore, sessionStore, servicesStore} from './../../../stores';

import {
    loadGallerySalonInfo,
    updateGalleryItem,
    loadGalleryItem
} from './../../../ac/profile/professional/professional-profile-gallery-actions';
import {MODE_ARTIST_SELECTABLE, MODE_ARTIST_LOCKED} from './../../../frizo-shared-constants';

import Validator from '../../../validation/validator';
import Violation from '../../../../app/components/helper-components/violation';
import LoaderBar from "../../../elements/loader-bar";

class ProfessionalProfileGalleryEdit extends Component {
    constructor(props, context) {
        super(props, context);

        let user = sessionStore.getSession();
        let mode = MODE_ARTIST_SELECTABLE;
        let formData = {};
        if (user && user.master) {
            formData = Object.assign(formData, {artistId: user.id});
            mode = MODE_ARTIST_LOCKED;
        }

        this.state = {
            salonInfo: {},
            formData: formData,
            imageId: 0,
            imageUrl: '',
            imageBeforeId: 0,
            imageBeforeUrl: '',
            userId: 0,
            galleryItemID: 0,
            galleryItem: {},
            mode: mode,
            formIsValid: false,
            violations: {}

        };

        this.onTagChange = this.onTagChange.bind(this);
        this.onGalleryStoreChange = this.onGalleryStoreChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.getBeforeImagePreview = this.getBeforeImagePreview.bind(this);

        profileGalleryStore.addChangeListener(this.onGalleryStoreChange);

        loadGallerySalonInfo();
        loadGalleryItem(this.props.params.id)

    }

    componentWillUnmount() {
        profileGalleryStore.removeChangeListener(this.onGalleryStoreChange);
    }

    onTagChange(obj) {
        let formData = this.state.formData;
        for (let prop in obj) {
            formData[prop] = obj[prop];
        }
        this.setState({
            formData: formData
        })
    }

    convertToDefaults(defaults) {

        let galleryItem = {
            artistId: defaults.galleryItemArtistID,
            serviceId: defaults.galleryItemServiceID,
            salonServiceId: defaults.galleryItemSalonServiceID
        };

        return galleryItem
    }

    onGalleryStoreChange() {

        let salonInfo = profileGalleryStore.getSalonInfo();
        let {galleryItemID, imageUrl, formData, imageId, galleryItem, imageBeforeId, imageBeforeUrl} = this.state;
        let profileGalleryItem = profileGalleryStore.getProfileGalleryItem();

        if (Object.keys(profileGalleryItem).length) {
            galleryItemID = profileGalleryItem.galleryItemID;
            formData = this.convertToDefaults(profileGalleryItem);
            imageUrl = profileGalleryItem.galleryItemImageURL + '-medium.jpg';
            imageId = profileGalleryItem.imageID;
            imageBeforeUrl = profileGalleryItem.imageBeforeId ? profileGalleryItem.galleryItemImageBeforeURL + '-medium.jpg' : null;
            imageBeforeId = profileGalleryItem.imageBeforeId;
            galleryItem = profileGalleryItem;
        }

        this.setState({
            salonInfo: salonInfo,
            galleryItemID: galleryItemID,
            formData: formData,
            imageUrl: imageUrl,
            imageId: imageId,
            imageBeforeUrl: imageBeforeUrl,
            imageBeforeId: imageBeforeId,
            galleryItem: galleryItem,
            formIsValid: false,
            violations: {}
        });

    }


    onSave() {

        const validator = new Validator();

        validator.addConstraint('formData', 'gallery-form', {servicesStore: servicesStore});

        let violations = validator.validate({
            formData: this.state.formData
        });

        this.setState({
            formIsValid: violations === true,
            violations: violations === true ? {} : violations
        });

        if (violations === true) {
            let imageId = this.state.imageId,
                userId = this.state.formData.artistId,
                galleryItemID = this.state.galleryItemID;
            let patch = Object.assign({}, this.state.formData, {userId, imageId, galleryItemID});

            updateGalleryItem(patch).then(() => {
                browserHistory.push('/my-salon/gallery');
            })
        }
    }

    isReady() {

        return (this.state.salonInfo.id && Object.keys(this.state.formData).length);
    }

    getBeforeImagePreview() {
        if (this.state.imageBeforeId) {
            return <Col md={4} className="fr-mb">
                <ImagePreview
                    style={{width: '100%', height: 400}}
                    img={this.state.imageBeforeUrl}
                    noRemove={true}/>
            </Col>
        }
        else {
            return null;
        }
    }

    render() {

        if (!this.isReady()) {
            return <LoaderBar className={"loader-msg-wrapper--full-height"}/>;
        }
        let beforeImagePreview = this.getBeforeImagePreview();
        let editMessage = this.state.imageBeforeId ? 'Edit Before & After image' : 'Edit gallery item tags';

        return (
            <Row>
                <div className="fr-gallery-form-title">
                    <Link to="/my-salon/gallery" className="fr-glyphicon">
                        <span className="glyphicon glyphicon-circle-arrow-left"></span>
                    </Link>
                    <div className="fr-profile__title" style={{display: 'inline-block'}}>{editMessage}</div>
                </div>
                {beforeImagePreview}
                <Col md={4} className="fr-mb">
                    <ImagePreview
                        style={{width: '100%', height: 400}}
                        img={this.state.imageUrl}
                        noRemove={true}/>
                </Col>
                <Col md={4}>
                    <GallerySelect
                        {...this.state.formData}
                        onChange={this.onTagChange}
                        salonId={this.state.salonInfo.id}
                        mode={this.state.mode}
                    />
                    <Violation className="fr-violation-gallery-select" violationSet={this.state.violations.formData}
                               visible={!this.state.formIsValid}/>
                    <div>
                        <Button className="pull-right fr-btn fr-big-button" onClick={this.onSave}>Save</Button>
                    </div>
                </Col>

            </Row>
        )

    }
}

export default withRouter(ProfessionalProfileGalleryEdit);
