/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.1.17.
 */

import {
    APPOINTMENT_ADD,
    APPOINTMENT_REMOVE,
    BOOK_ANOTHER,
    BOOK_INITIALS_LOADED,
    BOOK_MODAL,
    BOOK_PERSIST,
    BOOK_PERSIST_FAIL,
    BOOK_PERSIST_STOP,
    BOOK_PERSIST_SUCCESS,
    BOOK_START_BOOKING,
    BOOK_START_EDIT,
    MODAL_CHANGE,
    MODAL_HIDE
} from "../../constants";

import {
    BOOK_APPOINTMENT_EDIT,
    BOOK_APPOINTMENT_NEW,
    DROPDOWN_PLACEHOLDER_VALUE,
    JOURNAL_ELEMENT_APPOINTMENT,
    MODE_CLIENT
} from "../../frizo-shared-constants";
import AppDispatcher from "../../dispatcher";
import setAptConfig from "../../service/apt-config-setter";
import idGenerator from "./../../helper/id-generator-component";
import BookAptSyncer from "./../../sync/book-apt-syncer";
import { artistStore, bookAptStores, sessionStore } from "./../../stores/index";
import { xhrLoadServices } from "../../xhr/services-xhr";
import { servicesLoaded } from "./data-load-actions";
import xhrLoadSalonInfoByArtist from "../../xhr/salon-info-xhr";
import { salonInfoLoadSuccess } from "./salon-info-load-success";
import notificationSystem from "./../../service/notification-system";
import assertUser from "./../../service/assert-user";
import { notifyFail } from "../notify-actions";

const bookingPreloading = (artistId, salonId) => {

    let salonInfoPromise = xhrLoadSalonInfoByArtist(artistId).then(salonInfo => {
        if (Object.keys(salonInfo).length > 0) {
            salonInfoLoadSuccess(salonInfo);

            return Promise.resolve(salonInfo);
        }
        else {
            return Promise.reject({
                message: "You cannot book to this salon"
            });
        }

    });

    let artistsPromise = bookAptStores.artist.getReadyPromise();
    let configPromise = bookAptStores.conf.getReadyPromise();

    //load services for artists
    artistsPromise.then(store => {
        let colleaguesIds = Object.keys(bookAptStores.artist.getArtistsIndex());

        xhrLoadServices(salonId, colleaguesIds).then((answer) => {
            servicesLoaded(answer.services, answer.salonCorrections, answer.artistsCorrections);
        }, answer => {

            notifyFail("Unable to load services");

            return Promise.resolve(answer);

        });
    });

    return Promise.all([artistsPromise, configPromise, salonInfoPromise]).then((values) => {

        let [artistStoreData, configStoreData] = values;
        return Promise.resolve({
            artistStoreData, configStoreData
        });
    }, violations => {

        return Promise.reject(violations);
    });
};


export const startBooking = (mode,
                             artistId, salonId, date = null, startTime = DROPDOWN_PLACEHOLDER_VALUE, serviceId = 0, imageId = 0, imageURL = null,
                             addressId = 0, salonClient = null,
                             action = BOOK_APPOINTMENT_NEW) => {

    let userAssertion = assertUser()
    .assert("authenticated");

    if (mode === MODE_CLIENT) {
        userAssertion.assert("card-exists");
    }


    return userAssertion.then(response => {
        console.log("Conditions resolved");

        AppDispatcher.dispatch({
            type: BOOK_START_BOOKING,
            payload: {
                mode: mode,
                addressId: addressId,
                artistId: artistId,
                salonId: salonId,
                date: date,
                startTime: startTime,
                imageId: imageId,
                imageURL: imageURL,
                serviceId: serviceId,
                salonClient: salonClient,
                action: action
            }
        });

        bookingPreloading(artistId, salonId).then(({ artistStoreData, configStoreData }) => {

            const session = bookAptStores.session.getSession();
            const artistsIndex = artistStoreData.artistsIndex;
            const artists = artistStoreData.artists;
            const config = configStoreData.config;


            let schedule = artistStore.getArtistScheduleForDate(artistId, date);

            let aptConfig = setAptConfig(session, artistsIndex, artists, config, schedule);

            let payload = {
                id: idGenerator("dirty-apt"),
                dirty: true,
                artistId: artistId,
                salonId: salonId,
                date: date,
                startTime: startTime,
                imageId: imageId,
                imageURL: imageURL,
                serviceId: serviceId,
                type: JOURNAL_ELEMENT_APPOINTMENT
            };
            payload = Object.assign({}, payload, aptConfig);

            AppDispatcher.dispatch({
                type: APPOINTMENT_ADD,
                payload: payload
            });

            AppDispatcher.dispatch({
                type: BOOK_INITIALS_LOADED,
                payload: {
                    mode,
                    userInfo: sessionStore.getSession(),
                    salonInfo: bookAptStores.artist.getSalonInfo()
                }

            });


            AppDispatcher.dispatch({
                type: MODAL_CHANGE,
                payload: {
                    type: BOOK_MODAL
                }
            });

        }, violations => {
            console.log("violations", violations);

            let message = "Error on book process. Sorry for inconvenience.";

            if (violations.message) {
                message += "<br />" + violations.message;
            }

            notificationSystem.alert(message);
        });


    }, response => {
        console.log("Not complete, skip");
    });


};


export const startEditing = ({ mode, addressId = 0, salonId = 0, artistId = 0, salonClient = null, salonClientId = 0, date }) => {
    AppDispatcher.dispatch({
        type: BOOK_START_BOOKING,
        payload: {
            mode,
            addressId,
            salonId,
            artistId,
            action: BOOK_APPOINTMENT_EDIT,
            salonClient: salonClient,
            date

        }
    });

    bookingPreloading(artistId, salonId).then(({ artistStoreData, configStoreData }) => {

        AppDispatcher.dispatch({
            type: BOOK_START_EDIT,
            payload: {
                mode, addressId, salonId,
                salonClientId: salonClient ? salonClient.id : salonClientId,
                date, artistId
            }
        });

        AppDispatcher.dispatch({
            type: MODAL_CHANGE,
            payload: {
                type: BOOK_MODAL
            }
        });

    }, violations => {

        let message = "Error on book process. Sorry for inconvenience.";

        if (violations.message) {
            message += "<br />" + violations.message;
        }

        notificationSystem.alert(message);
    });


};


export function addApt(apt) {

    AppDispatcher.dispatch({
        type: BOOK_ANOTHER,
        payload: {}
    });

}


export function removeApt(id) {
    AppDispatcher.dispatch({
        type: APPOINTMENT_REMOVE,
        payload: {
            id: id
        }
    });
}

export function persistFail(report) {

    console.warn("persistFail", report);

    AppDispatcher.dispatch({
        type: BOOK_PERSIST_FAIL,
        payload: report
    });
}

export function persistSuccess(data) {
    AppDispatcher.dispatch({
        type: BOOK_PERSIST_SUCCESS,
        payload: data
    });

    AppDispatcher.dispatch({
        type: MODAL_HIDE
    });

}

export function persistStop(reason) {
    console.warn("persistStop", reason);

    AppDispatcher.dispatch({
        type: BOOK_PERSIST_STOP
    });
}

export function persist() {

    let syncer = new BookAptSyncer(bookAptStores.artist, bookAptStores.services);

    syncer.addPersistFailCallback(persistFail);
    syncer.addPersistSuccessCallback(persistSuccess);

    AppDispatcher.dispatch({
        type: BOOK_PERSIST,
        payload: {
            syncer
        }
    });

}
