/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 7.8.17
 */

import AppDispatcher from "./../dispatcher";

import { NOTIFY_FAIL, NOTIFY_SUCCESS } from "./../constants";
import { xhrEvents } from "../service/api-client-2/events";


export function notifySuccess(message = "Saved", options = {}) {

    AppDispatcher.dispatch({
        type: NOTIFY_SUCCESS,
        payload: {
            message, options
        }
    });
}

export function notifyFail(message = "Save fail", options = {}) {

    AppDispatcher.dispatch({
        type: NOTIFY_FAIL,
        payload: {
            message, options
        }
    });
}

xhrEvents.requestFailed.add(request => {
    if (!request.isNotificationsSuppressed()) {
        notifyFail(request.getFailNotificationMessage());
    }
});