/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.4.17
 * Time: 10.37
 */

import ApiClient from "../service/api-client";

export default function xhrLoadClientByPhone(phone) {
    return ApiClient.get(["Q73_find_salon_client", { clientPhone: phone }]).then((answer) => {
        let client = answer[0].response;

        if (Object.keys(client).length === 0) {
            return Promise.resolve(null);
        }

        return Promise.resolve(client);
    });
}
