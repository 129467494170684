/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 10.10.16
 */

import React, {Component} from 'react'
import Scheduler from './scheduler-component'
import {
    MODE_PROFESSIONAL,

    JOURNAL_ELEMENT_APPOINTMENT,
    JOURNAL_ELEMENT_BLOCK,

    DROPDOWN_PLACEHOLDER_VALUE, LIMIT_BOOK

} from '../../frizo-shared-constants';

import {
    BOOK_MODAL
} from '../../constants';

import Calendar from '../calendar-component'
import moment from 'moment';
import ApiClient from '../../service/api-client';
import MembersFilter from './members-filter-component'
import FrizoBookPopover from '../popover/frizo-book-popover';
import {startBooking, startEditing} from '../../ac/book-apt/common-actions';
import xhrLoadManagedArtists from '../../xhr/managed-artists-xhr';
import {modalsStore, sessionStore, subscriptionsStore} from '../../stores/index';
import {Link} from 'react-router';
import {xhrClientAptsBatchCancel} from './../../xhr/appointments-xhr';

import {appointmentNormalize} from './../../normal-form/appointment';
import {artistNormalizer1} from '../../normal-form/user-derived-forms/artist';
import {blockNormalize} from './../../normal-form/block';

import {buildIndex} from './../../helper/build-index';
import getOffsetRect from './../../helper/get-offset-rect';

import $ from 'jquery';

import AlertDismissable from './../unseen-appointments/alert-dismissable'
import UnseenAppointmentsList from "../unseen-appointments/unseen-appointments-list";

import notificationSystem from '../../service/notification-system';

import {calendarMarkersStore} from './../../stores';
import {reloadMarkers, changeCalendarBounds} from './../../ac/calendar-markers-actions';

import {modalChange} from "../../ac/modals";
import {CLIENT_COMMENT_MODAL} from "../../constants";

import {
    loadSalonInfo,
    removeUnseenAppointmentNotification,
    removeUnseenAppointmentSetNotification
} from './../../ac/journal-actions';

import {xhrNoShowAppointment} from './../../xhr/appointments-xhr';
import {Row} from 'react-bootstrap';
import gradientFill from '../../helper/gradient-fill';
import {CUSTOM_HEADER_U6_GROUPS} from './../../frizo-shared-constants';
import LoaderBar from '../../elements/loader-bar';
import withNonProDisclaimer from "../hoc/non-pro-disclaimer-hoc";

class Journal extends Component {
    constructor() {
        super();

        let date = new Date();
        let todayMidnight = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        let currentMoment = moment.utc(todayMidnight);

        let markersIntervalStart = Date.UTC(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0) / 1000;
        let markersIntervalEnd = Date.UTC(date.getFullYear(), date.getMonth() + 4, 0, 0, 0, 0, 0) / 1000;

        this.state = {
            rawAppointments: [],
            rawBlocks: [],
            data: [],

            checkedArtists: [],
            artists: [],
            artistIndex: {},

            currentMidnight: todayMidnight / 1000,
            currentDate: currentMoment.format('Y-MM-DD'),
            currentDay: currentMoment.day(),

            markersReady: false,
            blockReady: false,
            bookReady: false,
            sessionReady: sessionStore.isReady(),
            markers: [],
            fromDate: markersIntervalStart,
            toDate: markersIntervalEnd,

            unseenAptId: '',

            profileLack: false,
            session: this.getSessionData()
        };


        this.elementUpdateCallback = this.elementUpdateCallback.bind(this);
        this.calendarChangeCallback = this.calendarChangeCallback.bind(this);
        this.memberCheckCallback = this.memberCheckCallback.bind(this);
        this.updateBlockData = this.updateBlockData.bind(this);
        this.updateData = this.updateData.bind(this);

        this.blockAction = this.blockAction.bind(this);
        this.bookAction = this.bookAction.bind(this);
        this.bookAgainAction = this.bookAgainAction.bind(this);
        this.editAction = this.editAction.bind(this);
        this.cancelAction = this.cancelAction.bind(this);
        this.cancelAllAction = this.cancelAllAction.bind(this);
        this.unblockAction = this.unblockAction.bind(this);
        this.noShowAction = this.noShowAction.bind(this);
        this.editClientCommentAction = this.editClientCommentAction.bind(this);

        this.changeMonth = this.changeMonth.bind(this);
        this.onModalHide = this.onModalHide.bind(this);

        this.clickCalendarNavArrows = this.clickCalendarNavArrows.bind(this);
        this.markersDataChange = this.markersDataChange.bind(this);
        this.sessionDataChange = this.sessionDataChange.bind(this);

    };


    elementUpdateCallback(patchCollection) {

        // let origin = this.state.data.concat();

        patchCollection.forEach(updateUnit => {

            let [dataElement, patch] = updateUnit;

            delete dataElement.origin;

            //copy origin element
            let origin = Object.assign({}, dataElement);

            //mutate origin
            let newData = Object.assign(dataElement, patch, {
                dirty: true
            });

            let artist = this.state.artistIndex[newData.artistId];
            let addressId = Number(artist.addressSchedule[this.state.currentDay].id)

            switch (dataElement.type) {
                case JOURNAL_ELEMENT_BLOCK:
                    ApiClient.put(['Q2-3-block-put', {
                        id: dataElement.id
                    }], JSON.stringify({

                        startTimestamp: Number(newData.startTimestamp),
                        durationTime: Number(newData.endTimestamp - newData.startTimestamp),
                        masterUser: Number(newData.artistId),
                        address: addressId

                    }), {
                        headers: {'Content-Type': 'application/json'},
                        enableDefaultNotifications: false
                    }).then(answer => {
                        let [response] = answer;
                        console.log('data', response);


                        Object.assign(dataElement, response, {
                            dirty: false
                        });

                        //clarify
                        this.setState({
                            data: this.state.data.concat()
                        });

                    }, answer => {
                        console.log('fail data', answer);

                        let [response] = answer;

                        let message;
                        if (response.errors) {
                            message = Object.keys(response.errors).map(key => {
                                return response.errors[key].join('<br />');
                            });
                        }
                        else {
                            message = 'Save failed';
                        }

                        notificationSystem.logError(message);

                        Object.assign(dataElement, origin, {
                            dirty: false
                        });

                        //rollback
                        this.setState({
                            data: this.state.data.concat()
                        });
                    });

                    break;

                case JOURNAL_ELEMENT_APPOINTMENT:

                    let url = ApiClient.getRouting().generate('Q2-6-appointment-put', {
                        id: dataElement.id
                    });

                    ApiClient.put(url, JSON.stringify({
                        startTimestamp: Number(newData.startTimestamp),
                        processingTime: Number(newData.endTimestamp - newData.startTimestamp),
                        artistId: Number(newData.artistId),
                        addressId,
                        bookMode: MODE_PROFESSIONAL
                    }), {
                        headers: {
                            'Content-Type': 'application/json',
                            [CUSTOM_HEADER_U6_GROUPS]: ['basic', 'time_interval', 'apt-confirm']
                        },
                        enableDefaultNotifications: false
                    }).then(answer => {

                        let [response] = answer;
                        console.log('data', response);

                        Object.assign(dataElement, appointmentNormalize(response), {
                            dirty: false
                        });

                        //clarify
                        this.setState({
                            data: this.state.data.concat()
                        });

                    }, answer => {
                        console.log('fail data', answer);
                        let [response] = answer;

                        let message;
                        if (response.errors) {
                            message = Object.keys(response.errors).map(key => {
                                let errors = response.errors[key];
                                return Array.isArray(errors) ? errors.join('<br />') : errors;
                            });
                        }
                        else {
                            message = 'Save failed';
                        }

                        notificationSystem.logError(message);

                        Object.assign(dataElement, origin, {
                            dirty: false
                        });

                        //rollback
                        this.setState({
                            data: this.state.data.concat()
                        });
                    });

                    break;

                default:
                    throw new Error("Unknown dataElement type");
            }


        });

        this.setState({
            data: this.state.data.concat()
        });
    }


    getPaddingForElement(element, nextElement) {
        /*
         do not set padding between apts of one client, spec changed

        if (element.type !== JOURNAL_ELEMENT_APPOINTMENT || nextElement.type !== JOURNAL_ELEMENT_APPOINTMENT) return 0;
        if (element.client !== nextElement.client) return 0;

        let element1Processing = element.endTimestamp - element.startTimestamp;
        let element1Duration = element.duration;

        if (element1Duration > element1Processing) {
            return element1Duration - element1Processing;
        }
         */

        return 0;

    }

    showDoorClosedMessage() {
        if (!subscriptionsStore.store.showAlert) {
            let s = "Your door sign says “CLOSED”.<br />You may still book appointments for the selected date";
            notificationSystem.alert(s);
        }
    }

    calendarChangeCallback(newTime, unseenId = 0) {

        let date = new Date(newTime.date);
        let midnight = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

        let currentDay = date.getDay();

        //check only available artists today
        let availableArtists = this.filterAvailableArtists(this.state.artists, currentDay);

        if (availableArtists.length === 0) {
            if (this.state.artists.length === 1) {
                //if only one user exists, select it
                availableArtists = [this.state.artists[0]];
            }
            else {
                availableArtists = [].concat(this.state.artists);
            }
            this.showDoorClosedMessage();
        }


        let unseenAptId = this.state.unseenAptId === unseenId ? '' : unseenId;

        this.setState({
            currentMidnight: midnight / 1000,
            currentDate: moment.utc(midnight).format('Y-MM-DD'),
            currentDay: currentDay,
            checkedArtists: availableArtists.map(artist => artist.id),
            unseenAptId: unseenAptId ? `apt${unseenAptId}` : ''
            // selectedMembers: artists.filter(member => this.state.checkedArtists.indexOf(member.key) !== -1),

        }, this.updateData);
    }

    getSelectedArtists() {
        return this.state.artists.filter(artist => this.state.checkedArtists.indexOf(artist.id) !== -1);
    }

    updateBlockData() {

        let request = this.getSelectedArtists().map(artist => {
            return {
                id: artist.id,
                from: moment.utc(this.state.currentDate).unix(),
                to: moment.utc(this.state.currentDate).endOf('day').unix() + 1
            };
        });

        return ApiClient.post(['Q2-2-get-block-data'], JSON.stringify(request), {
            headers: {'Content-Type': 'application/json'}
        }).then(answer => {

            let [data] = answer;

            let prepared = this.prepareData(this.state.rawAppointments, data);

            this.setState({
                data: prepared,
                rawBlocks: data,
                blockReady: true
            });

        });

    }

    updateData() {

        this.updateBlockData();

        let url = ApiClient.getRouting().generate('Q2-1-get-book-data', {
            midnightTimestamp: this.state.currentMidnight,
            member: this.state.checkedArtists
        });

        ApiClient.get(url, {}, {
            headers: [{
                name: CUSTOM_HEADER_U6_GROUPS,
                value: ['basic', 'apt-confirm']
            }]
        }).then((data) => {

            let prepared = this.prepareData(data[0], this.state.rawBlocks);

            this.setState({
                data: prepared,
                rawAppointments: data[0],
                bookReady: true
            });

        });
    }

    prepareData(rawAppointments, rawBlocks) {

        let aptSet = rawAppointments
            .filter(apt => apt.startTimestamp) //filter out apts without startTimestamp
            .map(apt => appointmentNormalize(apt));

        let blocks = rawBlocks.map(block => blockNormalize(block));

        return aptSet.concat(blocks);
    }

    /**
     * Leave only available artists at day
     *
     * @param artists
     * @param day
     */
    filterAvailableArtists(artists, day) {
        return artists.filter(artist => artist.availableSchedule[day].length > 0);
    }

    componentDidMount() {
        sessionStore.getReadyPromise().then(store => {
            loadSalonInfo();
            xhrLoadManagedArtists().then(artists => {

                let artistSet = artists.map(artistNormalizer1).filter(artist => {
                    return artist.bookable;
                });

                if (artistSet.length === 0) {
                    this.setState({
                        profileLack: true
                    });

                    return;
                }

                //check only available artists today
                let availableArtists = this.filterAvailableArtists(artistSet, this.state.currentDay);


                if (availableArtists.length === 0) {
                    if (artistSet.length === 1) {
                        //if only one user exists, select it
                        availableArtists = [artistSet[0]];
                    }
                    else {
                        availableArtists = [].concat(artistSet);
                    }
                    this.showDoorClosedMessage();
                }
                console.log('availableArtists', artistSet);

                this.setState({
                    artists: artistSet,
                    checkedArtists: availableArtists.map(artist => artist.id),
                    artistIndex: buildIndex(artistSet)
                }, this.updateData);
            });

            changeCalendarBounds(this.state.fromDate, this.state.toDate, true);
        });

        //not flux way to interact with stores
        modalsStore.onHide(this.onModalHide);

        calendarMarkersStore.addChangeListener(this.markersDataChange);
        sessionStore.addChangeListener(this.sessionDataChange);
    }

    componentWillUnmount() {
        let mainContainer = document.querySelector("#main");
        if (mainContainer) {
            mainContainer.classList.remove("full-width");
        }
        calendarMarkersStore.removeChangeListener(this.markersDataChange);
        sessionStore.removeChangeListener(this.sessionDataChange);
    }

    componentDidUpdate() {
        let mainContainer = document.querySelector("#main");
        if (mainContainer) {
            mainContainer.classList.add("full-width");
        }
    }

    markersDataChange() {
        this.setState({
            markers: this.getMarkersData(),
            markersReady: true
        });
    }

    sessionDataChange() {
        this.setState({
            session: this.getSessionData(),
            sessionReady: true
        })
    }

    getSessionData() {
        return {}
    }

    getMarkersData() {
        return calendarMarkersStore.getStore().markers;
    }


    onModalHide(modalType) {
        if (modalType === BOOK_MODAL) {
            this.updateData();
            reloadMarkers(this.state.fromDate, this.state.toDate);
        }
    }

    changeMonth(month, year) {

        let from = Date.UTC(year, month, 1, 0, 0, 0, 0) / 1000;
        let to = Date.UTC(year, month + 4, 0, 0, 0, 0, 0) / 1000;

        this.setState({
            fromDate: from,
            toDate: to
        });

        changeCalendarBounds(from, to);
    }

    bookAction(originContext, originEvent) {

        startBooking(
            MODE_PROFESSIONAL,
            originContext.slotTriggeredMember.id,
            originContext.slotTriggeredMember.salon.id,
            originContext.startPointDate,
            originContext.slotTriggeredStartTime
        );

    }

    bookAgainAction(originContext, originEvent) {
        startBooking(
            MODE_PROFESSIONAL,
            originContext.elementTriggered.artistId,
            originContext.slotTriggeredMember.salon.id,
            moment(new Date()).format('YYYY-MM-DD'),
            DROPDOWN_PLACEHOLDER_VALUE,
            originContext.elementTriggered.service.id,
            0, null,
            originContext.slotTriggeredAddress.id,
            originContext.elementTriggered.salonClient
        );

    }

    cancelAction(originContext, originEvent) {

        if (originContext.elementTriggered.type !== JOURNAL_ELEMENT_APPOINTMENT) {
            throw new Error("You can cancel appointment element only");
        }

        let self = this;

        ApiClient.del(['Q2-7-appointment-delete', {
            id: originContext.elementTriggeredId
        }]).then((data) => {

            console.log('removed', data, self);

            self.updateData();
            reloadMarkers(this.state.fromDate, this.state.toDate);

            removeUnseenAppointmentNotification(originContext.elementTriggeredId);

        }, (data) => {
            console.log('not-removed', data);
        });

    }

    cancelAllAction(originContext, originEvent) {

        if (originContext.elementTriggered.type !== JOURNAL_ELEMENT_APPOINTMENT) {
            throw new Error("You can cancel appointment element only");
        }

        console.log('origin', originContext);

        let self = this;

        xhrClientAptsBatchCancel(
            originContext.elementTriggered.salonClientId,
            originContext.startPointDate,
            originContext.slotTriggeredAddress.id
        ).then((data) => {

            console.log('removed', data, self);

            self.updateData();
            reloadMarkers(this.state.fromDate, this.state.toDate);

            removeUnseenAppointmentSetNotification(data.aptIdSet);

        }, (data) => {
            console.log('not-removed', data);
        });

    }

    unblockAction(originContext, originEvent) {

        if (originContext.elementTriggered.type !== JOURNAL_ELEMENT_BLOCK) {
            throw new Error("You can cancel block element only");
        }

        let self = this;

        ApiClient.del(['Q2-4-block-delete', {
            id: originContext.elementTriggeredId
        }]).then((data) => {

            console.log('removed', data, self);

            self.updateData();
            reloadMarkers(this.state.fromDate, this.state.toDate);

        }, (data) => {
            console.log('not-removed', data);
        });
    }

    noShowAction(originContext) {
        xhrNoShowAppointment(originContext.elementTriggeredId).then(response => {
            this.updateData();

        });
    }

    blockAction(startTimestamp, endTimestamp, originContext) {

        /*
         Use tzShift to compensate timezone difference
         nulled such as Q57 use pseudo timestamps

        let tzShift = moment.utc(originContext.startPointDate).unix() - originContext.midnight;

         */

        let tzShift = 0;

        return ApiClient.post(['Q57_block_time'], {
            userID: originContext.slotTriggeredMemberKey,
            dates: moment.utc(originContext.startPointDate).unix(),
            addressList: originContext.slotTriggeredAddress.id,
            from: Number(startTimestamp) - tzShift,
            to: Number(endTimestamp) - tzShift
        }).then((answer) => {
            console.log('answer', answer);
            this.updateData();
            reloadMarkers(this.state.fromDate, this.state.toDate);
        })
    }

    editAction(originContext) {

        let mode = MODE_PROFESSIONAL;
        let {
            salonClientId,
            addressId,
            salonId,
            artistId
        } = originContext.elementTriggered;

        let date = originContext.startPointDate;

        const editData = {mode, salonClientId, addressId, date, salonId, artistId};

        startEditing(editData);

    }

    editClientCommentAction(client) {
        modalChange(CLIENT_COMMENT_MODAL, {client})
    }

    memberCheckCallback(memberId, checked, originEvent) {

        let checkedArtists;
        if (checked) {
            checkedArtists = this.state.checkedArtists.concat(memberId);
        }
        else {
            checkedArtists = this.state.checkedArtists.filter(checkedMemberId => memberId !== checkedMemberId);
        }

        this.setState({checkedArtists}, this.updateData);

    }


    clickCalendarNavArrows(arrow) {

        let momentObject = moment(this.state.currentDate);

        switch (arrow) {
            case 'previous':
                momentObject.subtract(1, 'days');
                break;

            case 'next':
                momentObject.add(1, 'days');
                break;

            case 'today':
                momentObject = moment();
                break;

            case 'tomorrow':
                momentObject = moment().add(1, 'days');
                break;

            default:
                console.warn("Unknown navigation control");
                return false;

        }

        $('.datepicker').datepicker("setDate", momentObject.toDate());

        this.calendarChangeCallback({
            date: momentObject.format('MM/DD/Y')
        });

    }

    drawCalendar() {
        //not shown calendar while members not loaded yet
        if (this.state.artists.length === 0) return null;

        let calendarViewPortClass;
        let calendarWidth;
        let maxCalendarsCount;

        //if member selector shown
        if (this.state.artists.length > 1) {
            calendarViewPortClass = 'col-md-9';
            calendarWidth = 0.75;
            maxCalendarsCount = 4;// 3;
        }
        else {
            calendarViewPortClass = 'col-md-12';
            calendarWidth = 1;
            maxCalendarsCount = 5; //4;
        }

        let fromDate = moment.utc(this.state.currentDate).unix();

        return <div className={calendarViewPortClass}>
            <Calendar viewportWidth={calendarWidth} fromDate={fromDate}
                      change={this.calendarChangeCallback} changeMonth={this.changeMonth}
                      markers={this.state.markers}
                      maxCalendarsCount={maxCalendarsCount}
            />
        </div>;
    }

    scrollToApt() {
        if (this.state.unseenAptId) {

            return () => {

                let DOMNode = document.getElementById(this.state.unseenAptId);

                if (DOMNode) {
                    let rect = getOffsetRect(DOMNode);
                    setTimeout(window.scrollTo(0, rect.top - 100), 100);
                }
            }
        }

        return null;
    };

    createScheduler() {
        let types = {
            block: {
                classes: ['schedule-element-block'],
                style: {
                    backgroundColor: '#E64650',
                    color: 'white'
                }
            },
            appointment: {
                classes: ['schedule-element-appointment'],
                style: {
                    color: 'white'
                }
            }
        };

        let dialogPopover = <FrizoBookPopover
            ref={(node) => {
                this.popover = node;
            }}
            id="scheduler-popover"
            blockAction={this.blockAction}
            bookAction={this.bookAction}
            bookAgainAction={this.bookAgainAction}
            editAction={this.editAction}
            cancelAction={this.cancelAction}
            cancelAllAction={this.cancelAllAction}
            unblockAction={this.unblockAction}
            noShowAction={this.noShowAction}
            editClientCommentAction={this.editClientCommentAction}
        />;

        return <Scheduler
            ref={(node) => {
                this.scheduler = node;
            }}
            types={types}
            selectedDate={this.state.currentDate}
            data={this.state.data}
            members={this.getSelectedArtists()}
            moveElementCallback={this.moveElementCallback}
            elementUpdateCallback={this.elementUpdateCallback}
            getPaddingForElement={this.getPaddingForElement}
            clickMenuConfigure={(context) => {
                console.log('clickMenuConfigure', context);
                console.log('Enjoy:');
                console.log('Artist', context.slotTriggeredMemberKey);
                console.log('at address', context.slotTriggeredAddress.id);
                console.log('on timestamp', Number(context.midnight) + Number(context.slotTriggeredStartTime));

                this.popover.setContextData(context);
            }}
            popoverElement={dialogPopover}
            scrollToApt={this.scrollToApt()}
        />;
    }

    render() {
        let body;
        let showNavigationBar = false;
        let isReady = this.state.sessionReady && this.state.blockReady && this.state.bookReady && this.state.markersReady;

        switch (true) {

            case this.state.profileLack:
                body = <div className={"loader-msg-wrapper"}>
                    <div className={"loader-msg-wrapper--item"}>
                        Your Profile's missing Services and/or an Artist with assigned services.
                        <br/>
                        <Link to="/my-salon">Click here to go to Profile</Link>
                    </div>
                </div>;

                break;

            case !isReady:
                let className = !this.state.artists.length ? "loader-msg-wrapper--full-height" : "";
                body = <LoaderBar className={className}/>

                break;

            default:
                body = this.createScheduler();
                showNavigationBar = true;
                break;
        }

        return (
            <div className="">
                <div className="fr-banner-top  fr-full-width fr-full-width__book"
                     style={{'background': gradientFill()}}>
                    <Row className="fr-container-width">
                        <div className="clearfix ">
                            <div className="pull-left fr-book__journal-title">
                                Book
                            </div>
                            <div className="alert-wrapper">
                                <AlertDismissable/>
                            </div>

                            {showNavigationBar && <div className="pull-right calendar-nav">
                                <button className="btn btn-default" onClick={() => {
                                    this.clickCalendarNavArrows('previous')
                                }}>
                                    <span className="glyphicon glyphicon-arrow-left"></span>
                                </button>
                                <button className="btn btn-default" onClick={() => {
                                    this.clickCalendarNavArrows('today')
                                }}>
                                    Today
                                </button>
                                <button className="btn btn-default" onClick={() => {
                                    this.clickCalendarNavArrows('tomorrow')
                                }}>
                                    Tomorrow
                                </button>
                                <button className="btn btn-default" onClick={() => {
                                    this.clickCalendarNavArrows('next')
                                }}>
                                    <span className="glyphicon glyphicon-arrow-right"></span>
                                </button>

                            </div>}
                        </div>
                    </Row>
                </div>

                <UnseenAppointmentsList onItemClick={this.calendarChangeCallback}/>

                <div className="row">
                    <div className="clearfix row">
                        {this.drawCalendar()}
                        <div className="col-md-3">
                            <MembersFilter members={this.state.artists} checked={this.state.checkedArtists}
                                           onCheck={this.memberCheckCallback}/>
                        </div>
                    </div>
                </div>

                {body}

            </div>

        )
    }
}

let WrappedComponent = withNonProDisclaimer(Journal, {
    nonPro: true,
    limits: [LIMIT_BOOK]
});

WrappedComponent.requireData = ['subscription-tier-2'];

export default WrappedComponent;