/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.12.4
 * Time: 10:49
 */

import ApiClient from "../service/api-client";
import notificationSystem from "../service/notification-system";
import { xhr } from "../service/api-client-2";

export function xhrLoadSalonArtists(salonId, limit, offset) {

    const url = ApiClient.getRouting().generate("Q34_5_salon_artists", { salonId });

    return ApiClient.get(url, { limit, offset })
    .then(answer => {
        return answer[0];
    });
}

export function xhrArtistAddEdit(data) {

    return xhr()
    .suppressNotifications()
    .post("@Q41_add_or_edit_master")
    .data(data)
    .end()
    .then((request: Request) => {

        let { response } = request;

        return response.response;
    });
}

export function xhrRemoveArtist(id) {

    return ApiClient.post(["Q45_remove_artist"], { userID: id }, {
        enableDefaultNotifications: false
    }).then(answer => {
        let [response] = answer;

        return response;
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}

export function xhrSendArtistInvitation(id) {

    return ApiClient.post(["Q46_send_artist_invitation"], { userID: id }, {
        enableDefaultNotifications: true
    }).then(answer => {
        let [response] = answer;

        notificationSystem.logSuccess("Invitation sent");

        return response;
    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
}
