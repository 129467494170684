/**
 * Created by Ihnat, Kryshchyk
 * Company: U6 SIA
 * Date: 20.03.2017
 * Time: 10:28
 */

import React, { Component } from "react";

import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import ImagePreview from "../components/book-apt/image-preview";

import { Badge, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import apiClient from "./../service/api-client";

import config from "../config";
import { renewToken } from "../auth";

export default class DropCrop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tempImageUrl: "",
            imgType: props.imgType || "appointment",
            /* for  ImagePreview */
            imageId: props.imageId || "",
            imageURL: props.imageURL || "",
            previewId: props.previewId,

            orientaion: props.orientaion,
            errors: []
        };
    }

    onDrop(acceptedFiles, rejectedFiles) {
        let errors = [];
        if (rejectedFiles.length) {
            if (rejectedFiles[0].type !== "image/jpeg" && rejectedFiles[0].type !== "image/png") {
                errors.push(" Wrong file format. ");
            }
            if (rejectedFiles[0].size > config.maxUploadFileSize) {
                errors.push(" File's too big. ");
            }
        }
        if (acceptedFiles.length) {
            this.setState({ tempImageUrl: URL.createObjectURL(acceptedFiles[0]) });
        }
        this.setState({ errors: errors });
    }

    onSave() {
        if (typeof this.cropper.getCroppedCanvas() !== "undefined") {
            this.imageFile = "imgData=" + this.cropper.getCroppedCanvas().toDataURL("image/jpeg");
            // this.props.onImageChange({status: 'save', imageFile: this.imageFile});
            this.loadImage(this.imageFile);
        }
    }

    loadImage(imageDataURL) {


        function dataURLtoBlob(dataurl) {
            let arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }

        let blob = dataURLtoBlob(imageDataURL);
        let fd = new FormData();
        fd.append("file", blob, "test.jpeg");
        let xhr = new XMLHttpRequest();
        let self = this;

        let url = apiClient.getRouting().generate("frizo_image_upload", {
            image_type: self.state.imgType
        });

        xhr.open("POST", url, true);
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let serverResponse = JSON.parse(xhr.responseText);
                self.setState({ imageId: serverResponse.imageId, imageURL: serverResponse.url });
                self.props.onImageChange({
                    imageId: serverResponse.imageId,
                    imageURL: serverResponse.url
                });
            }
        };


        renewToken().then(({ success }) => {
            if (success) xhr.send(fd);
        });
    }

    onCancel() {
        this.setState({ tempImageUrl: "" });
    }

    onRemove() {
        this.setState({
            imageId: "",
            imageURL: ""
        });
        this.props.onImageChange({ imageId: "", imageURL: "" });
    }

    render() {
        let styles = this.props.style || { height: "auto", width: "auto" };
        let uploadText = this.props.uploadText || "Tap to upload image";
        let qualityText = this.props.qualityText ||
            <div><br/><br/>JPG or PNG<br/>up 10 Megabytes,<br/>Portrait oriented,<br/>900x1200 pixels or better</div>;
        let crop = (
            <div>
                <Cropper
                    src={this.state.tempImageUrl}
                    ref={cropper => {
                        this.cropper = cropper;
                    }}
                    style={styles}
                    aspectRatio={3 / 4}
                />
                <Button className="fr-uploader-remove-btn btn-link pull-left"
                        onClick={this.onCancel.bind(this)}>Cancel</Button>
                <Button className="fr-btn btn pull-right" onClick={this.onSave.bind(this)}>Crop</Button>
            </div>
        );

        let drop = (
            <Dropzone
                multiple={false}
                maxSize={config.maxUploadFileSize}
                accept={"image/jpeg, image/png"}
                onDrop={this.onDrop.bind(this)}
                rejectClassName="fr-drop-crop-reject"
            >
                {({ getRootProps, getInputProps, isDragActive }) => {
                    return <div
                        {...getRootProps()}
                        style={styles}
                        className={this.state.errors.length ? "fr-drop-crop-reject" : "fr-drop-crop"}
                    >
                        <input {...getInputProps()} />
                        {
                            <div className="fr-drop-crop-title drop-crop__text" style={styles}>
                                <br/><br/><br/><strong>{uploadText}</strong><br/><br/>
                                {!!this.state.errors.length &&
                                <Badge className="fr-drop-crop-reject-badge"> {this.state.errors}</Badge>}
                                {qualityText}
                            </div>
                        }
                    </div>;
                }}

            </Dropzone>


        );
        let imagePreview = (
            this.state.imageURL.length && <ImagePreview
                style={styles}
                orientation={this.state.orientation}
                previewId={this.props.previewId}
                rejected={this.state.rejected}
                img={this.state.imageURL}
                removeBtnCaption={this.props.removeBtnCaption}
                onRemoveImg={this.onRemove.bind(this)}/>
        );

        let block;

        if (this.state.imageURL) {
            block = imagePreview;
        } else if (this.state.tempImageUrl) {
            block = crop;
        } else {
            block = drop;
        }

        return (
            <div className="drop-crop">
                {block}
            </div>
        );
    }
}

DropCrop.propTypes = {
    onImageChange: PropTypes.func,
    imageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    imageURL: PropTypes.string,
    imgType: PropTypes.string,
    previewId: PropTypes.string,
    orientaion: PropTypes.string,
    removeBtnCaption: PropTypes.string
};
