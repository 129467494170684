/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 1.7.17
 * Time: 21.36
 */

/**
 * Transform array of objects to indexed hash
 * For example you have buildIndex([{a:1}, {a:2}, {a:3}], 'a');
 * Helper will give you {1: {a:1}, 2: {a:2}, 3: {a: 3}}
 *
 * @param arrayOfObjects
 * @param keyPropName
 * @returns {{}}
 */
export function buildIndex(arrayOfObjects, keyPropName = "id") {

    let index = {};

    arrayOfObjects.forEach(element => {
        let indexKey = element[keyPropName];

        index[indexKey] = element;
    });

    return index;
}
