/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 08.11.2018
 * Time: 10:46
 */

import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router';
import gradientFill from '../../helper/gradient-fill';
import {loadPublicArtistDataByUrl} from '../../ac/artist-promo-actions';
import {startBooking} from "../../ac/book-apt/common-actions";
import {artistPromoStore} from '../../stores/index';
import {Img} from "../../elements/img";
import AuthButton from "../../require-auth/auth-button";
import {IMAGE_SIZE_MEDIUM, MODE_CLIENT} from "../../frizo-shared-constants";
import ScreenMessage from "../../elements/screen-message";
import {msgContent} from '../salon-promo/index';
import ArtistPromoGallery from "./artist-promo-gallery";
import moment from "moment/moment";


const msgErrors = (
    <div className="screen-message__block-content">
        <p>Artist not found.<br/> Click <Link to="/">here</Link> to safety</p></div>);


export default class ArtistPromo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artist: {
                id: 0,
                firstName: '',
                lastName: '',
                isPublic: null,
                image: {},
                loaded: false
            },
            salon: {},
            fixedHeader: false,
            errors: {}
        };
        this.storeChange = this.storeChange.bind(this);
        this.onScrollResizeHandler = this.onScrollResizeHandler.bind(this);

    }

    componentDidMount() {
        artistPromoStore.addChangeListener(this.storeChange);
        window.addEventListener('scroll', this.onScrollResizeHandler);
        window.addEventListener('resize', this.onScrollResizeHandler);
        loadPublicArtistDataByUrl(this.props.params.url);
    }

    componentWillUnmount() {
        artistPromoStore.removeChangeListener(this.storeChange);
        window.removeEventListener('scroll', this.onScrollResizeHandler);
        window.removeEventListener('resize', this.onScrollResizeHandler);
    }

    getStoreData() {
        return artistPromoStore.getStore();
    }

    storeChange() {
        this.setState(this.getStoreData());
    }

    onScrollResizeHandler() {
        let headerWrap = document.querySelector("#header-wrap");
        let filterWrap = document.querySelector("#artist-promo-header");
        let headerHeight = document.querySelector('#header-wrap').getBoundingClientRect().height;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let fixed;

        if (scrollTop - 5 >= headerHeight) {
            filterWrap.classList.add("fr-artist-promo-header--fixed");
            headerWrap.setAttribute('display', 'none');
            fixed = true;


        } else {
            filterWrap.classList.remove("fr-artist-promo-header--fixed");
            filterWrap.classList.add("fr-artist-promo-header");
            headerWrap.setAttribute('display', '');
            fixed = false;

        }
        this.setState({
            fixedHeader: fixed
        });
    }


    bookAction(e) {
        e.preventDefault();
        let date = moment(new Date()).format('YYYY-MM-DD');
        startBooking(
            MODE_CLIENT,
            this.state.artist.id,
            this.state.salon.id,
            date, 0, 0, 0, null);
        return false;
    }


    render() {
        let isMobile = window.innerWidth < 769;

        return (
            <div>
                <div className={"fr-artist-promo-header"} id={"artist-promo-header"}>
                    <div className="fr-banner-top fr-full-width artist-promo"
                         style={this.state.fixedHeader ? {
                             'backgroundColor': 'rgba(255, 255, 255, 0.7)'
                         } : {'background': gradientFill()}} id={"artist-banner"}>
                        {this.state.loaded === true && <Row className="fr-container-width">
                            <Col xs={10} md={10}>
                                <div className="fr-banner-top__artistName">
                                    <div className={"fr-banner-top__artistName--title"}>
                                        Work imagery by &nbsp;
                                        {this.state.artist.image && !isMobile &&
                                        <div className={"fr-promo-image--cropper"}>
                                            <Img size={IMAGE_SIZE_MEDIUM} className="avatar"
                                                 src={this.state.artist.image.url}
                                                 description={"Avatar"}/></div>}
                                        {!this.state.artist.image && !isMobile &&
                                        <div className="fr-promo-image--glyphicon"><span
                                            className="glyphicon glyphicon-user"
                                        ></span></div>}
                                    </div>
                                    <div className={"fr-banner-top__artistName--title xs-left"}>
                                        &nbsp;{this.state.artist.firstName + ' ' + this.state.artist.lastName}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} md={2}>
                                <div className={"fr-xs-center"}>
                                    <AuthButton onClick={this.bookAction.bind(this)}
                                                display={this.state.artist.isPublic === true}
                                                className="btn fr-btn fr-promo--book">BOOK</AuthButton>
                                </div>
                            </Col>
                        </Row>}
                        {this.state.errors.length > 0 && <Row className="fr-container-width">
                            <Col xs={12} md={12}>
                                <div className="fr-artist-promo--error">
                                    Houston, we've got a problem
                                </div>
                            </Col>
                        </Row>}
                    </div>
                </div>
                <div className={"container fr-tab-content"}>
                    {this.state.errors.length > 0 && <ScreenMessage message={msgErrors}/>}
                    {this.state.artist.isPublic === false && <ScreenMessage message={msgContent}/>}
                    {this.state.artist.id > 0 && this.state.artist.isPublic === true &&
                    <ArtistPromoGallery artistId={this.state.artist.id} salonId={this.state.salon.id}/>}
                </div>
            </div>
        )
    }
}

