/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.9.3
 * Time: 16:12
 */

import React, {Component} from 'react';
import {artistStore, servicesStore, bookAptStore, bookAptConfStore} from '../../stores/index'
import {MODE_PROFESSIONAL} from '../../frizo-shared-constants'
import moment from 'moment';
export default class ToatalCharge extends Component {

    constructor(...args) {
        super(...args);
        this.state = {
            appointments: [],
            salonServices: {},
            artistsServices: {},
            ready: false
        };
        this.handleAppointmentsChange = this.handleAppointmentsChange.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
    }

    componentDidMount() {
        bookAptStore.addChangeListener(this.handleAppointmentsChange);
        artistStore.addChangeListener(this.handleAppointmentsChange);
        servicesStore.addChangeListener(this.handleAppointmentsChange);
    }

    componentWillUnmount() {
        bookAptStore.removeChangeListener(this.handleAppointmentsChange);
        artistStore.removeChangeListener(this.handleAppointmentsChange);
        servicesStore.removeChangeListener(this.handleAppointmentsChange);
    }



    handleAppointmentsChange() {
        this.setState({
            appointments: bookAptStore.getAppointments(),
            salonServices: servicesStore.getStore().salonServicesIndex,
            artistsServices: servicesStore.getStore().artistServicesIndex,
            ready: bookAptStore.isReady() && servicesStore.isReady()

        });

    }

    getService(obj, index, id) {
        if (typeof obj[index][id] !== 'undefined') return obj[index][id];
        return null;
    }

    getServiceFieldValue(service, fieldName) {
        if (typeof service[fieldName] !== 'undefined') return service[fieldName];
        return false;
    }


    getArtistService(artistId, serviceId) {
        return this.getService(this.state.artistsServices, artistId, serviceId);

    }

    getSalonService(salonId, serviceId) {

        return this.getService(this.state.salonServices, salonId, serviceId)

    }

    getFieldValue(fieldName) {

        return (salonId, serviceId, artistId) => {
            let salonService = this.getSalonService(salonId, serviceId);
            let artistService = this.getArtistService(artistId, serviceId);
            let fieldValue = 0;

            if (artistService && this.getServiceFieldValue(artistService, fieldName) && artistService[fieldName] > 0) {
                return artistService[fieldName];
            }

            if (salonService && this.getServiceFieldValue(salonService, fieldName) && salonService[fieldName] > 0) {
                return salonService[fieldName];
            }

            return fieldValue;
        }

    }


    getServiceDuration(salonId, serviceId, artistId) {

        const getDuration = this.getFieldValue('durationValue');

        return getDuration(...arguments);

    }

    getServicePrice(salonId, serviceId, artistId) {
        const getPrice = this.getFieldValue('price');

        return getPrice(...arguments);
    }


    getTotal() {
        let priceTotal = 0;
        let durationTotal = 0;

        if (this.state.ready) {
            this.state.appointments.forEach((apt) => {
                let {serviceId, salonId, artistId} = apt;

                if (!salonId || !serviceId || !artistId) return true;

                durationTotal += this.getServiceDuration(salonId, serviceId, artistId);
                priceTotal  +=  this.getServicePrice(salonId, serviceId, artistId);

            });
        }

        return {priceTotal, durationTotal}
    }


    render() {
        let mode = bookAptConfStore.getMode();
        if (mode === MODE_PROFESSIONAL || !this.state.ready){
            return null;
        }
        let total = this.getTotal();
        return <div className="book-total">
           <span>Total charges </span><span>${total.priceTotal}</span> &bull; <span>{moment.utc(total.durationTotal * 1000).format('H')}h {moment.utc(total.durationTotal * 1000).format('mm')}m </span>
        </div>
    }
}