/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 2.8.17
 */

import alertify from "alertify.js";
import config from "../config";

export const LOG_TYPE_SUCCESS = "success";
export const LOG_TYPE_ERROR = "error";
export const LOG_TYPE_INFO = "info";

class NotificationSystem {

    alert(message, okBtn = "OK") {
        return alertify
        .okBtn(okBtn)
        .alert(message);
    }

    prompt(message, defaultValue) {
        return alertify
        .defaultValue(defaultValue)
        .prompt(message);
    }

    confirm(message, okBtn = "OK", cancelBtn = "Cancel") {
        return alertify
        .okBtn(okBtn)
        .cancelBtn(cancelBtn)
        .confirm(message);
    }

    logSuccess(message, options = {}) {
        options.type = LOG_TYPE_SUCCESS;

        return this.log(message, options);
    }

    logError(message, options = {}) {
        options.type = LOG_TYPE_ERROR;

        if (!options.delay) {
            options.delay = 0;
            options.closeLogOnClick = true;
        }

        return this.log(message, options);
    }

    /**
     * Generic log method
     *
     * @param message
     * @param options
     */
    log(message, options) {

        let delay = options.delay;
        if (delay === undefined) {
            delay = config.notification.delay;
        }

        alertify.reset();

        let position = options.position;
        if (!position) {
            position = "top right";
        }
        alertify.logPosition(position);

        if (options.closeLogOnClick) {
            alertify.closeLogOnClick(true);
        }

        let alert = alertify.delay(delay);

        switch (options.type) {
            case LOG_TYPE_SUCCESS:

                return alert.success(message);


            case LOG_TYPE_ERROR:

                return alert.error(message);

            default:
            case LOG_TYPE_INFO:

                return alert.log(message);
        }
    }
}

export default new NotificationSystem();
