/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 06.03.2018
 * Time: 11:10
 */
let informerConfig = {
    services: [
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 1,
            "service": "Women's haircut",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 2,
            "service": "Men's haircut",
            "gender": 1
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 3,
            "service": "Haircut / blow dry",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 4,
            "service": "Blow dry",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 5,
            "service": "Waves",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 6,
            "service": "Hollywood waves",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 7,
            "service": "Braids",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 8,
            "service": "Updo",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 9,
            "service": "Bridal trial",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 10,
            "service": "Bridal hair",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 11,
            "service": "Extensions",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 12,
            "service": "Single process",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 13,
            "service": "Double process",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 14,
            "service": "Color correction",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 15,
            "service": "Highlights",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 16,
            "service": "Ombre",
            "gender": 0
        },
        {
            "category": 1,
            "category_name": "Hair",
            "identifier": 17,
            "service": "Balayage",
            "gender": 0
        },
        {
            "category": 2,
            "category_name": "Nails",
            "identifier": 18,
            "service": "French manicure",
            "gender": 0
        },
        {
            "category": 2,
            "category_name": "Nails",
            "identifier": 19,
            "service": "Shellac manicure",
            "gender": 0
        },
        {
            "category": 2,
            "category_name": "Nails",
            "identifier": 20,
            "service": "Gel nails",
            "gender": 0
        },
        {
            "category": 3,
            "category_name": "Makeup",
            "identifier": 21,
            "service": "Day makeup",
            "gender": 0
        },
        {
            "category": 3,
            "category_name": "Makeup",
            "identifier": 22,
            "service": "Evening makeup",
            "gender": 0
        },
        {
            "category": 3,
            "category_name": "Makeup",
            "identifier": 23,
            "service": "Bridal trial makeup",
            "gender": 0
        }
    ],
    addresses: [
        {
            "identifier": 0,
            "address": "Boston, MA 02118"
        },
        {
            "identifier": 1,
            "address": "Newark, NJ 07105"
        },
        {
            "identifier": 2,
            "address": "Jersey City, NJ 07304"
        },
        {
            "identifier": 3,
            "address": "Little Falls, NJ 07424"
        },
        {
            "identifier": 4,
            "address": "Freehold, NJ 07728"
        },
        {
            "identifier": 5,
            "address": "New York, NY 10003"
        },
        {
            "identifier": 6,
            "address": "New York, NY 10011"
        },
        {
            "identifier": 7,
            "address": "New York, NY 10012"
        },
        {
            "identifier": 8,
            "address": "New York, NY 10021"
        },
        {
            "identifier": 9,
            "address": "New York, NY 10022"
        },
        {
            "identifier": 10,
            "address": "New York, NY 10028"
        },
        {
            "identifier": 11,
            "address": "New York, NY 10031"
        },
        {
            "identifier": 12,
            "address": "Staten Island, NY 10304"
        },
        {
            "identifier": 13,
            "address": "Bronx, NY 10472"
        },
        {
            "identifier": 14,
            "address": "Mamaroneck, NY 10543"
        },
        {
            "identifier": 15,
            "address": "White Plains, NY 10601"
        },
        {
            "identifier": 16,
            "address": "Brooklyn, NY 11210"
        },
        {
            "identifier": 17,
            "address": "Brooklyn, NY 11214"
        },
        {
            "identifier": 18,
            "address": "Brooklyn, NY 11223"
        },
        {
            "identifier": 19,
            "address": "Brooklyn, NY 11229"
        },
        {
            "identifier": 20,
            "address": "Brooklyn, NY 11234"
        },
        {
            "identifier": 21,
            "address": "Brooklyn, NY 11235"
        },
        {
            "identifier": 22,
            "address": "Brooklyn, NY 11236"
        },
        {
            "identifier": 23,
            "address": "Brooklyn, NY 11249"
        },
        {
            "identifier": 24,
            "address": "Forest Hills, NY 11375"
        },
        {
            "identifier": 25,
            "address": "South Richmond Hill, NY 11419"
        },
        {
            "identifier": 26,
            "address": "Woodhaven, NY 11421"
        },
        {
            "identifier": 27,
            "address": "Jamaica, NY 11432"
        },
        {
            "identifier": 28,
            "address": "Schenectady, NY 12302"
        },
        {
            "identifier": 29,
            "address": "Vestal, NY 13850"
        },
        {
            "identifier": 30,
            "address": "Marilla, NY 14102"
        },
        {
            "identifier": 31,
            "address": "Buffalo, NY 14207"
        },
        {
            "identifier": 32,
            "address": "Wexford, PA 15090"
        },
        {
            "identifier": 33,
            "address": "Jeannette, PA 15644"
        },
        {
            "identifier": 34,
            "address": "Mars, PA 16046"
        },
        {
            "identifier": 35,
            "address": "Wilkes-Barre, PA 18702"
        },
        {
            "identifier": 36,
            "address": "Laurel, MD 20707"
        },
        {
            "identifier": 37,
            "address": "Pikesville, MD 21208"
        },
        {
            "identifier": 38,
            "address": "Fredericksburg, VA 22405"
        },
        {
            "identifier": 39,
            "address": "Winston-Salem, NC 27103"
        },
        {
            "identifier": 40,
            "address": "Belmont, NC 28012"
        },
        {
            "identifier": 41,
            "address": "Lexington, SC 29072"
        },
        {
            "identifier": 42,
            "address": "Columbia, SC 29201"
        },
        {
            "identifier": 43,
            "address": "Cassville, GA 30123"
        },
        {
            "identifier": 44,
            "address": "Gainesville, FL 32605"
        },
        {
            "identifier": 45,
            "address": "Fort Lauderdale, FL 33301"
        },
        {
            "identifier": 46,
            "address": "Delray Beach, FL 33446"
        },
        {
            "identifier": 47,
            "address": "Port Richey, FL 34668"
        },
        {
            "identifier": 48,
            "address": "Birmingham, AL 35213"
        },
        {
            "identifier": 49,
            "address": "Nashville, TN 37206"
        },
        {
            "identifier": 50,
            "address": "Chesterton, IN 46304"
        },
        {
            "identifier": 51,
            "address": "Antioch, IL 60002"
        },
        {
            "identifier": 52,
            "address": "Schaumburg, IL 60173"
        },
        {
            "identifier": 53,
            "address": "Godfrey, IL 62035"
        },
        {
            "identifier": 54,
            "address": "Wichita, KS 67213"
        },
        {
            "identifier": 55,
            "address": "Metairie, LA 70006"
        },
        {
            "identifier": 56,
            "address": "Slidell, LA 70458"
        },
        {
            "identifier": 57,
            "address": "Yukon, OK 73099"
        },
        {
            "identifier": 58,
            "address": "Irving, TX 75060"
        },
        {
            "identifier": 59,
            "address": "El Paso, TX 79938"
        },
        {
            "identifier": 60,
            "address": "Aurora, CO 80014"
        },
        {
            "identifier": 61,
            "address": "Colorado Springs, CO 80922"
        },
        {
            "identifier": 62,
            "address": "Ogden, UT 84401"
        },
        {
            "identifier": 63,
            "address": "Green Valley, AZ 85614"
        },
        {
            "identifier": 64,
            "address": "Los Angeles, CA 90066"
        },
        {
            "identifier": 65,
            "address": "Thousand Oaks, CA 91360"
        },
        {
            "identifier": 66,
            "address": "Lancaster, CA 93536"
        },
        {
            "identifier": 67,
            "address": "Santa Clara, CA 95050"
        },
        {
            "identifier": 68,
            "address": "Roseville, CA 95678"
        },
        {
            "identifier": 69,
            "address": "Maple Valley, WA 98038"
        },
        {
            "identifier": 70,
            "address": "Tacoma, WA 98402"
        }
    ],
    clients: [
        {
            "identifier": 1,
            "name": "Emily",
            "surname": "N.",
            "gender": 0
        },
        {
            "identifier": 2,
            "name": "Emma",
            "surname": "H.",
            "gender": 0
        },
        {
            "identifier": 3,
            "name": "Madison",
            "surname": "M.",
            "gender": 0
        },
        {
            "identifier": 4,
            "name": "Abigail",
            "surname": "V.",
            "gender": 0
        },
        {
            "identifier": 5,
            "name": "Olivia",
            "surname": "J.",
            "gender": 0
        },
        {
            "identifier": 6,
            "name": "Isabella",
            "surname": "K.",
            "gender": 0
        },
        {
            "identifier": 7,
            "name": "Hannah",
            "surname": "P.",
            "gender": 0
        },
        {
            "identifier": 8,
            "name": "Samantha",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 9,
            "name": "Ava",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 10,
            "name": "Ashley",
            "surname": "M.",
            "gender": 0
        },
        {
            "identifier": 11,
            "name": "Sophia",
            "surname": "K.",
            "gender": 0
        },
        {
            "identifier": 12,
            "name": "Elizabeth",
            "surname": "H.",
            "gender": 0
        },
        {
            "identifier": 13,
            "name": "Alexis",
            "surname": "P.",
            "gender": 0
        },
        {
            "identifier": 14,
            "name": "Grace",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 15,
            "name": "Sarah",
            "surname": "L.",
            "gender": 0
        },
        {
            "identifier": 16,
            "name": "Alyssa",
            "surname": "W.",
            "gender": 0
        },
        {
            "identifier": 17,
            "name": "Mia",
            "surname": "Z.",
            "gender": 0
        },
        {
            "identifier": 18,
            "name": "Natalie",
            "surname": "A.",
            "gender": 0
        },
        {
            "identifier": 19,
            "name": "Chloe",
            "surname": "O.",
            "gender": 0
        },
        {
            "identifier": 20,
            "name": "Brianna",
            "surname": "L.",
            "gender": 0
        },
        {
            "identifier": 21,
            "name": "Lauren",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 22,
            "name": "Ella",
            "surname": "W.",
            "gender": 0
        },
        {
            "identifier": 23,
            "name": "Anna",
            "surname": "B.",
            "gender": 0
        },
        {
            "identifier": 24,
            "name": "Taylor",
            "surname": "K.",
            "gender": 0
        },
        {
            "identifier": 25,
            "name": "Kayla",
            "surname": "C.",
            "gender": 0
        },
        {
            "identifier": 26,
            "name": "Hailey",
            "surname": "R.",
            "gender": 0
        },
        {
            "identifier": 27,
            "name": "Jessica",
            "surname": "M.",
            "gender": 0
        },
        {
            "identifier": 28,
            "name": "Victoria",
            "surname": "V.",
            "gender": 0
        },
        {
            "identifier": 29,
            "name": "Jasmine",
            "surname": "J.",
            "gender": 0
        },
        {
            "identifier": 30,
            "name": "Sydney",
            "surname": "K.",
            "gender": 0
        },
        {
            "identifier": 31,
            "name": "Julia",
            "surname": "N.",
            "gender": 0
        },
        {
            "identifier": 32,
            "name": "Destiny",
            "surname": "H.",
            "gender": 0
        },
        {
            "identifier": 33,
            "name": "Morgan",
            "surname": "M.",
            "gender": 0
        },
        {
            "identifier": 34,
            "name": "Kaitlyn",
            "surname": "V.",
            "gender": 0
        },
        {
            "identifier": 35,
            "name": "Savannah",
            "surname": "J.",
            "gender": 0
        },
        {
            "identifier": 36,
            "name": "Katherine",
            "surname": "K.",
            "gender": 0
        },
        {
            "identifier": 37,
            "name": "Alexandra",
            "surname": "P.",
            "gender": 0
        },
        {
            "identifier": 38,
            "name": "Rachel",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 39,
            "name": "Lily",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 40,
            "name": "Megan",
            "surname": "M.",
            "gender": 0
        },
        {
            "identifier": 41,
            "name": "Kaylee",
            "surname": "K.",
            "gender": 0
        },
        {
            "identifier": 42,
            "name": "Jennifer",
            "surname": "H.",
            "gender": 0
        },
        {
            "identifier": 43,
            "name": "Angelina",
            "surname": "P.",
            "gender": 0
        },
        {
            "identifier": 44,
            "name": "Makayla",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 45,
            "name": "Allison",
            "surname": "L.",
            "gender": 0
        },
        {
            "identifier": 46,
            "name": "Brooke",
            "surname": "W.",
            "gender": 0
        },
        {
            "identifier": 47,
            "name": "Maria",
            "surname": "Z.",
            "gender": 0
        },
        {
            "identifier": 48,
            "name": "Trinity",
            "surname": "A.",
            "gender": 0
        },
        {
            "identifier": 49,
            "name": "Lillian",
            "surname": "O.",
            "gender": 0
        },
        {
            "identifier": 50,
            "name": "Mackenzie",
            "surname": "L.",
            "gender": 0
        },
        {
            "identifier": 51,
            "name": "Faith",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 52,
            "name": "Sofia",
            "surname": "W.",
            "gender": 0
        },
        {
            "identifier": 53,
            "name": "Riley",
            "surname": "B.",
            "gender": 0
        },
        {
            "identifier": 54,
            "name": "Haley",
            "surname": "N.",
            "gender": 0
        },
        {
            "identifier": 55,
            "name": "Gabrielle",
            "surname": "H.",
            "gender": 0
        },
        {
            "identifier": 56,
            "name": "Nicole",
            "surname": "M.",
            "gender": 0
        },
        {
            "identifier": 57,
            "name": "Kylie",
            "surname": "V.",
            "gender": 0
        },
        {
            "identifier": 58,
            "name": "Katelyn",
            "surname": "J.",
            "gender": 0
        },
        {
            "identifier": 59,
            "name": "Zoe",
            "surname": "K.",
            "gender": 0
        },
        {
            "identifier": 60,
            "name": "Paige",
            "surname": "P.",
            "gender": 0
        },
        {
            "identifier": 61,
            "name": "Gabriella",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 62,
            "name": "Jenna",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 63,
            "name": "Kimberly",
            "surname": "M.",
            "gender": 0
        },
        {
            "identifier": 64,
            "name": "Stephanie",
            "surname": "K.",
            "gender": 0
        },
        {
            "identifier": 65,
            "name": "Alexa",
            "surname": "H.",
            "gender": 0
        },
        {
            "identifier": 66,
            "name": "Avery",
            "surname": "P.",
            "gender": 0
        },
        {
            "identifier": 67,
            "name": "Andrea",
            "surname": "S.",
            "gender": 0
        },
        {
            "identifier": 68,
            "name": "Leah",
            "surname": "L.",
            "gender": 0
        },
        {
            "identifier": 69,
            "name": "Madeline",
            "surname": "W.",
            "gender": 0
        },
        {
            "identifier": 70,
            "name": "Nevaeh",
            "surname": "Z.",
            "gender": 0
        },
        {
            "identifier": 71,
            "name": "Evelyn",
            "surname": "A.",
            "gender": 0
        },
        {
            "identifier": 72,
            "name": "Maya",
            "surname": "O.",
            "gender": 0
        },
        {
            "identifier": 73,
            "name": "Michael",
            "surname": "L.",
            "gender": 1
        },
        {
            "identifier": 74,
            "name": "Joshua",
            "surname": "S.",
            "gender": 1
        },
        {
            "identifier": 75,
            "name": "Matthew",
            "surname": "W.",
            "gender": 1
        },
        {
            "identifier": 76,
            "name": "Ethan",
            "surname": "B.",
            "gender": 1
        },
        {
            "identifier": 77,
            "name": "Andrew",
            "surname": "K.",
            "gender": 1
        },
        {
            "identifier": 78,
            "name": "Daniel",
            "surname": "C.",
            "gender": 1
        },
        {
            "identifier": 79,
            "name": "Anthony",
            "surname": "P.",
            "gender": 1
        },
        {
            "identifier": 80,
            "name": "Christopher",
            "surname": "M.",
            "gender": 1
        },
        {
            "identifier": 81,
            "name": "Joseph",
            "surname": "V.",
            "gender": 1
        },
        {
            "identifier": 82,
            "name": "William",
            "surname": "J.",
            "gender": 1
        },
        {
            "identifier": 83,
            "name": "Alexander",
            "surname": "K.",
            "gender": 1
        },
        {
            "identifier": 84,
            "name": "Ryan",
            "surname": "P.",
            "gender": 1
        },
        {
            "identifier": 85,
            "name": "Davidentifier",
            "surname": "S.",
            "gender": 1
        },
        {
            "identifier": 86,
            "name": "Nicholas",
            "surname": "S.",
            "gender": 1
        },
        {
            "identifier": 87,
            "name": "Tyler",
            "surname": "M.",
            "gender": 1
        },
        {
            "identifier": 88,
            "name": "James",
            "surname": "N.",
            "gender": 1
        },
        {
            "identifier": 89,
            "name": "John",
            "surname": "H.",
            "gender": 1
        },
        {
            "identifier": 90,
            "name": "Jonathan",
            "surname": "M.",
            "gender": 1
        },
        {
            "identifier": 91,
            "name": "Nathan",
            "surname": "V.",
            "gender": 1
        },
        {
            "identifier": 92,
            "name": "Samuel",
            "surname": "J.",
            "gender": 1
        },
        {
            "identifier": 93,
            "name": "Christian",
            "surname": "K.",
            "gender": 1
        },
        {
            "identifier": 94,
            "name": "Noah",
            "surname": "P.",
            "gender": 1
        },
        {
            "identifier": 95,
            "name": "Dylan",
            "surname": "S.",
            "gender": 1
        },
        {
            "identifier": 96,
            "name": "Benjamin",
            "surname": "S.",
            "gender": 1
        },
        {
            "identifier": 97,
            "name": "Logan",
            "surname": "M.",
            "gender": 1
        },
        {
            "identifier": 98,
            "name": "Brandon",
            "surname": "K.",
            "gender": 1
        },
        {
            "identifier": 99,
            "name": "Gabriel",
            "surname": "H.",
            "gender": 1
        },
        {
            "identifier": 100,
            "name": "Zachary",
            "surname": "P.",
            "gender": 1
        },
        {
            "identifier": 101,
            "name": "Jose",
            "surname": "S.",
            "gender": 1
        },
        {
            "identifier": 102,
            "name": "Elijah",
            "surname": "L.",
            "gender": 1
        },
        {
            "identifier": 103,
            "name": "Angel",
            "surname": "W.",
            "gender": 1
        },
        {
            "identifier": 104,
            "name": "Kevin",
            "surname": "Z.",
            "gender": 1
        },
        {
            "identifier": 105,
            "name": "Jack",
            "surname": "A.",
            "gender": 1
        },
        {
            "identifier": 106,
            "name": "Caleb",
            "surname": "O.",
            "gender": 1
        },
        {
            "identifier": 107,
            "name": "Justin",
            "surname": "L.",
            "gender": 1
        },
        {
            "identifier": 108,
            "name": "Austin",
            "surname": "S.",
            "gender": 1
        },
        {
            "identifier": 109,
            "name": "Evan",
            "surname": "W.",
            "gender": 1
        },
        {
            "identifier": 110,
            "name": "Robert",
            "surname": "B.",
            "gender": 1
        }
    ]
};

export default informerConfig;
