/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 08.11.2018
 * Time: 12:06
 */

import { ARTIST_PROMO_DATA_LOAD_ERROR, ARTIST_PROMO_DATA_LOADED } from "./../constants";
import AppDispatcher from "./../dispatcher";
import { getPublicArtistDataByUrl } from "../xhr/artist-promo-xhr";
import ApiClient from "./../service/api-client";
import { singleLookNormalizer } from "../normal-form/gallery/professional-profile/single-look-normalizer";


export function loadPublicArtistDataByUrl(artistUrl) {
    getPublicArtistDataByUrl(artistUrl).then(data => {
        AppDispatcher.dispatch({
            type: ARTIST_PROMO_DATA_LOADED,
            payload: data
        });
    }, response => {
        let error;
        console.warn("fail", response);
        if (!response) {
            error = "Response not found";
        }

        AppDispatcher.dispatch({
            type: ARTIST_PROMO_DATA_LOAD_ERROR,
            payload: error
        });

        return Promise.reject(response);

    });

}


export function loadArtistPromoGallery({ offset, count, salonId, artistId }) {

    let params = {
        salonId: salonId,
        offset: offset,
        limit: count,
        artistId: artistId
    };

    return ApiClient.get(["Q35_2_get_salon_gallery", params]).then(answer => {
        let [response] = answer;
        return {
            items: response ? response.map(singleLookNormalizer) : [],
            salonId: salonId,
            artistId: artistId
        };
    });


}