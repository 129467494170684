/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.15.3
 * Time: 12:49
 */

import BaseStore from "../../base-store";
import {
    GALLERY_FILTER_ONCHANGE,
    GALLERY_FILTER_RESET,
    PROFILE_GALLERY_ITEM_LOADED,
    PROFILE_GALLERY_SALON_INFO_LOADED
} from "../../../constants";

export default class ProfileGalleryStore extends BaseStore {

    constructor(...args) {
        super(...args);
        this.store = {
            complete: false,
            more: true,
            stop: false,
            salonInfo: {},
            profileGalleryItem: {}
        };

        this.imagesPerSection = 4;

        this._registerActionSubscription((action) => {
            let {
                type,
                payload
            } = action;

            switch (type) {
                case PROFILE_GALLERY_SALON_INFO_LOADED:
                    this.store.salonInfo = payload.salonInfo;

                    break;

                case PROFILE_GALLERY_ITEM_LOADED:
                    this.store.profileGalleryItem = payload.profileGalleryItem;

                    break;

                case GALLERY_FILTER_RESET:
                    this.resetGallery();

                    break;

                case GALLERY_FILTER_ONCHANGE:
                    this.resetGallery();

                    break;
                default:
                    return true;
            }

            this.emitChange();
        });

    }


    resetStore() {
        this.store = {
            complete: false,
            more: true,
            stop: false,
            salonInfo: {},
            profileGalleryItem: {}
        };
    }

    resetGallery() {
        this.store = {
            complete: false,
            more: true,
            stop: false,
            salonInfo: this.store.salonInfo,
            profileGalleryItem: this.store.profileGalleryItem
        };
    }

    getSalonInfo() {

        return this.store.salonInfo;
    }

    getProfileGalleryItem() {

        return this.store.profileGalleryItem;
    }
}
