/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.09.2017
 * Time: 10:27
 */

import React, {Component} from 'react'
import {browserHistory} from 'react-router';
import SubscriptionPlan from '../components/subscription-plan';
import {selectPlan, selectPricingPlan} from './../../../ac/pricing-ac';
import {addNewPayCard} from '../../../ac/subscription-ac';
import {modalChange} from './../../../ac/modals'
import {
    ADD_NEW_CARD_MODAL,
    SUBSCRIPTION_APPROVAL_MODAL
} from '../../../constants';
import {MODE_PRICING, MODE_SUBSCRIPTION} from '../../../frizo-shared-constants';
import {pricingStore, sessionStore, subscriptionsStore} from "../../../stores/index";
import FreeSubscriptionPlan from './free-subscription-plan';

export default class SubscriptionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOpened: false,
            selectedPlan: this.getStoreData()
        };

        this.openSelect = this.openSelect.bind(this);
        this.selectPlan = this.selectPlan.bind(this);
        this.onStartSubscription = this.onStartSubscription.bind(this);
        this.approvalSubscription = this.approvalSubscription.bind(this);
        this.storeUpdate = this.storeUpdate.bind(this);

    }

    componentDidMount() {
        pricingStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        pricingStore.removeChangeListener(this.storeUpdate);
    }

    getStoreData() {
        return pricingStore.getStore().selectedPlan;
    }

    storeUpdate() {
        this.setState({selectedPlan: this.getStoreData()});
    }

    openSelect() {
        this.setState({selectOpened: !this.state.selectOpened});
    }

    selectPlan(id) {
        return () => {
            this.setState({
                selectedPlan: id,
                selectOpened: !this.state.selectOpened
            });
            selectPricingPlan(id);
        }
    }

    onStartSubscription(plan) {

        selectPlan(plan);

        if (this.props.mode === MODE_SUBSCRIPTION) {
            this.approvalSubscription(plan);
        }
        else {
            if (sessionStore.isLogged()) {
                browserHistory.push(`/list-your-business`);
            } else {
                browserHistory.push(`/pro-sign-up`);
            }
        }
    }

    approvalSubscription(plan) {
        if (subscriptionsStore.store.defaultPaymentCard.length === 0) {
            setTimeout(() => {
                modalChange(ADD_NEW_CARD_MODAL, {
                    noCard: false,
                    action: addNewPayCard
                })
            })
        }
        else {
            modalChange(SUBSCRIPTION_APPROVAL_MODAL, {
                selectedPlan: plan,
                paymentCard: subscriptionsStore.store.defaultPaymentCard[0],
                violations: subscriptionsStore.store.violations,
                currentPlan: subscriptionsStore.store.currentPlan
            });
        }
    }


    render() {
        let pricingList = this.props.products === undefined ? [] : this.props.products;
        let CardItems = [];
        if (pricingList.length) {
            CardItems = pricingList.map((product, index) => {
                return <SubscriptionPlan key={product.id}
                                         selectedPlan={this.state.selectedPlan}
                                         itemIndex={index}
                                         product={product}
                                         mode={this.props.mode}
                                         interval={this.props.interval}
                                         onStartSubscription={this.onStartSubscription}
                />
            });
        }

        return (<div>
                <div className="fr-pricing-flex">
                    {this.props.mode === MODE_PRICING && <FreeSubscriptionPlan pricing={this.props.pricing}/>}
                    {CardItems}
                </div>
            </div>
        )
    }
}