/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 18.9.17
 */

import BaseStore from "./base-store";
import { CLIENT_STREAM_MARK_AS_READ, CLIENT_STREAM_MESSAGE } from "./../constants";

export default class FeatureFlagStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case CLIENT_STREAM_MESSAGE:
                    /* FYI
                     switch (payload.type)   {
                     case CLIENT_STREAM_NOTIFICATION:
                     case CLIENT_STREAM_MESSAGE:
                     }
                     */
                    this.store.stream.push(payload);

                    break;
                case CLIENT_STREAM_MARK_AS_READ:
                    for (let i = 0; i < this.store.stream.length; i++) {
                        if (this.store.stream[i].message_id === payload.messageId) {
                            this.store.stream[i].read = true;
                        }
                    }

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    getInitialStore() {
        return {
            stream: []
        };
    }
}
