/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 7.11.17
 * Time: 17.44
 *
 * @flow
 */

import type { TArtistService, TService } from "../types";
import type { TRawArtistServiceLegacy, TRawService } from "./raw-types";
import { TYPE_SERVICE } from "../frizo-shared-constants";

export function serviceNormalize(rawService: TRawService): TService {

    let parent;
    if (rawService.parent) {
        parent = serviceNormalize(rawService.parent);
    }

    return {
        entityType: TYPE_SERVICE,
        id: rawService.id,
        title: rawService.i18nKey,
        durationValue: rawService.durationValue,
        processingValue: rawService.processingValue,
        parentId: rawService.parentId,
        parent: parent,
        canHaveImage: !!Number(rawService.image),
        weight: rawService.num
    };
}

export function artistServiceNormalizer(artistService: TRawArtistServiceLegacy): TArtistService {

    return {
        entityType: TYPE_SERVICE,
        id: artistService.serviceID,
        title: artistService.serviceName,
        parentId: artistService.serviceParentID,
        parentTitle: artistService.serviceParentTitle,
        canHaveImage: !!Number(artistService.image)
    };
}
