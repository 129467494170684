/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.5.17
 * Time: 9.11
 */

import React from "react";
import { Modal } from "react-bootstrap";
import gradientFill from "./../../helper/gradient-fill";

export function adaptToModal(Component, title, FooterComponent = null) {
    return function() {

        return <div>

            <Modal.Header closeButton style={{ "background": gradientFill() }}>
                <h2>{title}</h2>
            </Modal.Header>

            <Modal.Body>
                <Component/>
            </Modal.Body>

            {FooterComponent &&

            <Modal.Footer>
                <FooterComponent/>
            </Modal.Footer>

            }


        </div>;
    };
}
