/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.3.17
 * Time: 11.23
 */

import React, { Component } from "react";
import { NavItem } from "react-bootstrap";
import RoutedTab from "./../tabs/routed-tab";
import { sessionStore } from "./../../stores/index";
import {
    USER_TYPE_SALON_ADMIN,
    USER_TYPE_FREELANCER,
    USER_TYPE_MASTER,
    USER_TYPE_SALON_MASTER,
    USER_TYPE_SALON_OWNER,
    PROFILE_COMPLETE, PROFILE_FULLY_INCOMPLETE, PROFILE_INCOMPLETE
} from "./../../frizo-shared-constants";

import { loadProfileCompletenessInfo } from "./../../ac/profile-completeness-actions";
import ProfileBanner from "../../elements/profile-banner";

import { profileCompletenessStore } from "./../../stores";
import { xhr } from "../../service/api-client-2";
import { PROFILE_COMPLETION_IRRELEVANT } from "../../frizo-shared-constants";
import idGenerator from "./../../helper/id-generator-component";

const TAB_NAME_AND_LOCATION = "TAB_NAME_AND_LOCATION";
const TAB_SERVICES = "TAB_SERVICES";
const TAB_ARTISTS = "TAB_ARTISTS";
const TAB_GALLERY = "TAB_GALLERY";
const TAB_WH = "TAB_WH";
const TAB_BRANDS = "TAB_BRANDS";
const TAB_PAYMENTS = "TAB_PAYMENTS";


export default class ProfessionalTab extends Component {

    constructor() {
        super();

        this.state = {
            userType: sessionStore.getUserType(),
            completeAll: true,
            completeness: this.getCompletenessStoreData()
        };


        this.container = null;

        this.getTabs = this.getTabs.bind(this);
        this.getTabCompleteStatus = this.getTabCompleteStatus.bind(this);
        this.containerRef = this.containerRef.bind(this);
        this.handleCompletenessChange = this.handleCompletenessChange.bind(this);
        loadProfileCompletenessInfo();

        this.tabCreationConfig = {
            [TAB_NAME_AND_LOCATION]: {
                key: "name-and-location",
                title: "Name & Location",
                options: { "data-key": "Profile" }
            },
            [TAB_SERVICES]: {
                key: "services",
                title: "Services",
                options: { "data-key": "Services" }
            },
            [TAB_ARTISTS]: {
                key: "artists",
                title: "Artists",
                options: { "data-key": "Artists" }
            },
            [TAB_GALLERY]: {
                key: "gallery",
                title: "Gallery",
                options: { "data-key": "Gallery" }
            },
            [TAB_WH]: {
                key: "working-hours",
                title: "Working hours",
                options: { "data-key": "Wh" }
            },
            [TAB_BRANDS]: {
                key: "brands",
                title: "Brands",
                options: { "data-key": "Brands" }
            },
            [TAB_PAYMENTS]: {
                key: "payments",
                title: "Payments"
            }


        };

        this.tabConfig = {
            [USER_TYPE_FREELANCER]: [TAB_NAME_AND_LOCATION, TAB_SERVICES, TAB_GALLERY, TAB_WH, TAB_PAYMENTS],
            [USER_TYPE_SALON_MASTER]: [TAB_NAME_AND_LOCATION, TAB_SERVICES, TAB_GALLERY, TAB_WH, TAB_PAYMENTS],
            [USER_TYPE_MASTER]: [TAB_NAME_AND_LOCATION, TAB_SERVICES, TAB_GALLERY, TAB_WH],
            [USER_TYPE_SALON_OWNER]: [TAB_NAME_AND_LOCATION, TAB_SERVICES, TAB_ARTISTS, TAB_GALLERY, TAB_WH, TAB_PAYMENTS]
        };

    }


    componentDidMount() {
        sessionStore.getReadyPromise().then(store => {
            this.setState({
                userType: sessionStore.getUserType()
            });
        });

        profileCompletenessStore.addChangeListener(this.handleCompletenessChange);

        xhr.events.requestSucceeded.add(this.requestSuccess);
    }

    requestSuccess(request) {
        if (~["POST", "DELETE"].indexOf(request.getMethod())) {
            loadProfileCompletenessInfo();
        }
    }

    componentWillUnmount() {
        profileCompletenessStore.removeChangeListener(this.handleCompletenessChange);

        xhr.events.requestSucceeded.remove(this.requestSuccess);
    }

    handleCompletenessChange() {
        let completeness = this.getCompletenessStoreData();
        this.setState({
            completeAll: completeness.profileProfessional.status === PROFILE_COMPLETE,
            completeness: completeness
        });
    }

    getCompletenessStoreData() {
        return profileCompletenessStore.getStore().status;
    }

    getTabCompleteStatus(tabName) {
        if (this.state.completeness.profileProfessional.status === PROFILE_COMPLETE) return null;
        if (this.state.completeness["tab" + tabName] !== undefined) {
            return this.state.completeness["tab" + tabName].status;
        }

        return null;
    }

    createNavItem(key, title, options = {}) {
        let dataKey = options["data-key"] || key;

        let completeStatusLabel = <div className="li-cap hidden-xs"></div>;
        let badgeStyle;

        switch (this.getTabCompleteStatus(dataKey)) {
            case PROFILE_COMPLETE:
                badgeStyle = "fr-span-complete ";

                break;

            case PROFILE_FULLY_INCOMPLETE:
                badgeStyle = "fr-span-fully-incomplete ";
                break;

            case PROFILE_INCOMPLETE:
                badgeStyle = "fr-span-incomplete ";
                break;

            case PROFILE_COMPLETION_IRRELEVANT:
            default:
                badgeStyle = "fr-span-complete ";
        }
        return <NavItem key={idGenerator("navItem")} className={"fr-nav-item"} eventKey={key} data-key={dataKey}>
            {completeStatusLabel}{title}
            {!this.state.completeAll && <span className={badgeStyle}></span>}

        </NavItem>;

    }

    getTabs() {

        let tabConfigForUser = this.tabConfig[this.state.userType];
        if (!tabConfigForUser) {
            console.warn("There are no tab config for user type ", this.state.userType);

            return [];
        }

        return tabConfigForUser.map(tabName => {
            let config = this.tabCreationConfig[tabName];

            return this.createNavItem(config.key, config.title, config.options || {});
        });
    }

    containerRef(ref) {
        if (ref) {
            this.container = ref;
        }
    }

    render() {
        if (this.state.userType === null) {
            return <div className={"loading-message"}>Loading...</div>;
        }
        let tabs = this.getTabs();
        let bannerVisibility = [USER_TYPE_SALON_ADMIN, USER_TYPE_FREELANCER, USER_TYPE_SALON_MASTER, USER_TYPE_SALON_OWNER].includes(
            sessionStore.getUserType());

        return <div ref={this.containerRef}>
            <ProfileBanner
                mode="professional-profile"
                incomplete={!this.state.completeAll && bannerVisibility}
                completeness={this.state.completeness}
            />
            <RoutedTab route="/my-salon/:tab" activeKey="1">
                {tabs}
            </RoutedTab>
            <div className='container fr-tab-content'>
                {this.props.children}
            </div>
        </div>;
    }
}

ProfessionalTab.requireData = ["userSalon", "subscription-tier-2"];