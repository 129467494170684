/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 6/26/17
 * Time: 21:33
 */

import { LOAD_FAQ_FOR_CLIENTS, LOAD_FAQ_FOR_PROFESSIONALS } from "./../constants";
import BaseStore from "./base-store";

class FaqForClientsStore extends BaseStore {
    constructor(...args) {
        super(...args);
        this.store = {
            faqClients: [],
            faqProfessionals: []
        };

        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case LOAD_FAQ_FOR_CLIENTS:
                    this.store.faqClients = payload;
                    break;
                case LOAD_FAQ_FOR_PROFESSIONALS:
                    this.store.faqProfessionals = payload;
                    break;
                default:
                    return true;
            }
            this.emitChange();
        });
    }
}

export default FaqForClientsStore;
