/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 10.5.17
 * Time: 13.55
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    SLOT_STATE_DEFAULT,
    SLOT_STATE_WH_SET,
    SLOT_STATE_PAST,

    SCHEDULE_ELEMENT_STATE_UPCOMING,
    SCHEDULE_ELEMENT_STATE_IN_PROGRESS,
    SCHEDULE_ELEMENT_STATE_PAST,
    SCHEDULE_ELEMENT_STATE_SUSPENSE,
    SCHEDULE_ELEMENT_STATE_NO_SHOW

} from '../../frizo-shared-constants';


export class ScheduleElement extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            processingHeight: props.processingHeight,
            durationHeight: props.durationHeight
        };

        this.cnt = undefined;
        this.body = undefined;

        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.stopResize = this.stopResize.bind(this);
        this.startResize = this.startResize.bind(this);
        this.doResize = this.doResize.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.processingHeight !== this.state.processingHeight) {
            this.setState({
                processingHeight: nextProps.processingHeight,
                durationHeight: nextProps.durationHeight
            });
        }
    }


    stopResize(event) {
        document.documentElement.removeEventListener('mousemove', this.doResize, false);
        document.documentElement.removeEventListener('mouseup', this.stopResize, false);

        if (typeof this.props.onResize === 'function') {

            let uniqueId = this.cnt.getAttribute('data-id');

            let newHeight = parseInt(document.defaultView.getComputedStyle(this.body).height, 10);

            this.props.onResize(uniqueId, newHeight, this.cnt, event);
        }

    }

    startResize(event) {

        this.startY = event.clientY;
        this.startHeight = this.body.offsetHeight;

        document.documentElement.addEventListener('mousemove', this.doResize, false);
        document.documentElement.addEventListener('mouseup', this.stopResize, false);

        event.preventDefault();
        event.stopPropagation();

        if (typeof this.props.startResize === 'function') {
            let uniqueId = this.cnt.getAttribute('data-id');

            this.props.startResize(uniqueId, this.cnt, event);
        }

    }

    doResize(event) {

        let newHeight = (this.startHeight + event.clientY - this.startY);
        if (typeof this.props.doResize === 'function') {
            newHeight = this.props.doResize(newHeight, event);
        }

        this.setState({
            processingHeight: newHeight
        });
    }

    saveCntElement(el) {
        if (el) this.cnt = el;
    }

    saveBodyElement(el) {
        if (el) this.body = el;
    }

    render() {

        let style = {
            top: 0,
            left: 0,
            zIndex: 2
        };

        if (this.state.processingHeight) {
            style.height = this.state.processingHeight;
        }



        let ableToMutate = (this.props.status === SCHEDULE_ELEMENT_STATE_UPCOMING);

        let classList = ['schedule-element'];
        if (this.props.config.classes && Array.isArray(this.props.config.classes)) {
            classList = classList.concat(this.props.config.classes);
        }

        classList = classList.concat(this.props.extraClasses);

        if (this.props.dirty)   {
            classList.push('schedule-element-dirty');
        }

        classList.push('element-status-' + this.props.status);

        let bodyStyle = Object.assign({}, this.props.config.style, {
            height: this.state.processingHeight
        });

        return (
            <div
                ref={this.saveCntElement.bind(this)}
                draggable={ableToMutate ? 'true' : 'false'}
                onDragStart={this.props.onDragStart}
                onDragEnd={this.props.onDragEnd}
                onClick={this.props.onClick}
                key={ 'schedule-element' + this.props.uniqueId }
                data-id={this.props.uniqueId}
                style={style}
                className={classList.join(' ')}
            >
                <div
                    ref={this.saveBodyElement.bind(this)}
                    className="element-body"
                    style={bodyStyle}
                >
                    <div draggable="false" className="noselect">{this.props.children}</div>

                    <div
                        style={{display: ableToMutate ? 'block' : 'none'}}
                        draggable="false"
                        onMouseDown={ableToMutate ? this.startResize : () => {}}
                        className="resize-anchor"
                    />
                </div>
            </div>
        )
    }
}

ScheduleElement.propTypes = {
    config: PropTypes.object,
    uniqueId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    slotState: PropTypes.oneOf([
        SLOT_STATE_DEFAULT,
        SLOT_STATE_WH_SET,
        SLOT_STATE_PAST
    ]),
    status: PropTypes.oneOf([
        SCHEDULE_ELEMENT_STATE_UPCOMING,
        SCHEDULE_ELEMENT_STATE_IN_PROGRESS,
        SCHEDULE_ELEMENT_STATE_PAST,
        SCHEDULE_ELEMENT_STATE_SUSPENSE,
        SCHEDULE_ELEMENT_STATE_NO_SHOW
    ]),
    extraClasses: PropTypes.array,
    startResize: PropTypes.func,
    onResize: PropTypes.func,
    doResize: PropTypes.func
};

ScheduleElement.defaultProps = {
    config: {
        classes: [],
        style: {
            backgroundColor: 'green',
            color: 'white'
        }
    },
    status: SCHEDULE_ELEMENT_STATE_UPCOMING,
    extraClasses: []
};
