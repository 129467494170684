/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 17.29.3
 * Time: 11:49
 */

import {
    LOAD_SALON_LOCATION,
    LOAD_SALON_SERVICES,
    PROMO_INITIALS_LOADED,
    SALON_PUBLIC_DATA_LOADED
} from "../../constants";

import BaseStore from "./../base-store";

export default class SalonPromoStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            salonId: null,
            salonName: null,
            isPublic: null,
            artists: {},
            brands: {},
            gallery: {},
            location: {},
            services: { data: [[], [], []] }
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case LOAD_SALON_LOCATION:
                    Object.assign(this.store, { location: payload });

                    break;
                case LOAD_SALON_SERVICES:
                    Object.assign(this.store, { services: payload });

                    break;

                case PROMO_INITIALS_LOADED:
                    Object.assign(this.store, { location: payload });
                    break;

                case SALON_PUBLIC_DATA_LOADED:
                    this.store.salonId = payload.id;
                    this.store.salonName = payload.name;
                    this.store.isPublic = payload.isPublic;

                    break;

                default:
                    return this.store;
            }

            this.emitChange();
        });

    }
}
