/**
 * Created by Eleonora on 31-Jan-17.
 */

import { DOCS_LOADED, LEGAL_MODAL, LOAD_LEGAL_DOC, LOAD_SESSION_SUCCESS, MODAL_HIDE, SIGN_DOC } from "../constants";
import BaseStore from "./base-store";
import { modalChange, modalHide } from "../ac/modals";
import { getUnsignedDocuments } from "../ac/legal";


class DocStore extends BaseStore {

    constructor(...args) {
        super(...args);
        this.session = {};
        this.store = {
            docs: [],
            legalUnsigned: 0,
            legalDoc: []
        };


        this._registerActionSubscription((callback) => {
            const { type, payload } = callback;

            switch (type) {
                case LOAD_SESSION_SUCCESS:
                    this.session = payload.session;
                    if (this.session.legalUnsigned === 1) {
                        getUnsignedDocuments();
                        this.store.legalUnsigned = 0;
                    }
                    break;

                case DOCS_LOADED:
                    this.store.docs = payload;

                    break;

                case SIGN_DOC:
                    this.store.docs.splice(0, 1);

                    break;

                case MODAL_HIDE:
                    if (this.store.docs.length > 0) {
                        setTimeout(() => modalChange(LEGAL_MODAL), 0);
                    }

                    break;

                case LOAD_LEGAL_DOC:
                    this.store.legalDoc = payload;

                    break;

                default:

                    return true;
            }


            if (this.store.docs.length > 0) {
                this.modalState();
            } else {
                if (this.stores.modal.toggle && this.stores.modal.modalType === LEGAL_MODAL) {
                    setTimeout(() => modalHide(), 0);
                }
            }

            this.emitChange();
        });
    }

    modalState() {
        if (!this.stores.modal.toggle || this.stores.modal.modalType === LEGAL_MODAL) {
            setTimeout(() => modalChange(LEGAL_MODAL), 0);
        }
    }
}

export default DocStore;
