/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 21.3.17
 * Time: 7.59
 */

import React, { Component } from "react";
import authCheck from "./auth-check";
import PropTypes from "prop-types";

export default class AuthChecker extends Component {
    constructor(props, context) {
        super(props, context);

        authCheck(context.router.routes, context.router.location.pathname);
    }

    render() {
        // let route = this.context.router.routes[this.context.router.routes.length - 1];

        return (<div>
            {/*<div>Route: {route.path}</div>*/}
            <div>{this.props.children}</div>
        </div>);
    }
}


AuthChecker.contextTypes = {
    router: PropTypes.object.isRequired
};
