/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 2.10.17
 */

import AppDispatcher from "../../dispatcher";
import { xhrGetCustomer } from "../../xhr/payments/personal/get-customer-xhr";
import { LOAD_CLIENT_CARDS_INFO } from "../../constants";
import { notifyFail } from "../notify-actions";

export function loadClientCard() {
    xhrGetCustomer().then(data => {
        AppDispatcher.dispatch({
                type: LOAD_CLIENT_CARDS_INFO,
                payload: data
            }
        );
    }, data => {
        console.error("Cannot get customer", data);

        notifyFail("Load data error");
    });
}
