/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 1.7.17
 * Time: 21.47
 *
 * @flow
 *
 */

import type { TArtist, TArtistLegacy } from "../../types";
import type { TRawArtistLegacyBase, TRawUser } from "../raw-types";
import { normalizeSchedule } from "../schedule";
import { salonNormalizer1 } from "../salon";

import { TYPE_SALON, TYPE_USER } from "./../../frizo-shared-constants";
import { serviceNormalize } from "../service";

export function artistNormalizer1(rawArtist: TRawUser): TArtist {
    let { availableSchedule, addressSchedule } = rawArtist.schedule ? normalizeSchedule(rawArtist.schedule) : {
        availableSchedule: {},
        addressSchedule: {}
    };

    let services = [];
    if (rawArtist.services) {
        services = rawArtist.services.map(service => serviceNormalize(service));
    }


    let literal: TArtist = {
        id: rawArtist.id,
        entityType: TYPE_USER,

        firstName: rawArtist.firstName,
        lastName: rawArtist.lastName,

        email: rawArtist.username,
        phone: rawArtist.phone,

        activated: Boolean(rawArtist.activated),
        business: rawArtist.business,
        master: rawArtist.master,
        professional: rawArtist.professional,
        salonAdmin: rawArtist.salonAdmin,
        bookable: rawArtist.isBookable,
        public: rawArtist.isPublic,

        rawUserType: rawArtist.rawUserType,
        airtouchCertified: rawArtist.airtouchCertified,
        airtouchCertUrl: rawArtist.airtouchCertUrl,

        availableSchedule,
        addressSchedule,
        services
    };

    if (rawArtist.salon && Array.isArray(rawArtist.salon) && rawArtist.salon.length > 0) {
        literal.salon = salonNormalizer1(rawArtist.salon[0]);
    }

    if (rawArtist.image) {
        literal.image = {
            id: rawArtist.image.id,
            url: rawArtist.image.url
        };
    }

    if (rawArtist.services) {
        literal.services = rawArtist.services.map(serviceNormalize);

    }

    return literal;
}

/**
 * Another artist model for
 *  - Q41_add_or_edit_master
 *  - Q70
 *
 * @param rawArtist
 */
export function artistNormalizerLegacy(rawArtist: TRawArtistLegacyBase): TArtistLegacy {

    let literal: TArtistLegacy = {
        id: rawArtist.artistID,
        entityType: TYPE_USER,

        firstName: rawArtist.artistFirstName,
        lastName: rawArtist.artistLastName,

        email: rawArtist.artistEmail,
        phone: rawArtist.artistPhone,
        instagramUsername: rawArtist.instagramUsername || "",
        airtouchCertified: rawArtist.airtouchCertified,
        airtouchCertUrl: rawArtist.airtouchCertUrl,

        salon: {
            entityType: TYPE_SALON,
            id: rawArtist.salonID,
            title: rawArtist.salonName
        }
    };

    if (rawArtist.image) {
        literal.image = {
            id: rawArtist.image.id,
            url: rawArtist.image.url
        };
    }

    return literal;
}
