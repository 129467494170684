/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 6/26/17
 * Time: 21:36
 */

import ApiClient from "../service/api-client";

export function xhrLoadFaq(param) {
    let url = ApiClient.getRouting().generate(("Q126_get_faq"), {
        tab: param
    });

    return ApiClient.get((url), null, true).then(answer => {
        let collection = answer[0];
        let i = 0, val, index,
            values = [], result = [];
        for (; i < collection.length; i++) {
            val = collection[i]["theme_id"];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    });
}
