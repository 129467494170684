/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 13.29
 */


import React, {Component} from 'react';
import GalleryImageTitle from "../../gallery/elements/gallery-image-title";
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type {TGalleryImage} from "../../../types";
import {FavoriteMark} from "../../favorites/favorite-mark";
import {BUTTON_DANGER, GALLERY_ITEM_TYPE_LOOK, IMAGE_SIZE_MEDIUM} from "../../../frizo-shared-constants";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import GalleryItemButton from "../gallery-parts/gallery-item-button";
import {removeGalleryItemAction} from "../../../ac/profile/professional/professional-profile-gallery-actions";
import AirtouchCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";

type Props = TGalleryImage & {};

export default class ProfessionalProfileGalleryItemLook extends Component<Props, any> {

    render() {

        return <GalleryItemContainer>
            {this.props.favoritesEnabled && <FavoriteMark id={this.props.id} type={GALLERY_ITEM_TYPE_LOOK} /> }
            {this.props.imageBefore &&  <div className="fr-double-photo" onClick={this.props.onClick}></div>}

            <GalleryItemImage {...this.props} size={IMAGE_SIZE_MEDIUM} />
            {this.props.artist.airtouchCertified && <AirtouchCertificateBadge {...this.props.artist}/>}
            <GalleryItemCardInfo>
                <GalleryImageTitle
                    serviceName = {this.props.service.title}
                    firstName = {this.props.artist.firstName}
                    lastName = {this.props.artist.lastName}
                />

                <GalleryItemButtonsContainer>
                    <GalleryItemButton title="Remove" type={BUTTON_DANGER} onClick={removeGalleryItemAction.bind(this, this.props.id, this.props.galleryId)} />

                </GalleryItemButtonsContainer>

            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}

ProfessionalProfileGalleryItemLook.defaultProps = {
    favoritesEnabled: false
};
