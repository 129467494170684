/**
 * Created by fliak on 23.2.17.
 */

export function autoArray() {
    let arr = [];

    Object.defineProperty(arr, "ensureHash", {
        enumerable: false,
        value: function(initHash = null) {
            let hash = autoHash();
            if (initHash) {
                Object.assign(hash, initHash);
            }

            arr.push(hash);

            return hash;
        }
    });

    Object.defineProperty(arr, "ensureArray", {
        enumerable: false,
        value: function() {
            let newArray = autoArray();
            arr.push(newArray);

            return newArray;
        }
    });

    Object.defineProperty(arr, "last", {
        enumerable: false,
        value: function() {
            return arr[arr.length - 1];
        }
    });

    return arr;
}

/**
 * @typedef {Object} autoHash
 * @property {function} ensureHash ensureHash method
 * @property {function} ensureArray ensureArray method
 */

/**
 *
 * @returns {autoHash}
 */
export function autoHash() {

    let proto = Object.create(null);

    Object.defineProperty(proto, "ensureHash", {
        enumerable: false,
        value: function(key, initHash = null) {

            if (this[key] === undefined) {
                this[key] = autoHash();

                if (initHash) {
                    Object.assign(this[key], initHash);
                }
            }

            return this[key];
        }
    });

    Object.defineProperty(proto, "ensureArray", {
        enumerable: false,
        value: function(key) {
            if (this[key] === undefined) {
                this[key] = autoArray();
            }

            return this[key];
        }
    });

    return Object.create(proto);
}
