/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 14.04
 *
 * @flow
 */

import type { TArtistLegacy, TGalleryImage, TSalon } from "../../../types";
import {
    GALLERY_ITEM_TYPE_LOOK,
    TYPE_GALLERY_IMAGE,
    TYPE_SALON,
    TYPE_SERVICE,
    TYPE_USER
} from "../../../frizo-shared-constants";
import type { TRawLook, TRawUser } from "../../raw-types";

export function singleLookNormalizer(look: TRawLook, salon: ?TSalon = null, artist: ?TArtistLegacy = null): TGalleryImage {

    if (!salon) {
        salon = {
            entityType: TYPE_SALON,
            id: look.galleryItemSalonID,
            title: look.galleryItemSalonName,
            url: look.salonUrl,
            type: look.salonType
        };
    }

    let galleryImage: TGalleryImage = {
        id: look.galleryItemID,
        entityType: TYPE_GALLERY_IMAGE,
        galleryItemType: GALLERY_ITEM_TYPE_LOOK,
        image: {
            id: look.imageID,
            url: look.galleryItemImageURL
        },
        shareUrl: look.shareUrl,
        salon: salon,
        artist: artist || tinyArtistNormalizer(look.artist),
        service: {
            entityType: TYPE_SERVICE,
            id: look.galleryItemServiceID,
            title: look.galleryItemServiceName
        },
        priceLevel: look.galleryItemServicePriceLevel,
        location: {
            salonUrl: look.salonUrl,
            name: look.galleryItemSalonName,
            lat: look.addresses[0].latitude,
            lon: look.addresses[0].longitude,
            city: look.addresses[0].city,
            state: look.addresses[0].state,
            zip: look.addresses[0].zip
        }
    };

    if (look.imageBeforeId && look.galleryItemImageBeforeURL) {
        galleryImage.imageBefore = {
            id: look.imageBeforeId,
            url: look.galleryItemImageBeforeURL
        };
    }

    return galleryImage;
}


function tinyArtistNormalizer(rawArtist: TRawUser) {
    return {
        entityType: TYPE_USER,
        id: rawArtist.id,
        firstName: rawArtist.firstName,
        lastName: rawArtist.lastName,
        airtouchCertified: rawArtist.airtouchCertified,
        airtouchCertUrl: rawArtist.airtouchCertUrl
    };
}