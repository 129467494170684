/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 05.04.2017
 * Time: 06:14
 */

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PaymentOrderRow from './payment-order-row';
import { openOrderApprovePopup } from '../../../../../ac/payment-approval-ac';

export default class PaymentOrderList extends Component {

    onPay(orderId) {
        console.log('onPay', orderId);

        openOrderApprovePopup(orderId);
    }

    render() {

        let items = [];
        if (this.props.elements && this.props.elements.length > 0) {

            items = this.props.elements.map(element => {

                return <PaymentOrderRow
                    key={element.id}
                    orderId={element.id}
                    status={element.status}
                    salonName={element.salonName}
                    services={element.services.map(service => service.serviceName)}
                    date={element.date}
                    create={element.create}
                    onPay={this.onPay}
                />
            });
        }

        return (
            <div>
                <Row className="fr-list-header bordered">
                    <Col xsHidden={true} smHidden={true} md={1}>Status</Col>
                    <Col xsHidden={true} smHidden={true} md={2}>Date</Col>
                    <Col xsHidden={true} smHidden={true} md={3}>List of Services</Col>
                    <Col xsHidden={true} smHidden={true} md={3}>Salon</Col>
                    <Col xsHidden={true} smHidden={true} md={3}>Action</Col>

                    <Col xs={5} mdHidden={true} lgHidden={true}>Date</Col>
                    <Col xs={7} mdHidden={true} lgHidden={true}>Salon</Col>

                </Row>
                {items}
            </div>
        )
    }
}
