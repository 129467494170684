/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.7.17
 */

import ApiClient from "../../service/api-client";

export function xhrGetVisitApts(anAptId) {

    let url = ApiClient.getRouting().generate("Q117_get_payable_visit_appointments", {
        appointmentId: anAptId
    });

    return ApiClient.get(url).then(answer => answer[0]);
}
