/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 3/31/17
 * Time: 19:04
 */

import BaseConstraint from "./base-constraint";

export default class IsPasswordMatch extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Password fields must match";
        this.errorCode = "PASSWORDS_MUST_MATCH";
        this.fieldName = "password";

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        return "is-password-match";
    }
}
