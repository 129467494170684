/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 10.01.2018
 * Time: 11:42
 */
import React, {Component} from 'react';
import {Modal, Button, Form, FormControl, Col, Row} from 'react-bootstrap';
import DropdownWrapper from '../../elements/dropdown-wrapper';
import Validator from '../../validation/validator';
import {createIntervalsList, getIdbyValue} from '../../components/profile/professional/services/helpers';
import Violation from '../helper-components/violation';
import {selectedServiceStore} from '../../stores/index';
import {modalHide} from "../../ac/modals";
import PropTypes from 'prop-types';
import {
    removeSalonServiceAction,
    modifySalonServiceAction
} from '../../ac/profile-services/common-actions';
import gradientFill from './../../helper/gradient-fill';

export default class ServiceEditModal extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            violations: {},
            btnDisabled: false,
            service: Object.assign({}, this.props.service)
        }, this.getStoreData());

        this.storeUpdate = this.storeUpdate.bind(this);
        this.onModifyClick = this.onModifyClick.bind(this);
        this.durationChange = this.durationChange.bind(this);
        this.processingChange = this.processingChange.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.removeService = this.removeService.bind(this);

    }

    componentDidMount() {
        selectedServiceStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        selectedServiceStore.removeChangeListener(this.storeUpdate);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            service: Object.assign({}, nextProps.service)
        });
    }

    getStoreData() {

        return selectedServiceStore.getStore();
    }

    storeUpdate() {
        let object = Object.assign({}, this.getStoreData());

        this.setState(object);
    }

    onModifyClick() {

        const validator = new Validator();

        validator.addConstraint('price', 'is-required');
        validator.addConstraint('price', 'is-number');
        validator.addConstraint('price', 'length', {max: 4, min: 1});


        let violations = validator.validate({
            price: this.state.service.price,
        });

        this.setState({
            formIsValid: violations === true,
            violations: violations === true ? {} : violations
        });

        if (violations === true) {
            let service = this.state.service;
            const duration = this.state.duration.find(el => el.value === service.durationValue);
            const processing = this.state.processing.find(el => el.value === service.processingValue);
            this.setState({btnDisabled: true});

            modifySalonServiceAction(service, duration.value, processing.value)
        }

    }

    removeService() {
        this.setState({btnDisabled: true});
        removeSalonServiceAction(this.state.service);
    }

    changeHandler(ev) {
        let {value, name} = ev.target;
        if ((value.indexOf('.') > -1) || (value.indexOf(',') > -1) || (value.length > 4)) {
            return false;
        }
        let service = this.state.service;
        service[name] = '';

        value.replace(/\D+/g, '');
        if ((parseInt(value, 10) && value.length < 5) || (this.state.service.allowZeroPrice && (value === '0'))) {
            service[name] = value;
        }

        this.setState({service});
    }


    durationChange(id) {
        let durationItem = this.state.duration.find(item => item.id === Number(id));
        let value = durationItem.value;
        let processingValue = this.state.service.processingValue;

        if (value < processingValue) {
            processingValue = value;
        }
        let service = this.state.service;
        service.durationValue = Number(value);
        service.processingValue = Number(processingValue);
        this.setState({service});
    }

    processingChange(id) {
        let service = this.state.service;
        let processingItem = this.state.processing.find(item => item.id === Number(id));
        service['processingValue'] = processingItem.value;
        this.setState({service});
    }


    close() {
        modalHide();
    }

    render() {

        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>Edit service</h2>
                </Modal.Header>
                <Modal.Body className="service-modal__body">
                    <Form>
                        <Row className="fr-mb ">
                            <Col xs={4} md={4}>
                                <div className="fr-title">Service</div>
                            </Col>
                            <Col xs={8} md={8}>
                                <div className="fr-service-title">{this.state.service.i18nKey}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} md={4}>
                                <div className="fr-title__edit-price">Price</div>
                            </Col>
                            <Col xs={8} md={8}>
                                <FormControl placeholder="Price, $" name="price"
                                             value={this.state.service.price} type="tel"
                                             onChange={this.changeHandler} className=""/>
                                <Violation violationSet={this.state.violations.price}
                                           visible={!this.state.formIsValid}/>
                            </Col>
                        </Row>
                        <Row className="fr-mb">

                            <Col xs={4} md={4}>
                                <div className="fr-title__edit-price">Duration</div>
                            </Col>

                            <Col xs={8} md={8}>
                                <DropdownWrapper
                                    showPlaceholder={false}
                                    list={createIntervalsList(this.state.duration)}
                                    selected={getIdbyValue(this.state.duration, this.state.service.durationValue)}
                                    onChange={this.durationChange}
                                    className={"dropdown-service-row"}
                                />
                            </Col>
                        </Row>
                        <Row className="fr-mb">
                            <Col xs={4} md={4}>
                                <div className="fr-title__edit-price">Processing</div>
                            </Col>
                            <Col xs={8} md={8}>
                                <DropdownWrapper
                                    showPlaceholder={false}
                                    list={createIntervalsList(this.state.processing, this.state.service.durationValue)}
                                    onChange={this.processingChange}
                                    selected={getIdbyValue(this.state.processing, this.state.service.processingValue)}
                                    className={"dropdown-service-row"}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Violation violationSet={this.state.violations.general} visible={true}/>
                            </Col>
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="client-modal__footer">
                    <Button className="client-modal__footer fr-btn-client__remove" disabled={this.state.btnDisabled}
                            onClick={this.removeService}>Remove</Button>
                    <Button className="client-modal__footer fr-btn-client__save "
                            onClick={this.onModifyClick} disabled={this.state.btnDisabled}>Save</Button>
                </Modal.Footer>
            </div>
        )
    }
}
ServiceEditModal.propTypes = {
    service: PropTypes.object,
    processing: PropTypes.array,
    duration: PropTypes.array,
    durationValue: PropTypes.number,
    processingValue: PropTypes.number,
};

ServiceEditModal.defaultProps = {
    service: {},
    processing: [],
    duration: [],
    durationValue: 0,
    processingValue: 0,
};
