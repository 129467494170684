/**
 * Created by Alexey Ilasov on 24.11.2016.
 */
import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import BookWrapper from './book-wrapper';
import {persist} from '../../ac/book-apt/common-actions';
import BookHeader from './book-header';
import TotalCharge from './book-total-charge';
import {bookAptConfStore} from './../../stores/index';
import Violation from './../helper-components/violation';
import gradientFill from './../../helper/gradient-fill';

export default  class BookModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            violations: {},
            showViolations: false,
            config: null,
            bookDisabled: false
        };

        this.bookConfigChanged = this.bookConfigChanged.bind(this);
    }


    close() {
        this.props.close();
    }

    book() {
        persist();
    }

    bookConfigChanged() {
        this.setState(bookAptConfStore.getStore());
    }

    createHeader() {
        if (this.state.config){

            return <BookHeader action={this.state.config.action} />

        }

        return null;
    }

    componentDidMount() {
        bookAptConfStore.addChangeListener(this.bookConfigChanged);
        this.setState(bookAptConfStore.getStore());
    }

    componentWillUnmount() {
        bookAptConfStore.removeChangeListener(this.bookConfigChanged);
    }

    render() {


        let header = this.createHeader();
        let body = <BookWrapper  bookData={this.state.config}/>;
        let footer = <div className="book-footer-wrapper">
            <div className="book-footer-total"><TotalCharge /></div>

            <div className="book-footer-book">
                <Button disabled={this.state.bookDisabled} onClick={this.book} bsStyle="primary" className="btn-book">Book</Button>
            </div>
            <div className="book-footer-violation">
                <Violation violationSet={this.state.violations.general} visible={this.state.showViolations} />
            </div>
        </div>;

        return <div>
            <Modal.Header closeButton style={{'background': gradientFill()}}>{header}</Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>
        </div>;

    }

}
