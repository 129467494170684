/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.4.10
 * Time: 17:06
 */

import BaseStore from "./../base-store";
import { FORM_VALIDATION_FAIL, INITIALIZE_FORM, REGISTRATION_FORM_CHANGE } from "./../../constants";

export default class RegistrationStore extends BaseStore {

    constructor(...args) {
        super(...args);
        this.store = {};
        this.resetStore();

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case REGISTRATION_FORM_CHANGE:
                    this.store = Object.assign(
                        this.store,
                        payload,
                        {
                            formIsValid: true,
                            violations: {},
                            showRecaptchaError: false
                        }
                    );

                    break;

                case FORM_VALIDATION_FAIL:
                    this.store = Object.assign(this.store, payload, { recaptchaResponse: {} });

                    break;

                case INITIALIZE_FORM:
                    this.resetStore();

                    break;

                default:
                    return true;
            }
            this.emitChange();

        });
    }

    /**
     * @protected
     */
    resetStore() {
        this.store = {};
    }
}
