/**
 * Created by Alexey Ilasov on 26.01.2017.
 */
import React, {Component} from 'react';
import {
    MODE_CLIENT,
    USER_TYPE_FREELANCER,
    BOOK_APPOINTMENT_NEW,
    BOOK_APPOINTMENT_EDIT
} from './../../frizo-shared-constants';
import {bookAptClientStore, bookAptConfStore, bookAptSalonStore} from '../../stores/index'

import PhoneStaticMask from './../../elements/phone-static-mask';

const makeFullName = function (first, last) {
    return `${first} ${last}`;
};

export default  class BookHeader extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            mode: '',
            clientInfo: {name: '', phone: ''},
            salonInfo: {name: '', address: ''},
        };
        this.onInfoChange = this.onInfoChange.bind(this);
    }


    createClientPart() {

        let clientName = this.state.clientInfo.name;
        let clientPhone = this.state.clientInfo.phone;

        return <span>for <b>{clientName},</b> <PhoneStaticMask phone={clientPhone} /></span>;
    }

    createSalonPart() {

        if (this.state.salonInfo.address) {
            return (<span><b>{this.state.salonInfo.name},</b> {this.state.salonInfo.address}</span>);
        }

        return (<span>{this.state.salonInfo.name}</span>)

    }

    componentDidMount() {
        bookAptClientStore.addChangeListener(this.onInfoChange);
        bookAptSalonStore.addChangeListener(this.onInfoChange);
        this.onInfoChange();
    }

    componentWillUnmount() {
        bookAptClientStore.removeChangeListener(this.onInfoChange);
        bookAptSalonStore.removeChangeListener(this.onInfoChange);
    }

    onInfoChange() {
        let salonId = bookAptConfStore.getStore().config.salonId;
        let client = bookAptClientStore.getStore();
        let salon = bookAptSalonStore.getStore().salonSet[salonId];

        if (salon) {
            this.createSalonInfo(salon, bookAptConfStore.getStore().config.artistId);
        }

        if (client && salon) {
            this.setState({
                clientInfo: {
                    name: makeFullName(client.firstName, client.lastName),
                    phone: client.phone
                },
                mode: bookAptConfStore.store.config.mode,
                salonInfo: this.createSalonInfo(salon, bookAptConfStore.getStore().config.artistId),
            });
        }

    }

    createSalonInfo(salon, artistId = 0) {
        let artist;
        if (artistId)   {

            salon.users.forEach(user => {
                if (user.id === artistId)   {
                    artist = user

                    return false;
                }
            });

        }
        else {
            artist = salon.users[0];
        }

        if (artist.rawUserType === USER_TYPE_FREELANCER) return {name: makeFullName(artist.firstName, artist.lastName)};
        let {zip, address, city, state} = salon.addresses[0];

        return {
            name: salon.name,
            address: `${address},  ${city},  ${state} ${zip}`
        }
    };

    createTitle() {
        let title = '';
        switch (this.props.action) {
            case BOOK_APPOINTMENT_EDIT:
                title = 'Modify';
                break;

            case BOOK_APPOINTMENT_NEW:
                title = 'Book';
                break;
            default:
                title = '';
        }

        return <h2>{`${title} appointment`}</h2>

    }


    render() {

        let title = this.createTitle();

        if (this.state.mode === MODE_CLIENT) {
            let clientPart = this.createClientPart();
            let salonPart = this.createSalonPart();

            return (<div>{title}
                <div>{clientPart} at {salonPart}</div>
            </div>);
        }

        return <div>{title}</div>;
    }
}

BookHeader.defaultProps = {
    salon: null,
    client: null,
    action: '',
    type: MODE_CLIENT
};