/**
 * Created by fliak
 * Name: George Novik
 * Company: U6 SIA
 * Date: 29.01.2018
 * Time: 14:40
 */

import React, { Component } from 'react';
import ServicePriceRange from '../../modal/components/service-price-range';
import { PRICE_RANGE_SIGNS } from '../../../constants';
import { Img } from "../../../elements/img";
import {
    DROPDOWN_PLACEHOLDER_VALUE,
    IMAGE_SIZE_LARGE,
    MODE_CLIENT
} from "../../../frizo-shared-constants";
import { FavoriteMark } from "../../favorites/favorite-mark";
import GalleryFullscreenItemContainer from "../gallery-parts/gallery-fullscreen-item-container";
import GalleryImageTitle from "../../gallery/elements/gallery-image-title";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import AuthButton from "../../../require-auth/auth-button";
import { imageUrl } from "../../../helper/image-url";
import { startBooking } from "../../../ac/book-apt/common-actions";
import moment from 'moment';
import ApiClient from "../../../service/api-client";
import GalleryShareButton from "../gallery-parts/gallery-share-button";
import getImageTitle from '../../../helper/get-image-title';
import AirtouchCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";

export default class SalonPromoFullscreenLook extends Component {

    bookAction(e) {
        e.preventDefault();
        let date = moment(new Date()).format('YYYY-MM-DD');
        startBooking(
            MODE_CLIENT,
            this.props.currentItem.artist.id,
            this.props.storeParams.salonId,
            date, DROPDOWN_PLACEHOLDER_VALUE, this.props.currentItem.service.id, this.props.currentItem.image.id, imageUrl(this.props.currentItem.image.url));


        return false;
    }


    render() {

        let shareUrl = ApiClient.getRouting().generate("frizo_salons_gallery_image_item", {gallertyItemId: this.props.currentItem.id}, true);
        let imgSrcUrl = this.props.showImageAfter ? this.props.currentItem.image.url : this.props.currentItem.imageBefore.url;
        let imgDescription = getImageTitle(this.props.currentItem);

        return <GalleryFullscreenItemContainer>
            {this.props.showImageAfter && <ServicePriceRange
                priceRange= {this.props.currentItem.priceLevel}
                priceRangeSigns={PRICE_RANGE_SIGNS} />}

            {this.props.showImageAfter && this.props.favoritesEnabled && <FavoriteMark id={this.props.currentItem.id} type={this.props.currentItem.galleryItemType} inModal={true} /> }

            <Img size={IMAGE_SIZE_LARGE} className="img-responsive" src={imgSrcUrl} description={imgDescription} />
            <div className="img-slider"></div>

            {this.props.showPrev && this.props.prevArrow}
            {this.props.showNext && this.props.nextArrow}
            {this.props.currentItem.artist.airtouchCertified && <AirtouchCertificateBadge {...this.props.currentItem.artist}/>}
            <GalleryItemCardInfo>
                <GalleryImageTitle
                    serviceName = {this.props.currentItem.service.title}
                    firstName = {this.props.currentItem.artist.firstName}
                    lastName = {this.props.currentItem.artist.lastName}
                />

                <GalleryItemButtonsContainer style={{visibility: this.props.showImageAfter ? '' : 'hidden'}}>
                    <AuthButton autoHideModal={false} onClick={this.bookAction.bind(this)}
                                className="btn fr-btn">Book</AuthButton>
                    <GalleryShareButton title="Share" className={"fr-btn fr-card-btn__item fr-card-btn__promo"} shareUrl={shareUrl} imageUrl={this.props.currentItem.image.url}/>
                </GalleryItemButtonsContainer>
            </GalleryItemCardInfo>

        </GalleryFullscreenItemContainer>
    }

}
