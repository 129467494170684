/**
 * Created by fliak on 12.12.16.
 */

import React from 'react';
import moment from 'moment';
import config from './../../config';
import PropTypes from 'prop-types';

export function LocalTime(props) {

    let value = moment.tz(props.timestamp * 1000, props.tz);

    let format = props.format || config.defaultTimeFormat;

    return (
        <span className="noselect">
            { value.format(format) }
        </span>
    );
}

LocalTime.defaultProps = {
    tz: "UTC",
    format: null
};

LocalTime.propTypes = {
    timestamp: PropTypes.number.isRequired,
    tz: PropTypes.string,
    format: PropTypes.string
};

export default function Time(props) {
    let value;

    value = moment.utc(props.startPointDate).add(props.timeFromStartPoint, 'seconds');

    if (!value.isValid()) return <span />;

    if(String(value.format('mm')) === '15' || String(value.format('mm')) === '45') {
        return (
            <span className="noselect small">{ value.format(config.defaultTimeFormat) }</span>
        );
    }

    if(String(value.format('mm')) === '00') {
        return (
            <span className="noselect bold">{ value.format(config.defaultTimeFormat) }</span>
        );
    }

    return (
        <span className="noselect">{ value.format(config.defaultTimeFormat) }</span>
    );
}

Time.defaultProps = {
    startPointDate: "1970-01-01"
};

Time.propTypes = {
    startPointDate: PropTypes.string,
    timeFromStartPoint: PropTypes.number
};
