/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 27.10.17
 */

const DEFAULT_OPTIONS = {
    fetchAddressFromSchedule: true
};

class ArtistIndexer {

    /**
     *
     * @param options
     */
    constructor(options = DEFAULT_OPTIONS) {
        this.options = options;

        this.artistSet = [];
        this.artistIndex = {};

        this.addressSet = [];
        this.addressIndex = {};

        this.artistIndexReady = false;
        this.addressIndexReady = false;
    }

    putAddressSet(addressSet) {
        this.addressSet = this.addressSet.concat(addressSet);

        return this;
    }

    putArtistSet(artistSet) {
        this.artistSet = this.artistSet.concat(artistSet);

        return this;
    }

    /**
     * @protected
     */
    buildAddressIndex() {
        this.addressIndex = {};

        this.addressSet.forEach(address => {
            this.addressIndex[address.id] = address;
        });

        this.addressIndexReady = true;
    }

    /**
     * @protected
     */
    buildArtistIndex() {
        this.artistIndex = {};

        this.artistSet.forEach((artist) => {

            artist.scheduleIndex = {};

            artist.schedule.forEach((element) => {
                artist.scheduleIndex[element.day] = element;
                element.from = element.fromTime * 60;
                element.to = element.toTime * 60;
                element.addressId = element.address.id;

                if (this.options.fetchAddressFromSchedule) {
                    if (!this.addressIndex[element.address.id]) {
                        this.addressSet.push(element.address);
                        this.addressIndex[element.address.id] = element.address;
                    }
                }

            });

            this.artistIndex[artist.id] = artist;
        });

        this.artistIndexReady = true;

    }

    index() {
        this.buildAddressIndex();
        this.buildArtistIndex();

        return this;
    }

    getArtistIndex() {
        if (!this.artistIndexReady) {
            this.index();
        }

        return this.artistIndex;
    }

    getAddressIndex() {
        if (!this.addressIndexReady) {
            this.index();
        }

        return this.addressIndex;
    }

    getAddressSet() {
        return this.addressSet;
    }
}

export function createArtistIndexer() {
    return new ArtistIndexer();
}
