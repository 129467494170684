/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 05.04.2017
 * Time: 06:14
 */
import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import AptListRow from '../appointments/apt-list-row-component';
import {
    MODE_CLIENT,
    BOOK_APPOINTMENT_NEW, DROPDOWN_PLACEHOLDER_VALUE
} from '../../../../frizo-shared-constants';
import {startEditing, startBooking} from '../../../../ac/book-apt/common-actions';
import moment from 'moment';
import {changeAptStatus} from '../../../../ac/personal-apt';
import {openOrderApprovePopup} from './../../../../ac/payment-approval-ac';
import {modalChange} from "../../../../ac/modals";
import {PERSONAL_APPOINTMENT_DETAIL_MODAL} from "../../../../constants";
import notificationSystem from './../../../../service/notification-system';

export default class AppointmentsList extends Component {
    constructor(props) {
        super(props);

        this.onDelete = this.onDelete.bind(this);
        this.onModify = this.onModify.bind(this);
        this.onBook = this.onBook.bind(this);
        this.onPay = this.onPay.bind(this);
        this.onDecline = this.onDecline.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.getAptById = this.getAptById.bind(this);
        this.showAppointment = this.showAppointment.bind(this);

    }

    onModify(aptId) {
        let apt = this.getAptById(aptId);
        startEditing({
            mode: MODE_CLIENT,
            addressId: apt.addressId,
            salonId: apt.salonId,
            date: apt.localDate,
            artistId: apt.artist.id,
            salonClient: apt.salonClient
        });
    }

    onDelete(aptId) {
        let message = '<p class="title">Appointment cancel</p>' +
            'Are you sure you wish to cancel? </br> ';


        notificationSystem.confirm(message).then(result => {
            if (result.buttonClicked !== 'ok') {
                return false;
            }
            else {
                changeAptStatus(aptId);
            }
        });
    }

    onBook(aptId) {
        let apt = this.getAptById(aptId);
        startBooking(
            MODE_CLIENT,
            apt.artist.id,
            apt.salonId,
            moment(new Date()).format('YYYY-MM-DD'), DROPDOWN_PLACEHOLDER_VALUE,
            apt.serviceId,
            0, 0,
            apt.addressId, apt.salonClient, BOOK_APPOINTMENT_NEW);
    }


    onPay(aptId) {
        //console.log('onPay====', aptId);

        let apt = this.getAptById(aptId);

        openOrderApprovePopup(apt)
    }

    onDecline(aptId) {
        console.log('onDecline====', aptId);
    }


    getAptById(aptID) {
        let aptList = this.props.appointments;
        let index = aptList.findIndex(apt => apt.id === aptID);
        return aptList[index];
    }

    showAppointment(aptId, aptList) {
        modalChange(PERSONAL_APPOINTMENT_DETAIL_MODAL, {
            aptId: aptId,
            aptList: aptList,
        })
    }

    render() {
        let appointmentsList = this.props.appointments === undefined ? [] : this.props.appointments;
        let AptItems = [];
        if (appointmentsList.length) {
            AptItems = appointmentsList.map((apt, index) => {
                return <AptListRow key={apt.id}
                                   aptId={apt.id}
                                   aptDate={apt.startTime}
                                   aptTZ={apt.timezone}
                                   aptStartTimestamp={apt.startTimestamp}
                                   aptService={apt.serviceName}
                                   aptSalon={apt.salonName}
                                   aptArtistFirstName={apt.artist.firstName}
                                   aptArtistLastName={apt.artist.lastName}
                                   onPay={this.onPay}
                                   onModify={this.onModify}
                                   onDecline={this.onDecline}
                                   onDelete={this.onDelete}
                                   onBook={this.onBook}
                                   onShowPersonalAppointment={this.showAppointment}
                                   aptStatus={apt.status}
                                   aptList={this.props.aptList}
                />
            });
        }

        return (
            <div>
                <Row className="fr-list-header bordered ">
                    <Col xsHidden={true} smHidden={true} md={2}>Status</Col>
                    <Col xsHidden={true} smHidden={true} md={2}>Date</Col>
                    <Col xsHidden={true} smHidden={true} md={2}>Service</Col>
                    <Col xsHidden={true} smHidden={true} md={2}>Artist</Col>
                    <Col xsHidden={true} smHidden={true} md={2}>Salon</Col>
                    <Col xsHidden={true} smHidden={true} md={2}>Action</Col>

                    <Col xs={5} mdHidden={true} lgHidden={true}>Date</Col>
                    <Col xs={7} mdHidden={true} lgHidden={true}>Service</Col>
                </Row>
                {AptItems}
            </div>
        )
    }
}

