/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 14.11.2018
 * Time: 10.48
 */

import { xhr } from "../../service/api-client-2";
import { Request } from "../../service/api-client-2/request";

export function xhrGetUploadedGalleryItemsCount() {
    return xhr()
    .get("@Q2-44-Get-Uploaded-Gallery-Items-Count").end()
    .then((request: Request) => {
        return request.response;
    });
}
