/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 11.04.2017
 * Time: 14:28
 */

import {
    BOOK_PERSIST_SUCCESS, CHANGE_SALON_APPOINTMENT_FILTER,
    DELETE_SALON_APPOINTMENT,
    LOAD_SALON_APPOINTMENTS,
    MODAL_CHANGE,
    REFRESH_APPOINTMENT_LIST,
    SALON_APPOINTMENT_DETAIL_MODAL
} from "../constants";
import { loadAppointments } from "../ac/salon-apt";
import { modalHide } from "../ac/modals";

import BaseStore from "./base-store";

class SalonAptStore extends BaseStore {


    constructor(...args) {
        super(...args);

        this.store = {
            appointments: [],
            aptTotalCount: 0,
            filterParams: [],
            appointment: {},
            btnDisabled: false,
            searchString: ""
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;
            switch (type) {
                case REFRESH_APPOINTMENT_LIST:
                case BOOK_PERSIST_SUCCESS:
                    loadAppointments(this.store.filterParams);
                    break;

                case LOAD_SALON_APPOINTMENTS:
                    this.store.appointments = payload.requestData;
                    this.store.aptTotalCount = payload.requestTotalCount;
                    this.store.filterParams = payload.requestParams;
                    this.store.btnDisabled = false;
                    this.emitChange();
                    break;

                case DELETE_SALON_APPOINTMENT:
                    this.deleteApt(payload);
                    this.emitChange();
                    break;

                case MODAL_CHANGE:
                    if (payload.type === SALON_APPOINTMENT_DETAIL_MODAL) {
                        this.store.appointment = this.getAppointmentById(payload.options.aptId);
                    }
                    this.store.btnDisabled = false;
                    break;
                case CHANGE_SALON_APPOINTMENT_FILTER:

                    this.store =
                        {
                            appointments: [],
                            aptTotalCount: 0,
                            filterParams: [],
                            appointment: {},
                            btnDisabled: false
                        };
                    console.log(this.store, payload);
                    this.emitChange();
                    break;
                default:
                    return true;
            }
        });
    }

    getAppointments() {
        return this.store.appointments;
    }

    deleteApt(aptID) {
        const aptList = this.store.appointments;
        const indexToRemove = aptList.findIndex(apt => apt.id === aptID);
        aptList.splice(indexToRemove, 1);
        this.store.appointments = aptList;
        this.store.aptTotalCount = this.store.aptTotalCount - 1;
        this.store.btnDisabled = false;
        setTimeout(() => modalHide(), 0);
    }

    getAppointmentById(aptId) {
        let aptList = this.store.appointments;
        let appointment = aptList.filter(function(apt) {
                return apt.id === aptId;
            }
        );
        return appointment[0];
    }
}

export default SalonAptStore;
