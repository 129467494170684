/**
 * Created by fliak
 * Name: George Novik
 * Company: U6 SIA
 * Date: 05.12.2017
 * Time: 14:40
 */

import React, { Component } from 'react';
import { Img } from "../../../elements/img";
import { IMAGE_SIZE_LARGE } from "../../../frizo-shared-constants";
import { FavoriteMark } from "../../favorites/favorite-mark";
import GalleryFullscreenItemContainer from "../gallery-parts/gallery-fullscreen-item-container";
import noPhoto from './../../../../../img/frizo-no-photo-medium.png';
import getImageTitle from '../../../helper/get-image-title';
import AirtouchCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";

export default class SimpleGalleryFullscreenLook extends Component {
    render() {
        let imageUrl = this.props.currentItem.image ? this.props.showImageAfter ? this.props.currentItem.image.url : this.props.currentItem.imageBefore.url: noPhoto;
        let imgDescription = getImageTitle(this.props.currentItem);

        return <GalleryFullscreenItemContainer>

            {this.props.favoritesEnabled && <FavoriteMark id={this.props.currentItem.id} type={this.props.currentItem.galleryItemType} inModal={true} /> }
            {this.props.currentItem.artist.airtouchCertified && <AirtouchCertificateBadge {...this.props.currentItem.artist}/>}
            <Img size={IMAGE_SIZE_LARGE} className="img-responsive" src={imageUrl} description={imgDescription} />
            <div className="img-slider"></div>

            {this.props.showPrev && this.props.prevArrow}
            {this.props.showNext && this.props.nextArrow}

        </GalleryFullscreenItemContainer>
    }

}

