/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.11.17
 * Time: 8.20
 */
import AbstractGalleryStore from "./abstract-gallery-store";

export default class GenericGalleryStore extends AbstractGalleryStore {
}
