/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.7.17
 */

import ApiClient from "../../service/api-client";
import { CUSTOM_HEADER_U6_GROUPS } from "./../../frizo-shared-constants";

export function xhrClosePaymentOrderWithCash(paymentOrderId) {

    let url = ApiClient.getRouting().generate("Q120_close_payment_order_with_cash", {
        paymentOrderId
    });

    return ApiClient.put(url, null, {
        headers: {
            [CUSTOM_HEADER_U6_GROUPS]: "payment-order"
        }
    }).then(answer => answer[0], answer => Promise.reject(answer[0]));
}
