/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 6/20/17
 * Time: 19:31
 */

import React, {Component} from 'react';

import {faqStore} from '../../stores';
import {loadFaqForClients} from './../../ac/faq-actions';
import {Accordion, Panel} from 'react-bootstrap';
import {modalChange} from '../../ac/modals';
import {
    CONTACT_MODAL
} from '../../constants';


export default class FAQClients extends Component {

    constructor(props) {
        super(props);
        this.state = {
            faq: []
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        loadFaqForClients();
        faqStore.addChangeListener(this.handleChange);
    }

    componentWillUnmount() {
        faqStore.removeChangeListener(this.handleChange);
    }

    handleChange() {
        this.setState({faq: faqStore.getStore().faqClients});
    }

    createQuestions(questions){
        return questions.map((question, index) => (<Accordion key={question.id}>
                <Panel header={question.questionEn} eventKey={question.id}  collapsible>
                    <div dangerouslySetInnerHTML={{__html: question.answerEn}} />
                </Panel>
            </Accordion>)
        )
    }

    createTopic() {
        return this.state.faq.map((topic, index) => (<div key={index}>
                <div className="fr-profile__title">{topic[0].titleEn}</div>
                {this.createQuestions((topic))}
            </div>)
        )
    }

    openContactUs() {
        modalChange(CONTACT_MODAL);
    }

    render() {
        return (<div>
            {this.createTopic()}
            <div className={"fr-faq-contact"}>If you did not find an answer to your question, please <button type="button" className="btn btn-link btn-contact" onClick={this.openContactUs}>contact us</button>.</div>
        </div>);
    }
}