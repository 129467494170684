/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.7.17
 * Time: 14.44
 */

import ApiClient from "./../../../service/api-client";

export function xhrGetStripeAccount() {
    let url = ApiClient.getRouting().generate("Q106_get_stripe_managed_account");

    return ApiClient.get(url).then((answer) => {
        let [response] = answer;

        return Promise.resolve(response);
    });
}

export function xhrGetPaymentDataCompletionReport(type) {

    let url = ApiClient.getRouting().generate("Q2-54-Get-Payment-Data-Completion-Report", { type: type });

    return ApiClient.get(url).then((answer) => {
        let [response] = answer;
        return Promise.resolve(response);
    });
}

export function xhrGetStripeAccountPersons() {
    let url = ApiClient.getRouting().generate("Q106-2_get_stripe_managed_account_persons");

    return ApiClient.get(url).then((answer) => {
        let [response] = answer;

        return Promise.resolve(response);
    });
}