/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 21.03.2017
 * Time: 12:34
 */
import React, {Component} from 'react';
import {Modal, Button, Form, FormGroup, Label} from 'react-bootstrap';
import Validator from '../../validation/validator';
import PhoneInput from './../../elements/phone-input';
import Violation from '../helper-components/violation';
import {
    addClientAction,
    addClientVisibility,
    editClientAction,
    deleteClient,
    removeClientVisibility,
    searchClientAction
} from '../../ac/clients';
import {modalHide} from "../../ac/modals";
import {clientsStore, sessionStore} from '../../stores/index';
import PropTypes from 'prop-types';
import notificationSystem from './../../service/notification-system';
import gradientFill from './../../helper/gradient-fill';

export default class ClientModal extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            id: 0,
            firstName: '',
            lastName: '',
            phone: '',
            comment: '',
            clientExist: false,
            serverErrors: '',
            violations: {},
            btnDisabled: false
        }, this.getStoreClientData());

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.addClient = this.addClient.bind(this);
        this.searchClient = this.searchClient.bind(this);
        this.resetData = this.resetData.bind(this);
        this.close = this.close.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.storeUpdate = this.storeUpdate.bind(this);

    }

    componentDidMount() {
        clientsStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        clientsStore.removeChangeListener(this.storeUpdate);
    }

    getStoreClientData() {
        return clientsStore.getStore().client;
    }

    storeUpdate() {
        let object = Object.assign({
            btnDisabled: clientsStore.getStore().btnDisabled,
            clientExist: clientsStore.getStore().clientExist
        }, this.getStoreClientData());
        this.setState(object);
    }

    resetData() {
        this.setState({
            id: 0,
            lastName: '',
            firstName: '',
            comment: '',
            clientExist: false
        });
    }


    handleFieldChange(field) {
        return function (e) {
            this.setState({
                [field]: e.target.value
            })
        }.bind(this);

    }

    handlePhoneChange(e) {
        this.setState({
            phone: e.target.rawValue
        });
        if (e.target.rawValue.length === 10) {
            this.searchClient(e.target.rawValue);
        }
        else {
            if (this.state.id) {
                this.resetData();
            }
        }
    }

    searchClient(phone) {
        searchClientAction(phone);
    }

    addClient() {

        let validator = new Validator();
        //firstName
        validator.addConstraint('firstName', 'is-required');
        validator.addConstraint('firstName', 'is-name');
        validator.addConstraint('firstName', 'length', {
            min: 2,
            max: 32  // until backend refactor
        });

        //lastName
        validator.addConstraint('lastName', 'is-required');
        validator.addConstraint('lastName', 'is-name');
        validator.addConstraint('lastName', 'length', {
            min: 2,
            max: 32  // until backend refactor
        });


        //phone
        validator.addConstraint('phone', 'is-required');
        validator.addConstraint('phone', 'is-number');
        validator.addConstraint('phone', 'length', {
            max: 10,
            min: 10
        });
        let violations = validator.validate({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phone,
        });

        this.setState({
            formIsValid: violations === true,
            violations: violations === true ? {} : violations
        });

        if (violations === true) {
            this.setState({btnDisabled: true});
            if (this.props.clientId) {
                editClientAction(this.props.clientId,
                    {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        phone: this.state.phone,
                        comment: this.state.comment
                    });
            }
            else {
                if (this.state.id) {
                    addClientVisibility({
                        id: this.state.id,
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        phone: this.state.phone,
                        comment: this.state.comment
                    });
                }
                else {
                    addClientAction({
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        phone: this.state.phone,
                        comment: this.state.comment
                    });
                }
            }
        }
    }

    onDelete() {
        let message = '<p class="title">Delete client</p>' +
            'You are about to permanently delete this client </br> ';


        notificationSystem.confirm(message).then(result => {
            if (result.buttonClicked !== 'ok') {
                return false;
            }
            this.setState({btnDisabled: true});
            if (sessionStore.isBusinessAccount()) {
                deleteClient(this.props.clientId);
            }
            else removeClientVisibility(this.props.clientId);
        });

    }

    close() {
        modalHide();
    }

    render() {
        let modalTitle = this.props.clientId ? 'Edit client ' : 'Add client';
        let buttonTitle = this.props.clientId ? 'Save' : this.state.id ? "Add to my list" : "Add";
        let buttonStyle = this.state.id || this.props.clientId ? "client-modal__footer fr-btn-client__save" : "client-modal__footer fr-btn-client__edit";


        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>{modalTitle}</h2>
                </Modal.Header>
                <Modal.Body className="client-modal__body">
                    <Form>
                        <FormGroup>

                            <PhoneInput className="form-control" type="text"
                                        value={this.state.phone}
                                        placeholder="Phone"
                                        mask='(111) 111-1111'
                                        onChange={this.handlePhoneChange}
                                        ref="phone"
                                        autoFocus={true}
                                        disabled={this.props.clientId}
                            />
                            {this.state.clientExist && <li className="violation fr-error fr-alert-msg">
                                This client already exist</li>}
                            <Violation violationSet={this.state.violations.phone}
                                       visible={!this.state.formIsValid}/>

                        </FormGroup>
                        <FormGroup>
                            <input className="form-control" value={this.state.firstName}
                                   type="text" placeholder="First name"
                                   onChange={this.handleFieldChange('firstName')}
                                   ref="firstName"
                                   disabled={this.state.clientExist}
                            />
                            <Violation violationSet={this.state.violations.firstName}
                                       visible={!this.state.formIsValid}/>
                        </FormGroup>
                        <FormGroup>
                            <input className="form-control" value={this.state.lastName}
                                   type="text" placeholder="Last name"
                                   onChange={this.handleFieldChange('lastName')}
                                   disabled={this.state.clientExist}
                            />
                            <Violation violationSet={this.state.violations.lastName}
                                       visible={!this.state.formIsValid}/>
                        </FormGroup>
                        <h3>Optional</h3>
                        <FormGroup>
                            <textarea className="form-control fr-textarea-as-input" type="text"
                                      value={this.state.comment || ''}
                                      placeholder="Comment"
                                      onChange={this.handleFieldChange('comment')}
                                      disabled={this.state.clientExist}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="client-modal__footer">
                    {!this.state.clientExist && <Button className={buttonStyle}
                                                        onClick={this.addClient}
                                                        disabled={this.state.btnDisabled}>{buttonTitle}</Button>}
                    {this.state.clientExist && <Button className="btn fr-fr fr-btn"
                                                       onClick={this.close}
                                                       disabled={this.state.btnDisabled}>OK</Button>}
                    {this.props.clientId !== 0 && <Button className="client-modal__footer fr-btn-client__remove"
                                                          onClick={this.onDelete} disabled={this.state.btnDisabled}>Delete</Button>}
                    {!!this.state.serverErrors.length &&
                    <Label className="fr-span-message" bsStyle="danger">{this.state.serverErrors}</Label>}
                </Modal.Footer>
            </div>
        )
    }
}
ClientModal.propTypes = {
    clientId: PropTypes.number
};

ClientModal.defaultProps = {
    clientId: 0
};