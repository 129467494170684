/**
 * Created by fliak
 * Name: George Novik
 * Company: U6 SIA
 * Date: 05.12.2017
 * Time: 14:40
 */

import React, { Component } from 'react';
import { Img } from "../../../elements/img";
import {
    DROPDOWN_PLACEHOLDER_VALUE,
    IMAGE_SIZE_LARGE,
    MODE_CLIENT
} from "../../../frizo-shared-constants";
import { FavoriteMark } from "../../favorites/favorite-mark";
import GalleryFullscreenItemContainer from "../gallery-parts/gallery-fullscreen-item-container";
import GalleryImageTitle from "../../gallery/elements/gallery-image-title";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import AuthButton from "../../../require-auth/auth-button";
import { imageUrl } from "../../../helper/image-url";
import { startBooking } from "../../../ac/book-apt/common-actions";
import moment from 'moment';
import ApiClient from "../../../service/api-client";
import GalleryShareButton from '../gallery-parts/gallery-share-button';
import getImageTitle from '../../../helper/get-image-title';

export default class PersonalProfileFavoritesFullscreenLook extends Component {

    bookAction(e) {
        e.preventDefault();

        console.log('this.props.currentItem', this.props.currentItem);
        let date = moment(new Date()).format('YYYY-MM-DD');
        startBooking(
            MODE_CLIENT,
            this.props.currentItem.artist.id,
            this.props.currentItem.salon.id,
            date, DROPDOWN_PLACEHOLDER_VALUE, this.props.currentItem.service.id, this.props.currentItem.image.id, imageUrl(this.props.currentItem.image.url));


        return false;
    }


    render() {

        let shareUrl = ApiClient.getRouting().generate("frizo_salons_gallery_image_item", {gallertyItemId: this.props.currentItem.id}, true);
        let imgSrcUrl = this.props.showImageAfter ? this.props.currentItem.image.url : this.props.currentItem.imageBefore.url;
        let imgDescription = getImageTitle(this.props.currentItem);

        return <GalleryFullscreenItemContainer>

            {this.props.showImageAfter && this.props.favoritesEnabled && <FavoriteMark id={this.props.currentItem.id} type={this.props.currentItem.galleryItemType} inModal={true} /> }

            <Img size={IMAGE_SIZE_LARGE} className="img-responsive" src={imgSrcUrl} description={imgDescription} />
            <div className="img-slider"></div>

            {this.props.showPrev && this.props.prevArrow}
            {this.props.showNext && this.props.nextArrow}

            <GalleryItemCardInfo>
                <GalleryImageTitle
                    serviceName = {this.props.currentItem.service.title}
                    firstName = {this.props.currentItem.artist.firstName}
                    lastName = {this.props.currentItem.artist.lastName}
                />

                <GalleryItemButtonsContainer style={{visibility: this.props.showImageAfter ? '' : 'hidden'}}>
                    <AuthButton autoHideModal={false} onClick={this.bookAction.bind(this)}
                                className="btn fr-btn">Book</AuthButton>
                    <GalleryShareButton title="Share" className="fr-btn" shareUrl={shareUrl} imageUrl={this.props.currentItem.image.url}/>
                </GalleryItemButtonsContainer>
            </GalleryItemCardInfo>

        </GalleryFullscreenItemContainer>
    }

}
