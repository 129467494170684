/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 21.4.17
 * Time: 7.19
 */


import React, {Component} from 'react';
import ToggleGroup from "./toggle-group";
import PropTypes from 'prop-types';

export default class RoutedGroup extends Component {

    constructor(props, context) {
        super(props, context);

        let router = this.props.router || this.context.router;
        let currentRoute = router.location.pathname; //router.routes[router.routes.length - 1];

        this.state = {
            path: currentRoute
        };

        this.onSelect = this.onSelect.bind(this);
        this.isOption = this.isOption.bind(this);
    }

    componentDidMount() {
        if (
            this.props.forceTo &&
            this.props.forceTo !== this.state.path &&
            !this.isOption(this.state.path)
        )   {

            let router = this.props.router || this.context.router;

            router.push(this.props.forceTo);

            this.setState({
                path: this.props.forceTo
            });
        }
    }

    isOption(value)  {

        let match = false;
        const { children } = this.props;

        React.Children.forEach(children, child => {
            if (!React.isValidElement(child)) {
                return;
            }

            if (child.props.value === value)    {
                match = true;

                return false;
            }
        });

        return match;
    }

    componentWillReceiveProps(nextProps)    {
        let router = this.props.router || this.context.router;
        let newRoute =router.location.pathname; // router.routes[router.routes.length - 1];

        this.setState({
            path: newRoute
        })
    }

    onSelect(value)  {
        let router = this.props.router || this.context.router;
        router.push(value);

        this.props.onSelect(value);
    }

    render()    {

        let props = Object.assign({}, this.props);
        delete props.forceTo;

        return <ToggleGroup {...props} selected={this.state.path} onSelect={this.onSelect}>
            {this.props.children}
        </ToggleGroup>;
    }

}



RoutedGroup.contextTypes = {
    router: PropTypes.object.isRequired
};

RoutedGroup.propTypes = {
    onSelect: PropTypes.func
};

RoutedGroup.defaultProps = {
    onSelect: () => {}
};