/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 2.10.17
 */

import { sessionStore } from "./../../stores";

import { LOGIN_MODAL } from "./../../constants";

import { modalChange } from "./../../ac/modals";

export function authenticationAssertion() {
    return new Promise((success, fail) => {
        sessionStore.getReadyPromise().then(store => {
            if (sessionStore.isLogged()) {
                success();

                return store;
            }

            return modalChange(LOGIN_MODAL, {
                inTransition: true,
                autoHide: true,
                previous: () => {
                    success();
                }
            });
        });
    });
}
