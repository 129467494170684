/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.7.17
 */

import ApiClient from "../../service/api-client";
import { CUSTOM_HEADER_U6_GROUPS } from "./../../frizo-shared-constants";
import { centToUsd } from "../../helper/money-converter";

export function xhrCreatePaymentOrder(orderData) {

    let url = ApiClient.getRouting().generate("Q113_create_payment_order");

    let aptPriceList = orderData.services.map(service => {
        return {
            appointmentId: service.id,
            price: centToUsd(service.price),
            priceCent: service.price
        };
    });

    return ApiClient.post(url, {
        salonClientId: orderData.salonClientId,
        addressId: orderData.addressId,
        date: orderData.date,
        aptPriceList: JSON.stringify(aptPriceList),
        serviceTotalEntered: centToUsd(orderData.serviceTotalEntered),
        serviceTotalEnteredCent: orderData.serviceTotalEntered,
        taxPercentDefault: orderData.taxPercentDefault,
        taxPercent: orderData.taxPercent,
        taxValue: centToUsd(orderData.taxValue),
        taxValueCent: orderData.taxValue,
        serviceAndTax: centToUsd(orderData.serviceAndTax),
        serviceAndTaxCent: orderData.serviceAndTax,
        paymentType: orderData.paymentType

    }, {
        headers: {
            [CUSTOM_HEADER_U6_GROUPS]: "payment-order"
        }
    }).then(answer => answer[0], answer => Promise.reject(answer[0]));
}
