/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 9.11.17
 * Time: 14.22
 *
 * @flow
 */

import { addressNormalize1 } from "./address";
import type { TRawSchedule } from "./raw-types";
import type { TAddressSchedule, TAvailableSchedule } from "../types";

export function normalizeSchedule(schedule: Array<TRawSchedule>): {
    addressSchedule: TAddressSchedule,
    availableSchedule: TAvailableSchedule
} {

    let availableSchedule: TAvailableSchedule = {};
    let addressSchedule: TAddressSchedule = {};

    schedule.forEach(wh => {

        addressSchedule[wh.day] = addressNormalize1(wh.address);

        if (availableSchedule[wh.day] === undefined) {
            availableSchedule[wh.day] = [];
        }

        if (wh.dayOff) return true;                     //skip day offs
        if (wh.toTime - wh.fromTime === 0) return true; //skip empty intervals

        availableSchedule[wh.day].push([wh.fromTime * 60, wh.toTime * 60]);

    });

    return { availableSchedule, addressSchedule };
}
