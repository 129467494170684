/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.21.4
 * Time: 17:47
 */

import React, {Component} from 'react';
import ArtistForm from '../../professional/artists/artist-form';
import ArtistStatic from '../../professional/artists/artist-static';
import ArtistServices from '../../professional/artists/artist-services';
import {
    addArtistAction,
    loadArtistInfoAction,
    loadArtistServicesAction,
    removeArtistServiceAction,
    addArtistServiceAction,
    modifyArtistServiceAction,
    updateArtistFormAction, sendInvitation
} from '../../../../ac/profile-artists/common-actions';
import {loadProfileCompletenessInfo} from './../../../../ac/profile-completeness-actions';

import {profileArtistServicesStore} from '../../../../stores/index';
import {ARTIST_SERVICE_MODAL} from "../../../../constants";
import {modalChange} from "../../../../ac/modals";
import LoaderBar from '../../../../elements/loader-bar';


export default class ProfileArtist extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            artistInfo: {id: this.props.artistId},
            servicesInfo: {},
            violations: {},
            btnDisabled: false
        };
        this.saveHandler = this.saveHandler.bind(this);
        this.storeChange = this.storeChange.bind(this);
        this.removeService = this.removeService.bind(this);
        this.addService = this.addService.bind(this);
        this.modifyService = this.modifyService.bind(this);
        this.onEditModal = this.onEditModal.bind(this);
        this.sendInvitationHandler = this.sendInvitationHandler.bind(this);
    }

    saveHandler() {
        return addArtistAction().then(() => {
            return loadProfileCompletenessInfo();
        }, () => {
            //ignore
        })
    }

    removeService(serviceId, artistId) {

        return () => {
            removeArtistServiceAction(artistId, serviceId);
        }
    }

    addService(artistId, service) {
        addArtistServiceAction(artistId, service);
    }

    modifyService(artistId, service) {
        modifyArtistServiceAction(artistId, service);
    }

    storeChange() {
        this.setState({
            artistInfo: profileArtistServicesStore.getArtistInfo(),
            servicesInfo: profileArtistServicesStore.getServicesInfo(),
            violations: profileArtistServicesStore.getStore().violations,
            btnDisabled: profileArtistServicesStore.getStore().btnDisabled
        });
    }

    onEditModal(artistId, service, assigned) {
        modalChange(ARTIST_SERVICE_MODAL, {artistId, service, assigned});
    }

    sendInvitationHandler() {
        sendInvitation(this.props.artistId);
    }

    componentDidMount() {
        profileArtistServicesStore.addChangeListener(this.storeChange);
        if (this.props.artistId) {
            loadArtistInfoAction(this.props.artistId);
        }
        loadArtistServicesAction(this.props.artistId);
    }

    componentWillUnmount() {
        profileArtistServicesStore.removeChangeListener(this.storeChange);
        profileArtistServicesStore.resetStore();
    }


    isReady() {
        if (this.props.artistId) {
            return (Object.keys(this.state.artistInfo).length && Object.keys(this.state.servicesInfo).length);
        }

        return Object.keys(this.state.servicesInfo).length
    }

    createArtistInfo() {
        if (this.state.artistInfo.activated) {

            return <ArtistStatic {...this.state.artistInfo} formTitle="Edit Artist's services"/>
        }

        return <ArtistForm
            {...this.state.artistInfo}
            violations={this.state.violations}
            onSaveHandler={this.saveHandler}
            onChange={updateArtistFormAction}
            sendInvitationHandler={this.sendInvitationHandler}
            formMode={this.props.mode}
            btnDisabled={this.state.btnDisabled}
        />
    }

    render() {
        if (this.isReady()) {
            if (this.props.master) {

                return <div>
                    <ArtistServices
                        artistId={this.state.artistInfo.id}
                        disableActions={true}
                        master={true}
                        showFirst={true}
                        {...this.state.servicesInfo}
                        onRemove={this.removeService}
                        onAssign={this.addService}
                        onModify={this.modifyService}/>
                </div>
            }

            return <div>
                {this.createArtistInfo()}
                <ArtistServices artistId={this.state.artistInfo.id} {...this.state.servicesInfo}
                                onRemove={this.removeService} onAssign={this.addService} onModify={this.modifyService}
                                onEdit={this.onEditModal}
                />
            </div>;
        }

        return <LoaderBar className="loader-msg-wrapper--full-height"/>

    }
}

ProfileArtist.defaultProps = {
    artistId: 0,
    master: false
};
