/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.9.17
 */

import data from "./../resource/meta-data-map.json";
import { tagBuilder } from "./tag-builder";

import metaTagRenderer from "./meta-tag-renderer";

class MetaDataService {
    constructor(routesMap, rootRoute = "/") {
        this.routesMap = routesMap;
    }

    mergeMetaDataForRoutes(routes) {

        let routesSpec = routes.filter(route => {
            return !!this.routesMap[route];
        }).map(route => {
            return this.routesMap[route];
        });

        //console.log('dataSet', dataSet)

        let tagSet = routesSpec.map(routeData => {
            return routeData.tags || {};
        });

        let dataSet = routesSpec.map(routeData => {
            return routeData.data || {};
        });

        let tags = Object.assign({}, ...tagSet);
        let data = Object.assign({}, ...dataSet);

        return {
            tags, data
        };
    }

    matchRoute(state) {

        let routes = state.routes.filter(route => {
            return route.name || route.path;
        }).map(route => {
            return route.name || route.path;
        });

        let routeSpec = this.mergeMetaDataForRoutes(routes);

        let builder = tagBuilder();

        for (let id in routeSpec.tags) {
            let tagSpec = routeSpec.tags[id];

            builder
            .addTag(tagSpec.tag)
            .setBody(tagSpec.body);
        }

        for (let key in routeSpec.data) {
            let value = routeSpec.data[key];

            builder.addDataParam(key, value);
        }

        let tags = builder.getTags();

        metaTagRenderer.render(tags);
    }
}

export default new MetaDataService(data);
