/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 6/26/17
 * Time: 21:28
 */

import AppDispatcher from "../dispatcher";
import { LOAD_FAQ_FOR_CLIENTS, LOAD_FAQ_FOR_PROFESSIONALS } from "../constants";
import { xhrLoadFaq } from "../xhr/faq-xhr";

export function loadFaqForClients() {
    xhrLoadFaq("for-clients").then(answer => {
        AppDispatcher.dispatch({
            type: LOAD_FAQ_FOR_CLIENTS,
            payload: answer
        });
    });
}

export function loadFaqForProfessionals() {
    xhrLoadFaq("for-professionals").then(answer => {
        AppDispatcher.dispatch({
            type: LOAD_FAQ_FOR_PROFESSIONALS,
            payload: answer
        });
    });
}


