/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 04.06.2017
 * Time: 19:11
 */

import React, {Component} from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import {dashboardWhenStore} from '../../../stores/index';
import {whenDateChange} from '../../../ac/dashboard-filter-actions';

export default class DashboardCalendar extends Component {
    constructor(props) {
        super();

        this.state = Object.assign({}, this.getStoreData());

        this.clickDay = this.clickDay.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
        this.getStoreData = this.getStoreData.bind(this);
    }

    onStoreChange() {
        let object = Object.assign({}, this.getStoreData());
        this.setState(object);
    }

    getStoreData() {
        return dashboardWhenStore.getStore();
    }

    componentDidMount() {
        dashboardWhenStore.addChangeListener(this.onStoreChange);
    }

    componentWillUnmount() {
        dashboardWhenStore.removeChangeListener(this.onStoreChange);
    }

    clickDay(date) {
        whenDateChange(date);
        this.props.onChange({date: moment.utc(date).unix()});
    }

    render() {

        return (
            <div className="dashboard-calendar">
                <Calendar
                    view={'month'}
                    calendarType={'US'}
                    minDate={new Date()}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    showNeighboringMonth={false}
                    onChange={this.clickDay}
                    value={this.state.selectedDate}
                />
            </div>
        )
    }
}
