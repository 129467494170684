/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 17.08.2017
 * Time: 16:10
 */

import React from 'react';
import {Col, Row} from 'react-bootstrap';

export default function WidgetHeader() {

    return ( <div className={"fr-pr-md"}>
        <Row className="fr-list-header bordered">
            <Col xs={7} md={7}>Item</Col>
            <Col xs={5} md={5}>Action</Col>
        </Row>
    </div>);
}