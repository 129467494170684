/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 04.04.2018
 * Time: 11:51
 */

import { xhr } from "../service/api-client-2";

export const xhrGetWhereHintList = function(input) {
    return xhr()
    .suppressNotifications()
    .get("@Q164_city_or_zip_hint", { input })
    .end()
    .then((request: Request) => {
            let { response } = request;
            return Promise.resolve(response);
        },
        (request: Request) => {
            let { response } = request;
            return Promise.reject(response.errors);
        });
};
