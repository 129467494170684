/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.12.5
 * Time: 09:52
 */

import React from 'react';
import {PRICE_RANGE_SIGNS} from '../../../constants';

export default function ServicePriceRange(props) {

    const generateString = (n) => {
        let chars = '';
        for (let i = 0; i < n; i++) chars = chars + '$';

        return chars;
    };

    if (props.priceRange) {

        return (<div className="fr-price-range" style={{"display": "none"}}>
            <span className="fr-bold-dollars">{generateString(props.priceRange + 1)}</span>
            <span className="fr-dollars">{generateString(props.priceRangeSigns - props.priceRange - 1)}</span>
        </div>);
    }
    return <div></div>;
}

ServicePriceRange.defaultProps = {
    priceRange: 0,
    priceRangeSigns: PRICE_RANGE_SIGNS,
};