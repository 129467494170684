/**
 * Created by Tatjana Jankova on 24.02.2017.
 */

import React, {Component} from 'react';
import {Modal, Button, Form, FormGroup, Label} from 'react-bootstrap';
import PhoneInput from '../../elements/phone-input';
import {contactUsStore} from '../../stores/index';
import Recaptcha from 'react-recaptcha';
import config from '../../config';
import {browserHistory} from 'react-router';
import {contactUs} from './../../ac/contact-us-actions';
import Validator from './../../validation/validator';
import gradientFill from '../../helper/gradient-fill';


export default class ContactUsModal extends Component {
    constructor(props) {
        super(props);

        this.state = Object.assign({
            isLogged: false,
            recaptchaResponse: '',
            violations: {},
            btnDisabled: false,
        }, contactUsStore.getInitialStore());

        this.verifyCallback = this.verifyCallback.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.close = this.close.bind(this);
        this.getStoreData = this.getStoreData.bind(this);
        this.storeUpdate = this.storeUpdate.bind(this);
        this.getStoreData = this.getStoreData.bind(this);

    }

    componentDidMount() {
        contactUsStore.addChangeListener(this.storeUpdate);
        if (this.state.isLogged) {
            this.refs.message.focus();
        }
        else {
            this.refs.email.focus();
        }
    }


    componentWillUnmount() {
        contactUsStore.removeChangeListener(this.storeUpdate);
        if (browserHistory.getCurrentLocation().pathname === '/contact-us') {
            browserHistory.push('/');
        }
    }


    getStoreData() {
        return contactUsStore.getStore();
    }

    storeUpdate() {
        let object = Object.assign({}, this.getStoreData());
        this.setState(object);
    }

    handleFieldChange(field) {
        return function (e) {
            this.setState({
                [field]: e.target.value
            })
        }.bind(this);

    }

    handlePhoneChange() {
        return function (e) {
            let stateValue = e.target.rawValue;
            this.setState({phone: stateValue});
        }.bind(this);
    }


    verifyCallback(response) {
        let showError = this.state.showError;
        showError.recaptcha = response > 0;
        this.setState({
            recaptchaResponse: response,
            showError: showError
        });
    }

    validateEmail() {
        let validEmail = Validator.validateAtomic('is-email', this.state.email) === true;
        let showError = this.state.showError;
        showError.email = !validEmail;
        this.setState({showError: showError});

    }

    validatePhone() {
        let validPhone = Validator.validateAtomic('is-phone', this.state.phone) === true;
        let showError = this.state.showError;
        showError.phone = !validPhone;
        this.setState({showError: showError});
    }

    validateMessage() {
        let showError = this.state.showError;
        showError.message = this.state.message.length < 10;
        this.setState({showError: showError});
    }

    sendMessage() {

        let validEmail = Validator.validateAtomic('is-email', this.state.email) === true;
        let validPhone = Validator.validateAtomic('is-phone', this.state.phone) === true;

        let validMessage = this.state.message.length > 10;
        let validRecaptcha = this.state.recaptchaResponse.length > 0;


        if (validEmail && validPhone && validMessage && validRecaptcha) {
            this.setState({btnDisabled: true});
            let params = {
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message
            };
            contactUs(params);

        }
        let showError = this.state.showError;
        showError.email = !validEmail;
        showError.phone = !validPhone;
        showError.message = !validMessage;
        showError.recaptcha = !validRecaptcha;
        this.setState({
            showError: showError
        });
        this.resetRecaptcha();
    }


    onLoadReCaptchaCallback() {   //required!
        // console.log('Done !!');
    }

    resetRecaptcha() {
        this.refs.recaptchaContactInstance.reset();
    };

    close() {
        this.props.close();
    }

    render() {
        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>Contact us</h2>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <p>Please contact us with any questions or comments that you may have.</p>
                        <FormGroup>

                            <input className="form-control" value={this.state.email}
                                   disabled={this.state.isLogged}
                                   type="text" placeholder="E-mail"
                                   ref="email"
                                   onChange={this.handleFieldChange('email')}
                                   onBlur={this.validateEmail.bind(this)}

                            />
                            {this.state.showError.email &&
                            <div className="error-message">The entered e-mail address is incorrect</div>}
                        </FormGroup>
                        <FormGroup>

                            <PhoneInput className="form-control" type="text"
                                        value={this.state.phone}
                                        disabled={this.state.isLogged && this.state.phone !== null}
                                        placeholder="Phone"
                                        onChange={this.handlePhoneChange()}
                                        onBlur={this.validatePhone.bind(this)}
                                        mask='(111) 111-1111'
                            />
                            {this.state.showError.phone &&
                            <div className="error-message">The entered phone number is incorrect</div>}
                        </FormGroup>
                        <FormGroup>
                            <textarea className="form-control fr-textarea-as-input" type="text"
                                      placeholder="Message"
                                      maxLength="400"
                                      ref="message"
                                      onChange={this.handleFieldChange('message')}
                                      value={this.state.message}
                                      onBlur={this.validateMessage.bind(this)}
                            />
                            {this.state.showError.message &&
                            <div className="error-message">Message too short</div>}
                        </FormGroup>
                        <p>All fields are required</p>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    <div id="Recaptcha2">
                        <Recaptcha
                            sitekey={config.googleRecaptchaSiteKey}
                            render="explicit"
                            elementID='g-recaptcha-contact'
                            ref="recaptchaContactInstance"
                            verifyCallback={this.verifyCallback}
                            onloadCallback={this.onLoadReCaptchaCallback}
                        />
                    </div>
                    <div>
                        <Button className="btn fr-btn pull-left"
                                disabled={this.state.btnDisabled}
                                onClick={this.sendMessage}>Send</Button>
                        {this.state.showError.recaptcha &&
                        <Label className="fr-span-message" bsStyle="danger">Captcha required</Label>}
                        {this.state.showError.sendMessage &&
                        <Label className="fr-span-message" bsStyle="danger">Server error</Label>}
                    </div>
                </Modal.Footer>
            </div>
        );

    }
}