/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.2.17
 * Time: 17.12
 */

import IsNumber, { VALUE_IS_NOT_A_NUMBER } from "./is-number";
import violationBuilder from "./../violation-builder";
import sprintf from "./../../helper/sprintf";
import BaseValidator from "./base-validator";

export default class IsNumberValidator extends BaseValidator {

    static getValidConstraints() {
        return [IsNumber];
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    validate(constraint, field, root = null) {
        if (!constraint instanceof IsNumber) {
            throw new Error(sprintf("IsNumber should be passed to IsNumberValidator.validate(). %s given.",
                constraint.constructor.name));
        }

        let value = root ? root[field] : field;

        if (!this.isNumeric(value)) {
            return violationBuilder.build(constraint.message, {}, field, root, VALUE_IS_NOT_A_NUMBER, value);
        }

        return true;
    }
}
