/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.11.2018
 * Time: 10:19
 */

import React, { Component } from "react";
import { Button } from "react-bootstrap";

export default class GoUpButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnUpBottom: 0,
            btnUpLeft: 0,
            btnGoTopVisible: false
        };

        this.goTop = this.goTop.bind(this);
        this.onScrollResizeHandler = this.onScrollResizeHandler.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onScrollResizeHandler);
        window.addEventListener("resize", this.onScrollResizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScrollResizeHandler);
        window.removeEventListener("resize", this.onScrollResizeHandler);
    }

    onScrollResizeHandler() {
        let headerHeight = document.querySelector("#header-wrap").getBoundingClientRect().height;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        let footerTop = document.querySelector("#footer").getBoundingClientRect().top;
        let footerHeight = document.querySelector("#footer").getBoundingClientRect().height;
        let btnGoUp = document.querySelector("#btnGoUp");
        let btnBottom = (footerTop > document.documentElement.clientHeight ? 50 : footerHeight + 98);
        let btnLeft = (document.documentElement.clientWidth - 50);

        if (btnGoUp) {
            this.setState({
                btnUpBottom: btnBottom,
                btnUpLeft: btnLeft
            });

            if (scrollTop - 5 >= headerHeight) {
                btnGoUp.classList.add("show");

            } else {

                btnGoUp.classList.remove("show");
            }
        }
    }

    componentDidUpdate() {
        let btnGoUp = document.querySelector("#btnGoUp");
        if (btnGoUp) {
            let headerHeight = document.querySelector("#header-wrap").getBoundingClientRect().height;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (!this.state.btnGoTopVisible) {
                btnGoUp.classList.remove("show");
            }
            else {
                if (scrollTop >= headerHeight) {
                    btnGoUp.classList.add("show");
                }
            }
        }
    }

    goTop() {
        document.getElementById("header-wrap").scrollIntoView({ behavior: "smooth" });
    };


    render() {


        return (
            <Button id="btnGoUp" className="fr-btn-go-up" onClick={this.goTop}
                    style={{ "bottom": this.state.btnUpBottom + "px", "left": this.state.btnUpLeft + "px" }}>
                <span className="glyphicon glyphicon-arrow-up"></span>
            </Button>
        );
    }
}
