/**
 * User: Ignat Kryshchyk
 * Username: ignat
 * Company: U6 SIA
 * Date: 15.4.17
 * Time: 15.08
 */

import AppDispatcher from "../dispatcher";

import {
    GALLERY_SELECT_LOAD_ARTISTS_SUCCESS,
    GALLERY_SELECT_ONCHANGE,
    LOAD_SELECT_SERVICES_SUCCESS
} from "./../constants";

import { xhrLoadServicesWithoutAttributes } from "../xhr/services-xhr";
import xhrLoadSalonArtists from "../xhr/salon-artists-xhr";

export function loadGallerySelectData(salonId = null) {
    let promise;

    promise = xhrLoadSalonArtists(salonId);

    return promise.then(artists => {
        AppDispatcher.dispatch({
            type: GALLERY_SELECT_LOAD_ARTISTS_SUCCESS,
            payload: { artists: normalizeArtists(artists) }
        });

        let artistIds = artists.map(artist => artist.id);

        return xhrLoadServicesWithoutAttributes(salonId, artistIds).then((answer) => {
            AppDispatcher.dispatch({
                type: LOAD_SELECT_SERVICES_SUCCESS,
                payload: {
                    salonId,
                    services: answer.services,
                    salonCorrections: answer.salonCorrections,
                    artistsCorrections: answer.artistsCorrections
                }
            });
        });
    });

}

export const onSelectChange = function(changedObj) {
    AppDispatcher.dispatch({
        type: GALLERY_SELECT_ONCHANGE,
        payload: changedObj
    });
};

function normalizeArtists(artists) {

    return artists.map(artist => {
        let name = `${artist.firstName} ${artist.lastName}`;
        let id = Number(artist.id);

        return { id, name };
    });
}

