/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 13.29
 */


import React, {Component} from 'react';
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type {TGalleryImage} from "../../../types";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import {removeSalonBrandAction} from "../../../ac/profile/professional/professional-profile-brands-actions";
import {Glyphicon} from 'react-bootstrap';

type
Props = TGalleryImage & {};

export default class ProfessionalProfileBrandItem extends Component<Props, any> {
    constructor(props) {
        super(props);

        this.state = {
            btnDisabled: false
        };

        this.removeSalonBrand = this.removeSalonBrand.bind(this);
    }

    removeSalonBrand() {
        this.setState({btnDisabled: true});
        removeSalonBrandAction(this.props.id);
    }

    render() {

        return <GalleryItemContainer>
            <GalleryItemImage {...this.props} blockInflection={true}/>
            <GalleryItemCardInfo>

                <GalleryItemButtonsContainer>
                    <button className="btn-link fr-glyphicon" onClick={this.removeSalonBrand}
                            style={{'display': this.state.btnDisabled ? 'none' : ''}}><Glyphicon
                        glyph="trash"/></button>

                </GalleryItemButtonsContainer>

            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}
