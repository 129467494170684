/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.6.17
 * Time: 13.42
 */

import { xhr } from "../../../../service/api-client-2";
import { Request } from "../../../../service/api-client-2/request";

export function xhrAddPayCard(token) {

    return xhr()
    .suppressNotifications()
    .post("@Q138_add_subscription_payment_source")
    .data({
        token
    })
    .end()
    .then((request: Request) => {

        let { response } = request;

        return Promise.resolve(response);
    }, (request: Request) => {

        let { response } = request;

        return Promise.reject(response);
    });
}
