/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 30.09.2017
 * Time: 13:29
 */

import BaseStore from "./base-store";
import { LOAD_CLIENT_CARDS_INFO, PROFILE_PERSONAL_MYCARDS_LOADED, USER_LOGOUT } from "../constants";

export default class ClientCardsStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
                const { type, payload } = action;

                switch (type) {

                    case LOAD_CLIENT_CARDS_INFO:
                    case PROFILE_PERSONAL_MYCARDS_LOADED:

                        this.store.cards = payload.sources ? payload.sources.data : [];
                        this.setReady(true);

                        break;

                    case USER_LOGOUT:
                        this.resetStore();
                        this.setReady(false);

                        break;

                    default:
                        return true;
                }

                this.emitChange();
            }
        );
    }

    getInitialStore() {
        return {
            card: [],
            defaultPaymentCard: []
        };
    }
}
