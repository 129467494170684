/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 9.8.17
 */

import AppDispatcher from "../dispatcher";
import { GENERIC_VALIDATION_FAIL } from "./../constants";

export function validationFail(storeDispatchToken, violations) {

    AppDispatcher.dispatch({
        type: GENERIC_VALIDATION_FAIL,
        payload: {
            storeDispatchToken,
            violations
        }
    });
}
