/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 29.11.2020
 * Time: 16:32
 */

import {
 CHANGE_SALON_APPOINTMENT_FILTER,

} from "../constants";



import BaseStore from "./base-store";

class SalonAptFilterStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            filter: ""
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;
            switch (type) {
                case CHANGE_SALON_APPOINTMENT_FILTER:
                    this.store.filter = payload;
                    this.emitChange();
                    break;
                default:
                    return true;
            }
        });
    }
}

export default SalonAptFilterStore;
