/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 30.10.2018
 * Time: 10:05
 */

import BaseStore from "../../base-store";

import { BRAND_HINT_LIST_LOAD, BRAND_HINT_SELECT, BRAND_INPUT_CHANGE, BRAND_LIST_LOAD } from "./../../../constants";

export default class BrandSelectStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            searchValue: "",
            brand: {},
            hintList: [],
            brandList: [],
            listLoaded: false,
            menuActive: false
        };

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case BRAND_LIST_LOAD: {
                    this.store.hintList = payload;
                    this.store.brandList = payload;
                    this.store.searchValue = "";
                    this.store.listLoaded = true;
                    this.store.menuActive = false;

                    break;
                }

                case BRAND_INPUT_CHANGE: {
                    if (payload === "") {
                        this.store.hintList = [];
                        this.store.searchValue = "";
                        this.store.menuActive = false;
                    }
                    else {
                        this.store.searchValue = payload;
                        this.store.menuActive = true;
                    }
                    this.store.brand = {};
                    break;
                }

                case BRAND_HINT_SELECT: {
                    this.store.searchValue = payload.title;
                    this.store.brand = payload;
                    this.store.menuActive = false;

                    break;
                }

                case BRAND_HINT_LIST_LOAD: {
                    this.store.hintList = this.getNormalizedHintList(payload);
                    this.store.listLoaded = true;
                    this.store.menuActive = this.store.searchValue !== "";
                    break;
                }

                default:
                    return true;
            }

            this.emitChange();
        });

    }

    getHintList() {
        return this.store.hintList;
    }


    getSearchValue() {
        return this.store.searchValue;
    }

    getBrandId() {
        return this.store.brand.id;
    }

    getMenuActive() {
        return this.store.menuActive;
    }

    getNormalizedHintList(val) {
        return this.store.brandList.filter(i => {
            let title = i.title.toUpperCase();
            title = val.indexOf("'") === -1 ? title.replace("'", "") : title;
            title = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            return title.indexOf(val.toUpperCase()) > -1;
        });
    }
}