/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 27.7.17
 */

import AppDispatcher from "../dispatcher";


import { MODAL_SEQUENCE } from "../constants";


class Sequence {

    constructor(options = {}) {
        this.sequence = [];
    }

    run() {
        this.assertValid();

        AppDispatcher.dispatch({
            type: MODAL_SEQUENCE,
            payload: {
                sequence: this.sequence
            }
        });

        return this;
    }

    add(modalType) {
        this.sequence.push({
            modalType
        });

        return this;
    }

    withOptions(options) {
        this.sequence[this.getLastElementIndex()].options = options;

        return this;
    }

    getLastElementIndex() {
        this.assertValid();

        return this.sequence.length - 1;
    }

    isValid() {
        return this.sequence && this.sequence.length > 0;
    }

    assertValid() {
        if (!this.isValid()) throw new Error("No elements in sequence");
    }

}

/**
 *
 * @param options
 * @returns {Sequence}
 */
export function buildSequence(options = {}) {
    return new Sequence(options);
}