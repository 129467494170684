/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 13.29
 */


import React, {Component} from 'react';
import GalleryImageTitle from "../../gallery/elements/gallery-image-title";
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type {TGalleryImage} from "../../../types";
import {FavoriteMark} from "../../favorites/favorite-mark";
import {
    BOOK_APPOINTMENT_NEW,
    BUTTON_DEFAULT, DROPDOWN_PLACEHOLDER_VALUE,
    GALLERY_ITEM_TYPE_LOOK, IMAGE_SIZE_MEDIUM, MODE_CLIENT
} from "../../../frizo-shared-constants";
import GalleryItemButtonsContainer from "../gallery-parts/gallery-item-buttons-container";
import GalleryItemButton from "../gallery-parts/gallery-item-button";
import {startBooking} from "../../../ac/book-apt/common-actions";
import moment from "moment/moment";
import {imageUrl} from "../../../helper/image-url";
import ArtistCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";

type Props = TGalleryImage & {
    storeParams: {}
};

export default class SalonPromoGalleryItemLook extends Component<Props, any> {

    bookAction(item) {
        return startBooking(
            MODE_CLIENT,
            item.artist.id,
            this.props.storeParams.salonId,
            moment(new Date()).format('YYYY-MM-DD'),
            DROPDOWN_PLACEHOLDER_VALUE,
            item.service.id,
            item.image.id,
            imageUrl(item.image.url),
            0,
            null,
            BOOK_APPOINTMENT_NEW
        );
    }


    render() {

        return <GalleryItemContainer>
            {this.props.favoritesEnabled && <FavoriteMark id={this.props.id} type={GALLERY_ITEM_TYPE_LOOK} /> }
            {this.props.imageBefore &&  <div className="fr-double-photo" onClick={this.props.onClick}></div>}
            {this.props.artist.airtouchCertified && <ArtistCertificateBadge {...this.props.artist}/>}
            <GalleryItemImage {...this.props} size={IMAGE_SIZE_MEDIUM} />
            <GalleryItemCardInfo>
                <GalleryImageTitle
                    serviceName = {this.props.service.title}
                    firstName = {this.props.artist.firstName}
                    lastName = {this.props.artist.lastName}
                />

                <GalleryItemButtonsContainer>
                    <GalleryItemButton title="Book" type={BUTTON_DEFAULT} onClick={this.bookAction.bind(this, this.props)} />

                </GalleryItemButtonsContainer>

            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}

SalonPromoGalleryItemLook.defaultProps = {
    favoritesEnabled: false
};
