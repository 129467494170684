/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.3.17
 * Time: 16.31
 */

import React, {Component} from 'react';
import PersonalInfo from './personal-info';

export default class Me extends Component {
    render()    {
        return (
            <div className="row">
                <div className="col-md-12">
                    <PersonalInfo/>
                </div>
            </div>
        );
    }
}