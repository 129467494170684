/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 2.7.17
 * Time: 12.53
 */

import { JOURNAL_ELEMENT_BLOCK } from "./../frizo-shared-constants";

export function blockNormalize(rawBlock) {

    return {
        id: rawBlock.id,
        type: JOURNAL_ELEMENT_BLOCK,
        startTimestamp: rawBlock.startTimestamp,
        endTimestamp: rawBlock.endTimestamp,
        artistId: Number(rawBlock.userId),
        title: "block" + rawBlock.id
    };
}
