/**
 * Created by ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 13.3.17
 * Time: 11:23
 */

import {
    BRAND_LIST_LOAD,
    GALLERY_LOAD,
    GALLERY_RESET,
    LOAD_SESSION_SUCCESS,
    PROFILE_BRAND_ADDED,
    PROFILE_BRAND_REMOVED
} from "../../../constants";
import AbstractGalleryStore from "../../gallery2/abstract-gallery-store";
import { GALLERY2_PROFESSIONAL_PROFILE_BRAND_ID } from "../../../frizo-shared-constants";

export default class ProfessionalProfileBrandStore extends AbstractGalleryStore {

    constructor(...args) {

        super(GALLERY2_PROFESSIONAL_PROFILE_BRAND_ID, ...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {
                case BRAND_LIST_LOAD:
                    this.store.originBrandList = payload;
                    this.updateUnselectedBrands();

                    break;

                case GALLERY_LOAD:
                    payload.items.forEach(brand => {
                        this.store.gallery.selectedBrands.push(brand.id);
                    });

                    this.updateUnselectedBrands();

                    return true; //proceed to parent

                case GALLERY_RESET:
                    this.resetGallery({
                        salonId: this.store.gallery.params.salonId
                    });

                    break;

                case LOAD_SESSION_SUCCESS:
                    this.waitFor(this.stores.session.getDispatchToken());
                    let session = this.stores.session.getStore().session;

                    if (session.isLogged && session.salon) {
                        this.store.gallery.params.salonId = session.salon.id;
                    }

                    break;

                case PROFILE_BRAND_ADDED:
                    this.reloadGallery();
                    this.updateUnselectedBrands();

                    break;

                case PROFILE_BRAND_REMOVED:
                    this.reloadGallery();
                    this.updateUnselectedBrands();

                    break;

                default:
                    return true;
            }

            this.emitChange();

        });

    }

    getInitialStore() {
        return {
            gallery: this.getInitialGallery(),
            originBrandList: [],
            unselectedBrands: []
        };
    }

    getInitialGallery() {
        return Object.assign(super.getInitialGallery(), {
            totalIsKnown: false,
            params: {
                salonId: null
            },
            selectedBrands: []
        });
    }

    resetGallery(withParams) {
        let saveParams = Object.assign({}, this.store.gallery.params, withParams);

        this.store.gallery = this.getInitialGallery();

        this.store.gallery.params = saveParams;
    }


    updateUnselectedBrands() {
        this.store.unselectedBrands = this.store.originBrandList.filter(brand => {
            return this.store.gallery.selectedBrands.indexOf(brand.id) === -1;
        });
    }

    calculateShowMoreButton() {
        this.store.gallery.showMoreButton = false;

        return false;
    }
}
