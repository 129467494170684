/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 4.9.17
 */

import config from "../config";
import { streamInputMessage } from "../ac/client-stream-actions";
import SocketIOClient from "./socket-io-client";

function streamOn() {
    console.log("streamOn", arguments);
}

let client;

if (config.websocket_frontend_url) {
    client = new SocketIOClient({
            url: config.websocket_frontend_url
        }, {
            "client-stream": streamInputMessage,
            "message": streamOn
        }
    );
}

export default client;
