/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 23.10.2017
 * Time: 12:31
 */
import React from "react";

export default function Loader() {
    return (
        <div className="loader-wrapper">
            <div className="loader">
                <span className="loader-span"></span>
            </div>
        </div>
    );
}
