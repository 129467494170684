/**
 * Created by PhpStorm.
 * User: George Novik
 * Company: U6 SIA
 * Date: 07.09.2018
 * Time: 9.11
 */

import config from "./../config";

console.log('Import facebook', config);
window.fbAsyncInit = function() {

    /* global FB */

    console.log('Init facebook', config.facebook_app_id);

    FB.init({
        appId: config.facebook_app_id,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v4.0"
    });

    FB.AppEvents.logPageView();
};

window.addEventListener("load", function() {
    window.fbAsyncInit();
});
