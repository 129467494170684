import React, {Component} from 'react';
import RawServicesList from '../services/raw-services-list';
import SalonServicesList from '../services/salon-services-list';
import {profileServiceStore} from '../../../../stores/index';
import {
    addSalonServicesAction,
    removeSalonServiceAction,
    modifySalonServiceAction
} from '../../../../ac/profile-services/common-actions';

export default class ProfessionalProfileServices extends Component {

    constructor(...args) {
        super(...args);

        this.state = Object.assign({
            services: [],
            servicesIndex: {},
            selectedServices: [],
            unselectedServices: [],
            duration: [],
            processing: [],
            salonInfo: {},
            btnDisabled: false
        }, this.getStoreData());


        this.storeChange = this.storeChange.bind(this);
        this.onServiceAdd = this.onServiceAdd.bind(this);
        this.onServiceRemove = this.onServiceRemove.bind(this);
        this.onServiceModify = this.onServiceModify.bind(this);
    }

    componentDidMount() {
        profileServiceStore.addChangeListener(this.storeChange);

        /*
        Data preloading made by parent component ProfessionalProfileServiceTab

        sessionStore.getReadyPromise().then(store => {
            loadProfileServicesInitial(store.session.salon.id);
        });
        */
    }

    componentWillUnmount() {
        profileServiceStore.removeChangeListener(this.storeChange);
    }

    onServiceModify(service) {
        const duration = this.state.duration.find(el => el.value === service.durationValue);
        const processing = this.state.processing.find(el => el.value === service.processingValue);

        modifySalonServiceAction(service, duration.value, processing.value)
    }

    onServiceRemove(service) {

        return () => {
            this.setState({btnDisabled: true});
            removeSalonServiceAction(service);
        }
    }

    onServiceAdd(service) {
        const duration = this.state.duration.find(el => el.value === service.durationValue);
        const processing = this.state.processing.find(el => el.value === service.processingValue);

        addSalonServicesAction(service, duration.value, processing.value);
    }

    getStoreData()  {
        return {
            services: profileServiceStore.getServices(),
            servicesIndex: profileServiceStore.getServicesIndex(),

            selectedServices: profileServiceStore.getSelectedServices(),
            unselectedServices: profileServiceStore.getUnselectedServices(),

            duration: profileServiceStore.getDuration(),
            processing: profileServiceStore.getProcessing(),
            salonInfo: profileServiceStore.getSalonInfo(),
            btnDisabled: profileServiceStore.getBtnDisabled()
        };
    }

    storeChange() {
        this.setState(this.getStoreData());

    }


    render() {
        let rawServices = null;
        let salonServices = null;

        if (this.state.unselectedServices.length) {
            rawServices = (
                <RawServicesList {...this.state} onChange={this.onServiceChange} onServiceAdd={this.onServiceAdd}/>
            );
        }

        if (this.state.selectedServices.length) {
            salonServices = <SalonServicesList {...this.state} onServiceModify={this.onServiceModify}
                                               onServiceRemove={this.onServiceRemove}/>
        }

        return <div className="fr-mt-ng">
            {rawServices}
            {salonServices}
        </div>

    }
}

ProfessionalProfileServices.deafultProps = {
    salonId: 0
};

ProfessionalProfileServices.requireData = ['userSalon'];