/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 11.35
 */

import {
    DASHBOARD_GALLERY_ADD_LOOK,
    DASHBOARD_GALLERY_SPREAD_STRUCTURE,
    GALLERY_FULLSCREEN_OPEN,
    GALLERY_LOAD
} from "../../constants";
import config from "../../config";
import {
    GALLERY2_DASHBOARD_GALLERY_ID,
    GALLERY_ITEM_TYPE_SEPARATOR,
    TYPE_SALON,
    TYPE_USER
} from "../../frizo-shared-constants";
import arrayToHash from "../../helper/array-to-hash";
import AbstractGalleryStore from "./abstract-gallery-store";

function ensureMap(key) {
    if (!this.has(key)) {
        this.set(key, new Map());
    }

    return this.get(key);
}

function ensureArray(key) {
    if (!this.has(key)) {
        this.set(key, []);
    }

    return this.get(key);
}

export default class DashboardGalleryStore extends AbstractGalleryStore {

    constructor(...args) {
        super(GALLERY2_DASHBOARD_GALLERY_ID, ...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            if (!payload || payload.galleryId !== this.galleryId) return true;

            switch (type) {

                case DASHBOARD_GALLERY_ADD_LOOK:

                    let sourceStructure = this.store.gallery.sourceStructure;
                    for (let i = 0; i < payload.envelop.structure.length - 1; i++) {
                        let entity = payload.envelop.structure[i];
                        let entityKey = entity.entityType + entity.id;
                        sourceStructure = ensureMap.call(sourceStructure, entityKey);

                        this.store.gallery.entities.set(entityKey, entity);
                    }

                    let entity = payload.envelop.structure[payload.envelop.structure.length - 1];
                    let entityKey = entity.entityType + entity.id;

                    ensureArray
                    .call(sourceStructure, entityKey)
                    .push(payload.envelop.element);

                    this.store.gallery.entities.set(entityKey, entity);

                    break;

                case DASHBOARD_GALLERY_SPREAD_STRUCTURE:
                    this.store.gallery.items = [];
                    this.store.gallery.imageItems = [];
                    this.store.gallery.itemsCount = 0;
                    this.store.gallery.itemsToImageItemsIndex = [];

                    this.spread(this.store.gallery.sourceStructure);

                    this.store.gallery.totalCount = payload.totalCount;

                    this.calculateShowMoreButton();

                    this.store.gallery.loading = false;

                    this.checkIsEndReached();

                    break;

                case GALLERY_LOAD:
                    this.store.gallery.imageItems.push(...payload.items);

                    return true; //proceed with abstract store

                case GALLERY_FULLSCREEN_OPEN:
                    if (this.store.gallery.itemsToImageItemsIndex.length > 0) {
                        this.store.gallery.fullScreen.currentIndex = this.store.gallery.itemsToImageItemsIndex.indexOf(
                            payload.startIndex);
                    }
                    else {
                        return true; //proceed with abstract store
                    }

                    this.updateFullscreenView(this.store);
                    this.calculateFullScreenNextPrevButtons(this.store);

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });

    }

    getInitialGallery() {
        return Object.assign(super.getInitialGallery(), {
            showMoreButton: false,
            params: Object.assign({
                count: 12,
                offset: 0,
                categoryID: "",
                serviceList: "",
                cityZip: "",
                latitude: "",
                longitude: "",
                salonArtist: "",
                date: "",
                from: 0,
                to: 86400
            }, config.paramsMainScreen),

            sourceStructure: new Map(),
            entities: new Map(),

            imageItems: [],
            itemsToImageItemsIndex: [],

            fullScreen: {
                currentIndex: 0,
                currentItem: null,
                showNext: false,
                showPrev: false,
                showImageAfter: true
            }
        });

    }

    /**
     * Recursive spread of sourceStructure
     * @param structure
     * @param collection
     */
    spread(structure, collection = []) {
        if (Array.isArray(structure)) {
            let entities = arrayToHash(collection, entity => {
                let typesMap = {
                    [TYPE_SALON]: "salon",
                    [TYPE_USER]: "artist"
                };

                return [typesMap[entity.entityType], entity];
            });

            let separator = Object.assign({
                galleryItemType: GALLERY_ITEM_TYPE_SEPARATOR
            }, entities);

            this.store.gallery.items.push(separator);

            structure.forEach(look => {
                let element = Object.assign(look, entities);
                this.store.gallery.items.push(element);
                this.store.gallery.imageItems.push(element);
                this.store.gallery.itemsCount++;
                this.store.gallery.itemsToImageItemsIndex.push(this.store.gallery.items.length - 1);


            });
        }
        else {

            for (let [entityKey, nestedStructure] of structure) {
                collection.push(this.store.gallery.entities.get(entityKey));

                this.spread(nestedStructure, collection);
            }
        }
    }

    updateFullscreenView() {

        let index = this.store.gallery.fullScreen.currentIndex;

        if (this.store.gallery.imageItems[index] !== undefined) {
            this.store.gallery.fullScreen.currentItem = this.store.gallery.imageItems[index];
            this.store.gallery.fullScreen.showImageAfter = true;
        }
        else {
            throw new Error("Cannot update current view");
        }
    }
}
