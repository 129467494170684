/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 13.12.17
 * Time: 13.01
 *
 * @flow
 */

import React, {Component} from 'react';
import {BUTTON_DANGER, BUTTON_DEFAULT, BUTTON_DANGER_TRASH, BUTTON_LINK_PROMO} from "../../../frizo-shared-constants";
import {Link} from 'react-router';


type TButtonsType = BUTTON_DEFAULT | BUTTON_DANGER;

type TProps = {
    title: string,
    type: TButtonsType,
    onClick: Function,
    href: string
};


type TState = {
    btnDisabled: boolean
}

export default class GalleryItemButton extends Component<TProps, TState> {
    onClickHandler: Function;
    resetButton: Function;
    static defaultProps: {};

    constructor(props: TProps) {
        super(props);
        this.state = {
            btnDisabled: false
        };

        this.onClickHandler = this.onClickHandler.bind(this);
        this.resetButton = this.resetButton.bind(this);
    }

    onClickHandler() {
        if (!this.state.btnDisabled) {
            let ret = this.props.onClick();

            if (ret instanceof Promise) {
                ret.then(this.resetButton, this.resetButton);
            }
        }
        this.setState({
            btnDisabled: !this.state.btnDisabled
        });
    }

    resetButton()   {
        this.setState({
            btnDisabled: false
        });
    }

    render() {
        let classes = ['btn', 'fr-btn', 'fr-card-btn__item'];

        switch (this.props.type) {
            default:
            case BUTTON_DEFAULT:
                break;

            case BUTTON_DANGER:
                classes.push('fr-btn-danger');
                break;

            case BUTTON_DANGER_TRASH:
                classes.push('fr-btn-danger glyphicon glyphicon-trash fr-glyphicon__gallery');
                break;

            case BUTTON_LINK_PROMO:
                classes.push('fr-card-btn__promo');
                break;

        }

        if (this.props.href) {
            return <Link className={classes.join(' ')} to={this.props.href}>{this.props.title}</Link>;
        }
        else {
            return <button ref="btn" className={classes.join(' ')} onClick={this.onClickHandler}
                      disabled={this.state.btnDisabled}>{this.props.title}</button>
        }
    }
}


GalleryItemButton.defaultProps = {
    type: BUTTON_DEFAULT,
    title: '',
    onClick: () => {
    },
    href: null
};