/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 29.05.2017
 * Time: 13:22
 */

import ApiClient from "../service/api-client";
import { CTYPE_FORM_URLENCODED } from "../frizo-shared-constants";
import { xhr } from "../service/api-client-2";
import { Request } from "../service/api-client-2/request";

export const xhrLoadSalonClients = function(params) {
    if (params.combinedSearch === "") {
        delete params.combinedSearch;
    }
    let newApiClientURL = ApiClient.getRouting().generate("Q36_2_salon_clients_paginated", params);
    return ApiClient.get(newApiClientURL, params)
    .then((answer) => {
        return Promise.resolve({
            response: answer[0],
            loaded: true
        });
    }, answer => {
        let [response] = answer;
        let errors = response.errors;
        return Promise.resolve({
            response: response,
            loaded: false,
            errors: errors
        });
    });
};

export const xhrRemoveClient = (clientID) => {
    let url = ApiClient.getRouting().generate("Q47_remove_salon_client", { clientID });
    return ApiClient.post(url, { clientID }, {
        enableDefaultNotifications: false
    })
    .then(answer => {
        return Promise.resolve({
            response: answer[0],
            removed: true,
            clientID: clientID
        });

    }, answer => {
        let [response] = answer;

        return Promise.reject(response);
    });
};

export const xhrAddSalonClient = function(clientData) {

    let url = ApiClient.getRouting().generate("Q37_2_add_salon_client", { clientData });
    return ApiClient.post(url, clientData)
    .then((answer) => {
        return Promise.resolve({
            client: answer[0],
            added: true
        });
    });
};

export const xhrEditSalonClient = function(salonClientId, clientData) {

    let url = ApiClient.getRouting().generate("Q37_3_edit_salon_client", { salonClientId });

    return ApiClient.put(url, {
            salonClientId: salonClientId,
            firstName: clientData.firstName,
            lastName: clientData.lastName,
            phone: clientData.phone,
            comment: clientData.comment
        },
        {
            ctype: CTYPE_FORM_URLENCODED
        })
    .then((answer) => {
        return Promise.resolve({
            client: answer[0],
            edited: true
        });
    });
};

export const xhrSearchSalonClientByPhone = function(phone) {

    let params = { clientPhone: phone };
    return ApiClient.get(["Q73_find_salon_client"], params)
    .then((answer) => {
        return Promise.resolve({
            response: answer[0].response
        });

    }, answer => {
        let [response] = answer;
        let errors = response.errors;
        return Promise.resolve({
            response: response,
            errors: errors
        });
    });
};

export const xhrSearchUserClientByPhone = function(phone) {

    let params = { clientPhone: phone };
    return ApiClient.get(["Q36_2_salon_clients_paginated"], params)
    .then((answer) => {
        return Promise.resolve({
            response: answer[0]
        });

    }, answer => {
        let [response] = answer;
        let errors = response.errors;
        return Promise.resolve({
            response: response,
            errors: errors
        });
    });
};

export const xhrAddSalonClientVisibility = function(clientId) {

    let url = ApiClient.getRouting().generate("Q144_add_client_visibility", { salonClientId: clientId });
    return ApiClient.post(url, { salonClientId: clientId })
    .then((answer) => {
        return Promise.resolve({
            response: answer
        });
    });
};

export const xhrRemoveSalonClientVisibility = function(clientID = 0) {

    let url = ApiClient.getRouting().generate("Q145_remove_client_visibility", { salonClientId: clientID });
    return ApiClient.del(url, { salonClientId: clientID })
    .then((answer) => {
        return Promise.resolve({
            response: answer[0],
            clientID: clientID,
            removed: true
        });
    });
};

export const xhrSalonClientsAutoComplete = search => {
    return xhr()
    .get("@Q2-55-Auto-Complete-Salon-Client", {
        "search": search
    })
    .end()
    .then((request: Request) => {

        let response = request.response;

        return response;
    });
};
