/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 9.11.17
 * Time: 11.54
 *
 * @flow
 */

import type { TGender } from "../types";
import type { TRawGender } from "./raw-types";
import { TYPE_GENDER } from "../frizo-shared-constants";

export function normalizeGender(rawGender: TRawGender): TGender {
    return {
        entityType: TYPE_GENDER,
        id: rawGender.id,
        title: rawGender.i18nKey,
        code: rawGender.code
    };
}
