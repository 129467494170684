/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.27.3
 * Time: 16:01
 */

import React, {Component} from 'react';
import {Button, FormControl, Form} from 'react-bootstrap';
import {createIntervalsList, getIdbyValue} from './helpers';
import Validator from '../../../../validation/validator';
import Violation from '../../../helper-components/violation';
import DropdownWrapper from '../../../../elements/dropdown-wrapper';
import {selectedServiceStore} from "../../../../stores";

export default class AddServiceForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: '',
            durationValue: this.props.service.durationValue,
            processingValue: this.props.service.processingValue,
            violations: {}
        };


        this.priceChange = this.priceChange.bind(this);
        this.durationChange = this.durationChange.bind(this);
        this.processingChange = this.processingChange.bind(this);

        this.storeUpdate = this.storeUpdate.bind(this);
    }


    componentDidMount() {
        selectedServiceStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        selectedServiceStore.removeChangeListener(this.storeUpdate);
    }


    storeUpdate() {
        this.setState({
            violations: selectedServiceStore.getStore().violations
        });
    }



    addButtonClick() {
        let service = Object.assign({}, this.props.service, this.state);
        const validator = new Validator();

        validator.addConstraint('price', 'is-required');
        validator.addConstraint('price', 'is-number');
        validator.addConstraint('price', 'length', {max: 4, min: 1});

        if (!service.allowZeroPrice) {
            validator.addConstraint('price', 'is-greater-than', 0);
        }

        const violations = validator.validate({price: service.price});

        this.setState({
            violations: violations === true ? {} : violations
        });

        if (violations === true) {
            this.props.addClickHandler(service);
        }
    }

    durationChange(id) {
            let durationObj = this.props.duration.find(item => item.id === Number(id));
            let processingValue = this.state.processingValue;
            if (durationObj.value < processingValue) {
                processingValue = durationObj.value;
            }
            this.setState({
                durationValue: durationObj.value,
                processingValue : processingValue
            });
    }

    processingChange(id) {

            let processingObj = this.props.processing.find(item => item.id === Number(id));

            this.setState({
                processingValue : processingObj.value
            });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            price: '',
            durationValue: nextProps.service.durationValue,
            processingValue: nextProps.service.processingValue,
        });
    }


    priceChange(ev) {
        let price = '';
        if (ev.target.value.indexOf('.') > -1 || ev.target.value.indexOf(',') > -1 || ev.target.value.length > 4) {
            return false;
        }
        if (!isNaN(parseInt(ev.target.value, 10))) {
            price = ev.target.value.replace(/\D+/g, '');
        }
        this.setState({price});
    }

    render() {

        return <Form>
            <div className="control-group form-inline fr-add-serv-form">
                <label className="fr-add-serv-label">Enter price</label>
                <FormControl className="fr-add-serv-item" componentClass="input" onChange={this.priceChange}
                             value={this.state.price}
                             placeholder="Price, $"/>
                <Violation violationSet={this.state.violations.price} />
            </div>
            <div className="control-group form-inline fr-add-serv-form">
                <label className="fr-add-serv-label">Select duration</label>
                <DropdownWrapper
                    showPlaceholder = {false}
                    list={createIntervalsList(this.props.duration)}
                    onChange={this.durationChange}
                    selected={getIdbyValue(this.props.duration, this.state.durationValue)}
                    className={"dropdown-service-form"}
                />
            </div>
            <div className="control-group form-inline fr-add-serv-form">
                <label className="fr-add-serv-label">Select processing</label>
                <DropdownWrapper
                    showPlaceholder = {false}
                    list={createIntervalsList(this.props.processing, this.state.durationValue)}
                    onChange={this.processingChange}
                    selected={getIdbyValue(this.props.processing, this.state.processingValue)}
                    className = {"dropdown-service-form"}
                />

            </div>
            <Button className="fr-btn-add fr-btn btn fr-fr" onClick={this.addButtonClick.bind(this)}>Add service</Button>
        </Form>
    }
}

AddServiceForm.defaultProps = {
    durations: [],
    processing: [],
    durationId: 0,
    processingId: 0,
    addClickHandler: () => {
        console.log("Add button click");
    },

};