/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 11.10.2017
 * Time: 11:24
 */
import React, { Component } from "react";
import { sessionStore, paymentProfileStore } from "../../../stores";
import { Row, Col, Grid, Pagination } from "react-bootstrap";


import {
    loadSalonTransactions,
    loadStripeAccount,
    loadPaymentDataCompletionReport
} from "../../../ac/payments/payments-professional";
//import {loadStripeSubscriptionCustomer} from '../../../ac/payments/payments-professional-pay';

import PaymentProfileWidget from "./cards-and-bank/payment-profile-widget";
import GetPaidWidget from "./cards-and-bank/get-paid-widget";
import SalonTransactionsWidget from "./cards-and-bank/salon-transactions-widget";

import Violation from "../../helper-components/violation";
import { PAGINATION_ITEMS_COUNT } from "../../../frizo-shared-constants";
import LoaderBar from "../../../elements/loader-bar";

export default class ProfessionalProfilePayments extends Component {
    constructor(...args) {
        super(...args);

        this.state = Object.assign({
            ready: false,
            artistId: null,

            verified: false,
            status: null,
            profileExist: false,

            subscriptionCustomer: {
                sources: []
            },
            transactions: []

        }, this.getDataFromStore());
        this.state.currentPageNumber = 1;

        this.changePaymentProfile = this.changePaymentProfile.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        sessionStore.getReadyPromise().then(store => {
            this.setState({
                artistId: sessionStore.getUserId()
            });
        });

        paymentProfileStore.addChangeListener(this.changePaymentProfile);
        loadPaymentDataCompletionReport("pro-account");
        loadStripeAccount();

        let params = {
            mode: "salon",
            limit: PAGINATION_ITEMS_COUNT,
            offset: 0
        };
        loadSalonTransactions(params);
    }

    componentWillUnmount() {
        paymentProfileStore.removeChangeListener(this.changePaymentProfile);
    }

    changePaymentProfile() {
        this.setState(Object.assign({}, this.getDataFromStore(), {
            ready: true
        }));
    }

    getDataFromStore() {
        return paymentProfileStore.getStore();
    }

    handleSelect(eventKey) {

        let params = {
            mode: "salon",
            limit: PAGINATION_ITEMS_COUNT,
            offset: (eventKey - 1) * PAGINATION_ITEMS_COUNT
        };

        this.setState({
            currentPageNumber: eventKey
        });
        loadSalonTransactions(params);
    }

    render() {
        if (!this.state.ready) return <LoaderBar className="loader-msg-wrapper--full-height"/>;
        let totalPages = Math.ceil(this.state.transactions.count / PAGINATION_ITEMS_COUNT);
        let maxVisibleButtons = (window.innerWidth < 769 ? 3 : 7);

        return <div className="bank-card-profile">

            <PaymentProfileWidget
                fieldsNeeded={this.state.fieldsNeeded}
                verified={this.state.verified}
                profileExist={this.state.profileExist}
                status={this.state.status}
            />


            {this.state.profileExist && <Grid className="bank-card-profile__container">
                <Row>
                    <Col md={6} className="fr-mt fr-mb-xs">
                        <GetPaidWidget
                            externalAccounts={this.state.externalAccounts}
                        />
                    </Col>
                    <Col md={6} className="fr-mt fr-mb-xs">
                        <SalonTransactionsWidget
                            sources={this.state.transactions.sources}
                        />
                        {totalPages > 1 && <Pagination
                            className="fr-pagination-standard fr-ml"
                            bsSize="medium"
                            items={totalPages}
                            activePage={this.state.currentPageNumber}
                            onSelect={this.handleSelect}
                            maxButtons={maxVisibleButtons} first={totalPages > 1 ? "<<" : null}
                            last={totalPages > 1 ? ">>" : null} next={totalPages > 1 ? ">" : null}
                            prev={totalPages > 1 ? "<" : null}
                            ellipsis={totalPages > 1 ? "..." : null}
                        />}
                    </Col>
                </Row>
                <Row>
                    <Col md={2} mdOffset={5}>
                        <Violation violationSet={this.state.violations.general} visible={true}/>
                    </Col>
                </Row>
            </Grid>}
        </div>;
    }
}

ProfessionalProfilePayments.requireData = ["userSalon", "salonInfo"];