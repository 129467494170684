/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.3.17
 * Time: 16.31
 */

/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.3.17
 * Time: 16.31
 */

import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import RoutedGroup from './../../forms/routed-group';

export default class Appointments extends Component {

    render() {
        return <div>
            <div className="fr-apt-left-container fr-pb-xs">
                <RoutedGroup className="fr-btn-routed">
                    <Button value="/me/my-appointments/pending">Unpaid</Button>
                    <Button value="/me/my-appointments/upcoming">Upcoming</Button>
                    <Button value="/me/my-appointments/history">History</Button>
                </RoutedGroup>
            </div>
            <div>
                {this.props.children}
            </div>
        </div>
    }
}