/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.8.6
 * Time: 14:55
 */

import BaseValidator from "./base-validator";
import Image, { IMAGE_REQUIRED } from "./image";
import violationBuilder from "./../violation-builder";
import sprintf from "./../../helper/sprintf";

export default class ImageValidator extends BaseValidator {

    static getValidConstraints() {
        return [Image];
    }

    validate(constraint, field, root = null) {
        if (!(constraint instanceof Image)) {
            throw new Error(sprintf("Image should be passed to ImageValidator.validate(). %s given.",
                constraint.constructor.name));
        }

        let value = root ? root[field] : field;

        if (Number(value) <= 0) {
            return violationBuilder.build(constraint.message, {}, field, root, IMAGE_REQUIRED, value);
        }

        return true;
    }
}
