/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 12.07
 */

import { xhrDashboardGallery } from "../../xhr/dashboard-gallery-xhr";
import { DASHBOARD_GALLERY_SPREAD_STRUCTURE } from "./../../constants";
import AppDispatcher from "../../dispatcher";
import { looksGroupedBySalonByArtist } from "../../normal-form/gallery/dashboard/looks-grouped-by-artist-by-salon";
import { DASHBOARD_GALLERY_ADD_LOOK } from "../../constants";
import { artistsGroupedBySalon } from "../../normal-form/gallery/dashboard/artists-grouped-by-salon";
import { plainLooks } from "../../normal-form/gallery/dashboard/plain-looks-normalizer";

const TYPE1 = 1; //plain looks
const TYPE2 = 2; //grouped by artist by salon
const TYPE3 = 3; //grouped by artist

export function loadDashboardGallery(params, galleryId) {

    return xhrDashboardGallery(params).then(response => {

        if (!response) {
            console.warn("Invalid response for dashboard gallery");

            return null;
        }

        let normalizer;

        switch (response.type) {

            case TYPE1:
                //return directly
                return Promise.resolve({
                    items: plainLooks(response.items),
                    totalCount: response.count
                });

            case TYPE2:
                normalizer = looksGroupedBySalonByArtist;
                break;

            case TYPE3:
                normalizer = artistsGroupedBySalon;
                break;

            default:
                return null;
        }

        if (!normalizer) throw new Error("Wrong response type " + response.type);

        for (let envelop of normalizer(response.items)) {
            AppDispatcher.dispatch({
                type: DASHBOARD_GALLERY_ADD_LOOK,
                payload: {
                    envelop,
                    galleryId
                }
            });
        }

        AppDispatcher.dispatch({
            type: DASHBOARD_GALLERY_SPREAD_STRUCTURE,
            payload: {
                totalCount: response.count,
                galleryId
            }
        });

        return null; //return nothing, data already in store

    });
}

