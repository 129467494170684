/**
 * User: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 22.3.17
 * Time: 17.13
 */

import React, {Component} from 'react';
import {NavItem} from 'react-bootstrap';
import RoutedTab from './../tabs/routed-tab';

import SalonName from './components/salon-name';
import {salonPromoStore} from './../../stores'
//import {loadPromoInitials} from "../../ac/salon-promo-action";
import aptInformer from "../../service/apt-informer";
import {sessionStore} from './../../stores/index';
import config from './../../config';
import {loadPublicSalonDataByUrl} from "../../ac/salon-promo-action";
import ScreenMessage from "../../elements/screen-message";
import {Link} from 'react-router';

export const msgContent = (
    <div className="screen-message__block-content">
        <h1><span className="glyphicon glyphicon-eye-close"></span></h1>
        <p>No longer public.<br/> Click <Link to="/">here</Link> to continue</p></div>);

export default class SalonPromo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            timerId: null,
            informerEnabled: config.apt_informer || false,
            salon: {
                id: 0,
                name: '',
                isPublic: null
            }
        };

        this.tabs = [
            {tab: 'gallery', title: 'Gallery'},
            {tab: 'location', title: 'Location'},
            {tab: 'services', title: 'Services'},
            {tab: 'artists', title: 'Artists'},
            {tab: 'brands', title: 'Brands'}];

        this.onStoreChange = this.onStoreChange.bind(this);
        this.showInformer = this.showInformer.bind(this);
        this.stopInformer = this.stopInformer.bind(this);
        this.randomTimer = this.randomTimer.bind(this);
    }

    onStoreChange() {
        let {salonId, salonName, isPublic} = salonPromoStore.getStore();

        this.setState({
                salon: {
                    id: salonId,
                    name: salonName,
                    isPublic
                }
            },
        );
    }

    componentDidMount() {
        salonPromoStore.addChangeListener(this.onStoreChange);

        if (this.state.informerEnabled) {
            this.showInformer();
        }

        loadPublicSalonDataByUrl(this.props.params.url);

    }

    componentWillUnmount() {
        salonPromoStore.removeChangeListener(this.onStoreChange);

        this.stopInformer();
    }

    componentDidUpdate() {
        if (this.state.informerEnabled) {
            if (!this.state.timerId) {
                this.showInformer();
            }
        }
    }

    createTabList() {
        return this.tabs.map((tab) => {
            return <NavItem key={tab.tab} eventKey={tab.tab}>{tab.title}</NavItem>
        });
    }

    showInformer() {

        let userIsLogged = sessionStore.isLogged();
        if (!userIsLogged) {
            if (!this.state.timerId) {
                let timerId = setTimeout(this.randomTimer, Math.floor((Math.random() * 15)) * 1000 + 6000);
                this.setState({timerId: timerId});
            }
        }
    }

    randomTimer() {
        aptInformer();
        clearTimeout(this.state.timerId);
        let timerId = setTimeout(this.randomTimer, Math.floor((Math.random() * 15)) * 1000 + 6000);
        this.setState({timerId: timerId});
    }

    stopInformer() {
        if (this.state.timerId) {
            window.clearTimeout(this.state.timerId);
        }
        this.setState({timerId: null})
    }

    render() {

        return (
            <div>

                <SalonName name={this.state.salon.name} url={this.props.params.url}/>

                {this.state.salon.isPublic === true && <div className="fr-mt">
                    <RoutedTab route={`/promo/${this.props.params.url}/:tab`}>
                        {this.createTabList()}
                    </RoutedTab>
                    <div className="container fr-tab-content">
                        {this.props.children}

                    </div>
                </div>}

                {this.state.salon.isPublic === false && <ScreenMessage message={msgContent}/>}
                {this.state.salon.isPublic === null && <div className={"loading-message"}>Loading...</div>}
            </div>
        )
    }
}
