/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 28.05.2017
 * Time: 11:18
 */

import React, {Component} from 'react';
import {Button, FormControl} from 'react-bootstrap';
import GeoCode from './../../../service/geo-code';

import {whereInputChange, isNearestCheckedChange, getWhereHintList} from '../../../ac/dashboard-filter-actions';
import {dashboardWhereStore} from '../../../stores/';
import DashboardWhereMenu from "./dashboard-where-menu";

export default class DashboardWhere extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({
            location: {},
            data: null,
            firstTime: true,
            searchValue: '',
            isNearestChecked: false,
            selected: -1
        }, this.getStoreData());

        this.onStoreChange = this.onStoreChange.bind(this);
        this.getStoreData = this.getStoreData.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onArrowDown = this.onArrowDown.bind(this);
        this.onArrowUp = this.onArrowUp.bind(this);
        this.submitValue = this.submitValue.bind(this);
        this.onReset = this.onReset.bind(this);
        this.getDeviceLocation = this.getDeviceLocation.bind(this);
        this.handleNearest = this.handleNearest.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
    }

    componentDidMount() {

        dashboardWhereStore.addChangeListener(this.onStoreChange);
    }

    componentWillUnmount() {
        dashboardWhereStore.removeChangeListener(this.onStoreChange);
    }

    onStoreChange() {
        let object = Object.assign({}, this.getStoreData());
        this.setState(object);
    }

    getStoreData() {
        return dashboardWhereStore.getStore();
    }


    onReset() {
        whereInputChange('');
        isNearestCheckedChange(false);
        this.props.onEnter({cityZip: '', whereMenu: false});
    }

    fetchData(address) {
        return GeoCode.getLocationFromAddress(address)
    }

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            if (this.state.selected > -1) {
                let selectedAddress = this.state.searchList[this.state.selected];
                if (selectedAddress.zip !== null) {
                    this.handleSelect(selectedAddress.zip);
                }
                else {
                    this.handleSelect(selectedAddress.city);
                }
            }
            else {
                this.submitValue(this.state.searchValue);
            }
        } else if (e.key === 'ArrowDown') {
            this.onArrowDown();
        } else if (e.key === 'ArrowUp') {
            this.onArrowUp();
        }
    }


    onArrowDown() {
        if (this.state.selected < this.state.searchList.length - 1) {
            this.setState({selected: this.state.selected + 1});
        }
        else {
            this.setState({selected: this.state.selected});
        }
    }

    onArrowUp() {
        if ((this.state.selected < this.state.searchList.length) && (this.state.selected > 0)) {
            this.setState({selected: this.state.selected - 1});
        }
        else {
            this.setState({selected: this.state.selected});
        }
    }

    handleFilterUpdate() {
        this.props.onLocationChange({location: this.state.location});
        let zip = this.state.searchValue.match(/\b\d{5}\b/g);
        if (zip) {
            this.props.onEnter({cityZip: zip[0]});
        } else {
            this.props.onEnter({cityZip: this.state.searchValue});
        }
    }

    onInputChange(ev) {
        this.setState({selected: -1});
        if (this.state.isNearestChecked) {
            isNearestCheckedChange(false);
        }
        whereInputChange(ev.target.value);
        getWhereHintList(ev.target.value);
    }


    closeDropdown() {
        this.setState({
            menuActive: false
        })
    }

    submitValue(value) {
        this.fetchData(value)
            .then((newState) => {
                this.setState(newState, () => {
                    this.props.onLocationChange({location: this.state.location});
                    let zip = this.state.searchValue.match(/\b\d{5}\b/g);
                    if (zip) {
                        this.props.onEnter({cityZip: zip[0]});
                    } else {
                        if (this.state.data !== undefined) {
                            this.props.onEnter({cityZip: this.state.data.address_components[0].short_name});
                        }
                    }
                });
            });
        this.closeDropdown();
    }


    getDeviceLocation() {
        let startPos;
        let that = this;
        let geoSuccess = function (position) {
            startPos = position;
            that.setState({
                location: {lat: startPos.coords.latitude, lon: startPos.coords.longitude}
            });

            that.props.onLocationChange({
                location: {
                    lat: startPos.coords.latitude,
                    lon: startPos.coords.longitude
                }
            });
            GeoCode.getAddressFromLatLng(that.state.location.lat, that.state.location.lon).then((data) => {
                let localityAddressComponent = data.results[0].address_components.filter((component) => {
                    return component.types.indexOf('locality') !== -1
                });
                let localityName = localityAddressComponent[0].long_name;
                if (that.state.firstTime) {
                    that.setState({
                        firstTime: false
                    });
                }
                that.submitValue(localityName);

            });
        };
        GeoCode.getCurrentPosition(this.state.firstTime).then(geoSuccess, (error) => {
            isNearestCheckedChange(false);
        })
    }

    handleNearest() {
        if (!this.state.isNearestChecked) {
            this.getDeviceLocation();
            whereInputChange('');
            isNearestCheckedChange(true);
        } else {
            this.onReset();
        }
    }

    handleSelect(address) {
        let zip = address.match(/\b\d{5}\b/g);
        let selectedAddress = zip ? zip[0] : address;
        this.props.onEnter({cityZip: selectedAddress});

        whereInputChange(selectedAddress);
        this.submitValue(address);
    }

    render() {
        return (
            <div className={this.props.className + " dashboard-where form-horizontal"}>
                <FormControl
                    value={this.state.searchValue}
                    onChange={this.onInputChange}
                    className={'dashboard-where__input'}
                    placeholder={'City or ZIP'}
                    onKeyDown={this.handleKeyDown}
                    onFocus={this.props.onFocus}
                />

                <DashboardWhereMenu show={this.state.menuActive} className="dashboard-where__input"
                                    onSelect={this.handleSelect} selected={this.state.selected}/>
                {this.state.searchValue !== '' && <div className="dashboard-where__btns">
                    {this.state.searchValue !== '' && <Button onClick={this.props.openMap}
                                                              className="dashboard-where__map ">
                        <span className="glyphicon glyphicon-map-marker"></span></Button>}
                    {this.state.searchValue !== '' && <Button onClick={this.onReset}
                                                              className="dashboard-btn__reset fr-btn pull-right">
                        <span className="glyphicon glyphicon-remove"></span></Button>}
                </div>}
                <span className="input-group-btn">
                        <span className="glyphicon glyphicon-search" aria-hidden="true"></span>
                     </span>
                {this.props.isWhereTabActive && this.state.searchValue === '' &&
                <div className="dashboard-where__nearest">
                    <div className="dashboard-where__nearest-wrapper">
                        <input type="checkbox"
                               id={this.props.idIsNearest}
                               onChange={this.handleNearest}
                               checked={this.state.isNearestChecked}
                        />
                        <label htmlFor={this.props.idIsNearest}>
                            Nearest to me
                        </label>
                    </div>
                </div>}
            </div>
        );
    }
}
