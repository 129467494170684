/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.3.17
 * Time: 10.10
 */

import React, {Component} from 'react';
import {Nav} from 'react-bootstrap';
import strtr from './../../helper/strtr';
import PropTypes from 'prop-types';

export default class RoutedTab extends Component {
    constructor(props, context) {
        super(props, context);

        let tabRoute = context.router.routes[context.router.routes.length - 1];

        this.state = {
            tab: tabRoute.tab,
            mobileOpen: false
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.handleNavTabsDropdown = this.handleNavTabsDropdown.bind(this);
        this.getTabTitle = this.getTabTitle.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let tabRoute = this.context.router.routes[this.context.router.routes.length - 1];

        this.setState({
            tab: tabRoute.tab
        })
    }

    handleSelect(tab) {
        let nextTabURL = strtr(this.props.route, {
            ":tab": tab
        });

        let router = this.props.router || this.context.router;
        router.push(nextTabURL);
        this.setState({
            mobileOpen: false
        })


    }

    handleNavTabsDropdown() {
        let navbar = this.refs.navbarCollapse;
        if (navbar.classList.contains('open')) {

            navbar.classList.remove('open');
            navbar.classList.add('opened');
            this.setState({
                    mobileOpen: !this.state.mobileOpen
                }
            )
        }
        else {
            navbar.classList.remove('opened');
            navbar.classList.add('open');
            this.setState({
                    mobileOpen: !this.state.mobileOpen
                }
            )
        }
    }

    getTabTitle(tab){

        let propsChildren = this.props.children;
        let child = propsChildren.filter(function (obj) {
            if (typeof obj !== 'object') return false;
            return obj.props.eventKey === tab ;
        });

        if (!child || child.length === 0) return '';

        return child[0].props.children;
    }

    render() {
        return <div>
            <button className="nav-tabs-dropdown fr-btn-dropdown fr-mt-xs" data-toggle="collapse"
               ref="navbarCollapse" onClick={this.handleNavTabsDropdown}>{this.getTabTitle(this.state.tab)}</button>
            <Nav bsStyle="tabs" activeKey={this.state.tab} onSelect={this.handleSelect}
                 className="fr-mt fr-navbar-tabs nav-tabs-horizontal" id="nav-tabs-wrapper"
                 style={{display: this.state.mobileOpen ? 'block' : 'none'}}
            >
                {this.props.children}
            </Nav>
        </div>;
    }
}

RoutedTab.contextTypes = {
    router: PropTypes.object.isRequired
};
