/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.11.17
 * Time: 13.29
 */


import React, {Component} from 'react';
import GalleryImageTitle from "../../gallery/elements/gallery-image-title";
import GalleryItemContainer from "../gallery-parts/gallery-item-container";
import GalleryItemImage from "../gallery-parts/gallery-item-image";
import GalleryItemCardInfo from "../gallery-parts/gallery-item-card-info";
import type { TGalleryImage } from "../../../types";
import {FavoriteMark} from "../../favorites/favorite-mark";
import {GALLERY_ITEM_TYPE_LOOK, IMAGE_SIZE_MEDIUM} from "../../../frizo-shared-constants";
import ArtistCertificateBadge from "../../gallery/elements/airtouch-certificate-badge";

type Props = TGalleryImage & {};

export default class DashboardGalleryItemLook extends Component<Props, any> {


    render() {
        return <GalleryItemContainer>
            {this.props.favoritesEnabled && <FavoriteMark id={this.props.id} type={GALLERY_ITEM_TYPE_LOOK} /> }
            {this.props.imageBefore &&  <div className="fr-double-photo" onClick={this.props.onClick}></div>}
            {this.props.artist.airtouchCertified && <ArtistCertificateBadge {...this.props.artist}/>}

            <GalleryItemImage {...this.props} size={IMAGE_SIZE_MEDIUM} />
            <GalleryItemCardInfo>
                <GalleryImageTitle
                    serviceName = {this.props.service.title}
                    firstName = {this.props.artist.firstName}
                    lastName = {this.props.artist.lastName}
                />
            </GalleryItemCardInfo>
        </GalleryItemContainer>;
    }
}

DashboardGalleryItemLook.defaultProps = {
    favoritesEnabled: false
};
