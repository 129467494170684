/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 21.03.2017
 * Time: 15:48
 */

import {
    CLIENT_ADDED,
    CLIENT_EDITED,
    CLIENT_FOUND_BY_PHONE_NUMBER,
    CLIENT_MODAL,
    CLIENT_NOT_REMOVED,
    CLIENT_REMOVED,
    CLIENT_VISIBILITY_ADDED,
    CLIENT_VISIBILITY_REMOVED,
    CLIENTS_LOAD_START,
    CLIENTS_LOAD_SUCCESS,
    LOAD_SALON_CLIENTS,
    MODAL_CHANGE,
    MODAL_HIDE,
    SALON_CLIENT_FOUND
} from "../constants";
import BaseStore from "./base-store";
import { modalHide } from "../ac/modals";

class ClientsStore extends BaseStore {
    constructor(...args) {
        super(...args);
        this.store = {
            clientsList: [],
            clientsCount: 0,
            btnDisabled: false,
            client: {},
            clientExist: false
        };

        this._registerActionSubscription((action) => {
                const { type, payload } = action;

                switch (type) {
                    case CLIENTS_LOAD_START:
                        this.loading = true;

                        break;

                    case CLIENTS_LOAD_SUCCESS:
                        this.loading = false;

                        break;

                    case CLIENT_ADDED:
                        this.addClient(payload.client);
                        if (!payload.added) {
                            this.store.serverErrors = payload.errors;
                        }
                        this.store.btnDisabled = false;
                        break;

                    case MODAL_CHANGE:
                        if (payload.type === CLIENT_MODAL) {
                            this.store.client = this.getClientById(payload.options.clientId);
                        }
                        break;

                    case CLIENT_EDITED:
                        this.editClient(payload.client);
                        if (!payload.edited) {
                            this.store.serverErrors = payload.errors;
                        }
                        this.store.btnDisabled = false;
                        break;

                    case CLIENT_REMOVED:
                    //fallthrough
                    case CLIENT_VISIBILITY_REMOVED:
                        if (payload.removed) this.removeClient(payload.clientID);

                        this.store.btnDisabled = false;
                        break;

                    case CLIENT_NOT_REMOVED:
                        this.store.btnDisabled = false;
                        break;

                    case LOAD_SALON_CLIENTS:
                        if (payload.loaded) {
                            this.store.clientsList = payload.response.data;
                            this.store.clientsCount = payload.response.count;
                        }
                        this.loading = false;

                        break;

                    case CLIENT_VISIBILITY_ADDED:

                        if (payload.id) {
                            this.addClient(payload);
                        }
                        else this.store.serverErrors = payload.errors;

                        break;

                    case CLIENT_FOUND_BY_PHONE_NUMBER:
                        this.store.client = payload;
                        this.store.clientExist = true;

                        break;

                    case SALON_CLIENT_FOUND:

                        this.store.client = payload;
                        this.store.clientExist = false;

                        break;

                    case MODAL_HIDE:
                        this.store.btnDisabled = false;
                        break;

                    default:
                        return true;

                }

                this.emitChange();
            }
        )
        ;
    }


    getClientById(clientId) {
        let clients = this.store.clientsList;
        let client = clients.filter(function(client) {
                return client.id === clientId;
            }
        );
        return client[0];
    }


    removeClient(clientID) {
        this.store.clientsList = this.store.clientsList.filter(client => client.id !== clientID);
        this.store.clientsCount = this.store.clientsCount - 1;
        setTimeout(() => modalHide(), 0);
    }

    editClient(client) {
        let clientsList = this.store.clientsList;
        let index = clientsList.findIndex(clients => clients.id === client.id);
        clientsList.splice(index, 1, client);
        this.store.clientsList = clientsList;
        setTimeout(() => modalHide(), 0);
    }

    addClient(client) {
        let clientsList = this.store.clientsList;
        clientsList.splice(0, 0, client);
        this.store.clientsCount = this.store.clientsCount + 1;
        setTimeout(() => modalHide(), 0);
    }

}

export default ClientsStore;
