/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.9.5
 * Time: 11:47
 */

import React from 'react';

//FIXME: @ignat: for artist photo title display use ArtistImageTitle component!

export default function GalleryImageTitle(props) {

    let title;

    if (props.serviceName && (props.firstName || props.lastName)) {
        title = `${props.serviceName} by ${props.firstName}  ${props.lastName}`;
    }

    return (
        <div className={"fr-card-title"}>{title}</div>
    );
}

GalleryImageTitle.defaultProps = {
    serviceName: '',
    firstName: '',
    lastName: ''
};
