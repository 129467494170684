/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 4.9.17
 */

import {
    CLIENT_STREAM_MARK_AS_READ,
    CLIENT_STREAM_MESSAGE,
    CLIENT_STREAM_NOTIFICATION,
    CLIENT_STREAM_SALON_BILLING_UPDATE
} from "./../constants";


import AppDispatcher from "./../dispatcher";

import { xhrGetClientStream, xhrMarkAsRead } from "./../xhr/client-stream-xhr";

import { sessionStore } from "./../stores";

export function streamInputMessage(data) {
    AppDispatcher.dispatch({
        type: CLIENT_STREAM_MESSAGE,
        payload: data
    });

    //do something specific
    switch (data.type) {
        case CLIENT_STREAM_NOTIFICATION:

            break;

        case CLIENT_STREAM_MESSAGE:

            break;

        case CLIENT_STREAM_SALON_BILLING_UPDATE:
            //force to renew
            break;

        default:
            break;
    }

}

export function markMessageAsRead(messageId) {
    xhrMarkAsRead(messageId).then(response => {
        AppDispatcher.dispatch({
            type: CLIENT_STREAM_MARK_AS_READ,
            payload: {
                messageId: messageId
            }
        });
    });
}

export function initClientStream() {

    sessionStore.getReadyPromise().then(store => {
        if (!sessionStore.isLogged()) {
            return false;
        }

        xhrGetClientStream().then(messageSet => {

            messageSet = messageSet.map(message => {
                let o = Object.assign({}, message);
                delete o.message;

                Object.assign(o, message.message);

                return o;
            });

            for (let i = 0; i < messageSet.length; i++) {
                let message = messageSet[i];

                streamInputMessage(message);
            }
        });
    });

}