/**
 * Created by fliak on 9.11.16.
 */

export default function idGenerator(...chunks) {

    let prefix = chunks.join("-");

    if (idGenerator.iteratorStore[prefix] === undefined) {
        idGenerator.iteratorStore[prefix] = 1;
    }
    else {
        idGenerator.iteratorStore[prefix]++;
    }


    return prefix + "-" + idGenerator.iteratorStore[prefix];
}

//static store
idGenerator.iteratorStore = {};
