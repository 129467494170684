/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 5/16/17
 * Time: 20:03
 */

import { FAVORITES_ITEM_REMOVED } from "../../../constants";
import AbstractGalleryStore from "../../gallery2/abstract-gallery-store";
import { GALLERY2_PERSONAL_PROFILE_FAVORITES_ITEMS_ID } from "../../../frizo-shared-constants";
import { loadGallery } from "../../../ac/gallery/generic-gallery-actions";

export default class PersonalProfileFavoritesItemsStore extends AbstractGalleryStore {

    constructor(...args) {
        super(GALLERY2_PERSONAL_PROFILE_FAVORITES_ITEMS_ID, ...args);

        this._registerActionSubscription((action) => {
            let { type } = action;

            switch (type) {
                case FAVORITES_ITEM_REMOVED:
                    this.reloadGallery();

                    break;

                default:
                    return true;
            }
        });

        this.emitChange();
    }

    getInitialGallery() {
        return Object.assign(super.getInitialGallery(), {
            totalIsKnown: false,
            params: {
                offset: 0,
                count: 12,
                salonId: null
            }
        });
    }

    nextPage() {
        this.store.gallery.params.offset++;
        this.store.gallery.loading = true;
        this.store.gallery.scrollMode = true;

        return loadGallery(this.galleryId, this.store.gallery.params);
    }
}
