/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 23.01.2018
 * Time: 12:40
 */
import React, {Component} from 'react';
import {Modal, Button, Form, Row, Col, Badge} from 'react-bootstrap';
import {
    MODE_PROFESSIONAL, BOOK_APPOINTMENT_NEW,
    PAYMENT_STATUS_DECLINED, PAYMENT_STATUS_NO_STATUS,
    APPOINTMENT_MISSING_TIME_VALUE
} from '../../frizo-shared-constants';
import {LocalTime} from './../helper-components/time-component';
import PhoneStaticMask from './../../elements/phone-static-mask';
import {modalHide} from "../../ac/modals";
import {salonAptStore} from '../../stores/index';
import {startEditing, startBooking} from '../../ac/book-apt/common-actions';
import moment from 'moment';
import {changeAptStatus} from '../../ac/salon-apt';
import {createNewPaymentOrder, alterExistedPaymentOrder} from '../../ac/payment-order-ac';
import notificationSystem from './../../service/notification-system';
import PropTypes from 'prop-types';
import gradientFill from './../../helper/gradient-fill';

export default class AppointmentDetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            id: 0,
            btnDisabled: false
        }, this.getStoreData());


        this.close = this.close.bind(this);
        this.onDeleteHandler = this.onDeleteHandler.bind(this);
        this.onModifyHandler = this.onModifyHandler.bind(this);
        this.onBookHandler = this.onBookHandler.bind(this);
        this.onPaidHandler = this.onPaidHandler.bind(this);

        this.storeUpdate = this.storeUpdate.bind(this);

    }

    componentDidMount() {
        salonAptStore.addChangeListener(this.storeUpdate);
    }

    componentWillUnmount() {
        salonAptStore.removeChangeListener(this.storeUpdate);
    }

    getStoreData() {
        return salonAptStore.getStore().appointment;
    }

    storeUpdate() {
        let object = Object.assign({
            btnDisabled: salonAptStore.getStore().btnDisabled,
            clientExist: salonAptStore.getStore().clientExist
        }, this.getStoreData());
        this.setState(object);
    }


    onDeleteHandler() {
        let message = '<p class="title">Appointment cancel</p>' +
            'Are you sure you wish to cancel? </br> ';


        notificationSystem.confirm(message).then(result => {
            if (result.buttonClicked !== 'ok') {
                return false;
            }
            else {
                this.setState({btnDisabled: true});
                changeAptStatus(this.props.aptId);
            }
        });

    }

    onModifyHandler() {
        startEditing({
            mode: MODE_PROFESSIONAL,
            addressId: this.state.addressId,
            salonId: this.state.salonId,
            date: this.state.localDate,
            artistId: this.state.artist.id,
            salonClientId: this.state.salonClient.id
        });
    }

    onBookHandler() {
        startBooking(
            MODE_PROFESSIONAL,
            this.state.artist.id,
            this.state.salonId,
            moment(new Date()).format('YYYY-MM-DD'), APPOINTMENT_MISSING_TIME_VALUE,
            this.state.serviceId,
            0, 0,
            this.state.addressId, this.state.salonClient, BOOK_APPOINTMENT_NEW);
    }

    onPaidHandler() {
        this.setState({btnDisabled: true});
        if (this.state.paymentStatus === PAYMENT_STATUS_NO_STATUS || this.state.paymentStatus === PAYMENT_STATUS_DECLINED) {
            createNewPaymentOrder(this.props.aptId)
        }
        else {
            alterExistedPaymentOrder(this.props.aptId);
        }
    }

    close() {
        modalHide();
    }

    render() {


        let statusClass = this.props.aptList;
        if (this.props.aptList === "History") {
            statusClass = (this.state.status === 2 ? "No-show" : this.state.status === 1 ? "Canceled" : "Done");
        }

        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>Appointment</h2>
                </Modal.Header>
                <Modal.Body className="client-modal__body">
                    <Form>

                        <Row className="fr-mb ">
                            <Col xs={4} md={4}>
                                <div className="fr-title">Status</div>
                            </Col>
                            <Col xs={8} md={8}>
                                <Badge className={"fr-apt-" + statusClass.toLowerCase()}>{statusClass}</Badge>
                            </Col>
                        </Row>
                        <Row className="fr-mb ">
                            <Col xs={4} md={4}>
                                <div className="fr-title">Date</div>
                            </Col>
                            <Col xs={8} md={8}>
                                <div className="fr-service-title"><span>
                            <LocalTime
                                timestamp={this.state.startTimestamp}
                                tz={this.state.timezone}
                                format="ll, hh:mm A"
                            />
                        </span></div>
                            </Col>
                        </Row>
                        <Row className="fr-mb ">
                            <Col xs={4} md={4}>
                                <div className="fr-title">Client</div>
                            </Col>
                            <Col xs={8} md={8}>
                                <div className="fr-service-title fr-text-overflow">
                                    {this.state.salonClient.firstName}{' '}{this.state.salonClient.lastName}
                                </div>
                            </Col>
                        </Row>
                        <Row className="fr-mb ">
                            <Col xs={4} md={4}>
                                <div className="fr-title">Phone</div>
                            </Col>
                            <Col xs={8} md={8}>
                                <strong><PhoneStaticMask phone={this.state.salonClient.phone}/></strong>
                            </Col>
                        </Row>
                        <Row className="fr-mb ">
                            <Col xs={4} md={4}>
                                <div className="fr-title">Service</div>
                            </Col>
                            <Col xs={8} md={8}>
                                <div className="fr-service-title fr-text-overflow">{this.state.serviceName}</div>
                            </Col>
                        </Row>
                        <Row className="fr-mb ">
                            <Col xs={4} md={4}>
                                <div className="fr-title">Artist</div>
                            </Col>
                            <Col xs={8} md={8}>
                                <div className="fr-service-title fr-text-overflow">
                                    {this.state.artist.firstName}{' '}{this.state.artist.lastName}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="client-modal__footer">
                    {this.props.aptList === 'Pending' &&
                    <div className="fr-apt-modal__footer">
                        <Button className=" fr-apt-modal__footer fr-btn-remove "
                                disabled={this.state.btnDisabled}
                                onClick={this.onPaidHandler}>
                            Get paid
                        </Button></div>}

                    {this.props.aptList === 'History' &&
                    <div className="fr-apt-modal__footer">
                        <Button className="fr-apt-modal__footer fr-btn__center "
                                onClick={this.onBookHandler}
                                disabled={this.state.btnDisabled}>
                            Book again
                        </Button>
                    </div>}

                    {this.props.aptList === 'Upcoming' &&
                    <div>
                        <Button className="client-modal__footer fr-btn-client__remove"
                                disabled={this.state.btnDisabled}
                                onClick={this.onDeleteHandler}>Cancel
                        </Button>
                        <Button className="client-modal__footer fr-btn-client__save "
                                disabled={this.state.btnDisabled}
                                onClick={this.onModifyHandler}>Modify
                        </Button>
                    </div>}
                </Modal.Footer>
            </div>
        )
    }
}
AppointmentDetailModal.propTypes = {
    aptId: PropTypes.number,
    aptList: PropTypes.string
};

AppointmentDetailModal.defaultProps = {
    aptId: 0,
    aptList: ''
};