/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 9.11.16.
 * Popover buttons can be configured on the fly
 */

import React, {Component} from 'react';
import {Popover} from 'react-bootstrap';
import idGenerator from './../../helper/id-generator-component';
import PropTypes from 'prop-types';

export default class PopoverWrapper extends Component {

    constructor(props)   {
        super(props);

        this.state = {
            data: {
                originContext: {},
                buttons: []
            }
        };

        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Configure popover buttons before open
     * @param data
     */
    setContextData(data)    {
        this.setState({
            data: data
        })
    }

    handleClick(e, buttonConfig)   {
        if (typeof buttonConfig.onClick === 'function') {
            buttonConfig.onClick(e, this.state.data.originContext);
        }
        else {
            e.persist();
            console.log('button clicked', e, 'in context', this.state.data.originContext);
        }
    }

    render() {
        return (
            <Popover className="popover" {...this.props}>
                <div className="popover-content">
                    {this.state.data.element}
                </div>
            </Popover>
        );
    }
}

PopoverWrapper.defaultProps = {
    id: idGenerator('popover'),
    contentWrapClass: ''
};

PopoverWrapper.propTypes = {
    id: PropTypes.string
};
