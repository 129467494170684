/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 02.01.18
 */

import { Q_MANUAL, Q_PROCESSED } from "../frizo-shared-constants";

export class Queue {

    constructor() {
        this.queue = [];
    }

    length() {
        return this.queue.length;
    }

    push(element) {
        this.queue.push(element);
    }

    shift() {
        return this.queue.shift();
    }

    reset() {
        this.queue = [];
    }

    consume(consumer: Function) {
        if (this.queue.length === 0) return false;

        let element = this.queue[0];

        switch (consumer(element)) {
            case Q_PROCESSED:
                this.queue.shift();
                break;
            case Q_MANUAL:
            default:
                break;
        }

        return true;
    }

    confirm(element) {
        let index = this.queue.indexOf(element);
        if (~index) {
            this.queue.splice(index, 1);
        }
        else {
            console.warn("Try to confirm missing element in queue", element, this.queue);
        }
    }
}
