/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 25.7.17
 */

export function paymentOrderServiceNormalize(rawElement) {
    let normalForm = {
        id: rawElement.id,
        priceDefault: rawElement.priceDefaultCent,
        priceEntered: rawElement.priceEnteredCent

    };

    if (rawElement.appointment) {
        normalForm.aptId = rawElement.appointment.id;
        normalForm.aptPrice = rawElement.appointment.priceCent;
        normalForm.aptStartTimestamp = rawElement.appointment.startTimestamp;


        if (rawElement.appointment.masterUser) {
            let master = rawElement.appointment.masterUser;

            normalForm.artistId = master.id;
            normalForm.artistName = master.firstName + " " + master.lastName;
        }

        if (rawElement.appointment.service) {
            normalForm.serviceName = rawElement.appointment.service.i18nKey;
            normalForm.serviceId = rawElement.appointment.service.id;
        }
    }

    return normalForm;
}
