/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 10.3.17
 * Time: 7.42
 */

import moment from "moment";
import config from "./../config";

window.moment = moment;

export default function printDate(date, format = null) {
    format = format || config.defaultDateFormat;

    return moment.utc(date).format(format);
}
