/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.03.2017
 * Time: 11:59
 */

import React, {Component} from 'react'
import {Col, Row, Pagination, Button} from 'react-bootstrap';
import {CLIENT_MODAL} from '../../constants';
import {modalChange} from '../../ac/modals';
import ClientsList from './clients-list';
import ClientsFilter from './client-filter';
import {getSalonClients} from '../../ac/clients';
import {clientsStore, sessionStore} from '../../stores/index';
import {PAGINATION_ITEMS_COUNT} from '../../frizo-shared-constants';
import gradientFill from '../../helper/gradient-fill';
import withNonProDisclaimer from "../hoc/non-pro-disclaimer-hoc";

class ClientsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            clientsCount: 0,
            currentPageNumber: 1,
            searchString: ''
        };
        this.session = sessionStore.getSession();
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    openModal() {
        modalChange(CLIENT_MODAL, {});
    }

    onEdit(clientId) {
        modalChange(CLIENT_MODAL, {clientId});
    }

    componentDidMount() {
        let params = {
            limit: PAGINATION_ITEMS_COUNT,
            offset: 0
        };
        getSalonClients(params);
        clientsStore.addChangeListener(this.handleChange);
    }

    componentWillUnmount() {
        clientsStore.removeChangeListener(this.handleChange);
    }

    handleChange() {
        this.setState({
                clients: clientsStore.store.clientsList,
                clientsCount: clientsStore.store.clientsCount
            }
        );
    }

    handleSelect(eventKey) {

        let params = {
            limit: PAGINATION_ITEMS_COUNT,
            offset: eventKey - 1,
            combinedSearch: this.state.searchString
        };

        this.setState({
            currentPageNumber: eventKey
        });
        getSalonClients(params);
    }

    handleFilterUpdate(filter) {
        this.setState({
            searchString: filter,
            currentPageNumber: 1
        });
        let params = {
            limit: PAGINATION_ITEMS_COUNT,
            offset: 0,
            combinedSearch: filter
        };
        this.setState({
                currentPageNumber: 1
            }
        );
        getSalonClients(params);
    }


    render() {
        let totalPages = Math.ceil(this.state.clientsCount / PAGINATION_ITEMS_COUNT);
        let maxVisibleButtons = (window.innerWidth < 769 ? 3 : 10);

        return (
            <div>
                <div className="fr-banner-top fr-full-width" style={{'background': gradientFill()}}>
                    <Row className="fr-container-width">
                        <Col xs={12} md={6} sm={6} className="fr-banner-top__text--clients">
                            <h1>Clients </h1>
                            <Button className="fr-clients__btn-add fr-btn-add "
                                    onClick={this.openModal.bind(this)}>Add new</Button>
                        </Col>
                        <Col xs={12} md={6} sm={6}>
                            <ClientsFilter clientsFilter={this.handleFilterUpdate}/>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md={12}>
                        <div>
                            <Row className="fr-list-header bordered fr-mt row">
                                <Col xs={5} md={3}>Name</Col>
                                <Col xs={5} md={2}>Phone</Col>
                                <Col xsHidden={true} smHidden={true} md={6}>Comment</Col>
                                <Col xsHidden={true} smHidden={true} md={1}>Action</Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <ClientsList clients={this.state.clients} onDelete={this.onDelete}
                                     clientsCount={this.state.clientsCount} onEdit={this.onEdit}
                        />
                    </Col>
                </Row>
                <Pagination
                    className="fr-pagination-standard"
                    bsSize="medium"
                    items={totalPages > 1 ? totalPages : 0}
                    activePage={totalPages > 0 ? this.state.currentPageNumber : 0}
                    onSelect={this.handleSelect}
                    maxButtons={maxVisibleButtons} first={totalPages > 1 ? "<<" : null}
                    last={totalPages > 1 ? ">>" : null} next={totalPages > 1 ? ">" : null}
                    prev={totalPages > 1 ? "<" : null}
                    ellipsis={totalPages > 1 ? "..." : null}
                />
            </div>
        )
    }
}

export default withNonProDisclaimer(ClientsContainer);
