/**
 * Created by fliak on 12.1.17.
 */

import {
    APPOINTMENT_CHANGE,
    BOOK_CLIENT_FORM_RESET,
    CLIENT_DATA_CHANGED,
    CLIENT_FOUND,
    CLIENT_MISSING
} from "../../constants";

import AppDispatcher from "../../dispatcher";
import { bookAptClientStore } from "./../../stores";
import notificationSystem from "./../../service/notification-system";
import { modalHide } from "./../modals";
//import ApiClient from '../../service/api-client';

let bookAptFormActions = {

    aptChange: function(aptId, patch) {
        AppDispatcher.dispatch({
            type: APPOINTMENT_CHANGE,
            payload: {
                id: aptId,
                patch: patch
            }
        });
    },

    clientDataChange(clientDataPatch) {
        AppDispatcher.dispatch({
            type: CLIENT_DATA_CHANGED,
            payload: clientDataPatch
        });
    }


};

export default bookAptFormActions;

export function clientFound(clientData) {
    AppDispatcher.dispatch({
        type: CLIENT_FOUND,
        payload: clientData
    });
}


export function clientMissing(clientData) {
    if (bookAptClientStore.getStore().salonClientSpecified) {
        notificationSystem.confirm(
            "The client details have been deleted. <br />You may add them back",
            "Proceed", "Cancel"
        ).then(result => {
            if (result.buttonClicked !== "ok") {
                modalHide();
            }
        });
    }

    AppDispatcher.dispatch({
        type: CLIENT_MISSING
    });
}

export function clientFormReset() {
    AppDispatcher.dispatch({
        type: BOOK_CLIENT_FORM_RESET
    });
}

