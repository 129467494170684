/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 3/30/17
 * Time: 23:53
 */

import BaseConstraint from "./base-constraint";

export default class IsEmail extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Invalid email";
        this.regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.errorCode = "VALUE_IS_NOT_EMAIL";
    }

    static getName() {
        return "is-email";
    }
}
