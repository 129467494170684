/**
 * Created by ignat
 * Name: Ignat Kryshcyk
 * Company: U6 SIA
 * Date: 08.08.2017
 * Time: 9:26
 */
import React from "react";
import PropTypes from "prop-types";


export default function ScreenMessage(props) {

    return (
        <div className="screen-message">
            <div className="screen-message__block">{props.message}</div>
        </div>
    );
}

ScreenMessage.propTypes = {
    message: PropTypes.object.isRequired
};
