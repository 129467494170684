/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 3.06.2017
 * Time: 11:45
 */
import React, {Component} from 'react';
import {withGoogleMap, GoogleMap, Marker} from 'react-google-maps';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import {Link, browserHistory} from 'react-router';
import _ from 'lodash';
import icon from '../../../../img/map-pin.svg';
// Wrap all `react-google-maps` components with `withGoogleMap` HOC
// and name it GettingStartedGoogleMap
/* global google */

const MOBILE_VIEW = 768;

class WhereMapMarker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false
        };
        this.timeout = null;
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    open() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.setState({opened: true});

    }

    close() {
        this.setState({opened: false});
    }

    handleClose(e) {
        this.timeout = setTimeout(_.debounce(this.close), 3000);
    }

    redirect(link) {
        browserHistory.push(link);
    }

    handleClick(e) {
        /* const isTooltip = e.target ? e.target.classList.contains('where-map-marker__label-link') : false;
         if (isTooltip) {
             this.redirect(e.target.href);
         }*/
        let promoUrl = `promo/${this.props.location.salonUrl}/gallery`;
        this.redirect(promoUrl);
    }

    render() {
        let labelStyle = {
            backgroundColor: "white",
            fontSize: "12px",
            padding: "5px"
        };
        return (
            <MarkerWithLabel {...this.props}
                             position={{
                                 lat: this.props.position.lat,
                                 lng: this.props.position.lon
                             }}

                             onMouseOver={this.open}
                             onTouchStart={this.open}
                             onMouseOut={this.handleClose}
                             onTouchEnd={this.handleClose}
                             labelAnchor={new google.maps.Point(40, 60)}
                             labelStyle={labelStyle}
                             labelClass={'where-map-marker__label'}
                             labelVisible={this.state.opened}
                             onClick={this.handleClick}
            >
                <Link className="where-map-marker__label-link" to={`promo/${this.props.location.salonUrl}/gallery`}>
                    {this.props.location.name}
                </Link>

            </MarkerWithLabel>
        )
    }
}

const MapWithCoords = withGoogleMap(props => {

        function createSalonMarkers() {
            return props.locations.map((loc, id) => {
                return (
                    <WhereMapMarker
                        icon={icon}
                        key={`${loc.name}${id}`}
                        location={loc}
                        position={{lat: loc.lat, lon: loc.lon}}/>
                )
            })
        }

        let mapZoom = window.innerWidth < MOBILE_VIEW ? props.zoom - 1 : props.zoom;
        return (
            <GoogleMap
                zoom={mapZoom}
                center={{lat: props.lat, lng: props.lon}}

            >
                {props.showCenterMarker && <Marker position={{lat: props.lat, lng: props.lon}}/>}
                {createSalonMarkers()}
            </GoogleMap>
        )
    }
);

type Props = {
    lat: number,
    lon: number,
    locations: Array,
    render: boolean,
    zoom: number,
    showCenterMarker: boolean
};

export default class GoogleMapWithMarkers extends Component<Props> {

    render() {
        if (!this.props.render) {
            return null
        }
        let height = this.props.height || '400px';

        return (
            <MapWithCoords
                containerElement={
                    <div style={{height: height, width: '100%'}}/>
                }
                mapElement={
                    <div style={{height: height, width: '100%'}}/>
                }
                lat={this.props.lat}
                lon={this.props.lon}
                locations={this.props.locations}
                zoom={this.props.zoom}
                showCenterMarker={this.props.showCenterMarker}
            />
        )
    }
}
