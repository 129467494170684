/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 25.7.17
 *
 * @flow
 */

import type { TRawAddress1, TRawAddressLegacy } from "./raw-types";
import type { TAddress } from "../types";
import { TYPE_ADDRESS } from "../frizo-shared-constants";

export function addressNormalize1(rawElement: TRawAddress1): TAddress {

    return {
        entityType: TYPE_ADDRESS,
        id: rawElement.id,
        address: rawElement.address,
        suite: rawElement.suite,
        city: rawElement.city,
        state: rawElement.state,
        zip: rawElement.zip,
        timezone: rawElement.timezone,

        lat: rawElement.lat,
        lon: rawElement.lon,
        onLocation: rawElement.onLocation
    };
}

export function addressNormalizerLegacy(rawElement: TRawAddressLegacy): TAddress {

    return {
        entityType: TYPE_ADDRESS,
        id: rawElement.addressID,
        address: rawElement.addressStr,
        suite: rawElement.suite,
        countryId: rawElement.countryID,
        countryName: rawElement.countryName,
        state: rawElement.state,
        city: rawElement.city,
        zip: rawElement.zip,
        timezone: rawElement.timezone,

        onLocation: rawElement.onLocation,

        lat: rawElement.latitude,
        lon: rawElement.longitude
    };
}
