/**
 * User: Ihnat Kryshchyk
 * Username: ihnat
 * Company: U6 SIA
 * Date: 21.6.17
 * Time: 8.53
 */

import moment from "moment";
import config from "./../config";

export default function convertSecondsToFormattedTime(seconds) {
    if (seconds === undefined) {
        console.warn("Try to convert undefined seconds to formatted time");

        return "";
    }

    return moment()
    .startOf("day")
    .seconds(seconds)
    .format(config.defaultTimeFormat);
}
