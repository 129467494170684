import React, { Component } from "react";

import MaskedFormControl from "react-bootstrap-maskedinput";

export default class PhoneInput extends Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onPaste = this.onPaste.bind(this);
    }

    componentDidMount() {
        let phoneInput = document.querySelector("#phone-input");
        if (phoneInput) {
            phoneInput.addEventListener("paste", this.onPaste);
        }
    }

    componentWillUnmount() {
        let phoneInput = document.querySelector("#phone-input");
        if (phoneInput) {
            phoneInput.removeEventListener("paste", this.onPaste);
        }
    }

    onChange(e) {
        let value = e.target.value;
        let rawValue = value.replace(/\D+/g, "");

        this.props.onChange({
            target: {
                value: value,
                rawValue: rawValue
            }
        }, e);
    }

    onPaste(e) {
        let value = e.clipboardData.getData("Text");
        let rawValue = value.replace(/\D+/g, "");
        this.props.onChange({
            target: {
                value: value,
                rawValue: rawValue
            }
        }, e);
    }

    render() {
        let props = Object.assign({}, this.props);
        props.onChange = this.onChange;

        return <MaskedFormControl {...props} type="tel" id={"phone-input"}/>;
    }
}

PhoneInput.defaultProps = {
    mask: "(111) 111-1111"
};
