/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 2.10.17
 */

import { clientCardsStore } from "./../../stores";
import { loadClientCard } from "./../../ac/payments/payment-common";
import { addNewPaymentSource } from "./../../ac/payments/payments-personal";

import { ADD_NEW_CARD_MODAL } from "./../../constants";

import { modalChange } from "./../../ac/modals";

export function cardAssertion() {
    return new Promise((success, fail) => {
        if (!clientCardsStore.isReady()) {
            loadClientCard();
        }

        clientCardsStore.getReadyPromise().then(store => {
            if (store.cards.length > 0) {
                success();

                return store;
            }

            modalChange(ADD_NEW_CARD_MODAL, {
                noCard: true,
                action: token => {
                    return addNewPaymentSource(token).then(response => {
                        success(response);

                        return response;
                    }, response => {
                        fail(response);

                        return Promise.reject(response);
                    });
                }
            });

            return store;
        });
    });
}
