/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.10.17
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_TO_HIGH = "VALUE_IS_TO_HIGH";

export default class LessThan extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Must be less than {value}";

        this.value = null;

        this.setOptions(options);
    }

    static getName() {
        return "is-less-than";
    }

    getRequiredOptions() {
        return ["value"];
    }
}
