/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.12.17
 * Time: 17.23
 */

/*
 global Routing
 */

export default function buildUrl(route: string, params: {} = {}) {
    return Routing.generate(route, params);
}
