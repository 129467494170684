/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 15.02.2018
 * Time: 16:32
 */

import { CONTACT_MODAL, CONTACT_US_MESSAGE_FAIL, CONTACT_US_MESSAGE_SENT, MODAL_CHANGE } from "../constants";
import BaseStore from "./base-store";
import { sessionStore } from "./index";
import { modalHide } from "../ac/modals";

class ContactUsStore extends BaseStore {
    constructor(...args) {
        super(...args);

        this._registerActionSubscription((action) => {
            let { type, payload } = action;
            switch (type) {


                case MODAL_CHANGE:
                    if (payload.type === CONTACT_MODAL) {
                        this.store.isLogged = sessionStore.getSession().isLogged;
                        this.store.email = sessionStore.getSession().isLogged ? sessionStore.getSession().userName : "";
                        this.store.phone = sessionStore.getSession().isLogged ? sessionStore.getSession().phone : "";
                        this.store.btnDisabled = false;

                    }

                    break;
                case CONTACT_US_MESSAGE_SENT:
                    this.store.btnDisabled = false;
                    this.store.showError = {};

                    setTimeout(() => modalHide(), 0);

                    break;

                case CONTACT_US_MESSAGE_FAIL:

                    this.store.btnDisabled = false;
                    this.store.showError.sendMessage = true;

                    break;


                default:
                    return true;
            }
        });

    }

    getInitialStore() {
        return {
            isLogged: sessionStore.getSession().isLogged,
            email: sessionStore.getSession().isLogged ? sessionStore.getSession().userName : "",
            phone: sessionStore.getSession().isLogged ? sessionStore.getSession().phone : "",
            btnDisabled: false,
            message: "",
            showError: {}
        };
    }
}

export default ContactUsStore;
