/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.8.6
 * Time: 14:56
 */

import BaseConstraint from "./base-constraint";

export const IMAGE_REQUIRED = "IMAGE_REQUIRED";

export default class Image extends BaseConstraint {

    constructor(options = null) {
        super();


        this.message = "Please upload image";

        if (options) {
            this.setOptions(options);
        }
    }

    static getName() {
        return "image";
    }
}
