export const LOAD_IMAGES_START = "LOAD_IMAGES_START";
export const LOAD_IMAGES_SUCCESS = "LOAD_IMAGES_SUCCESS";
export const LOAD_FILTERED_GALLERY_ITEMS = "LOAD_FILTERED_GALLERY_ITEMS";
export const INIT_IMAGE_STORE = "INIT_IMAGE_STORE";
export const IMAGE_CLICKED = "IMAGE_CLICKED";

//auth
export const LOAD_SESSION_SUCCESS = "LOAD_SESSION_SUCCESS";
export const LOAD_FEATURE_FLAGS = "LOAD_FEATURE_FLAGS";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_AUTH = "USER_AUTH";
export const USER_SESSION_UPDATE = "USER_SESSION_UPDATE";

//FAQ
export const LOAD_FAQ_FOR_CLIENTS = "LOAD_FAQ_FOR_CLIENTS";
export const LOAD_FAQ_FOR_PROFESSIONALS = "LOAD_FAQ_FOR_PROFESSIONALS";

//modals
export const LOGIN_MODAL = "LOGIN_MODAL";
export const FORGOT_MODAL = "FORGOT_MODAL";
export const BOOK_MODAL = "BOOK_MODAL";
export const LEGAL_MODAL = "LEGAL_MODAL";
export const CONTACT_MODAL = "CONTACT_MODAL";
export const CLIENT_MODAL = "CLIENT_MODAL";
export const CLIENT_COMMENT_MODAL = "CLIENT_COMMENT_MODAL";
export const CHANGE_PASSWORD_MODAL = "CHANGE_PASSWORD_MODAL";
export const CHANGE_EMAIL_MODAL = "CHANGE_EMAIL_MODAL";
export const GALLERY_FULLSCREEN_MODAL = "GALLERY_FULLSCREEN_MODAL";
export const GALLERY_FULLSCREEN_MODAL_LEGACY = "GALLERY_FULLSCREEN_MODAL_LEGACY";
export const ADD_NEW_CARD_MODAL = "ADD_NEW_CARD_MODAL";
export const CREATE_PAYMENT_PROFILE_MODAL = "CREATE_PAYMENT_PROFILE_MODAL";
export const ADD_NEW_BANK_ACCOUNT_MODAL = "ADD_NEW_BANK_ACCOUNT_MODAL";
export const ALERT_SUBSCRIPTION_MODAL = "ALERT_SUBSCRIPTION_MODAL";
export const SUBSCRIPTION_APPROVAL_MODAL = "SUBSCRIPTION_APPROVAL_MODAL";
export const SUBSCRIPTION_FINISH_MODAL = "SUBSCRIPTION_FINISH_MODAL";
export const SERVICE_EDIT_MODAL = "SERVICE_EDIT_MODAL";
export const SALON_APPOINTMENT_DETAIL_MODAL = "SALON_APPOINTMENT_DETAIL_MODAL";
export const PERSONAL_APPOINTMENT_DETAIL_MODAL = "PERSONAL_APPOINTMENT_DETAIL_MODAL";
export const ARTIST_SERVICE_MODAL = "ARTIST_SERVICE_MODAL";
export const AFFILIATE_FORM_MODAL = "AFFILIATE_FORM_MODAL";
export const PRO_PRODUCTS_VIDEO_MODAL = "PRO_PRODUCTS_VIDEO_MODAL";

export const COMMON_MODAL_SAVE = "COMMON_MODAL_SAVE";
export const COMMON_MODAL_CLEAR = "COMMON_MODAL_CLEAR";
export const COMMON_MODAL_UPDATE_DATA = "COMMON_MODAL_UPDATE_DATA";
export const COMMON_MODAL_SAVE_FAIL = "COMMON_MODAL_SAVE_FAIL";

export const CREATE_PAYMENT_ORDER_MODAL = "CREATE_PAYMENT_ORDER_MODAL";
export const PAYMENT_APPROVAL_MODAL = "PAYMENT_APPROVAL_MODAL";


export const SIGN_DOC = "SIGN_DOC";
export const DOCS_LOADED = "DOCS_LOADED";

export const MODAL_CHANGE = "MODAL_CHANGE";
export const MODAL_HIDE = "MODAL_HIDE";
export const MODAL_SEQUENCE = "MODAL_SEQUENCE";

export const LOAD_SALON_DATA_SUCCESS = "LOAD_SALON_DATA_SUCCESS";
export const CLIENT_DATA_CHANGED = "CLIENT_DATA_CHANGED";
export const CLIENT_FOUND = "CLIENT_FOUND";
export const CLIENT_MISSING = "CLIENT_MISSING";

export const APPOINTMENT_ADD = "APPOINTMENT_ADD";
export const BOOK_ANOTHER = "BOOK_ANOTHER";
export const APPOINTMENT_REMOVE = "APPOINTMENT_REMOVE";
export const APPOINTMENT_CHANGE = "APPOINTMENT_CHANGE";
export const BOOK_APPOINTMENT_LOADED = "BOOK_APPOINTMENT_LOADED";
export const BOOK_INITIALS_LOADED = "BOOK_INITIALS_LOADED";

export const AVAILABLE_TIME_LOAD_SUCCESS = "AVAILABLE_TIME_LOAD_SUCCESS";
export const RESERVE_TIME = "RESERVE_TIME";

export const BOOK_START_BOOKING = "BOOK_START_BOOKING";
export const BOOK_START_EDIT = "BOOK_START_EDIT";
export const BOOK_PERSIST = "BOOK_PERSIST";

export const LOAD_SERVICES_SUCCESS = "LOAD_SERVICES_SUCCESS";
export const LOAD_SELECT_SERVICES_SUCCESS = "LOAD_SELECT_SERVICES_SUCCESS";
export const GALLERY_FILTER_LOAD_SERVICES_SUCCESS = "GALLERY_FILTER_LOAD_SERVICES_SUCCESS";

export const BOOK_LOAD_SALON_INFO_SUCCESS = "BOOK_LOAD_SALON_INFO_SUCCESS";
export const BOOK_LOAD_TIMELINE_SUCCESS = "BOOK_LOAD_TIMELINE_SUCCESS";
export const BOOK_APPOINTMENTS_LOAD_SUCCESS = "BOOK_APPOINTMENTS_LOAD_SUCCESS";

export const BOOK_PERSIST_SUCCESS = "BOOK_PERSIST_SUCCESS";
export const BOOK_PERSIST_FAIL = "BOOK_PERSIST_FAIL";
export const BOOK_PERSIST_STOP = "BOOK_PERSIST_STOP";

export const PROFILE_SERVICES_DATA_LOADED = "PROFILE_SERVICES_DATA_LOADED";
export const PROFILE_SALON_SERVICE_ADDED = "PROFILE_SALON_SERVICE_ADDED";
export const PROFILE_SALON_SERVICE_REMOVED = "PROFILE_SALON_SERVICE_REMOVED";
export const PROFILE_SALON_SERVICE_REMOVE_FAILED = "PROFILE_SALON_SERVICE_REMOVE_FAILED";
export const PROFILE_SALON_SERVICE_MODIFIED = "PROFILE_SALON_SERVICE_MODIFIED";
export const PROFILE_SALON_SERVICE_MODIFY_FAILED = "PROFILE_SALON_SERVICE_MODIFY_FAILED";

export const LOAD_SALON_LOCATION = "LOAD_SALON_LOCATION";
export const LOAD_SALON_SERVICES = "LOAD_SALON_SERVICES";

export const SALON_GALLERY_ACTION_INIT = "SALON_GALLERY_ACTION_INIT";
export const SALON_GALLERY_ACTION_CLICK = "SALON_GALLERY_ACTION_CLICK";
export const SALON_GALLERY_ACTION_SCROLL = "SALON_GALLERY_ACTION_SCROLL";
export const SALON_GALLERY_DATA_LOAD_SUCCESS = "SALON_GALLERY_DATA_LOAD_SUCCESS";
export const SALON_GALLERY_IMAGES_PER_SECTION = 4;
export const SALON_GALLERY_DATA_RESET = "SALON_GALLERY_DATA_RESET";

export const PROFESSIONAL_PROFILE_PROFILE_COMPLETE_UPDATE = "PROFESSIONAL_PROFILE_PROFILE_COMPLETE_UPDATE";

export const PROFESSIONAL_PROFILE_WH_ARTISTS_LOADED = "PROFESSIONAL_PROFILE_WH_ARTISTS_LOADED";
export const PROFESSIONAL_PROFILE_WH_OPEN = "PROFESSIONAL_PROFILE_WH_OPEN";
export const PROFESSIONAL_PROFILE_WH_UPDATE = "PROFESSIONAL_PROFILE_WH_UPDATE";
export const PROFESSIONAL_PROFILE_WH_PERSIST = "PROFESSIONAL_PROFILE_WH_PERSIST";
export const PROFESSIONAL_PROFILE_WH_DISCARD = "PROFESSIONAL_PROFILE_WH_DISCARD";
export const PROFESSIONAL_PROFILE_WH_SELECT_ARTIST = "PROFESSIONAL_PROFILE_WH_SELECT_ARTIST";

export const PROFESSIONAL_PROFILE_LOAD_SALON_INFO_SUCCESS = "PROFESSIONAL_PROFILE_LOAD_SALON_INFO_SUCCESS";

export const PROFESSIONAL_PROFILE_WH_SYNC_COMPLETE = "PROFESSIONAL_PROFILE_WH_SYNC_COMPLETE";
export const PROFESSIONAL_PROFILE_WH_SYNC_ERROR = "PROFESSIONAL_PROFILE_WH_SYNC_ERROR";

export const PROFILE_ARTIST_SALON_INFO_LOADED = "PROFILE_ARTIST_SALON_INFO_LOADED";
export const ARTIST_LIST_CLICK = "ARTIST_LIST_CLICK";
export const ARTIST_LIST_SCROLL = "ARTIST_LIST_SCROLL";
export const ARTIST_LIST_INIT = "ARTIST_LIST_INIT";
export const ARTIST_LIST_LOADED = "ARTIST_LIST_LOADED";
export const UPDATE_ARTIST_FORM = "UPDATE_ARTIST_FORM";
export const ARTIST_ADDED = "ARTIST_ADDED";
export const ARTIST_REMOVED = "ARTIST_REMOVED";
export const ARTIST_SAVE_ERROR = "ARTIST_SAVE_ERROR";
export const ARTIST_INFO_LOADED = "ARTIST_INFO_LOADED";
export const ARTIST_SERVICES_LOADED = "ARTIST_SERVICES_LOADED";
export const ARTIST_INVITATION_SENT = "ARTIST_INVITATION_SENT";

export const PROFILE_ARTIST_SERVICE_REMOVED = "PROFILE_ARTIST_SERVICE_REMOVED";
export const PROFILE_ARTIST_SERVICE_ADDED = "PROFILE_ARTIST_SERVICE_ADDED";
export const PROFILE_ARTIST_SERVICE_MODIFIED = "PROFILE_ARTIST_SERVICE_MODIFIED";

export const PROFILE_GALLERY_SALON_INFO_LOADED = "PROFILE_GALLERY_SALON_INFO_LOADED";
export const PROFILE_GALLERY_ITEM_LOADED = "PROFILE_GALLERY_ITEM_LOADED";
export const GALLERY_LIST_CLICK = "GALLERY_LIST_CLICK";
export const GALLERY_LIST_SCROLL = "GALLERY_LIST_SCROLL";
export const GALLERY_LIST_INIT = "GALLERY_LIST_INIT";
export const PROFILE_GALLERY_RESET = "PROFILE_GALLERY_RESET";
export const PROFILE_GALLERY_ITEM_REMOVED = "PROFILE_GALLERY_ITEM_REMOVED";

export const GALLERY_REMOVE_ELEMENT = "GALLERY_REMOVE_ELEMENT";

export const DASHBOARD_GALLERY_ACTION_INIT = "DASHBOARD_GALLERY_ACTION_INIT";
export const DASHBOARD_GALLERY_ACTION_CLICK = "DASHBOARD_GALLERY_ACTION_CLICK";
export const DASHBOARD_GALLERY_ACTION_SCROLL = "DASHBOARD_GALLERY_ACTION_SCROLL";
export const DASHBOARD_GALLERY_DATA_LOAD_SUCCESS = "DASHBOARD_GALLERY_DATA_LOAD_SUCCESS";
export const DASHBOARD_GALLERY_IMAGES_PER_SECTION = 12;
export const DASHBOARD_GALLERY_DATA_RESET = "DASHBOARD_GALLERY_DATA_RESET";
export const DASHBOARD_WHO_HINT_CHANGE = "DASHBOARD_WHO_HINT_CHANGE";
export const DASHBOARD_WHERE_INPUT_CHANGE = "DASHBOARD_WHERE_INPUT_CHANGE";
export const DASHBOARD_WHERE_IS_NEAREST_CHANGE = "DASHBOARD_WHERE_IS_NEAREST_CHANGE";

export const DASHBOARD_FILTER_SERVICE_TREE = "DASHBOARD_FILTER_SERVICE_TREE";

export const DASHBOARD_WHO_HINT_LIST_LOADED = "DASHBOARD_WHO_HINT_LIST_LOADED";
export const DASHBOARD_WHO_INPUT_CHANGE = "DASHBOARD_WHO_INPUT_CHANGE";

export const DASHBOARD_WHERE_HINT_LIST_LOADED = "DASHBOARD_WHERE_HINT_LIST_LOADED";
export const DASHBOARD_WHERE_HINT_SELECT = "DASHBOARD_WHERE_HINT_SELECT";
export const DASHBOARD_WHEN_TIME_INTERVAL_CHANGE = "DASHBOARD_WHEN_TIME_INTERVAL_CHANGE";
export const DASHBOARD_WHEN_DATE_CHANGE = "DASHBOARD_WHEN_DATE_CHANGE";
export const DASHBOARD_WHEN_DATE_RESET = "DASHBOARD_WHEN_DATE_RESET";

export const BRAND_LIST_LOAD = "BRAND_LIST_LOAD";
export const BRAND_INPUT_CHANGE = "BRAND_INPUT_CHANGE";
export const BRAND_HINT_SELECT = "BRAND_HINT_SELECT";
export const BRAND_HINT_LIST_LOAD = "BRAND_HINT_LIST_LOAD";

export const PROFILE_BRAND_DATA_LOADED = "PROFILE_BRAND_DATA_LOADED";
export const PROFILE_BRAND_ADDED = "PROFILE_BRAND_ADDED";
export const PROFILE_BRAND_REMOVED = "PROFILE_BRAND_REMOVED";

export const CLIENT_ADDED = "CLIENT_ADDED";
export const CLIENT_EDITED = "CLIENT_EDITED";
export const LOAD_SALON_CLIENTS = "LOAD_SALON_CLIENTS";
export const CLIENT_REMOVED = "CLIENT_REMOVED";
export const CLIENT_NOT_REMOVED = "CLIENT_NOT_REMOVED";
export const CLIENTS_LOAD_START = "CLIENTS_LOADED_START";
export const CLIENTS_LOAD_SUCCESS = "CLIENTS_LOADED_SUCCESS";
export const CLIENT_VISIBILITY_ADDED = "CLIENT_VISIBILITY_ADDED";
export const CLIENT_VISIBILITY_REMOVED = "CLIENT_VISIBILITY_REMOVED";
export const CLIENT_FOUND_BY_PHONE_NUMBER = "CLIENT_FOUND_BY_PHONE_NUMBER";
export const SALON_CLIENT_FOUND = "SALON_CLIENT_FOUND";

export const GALLERY_FILTER_ONCHANGE = "GALLERY_FILTER_ONCHANGE";
export const GALLERY_FILTER_RESET = "GALLERY_FILTER_RESET";
export const GALLERY_FILTER_LOAD_ARTISTS_SUCCESS = "GALLERY_FILTER_LOAD_ARTISTS_SUCCESS";
export const GALLERY_FILTER_PRESET = "GALLERY_FILTER_PRESET";

export const GALLERY_SELECT_ONCHANGE = "GALLERY_SELECT_ONCHANGE";
export const GALLERY_SELECT_LOAD_ARTISTS_SUCCESS = "GALLERY_SELECT_LOAD_ARTISTS_SUCCESS";

export const USER_FAVORITE_ADDED = "USER_FAVORITE_ADDED";
export const USER_FAVORITE_REMOVED = "USER_FAVORITE_REMOVED";
export const GALLERY_FAVORITE_ADDED = "GALLERY_FAVORITE_ADDED";
export const GALLERY_FAVORITE_REMOVED = "GALLERY_FAVORITE_REMOVED";

export const IMAGE_TYPE_GALLERY_PHOTO = 1;
export const IMAGE_TYPE_MASTER_PHOTO = 3;
export const LOAD_COUNTRIES_SPEC = "LOAD_COUNTRIES_SPEC";

export const LOAD_SALON_APPOINTMENTS = "LOAD_SALON_APPOINTMENTS";
export const DELETE_SALON_APPOINTMENT = "DELETE_SALON_APPOINTMENT";
export const CHANGE_SALON_APPOINTMENT_FILTER = "CHANGE_SALON_APPOINTMENT_FILTER";
export const LOAD_PERSONAL_APPOINTMENTS = "LOAD_PERSONAL_APPOINTMENTS";
export const DELETE_PERSONAL_APPOINTMENT = "DELETE_PERSONAL_APPOINTMENT";
export const LOAD_PERSONAL_PAYMENT_ORDERS = "LOAD_PERSONAL_PAYMENT_ORDERS";
export const FILTER_PERSONAL_PAYMENT_ORDERS = "FILTER_PERSONAL_PAYMENT_ORDERS";


export const RAW_SERVICES_LOADED = "RAW_SERVICES_LOADED";
export const LOAD_LEGAL_DOC = "LOAD_LEGAL_DOC";

export const PRICE_RANGE_SIGNS = 4;

export const PROFILE_PERSONAL_ESSENTIALS_PERSIST = "PROFILE_PERSONAL_ESSENTIALS_PERSIST";
export const PROFILE_PERSONAL_ESSENTIALS_PERSIST_FAIL = "PROFILE_PERSONAL_ESSENTIALS_PERSIST_FAIL";

export const UPDATE_SESSION_DATA = "UPDATE_SESSION_DATA";
export const PROFILE_PERSONAL_ESSENTIALS_CHANGE = "PROFILE_PERSONAL_ESSENTIALS_CHANGE";

export const LOAD_FILTERS_SERVICES_SUCCESS = "LOAD_FILTERS_SERVICES_SUCCESS";

export const FAVORITES_ARTISTS_ACTION_SCROLL = "FAVORITES_ARTISTS_ACTION_SCROLL";
export const FAVORITES_ARTISTS_ACTION_INIT = "FAVORITES_ARTISTS_ACTION_INIT";
export const FAVORITES_ARTISTS_ACTION_CLICK = "FAVORITES_ARTISTS_ACTION_CLICK";
export const FAVORITES_ARTISTS_DATA_LOAD_SUCCESS = "FAVORITES_ARTISTS_DATA_LOAD_SUCCESS";
export const FAVORITES_ARTIST_REMOVED = "FAVORITES_ARTIST_REMOVED";


export const FAVORITES_ITEMS_ACTION_SCROLL = "FAVORITES_ITEMS_ACTION_SCROLL";
export const FAVORITES_ITEMS_ACTION_INIT = "FAVORITES_ITEMS_ACTION_INIT";
export const FAVORITES_ITEMS_ACTION_CLICK = "FAVORITES_ITEMS_ACTION_CLICK";
export const FAVORITES_ITEMS_DATA_LOAD_SUCCESS = "FAVORITES_ITEMS_DATA_LOAD_SUCCESS";
export const FAVORITES_ITEMS_IMAGES_PER_SECTION = 4;
export const FAVORITES_ITEM_REMOVED = "FAVORITES_ITEM_REMOVED";


export const PROMO_INITIALS_LOADED = "PROMO_INITIALS_LOADED";

export const SALON_PROMO_INITIALS_SET = "SALON_PROMO_INITIALS_SET";

export const SALON_PROMO_RESET_GALLERY = "SALON_PROMO_RESET_GALLERY";

export const SALON_PROMO_BRANDS_LOAD_SUCCESS = "SALON_PROMO_BRANDS_LOAD_SUCCESS";

export const SALON_PROMO_ARTISTS_LOAD_SUCCESS = "SALON_PROMO_ARTISTS_LOAD_SUCCESS";

export const SALON_PUBLIC_DATA_LOADED = "SALON_PUBLIC_DATA_LOADED";

export const LOAD_SALON_CLIENT_LIST_SUCCESS = "LOAD_SALON_CLIENT_LIST_SUCCESS";
export const SALON_CLIENT_LIST_RESET = "SALON_CLIENT_LIST_RESET";
export const BOOK_CLIENT_FORM_RESET = "BOOK_CLIENT_FORM_RESET";

export const PROFILE_PROFESSIONAL_PAY_PAYMENTSOURCE_LOADED = "PROFILE_PROFESSIONAL_PAY_PAYMENTSOURCE_LOADED";
export const PROFILE_PROFESSIONAL_GETPAID_PAYMENTSOURCE_LOADED = "PROFILE_PROFESSIONAL_GETPAID_PAYMENTSOURCE_LOADED";

export const PROFILE_PERSONAL_MYCARDS_LOADED = "PROFILE_PERSONAL_MYCARDS_LOADED";
export const PROFILE_PERSONAL_MYCARDS_ERROR = "PROFILE_PERSONAL_MYCARDS_ERROR";
export const PROFILE_PERSONAL_PAYMENTS_LOADED = "PROFILE_PERSONAL_PAYMENTS_LOADED";

export const PROFILE_PROFESSIONAL_PAYMENT_PROFILE_FORM_UPDATE = "PROFILE_PROFESSIONAL_PAYMENT_PROFILE_FORM_UPDATE";
export const PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED = "PROFILE_PROFESSIONAL_PAYMENT_PROFILE_LOADED";
export const PROFILE_PROFESSIONAL_PAYMENT_PROFILE_PERSONS_LOADED = "PROFILE_PROFESSIONAL_PAYMENT_PROFILE_PERSONS_LOADED";
export const PROFILE_PROFESSIONAL_PAYMENT_PROFILE_SAVE_FAIL = "PROFILE_PROFESSIONAL_PAYMENT_PROFILE_SAVE_FAIL";
export const PROFILE_PROFESSIONAL_PAYMENT_PROFILE_DOCUMENT_UPLOADED = "PROFILE_PROFESSIONAL_PAYMENT_PROFILE_DOCUMENT_UPLOADED";
export const PROFILE_PROFESSIONAL_PAYMENT_DATA_COMPLETION_REPORT_LOADED = "PROFILE_PROFESSIONAL_PAYMENT_DATA_COMPLETION_REPORT_LOADED";


export const PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_LOADED = "PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_LOADED";
export const PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ERROR = "PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ERROR";
export const PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ADD = "PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ADD";
export const PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ADD_ERROR = "PROFILE_PROFESSIONAL_EXTERNAL_ACCOUNTS_ADD_ERROR";

export const PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED = "PROFILE_PROFESSIONAL_STRIPE_SUBSCRIPTION_CUSTOMER_LOADED";
export const PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR = "PROFILE_PROFESSIONAL_PAY_SOURCE_ERROR";

export const PROFILE_PROFESSIONAL_CHARGES_LOADED = "PROFILE_PROFESSIONAL_CHARGES_LOADED";
export const PROFILE_PROFESSIONAL_TRANSACTIONS_LOADED = "PROFILE_PROFESSIONAL_TRANSACTIONS_LOADED";
export const SALON_SUBSCRIPTION_CANCELED = "SALON_SUBSCRIPTION_CANCELED";
export const SALON_SUBSCRIPTION_CANCEL_ERROR = "SALON_SUBSCRIPTION_CANCEL_ERROR";

export const PAYMENT_ORDER_SUGGEST_TAX = "PAYMENT_ORDER_SUGGEST_TAX";
export const PAYMENT_ORDER_UPDATE_TAX = "PAYMENT_ORDER_UPDATE_TAX";
export const PAYMENT_ORDER_APTS_LOADED = "PAYMENT_ORDER_APTS_LOADED";
export const PAYMENT_ORDER_UPDATE_PRICE = "PAYMENT_ORDER_UPDATE_PRICE";
export const PAYMENT_ORDER_LOAD = "PAYMENT_ORDER_LOAD";
export const PAYMENT_ORDER_CREATE_ERROR = "PAYMENT_ORDER_CREATE_ERROR";

export const REFRESH_APPOINTMENT_LIST = "REFRESH_APPOINTMENT_LIST";

export const LOAD_UNSEEN_APPOINTMENTS_LIST_SUCCESS = "LOAD_UNSEEN_APPOINTMENTS_LIST_SUCCESS";
export const TOGGLE_UNSEEN_APPOINTMENTS_LIST = "TOGGLE_UNSEEN_APPOINTMENTS_LIST";
export const UNSEEN_APPOINTMENT_DISMISS = "UNSEEN_APPOINTMENT_DISMISS";
export const REMOVE_UNSEEN_APPOINTMENT_NOTIFICATION = "REMOVE_UNSEEN_APPOINTMENT_NOTIFICATION";
export const REMOVE_UNSEEN_APPOINTMENT_SET_NOTIFICATION = "REMOVE_UNSEEN_APPOINTMENT_SET_NOTIFICATION";

export const LOAD_PRICING_PRODUCTS = "LOAD_PRICING_PRODUCTS";
export const SELECT_PLAN = "SELECT_PLAN";
export const SELECT_PRICING_PLAN = "SELECT_PRICING_PLAN";

export const PAYMENT_APPROVAL_UPDATE_TIP = "PAYMENT_APPROVAL_UPDATE_TIP";
export const PAYMENT_APPROVAL_CHANGE_PAYMENT_SOURCE = "PAYMENT_APPROVAL_CHANGE_PAYMENT_SOURCE";
export const PAYMENT_APPROVAL_FAILED = "PAYMENT_APPROVAL_FAILED";
export const PAYMENT_APPROVAL_CANCELED = "PAYMENT_APPROVAL_CANCELED";
export const PAYMENT_APPROVAL_DISCOUNT_SOLUTION_LOADED = "PAYMENT_APPROVAL_DISCOUNT_SOLUTION_LOADED";


export const NOTIFY_SUCCESS = "NOTIFY_SUCCESS";
export const NOTIFY_FAIL = "NOTIFY_FAIL";

export const REQUEST_SUCCESS = "REQUEST_SUCCESS";

export const GENERIC_VALIDATION_FAIL = "GENERIC_VALIDATION_FAIL";

export const FEATURE_FLAG_LOADED = "FEATURE_FLAG_LOADED";
export const FEATURE_FLAG_PARAMS_ENTERED = "FEATURE_FLAG_PARAMS_ENTERED";
export const ROUTE_MATCHED = "ROUTE_MATCHED";


export const PROFILE_COMPLETENESS_UPDATE = "PROFILE_COMPLETENESS_UPDATE";

export const CLIENT_STREAM_MESSAGE = "CLIENT_STREAM_MESSAGE";
export const CLIENT_STREAM_NOTIFICATION = "CLIENT_STREAM_NOTIFICATION";
export const CLIENT_STREAM_SALON_BILLING_UPDATE = "CLIENT_STREAM_SALON_BILLING_UPDATE";
export const CLIENT_STREAM_MARK_AS_READ = "CLIENT_STREAM_MARK_AS_READ";

export const CALENDAR_MARKERS_LOADED = "CALENDAR_MARKERS_LOADED";
export const CALENDAR_BOUND_CHANGES = "CALENDAR_BOUND_CHANGES";

export const LOAD_SUBSCRIPTION_PRODUCTS = "LOAD_SUBSCRIPTION_PRODUCTS";
export const LOAD_SALON_INFO = "LOAD_SALON_INFO";
export const SELECT_SUBSCRIPTION = "SELECT_SUBSCRIPTION";
export const GET_COUPON = "GET_COUPON";
export const SALON_SUBSCRIPTION_CARD_ADDED = "SALON_SUBSCRIPTION_CARD_ADDED";
export const SALON_SUBSCRIPTION_ADDED = "SALON_SUBSCRIPTION_ADDED";
export const SALON_SUBSCRIPTION_CREATE_ERROR = "SALON_SUBSCRIPTION_CREATE_ERROR";
export const SALON_SUBSCRIPTION_CHANGED = "SALON_SUBSCRIPTION_CHANGED";

export const CLIENT_CARD_ADDED = "CLIENT_CARD_ADDED";
export const LOAD_CLIENT_CARDS_INFO = "LOAD_CLIENT_CARDS_INFO";

export const REGISTRATION_FORM_CHANGE = "REGISTRATION_FORM_CHANGE";
export const FORM_VALIDATION_FAIL = "FORM_VALIDATION_FAIL";
export const INITIALIZE_FORM = "INITIALIZE_FORM";

export const PROFILE_PERSONAL_EMAIL_FORM_CHANGE = "PROFILE_PERSONAL_EMAIL_FORM_CHANGE";
export const PROFILE_PERSONAL_EMAIL_FORM_PROCESS = "PROFILE_PERSONAL_EMAIL_FORM_PROCESS";
export const PROFILE_PERSONAL_EMAIL_FORM_CLEAR = "PROFILE_PERSONAL_EMAIL_FORM_CLEAR";
export const PROFILE_PERSONAL_EMAIL_CHANGE_SUCCESS = "PROFILE_PERSONAL_EMAIL_CHANGE_SUCCESS";
export const PROFILE_PERSONAL_EMAIL_CHANGE_ERROR = "PROFILE_PERSONAL_EMAIL_CHANGE_ERROR";

export const PROFILE_PERSONAL_PASSWORD_FORM_CHANGE = "PROFILE_PERSONAL_EMAIL_FORM_CHANGE";
export const PROFILE_PERSONAL_PASSWORD_FORM_PROCESS = "PROFILE_PERSONAL_EMAIL_FORM_PROCESS";
export const PROFILE_PERSONAL_PASSWORD_FORM_CLEAR = "PROFILE_PERSONAL_EMAIL_FORM_CLEAR";
export const PROFILE_PERSONAL_PASSWORD_CHANGE_SUCCESS = "PROFILE_PERSONAL_PASSWORD_CHANGE_SUCCESS";
export const PROFILE_PERSONAL_PASSWORD_CHANGE_ERROR = "PROFILE_PERSONAL_PASSWORD_CHANGE_ERROR";

export const DASHBOARD_GALLERY_START_LOADING = "DASHBOARD_GALLERY_START_LOADING";
export const DASHBOARD_GALLERY_LOAD = "DASHBOARD_GALLERY_LOAD";
export const DASHBOARD_GALLERY_RESET = "DASHBOARD_GALLERY_RESET";
export const DASHBOARD_GALLERY_ENTER_PARAMS = "DASHBOARD_GALLERY_ENTER_PARAMS";
export const DASHBOARD_GALLERY_NEXT_PAGE = "DASHBOARD_GALLERY_NEXT_PAGE";
export const DASHBOARD_GALLERY_FULLSCREEN_PREV = "DASHBOARD_GALLERY_FULLSCREEN_PREV";
export const DASHBOARD_GALLERY_FULLSCREEN_NEXT = "DASHBOARD_GALLERY_FULLSCREEN_NEXT";
export const DASHBOARD_GALLERY_FULLSCREEN_OPEN = "DASHBOARD_GALLERY_FULLSCREEN_OPEN";


export const GALLERY_INIT = "GALLERY_INIT";
export const GALLERY_RESET = "GALLERY_RESET";
export const GALLERY_PRESET = "GALLERY_PRESET";
export const GALLERY_START_LOADING = "GALLERY_START_LOADING";
export const GALLERY_LOAD = "GALLERY_LOAD";
export const GALLERY_NEXT_PAGE = "GALLERY_NEXT_PAGE";
export const GALLERY_FULLSCREEN_PREV = "GALLERY_FULLSCREEN_PREV";
export const GALLERY_FULLSCREEN_NEXT = "GALLERY_FULLSCREEN_NEXT";
export const GALLERY_FULLSCREEN_OPEN = "GALLERY_FULLSCREEN_OPEN";
export const GALLERY_ENTER_PARAMS = "GALLERY_ENTER_PARAMS";
export const GALLERY_ADDITIVE_MODE_ENABLE = "GALLERY_ADDITIVE_MODE_ENABLE";


export const DASHBOARD_GALLERY_LOAD_STRUCTURE1 = "DASHBOARD_GALLERY_LOAD_STRUCTURE1";
export const DASHBOARD_GALLERY_LOAD_STRUCTURE2 = "DASHBOARD_GALLERY_LOAD_STRUCTURE2";
export const DASHBOARD_GALLERY_LOAD_STRUCTURE3 = "DASHBOARD_GALLERY_LOAD_STRUCTURE3";
export const DASHBOARD_GALLERY_ADD_LOOK = "DASHBOARD_GALLERY_ADD_LOOK";
export const DASHBOARD_GALLERY_SPREAD_STRUCTURE = "DASHBOARD_GALLERY_SPREAD_STRUCTURE";

//Client search
export const INPUT_MASK_PHONE = "(111)-111-1111";
export const INPUT_MASK_TEXT = "aaaaaaaaaaaaaaaaaaaa";

export const PROFILE_FAVORITES_LOADED = "PROFILE_FAVORITES_LOADED";
export const LOGIN_PROCESS_SEMAPHORE_ENABLE = "LOGIN_PROCESS_SEMAPHORE_ENABLE";

export const CONTACT_US_MESSAGE_SENT = "CONTACT_US_MESSAGE_SENT";
export const CONTACT_US_MESSAGE_FAIL = "CONTACT_US_MESSAGE_FAIL";

export const LOAD_ALL_SALON_ADDRESSES = "LOAD_ALL_SALON_ADDRESSES";

export const SALON_UPDATE_UPLOADED_GALLERY_ITEMS_COUNT = "SALON_UPDATE_UPLOADED_GALLERY_ITEMS_COUNT";

export const ARTIST_PROMO_DATA_LOADED = "ARTIST_PROMO_DATA_LOADED";
export const ARTIST_PROMO_DATA_LOAD_ERROR = "ARTIST_PROMO_DATA_LOAD_ERROR";

export const CLIENT_RECOMMENDATION_LINK_VALID = "CLIENT_RECOMMENDATION_LINK_VALID";
export const CLIENT_RECOMMENDATION_LINK_INVALID = "CLIENT_RECOMMENDATION_LINK_INVALID";

export const PRO_RECOMMENDATION_LINK_VALID = "PRO_RECOMMENDATION_LINK_VALID";
export const PRO_RECOMMENDATION_LINK_INVALID = "PRO_RECOMMENDATION_LINK_INVALID";