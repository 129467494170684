/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 3.1.18
 *
 * @flow
 */

import { Request } from "./request";

export class AbstractResponseHandler {

    handleResponse(request: Request, xhr: XMLHttpRequest): boolean {
        throw new Error("Method should be overridden in child class");
    }
}
