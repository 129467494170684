/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.3.17
 * Time: 6.38
 */

import React, { Component } from "react";
import Validator from "./../../../../validation/validator";
import Violation from "./../../../helper-components/violation";
import PhoneInput from "./../../../../elements/phone-input";

export default class MySalonBaseInfoForm extends Component {

    constructor(props) {
        super(props);

        this.onNameChange = this.onNameChange.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.onInstagramUserChange = this.onInstagramUserChange.bind(this);
        this.onSave = this.onSave.bind(this);

        this.state = {
            name: this.props.name,
            phone: this.props.phone,
            instagramUsername: this.props.instagramUsername,
            formIsValid: props.formIsValid,
            violations: props.violations,
            btnDisabled: false
        };

    }

    onNameChange(e) {
        this.setState({ name: e.target.value });
        this.props.onChange("name", e.target.value);
    }

    onInstagramUserChange(e) {
        this.setState({ instagramUsername: e.target.value });
        this.props.onChange("instagramUsername", e.target.value);
    }

    onPhoneChange(e) {
        this.setState({ phone: e.target.value });
        this.props.onChange("phone", e.target.rawValue);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            name: nextProps.name,
            instagramUsername: nextProps.instagramUsername,
            phone: nextProps.phone
        });
    }

    onSave(e) {
        e.preventDefault();

        let validator = new Validator();

        validator.addConstraint("phone", "is-required");
        validator.addConstraint("phone", "is-number");
        validator.addConstraint("phone", "length", {
            max: 10,
            min: 10
        });

        validator.addConstraint("name", "is-required");
        validator.addConstraint("name", "is-salon-name", {
            allowSpaces: true
        });
        validator.addConstraint("name", "length", {
            min: 2,
            max: 50
        });

        let violations = validator.validate({
            name: this.props.name,
            phone: this.props.phone
        });


        this.setState({
            formIsValid: violations === true,
            violations: violations === true ? {} : violations
        });

        if (violations === true) {
            this.setState({
                btnDisabled: true
            });
            this.props.onSave().then(response => {
                this.setState({
                    btnDisabled: false
                });
                //ignore
            }, response => {
                this.setState({
                    violations: response.errors,
                    formIsValid: !response.errors,
                    btnDisabled: false
                });
            });
        }
    }


    render() {
        let btn = null;

        if (!this.props.readOnly) {
            btn = <div>
                <button onClick={this.onSave} className="btn fr-btn pull-right" disabled={this.state.btnDisabled}>
                    Update
                </button>
            </div>;
        }
        let title = this.props.isFreelancer ? "Business" : "Salon";

        return <form method="post" noValidate="noValidate" className="form-horizontal fr-masked">
            <div className="form-group">
                <div className="col-sm-12">
                    <label htmlFor="t_salon_info_change_SalonName" className="required">{title + " name"}</label>
                    <input readOnly={this.props.readOnly} value={this.state.name} onChange={this.onNameChange}
                           type="text" id="t_salon_info_change_SalonName" required="required"
                           placeholder={title + " name"} className="form-control"/>

                    <Violation violationSet={this.state.violations.name} visible={!this.state.formIsValid}/>

                </div>
            </div>
            <div className="form-group">
                <div className="col-sm-12">
                    <label htmlFor="t_salon_info_change_instagramUsername">{"Social"}</label>
                    <input type="text" className="form-control"
                           name="instagramUsername"
                           id={"t_salon_info_change_instagramUsername"}
                           value={this.state.instagramUsername}
                           onChange={this.onInstagramUserChange}
                           readOnly={this.props.readOnly}
                           placeholder={"Instagram username"}
                           autoComplete="off"
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="col-sm-12">
                    <label htmlFor="t_salon_info_change_SalonPhone" className="required">{title + " phone"}</label>
                    <PhoneInput className="form-control" type="phone"
                                required="required"
                                name="phone"
                                value={this.state.phone}
                                mask='(111) 111-1111'
                                onChange={this.onPhoneChange}
                                readOnly={this.props.readOnly}
                                placeholder={title + " phone"}
                                autoComplete="off"
                    />

                    <Violation violationSet={this.state.violations.phone} visible={!this.state.formIsValid}/>

                </div>
            </div>

            {btn}
        </form>;
    }
}


MySalonBaseInfoForm.defaultProps = {
    name: "",
    phone: "",
    instagramUsername: "",
    formIsValid: true,
    violations: {},
    isFreelancer: false,

    readOnly: false,

    onSave: () => {
    },
    onChange: () => {
    }
};