/**
 * Name: Zakhar Shushkevich
 * Company: U6 SIA
 * Date: 09.01.2017
 * Time: 09:27
 */

import BaseStore from "./base-store";
import {
    LOAD_SESSION_SUCCESS,
    LOGIN_MODAL,
    LOGIN_PROCESS_SEMAPHORE_ENABLE,
    MODAL_CHANGE,
    MODAL_HIDE,
    PROFILE_COMPLETENESS_UPDATE,
    SALON_UPDATE_UPLOADED_GALLERY_ITEMS_COUNT,
    UPDATE_SESSION_DATA,
    USER_AUTH,
    USER_LOGOUT,
    USER_SESSION_UPDATE
} from "../constants";
import { modalHide } from "../ac/modals";
import { loadProfileCompletenessInfo } from "../ac/profile-completeness-actions";
import type { TSession } from "../types";
import { Request } from "../service/api-client-2/request";
//import notificationSystem from "../service/notification-system";
//import {alertMessage} from "../elements/alert-message";

type TAuthResult = {
    session: TSession | null,
    response: any, //FIXME: undocumented
    authorized: boolean,
    problemKey: string,
    problem: string,
    request: Request,
    options: {
        groups: Array<string>
    }
};

type TSessionStore = {
    session: TSession,
    salon: {
        uploadedGalleryItemsCount: integer
    },
    loadedGroups: Array<string>,
    authResult: TAuthResult,
    loginModalOptions: {
        autoHide: boolean
    },
    loginModalOpened: boolean,
    loginSemaphore: boolean
};

class SessionStore extends BaseStore {

    store: TSessionStore;

    constructor(...args) {
        super(...args);

        this.initEmptySession();

        this._registerActionSubscription((callback) => {


            const { type, payload } = callback;

            switch (type) {
                case SALON_UPDATE_UPLOADED_GALLERY_ITEMS_COUNT:
                    this.store.salon.uploadedGalleryItemsCount = payload.uploadedGalleryItemsCount;
                    break;

                case PROFILE_COMPLETENESS_UPDATE:
                    /*
                     Disable popup due to FW-1974

                     if (this.store.qualified === false && payload.qualifyTimestamp)  {
                     console.log('this.store', this.store.session);

                     let username = this.store.session.firstName;
                     let s = `<p class="title">Good job, ${username}!</p>` +
                     `<p class="title__p">Professional profile completed.</p>`+
                     `Uploaded images under review.<br/>`+
                     `We'll send an e-mail upon review completion`;

                     notificationSystem.alert(s);
                     }
                     */

                    this.store.qualified = Boolean(payload.qualifyTimestamp);

                    break;

                case MODAL_CHANGE:
                    if (payload.type === LOGIN_MODAL) {
                        Object.assign(this.store.loginModalOptions, payload.options);
                        this.store.loginModalOpened = true;

                        break;
                    }

                    return;

                case LOGIN_PROCESS_SEMAPHORE_ENABLE:
                    this.store.loginSemaphore = payload;

                    break;

                case MODAL_HIDE:
                    if (this.store.loginModalOpened) {
                        this.store.loginModalOpened = false;
                    }
                    else {
                        return true;
                    }

                    break;


                case UPDATE_SESSION_DATA:
                    let data = payload.response;

                    Object.assign(this.store.session, {
                        firstName: data.firstName,
                        lastName: data.lastName
                    });

                    break;

                case  USER_SESSION_UPDATE:
                    this.setupSession(payload.session, payload.request);
                    this.setReady(true);

                    break;

                case LOAD_SESSION_SUCCESS:
                    this.setupSession(payload.session, payload.request);
                    this.store.loadedGroups = payload.options.groups || [];

                    this.setReady(true);

                    if (this.store.session.professional) {
                        loadProfileCompletenessInfo();
                    }
                    break;

                case USER_AUTH:
                    this.store.loginSemaphore = false;

                    this.store.authResult = payload;

                    if (payload.authorized) {

                        if (
                            this.store.loginModalOpened &&
                            this.store.loginModalOptions.autoHide) {

                            setTimeout(modalHide);
                        }
                    }
                    else {
                        this.destroySession();

                        //or handle specific problem..
                    }

                    this.setReady(true);

                    break;

                case USER_LOGOUT:
                    this.destroySession();

                    this.setReady(true);

                    break;

                default:

                    return true;
            }

            this.emitChange();
        });
    }

    getStore(): TSessionStore {
        return this.store;
    }

    getInitialStore(): TSessionStore {
        return {
            qualified: null,
            session: {},
            salon: {
                uploadedGalleryItemsCount: 0
            },
            loadedGroups: [],
            authResult: {},
            loginModalOptions: {
                autoHide: true
            },
            loginModalOpened: false,
            loginSemaphore: false
        };
    }

    getLoadedGroups() {
        return this.store.loadedGroups;
    }

    /**
     *
     * @param session hash server session response
     * @param request xhr object
     * @private
     */
    setupSession(session, request) {
        this.store.session = {
            ...session
        };

        this.store.session.isLogged = true;

        if (session.subscriptionTier) {
            if (session.subscriptionTier.key === "no-professional") {
                this.store.session.subscriptionTier = null;
            }
        }
    }

    /**
     * @private
     */
    initEmptySession() {
        this.store.session = {
            rawUserType: null,
            isLogged: null,
            professional: null,
            master: null,
            subscriptionTier: null,
            discountTotal: {}
        };
    }

    destroySession() {
        this.initEmptySession();
    }

    isLogged() {
        return Boolean(this.store.session.isLogged);
    }

    isMaster() {
        return Boolean(this.store.session.master);
    }

    isBusinessAccount() {
        return Boolean(this.store.session.business);
    }

    isProfessional() {
        return Boolean(this.store.session.professional);
    }

    isSalonAdmin() {
        return Boolean(this.store.session.salonAdmin);
    }

    getUserType() {
        return this.store.session.rawUserType;
    }

    getUserId() {
        return this.store.session.id;
    }

    getSession() {
        return this.store.session;
    }

    getAuthResult() {
        return this.store.authResult;
    }

    resetAuthResult() {
        return this.store.authResult = {};
    }

    getDiscountTotal() {
        return this.store.session.discountTotal;
    }
}

export default SessionStore;
