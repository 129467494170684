/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.4.17
 * Time: 21.06
 */

import React, {Component, cloneElement } from 'react';
import {ButtonGroup} from 'react-bootstrap';
import PropTypes from 'prop-types';

export default class ToggleGroup extends Component {

    onSelect(value)  {
        this.props.onSelect(value);
    }

    render()    {
        const { children } = this.props;

        let disabled = this.props.disabled;

        let forwardProps = Object.assign({}, this.props);
        delete forwardProps.disabled;
        delete forwardProps.selected;
        delete forwardProps.onSelect;

        return <ButtonGroup {...forwardProps}>
            { React.Children.map(children, child => {
                if (!React.isValidElement(child)) {
                    return;
                }

                let active = child.props.value === this.props.selected;

                return cloneElement(child, {
                    onClick: this.onSelect.bind(this, child.props.value),
                    active,
                    disabled
                });
            }) }
        </ButtonGroup>;
    }

}

ToggleGroup.defaultProps = {
    disabled: false
};

ToggleGroup.propTypes = {
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelect: PropTypes.func,
    disabled: PropTypes.bool

};
