/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.11.17
 * Time: 7.39
 */

import BaseStore from "../base-store";

import {
    GALLERY_ADDITIVE_MODE_ENABLE,
    GALLERY_ENTER_PARAMS,
    GALLERY_FILTER_LOAD_ARTISTS_SUCCESS,
    GALLERY_FILTER_LOAD_SERVICES_SUCCESS,
    GALLERY_FULLSCREEN_NEXT,
    GALLERY_FULLSCREEN_OPEN,
    GALLERY_FULLSCREEN_PREV,
    GALLERY_INIT,
    GALLERY_LOAD,
    GALLERY_NEXT_PAGE,
    GALLERY_PRESET,
    GALLERY_REMOVE_ELEMENT,
    GALLERY_RESET,
    GALLERY_START_LOADING
} from "../../constants";
import { galleryFullscreenNext, loadGallery } from "../../ac/gallery/generic-gallery-actions";

export default class AbstractGalleryStore extends BaseStore {

    constructor(catchId, ...args) {
        super(...args);
        this.galleryId = catchId;
        this.genericFilterAware = false;
    }

    getInitialGallery() {
        return {
            items: [],
            itemsCount: 0, //items count and items.length can be differ
            totalCount: 0,
            totalIsKnown: true,
            endReached: false,
            showMoreButton: false,
            loading: false,
            scrollMode: false,

            fullScreen: {
                currentIndex: 0,
                currentItem: null,
                showNext: false,
                showPrev: false,
                showImageAfter: true
            },

            params: {
                offset: 0,
                count: 12
            }
        };
    }

    getInitialStore() {
        return {
            gallery: this.getInitialGallery(),
            artists: [],
            categories: [],
            services: [],
            salonCategories: []
        };
    }

    _registerActionSubscription(childCallback) {

        super._registerActionSubscription(action => {

            //call child callback before
            let ret = childCallback(action);
            if (ret !== true) return ret;

            let ret2 = this.processGenericActions(action);
            if (ret2 !== true) return ret2;

            //run own if origin skipped
            return this.processGalleryActions(action);

        });

    }

    setGenericFilterAware(value) {
        this.genericFilterAware = value;
    }

    processGalleryActions(action) {
        let { type, payload } = action;

        if (!payload || payload.galleryId !== this.galleryId) return true;

        switch (type) {

            case GALLERY_INIT:
            case GALLERY_RESET:
                this.resetStore();

                break;

            case GALLERY_PRESET:
                this.resetGallery(payload.params);
                this.store.gallery.params.offset = 0;

                break;

            case GALLERY_ENTER_PARAMS:
                this.enterParams(payload.params);

                break;

            case GALLERY_REMOVE_ELEMENT:
                this.reloadGallery();
                break;

            case GALLERY_START_LOADING:
                this.store.gallery.loading = true;
                break;

            case GALLERY_LOAD:
                if (!payload.items || payload.items.length < this.store.gallery.params.count) {
                    this.store.gallery.endReached = true;
                    this.store.gallery.scrollMode = false;
                }

                this.store.gallery.items.push(...payload.items);

                this.store.gallery.itemsCount += payload.items.length;
                this.store.gallery.totalCount = payload.totalCount;

                this.store.gallery.loading = false;
                this.calculateShowMoreButton();
                this.checkIsEndReached();

                break;

            case GALLERY_NEXT_PAGE:
                this.nextPage();

                break;

            case GALLERY_FULLSCREEN_PREV:
                if (this.store.gallery.fullScreen.currentIndex === 0) return true;
                this.prevItem();

                break;

            case GALLERY_FULLSCREEN_NEXT:
                if (this.store.gallery.totalIsKnown) {
                    if (this.store.gallery.fullScreen.currentIndex === this.store.gallery.totalCount - 1) {
                        console.debug("last element reached 1, skip..",
                            this.store.gallery.fullScreen.currentIndex,
                            this.store.gallery.totalCount);

                        return true;
                    }
                }
                if (this.store.gallery.fullScreen.currentIndex === this.store.gallery.itemsCount - 1) {
                    console.debug("last loaded element reached, preloading..",
                        this.store.gallery.fullScreen.currentIndex,
                        this.store.gallery.itemsCount);

                    if (this.store.gallery.endReached) {
                        console.debug("last element reached 2, skip..",
                            this.store.gallery.fullScreen.currentIndex,
                            this.store.gallery.totalCount);

                        return true;
                    }

                    this.nextPage().then(
                        galleryFullscreenNext.bind(this, this.galleryId)
                    );
                }
                else {
                    this.nextItem();
                }

                break;

            case GALLERY_FULLSCREEN_OPEN:
                this.store.gallery.fullScreen.currentIndex = payload.startIndex;
                this.updateFullscreenView();
                this.calculateFullScreenNextPrevButtons();

                break;

            case GALLERY_ADDITIVE_MODE_ENABLE:
                this.nextPage();

                break;

            default:
                return true;
        }

        this.emitChange();

    }

    processGenericActions(action) {
        let { type, payload } = action;

        switch (type) {

            case GALLERY_FILTER_LOAD_ARTISTS_SUCCESS:
                if (!this.genericFilterAware) return true;
                this.store.artists = payload.artists;

                this.updateFilter();

                break;

            case GALLERY_FILTER_LOAD_SERVICES_SUCCESS:
                if (!this.genericFilterAware) return true;

                let categories = {};

                payload.forEach(service => {
                    if (!service.canHaveImage) {
                        return true;
                    }

                    let parent = categories[service.parent.id];

                    if (parent === undefined) {
                        parent = Object.assign({
                            leafs: []
                        }, service.parent);

                        categories[service.parent.id] = parent;
                        this.store.salonCategories.push(parent);
                    }

                    parent.leafs.push(service);

                });

                this.updateFilter();

                break;

            default:
                return true;
        }

        this.emitChange();
    }

    enterParams(params) {
        Object.keys(params).forEach(paramName => {
            let newValue = params[paramName];
            let oldValue = this.store.gallery.params[paramName];

            if (newValue !== oldValue) {

                this.reloadGallery(params);

                return false;
            }
        });
    }

    resetGallery(withParams = {}) {
        let saveParams = Object.assign({}, this.store.gallery.params, withParams);

        this.store.gallery = this.getInitialGallery();

        this.store.gallery.params = saveParams;
    }


    reloadGallery(newParams = {}) {
        this.resetGallery(newParams);

        this.store.gallery.loading = true;

        this.store.gallery.params.offset = 0;

        this.loadGallery();
    }

    loadGallery() {
        loadGallery(this.galleryId, this.store.gallery.params);
    }

    updateFullscreenView() {

        let index = this.store.gallery.fullScreen.currentIndex;

        if (this.store.gallery.items[index] !== undefined) {
            this.store.gallery.fullScreen.currentItem = this.store.gallery.items[index];
            this.store.gallery.fullScreen.showImageAfter = true;
        }
        else {
            throw new Error("Cannot update current view");
        }
    }

    prevItem() {
        this.store.gallery.fullScreen.currentIndex--;
        this.updateFullscreenView();

        this.calculateFullScreenNextPrevButtons();
    }

    nextItem() {
        this.store.gallery.fullScreen.currentIndex++;
        this.updateFullscreenView();

        this.calculateFullScreenNextPrevButtons();
    }

    calculateFullScreenNextPrevButtons() {
        if (this.store.gallery.totalIsKnown) {
            this.store.gallery.fullScreen.showNext = this.store.gallery.fullScreen.currentIndex < this.store.gallery.totalCount - 1;
        }
        else {
            if (this.store.gallery.fullScreen.currentIndex < this.store.gallery.itemsCount - 1) {
                this.store.gallery.fullScreen.showNext = true;
            }
            else {
                this.store.gallery.fullScreen.showNext = !this.store.gallery.endReached;
            }
        }

        this.store.gallery.fullScreen.showPrev = this.store.gallery.fullScreen.currentIndex > 0;
    }

    nextPage() {
        this.store.gallery.params.offset += this.store.gallery.params.count;
        this.store.gallery.loading = true;
        this.store.gallery.scrollMode = true;

        return this.loadGallery();
    }

    checkIsEndReached() {
        if (this.store.gallery.totalIsKnown) {
            this.store.gallery.endReached = this.store.gallery.totalCount <= this.store.gallery.itemsCount;
        }
    }

    calculateShowMoreButton() {
        if (this.store.gallery.scrollMode) {
            this.store.gallery.showMoreButton = false;
        }
        else {

            if (this.store.gallery.totalIsKnown) {
                this.store.gallery.showMoreButton = this.store.gallery.totalCount > this.store.gallery.itemsCount;
            }
            else {
                this.store.gallery.showMoreButton = !this.store.gallery.endReached;
            }
        }

        return this.store.gallery.showMoreButton;

    }

    getStore() {
        return Object.assign(super.getStore(), {
            galleryId: this.galleryId
        });
    }


    updateFilter() {
        if (!this.store.artists || this.store.artists.length === 0) return;

        let filter = this.store.gallery.params;

        if (filter.artistId) {
            let artist = this.store.artists.find(artist => artist.id === filter.artistId);
            if (!artist) {
                filter.artistId = 0;

                return this.updateFilter();
            }

            this.store.categories = [];
            let categories = {};

            artist.services.forEach(service => {

                if (!service.canHaveImage) {
                    return true;
                }

                let parent = categories[service.parent.id];
                if (parent === undefined) {
                    parent = Object.assign({
                        leafs: []
                    }, service.parent);

                    categories[parent.id] = parent;

                    this.store.categories.push(parent);
                }

                parent.leafs.push(service);
            });

        }
        else {
            this.store.categories = this.store.salonCategories;
        }

        if (filter.categoryId) {
            let category = this.store.categories.find(category => category.id === filter.categoryId);
            if (category) {
                this.store.services = category.leafs;
            }
            else {
                this.store.services = [];
            }
        }
    }
}
