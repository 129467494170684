/**
 * User: Ignat Kryshchyk
 * Username: ihnat
 * Company: U6 SIA
 * Date: 27.09.17
 * Time: 11.53
 */
// let GoogleApiKey = 'AIzaSyA6rPOneRGovpEy8nCBYqkrkh5LDGA2N8w';
import config from "./../config";
import notificationSystem from "./../service/notification-system";

let googleMapsUrl = "https://maps.googleapis.com/maps/api/geocode/json?";
let googleAutocompleteUrl = "https://maps.googleapis.com/maps/api/place/autocomplete/json?";

let GeoCode = {
    GoogleApiKey: config.googleGeocoderApiKey,
    autocompleteOptions: config.autocompleteOptions,
    encodeQueryData: function(data) {
        let ret = [];
        for (let d in data)
            ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
        return ret.join("&");
    },
    getCurrentPosition: function() {
        let errorMessage = "<p class=\"title__middle\">Stay local</p>" +
            `Share your location with Frizo to personalize your search. <br/>` +
            `To do so, turn on Location in browser Settings`;
        /*let warnMessage = '<p class="title__middle">Share you location with us</p>' +
         `So we may personalize you search results.<br/> We won't make it public<br/><br/>` +
         `<span class="glyphicon glyphicon-map-marker" aria-hidden="true"></span>`;*/
        let navigatorErrorMessage = "<p class=\"title__middle\">D’oh!</p>" +
            `Location is not accessible <br/>`;

        return new Promise(function(resolve, reject) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    //console.log(position, "POSITION")
                    resolve(position);
                }, (err) => {
                    notificationSystem.alert(errorMessage);
                    reject(err);
                });
            }
            else {
                notificationSystem.alert(navigatorErrorMessage);
            }
        });
    },
    getLocationFromAddress: function(address) {
        let requestUrl = googleMapsUrl + this.encodeQueryData({ address: address, key: this.GoogleApiKey });
        return fetch(requestUrl)
        .then((response) => response.json())
        .then((data) => {
            let location = null;
            if (data.results.length > 0) {
                location = {
                    lat: data.results[0].geometry.location.lat,
                    lon: data.results[0].geometry.location.lng
                };
            }
            return {
                value: address,
                location: location,
                data: data.results[0]
            };
        });
    },
    getAddressFromLatLng: function(lat, lng) {
        let requestUrl = googleMapsUrl + this.encodeQueryData({
            latlng: `${lat},${lng}`,
            key: this.GoogleApiKey
        });

        return fetch(requestUrl)
        .then((response) => response.json());
    },
    getAutocompleteData(value) {
        let requestUrl = googleAutocompleteUrl + this.encodeQueryData({
            input: value,
            // components: 'country:us',
            language: "en",
            types: ["geocode"],
            key: config.googleApiKey
        });

        //let myHeaders = new Headers();

        let myInit = {
            method: "GET",
            // headers: myHeaders,
            mode: "no-cors",
            cache: "default"
        };
        console.log(requestUrl);
        return fetch(requestUrl, myInit)
        //.then((response) => response.status)
        .then((data) => {
            let places = null;
            console.log(data);
            /*if (data.results.length > 0) {
             places = data.results[0]

             }*/
            return {
                value: places,
                data: data
            };
        });
    }
};

export default GeoCode;
