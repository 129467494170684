/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.3.17
 * Time: 5.50
 */

import React from 'react';
import PropTypes from 'prop-types';
import printAddress from '../../../../helper/print-address';

export default function MySalonDisplayAddressForm(props) {
    let removeButton = null;
    let style = "form-group";

    if (props.removable) {
        removeButton = <div className="input-group-btn">
            <button onClick={props.onRemove} className="btn fr-btn-address-remove" disabled={props.locked}>Delete</button>
        </div>;
        style = "input-group fr-btn-with-input";
    }
   else if (!props.readOnly) {
        removeButton = <div className="input-group-btn">
            <button onClick={props.onUpdate} className="btn fr-btn-update address" disabled={props.locked}>Update</button>
        </div>;
        style ="input-group fr-btn-with-input";
    }

    let addressString = printAddress(props.address);

    return <div>
        <div className={style}>
            <input type="text" className="form-control" value={addressString} disabled={true} />
            {removeButton}
        </div>
    </div>;
}

MySalonDisplayAddressForm.propTypes = {
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func,
    removable: PropTypes.bool,
    readOnly: PropTypes.bool,
    locked: PropTypes.bool
};

MySalonDisplayAddressForm.defaultProps = {
    onRemove: () => {
    },
    onUpdate: () => {
    },
    removable: false,
    readOnly: true,
    locked: false
};
