/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 07.09.2017
 * Time: 17:42
 */
import React, {Component} from 'react';
import {Link} from 'react-router';
import {Row} from 'react-bootstrap';
import gradientFill from '../../helper/gradient-fill';
import imgSrc1 from './../../../../img/client-landing-1.jpg';
import imgSrc2 from './../../../../img/client-landing-2.jpg';
import imgSrc3 from './../../../../img/client-landing-3.jpg';
import imgSrc4 from './../../../../img/client-landing-4.jpg';

export default class ClientLandingPage extends Component {

    render() {

        return (
            <div className="fr-banner-top__margin--mobile">
                <div className="fr-banner-top fr-full-width" style={{'background': gradientFill()}}>
                    <Row className="fr-container-width fr-text-center">
                        <div className="fr-banner-top__text--content">
                            <h1>How it works</h1>
                        </div>
                    </Row>
                </div>
                <div className="fr-landing-flex">
                    <div className="fr-landing-flex__item">
                        <div className="">
                            <div className="fr-landing-flex__item-title">Turn likes and&nbsp;followers into&nbsp;real
                                clients
                            </div>
                            Our focus is your local potential clients.<br/>
                            And we know how to reach them.
                        </div>
                        <div className="fr-mt">
                            By the way, our service is free until you meet your first client on Frizo
                        </div>
                        <div className="fr-title-centred">
                            <Link to={"/pro-sign-up"} className="btn fr-btn-big__green">List your business</Link>
                        </div>
                    </div>
                    <div className="fr-landing-flex__item">
                        <img src={imgSrc1} alt="Turn Insta-fans into new clients"/>
                    </div>

                    <div className="fr-landing-flex__item ">
                        <img src={imgSrc2} alt="Get real bookings 24/7"/>
                    </div>
                    <div className="fr-landing-flex__item ">
                        <div className="">
                            <div className="fr-landing-flex__item-title">Get real appointments 24/7</div>
                            Each appointment's secured by client's credit card to provide you maximum comfort
                        </div>
                        <div className="fr-title-centred">
                            <Link to={"/pro-sign-up"} className="btn fr-btn-big__green">List your business</Link>
                        </div>
                    </div>
                    <div className="fr-landing-flex__item">
                        <div className="">
                            <div className="fr-landing-flex__item-title">Let your work say your name</div>
                            Place your images next to your name on Frizo.<br/>
                            We will amplify your online presence
                        </div>
                        <div className="fr-title-centred">
                            <Link to={"/pro-sign-up"} className="btn fr-btn-big__green">List your business</Link>
                        </div>
                    </div>
                    <div className="fr-landing-flex__item">
                        <img src={imgSrc3} alt="Flex your style skills"/>
                    </div>

                    <div className="fr-landing-flex__item">
                        <img src={imgSrc4} alt="Process payments on-the-go"/>
                    </div>
                    <div className="fr-landing-flex__item">
                        <div className="">
                            <div className="fr-landing-flex__item-title">Process payments securely</div>
                            Be it in your beauty chair or on location, get paid securely.<br/>
                            Your clients can tip through the app as well
                        </div>
                        <div className="fr-title-centred">
                            <Link to={"/pro-sign-up"} className="btn fr-btn-big__green">List your business</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}