/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 28.12.17
 * Time: 15.19
 *
 * @flow
 */

import { Request } from "./request";
import {
    HTTP_POST,
    HTTP_PUT,
    MODE_SIMULTANEOUS,
    MODE_TRANSACTIONAL,
    REQUEST_STATE_READY
} from "../../frizo-shared-constants";
import { RequestSetBuilder } from "./request-set-builder";
import hashUrlencode from "../hash-urlencode";

export class RequestBuilder {

    requestSetBuilder: RequestSetBuilder;
    request: Request;

    constructor(requestSetBuilder: RequestSetBuilder, url: string, method: string, requestId: string = "none") {
        this.requestSetBuilder = requestSetBuilder;
        this.request = new Request(url, method, requestId);
    }


    contentTypeIsSet(): boolean {
        return !!this.request.getRequestHeaders().find(header => header.name === "Content-Type");
    }

    contentType(mimeType: string): RequestBuilder {

        this.request.header("Content-Type", mimeType);

        return this;
    }

    typeJSON(): this {
        this.contentType("application/json; charset=UTF-8");

        return this;
    }

    typeFormUrlEncoded(): RequestBuilder {
        this.contentType("application/x-www-form-urlencoded; charset=UTF-8");

        return this;
    }

    alterWith(fn: Function, ...params: any) {
        fn(this, ...params);

        return this;
    }

    body(body: string | FormData): RequestBuilder {
        this.ensureMethod(HTTP_POST, HTTP_PUT);

        this.request.setRequestBody(body);

        return this;
    }

    json(data: {} | Array<{}>): RequestBuilder {
        this.body(JSON.stringify(data));

        if (!this.contentTypeIsSet()) this.typeJSON();

        return this;
    }

    data(data: {} | Array<{}>): RequestBuilder {
        let encodedData = hashUrlencode(data);

        if (this.request.isGet() || this.request.isDelete()) {
            let url = this.request.getUrl();

            if (encodedData.length > 0) {
                if (~url.indexOf("?")) {
                    this.request.setUrl(url + "&" + encodedData);
                }
                else {
                    this.request.setUrl(url + "?" + encodedData);
                }
            }
        }
        else {
            if (!this.contentTypeIsSet()) this.typeFormUrlEncoded();
            this.request.setRequestBody(encodedData);
        }

        return this;
    }

    formData(formData: FormData): RequestBuilder {
        this.body(formData);

        if (!this.contentTypeIsSet()) this.typeFormUrlEncoded();

        if (~[MODE_SIMULTANEOUS, MODE_TRANSACTIONAL].indexOf(this.requestSetBuilder.getMode())) {
            throw new Error("Configuration error. ApiClient2 didn't supports FormData with Relay requests");
        }


        return this;
    }

    header(name: string, value: string): RequestBuilder {
        this.request.header(name, value);

        return this;
    }

    headers(headerSet: Array<{ name: string, value: string }>): RequestBuilder {
        headerSet.forEach(({ name, value }) => {
            this.request.header(name, value);
        });

        return this;
    }

    end(): RequestSetBuilder {
        this.request.setState(REQUEST_STATE_READY);
        this.requestSetBuilder.add(this.request);

        return this.requestSetBuilder;
    }

    ensureMethod(...method: Array<string>) {
        let requestMethod = this.request.getMethod();
        if (~method.indexOf(requestMethod)) {
            return true;
        }

        throw new Error(`This action is incompatible with "${requestMethod}" request.` +
            `${method.join(", ")} required`);
    }
}
