/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.3.17
 * Time: 7.07
 */

import React, {Component} from 'react';
import {Button, Row, Col} from 'react-bootstrap';
import DropCrop from './../../../elements/drop-crop';
import GallerySelect from './../../gallery-select/gallery-select';
import {
    profileGalleryStore,
    sessionStore,
    servicesStore,
    brandSelectStore
} from './../../../stores';
import Validator from '../../../validation/validator';
import Violation from '../../../../app/components/helper-components/violation';

import {
    loadGallerySalonInfo,
    createNewGalleryItem
} from './../../../ac/profile/professional/professional-profile-gallery-actions';
import {
    MODE_ARTIST_SELECTABLE,
    MODE_ARTIST_LOCKED,
    MODE_IMAGE_SINGLE,
    MODE_IMAGE_DOUBLE, LIMIT_ADD_GALLERY_IMAGE
} from './../../../frizo-shared-constants';

import {Link, withRouter, browserHistory} from 'react-router';

import notificationSystem from './../../../service/notification-system';
import {notifyFail} from "../../../ac/notify-actions";
import {updateUploadedGalleryItemsCountAction} from "../../../ac/profile/professional/professional-profile-gallery-actions";
import LoaderBar from "../../../elements/loader-bar";

class ProfessionalProfileGalleryAdd extends Component {
    constructor(props, context) {
        super(props, context);

        let user = sessionStore.getSession();
        let mode = MODE_ARTIST_SELECTABLE;
        let formData = {};
        let imageMode = props.location.pathname === "/my-salon/gallery/add-single-image" ? MODE_IMAGE_SINGLE : MODE_IMAGE_DOUBLE;


        if (user && user.master) {
            formData = Object.assign(formData, {artistId: user.id});
            mode = MODE_ARTIST_LOCKED;
        }

        this.getGalleryItemsCount = this.getGalleryItemsCount.bind(this);

        this.state = {
            galleryItemsCount: this.getGalleryItemsCount(),
            granted: this.canAddImages(),
            isBusinessAccount: user.business,
            salonInfo: {},
            formData: formData,
            imageId: 0,
            imageBeforeId: 0,
            userId: 0,
            mode: mode,
            formIsValid: false,
            violations: {},
            imageMode: imageMode,
            btnDisabled: false,
            brandError: false
        };

        this.onImageChange = this.onImageChange.bind(this);
        this.onTagChange = this.onTagChange.bind(this);
        this.onSalonInfoChange = this.onSalonInfoChange.bind(this);
        this.onSessionStoreChange = this.onSessionStoreChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onImageBeforeChange = this.onImageBeforeChange.bind(this);
    }

    componentDidMount() {
        profileGalleryStore.addChangeListener(this.onSalonInfoChange);
        sessionStore.addChangeListener(this.onSessionStoreChange);
        loadGallerySalonInfo(true);
        updateUploadedGalleryItemsCountAction();
    }

    componentWillUnmount() {
        profileGalleryStore.removeChangeListener(this.onSalonInfoChange);
        sessionStore.removeChangeListener(this.onSessionStoreChange);
    }

    getGalleryItemsCount()  {
        return sessionStore.getStore().salon.uploadedGalleryItemsCount;
    }

    onImageChange(imgData) {
        this.setState({
            imageId: imgData.imageId
        })
    }

    onImageBeforeChange(imgData) {
        this.setState({
            imageBeforeId: imgData.imageId
        })
    }

    onTagChange(obj) {
        let formData = this.state.formData;
        for (let prop in obj) {
            formData[prop] = obj[prop];
        }

        this.setState({
            formData: formData
        });
    }


    canAddImages()  {
        let subscriptionTier = sessionStore.getSession().subscriptionTier;

        let granted = true;
        if (subscriptionTier && Array.isArray(subscriptionTier.limits)) {
            granted = !~subscriptionTier.limits.indexOf(LIMIT_ADD_GALLERY_IMAGE);
        }

        return granted;
    }

    onSessionStoreChange()  {
        this.setState({
            granted: this.canAddImages(),
            galleryItemsCount: this.getGalleryItemsCount()
        });
    }

    onSalonInfoChange() {
        this.setState({
            salonInfo: profileGalleryStore.getSalonInfo(),
        });
    }

    onSave() {
        const validator = new Validator();

        validator.addConstraint('image', 'image');
        //FIXME: Add  constrains  for each formData field separately, not for complete object
        validator.addConstraint('formData', 'gallery-form', {servicesStore: servicesStore});

        let violations = validator.validate({
            image: this.state.imageId,
            formData: this.state.formData
        });

        let brandError = !brandSelectStore.getBrandId() && brandSelectStore.getSearchValue() !== '';

        this.setState({
            formIsValid: violations === true,
            violations: violations === true ? {} : violations,
            btnDisabled: violations === true && !brandError,
            brandError: brandError
        });

        if (violations === true && !brandError) {
            let {id: userId, firstName} = sessionStore.getSession();
            let patch = Object.assign({}, this.state.formData, {
                userId,
                imageId: this.state.imageId,
                imageBeforeId: this.state.imageBeforeId > 0 ? this.state.imageBeforeId : ''
            });


            createNewGalleryItem(patch).then(() => {
                console.log('this.state.galleryItemsCount', this.state.galleryItemsCount);
                //show popup at first
                if (this.state.galleryItemsCount === 0) {
                    let s = [
                        `<p class="title">Good job, ${firstName}!</p>`,
                        '<p class="title__p">To ensure quality,<br />',
                        'we review all images.<br />',
                        'Once done, we shall notify you by e-mail.</p>',
                        'In the meantime, continue<br />',
                        'uploading your best work imagery.',
                    ];

                    notificationSystem.alert(s.join(''), 'OKEY-DOKEY');
                }

                browserHistory.push('/my-salon/gallery');
            }, () => {

                notifyFail('Facing problem adding gallery item');

                this.setState({
                    btnDisabled: false
                });
            })
        }
    }

    render() {
        if (!Object.keys(this.state.salonInfo).length) {

            return <LoaderBar/>;
        }
        let addMessage = this.state.imageMode === MODE_IMAGE_DOUBLE ? 'Add Before & After image' : 'Add handiwork image';

        if (!this.state.granted) {
            if (this.state.isBusinessAccount) {
                return <div>
                    <b>Subscription request.</b>
                    <p>Quality images is your best promotion.</p>
                    <p>More images, better results.<br />Upload more images?</p>

                    <Link
                        className="fr-mr fr-gallery-btn-add btn"
                        to="/subscription"
                    >
                        SUBSCRIBE NOW
                    </Link>

                </div>;
            }
            else {
                return <div>
                    <b>Subscription required.</b>
                    <p>Please ask your salon owner to subscribe.</p>
                </div>;
            }

        }

        if (this.state.salonInfo.isServicePaused) {
            return <div>
                <div>Service paused. Please subscribe.</div>
                <div>
                    <Link className="fr-btn-add btn fr-btn" to="/my-salon/subscription">
                        Proceed
                    </Link>
                </div>
            </div>;
        }

        return (
            <Row>
                <div className="fr-gallery-form-title">
                    <Link to="/my-salon/gallery" className="fr-glyphicon">
                        <span className="glyphicon glyphicon-circle-arrow-left"></span>
                    </Link>
                    <div className="fr-profile__title" style={{display: 'inline-block'}}>{addMessage}</div>

                </div>
                {this.state.imageMode === MODE_IMAGE_DOUBLE && <Col md={4} className="fr-mb4-xs">
                    <DropCrop
                        imgType={'gallery'}
                        style={{width: '100%', height: 400}}
                        uploadText={"Tap to upload BEFORE image"}
                        onImageChange={this.onImageBeforeChange}/>
                    <Violation className="fr-violation-gallery-image" violationSet={this.state.violations.image}
                               visible={!this.state.formIsValid}/>
                </Col>}
                <Col md={4}>
                    <DropCrop
                        imgType={'gallery'}
                        style={{width: '100%', height: 400}}
                        uploadText={this.state.imageMode === MODE_IMAGE_DOUBLE ? 'Tap to upload AFTER image' : ''}
                        onImageChange={this.onImageChange}/>
                    <Violation className="fr-violation-gallery-image" violationSet={this.state.violations.image}
                               visible={!this.state.formIsValid}/>
                </Col>

                <Col md={4} style={{display: !this.state.imageId ? 'none' : ''}}>
                    <GallerySelect
                        onChange={this.onTagChange}
                        salonId={this.state.salonInfo.id}
                        {...this.state.formData}
                        mode={this.state.mode}
                    />
                    <Violation className="fr-violation-gallery-select" violationSet={this.state.violations.formData}
                               visible={!this.state.formIsValid}/>
                    {this.state.brandError && <li className="fr-error fr-alert-msg">Brand not selected</li>}
                    <Button className="fr-btn fr-fr fr-big-button" onClick={this.onSave}
                            style={{display: !this.state.imageId ? 'none' : ''}}
                            disabled={this.state.btnDisabled}>Save</Button>
                </Col>
            </Row>
        )

    }
}

export default withRouter(ProfessionalProfileGalleryAdd);
