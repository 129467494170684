/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 16.3.17
 * Time: 11.59
 */

import React, {Component} from 'react';
import DashboardFilter from './../dashboard-filter';
import {dashboardGalleryStore, sessionStore} from "../../stores/index";
import {loadDashboardGallery} from "../../ac/gallery/gallery-actions";
import {createGallery} from "../gallery2/gallery-factory";
import {loadAllFavorites} from "../../ac/favorites/common-ac";
import DashboardGalleryItemLook from "../gallery2/items/dashboard-gallery-item-look";
import {
    GALLERY2_DASHBOARD_GALLERY_ID,
    GALLERY_ITEM_TYPE_AVATAR, GALLERY_ITEM_TYPE_LOOK,
    GALLERY_ITEM_TYPE_SEPARATOR
} from "../../frizo-shared-constants";
import DashboardGallerySeparator from "./components/dashboard-gallery-separator";
import DashboardGalleryItemAvatar from "../gallery2/items/dashboard-gallery-item-avatar";
import {enterGalleryParams, resetGallery} from "../../ac/gallery/generic-gallery-actions";
import DashboardGalleryFullscreenLook from './../gallery2/fullscreen-items/dashboard-gallery-fullscreen-look';
import DashboardGalleryFullscreenAvatar from "../gallery2/fullscreen-items/dashboard-gallery-fullscreen-avatar";
import SmartBanner from "../../elements/smart-banner";
import aptInformer from "../../service/apt-informer";
import config from '../../config';


export default class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            timerId: null,
            informerEnabled: config.apt_informer || false,
            params: {
                //load default params from store
            }
        }, this.getStoreData());

        this.storeUpdate = this.storeUpdate.bind(this);
        this.sessionUpdate = this.sessionUpdate.bind(this);

        this.gallery = this.createGallery();
        this.showInformer = this.showInformer.bind(this);
        this.stopInformer = this.stopInformer.bind(this);
        this.randomTimer = this.randomTimer.bind(this);
    }


    componentDidMount() {
        sessionStore.getReadyPromise().then(store => {
            if (!!store.session.isLogged) {
                loadAllFavorites();
            }
        });

        dashboardGalleryStore.addChangeListener(this.storeUpdate);
        sessionStore.addChangeListener(this.sessionUpdate);

        resetGallery(GALLERY2_DASHBOARD_GALLERY_ID);

        this.gallery.init();
        if (this.state.informerEnabled) {
            this.showInformer();
        }

    }

    componentWillUnmount() {
        dashboardGalleryStore.removeChangeListener(this.storeUpdate);
        sessionStore.removeChangeListener(this.sessionUpdate);
        this.stopInformer();
        resetGallery(GALLERY2_DASHBOARD_GALLERY_ID);
    }

    sessionUpdate() {
        this.setState(this.getStoreData());
    }

    storeUpdate() {
        this.setState(this.getStoreData());
        if (this.state.session.isLogged) {
            this.stopInformer();
        }
    }

    getStoreData() {
        let session = sessionStore.getStore().session;

        return Object.assign(dashboardGalleryStore.getStore().gallery, {
            session: {
                isLogged: session.isLogged
            }
        });
    }

    createGallery() {

        let itemClassMap = {
            [GALLERY_ITEM_TYPE_LOOK]: DashboardGalleryItemLook,
            [GALLERY_ITEM_TYPE_SEPARATOR]: DashboardGallerySeparator,
            [GALLERY_ITEM_TYPE_AVATAR]: DashboardGalleryItemAvatar
        };

        let fullscreenClassMap = {
            [GALLERY_ITEM_TYPE_LOOK]: DashboardGalleryFullscreenLook,
            [GALLERY_ITEM_TYPE_AVATAR]: DashboardGalleryFullscreenAvatar
        };

        return createGallery(GALLERY2_DASHBOARD_GALLERY_ID)
            .setGalleryStore(dashboardGalleryStore)
            .setFavoritesEnabled(true)
            .setSessionStore(sessionStore)
            .setItemClassResolver(item => itemClassMap[item.galleryItemType])
            .setFullscreenClassResolver(item => fullscreenClassMap[item.galleryItemType])
            .setGalleryLoader(loadDashboardGallery);
    }

    componentDidUpdate() {
        if (this.state.informerEnabled && !this.state.session.isLogged) {
            if (!this.state.timerId) {
                this.showInformer();
            }
        }
    }

    showInformer() {
        if (!this.state.session.isLogged) {
            if (!this.state.timerId) {
                let timerId = setTimeout(this.randomTimer, Math.floor((Math.random() * 15)) * 1000 + 6000);
                this.setState({timerId: timerId});
            }
        }
    }

    randomTimer() {
        aptInformer();
        clearTimeout(this.state.timerId);
        let timerId = setTimeout(this.randomTimer, Math.floor((Math.random() * 15)) * 1000 + 6000);
        //console.log("START", timerId );
        this.setState({timerId: timerId});
    }

    stopInformer() {
        //console.log("STOP", this.state.timerId)
        if (this.state.timerId) {
            window.clearTimeout(this.state.timerId);
        }
        this.setState({timerId: null});
    }

    render() {

        return <div>
            <DashboardFilter onChange={enterGalleryParams.bind(this, GALLERY2_DASHBOARD_GALLERY_ID)}
                             defaultParams={this.state.params}/>
            <div className="fr-gallery-main">{this.gallery.getGallery()}</div>
            {!this.state.session.isLogged && <SmartBanner/>}
        </div>;
    }
}

/**
 * Use manual loading instead, to reduce extra data loading with 'base' group

 Dashboard.requireData = ['gallery-favorite', "artist-favorite"];

 */