/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 20.9.17
 */

import { xhrGetCalendarMarkers } from "./../xhr/calendar-markers-xhr";
import { CALENDAR_BOUND_CHANGES, CALENDAR_MARKERS_LOADED } from "./../constants";
import AppDispatcher from "../dispatcher";

export function reloadMarkers(fromDate, toDate) {
    xhrGetCalendarMarkers(fromDate, toDate).then(response => {
        AppDispatcher.dispatch({
            type: CALENDAR_MARKERS_LOADED,
            payload: response
        });
    });
}

export function changeCalendarBounds(fromDate, toDate, forceMarkersReload = false) {
    AppDispatcher.dispatch({
        type: CALENDAR_BOUND_CHANGES,
        payload: {
            fromDate,
            toDate,
            forceMarkersReload
        }
    });
}
