/**
 * Created by Alexey Ilasov on 30.01.2017.
 */
import React, {Component} from 'react';

export default  class BookImage extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            showImage: false
        };
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow() {
        this.setState({
            showImage: !this.state.showImage
        });
    }

    createView(){
       if (this.state.showImage){
           return (<div>
               <button className={"btn-link"} onClick={this.toggleShow}>Hide Image</button>
               <img  src={this.props.imageURL} alt="" />
           </div>);
       }

        return <div> <button className={"btn-link"} onClick={this.toggleShow}>Show Image</button></div>
    }

    render() {

        if (this.props.imageURL && this.props.imageId) {
            return this.createView();
        }
        return null;
    }
}

BookImage.defaultProps ={
    imageURL: '',
    imageId: 0
};