/**
 * User: Ignat Kryshchyk
 * Username: ignat
 * Company: U6 SIA
 * Date: 18.4.17
 * Time: 17.22
 */

import React, {Component} from 'react';
import DropdownWrapper from './../../elements/dropdown-wrapper';
import {Row, ControlLabel, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import BrandSelectionMenu from "../profile/professional/brands/brand-selection-menu";

export default class GallerySelectForm extends Component {

    constructor(props) {
        super(props);

        this.onArtistChange = this.onArtistChange.bind(this);
        this.onServiceChange = this.onServiceChange.bind(this);

        this.createServicesSelectors = this.createServicesSelectors.bind(this);
        this.createBrandSelector = this.createBrandSelector.bind(this);
        this.onBrandSelect = this.onBrandSelect.bind(this);
    }

    onArtistChange(id) {
        this.props.onArtistChange(Number(id));
    }

    onServiceChange(id) {
        this.props.onServiceChange(Number(id));
    }

    onBrandSelect(brand) {
        this.props.onBrandSelect(brand);
    }

    createArtistSelect() {

        return (<div className="control-group form-inline ">
            <ControlLabel>{"Artist"}</ControlLabel>
            <DropdownWrapper
                className={"dropdown-gallery"}
                placeholder={"Select artist"}
                list={this.props.artists}
                selected={this.props.artistId}
                onChange={this.onArtistChange}
            />

        </div>);
    }

    createBrandSelector() {

        return (<div className="control-group form-inline ">
            <ControlLabel>{"Main brand"}</ControlLabel>
            <BrandSelectionMenu  selectedBrand={this.props.brand}
                            onSelect={this.onBrandSelect}/>


        </div>);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.artists
            && nextProps.artists.length
            && nextProps.artists.length === 1
            && !nextProps.artistId) {

            this.props.onArtistChange(Number(nextProps.artists[0].id));

            return true;
        }

        if (nextProps.groupedServices && nextProps.groupedServices.length) {
            let groupedServicesLength = nextProps.groupedServices.length;
            let lastSelect = nextProps.groupedServices[groupedServicesLength - 1];

            if (lastSelect.options.length === 1 && !lastSelect.selected) {
                this.props.onServiceChange(Number(lastSelect.options[0].id));

                return true;
            }
        }
    }

    createServicesSelectors() {
        if (!this.props.groupedServices.length) {

            return null;
        }


        return this.props.groupedServices.map((item, index) => {

            let label = item.isCategory ? 'Category' : 'Service';
            item.options.forEach(option => {
                option.name = option.i18nKey
            });
            return (<div key={index} className="control-group form-inline">
                <ControlLabel className="">{label}</ControlLabel>
                <DropdownWrapper
                    className={"dropdown-gallery"}
                    placeholder={item.placeholder}
                    list={item.options}
                    selected={item.selected}
                    onChange={this.onServiceChange}
                />
            </div>)
        });
    }


    render() {

        let artistSelector = null;
        if (this.props.showArtistSelector) {
            artistSelector = this.createArtistSelect();
        }

        let serviceSelectors = this.createServicesSelectors();
        let brandSelector = null;
        if (this.props.groupedServices.find(item => item.selected > 0)) {
            brandSelector = this.createBrandSelector();
        }

        return (
            <Row>
                <Form className="col-xs-12 fr-gallery-form-add">
                    {this.props.showArtistSelector}
                    {artistSelector}
                    {serviceSelectors}
                    {brandSelector}
                </Form>
            </Row>
        )
    }
};

GallerySelectForm.propTypes = {
    artistId: PropTypes.number, //controlled value
    artists: PropTypes.array,
    brand: PropTypes.object,
    onBrandSelect: PropTypes.func,
    onServiceChange: PropTypes.func,
    onArtistChange: PropTypes.func,
    groupedServices: PropTypes.array,
    showArtistSelector: PropTypes.bool

};

GallerySelectForm.defaultProps = {
    onServiceChange: () => {
    },
    onArtistChange: () => {
    },
    onBrandSelect: () => {
    },
    groupedServices: [],
    artists: [],
    artistId: 0,
    brand: {},
    showArtistSelector: true
};
