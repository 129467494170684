/**
 * Created by Eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 30-Mar-17
 * Time: 14:44
 */

import React, { Component } from 'react';
import { FormGroup } from 'react-bootstrap';
import Violation from '../helper-components/violation';

class SignUpLoginInfo extends Component {

    constructor(props)  {
        super(props);

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPassChange = this.handleConfirmPassChange.bind(this);
    }


    handleUsernameChange(e) {
        this.props.onChange('userName', e.target.value);
    }

    handlePasswordChange(e) {
        this.props.onChange('password', e.target.value);
    }

    handleConfirmPassChange(e) {
        this.props.onChange('confirmPassword', e.target.value);
    }


    render() {
        return (
            <div>
                <h2>Login info</h2>
                <FormGroup>
                    <div className="col-sm-12">
                        <input type="email" name="userName" required="required" id="userName"
                               className="form-control" placeholder="E-mail" onChange={this.handleUsernameChange}/>
                        <Violation violationSet={this.props.violations.userName} visible={!this.props.formIsValid} />
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className="col-sm-6">
                        <input type="password" name="password" required="required" id="password"
                               className="form-control" placeholder="Create a password" onChange={this.handlePasswordChange}/>
                        <Violation violationSet={this.props.violations.password} visible={!this.props.formIsValid} />
                    </div>
                    <div className="col-sm-6">
                        <input type="password" name="confirm_password" required="required" id="confirmPassword"
                               className="form-control" placeholder="Confirm your password" onChange={this.handleConfirmPassChange}/>
                        <Violation violationSet={this.props.violations.confirmPassword} visible={!this.props.formIsValid} />
                    </div>
                </FormGroup>
            </div>
        );
    }
}

export default SignUpLoginInfo;
