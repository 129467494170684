/**
 * Created by Alexey Ilasov on 31.01.2017.
 */

import ApiClient from "./../service/api-client";
import moment from "moment";
import { APPOINTMENT_STATUS_CANCEL, APPOINTMENT_STATUS_NO_SHOW } from "./../frizo-shared-constants";

export default function xhrLoadAppointments({ mode, salonClientId, date, addressId, artistId }) {

    let bookDate = moment.tz(date, "UTC");
    let from = bookDate.startOf("day").unix();

    let url = ApiClient.getRouting().generate(("Q96_get_daily_apts_for_salon_client"), {
        salonClient: salonClientId,
        midnightTimestamp: from
    });

    return ApiClient.get(url)
    .then((answer) => {
        let [response] = answer;

        return Promise.resolve({
            appointments: response
        });
    });
};

export const xhrCancelAppointment = function(aptId) {

    return ApiClient.post(["Q80_change_appointment_status"], {
        appointmentList: aptId,
        statusCode: APPOINTMENT_STATUS_CANCEL
    }).then(answer => {
        return answer;
    });
};

export const xhrNoShowAppointment = function(aptId) {

    return ApiClient.post(["Q80_change_appointment_status"], {
        appointmentList: aptId,
        statusCode: APPOINTMENT_STATUS_NO_SHOW
    }).then(answer => {
        return answer;
    });
};

/**
 *
 * @param salonClientId
 * @param localDate date as a string Y-m-d
 * @param addressId
 */
export const xhrClientAptsBatchCancel = function(salonClientId, localDate, addressId) {
    return ApiClient.post(["Q123_client_apts_batch_cancel"], JSON.stringify({
        salonClientId, localDate, addressId
    })).then(answer => {
        return answer[0];
    });
};

export const xhrLoadSalonClientList = function() {

    return ApiClient.get(["Q36_2_salon_clients_paginated"]).then(answer => answer[0].data);
};
