/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.11.17
 * Time: 4.45
 */

import type { TCoor, TGmapCoor } from "../types";

export function normalizeCoor(coor: TGmapCoor): TCoor {
    if (!coor.lng) {
        console.warn("Possible misuse of coor formats", coor);
    }

    return {
        lat: coor.lat,
        lon: coor.lng
    };
}

export function denormalizeCoor(coor: TCoor): TGmapCoor {
    if (!coor.lon) {
        console.warn("Possible misuse of coor formats", coor);
    }

    return {
        lat: coor.lat,
        lng: coor.lon
    };
}
