/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.3.17
 * Time: 16.25
 */

import React from 'react'
import {Nav} from 'react-bootstrap';
import strtr from './../../helper/strtr';
import PropTypes from 'prop-types';

import createReactClass from 'create-react-class';

export default function routedTabCreator(route, className, banner, ...navsArray) {
    return createReactClass({
        displayName: 'RoutedTabWrapper',

        contextTypes: {
            router: PropTypes.object.isRequired
        },

        getInitialState: function () {

            let tabRoute = this.props.routes[this.props.routes.length - 1];

            return {
                tab: tabRoute.tab,
                mobileOpen: false
            };
        },

        componentWillReceiveProps: function (nextProps) {
            let tabRoute = nextProps.routes[nextProps.routes.length - 1];

            this.setState({
                tab: tabRoute.tab
            })
        },

        handleSelect: function (tab) {
            let nextTabURL = strtr(route, {
                ":tab": tab
            });

            let router = this.props.router || this.context.router;
            router.push(nextTabURL)
            this.setState({
                mobileOpen: false
            })
        },

        handleNavTabsDropdown: function () {
            let navbar = this.refs.navbarCollapse;
            if (navbar.classList.contains('open')) {

                navbar.classList.remove('open');
                navbar.classList.add('opened');
                this.setState({
                        mobileOpen: !this.state.mobileOpen
                    }
                )
            }
            else {
                navbar.classList.remove('opened');
                navbar.classList.add('open');
                this.setState({
                        mobileOpen: !this.state.mobileOpen
                    }
                )
            }
        },
        getTabTitle: function (tab) {

            let child = navsArray.filter(function (obj) {
                return obj.props.eventKey === tab;
            });
            return child[0].props.children;
        },

        render: function render() {

            return <div className={className}>
                {banner}
                <div className="fr-routed-tab">
                    <button className="nav-tabs-dropdown fr-btn-dropdown fr-mt-xs" data-toggle="collapse"
                       ref="navbarCollapse" onClick={this.handleNavTabsDropdown}>{this.getTabTitle(this.state.tab)}</button>
                    <Nav bsStyle="tabs" id="nav-tabs-wrapper"
                         className="fr-mt fr-navbar-tabs nav nav-tabs nav-tabs-horizontal" activeKey={this.state.tab}
                         onSelect={this.handleSelect}
                         style={{display: this.state.mobileOpen ? 'block' : 'none'}}>
                        {navsArray}
                    </Nav>
                </div>
                    <div className='container fr-tab-content'>
                        {this.props.children}
                    </div>

            </div>
        }
    });
}