/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 05.05.2017
 * Time: 16:59
 */
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import RoutedGroup from './../../forms/routed-group';


export default class PersonalFavorites extends Component {

    render() {
        return <div>
            <div className="fr-apt-left-container fr-pb-xs">
                <RoutedGroup className="fr-btn-routed">
                    <Button value="/me/favorites/my-styles">My styles</Button>
                    <Button value="/me/favorites/my-artists">My artists</Button>
                </RoutedGroup>
            </div>
            <div>
                {this.props.children}
            </div>
        </div>
    }
}