/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 04.04.2017
 * Time: 16:58
 */

import AppDispatcher from "../dispatcher";
import { CHANGE_SALON_APPOINTMENT_FILTER, DELETE_SALON_APPOINTMENT, LOAD_SALON_APPOINTMENTS } from "../constants";
import { xhrLoadSalonAppointments } from "../xhr/salon-appointments-xhr";
import { xhrCancelAppointment } from "../xhr/appointments-xhr";

export const loadAppointments = (params) => {
    if (Object.keys(params).length) {
        xhrLoadSalonAppointments(params).then(data => {
            AppDispatcher.dispatch({
                type: LOAD_SALON_APPOINTMENTS,
                payload: {
                    requestData: data.requestData,
                    requestTotalCount: data.requestTotalCount,
                    requestParams: params
                }
            });
        });
    }
};

export const changeAptStatus = (aptId) => {
    xhrCancelAppointment(aptId).then(answer => {
        AppDispatcher.dispatch({
                type: DELETE_SALON_APPOINTMENT,
                payload: aptId
            }
        );
    });
};

export const changeAptFilter = (params) => {
    AppDispatcher.dispatch({
            type: CHANGE_SALON_APPOINTMENT_FILTER,
            payload: params
        }
    );
};