/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 18.04.2018
 * Time: 17:21
 */

import BaseStore from "./base-store";
import {
    DASHBOARD_WHEN_DATE_CHANGE,
    DASHBOARD_WHEN_DATE_RESET,
    DASHBOARD_WHEN_TIME_INTERVAL_CHANGE,
    GALLERY_RESET
} from "../constants";
import convertSecondsToFormattedTime from "./../helper/convert-seconds-to-formatted-time";
import moment from "moment";

export default class DashboardWhenStore extends BaseStore {

    constructor(...args) {
        super(...args);


        this._registerActionSubscription((action) => {
            let { type, payload } = action;

            switch (type) {

                case DASHBOARD_WHEN_TIME_INTERVAL_CHANGE:
                    this.store.timeInterval = payload;

                    break;

                case DASHBOARD_WHEN_DATE_CHANGE:
                    this.store.selectedDate = payload;

                    break;

                case DASHBOARD_WHEN_DATE_RESET:
                case GALLERY_RESET:

                    this.resetStore();

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });

    }

    getTimeInterval() {
        return this.store.timeInterval;
    }

    getDateTimeInterval() {
        let currentInterval = this.getTimeInterval();
        let timeFrom = currentInterval[0];
        let timeTo = currentInterval[1];
        return convertSecondsToFormattedTime(timeFrom) + " - " + convertSecondsToFormattedTime(timeTo);
    }

    getInitialStore() {
        let today = moment();
        return {
            selectedDate: null,
            timeInterval: [0, 86400],
            today: today,
            currentMonth: today.clone(),
            startDate: today.clone().startOf("month"),
            endDate: today.clone().endOf("month")
        };
    }
}
