/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 19.09.2017
 * Time: 14:47
 */
import React, {Component} from 'react';
import {Modal, Button, Form, FormGroup, Row, Col, Label} from 'react-bootstrap';
import {modalHide} from '../../ac/modals';
import {getPromoCoupon, createNewSubscription, changeSubscriptionPlan} from '../../ac/subscription-ac';
import PropTypes from 'prop-types';
import {subscriptionsStore} from "../../stores/index";
import gradientFill from './../../helper/gradient-fill';

export default class SubscriptionApprovalModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            couponId: '',
            coupon: [],
            couponError: false,
            confirmDisabled: false
        };


        this.close = this.close.bind(this);
        this.confirm = this.confirm.bind(this);
        this.applyPromo = this.applyPromo.bind(this);
        this.handleCouponChange = this.handleCouponChange.bind(this);
        this.storeChange = this.storeChange.bind(this);

    }

    componentDidMount() {
        subscriptionsStore.addChangeListener(this.storeChange);
    }


    componentWillUnmount() {
        subscriptionsStore.removeChangeListener(this.storeChange)
    }

    storeChange() {
        this.setState({
            coupon: subscriptionsStore.store.coupon,
            couponError: subscriptionsStore.store.couponError
        })
    }

    applyPromo() {
        if (this.state.couponId !== '') {
            getPromoCoupon(this.state.couponId.trim());
        }
    }

    confirm() {
        this.setState({
            confirmDisabled: true
        });
        let params = {
            planId: this.props.selectedPlan.id
        };

        if (this.state.coupon.id !== undefined) {
            params.couponId = this.state.coupon.id;
        }

        if (this.props.currentPlan.id !== undefined) {
            let planId = this.props.selectedPlan.id;
            changeSubscriptionPlan(planId);
        }
        else {
            createNewSubscription(params);
        }
    }


    close() {
        modalHide();
    }

    handleCouponChange(e) {
        this.setState({
            couponId: e.target.value,
            coupon: [],
            couponError: false
        });
    }

    render() {

        let price = this.props.selectedPlan.amount / 100;
        let percentOff = 0;

        if ((this.state.coupon.id !== undefined && (this.state.couponId !== '')) && this.state.coupon.valid) {
            percentOff = this.state.coupon["percent_off"];
        }


        let discountAmount = Math.round((price * percentOff / 100) * 100 / 100);
        let amount = Math.round(price - discountAmount * 100 / 100);

        return (
            <div>
                <Modal.Header closeButton style={{'background': gradientFill()}}>
                    <h2>Subscription</h2>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col sm={4} xs={4} className="approval-title">
                                    <div>Card</div>
                                </Col>
                                <Col sm={8} xs={8}>
                                    <div className="approval-title pull-right">
                                        {this.props.paymentCard.brand}
                                        <strong>&nbsp;&#8226;&nbsp;</strong>
                                        {this.props.paymentCard.last4}</div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="fr-mt">
                                <Col sm={7} xs={7}>
                                    <div className="approval-title">{this.props.selectedPlan.nickName}</div>
                                </Col>
                                <Col sm={5} xs={5}>
                                    <div className="approval-title approval-title__bold pull-right">
                                        {`$ ${price.toFixed(2)}`}
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup className="promo-code">
                            <Row>
                                <Col sm={6} xs={6} className="input-padding">
                                    <input className=" promo-code__input form-control"
                                           value={this.state.couponId}
                                           onChange={this.handleCouponChange}
                                           placeholder="Promo code"
                                    />
                                </Col>
                                <Col sm={6} xs={6}>
                                    {discountAmount > 0 &&
                                    <div
                                        className="approval-title approval-title__bold pull-right">{` - $ ${discountAmount.toFixed(2)}`}
                                    </div>}
                                </Col>
                                <Row>
                                    <Col sm={8} xs={8}>
                                        <div className="fr-ml">
                                            <Button className="btn fr-btn fr-btn-apply"
                                                    onClick={this.applyPromo}>Apply</Button>
                                        </div>
                                    </Col>

                                </Row>
                            </Row>
                            <Row>
                                <Col sm={12} xs={12}>
                                    {this.state.couponError && <div
                                        className="approval-title approval-title__bold promo-code__error">NOT VALID
                                        PROMO CODE
                                    </div>}
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col sm={7} xs={7} className="approval-title">
                                    <div>Amount due</div>
                                </Col>
                                <Col sm={5} xs={5}>
                                    <div
                                        className="approval-title approval-title__bold pull-right">{`$ ${amount.toFixed(2)}`}
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </Modal.Body>

                <Modal.Footer className="card-add-footer">
                    <div className="pull-left">
                        <Button
                            onClick={this.close
                            }
                            bsStyle="danger"
                            className=""> CANCEL
                        </Button>
                    </div>
                    <div className="pull-right">
                        <Button disabled={this.state.confirmDisabled} onClick={this.confirm} bsStyle="success"
                                className="">CONFIRM</Button>
                    </div>

                    {this.props.violations.length > 0 &&
                    <Label className="fr-span-message" bsStyle="danger"> {this.props.violations}</Label>}
                </Modal.Footer>
            </div>)
    }
}
SubscriptionApprovalModal.propTypes = {
    selectedPlan: PropTypes.object
};

SubscriptionApprovalModal.defaultProps = {
    selectedPlan: [],
    paymentCard: [],
    currentPlan: []
};