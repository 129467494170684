/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.11.17
 * Time: 8.36
 */

import AppDispatcher from "../../dispatcher";
import {
    GALLERY_ADDITIVE_MODE_ENABLE,
    GALLERY_ENTER_PARAMS,
    GALLERY_FULLSCREEN_MODAL,
    GALLERY_FULLSCREEN_NEXT,
    GALLERY_FULLSCREEN_OPEN,
    GALLERY_FULLSCREEN_PREV,
    GALLERY_LOAD,
    GALLERY_NEXT_PAGE,
    GALLERY_PRESET,
    GALLERY_RESET,
    MODAL_CHANGE
} from "../../constants";

let galleryLoaderSet = {};

export function initGallery(galleryId, loader) {
    galleryLoaderSet[galleryId] = loader;
}

export function loadGallery(galleryId, params) {
    let galleryLoader = galleryLoaderSet[galleryId];

    if (typeof galleryLoader !== "function") {
        throw new Error("Invalid gallery loader provided or gallery loader is missing");
    }

    return galleryLoader(params, galleryId).then(response => {

        if (!response) return;
        if (!response.items) return;

        response.galleryId = galleryId;

        AppDispatcher.dispatch({
            type: GALLERY_LOAD,
            payload: response

        });

        return response;
    });
}

export function enterGalleryParams(galleryId, newParams) {

    AppDispatcher.dispatch({
        type: GALLERY_ENTER_PARAMS,
        payload: {
            galleryId, params: newParams
        }
    });

}

export function galleryNextPage(galleryId, step = 1) {
    AppDispatcher.dispatch({
        type: GALLERY_NEXT_PAGE,
        payload: {
            galleryId,
            step
        }
    });
}


export function resetGallery(galleryId) {

    AppDispatcher.dispatch({
        type: GALLERY_RESET,
        payload: {
            galleryId
        }
    });
}

export function presetGallery(galleryId, params) {

    AppDispatcher.dispatch({
        type: GALLERY_PRESET,
        payload: {
            galleryId, params
        }
    });
}

export function openFullScreen({ galleryId, startIndex, store, favoritesEnabled, classResolver }) {
    AppDispatcher.dispatch({
        type: GALLERY_FULLSCREEN_OPEN,
        payload: {
            galleryId,
            startIndex: startIndex
        }
    });

    AppDispatcher.dispatch({
        type: MODAL_CHANGE,
        payload: {
            type: GALLERY_FULLSCREEN_MODAL,
            options: {
                index: startIndex,
                store, favoritesEnabled, classResolver
            }
        }
    });

}

export function galleryFullscreenPrev(galleryId) {
    AppDispatcher.dispatch({
        type: GALLERY_FULLSCREEN_PREV,
        payload: { galleryId }
    });
}

export function galleryFullscreenNext(galleryId) {
    AppDispatcher.dispatch({
        type: GALLERY_FULLSCREEN_NEXT,
        payload: { galleryId }
    });
}

export function galleryAdditiveModeEnable(galleryId) {
    AppDispatcher.dispatch({
        type: GALLERY_ADDITIVE_MODE_ENABLE,
        payload: { galleryId }
    });
}
