/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 3/30/17
 * Time: 16:57
 */

import React, {Component} from 'react';
import {FormGroup} from 'react-bootstrap';
import Violation from '../helper-components/violation';
import {xhrZipResolve} from '../../xhr/my-salon-xhr';
import MaskedInput from 'react-bootstrap-maskedinput';

class SignUpZipCity extends Component {
    constructor(props) {
        super(props);

        this.handleZipChange = this.handleZipChange.bind(this);

        this.state = {
            formIsValid: props.formIsValid,
            violations: props.violations
        };
    }

    handleZipChange(e) {
        this.props.onChange('address', "");
        let rawValue = e.target.value.replace(/\D+/g, '');

        this.props.onChange('zip', rawValue);

        if (rawValue.length === 5) {

            xhrZipResolve(rawValue).then(parsedLocation => {
                    if (parsedLocation) {
                        this.props.onChange('address', parsedLocation.city + ', ' + parsedLocation.state);
                    }
                    else {
                        this.props.onChange('address', "");
                    }
                },

                response => {
                    console.log(response.message);
                });
        }
    }

    render() {
        return (
            <div>
                <FormGroup>
                    <div className="col-sm-4 col-xs-4">
                        <MaskedInput id="zip"
                                     value={this.props.zip}
                                     type={"text"}
                                     name={"zip"}
                                     required={"required"}
                                     className={"form-control"}
                                     placeholder={"ZIP"}
                                     autoComplete={"off"}
                                     mask={"11111"}
                                     maxLength={"5"}
                                     onChange={this.handleZipChange.bind(this)}/>

                        <Violation violationSet={this.props.violations.zip} visible={!this.props.formIsValid}/>
                    </div>
                    <div className="col-sm-8 col-xs-8">
                        <input type="text" name="city"
                               required="required" className="form-control" readOnly="readonly"
                               placeholder="City, State" value={this.props.address}/>
                        <Violation violationSet={this.props.violations.address} visible={!this.props.formIsValid}/>
                    </div>
                </FormGroup>
            </div>
        );
    }
}

export default SignUpZipCity;

SignUpZipCity.defaulProps = {zip: ''};