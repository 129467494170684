/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 12.09.2017
 * Time: 16:21
 */

import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router';
import {modalHide} from "../../ac/modals";

export default class AlertSubscriptionModal extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        setTimeout(() => modalHide(), 0);
    }

    render() {
        let alertMessage = 'To continue using Book please complete subscription process';
        return (
            <div>
                <Modal.Body>
                    <div className="alert-message">
                        <h3>{alertMessage}</h3>
                    </div>
                    <div className="alert-message__link">
                        <Link to="/subscription" className="btn btn-add" onClick={this.handleClick}>Proceed</Link>
                    </div>
                </Modal.Body>
            </div>
        );
    }
}
