/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.01.18
 * Time: 11.24
 */

import React, {
    Component
} from 'react';
import {promoArtistStore, sessionStore} from "../../stores";
import {GALLERY2_SALON_PROMO_ARTISTS_ID} from "../../frizo-shared-constants";
import {createGallery} from "../gallery2/gallery-factory";
import {loadSalonPromoArtists} from "../../ac/salon-promo-action";
import {loadArtistFavorites} from "../../ac/favorites/common-ac";
import SalonPromoArtistAvatar from "../gallery2/items/salon-promo-artist-avatar";
import SalonPromoFullscreenAvatar from "../gallery2/fullscreen-items/salon-promo-fullscreen-avatar";
import GoUpButton from "../../elements/go-up-button";
import LoaderBar from "../../elements/loader-bar";


export default class SalonPromoArtists extends Component {
    constructor(props) {
        super(props);


        this.state = Object.assign({
            ready: false,
            isEmptyGallery: false,
            params: {},
        }, this.getStoreData());


        this.onGalleryStoreChange = this.onGalleryStoreChange.bind(this);

        this.gallery = this.createGallery();

        setTimeout(() => {
            this.gallery.reset();
            this.gallery.init({
                salonUrl: props.params.url,
                tab: this.props.route.tab
            });
        });

    }

    getStoreData()  {
        let storeData = promoArtistStore.getStore().gallery;

        return {
            params: storeData.params,
        }
    }

    componentDidMount() {

        sessionStore.getReadyPromise().then(store => {
            if (!!store.session.isLogged) {
                loadArtistFavorites();
            }
        });

        promoArtistStore.addChangeListener(this.onGalleryStoreChange);
    }

    componentWillUnmount() {
        promoArtistStore.removeChangeListener(this.onGalleryStoreChange);

    }

    createGallery() {

        return createGallery(GALLERY2_SALON_PROMO_ARTISTS_ID)
            .setGalleryStore(promoArtistStore)
            .setFavoritesEnabled(true)
            .setNoItemsText('Yet to post images')
            .setItemClass(SalonPromoArtistAvatar)
            .setFullscreenClass(SalonPromoFullscreenAvatar)
            .setGalleryLoader(loadSalonPromoArtists);
    }



    onGalleryStoreChange() {
        let newState = Object.assign({}, this.getStoreData());

        this.setState(newState);
    }

    render() {
        if (!this.state.params.salonId) {
            return <LoaderBar className={"loader-msg-wrapper--full-height"}/>
        }

        return (
            <div>
                {this.gallery.getGallery()}
                <GoUpButton/>
            </div>
        )

    }
}
