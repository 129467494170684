/**
 * Created by fliak on 12.12.16.
 * Used to avoid circular dependencies
 */

export const JOURNAL_ELEMENT_APPOINTMENT = "appointment";
export const JOURNAL_ELEMENT_BLOCK = "block";

//User Types (Salon Types)
export const USER_TYPE_CLIENT = 0;
export const USER_TYPE_SALON_ADMIN = 1;
export const USER_TYPE_FREELANCER = 2;
export const USER_TYPE_MASTER = 3;
export const USER_TYPE_ADMIN = 4;
export const USER_TYPE_GUEST = 5;
export const USER_TYPE_SALON_MASTER = 6;
export const USER_TYPE_SALON_OWNER = 7;

export const SALON_TYPE_SALON = "salon";
export const SALON_TYPE_FREELANCER = "freelancer";

export const BUSINESS_TYPE_SALON = USER_TYPE_SALON_OWNER;
export const BUSINESS_TYPE_FREELANCER = USER_TYPE_FREELANCER;
export const BUSINESS_TYPE_SALON_ARTIST = USER_TYPE_SALON_MASTER;

export const BOOK_APPOINTMENT_NEW = "BOOK_NEW_APPOINTMENT";
export const BOOK_APPOINTMENT_EDIT = "BOOK_EDIT_APPOINTMENT";

export const MODE_CLIENT = 0;
export const MODE_PROFESSIONAL = 1;

export const AUTH_ERROR_WRONG_CREDENTIALS = "wrong_credentials";
export const AUTH_ERROR_GENERIC_ERROR = "generic_error";

export const LINK_SENT = "link_sent";
export const MESSAGE_SENT = "message_sent";
export const SERVER_ERROR = "server_error";

export const APT_STATUS_NORMAL = 0;
export const APT_STATUS_REMOVED = 1;

export const PAGINATION_ITEMS_COUNT = 20;
export const CTYPE_FORM_URLENCODED = "CTYPE_FORM_URLENCODED";

export const CUSTOM_HEADER_U6_GROUPS = "U6-groups";
export const CUSTOM_HEADER_U6_PAGINATION = "U6-pagination";
export const CUSTOM_HEADER_U6_PLATFORM = "U6-platform";
export const CUSTOM_HEADER_U6_VERSION = "U6-version";
export const CUSTOM_HEADER_U6_AUTH = "U6-Authenticate";

export const MODE_ARTIST_SELECTABLE = "MODE_ARTIST_SELECTABLE";
export const MODE_ARTIST_LOCKED = "MODE_ARTIST_LOCKED";

export const GENDER_NOT_SHARED = 3;
export const GENDER_MALE = 2;
export const GENDER_FEMALE = 1;

export const SLOT_STATE_DEFAULT = "default";
export const SLOT_STATE_WH_SET = "wh";
export const SLOT_STATE_PAST = "past";

export const SCHEDULE_ELEMENT_STATE_UPCOMING = "upcoming";
export const SCHEDULE_ELEMENT_STATE_IN_PROGRESS = "in_progress";
export const SCHEDULE_ELEMENT_STATE_PAST = "past";
export const SCHEDULE_ELEMENT_STATE_SUSPENSE = "suspense";
export const SCHEDULE_ELEMENT_STATE_NO_SHOW = "no_show";

export const WH_START_TIME = 36000;
export const WH_END_TIME = 68400;

export const GALLERY_LAYOUT_GROUPED = "GALLERY_LAYOUT_GROUPED";
export const GALLERY_LAYOUT_SIMPLE = "GALLERY_LAYOUT_SIMPLE";

export const ARTISTS_BY_SALON = "ARTISTS_BY_SALON";
export const IMAGES_BY_ARTIST_BY_SALON = "IMAGES_BY_ARTIST_BY_SALON";


export const FORM_MODE_CREATE = "FORM_MODE_CREATE";
export const FORM_MODE_UPDATE = "FORM_MODE_UPDATE";
export const FORM_MODE_VIEW = "FORM_MODE_VIEW";

export const LEGAL_ENTITY_TYPE_COMPANY = "company";
export const LEGAL_ENTITY_TYPE_INDIVIDUAL = "individual";

export const PAYMENT_TYPE_CASH = "cash";
export const PAYMENT_TYPE_STRIPE = "stripe";


export const PAYMENT_STATUS_NO_STATUS = 0;
export const PAYMENT_STATUS_PENDING = 1;
export const PAYMENT_STATUS_PAID = 2;
export const PAYMENT_STATUS_DECLINED = 3;

export const HRC_FIELD_GENERIC = "hidden_referral_code_generic";

//different fields for HRC storing is not in use since new referral game system with discounts
//@see https://yt.u6.lv/issue/FW-2043
export const HRC_FIELD_PRO = "hidden_referral_code_pro";
export const HRC_FIELD_CLIENT = "hidden_referral_code_client";

export const HRC_REDIRECT_GUESS = "hrc-guess-redirect";
export const HRC_REDIRECT_DISABLED = "hrc-no-redirect";


export const PROFILE_FULLY_INCOMPLETE = 0;
export const PROFILE_INCOMPLETE = 1;
export const PROFILE_COMPLETE = 2;
export const PROFILE_COMPLETION_IRRELEVANT = 3;

export const MODE_PRICING = 0;
export const MODE_SUBSCRIPTION = 1;
export const PRICING_SINGLE_ARTIST = 0;
export const PRICING_MULTIPLE_ARTIST = 1;

export const APPOINTMENT_STATUS_ACTIVE = 0;
export const APPOINTMENT_STATUS_CANCEL = 1;
export const APPOINTMENT_STATUS_NO_SHOW = 2;
export const APPOINTMENT_MISSING_TIME_VALUE = -1;

export const ADD_NEW_OPTION = "add-new";

export const DROPDOWN_PLACEHOLDER_VALUE = -1;

export const SECONDS_IN_15_MINUTES = 900;


export const IMAGE_SIZE_SMALL = "small";
export const IMAGE_SIZE_MEDIUM = "medium";
export const IMAGE_SIZE_LARGE = "large";


export const TIP_CONFIG = [0, 10, 20, 50];
export const DEFAULT_TIP_PERCENT = 20;

export const GALLERY_ITEM_TYPE_LOOK = "look";
export const GALLERY_ITEM_TYPE_AVATAR = "avatar";
export const GALLERY_ITEM_TYPE_BRAND = "brand";
export const GALLERY_ITEM_TYPE_SEPARATOR = "separator";


export const TYPE_GALLERY_IMAGE = "TYPE_GALLERY_IMAGE";
export const TYPE_SALON = "TYPE_SALON";
export const TYPE_USER = "TYPE_USER";
export const TYPE_SALON_CLIENT = "TYPE_SALON_CLIENT";
export const TYPE_SERVICE = "TYPE_SERVICE";
export const TYPE_ADDRESS = "TYPE_ADDRESS";
export const TYPE_APPOINTMENT = "TYPE_APPOINTMENT";
export const TYPE_GENDER = "TYPE_GENDER";

export const GALLERY2_SALON_PROMO_BRANDS_ID = "salon-promo-brands";
export const GALLERY2_SALON_PROMO_ARTISTS_ID = "salon-promo-artists";
export const GALLERY2_SALON_PROMO_GALLERY_ID = "salon-promo-gallery";
export const GALLERY2_PERSONAL_PROFILE_FAVORITES_ITEMS_ID = "personal-profile-favorites-items";
export const GALLERY2_PERSONAL_PROFILE_FAVORITES_ARTISTS_ID = "personal-profile-favorites-artists";
export const GALLERY2_PROFESSIONAL_PROFILE_BRAND_ID = "professional-profile-brand";
export const GALLERY2_PROFESSIONAL_PROFILE_ARTIST_ID = "professional-profile-artist";
export const GALLERY2_PROFESSIONAL_PROFILE_GALLERY_ID = "professional-profile-gallery";
export const GALLERY2_DASHBOARD_GALLERY_ID = "dashboard-gallery";
export const GALLERY2_ARTIST_PROMO_GALLERY_ID = "GALLERY2_ARTIST_PROMO_GALLERY_ID";

export const BUTTON_DEFAULT = "btn-default";
export const BUTTON_DANGER = "btn-danger";
export const BUTTON_DANGER_TRASH = "glyphicon-trash";
export const BUTTON_LINK_PROMO = "fr-card-btn__promo";

export const HTTP_GET = "GET";
export const HTTP_POST = "POST";
export const HTTP_PUT = "PUT";
export const HTTP_DELETE = "DELETE";

export const MODE_ONE_BY_ONE = "ONE_BY_ONE";
export const MODE_SIMULTANEOUS = "SIMULTANEOUS";
export const MODE_TRANSACTIONAL = "TRANSACTIONAL";

export const Q_PROCESSED = 1;
export const Q_MANUAL = 2;

export const E_PRE_PROCESS = "E_PRE_PROCESS";
export const E_POST_PROCESS = "E_POST_PROCESS";

export const XHR_READY_STATE_DONE = 4;

export const REQUEST_STATE_NOT_READY = "not-ready";
export const REQUEST_STATE_READY = "ready";
export const REQUEST_STATE_SCHEDULED = "scheduled";
export const REQUEST_STATE_RUNNING = "running";
export const REQUEST_STATE_DONE = "done";

export const INVITATION_STATUS_SENT = 0;
export const INVITATION_STATUS_ACCEPTED = 1;
export const INVITATION_STATUS_NOT_SENT = 2;

export const ARTIST_FORM_MODE_CREATE = 0;
export const ARTIST_FORM_MODE_EDIT = 1;

export const MODE_IMAGE_SINGLE = "MODE_IMAGE_SINGLE";
export const MODE_IMAGE_DOUBLE = "MODE_IMAGE_DOUBLE";

export const TIME_INTERVAL_BLOCK = 1;
export const TIME_INTERVAL_PROCESSING = 2;
export const TIME_INTERVAL_DURATION = 3;


export const LIMIT_ADD_GALLERY_IMAGE = "LIMIT_ADD_GALLERY_IMAGE";
export const LIMIT_BOOK = "LIMIT_BOOK";
export const LIMIT_APT_LIST = "LIMIT_APT_LIST";