/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 21.03.2017
 * Time: 15:11
 */

import {
    CLIENT_ADDED,
    CLIENT_EDITED,
    CLIENT_FOUND_BY_PHONE_NUMBER,
    CLIENT_NOT_REMOVED,
    CLIENT_REMOVED,
    CLIENT_VISIBILITY_ADDED,
    CLIENT_VISIBILITY_REMOVED,
    LOAD_SALON_CLIENTS,
    SALON_CLIENT_FOUND
} from "../constants";
import AppDispatcher from "../dispatcher";
import {
    xhrAddSalonClient,
    xhrAddSalonClientVisibility,
    xhrEditSalonClient,
    xhrLoadSalonClients,
    xhrRemoveClient,
    xhrRemoveSalonClientVisibility,
    xhrSearchSalonClientByPhone,
    xhrSearchUserClientByPhone
} from "../xhr/salon-clients-xhr";

import { notifyFail, notifySuccess } from "./notify-actions";
import { salonClientNormalize2 } from "./../normal-form/user-derived-forms/salon-client";

export const addClientAction = (clientData) => {
    if (Object.keys(clientData).length) {
        xhrAddSalonClient(clientData).then(answer => {
            AppDispatcher.dispatch({
                type: CLIENT_ADDED,
                payload: answer
            });
        });
    }
};
export const editClientAction = (salonClientId, clientData) => {
    if (Object.keys(clientData).length) {
        xhrEditSalonClient(salonClientId, clientData).then(answer => {
            AppDispatcher.dispatch({
                type: CLIENT_EDITED,
                payload: answer
            });
        });
    }
};
export const deleteClient = (clientID) => {
    xhrRemoveClient(clientID).then(answer => {
        AppDispatcher.dispatch({
                type: CLIENT_REMOVED,
                payload: answer
            }
        );

        notifySuccess("Removed");

    }, answer => {
        AppDispatcher.dispatch({
                type: CLIENT_NOT_REMOVED,
                payload: answer
            }
        );
        notifyFail(answer.message);
    });
};


export function getSalonClients(params) {
    xhrLoadSalonClients(params).then(answer => {
        AppDispatcher.dispatch({
            type: LOAD_SALON_CLIENTS,
            payload: answer
        });
    });
}


export const addClientVisibility = (client) => {
    if (client.id) {
        xhrAddSalonClientVisibility(client.id).then(answer => {
            AppDispatcher.dispatch({
                type: CLIENT_VISIBILITY_ADDED,
                payload: client
            });
        });
    }
};

export const removeClientVisibility = (clientId) => {
    if (clientId) {
        xhrRemoveSalonClientVisibility(clientId).then((answer) => {
            AppDispatcher.dispatch({
                type: CLIENT_VISIBILITY_REMOVED,
                payload: answer
            });
        });
    }
};

export const searchClientAction = (phone) => {
    xhrSearchUserClientByPhone(phone).then(response => {
        if (response.response.count) {
            let data = response.response.data[0];
            AppDispatcher.dispatch({
                type: CLIENT_FOUND_BY_PHONE_NUMBER,
                payload: data
            });
        }
        else {
            xhrSearchSalonClientByPhone(phone).then(response => {
                if (response.response.clientID) {
                    let data = salonClientNormalize2(response.response);
                    AppDispatcher.dispatch({
                        type: SALON_CLIENT_FOUND,
                        payload: data
                    });
                }
            });
        }
    });
};