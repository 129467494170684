/**
 * Created by fliak on 30.1.17.
 */

import {
    BOOK_LOAD_SALON_INFO_SUCCESS,
    BOOK_START_BOOKING,
    LOAD_FILTERS_SERVICES_SUCCESS,
    LOAD_SELECT_SERVICES_SUCCESS,
    LOAD_SERVICES_SUCCESS,
    RAW_SERVICES_LOADED
} from "../constants";
import BaseStore from "./base-store";

const plainToIndex = function(planeArray) {
    let result = {};

    planeArray.forEach(elem => {
        let index = parseInt(elem.id, 10);
        result[index] = elem;
    });

    return result;
};

const objectToPlain = function(obj) {
    return Object.keys(obj).map(key => {
        key = parseInt(key, 10);
        return obj[key];
    });
};

export default class ServicesStore extends BaseStore {

    constructor(...args) {
        super(...args);

        this.store = {
            currentSalonId: null,

            services: [],
            salonCorrections: {},
            artistCorrections: [],

            servicesIndex: {},
            salonServicesIndex: {},
            artistServicesIndex: {},
            artistUserServicesIndex: {}

        };

        this._registerActionSubscription((action) => {
            const { type, payload } = action;

            switch (type) {
                case BOOK_START_BOOKING:
                    this.store.currentSalonId = payload.salonId;
                    this.setReady(false);

                    //FIXME add check- may be you already have artist, so,
                    // you can start load services right now.
                    // Issue for repeated book-apt opening and for integration with scheduler

                    return true;

                case BOOK_LOAD_SALON_INFO_SUCCESS:
                    this.store.currentSalonId = payload.id;
                    break;

                case LOAD_SERVICES_SUCCESS: {

                    this.store.services = payload.services;
                    this.store.artistCorrections = payload.artistsCorrections || [];

                    this.updateServicesIndex();

                    let salonId = this.store.currentSalonId;
                    if (salonId && payload.salonCorrections) {
                        this.store.salonCorrections[salonId] = payload.salonCorrections;
                        this.updateSalonServicesIndex(salonId);
                    }

                    this.updateArtistsServicesIndex();
                    this.setReady(true);

                    break;
                }

                case LOAD_FILTERS_SERVICES_SUCCESS:
                    this.store.currentSalonId = payload.salonId;

                    this.store.services = payload.services;
                    this.updateServicesIndex();

                    this.store.salonCorrections[payload.salonId] = payload.salonCorrections;
                    this.updateSalonServicesIndex(payload.salonId);

                    this.store.artistCorrections = payload.artistsCorrections || [];
                    this.updateArtistsServicesIndex();

                    break;

                case LOAD_SELECT_SERVICES_SUCCESS: {
                    this.store.currentSalonId = payload.salonId;

                    this.store.services = payload.services;
                    this.updateServicesIndex();

                    this.store.salonCorrections[payload.salonId] = payload.salonCorrections;
                    this.updateSalonServicesIndex(payload.salonId);

                    this.store.artistCorrections = payload.artistsCorrections || [];
                    this.updateArtistsServicesIndex();
                    this.updateArtistsUserServicesIndex();
                    this.setReady(true);

                    break;
                }

                case RAW_SERVICES_LOADED: {
                    this.store.services = payload.services;

                    break;
                }

                default:
                    return true;
            }

            this.emitChange();
        });
    }

    /**
     * @protected
     */
    updateServicesIndex() {
        this.store.servicesIndex = {};
        this.store.services.forEach(service => {
            this.store.servicesIndex[service.id] = service;
        });

    }

    /**
     * @protected
     */
    updateSalonServicesIndex(salonId) {
        this.store.salonServicesIndex[salonId] = {};

        this.store.salonCorrections[salonId].forEach(correction => {
            let originService = this.store.servicesIndex[correction.serviceId];
            this.store.salonServicesIndex[salonId][correction.serviceId] = Object.assign({}, originService, correction);
        });
    }

    /**
     *
     * @protected
     */
    updateArtistsServicesIndex() {

        this.store.artistServicesIndex = {};

        this.store.artistCorrections.forEach(correction => {

            if (!this.store.artistServicesIndex[correction.userId]) {
                this.store.artistServicesIndex[correction.userId] = {};
            }

            // let artist = this.stores.artist.getArtistById(correction.userId);
            // let salonId = artist.salonId;
            let salonId = this.store.currentSalonId;
            let baseService;

            if (salonId) {
                baseService = this.store.salonServicesIndex[salonId][correction.serviceId];
            }

            if (!baseService) {
                baseService = this.store.servicesIndex[correction.serviceId]; //take origin
            }

            let patch = {};
            if (correction.price) {
                patch.price = correction.price;
            }

            this.store.artistServicesIndex[correction.userId][correction.serviceId] = Object.assign({},
                baseService,
                patch);
        });
    }

    updateArtistsUserServicesIndex() {

        this.store.artistUserServicesIndex = {};

        this.store.artistCorrections.forEach(correction => {

            if (!this.store.artistUserServicesIndex[correction.userId]) {
                this.store.artistUserServicesIndex[correction.userId] = {};
            }

            let salonId = this.store.currentSalonId;
            let baseService;

            if (salonId) {
                baseService = this.store.salonServicesIndex[salonId][correction.serviceId];
            }

            if (!baseService) {
                baseService = this.store.servicesIndex[correction.serviceId]; //take origin
            }

            this.store.artistUserServicesIndex[correction.userId][correction.id] = Object.assign({}, baseService);
        });

    }

    /**
     *
     * @param artistId
     * @returns {*}
     */
    getArtistServices(artistId) {

        return this.store.artistServicesIndex[artistId] || {};
    }

    getArtistService(artistId, serviceId) {

        return this.getArtistServices(artistId)[parseInt(serviceId, 10)];
    }

    getSalonServices(salonId) {

        return this.store.salonServicesIndex[salonId];
    }

    /**
     *
     * @param serviceId
     * @param planeServicesList
     * @returns {*}
     * @private
     */
    createServiceBranch(serviceId = 0, planeServicesList) {
        let result = [];
        if (serviceId) {
            let service = planeServicesList[serviceId];
            if (!service) {
                console.warn("Service " + serviceId + " is missing!");

                return [];
            }

            let _go = true;

            do {
                result.push(service.id);
                if (!service.parentId) {
                    _go = false;
                } else {
                    service = planeServicesList[service.parentId];
                }
            }
            while (_go);

            return result.reverse();
        }
        result.push(serviceId);
        return result;
    }

    createArtistIndexServices(artistId) {

        let branches = [];

        if (this.store.artistServicesIndex[artistId]) {
            Object.keys(this.store.artistServicesIndex[artistId]).forEach(serviceId => {
                let branch = this.createServiceBranch(serviceId, this.store.servicesIndex);
                branches.push(...branch);
            });
        }

        let servicesIds = branches.filter((v, i, a) => a.indexOf(v) === i);
        let result = servicesIds.map((id) => this.store.servicesIndex[id]);
        result = plainToIndex(result);

        return result;
    }

    createServicesList(artistPlainServices, service) {
        let result = [];
        let parentId = service ? service.parentId : 0;

        artistPlainServices.forEach(elem => {
            if (elem.parentId === parentId) result.push(elem);
        });

        return result;
    }

    prepareServicesData(artistId = 0, serviceId = 0, salonId = 0) {
        let self = this;

        if (artistId) {

            let artistIndexServices = this.createArtistIndexServices(artistId);
            let artistPlaneServices = objectToPlain(artistIndexServices);
            let branch = this.createServiceBranch(serviceId, artistIndexServices);

            if (!branch || branch.length === 0) {
                branch = this.createServiceBranch(0, artistIndexServices);
            }

            //FIXME: Move placeholder to view
            let placeholder = "Select category";

            let selectsData = branch.map((id, index) => {
                if (index > 0) {
                    placeholder = "Select service";
                }

                let service = artistIndexServices[id];
                let options = self.createServicesList(artistPlaneServices, service);
                let data = { options, placeholder };
                data.selected = id;

                data.isCategory = false;
                if (options.length) {
                    data.isCategory = !options[0].durationValue;
                }

                return data;
            });

            if (serviceId) {
                let children = artistPlaneServices.filter(service => service.parentId === serviceId);

                if (children.length > 0) {
                    selectsData.push({
                        options: children,
                        placeholder: placeholder
                    });
                }
            }

            return selectsData;

        }

        return [];
    }

    getServices() {
        return this.store.services;
    }

    /**
     *
     * @param id
     * @returns {*}
     */
    getServiceById(id) {
        return this.store.servicesIndex[id];
    }

    getArtistServicesIndex() {

        return Object.keys(this.store.artistServicesIndex).length ? this.store.artistServicesIndex : {};
    }

    resetStore() {
        this.store = {
            currentSalonId: null,

            services: [],
            salonCorrections: {},
            artistCorrections: [],

            servicesIndex: {},
            salonServicesIndex: {},
            artistServicesIndex: {},
            artistsUserServicesIndex: {}
        };
    }
}
