/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 17.7.17
 */

import React from 'react';
import {FormControl, Col, Row} from 'react-bootstrap';
import {centToUsdInteger} from "../../../helper/money-converter";

export default function ServiceRow(props) {

    return <Row>
        <div>
            <Col md={7} xs={7} >
                {props.service}<br/>
                <div className="services-artist fr-text-overflow" >by {props.artist}</div>
            </Col>
            <Col md={5}  xs={5}>
                <label className="control-label paid-label">&#36;</label>
                <FormControl
                    type="text"
                    className="price-input"
                    placeholder="0"
                    value={centToUsdInteger(props.price)}
                    onChange={props.onChange}
                    disabled={props.disabled}
                />
            </Col>
        </div>
    </Row>;
}

ServiceRow.defaultProps = {
    disabled: false
};