/**
 * Created by eleonora
 * Name: Eleonora Kolodinskaya
 * Company: U6 SIA
 * Date: 4/6/17
 * Time: 19:59
 */

import ApiClient from "../service/api-client";

export const xhrUpdateProfileInfo = function(formdata) {
    return ApiClient.post(["Q84_update_user_profile"], formdata).then(answer => {
        //process successfull answer
        let [response] = answer;

        return response;
    }, answer => {
        //catch server problem

        let [response] = answer;

        //make a reject
        return Promise.reject(response);
    });
};

export const xhrChangeEmail = function(formdata) {
    return ApiClient.post(["Q31_change_email"], formdata).then(answer => {
        //process successfull answer
        let [response] = answer;

        return response;
    }, answer => {
        //catch server problem

        let [response] = answer;

        //make a reject
        return Promise.reject(response);
    });
};

export const xhrChangePassword = function(formdata) {
    return ApiClient.post(["Q21_change_password"], formdata).then(answer => {
        //process successfull answer
        let [response] = answer;

        return response;
    }, answer => {
        //catch server problem

        let [response] = answer;

        //make a reject
        return Promise.reject(response);
    });
};
