/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 22.3.17
 * Time: 5.53
 */

import React, {Component} from 'react';
import BtnToggle from './../../../../components/forms/btn-toggle';
import {ButtonGroup} from 'react-bootstrap';
import Validator from './../../../../validation/validator';
import SignUpZipCity from '../../../sign-up/sign-up-zip-city';
import AddressForm from "../../../sign-up/address-form";

const MODE = "mode";

export default class MySalonAddressForm extends Component {

    constructor(props) {
        super(props);

        this.modeSwitch = this.modeSwitch.bind(this);
        this.onSave = this.onSave.bind(this);
        this.getAddressInput = this.getAddressInput.bind(this);
        this.getZipCity = this.getZipCity.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.onZipChange = this.onZipChange.bind(this);
        this.hideForm = this.hideForm.bind(this);
        this.onCityChange = this.onCityChange.bind(this);

        this.state = {
            formIsValid: true,
            onLocation: this.props.onLocation,
            violations: {}
        };
    }

    modeSwitch() {

        let mode = BtnToggle.getActiveValue(MODE);
        let data = {};
        data.zip = '';
        data.city = '';
        data.onLocation = mode;
        this.setState({onLocation: mode});
        this.props.onChange(data);
    }

    componentDidMount() {
        BtnToggle.addStateChangeListener(MODE, this.modeSwitch);
    }

    componentWillUnmount() {
        BtnToggle.removeStateChangeListener(MODE, this.modeSwitch);
    }

    onSave(e) {
        e.preventDefault();

        let validator = new Validator();

        if (!this.props.showOnLocationSwitcher || !this.props.onLocation) {
            validator.addConstraint('address', 'is-required');
        }

        validator.addConstraint('zip', 'is-required');
        validator.addConstraint('zip', 'is-number');
        validator.addConstraint('zip', 'length', {
            max: 5,
            min: 5
        });

        validator.addConstraint('city', 'is-required');

        let violations = validator.validate({
            address: this.props.address,
            zip: this.props.zip,
            city: this.props.city,
        });

        this.setState({
            formIsValid: violations === true,
            violations: violations === true ? {} : violations
        });

        if (violations === true) {
            this.props.onSave().then(response => {
                //ignore
            }, response => {
                this.setState({
                    violations: response.errors,
                    formIsValid: !response.errors
                })
            });
        }
    }

    onAddressChange(data) {
        this.props.onChange(data);
    }

    onCityChange(data) {
        let address = data;
        address['city'] = data.city;
        this.props.onChange(address);
    }


    onZipChange(field, value) {
        if (field === 'address') {
            field = 'city';
        }
        let data = {};
        data[field] = value;
        this.props.onChange(data);
    }

    getAddressInput() {
        return <div id="address_container">
            <AddressForm
                city={this.props.city}
                address={this.props.address}
                zip={this.props.zip}
                suite={this.props.suite}
                onChange={this.onAddressChange}
                onZipChange={this.onCityChange}
                violations={this.state.violations}
                formIsValid={this.state.formIsValid}
            />
        </div>
    }

    getZipCity() {
        return <SignUpZipCity
            zip={this.props.zip}
            address={this.props.city}
            violations={this.state.violations}
            formIsValid={this.state.formIsValid}
            onChange={this.onZipChange}/>
    }

    hideForm() {
        this.props.onHideForm();
    }

    render() {

        let addressInput = null;
        let header = '';
        let button = 'Update';
        let buttonStyle = "btn fr-btn pull-right";
        let onLocationSwitcher = null;

        if (this.props.showOnLocationSwitcher) {
            header = 'Add address or location';
            button = 'Add';
            buttonStyle = "fr-btn-add btn fr-btn pull-right";

            if (!this.state.onLocation) {
                addressInput = this.getAddressInput();
            }
            else {
                addressInput = this.getZipCity()
            }

            onLocationSwitcher = <div>

                <div className="fr-mb">
                    <ButtonGroup justified>
                        <BtnToggle name={MODE} selected={this.state.onLocation} value={false}>Address</BtnToggle>
                        <BtnToggle name={MODE} selected={this.state.onLocation} value={true}>OnLocation</BtnToggle>
                    </ButtonGroup>
                </div>

            </div>
        }
        else {
            header = 'Update address';
            addressInput = this.getAddressInput();
        }
        return <form name="address_form" method="post" noValidate="noValidate" className="form-horizontal">
            <div className="fr-profile__title">{header}</div>

            {onLocationSwitcher}

            {addressInput}


            <div className="form-group">
                <div className="col-sm-12">
                    <button onClick={this.onSave} className={buttonStyle} type="submit"
                            disabled={this.props.btnUpdateDisabled}>
                        {button}
                    </button>
                    <button onClick={this.hideForm} type="button" className="btn fr-btn fr-btn-remove pull-left">
                        Cancel
                    </button>
                </div>
            </div>

        </form>;
    }
}

MySalonAddressForm.defaultProps = {
    address: '',
    city: '',
    zip: '',
    onLocation: false,
    onSave: () => {
    },
    onChange: () => {
    },
    onHideForm: () => {
    }
};