/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 22.03.2017
 * Time: 14:20
 */

import React, {Component} from 'react'
import ClientStatic from '../clients/client-static-component';
import idGenerator from './../../helper/id-generator-component';


export default class ClientsList extends Component {

    render() {
        let clientsList = this.props.clients;
        let clientItems = [];
        if (clientsList.length) {
            clientItems = clientsList.map((client, index) => {
                return <ClientStatic key={idGenerator('clientList')}
                                     clientID={client.id}
                                     clientFirstName={client.firstName}
                                     clientLastName={client.lastName}
                                     clientPhone={client.phone}
                                     clientEmail={client.email}
                                     clientComment={client.comment}
                                     onEdit={this.props.onEdit}
                />
            });
        }

        return (
            <div>
                <div>
                    {clientItems}
                </div>
            </div>
        )
    }
}
