/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 24.2.17
 * Time: 16.40
 */

import sprintf from "./../../helper/sprintf";

export default class BaseValidator {

    static getValidConstraints() {
        let s = sprintf(
            "Problem with %s validator. Method 'getValidConstraints' should be overriden in child classes",
            this.constructor.name
        );

        throw new Error(s);
    }

    assertConstraintIsAllowed(constraint) {
        let isAllowed = false;
        this.constructor.getValidConstraints().forEach(supportedConstraint => {
            if (constraint instanceof supportedConstraint) {
                isAllowed = true;

                return false;
            }
        });

        if (isAllowed) return true;

        let allowedConstraints = this.getValidConstraints().map(constraint => constraint.constructor.name).join(", ");

        throw new Error(sprintf(
            "%s should be passed to %s.validate(). %s given.",
            allowedConstraints, this.constructor.name, constraint.constructor.name
        ));
    }
}
