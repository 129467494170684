/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.20.4
 * Time: 15:47
 */

import React, {Component} from 'react';
import {Row, Col, FormControl, Button} from 'react-bootstrap';

export default class TableRowArtistEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            individualPrice: this.props.individualPrice,
        };

    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            individualPrice: nextProps.individualPrice
        });
    }

    priceChange(ev) {
        let individualPrice = this.modifyPrice(ev.target.value,this.props.allowZeroPrice);
        if (individualPrice.match(/^\d+$/) || individualPrice === '') {
            this.setState({individualPrice: individualPrice});
        }
    }

    modifyPrice(price, allowZero) {
        let val = Number(price) || 0;
        if (allowZero && val === 0) {
            return val.toString()
        }
        if (!val) {

            return '';
        }

        return val.toString();
    }

    checkbasePrice() {
        return Number(this.props.price) === Number(this.state.individualPrice);
    }

    onAssign() {

        const service = {
            id: this.props.id,
            price: this.state.individualPrice,
            serviceId: this.props.id,
            userId: this.props.artistId
        };
        if (this.checkbasePrice()) {
            service.price = '';
        }
        this.props.onAssign(this.props.artistId, service);
    }

    onSave() {

        const service = {
            id: this.props.id,
            price: this.state.individualPrice,
            serviceId: this.props.id,
            userId: this.props.artistId
        };

        if (this.checkbasePrice()) {
            service.price = '';
        }
        this.props.onModify(this.props.artistId, service, this.props.assigned);
    }

    onEditHandler() {
        const service = {
            i18nKey: this.props.i18nKey,
            price: this.props.price,
            individualPrice: this.props.individualPrice,
            serviceId: this.props.serviceId,
            allowZeroPrice: this.props.allowZeroPrice
        };

        this.props.onEdit(this.props.artistId, service);
    }

    render() {
        let btnStyle = this.props.assigned ? "fr-btn fr-btn-small fr-serv-action__more" : "fr-btn-assign fr-btn-small fr-serv-action__more";

        if (!this.props.actions) {

            return null;
        }

        let buttons = <Button bsSize={"small"} className="fr-btn-add"
                              onClick={this.onAssign.bind(this)}>Assign</Button>;
        if (this.props.assigned) {
            buttons = <div>
                <Button bsSize={"small"} className={"fr-btn-serv-remove btn-link"} onClick={this.props.toggleView}>Cancel</Button>
                <Button bsSize={"small"} className={"fr-btn-serv-save-artist"}
                        onClick={this.onSave.bind(this)}>Save</Button>
            </div>
        }

        return <Row className="fr-table-centred__bordered services ">
            <Col xs={10} sm={5} md={6} className="fr-serv-name">{this.props.i18nKey}</Col>
            <Col xsHidden={true}  sm={2} md={2} className="fr-serv-price__artist">{this.props.price}</Col>
            <Col xsHidden={true}  sm={2} md={2} className="fr-serv-price-edit__artist">
                <FormControl
                    className="fr-serv-edit-control"
                    onChange={this.priceChange.bind(this)}
                    value={this.state.individualPrice}
                    placeholder={"Price, $"}
                    type={"text"}
                    maxLength={"4"}
                />

            </Col>
            <Col xsHidden={true} sm={3} md={2}>{buttons}</Col>
            <Col xs={2} lgHidden={true} mdHidden={true} smHidden={true}>
                <Button className={btnStyle}
                        onClick={this.onEditHandler.bind(this)}
                ><span className="glyphicon glyphicon-option-horizontal"></span></Button>
            </Col>
        </Row>
    }

}

TableRowArtistEdit.defaulProps = {
    name: '',
    price: '',
    individualPrice: '',
    onAssign: () => false,
    onModify: () => false
};