/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.18.7
 * Time: 11:08
 */

import ApiClient from "../service/api-client";

export function xhrLoadUnseenAppointments() {

    return ApiClient.get(["Q141_get_unseen_appointments"], {}, {
        headers: [{
            name: "U6-groups",
            value: ["basic", "apt-unseen"]
        }],
        enableDefaultNotifications: false
    }).then(answer => answer[0]);

}

export function xhrDismissAppointment(id = 0) {
    let url = ApiClient.getRouting().generate("Q142_remove_unseen_appointment", { id });

    return ApiClient.del(url).then(answer => answer);
}
