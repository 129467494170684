/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 19.10.17
 */

import BaseConstraint from "./base-constraint";

export const VALUE_IS_EQUAL = "VALUE_IS_EQUAL";

export default class IsNotEqual extends BaseConstraint {

    constructor(options = null) {
        super();

        this.message = "Must not be equal to {value}";

        this.value = null;

        this.setOptions(options);
    }

    static getName() {
        return "is-not-equal";
    }

    getRequiredOptions() {
        return ["value"];
    }
}
