/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 6.12.17
 * Time: 13.36
 */

import React from 'react';

export default function GalleryFullscreenItemContainer(props)    {
    return <div className="image-details">
        {props.children}
    </div>;
}