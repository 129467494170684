/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.31.3
 * Time: 16:25
 */

import React, {Component} from 'react';
import TableRowStatic from './table-row-static';
import {modalChange} from '../../../../../ac/modals';
import {SERVICE_EDIT_MODAL} from '../../../../../constants';

export default  class TableRow extends Component {

    constructor(...props) {
        super(...props);

        this.onServiceEdit = this.onServiceEdit.bind(this);
    }


    onServiceEdit() {
        return () => {
            modalChange(SERVICE_EDIT_MODAL, {
                service: this.props.service,
                duration: this.props.duration,
                processing: this.props.processing
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({service: nextProps.service});
    }

    render() {

        if (this.props.promo) {

            return <TableRowStatic
                promo={true}
                {...this.props}
            />
        }

        return <TableRowStatic onServiceEdit={this.onServiceEdit} {...this.props} />
    }
}

TableRow.defaultProps = {
    promo: false
};

