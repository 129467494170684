/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 29.6.17
 * Time: 14.16
 */

import React, { Component } from "react";
import { Button, Col, Modal, FormGroup } from "react-bootstrap";
import { modalHide } from "./../../ac/modals";
import Violation from "../helper-components/violation";
import stripe from "./../../service/stripe";
import { addNewPaymentSource } from "../../ac/payments/payments-personal";
import PropTypes from "prop-types";
import gradientFill from "./../../helper/gradient-fill";

export default class AddNewCardForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            violations: {},
            brand: null,
            btnDisabled: false
        };

        this.save = this.save.bind(this);
        this.checkErrorsCallback = this.checkErrorsCallback.bind(this);
        this.placeGeneralViolation = this.placeGeneralViolation.bind(this);
        this.clear = this.clear.bind(this);
    }

    componentDidMount() {

        this.buildForm();
    }

    buildForm() {
        const style = {
            base: {
                fontSize: "16px",
                lineHeight: "24px"
            }
        };

        const elements = stripe.getStripe().elements();

        this.form = {};

        this.form.postalCode = elements.create("postalCode", {
            placeholder: "ZIP",
            style
        });
        this.form.postalCode.addEventListener("change", this.checkErrorsCallback.bind(this, "postalCode"));

        this.form.cardNumber = elements.create("cardNumber", {
            placeholder: "Card No.",
            style
        });
        this.form.cardNumber.mount("#cardNumber");
        this.form.cardNumber.addEventListener("change", this.checkErrorsCallback.bind(this, "cardNumber"));
        this.form.cardNumber.addEventListener("change", data => {
            console.log("card change", data);

            if (data.brand === "visa") {
                this.form.postalCode.mount("#postalCode");
            }
            else if (this.form.postalCode) {
                this.form.postalCode.unmount();
                delete this.state.violations.postalCode;
            }

            this.setState({
                brand: data.brand
            });
        });

        this.form.cardExpiry = elements.create("cardExpiry", {
            placeholder: "Expiry MM/YY",
            style
        });
        this.form.cardExpiry.mount("#cardExpiry");
        this.form.cardExpiry.addEventListener("change", this.checkErrorsCallback.bind(this, "cardExpiry"));

        this.form.cardCvc = elements.create("cardCvc", {
            placeholder: "CVC",
            style
        });
        this.form.cardCvc.mount("#cardCvc");
        this.form.cardCvc.addEventListener("change", this.checkErrorsCallback.bind(this, "cardCvc"));


        console.log("this.form.postalCode", this.form.postalCode);

    }

    checkErrorsCallback(field, { error }) {
        let violations = this.state.violations;

        violations[field] = error ? error.message : "";
        violations["general"] = "";

        this.setState({
            violations
        });
    }

    placeGeneralViolation(message) {
        this.setState({
            violations: {
                general: message
            },
            btnDisabled: false
        });
    }

    save() {
        console.log("stripe", stripe.getStripe());
        this.setState({
            btnDisabled: true
        });
        stripe.getStripe().createToken(this.form.cardNumber, {
            currency: "USD"
        }).then(result => {
            if (result.error) {
                return Promise.reject(result);
            }

            this.props.action(result.token).then(positiveResult => {

                modalHide();

            }, negativeResult => {

                console.warn("negativeResult", negativeResult);

                this.placeGeneralViolation(negativeResult.message);
            });

        }).catch(error => {

            console.warn(error);

            this.placeGeneralViolation("Cannot place given card to payment system");
        });
    }

    clear() {

        Object.keys(this.form).forEach(key => {
            if (typeof this.form[key].clear === "function") {
                this.form[key].clear();
            }
        });
    }


    render() {
        const header = this.props.professional ? "Add new debit card" : "Add new card";
        return <div>
            <Modal.Header closeButton style={{ "background": gradientFill() }}>
                <h2>{header}</h2>
            </Modal.Header>

            <Modal.Body>
                <div>
                    <form id="form" className="form-horizontal">
                        {this.props.noCard &&
                        <p>
                            Please enter valid credit card details to secure your appointment.<br/>
                            Not to be charged until after the appointment with your final approval.
                        </p>
                        }
                        <FormGroup>
                            <Col md={12} xs={12}>
                                <div className="form-control" id="cardNumber"/>
                                <Violation violationSet={this.state.violations.cardNumber} visible={true}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={7} xs={12}>
                                <div className="form-control" id="cardExpiry">Expiry MM/YY</div>
                                <Violation violationSet={this.state.violations.cardExpiry} visible={true}/>
                            </Col>
                            <Col md={5} xs={12}>
                                <div className="form-control " id="cardCvc">CVC</div>
                                <Violation violationSet={this.state.violations.cardCvc} visible={true}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={6} xs={12}>
                                <div className="form-control" id="postalCode"
                                     style={{ display: this.state.brand !== "visa" ? "none" : "" }}/>
                                <Violation violationSet={this.state.violations.postalCode} visible={true}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Violation violationSet={this.state.violations.general} visible={true}/>
                        </FormGroup>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer className="card-add-footer">
                <div className="pull-left">
                    <Button onClick={this.clear} bsStyle="danger" className="">CLEAR</Button>
                </div>
                <div className="pull-right">
                    <Button onClick={this.save} bsStyle="success" className=""
                            disabled={this.state.btnDisabled}>SAVE
                    </Button>
                </div>

            </Modal.Footer>
        </div>;
    }

}

AddNewCardForm.propTypes = {
    noCard: PropTypes.bool,
    action: PropTypes.func,
    professional: PropTypes.bool
};

AddNewCardForm.defaultProps = {
    noCard: false,
    action: addNewPaymentSource,
    professional: false

};
