/**
 * Created by Ignat
 * Name: Ignat Kryshchyk
 * Company: U6 SIA
 * Date: 17.12.4
 * Time: 9:57
 */

import AppDispatcher from "./../../../dispatcher";
import ApiClient from "./../../../service/api-client";
import {
    PROFILE_GALLERY_ITEM_LOADED,
    PROFILE_GALLERY_ITEM_REMOVED,
    PROFILE_GALLERY_SALON_INFO_LOADED,
    SALON_UPDATE_UPLOADED_GALLERY_ITEMS_COUNT
} from "../../../constants";
import { xhrGetGalleryItem, xhrLoadNameAndLocation } from "../../../xhr/my-salon-xhr";
import { xhrRemoveGalleryItem } from "../../../xhr/profile-gallery-xhr";
import { notifySuccess } from "./../../notify-actions";

import { professionalProfileGalleryStore, sessionStore } from "../../../stores";
import notificationSystem from "../../../service/notification-system";
import { xhrGetUploadedGalleryItemsCount } from "../../../xhr/gallery/q2-44-get-uploaded-gallery-items-count-xhr";


export function changeFilterParams() {
}

export const loadGallerySalonInfo = (withGalleryData = false) => {
    xhrLoadNameAndLocation(withGalleryData).then(data => {
        AppDispatcher.dispatch({
            type: PROFILE_GALLERY_SALON_INFO_LOADED,
            payload: {
                salonInfo: data
            }
        });
    });
};

export const loadGalleryItem = (itemId) => {
    xhrGetGalleryItem(itemId).then(galleryItem => {
        AppDispatcher.dispatch({
            type: PROFILE_GALLERY_ITEM_LOADED,
            payload: {
                profileGalleryItem: galleryItem
            }
        });
    });
};


export const createNewGalleryItem = (data) => {
    let params = {
        userID: data.artistId,
        salonServiceID: data.salonServiceId,
        imageId: data.imageId,
        imageBeforeId: data.imageBeforeId
    };
    if (data.brand && data.brand.id) {
        params.brandID = data.brand.id;
    }
    return ApiClient.post(["Q16_new_gallery_item"], params, {
        enableDefaultNotifications: false
    }).then(response => {

        notifySuccess();
    });
};

export const updateGalleryItem = (data) => {

    return ApiClient.post(["Q30_edit_gallery_item"], {
        userID: data.userId,
        salonServiceID: data.salonServiceId,
        imageId: data.imageId,
        galleryItemID: data.galleryItemID
    }).then(response => {

        notifySuccess();

    });
};

export const removeGalleryItemAction = (itemId: any, galleryId: string) => {
    console.log("sessionStore.getSession()", sessionStore.getSession());
    console.log("professionalProfileGalleryStore.getStore().gallery",
        professionalProfileGalleryStore.getStore().gallery);

    let promise = new Promise(ok => ok());

    if (sessionStore.getSession().salon && sessionStore.getSession().salon.public) {
        if (professionalProfileGalleryStore.getStore().gallery.itemsCount === 1) {

            let s = "<b>FYI</b><br />" +
                "You are about to delete last image in your Gallery.<br />" +
                "Once done, your Profile will no longer be public.<br />" +
                "Still wish to proceed?";

            promise = notificationSystem.confirm(s, "Go ahead", "No way").then(result => {
                if (result.buttonClicked === "ok") {
                    return Promise.resolve();
                }

                return Promise.reject();
            });
        }
    }

    return promise.then(() => {

        xhrRemoveGalleryItem(itemId).then(() => {
            notifySuccess();

            AppDispatcher.dispatch({
                type: PROFILE_GALLERY_ITEM_REMOVED,
                payload: {
                    itemId,
                    galleryId
                }
            });
        });

    }, () => {
    });


};

export const updateUploadedGalleryItemsCountAction = () => {
    xhrGetUploadedGalleryItemsCount().then(response => {
        AppDispatcher.dispatch({
            type: SALON_UPDATE_UPLOADED_GALLERY_ITEMS_COUNT,
            payload: {
                uploadedGalleryItemsCount: response.uploaded_gallery_items
            }
        });
    });
};
