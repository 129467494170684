/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 21.7.17
 */

import ApiClient from "../../service/api-client";
import { CUSTOM_HEADER_U6_GROUPS } from "./../../frizo-shared-constants";

export function xhrGetPaymentOrderForApt(appointmentId) {

    let url = ApiClient.getRouting().generate("Q119_get_payment_order_by_appointment_id", {
        appointmentId
    });

    return ApiClient.get(url, null, {
        headers: {
            [CUSTOM_HEADER_U6_GROUPS]: "payment-order"
        }
    }).then(answer => answer[0], answer => Promise.reject(answer[0]));
}
