/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 10.5.17
 * Time: 22.58
 */

import {
    APPOINTMENT_STATUS_NO_SHOW,
    SCHEDULE_ELEMENT_STATE_IN_PROGRESS,
    SCHEDULE_ELEMENT_STATE_NO_SHOW,
    SCHEDULE_ELEMENT_STATE_PAST,
    SCHEDULE_ELEMENT_STATE_SUSPENSE,
    SCHEDULE_ELEMENT_STATE_UPCOMING
} from "../../frizo-shared-constants";

import moment from "moment";

export function calculateScheduleElementStatus(dataElement, nowTimestamp, tz) {
    if (dataElement.status === APPOINTMENT_STATUS_NO_SHOW) {
        return SCHEDULE_ELEMENT_STATE_NO_SHOW;
    }

    let endOfDay = moment
    .tz(dataElement.startTimestamp * 1000, tz)
    .endOf("day")
    .unix();

    if (nowTimestamp > endOfDay) {
        return SCHEDULE_ELEMENT_STATE_PAST;
    }

    if (nowTimestamp > dataElement.startTimestamp) {
        if (nowTimestamp < dataElement.endTimestamp) {
            return SCHEDULE_ELEMENT_STATE_IN_PROGRESS;
        }
        else {
            return SCHEDULE_ELEMENT_STATE_SUSPENSE;
        }
    }

    return SCHEDULE_ELEMENT_STATE_UPCOMING;
}
