/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 16.11.2018
 * Time: 09:54
 */

import React from "react";

export default function SpinnerGif() {
    return (
        <div className="spinner__gif">
        </div>);
}
