/**
 * Created by alexey
 * Name: Alexey Ilasov
 * Company: U6 SIA
 * Date: 17.3.11
 * Time: 15:05
 */

import React, {Component} from 'react';
import  WithHintDecorator from '../../decorators/with-hint-decorator';
import Violation from '../helper-components/violation';
import {FormControl} from 'react-bootstrap';

class ClientNameInput extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(ev) {
        let target = ev.target;

        this.props.change({field: target.name, value: target.value});
        this.props.onValueChange(target.value);
    }

    render() {
        return (<div>
                <FormControl
                    type={"text"}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange}
                    name={this.props.name}
                    readOnly={this.props.readOnly}
                    autoComplete={"off"}
                />
                <Violation violationSet={this.props.violations[this.props.name]} visible={this.props.showViolations}/>
            </div>
        );
    }
}

export default WithHintDecorator(ClientNameInput);