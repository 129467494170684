/**
 * Created by fliak on 12.1.17.
 */

import { EventEmitter } from "events";
import AppDispatcher from "../dispatcher/index";

import { GENERIC_VALIDATION_FAIL } from "./../constants";

import { validationFail } from "./../ac/validate-actions";

let dispatchTokens = {};

class BaseStore extends EventEmitter {

    constructor(stores) {
        super();
        this.stores = stores;
        this.store = this.getInitialStore();
        this.ready = false;
        this.readyPromise = null;

        this.validationScope = null;
    }

    /**
     * @private
     * @returns {{violations: {}}}
     */
    getInitialStore() {
        return {
            violations: {}
        };
    }

    /**
     * @protected
     */
    resetStore() {
        this.store = this.getInitialStore();
    }

    getDispatchToken() {
        return this.dispatchToken;
    }

    /**
     * @param ids string
     */
    waitFor(ids) {
        if (!Array.isArray(ids)) {
            ids = [ids];
        }

        AppDispatcher.waitFor(ids);
    }


    _registerActionSubscription(callback) {

        this.dispatchToken = AppDispatcher.register(action => {
            //call origin callback before
            let ret = callback(action);
            if (ret !== true) return ret;

            //run default if origin skipped
            let { type, payload } = action;

            switch (type) {
                case GENERIC_VALIDATION_FAIL:
                    if (payload.storeDispatchToken !== this.getDispatchToken()) {
                        return true;
                    }

                    this.store.violations = payload.violations;

                    break;

                default:
                    return true;
            }

            this.emitChange();
        });

        if (dispatchTokens[this.constructor.name] === undefined) {
            dispatchTokens[this.constructor.name] = [this.dispatchToken];
        }
        else {
            dispatchTokens[this.constructor.name].push(this.dispatchToken);
        }

    }

    getStore() {
        return this.store;
    }

    getAllStores() {
        return this.stores;
    }

    addChangeListener(callback) {
        this.on("SOME_INTERNAL_EVENT", callback);
    }

    removeChangeListener(callback) {
        this.removeListener("SOME_INTERNAL_EVENT", callback);
    }

    emitChange() {
        this.emit("SOME_INTERNAL_EVENT");
    }

    /**
     * @protected
     * @param readyFlag
     *
     * @protected
     */
    setReady(readyFlag) {
        this.ready = Boolean(readyFlag);
        if (readyFlag) {
            this.setReadyResolve(this.store);
        }
        else {
            this.readyPromise = null;
        }
    }

    isReady() {
        return Boolean(this.ready);
    }

    /**
     *
     * @param store
     * @returns {*}
     *
     * @private
     */
    setReadyResolve(store) {
        return store;
    }

    getReadyPromise() {
        if (!this.readyPromise) {

            if (this.ready) {
                this.readyPromise = new Promise((resolve, reject) => {
                    resolve(this.store);
                });
            }
            else {
                this.readyPromise = new Promise((resolve, reject) => {
                    this.setReadyResolve = resolve;
                });
            }
        }

        return this.readyPromise;
    }


    getViolations() {
        return this.store.violations;
    }

    resetValidationResult() {
        this.store.violations = {};
    }

    ensureValidator() {
        if (!this.validator) {
            if (typeof this.buildValidation === "function") {
                this.buildValidation();
            }
            else {
                throw new Error("Set this.validator before call validate");
            }
        }
    }

    getValidationScope() {
        if (!this.validationScope) {
            return this.store;
        }

        return this.validationScope;
    }

    validate() {
        this.ensureValidator();

        this.resetValidationResult();

        let result = this.validator.validate(this.getValidationScope());
        if (result !== true) {
            this.store.violations = result;

            return false;
        }

        return true;
    }

    fluxValidate() {
        this.ensureValidator();

        this.resetValidationResult();

        let result = this.validator.validate(this.getValidationScope());
        if (result !== true) {

            validationFail(this.getDispatchToken(), result);

            return false;
        }

        return true;
    }
}

export default BaseStore;
