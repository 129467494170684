/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 04.04.2017
 * Time: 16:39
 */
import React from 'react';
import {Col, Row} from 'react-bootstrap';

export default function HeaderAppointments() {

    return ( <div>
        <Row className="fr-list-header bordered fr-mt">
            <Col xsHidden={true} smHidden={true} md={1}>Status</Col>
            <Col xsHidden={true} smHidden={true} md={2}>Date</Col>
            <Col xsHidden={true} smHidden={true} md={3}>Client</Col>
            <Col xsHidden={true} smHidden={true} md={2}>Service</Col>
            <Col xsHidden={true} smHidden={true} md={2}>Artist</Col>
            <Col xsHidden={true} smHidden={true} md={2}>Action</Col>

            <Col xs={5} mdHidden={true} lgHidden={true}>Date</Col>
            <Col xs={7} mdHidden={true} lgHidden={true}>Client</Col>
        </Row>
    </div>);
}
