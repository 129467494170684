/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 15.09.2017
 * Time: 17:02
 */

import React from "react";
import { Row } from "react-bootstrap";
import SubscriptionBanner from "../subscriptions/components/subscription-banner";
import gradientFill from "../../helper/gradient-fill";
import PricingSingleArtist from "./pricing-single-artist";

export default function Pricing() {
    return (
        <div className="fr-banner-top__margin--mobile">
            <div className="fr-banner-top fr-full-width" style={{ "background": gradientFill() }}>
                <Row className="fr-container-width fr-text-center">
                    <div className="fr-banner-top__text--content">
                        <h1>Pricing</h1>
                    </div>
                </Row>
            </div>
            <SubscriptionBanner/>
            <PricingSingleArtist/>
        </div>
    );
}