/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.3.17
 * Time: 11.06
 *
 * @deprecated
 */

import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { autoHash } from './../../helper/auto-hash';

let groups = autoHash();
let callbacks = autoHash();

/**
 * @deprecated Use ToggleGroup instead
 */
export default class BtnToggle extends Component {

    constructor(props)   {
        super();

        let name = props.name;
        groups.ensureHash(name, {
            elements: [],
            active: null
        });

        groups[name].elements.push(this);

        this.state = {
            active: false
        };

        this.updateState = this.updateState.bind(this);
        this.selectCurrent = this.selectCurrent.bind(this);
        this.onClick = this.onClick.bind(this);

    }

    componentDidMount()    {

        if (this.props.value === this.props.selected)   {
            groups[this.props.name].active = this;
            groups[this.props.name].value = this.props.value;

            this.selectCurrent();
        }
    }

    componentWillReceiveProps(newProps) {

        if (newProps.value === newProps.selected)   {
            groups[this.props.name].active = this;
            groups[this.props.name].value = this.props.value;

            this.selectCurrent();
        }
    }

    onClick(e)   {

        e.preventDefault();

        groups[this.props.name].active = this;
        groups[this.props.name].value = this.props.value;


        if (Array.isArray(callbacks[this.props.name]))  {
            callbacks[this.props.name].forEach(callback => {
                callback();
            });
        }

    }


    selectCurrent() {

        groups[this.props.name].elements.forEach(btn => {
            btn.updateState();
        });
    }

    updateState()   {
        let active = false;

        let name = this.props.name;

        if (groups[name].active === this)    {
            active = true;
        }

        this.setState({
            active: active
        });

    }

    render()    {



        return <Button href="#" onClick={this.onClick} active={this.state.active}>
            {this.props.children}
        </Button>
    }
}

BtnToggle.addStateChangeListener = function(name, callback)  {
    callbacks.ensureArray(name).push(callback);
};

BtnToggle.removeStateChangeListener = function(name, callback)  {
    let groupCallbackSet = callbacks[name];
    if (Array.isArray(groupCallbackSet))    {
        let index = groupCallbackSet.indexOf(callback);
        groupCallbackSet.splice(index, 1);
    }
};

BtnToggle.getActiveValue = function(name)  {
    let group = groups[name];
    if (group !== undefined)    {
        return group.value;
    }
    else {
        throw new Error("Group is missing");
    }
};