/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 21.8.17
 */

import React from 'react'
import {
    PROFILE_FULLY_INCOMPLETE,
    PROFILE_INCOMPLETE,
    PROFILE_COMPLETE
} from './../../frizo-shared-constants';
import {Badge} from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function ProfileCompletenessBadge(props) {


    let className;

    switch (props.strength) {
        case PROFILE_FULLY_INCOMPLETE:
            className = 'fr-red-badge ';
            break;

        case PROFILE_INCOMPLETE:
            className = 'fr-orange-badge ';
            break;

        default:
            return false;
    }
    if (props.className !== undefined) {
        className = className + props.className;
    }
    return <Badge className={className}>!</Badge>;
}

ProfileCompletenessBadge.defaultProps = {
    strength: PROFILE_COMPLETE
};

ProfileCompletenessBadge.propTypes = {
    strength: PropTypes.oneOf([PROFILE_INCOMPLETE, PROFILE_FULLY_INCOMPLETE, PROFILE_COMPLETE])
};
