/**
 * User: George Novik
 * Username: fliak
 * Company: U6 SIA
 * Date: 23.11.17
 * Time: 9.49
 */

import ApiClient from "./../../service/api-client";
import { singleLookNormalizer } from "../../normal-form/gallery/professional-profile/single-look-normalizer";

type TParams = {
    count: number, offset: number, filter: {}
};

export function xhrLoadSalonGallery({ count, offset, artistId, categoryId, serviceId }: TParams) {
    const url = ApiClient.getRouting().generate("Q35_3_get_salon_gallery");

    let filter = {};
    if (artistId) filter.artistId = artistId;
    if (serviceId) {
        filter.serviceId = serviceId;
    }
    else {
        if (categoryId) filter.serviceId = categoryId;
    }

    let reqParams = Object.assign({}, { offset, limit: count }, filter);

    return ApiClient.get(url, reqParams)
    .then(answer => {
        let [response] = answer;
        //console.log('xhrLoadSalonGallery response', response);

        return {
            items: response.map(singleLookNormalizer)
        };
    });
}
